/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
export type TransitionStyle = {
  key: string;
  data?: unknown;
  style: {[key: string]: number};
};

const verticalPosition = {
  close: (dir: MotionDirection = 0): {y: number; opacity: number} => ({
    y: 50 * dir,
    opacity: 0,
  }),
  open: {y: 0, opacity: 1},
};

export const dropdownVertical = {
  positions: verticalPosition,
  enter: verticalPosition.close,
  open: {
    y: verticalPosition.open.y,
    opacity: verticalPosition.open.opacity,
  },
  leave: verticalPosition.close,
};

const horizontalPosition = {
  close: {x: 10, opacity: 0},
  open: {x: 0, opacity: 1},
};

export type DropdownTransitionPlainStyle = {
  x: number;
  y: number;
  opacity: number;
};

export type HorizontalStyle = {
  y: number;
  x: number;
  opacity: number;
};

export const dropdownHorizontal = {
  positions: horizontalPosition,
  enter: (y?: number): {x: number; y?: number; opacity: number} => ({y, ...dropdownHorizontal.positions.close}),
  open: (y: number): HorizontalStyle => ({
    y,
    x: dropdownHorizontal.positions.open.x,
    opacity: dropdownHorizontal.positions.open.opacity,
  }),
  leave: (y: number): HorizontalStyle => ({
    y,
    x: dropdownHorizontal.positions.close.x,
    opacity: dropdownHorizontal.positions.close.opacity,
  }),
};

const itemsPosition = {
  close: {y: -30, opacity: 0},
  open: {y: 0, opacity: 1},
};

const itemsConfig = {
  type: 'spring',
  duration: 0.3,
  y: {stiffness: 700, damping: 40},
  size: {stiffness: 700, damping: 40},
  opacity: {stiffness: 700, damping: 40},
};

export type MotionDirection = -1 | 0 | 1;

// Items container and items list
export const items = {
  positions: itemsPosition,
  config: itemsConfig,
  listEnter: (dir: MotionDirection = 0): {y: number; opacity: number} => ({
    y: items.positions.close.y * dir,
    opacity: items.positions.close.opacity,
  }),
  listActive: {
    y: itemsPosition.open.y,
    opacity: itemsPosition.open.opacity,
  },
  listLeave: (dir: MotionDirection = 0): {y: number; opacity: number} => ({
    y: items.positions.close.y * dir,
    opacity: items.positions.close.opacity,
  }),
};
