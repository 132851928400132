/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import stringify from 'safe-stable-stringify';
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {Grid} from 'components';
import {getGridData, prepareColumns} from 'components/Grid/GridUtils';

export default class GridLocal extends PureComponent {
  static propTypes = {
    settings: PropTypes.object.isRequired, //Grid config
    rows: PropTypes.array.isRequired,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      settings: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (stringify(nextProps.settings) !== stringify(prevState.settings) || nextProps.rows !== prevState.rows) {
      const nextState = {...nextProps};
      const columns = prepareColumns(nextProps.settings.columns);

      nextState.grid = getGridData({
        settings: nextProps.settings,
        rows: nextProps.rows,
        columns,
        sort: nextProps.settings.sort,
        capacity: nextProps.settings.capacity,
      });

      return nextState;
    }

    return null;
  }

  handleChange(gridParam) {
    const {capacity} = this.state.grid;
    let {columns, sort} = this.state.grid;

    if (gridParam.columns) {
      columns = new Map(
        Array.from(columns, ([id, column]) => [
          id,
          {...column, hidden: !gridParam.columns.includes(id) && id !== 'checkboxes'},
        ]),
      );
    } else if (gridParam.hasOwnProperty('columns')) {
      columns = prepareColumns(this.props.settings.columns);
    }

    if (gridParam.sort) {
      sort = gridParam.sort;
    } else if (gridParam.hasOwnProperty('sort')) {
      sort = this.props.settings.sort;
    }

    this.setState(prevState => ({
      grid: getGridData({
        settings: prevState.settings,
        rows: prevState.rows,
        columns,
        sort,
        page: gridParam.page || 1,
        capacity: gridParam.capacity || capacity,
      }),
    }));
    this.props.onChange?.(gridParam);
  }

  render() {
    return <Grid localGrid {...this.props} grid={this.state.grid} onChange={this.handleChange} />;
  }
}
