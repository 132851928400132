/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import PropTypes from 'prop-types';
import {useCallback, useContext} from 'react';
import {Selector} from 'containers';
import {AppContext} from 'containers/App/AppUtils';
import _ from 'lodash';

const defaultSelectorProps = {
  showCombinedCategory: true,
  autoFocus: true,
  closeDropdownOnSelection: true,
  maxColumns: 3,
  notExpandable: true,
};

PropTypes.GridFilter = {
  grid: PropTypes.object,
  onNavigate: PropTypes.func,
};

export default function GridFilter(props) {
  const {navigate} = useContext(AppContext);

  const {
    grid: {
      settings: {id},
      filter,
      params,
    },
    addLabelsAsScope,
    onNavigate,
    noAutoFocus,
    ...selectorProps
  } = props;

  const handleFilterChange = useCallback(
    values => {
      if (onNavigate) {
        return onNavigate(values);
      }

      const filter = _.mapValues(Object.fromEntries(values), values => {
        return values.map(value => {
          if (typeof value === 'object' && value.isPartial) {
            return value.value;
          }

          return value;
        });
      });

      navigate({params: {[id]: {...params, filter, page: null}}});
    },
    [id, onNavigate, navigate, params],
  );

  const values = new Map(Object.entries(filter ?? {}));

  Object.assign(selectorProps, {
    values,
    onSelectionChange: handleFilterChange,
  });

  return <Selector {...defaultSelectorProps} {...selectorProps} />;
}
