/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {type ChangeEvent, Component} from 'react';
import {Button, Tooltip, Textarea, type ButtonProps} from 'components';
import {type ThemeProps, type Theme, composeThemeFromProps} from '@css-modules-theme/react';
import styles from './TextareaTooltip.css';
import type {TooltipProps} from './Tooltip';
import {AppContext, type AppContextValue} from 'containers/App/AppUtils';

type TextareaTooltipProps = ThemeProps &
  TooltipProps &
  typeof TextareaTooltip.defaultProps & {
    editButtonProps?: ButtonProps;
    isInEditMode?: boolean;
    isVisible?: boolean;
    text?: string;
    onClickOutside(): void;
    onCloseIconClick(): void;
    onSaveIconClick(value: TextareaTooltipProps['text']): void;
    onEditIconClick(): void;
  };

interface TextareaTooltipState {
  isInEditMode: TextareaTooltipProps['isInEditMode'];
  value: TextareaTooltipProps['text'];
  text: TextareaTooltipProps['text'];
}

export default class TextareaTooltip extends Component<TextareaTooltipProps, TextareaTooltipState> {
  static contextType = AppContext;
  static defaultProps = {
    isInEditMode: false,
    text: '',
  };

  // eslint-disable-next-line react/static-property-placement
  declare context: AppContextValue;

  static getDerivedStateFromProps(nextProps: Readonly<TextareaTooltipProps>, prevState: TextareaTooltipState) {
    if (nextProps.isInEditMode !== prevState.isInEditMode || nextProps.text !== prevState.text) {
      return {
        isInEditMode: nextProps.isInEditMode,
        value: nextProps.text,
        text: nextProps.text,
      };
    }

    return null;
  }

  constructor(props: TextareaTooltipProps) {
    super(props);

    this.state = {
      isInEditMode: props.isInEditMode,
      value: props.text,
      text: props.text,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.renderEditMode = this.renderEditMode.bind(this);
    this.renderViewMode = this.renderViewMode.bind(this);
  }

  private handleChange(evt: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      value: evt.target.value,
    });
  }

  private handleEdit() {
    this.setState({
      isInEditMode: true,
    });
  }

  private handleClose() {
    this.setState({
      value: this.props.text,
      isInEditMode: false,
    });

    return this.props.onCloseIconClick?.();
  }

  private handleClickOutside() {
    this.setState({
      value: this.props.text,
      isInEditMode: true,
    });

    return this.props.onClickOutside?.();
  }

  private handleSave() {
    if (this.props.onSaveIconClick) {
      // pass the text area's current value to the parent handler
      this.props.onSaveIconClick(this.state.value);

      return;
    }

    this.setState({
      isInEditMode: false,
    });
  }

  private renderEditMode(theme: Theme) {
    return (
      <div className={theme.content}>
        <Textarea
          theme={styles}
          value={this.state.value}
          onChange={this.handleChange}
          rows={7}
          noWrap
          tid="textarea-tooltip"
        />
        <div className={theme.buttonsWrapper}>
          <Button size="medium" noFill icon="save" onClick={this.handleSave} theme={theme} themePrefix="button-" />
          <Button size="medium" noFill icon="close" onClick={this.handleClose} theme={theme} themePrefix="button-" />
        </div>
      </div>
    );
  }

  private renderViewMode(theme: Theme, buttonProps?: ButtonProps) {
    return (
      <div className={theme.content}>
        <div className={theme.viewMode}>{this.state.value}</div>
        <div className={theme.buttonsWrapper}>
          <Button
            size="medium"
            noFill
            icon="edit-underline"
            onClick={this.props.onEditIconClick || this.handleEdit}
            theme={theme}
            themePrefix="button-"
            {...buttonProps}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      onClickOutside,
      isVisible,
      children,
      editButtonProps,
      isInEditMode,
      text,
      onCloseIconClick,
      onSaveIconClick,
      onEditIconClick,
      ...rest
    } = this.props;

    const theme = composeThemeFromProps(styles, this.props);

    // rest in destruction uses Omit behind the scene and can't correctly handle distributive unions
    // so we need to assert with UnionOmit
    const tooltipProps: TooltipProps = rest;

    tooltipProps.interactive = true;
    tooltipProps.content = isInEditMode ? this.renderEditMode(theme) : this.renderViewMode(theme, editButtonProps);
    tooltipProps.visible = isVisible;
    tooltipProps.onClickOutside = this.handleClickOutside;

    return <Tooltip {...tooltipProps}>{children}</Tooltip>;
  }
}
