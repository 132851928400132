/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './WorkloadVulnerabilitiesConfig';
import {getWorkloadInstance} from '../WorkloadItemState';
import {roundNumber} from 'components/Vulnerability/VulnerabilityUtils';

export default {
  vulnerabilities(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_VULNERABILITIES':
        state[action.key] = action.data;

        return {...state};
      default:
        return state;
    }
  },
  traffic(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_VULNERABILITIES_GET_TRAFFIC':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVulnerabilities = state => state.workload.vulnerabilities;
export const getTraffic = state => state.workload.traffic;

export const getVulnerabilitiesData = createSelector(
  [getVulnerabilities, getWorkloadInstance, getTraffic],
  (vulnerabilities, workload, traffic) => {
    return vulnerabilities[workload.href]
      ? Object.values(vulnerabilities[workload.href].instances)
          .flat()
          .map((vulnerability, idx) => ({
            key: idx,
            ...vulnerability,
            traffic: traffic[[vulnerability.port, vulnerability.protocol].join(',')]?.policyDecision ?? 'none',
          }))
      : [];
  },
);

export const getVulnerabilitiesRows = createSelector(
  [getVulnerabilitiesData],
  vulnerabilitiesData => vulnerabilitiesData,
);

export const getTotalVEScore = createSelector(
  [getVulnerabilities, getWorkloadInstance],
  (vulnerabilities, workload) => {
    return vulnerabilities[workload.href]?.aggregatedValues
      ? roundNumber(vulnerabilities[workload.href].aggregatedValues.vulnerabilityExposureScore)
      : null;
  },
);

export const vulnerabilitiesGridSettings = createSelector([], () => gridSettings);

const getVulnerabilitiesGrid = state =>
  getGridSelector(state, {
    settings: vulnerabilitiesGridSettings,
    rows: getVulnerabilitiesRows,
  });

export const getVulnerabilitiesPage = createSelector(
  [getWorkloadInstance, getVulnerabilitiesGrid, getTotalVEScore],
  (workload, vulnerabilitiesgrid, totalVEScore) => ({
    workload,
    vulnerabilitiesgrid,
    totalVEScore,
  }),
);
