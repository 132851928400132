/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/RulesetListState';
import detailReducers from './Item/RulesetItemState';

export default {
  ruleset: combineReducers({
    ...listReducers,
    ...detailReducers,
  }),
};

// @TODO move to RulesetListState when converted to TS
export interface FetchRulesetsParams {
  filter?: Record<string, string[]>;
  representation?: boolean;
  pversion?: import('illumio').PVersion;
  scope?: {scope: {key: string}[]};
  force?: boolean;
}
