/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {hrefUtils, ipUtils} from '@illumio-shared/utils';
import {isAPIAvailable} from 'api/apiUtils';
import {getRouteName, isLabelBasedNetworkEnabled} from 'containers/App/AppState';
import {gridSettings} from '../Edit/NetworkEditConfig';
import {Pill} from 'components';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';

export default {
  network(state = {}, action) {
    switch (action.type) {
      case 'NETWORK_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getNetworkInstance = state => state.networks.network;

export const getLabelRows = createSelector([getNetworkInstance], detail => {
  return detail?.scopes
    ? detail.scopes?.map((label, index) => {
        return {
          key: index,
          selectable: true,
          data: {
            labels: label,
          },
        };
      })
    : [];
});
export const isNetworkEditEnabled = createSelector(
  [getNetworkInstance],
  ({user_managed}) => user_managed && isAPIAvailable('network.update'),
);

export const getNetworkPage = createSelector(
  [
    getNetworkInstance,
    isNetworkEditEnabled,
    getRouteName,
    gridSettings,
    getLabelRows,
    getDisplayNames,
    isLabelBasedNetworkEnabled,
  ],
  (network, enableEdit, routeName, gridSettings, initialRows, labelTypesNameObj, isCustomNetworkEnabled) => {
    const {cidrs = [], ip_version, href} = network;
    const formattedCidrs = ipUtils.getIpFormat(cidrs || []);

    const labelStatics = [];

    initialRows?.forEach(row => {
      if (row.data.labels.length !== 0) {
        row.data.labels.forEach(label => {
          labelStatics.push(label);
        });
      }
    });

    const categories = [
      {
        id: 'labels',
        name: 'Labels',
        resources: {
          labels: {
            statics: labelStatics,
            optionProps: {
              format: ({option}) => (
                <Pill.Label type={option.key} noContextualMenu>
                  {option.value}
                </Pill.Label>
              ),
              allowMultipleSelection: false,
              tooltipProps: option => ({content: labelTypesNameObj[option.key]}),
            },
            selectedProps: {
              formatValue: ({value, onRemove, disabled}) => {
                return (
                  <Pill.Label type={value.key} onClose={disabled ? undefined : onRemove} noContextualMenu>
                    {value.value}
                  </Pill.Label>
                );
              },
            },
          },
        },
      },
    ];

    return {
      ...network,
      ...(href && {network_id: hrefUtils.getId(href)}),
      isEdit: routeName.endsWith('edit'),
      enableEdit,
      cidrs4: ip_version === 4 ? formattedCidrs.ipFormat : [],
      cidrs6: ip_version === 6 ? formattedCidrs.ipFormat : [],
      formattedCidrs,
      gridSettings,
      initialRows,
      categories,
      isCustomNetworkEnabled,
    };
  },
);
