/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {createRef, PureComponent} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {StickyContainer, Banner} from 'components';
import SizeWatcher from 'react-size-watcher';
import BodyRow from './GridRowBody';
import HeadRow from './GridRowHead';
import Manager from './Manager/GridManager';
import {domUtils, tidUtils} from '@illumio-shared/utils';
import styles from './Grid.css';
import stylesUtils from 'utils.css';

const minmaxRegex = /^minmax\((.+),(.+)\)$/;
const tableMediaRegex = /^table(?:-minWidth_(\d+))?(?:-maxWidth_(\d+))?$/;

export default class Grid extends PureComponent {
  // PureComponent is important for grid to avoid rerendering on parent not related rerender
  static contextType = AppContext;
  static propTypes = {
    tid: PropTypes.string,
    grid: PropTypes.shape({
      settings: PropTypes.object.isRequired, // Grid config
      rows: PropTypes.array.isRequired,
      rowsMap: PropTypes.object,
      columns: PropTypes.object.isRequired,
      sort: PropTypes.string, // Sort column id
      sortObject: PropTypes.object, // {factor, columnId}
      filter: PropTypes.object, // {passed, valid, isEmpty}
      page: PropTypes.number, // Page number
      capacity: PropTypes.number, // Number of rows per page
      totalRows: PropTypes.number, // Total number of rows
      params: PropTypes.object, // Grid Url params
      columnIds: PropTypes.object, //{required, default, optional, visible, defaultNotRequired, newColumnIds, seenColumnIds, allColumnIds}
    }).isRequired,
    localGrid: PropTypes.bool,
    offset: PropTypes.string,

    /* By default table gets out of page padding, set this to true to be just 100% of parent*/
    inlineSize: PropTypes.bool,

    // Row onclick handler
    onClick: PropTypes.func,

    // Row onReorder handler
    onReorder: PropTypes.func,

    // Row onReorderEnd handler
    onReorderEnd: PropTypes.func,

    // Grid data change by sort, page, column, or capacity selections
    onChange: PropTypes.func,

    // The onBeforeChange function is called before a sort, page, column, or
    // capacity action is executed. It provides a way to programmatically allow
    // or cancel the action. The function is called with the following arguments:
    // (event, {allow, cancel, ...params})
    //   event: the click event that triggered the page change
    //   allow: function that allows the action when called;
    //   cancel: function that cancels the action when called;
    //   ...params: params for the specific action (e.g. page, lastPage, capacity, sort, etc.)
    onBeforeChange: PropTypes.func,

    // Notification of a page change
    onPageChange: PropTypes.func,

    // Row onMouseOver handler
    onMouseOver: PropTypes.func,

    // Row onMouseLeave handler
    onMouseLeave: PropTypes.func,

    // Map of props for each row key that will be assign to props in GridRowBody
    // Useful for inserting individual rendering props in rows, for example, custom highlighting
    extraPropsKeyMap(props, propName, componentName) {
      const value = props[propName];

      if (value !== undefined && !(props[propName] instanceof Map)) {
        return new Error(`Prop '${propName}' supplied to '${componentName}' should be a Map`);
      }
    },

    // Grid selection behavior can be controlled or uncontrolled, similar to react's controlled/uncontrolled components concept.
    // By default Grid manages selected rows by itself (uncontrolled) and calls onSelect callback (if exists) after selection changes.
    // If selectedKeySet is passed Grid becomes controlled, and will render checkbox as checked only if row key exists in selectedKeySet.
    selectedKeySet(props, propName, componentName) {
      const value = props[propName];

      if (value !== undefined && !(props[propName] instanceof Set)) {
        return new Error(`Prop '${propName}' supplied to '${componentName}' should be a Set`);
      }
    },
    // In controlled scenario Grid calls onSelect callback with {evt, affectedRows, selecting} params, where
    // 'evt' is a checkbox's change event that triggered onSelect,
    // 'affectedRows' is an array of rows that changed their selected state on current event, and
    // 'selecting' is a boolean whether user checking/unchecking affectedRows
    // In uncontrolled scenario Grid also passes 'selectedKeySet' param with all currently selected rows keys, since it manages by itself
    onSelect(props, propName, componentName) {
      const type = typeof props[propName];

      if (type !== 'undefined' && type !== 'function') {
        return new Error(`Prop '${propName}' supplied to '${componentName}' should be a function`);
      }

      // Controlled Grid should have onSelect property so parent can react to selection and update selectedKeySet
      if (props.selectedKeySet instanceof Set && type === 'undefined') {
        return new Error(
          `Prop '${propName}' supplied to '${componentName}' required to be a function in case of controlled selection`,
        );
      }
    },
    // By default grid highlights selected rows
    // If it is not desired (for instance, we want to highlight only some selected rows by button hover), pass true to this property
    dontHighlightSelected: PropTypes.bool,

    // Show a type of message for empty data
    emptyMessage: PropTypes.node,

    // If data is empty, link to create data
    addItemLink: PropTypes.node,
    count: PropTypes.object,

    // By default, if all columns are fixed (no 'auto' or 'fr' among them) then extra column with 'auto' will be added.
    // If, instead, you want to make last column expandable, set expandLastFixedColumn to true,
    // in which case its width will be set to 'auto' (or minmax(x, auto) if template has minmax(x, y) size)
    expandLastFixedColumn: PropTypes.bool,
  };

  static defaultProps = {
    localGrid: false,
    offset: 'var(--header-height)',
    emptyMessage: '',
  };

  constructor(props) {
    super(props);

    this.breakpoint = null;
    this.ref = createRef();

    this.state = {
      // Selection is controllable if 'selectedKeySet' passed in props
      selectIsControllable: Boolean(props.selectedKeySet),
      // One source of truth for 'selectedKeySet'
      // If selection is uncontrolled - managed by Grid, if controlled - simply taken from props
      selectedKeySet: props.selectedKeySet || new Set(),
    };
    // Needed to determine selection with pressed Shift button
    this.lastSelectedRow = null;

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const theme = composeThemeFromProps(styles, nextProps);
    const templates = nextProps.grid.settings.templates;

    if (
      nextProps.selectedKeySet !== prevState.selectedKeySetProps ||
      templates !== prevState.templates ||
      theme !== prevState.theme
    ) {
      const newState = {};

      if (templates !== prevState.templates) {
        // Compute breakpoint before first render or recompute if template settings are changed
        newState.templates = templates;
        newState.breakpoints = computeBreakpointsFromGridConfig(templates, nextProps);
      }

      if (nextProps.selectedKeySet !== prevState.selectedKeySetProps) {
        const selectIsControllable = Boolean(nextProps.selectedKeySet);

        newState.selectedKeySetProps = nextProps.selectedKeySet;

        if (selectIsControllable) {
          // If selection is controllable, set received selectedKeySet prop into state
          newState.selectedKeySet = nextProps.selectedKeySet;
        }

        if (selectIsControllable !== prevState.selectIsControllable) {
          // If controlled setting is changed, set it to the state
          newState.selectIsControllable = selectIsControllable;
        }
      }

      if (theme !== prevState.theme) {
        newState.theme = theme;
      }

      return newState;
    }

    return null;
  }

  componentDidMount() {
    if (!this.props.localGrid) {
      this.checkSeenColumns();
    }
  }

  componentDidUpdate(prevProps) {
    // If rows are changed (another page or sort), reset lastSelectedRow since it doesn't make sense anymore
    if (this.lastSelectedRow !== null && this.props.grid.rows !== prevProps.grid.rows) {
      this.lastSelectedRow = null;
    }

    if (!this.props.localGrid) {
      this.checkSeenColumns();
    }
  }

  handleSizeChange(size, currentReceivedBreakpoint, newReceivedBreakpoint) {
    this.size = size;

    // If breakpoint is going to change, children function will be called that calls computeClassName itself.
    // So check call computeClassName here only if breakpoint is not going to change
    if (newReceivedBreakpoint === currentReceivedBreakpoint) {
      const className = this.computeClassName();

      // If className has changed, rerender grid
      if (className !== this.className) {
        this.className = className;
        this.forceUpdate();
      }
    }
  }

  handleSelect(evt, row, pressedKeys) {
    let affectedRows;
    let selecting;

    if (this.state.selectIsControllable) {
      const {props, state} = this;

      // If selection is controlled, compute affected rows and simply pass it to the parent, so it can decide what to do
      ({affectedRows, selecting} = this.computeSelection({state, props, row, isShiftPressed: pressedKeys.shift}));
      props.onSelect({evt, affectedRows, selecting});
    } else {
      // If selection is uncontrolled, compute affected rows and new selectedKeySet and set it to the state.
      // After state is set, pass affected rows to the parent, so it can perform some related actions if needed
      this.setState(
        // Set state in callback to address possible asynchronous setState
        (state, props) => {
          let selectedKeySet;

          ({selectedKeySet, affectedRows, selecting} = this.computeSelection({
            state,
            props,
            row,
            isShiftPressed: pressedKeys.shift,
          }));

          return {selectedKeySet};
        },
        // Callback after successful (possibly asynchronous) state update
        () => {
          const {props, state} = this; // Get state and props again since they could have been changed since handle call

          if (props.onSelect) {
            props.onSelect({evt, selecting, affectedRows, selectedKeySet: state.selectedKeySet});
          }
        },
      );
    }

    this.lastSelectedRow = row;
  }

  handleSelectAll(evt, selecting) {
    const {props, state} = this;
    const selectableRows = props.grid.rows.filter(row => row.selectable && !row.disableCheckbox);

    this.lastSelectedRow = null;

    if (state.selectIsControllable) {
      props.onSelect({
        evt,
        selecting,
        affectedRows: selectableRows.filter(row =>
          selecting ? !state.selectedKeySet.has(row.key) : state.selectedKeySet.has(row.key),
        ),
      });
    } else {
      this.setState({selectedKeySet: new Set(selecting ? selectableRows.map(row => row.key) : [])}, () => {
        if (this.props.onSelect) {
          this.props.onSelect({
            evt,
            selecting,
            affectedRows: selectableRows,
            selectedKeySet: this.state.selectedKeySet,
          });
        }
      });
    }
  }

  async handleChange(evt, param, seenColumnIds) {
    const {grid, onChange, onPageChange, onBeforeChange} = this.props;

    const allowChange = onBeforeChange
      ? await new Promise(resolve => {
          const allow = () => {
            resolve(true);
          };
          const cancel = () => {
            resolve(false);
          };

          onBeforeChange(evt, {...param, allow, cancel});
        })
      : true;

    if (!allowChange) {
      return;
    }

    if (param?.hasOwnProperty('page') || param?.hasOwnProperty('sort')) {
      setTimeout(() => domUtils.scrollToTopOfElement({element: this.ref.current}));

      if (onPageChange) {
        onPageChange({page: param.page});
      }
    }

    this.sendAnalytics(param);

    const gridParam = _.omit(param, ['lastPage', 'action', 'columnId']);

    if (onChange) {
      return onChange(gridParam);
    }

    let params = {};

    if (gridParam) {
      params = _.omitBy({...grid.params, ...gridParam}, param => param === null);

      // Remove from URL if it is a default sort
      if (params.sort && params.sort === grid.settings.sort) {
        params = _.omit(params, 'sort');
      }
    } else {
      params = grid.params;
    }

    seenColumnIds ||= grid.columnIds.seenColumnIds;

    //New user settings
    this.context.dispatch({
      type: 'GRID_UPDATE_SETTINGS',
      key: grid.settings.uniqueId,
      data: Object.assign(_.pick(params, grid.settings.validKVPairKeys || ['sort', 'columns', 'capacity']), {
        seenColumnIds,
      }),
    });

    const capacity = params.capacity ?? grid.settings.capacity;

    if (grid.totalRows < params.page * capacity) {
      params.page = Math.ceil(grid.totalRows / capacity);
    }

    // Navigate to new url if url parameters are changed
    if (params !== grid.params) {
      this.context.navigate({
        evt,
        params: {[grid.settings.id]: _.isEmpty(params) ? null : params},
        // Append to history in case of page change, and replace otherwise
        replace: params.page === grid.params.page || (!params.page && !grid.params.page),
        scrollTop: false,
        noUnsavedPendingWarning: true,
      });
    }
  }

  checkSeenColumns() {
    const {
      settings,
      columnIds: {seenColumnIds = [], allColumnIds = [], visible: visibleColumnIds, default: defaultColumnIds},
    } = this.props.grid;
    let validSeenColumnIds;

    if (!seenColumnIds.length) {
      // If user opens up this grid for the first time, there is no point in shwoing 'new' badge on columns,
      // because the whole view is new for that user, so immedeately mark all columns as seen
      validSeenColumnIds = allColumnIds;
    } else if (visibleColumnIds.some(id => !seenColumnIds.includes(id))) {
      // If user is seeing columns that were not visible during the last visit,
      // for instance new default/required column has been added,
      // then mark those columns as seen
      validSeenColumnIds = defaultColumnIds.reduce((result, id) => {
        if (seenColumnIds.includes(id) || visibleColumnIds.includes(id)) {
          result.push(id);
        }

        return result;
      }, []);
    } else {
      validSeenColumnIds = seenColumnIds;
    }

    if (validSeenColumnIds !== seenColumnIds) {
      this.context.dispatch({
        type: 'GRID_UPDATE_SETTINGS',
        key: settings.uniqueId,
        data: {seenColumnIds: validSeenColumnIds},
        merge: true,
      });
    }
  }

  computeSelection({state, props, row, isShiftPressed}) {
    const {rows} = props.grid;
    const selectedKeySet = new Set(state.selectedKeySet);
    let selecting = false;
    let affectedRows;

    if (isShiftPressed && row !== this.lastSelectedRow) {
      const lastSelectedRow = this.lastSelectedRow || rows.find(row => row.selectable);
      const lastSelectedRowIndex = rows.indexOf(lastSelectedRow);
      const selectedRowIndex = rows.indexOf(row);
      let startRowIndex;
      let endRowIndex;

      if (selectedRowIndex > lastSelectedRowIndex) {
        startRowIndex = this.lastSelectedRow ? lastSelectedRowIndex + 1 : lastSelectedRowIndex;
        endRowIndex = selectedRowIndex + 1;
      } else {
        startRowIndex = selectedRowIndex;
        endRowIndex = lastSelectedRowIndex + 1;
      }

      // Get slice of affected rows between last and current selections
      affectedRows = rows.slice(startRowIndex, endRowIndex).filter(row => row.selectable);
      // If at least one row in affected slice is not selected,
      // consider action as selecting of not yet selected rows within slice, if all selected - as deselecting whole slice
      selecting = affectedRows.some(row => !state.selectedKeySet.has(row.key));

      if (selecting) {
        // If intention is to select, keep only not yet selected
        affectedRows = affectedRows.filter(row => !state.selectedKeySet.has(row.key));
      }
    } else {
      affectedRows = [row];
      selecting = !state.selectedKeySet.has(row.key);
    }

    affectedRows.forEach(row => selectedKeySet[selecting ? 'add' : 'delete'](row.key));

    return {selectedKeySet, affectedRows, selecting};
  }

  computeBreakpoint(receivedBreakpoint) {
    const {columns} = this.props.grid;

    // Recompute className only if next properties have changed since last compute
    if (
      this.breakpointComputedFor &&
      this.breakpointComputedFor.receivedBreakpoint === receivedBreakpoint &&
      this.breakpointComputedFor.columns === columns
    ) {
      return;
    }

    const template = ['0px']; // First column is zero-width focuser
    const {minWidth = 0, maxWidth = Infinity, minHeight = 0, maxHeight = Infinity, data} = receivedBreakpoint;
    const breakpoint = {meta: {minWidth, maxWidth, minHeight, maxHeight}, columns: []};

    breakpoint.meta.data = data.data ?? data.getData?.call(this.props.component) ?? {};

    if (typeof data.id === 'string') {
      breakpoint.meta.id = data.id;
    }

    const templateArray = data.template;
    let expandableColumnExists = false;

    breakpoint.templateArray = templateArray;

    for (const item of templateArray.values()) {
      const {size = '0px'} = item;
      const column = {size};

      column.cells = item.columns?.reduce((cells, name) => {
        if (__DEV__ && !columns.has(name)) {
          console.error(`You specified column with '${name}' name in grid template, but not in columns config`);
        }

        const cell = columns.get(name);

        if (!cell.disabled && !cell.hidden) {
          cells.push(cell);

          if (name === 'checkboxes') {
            column.containsCheckboxCell = true;
          }

          if (name === 'dragbar') {
            column.containsdragbarCell = true;
          }

          if (cell.reactsToSelection) {
            column.reactsToSelection = true;
          }
        }

        return cells;
      }, []);

      if (__DEV__) {
        if (item.span && column.cells.length > 1) {
          console.error(
            'Column containing span: [before, after] can have only one entry in templates "columns" property array in grid config',
          );
        }
      }

      if (!column.cells?.length) {
        continue;
      }

      if (typeof item.extraClass === 'string') {
        column.extraClass = item.extraClass;
      }

      if (typeof item.extraCellClasses === 'object') {
        // Useful to override or add specific styling at a column level
        column.extraCellClasses = item.extraCellClasses;
      }

      if (typeof item.style === 'object') {
        column.style = item.style;
      }

      if (item.header !== undefined) {
        column.header = item.header;
      }

      if (item.span) {
        column.span = item.span;

        if (__DEV__) {
          if (
            !Array.isArray(column.span) ||
            !Number.isInteger(column.span[0]) ||
            !Number.isInteger(column.span[1]) ||
            column.span.length !== 2 ||
            column.index + column.span[0] < 0 ||
            column.span[0] > 0 ||
            column.span[1] + column.index > breakpoint.columns.length ||
            column.span[1] < 0
          ) {
            console.error(
              `%cPlease add a span array to span row with format span: [before,after] for column "${column.cells[0].id}", where before < = 0 and after >= 0 and before <= (span template row index) <= after`,
              'color: salmon;',
            );
          }
        }
      }

      if (!expandableColumnExists) {
        let sizeToCheck;

        if (minmaxRegex.test(size)) {
          // Take max value from minmax
          sizeToCheck = size.match(minmaxRegex)[2].trim();
        } else {
          sizeToCheck = size;
        }

        // Column consider to be expandable if size contain auto or fraction (fr)
        expandableColumnExists = sizeToCheck.includes('auto') || sizeToCheck.includes('fr');
      }

      // Modern browsers that support display:grid and display:contents
      template.push(size);

      breakpoint.columns.push(column);
    }

    if (!expandableColumnExists) {
      if (this.props.expandLastFixedColumn && template.length) {
        // If all columns have fixed size, forcibly make the last column auto to expand it to the full width of the grid
        const size = template.at(-1);

        if (minmaxRegex.test(size)) {
          // If last column uses minmax, then assign auto as max
          template[template.length - 1] = `minmax(${size.match(minmaxRegex)[1].trim}, auto)`;
        } else {
          template[template.length - 1] = 'auto';
        }
      } else {
        // If all columns have fixed size, add one more dummy column that will expand to occupy the rest space
        template.push('auto');

        breakpoint.columns.push({isExpander: true});
      }
    }

    breakpoint.columns = breakpoint.columns.map((column, index) => ({...column, index}));

    const templateString = template.join(' ');

    breakpoint.template = templateString;

    this.breakpoint = breakpoint;
    this.templateString = templateString;

    this.breakpointComputedFor = {
      columns,
      receivedBreakpoint,
    };

    this.computeColSpanDynamically();
  }

  computeClassName() {
    const {breakpoint, state, size} = this;
    const {width, theme, templateString, breakpointExtraClass} = this.classNameComputedFor || {};

    // Recompute className only if next properties have been change since last compute
    if (
      size.width !== width ||
      theme !== state.theme ||
      templateString !== this.templateString ||
      breakpointExtraClass !== breakpoint.extraClass
    ) {
      const tableClasses = [state.theme.table];

      for (const className of Object.keys(state.theme)) {
        const match = className.match(tableMediaRegex);

        if (className !== 'table' && Array.isArray(match)) {
          const minWidth = Number(match[1]) || 0;
          const maxWidth = Number(match[2]) || 0;

          if (__DEV__ && minWidth && maxWidth && minWidth > maxWidth) {
            throw new Error('GRID CSS: table minWidth should be less or smaller maxWidth');
          }

          if ((!minWidth || size.width >= minWidth) && (!maxWidth || size.width <= maxWidth)) {
            tableClasses.push(state.theme[className]);
          }
        }
      }

      this.classNameComputedFor = {
        width: size.width,
        theme: state.theme,
        templateString: this.templateString,
        breakpointExtraClass: breakpoint.extraClass,
      };

      return cx(tableClasses, breakpoint?.extraClass);
    }

    return this.className;
  }

  /**
   * Method that updates span: [before, after] values dynamically when column manager columns are checked/unchecked or when columns are disabled in config.
   * @param span span object that contains span columns as keys assigned with bool value. Dervied from extraPropsKeyMap or row.span
   */
  computeColSpanDynamically() {
    const breakpoint = this.breakpoint;

    const {
      grid: {
        columnIds: {visible: visibleColumns, default: defaultColumns},
      },
    } = this.props;

    // Get indices of all span columns.
    const breakpointColumnLength = breakpoint.columns.length;

    //Case when columns are disabled in state file append to missing columns.
    const disabledOrHiddenColumns = breakpoint.templateArray
      .filter(
        templateColumn =>
          !breakpoint.columns.some(breakpointColumn =>
            breakpointColumn.cells?.some(cell => templateColumn.columns.includes(cell.id)),
          ),
      )
      .flatMap(templateColumn => templateColumn.columns);

    //Only unique values in case disabledColumns match with missing columns.
    //Missing columns = Disabled columns + defaultColumns - visibleColumns
    const missingColumns = new Set(
      defaultColumns.filter(column => !visibleColumns.includes(column)).concat(disabledOrHiddenColumns),
    );

    const spanColumns = breakpoint.templateArray.reduce((result, item, templateArrayIndex) => {
      if (item.span) {
        const columnsToSpan = [];

        for (let i = templateArrayIndex + item.span[0]; i <= templateArrayIndex + item.span[1]; i++) {
          if (i !== templateArrayIndex) {
            columnsToSpan.push({
              name: breakpoint.templateArray[i].columns[0],
              position: i < templateArrayIndex && breakpoint.templateArray[i].columns.length <= 1 ? 'before' : 'after',
            });
          }
        }

        item.columnsToSpan = columnsToSpan;

        result.push(item);
      }

      return result;
    }, []);

    if (spanColumns.length === 0) {
      return;
    }

    // Each span set via row or extra props.
    spanColumns.forEach(spanColumn => {
      const columnName = spanColumn.columns[0];

      //Loop through breakpoint columns.
      breakpoint.columns.forEach((column, index) => {
        if (column.cells?.some(cell => cell.id === columnName)) {
          //Reset span to original value each time.
          column.span = _.clone(spanColumn.span);

          //Go through all missing columns and update col span value
          missingColumns.forEach(missingColumn => {
            const chosenColumn = spanColumn.columnsToSpan.find(columnToSpan => missingColumn === columnToSpan.name);

            // If missing column found, update col span based on where it exists in grid.
            if (chosenColumn && missingColumns.has(chosenColumn.name)) {
              if (chosenColumn.position === 'before') {
                if (column.span[0] + 1 <= 0) {
                  column.span[0]++;
                } else {
                  column.span[0]--;
                }
              } else if (chosenColumn.position === 'after') {
                if (column.span[1] - 1 >= 0) {
                  column.span[1]--;
                } else {
                  column.span[1]++;
                }
              }
            }
          });

          // Handle out of bounds case for column span.
          const columnZeroIndexValue = column.span[0] + index < 0 ? 0 : column.span[0];
          const columnOneIndexValue =
            index + column.span[1] > breakpointColumnLength ? breakpointColumnLength - 1 : column.span[1];

          column.span = [columnZeroIndexValue, columnOneIndexValue];
        }
      });
    });
  }

  sendAnalytics(gridParam) {
    try {
      const {grid = {}, count = {}} = this.props;
      const {capacity: prevCapacity, page: prevPage, totalRows, columns, columnIds} = grid;
      const {id, capacity: defaultCapacity} = grid.settings || {};

      const params = Object.entries(gridParam);
      let [key, value] = params[0];
      let eventName = key;
      const isSubset = count.matched > totalRows;
      const analyticsData = {id};

      switch (key) {
        case 'capacity':
          analyticsData.capacity = value ?? defaultCapacity;
          analyticsData.capacityOld = prevCapacity;
          break;
        case 'page':
          const {page, lastPage} = gridParam;

          analyticsData.capacity = prevCapacity;
          analyticsData.page = page;
          analyticsData.pageOld = prevPage;

          if (isSubset && lastPage === page) {
            analyticsData.isLastInSubset = true;
          }

          break;
        case 'sort':
          if (value === null) {
            eventName = 'column.reset';
            analyticsData.action = params?.length === 2 ? 'all' : 'sort';
            break;
          }

          eventName = 'column.sort';

          let sortOrder = 'asc';

          // column name comes back with leading '-' when sorting in desc order
          if (value[0] === '-' && value.length > 1) {
            value = value.substring(1);
            sortOrder = 'desc';
          }

          if (isSubset) {
            analyticsData.isSubset = true;
          }

          if (columns.get(value).optional === true) {
            analyticsData.isOptional = true;
          }

          analyticsData.column = value;
          analyticsData.order = sortOrder;
          break;
        case 'columns':
          if (value === null && !gridParam.columnId) {
            eventName = 'column.reset';
            analyticsData.action = params?.length === 2 ? 'all' : 'columns';
            break;
          }

          eventName = 'column.toggle';

          const {columns: newColumns, columnId, action} = gridParam;

          if (columns.get(columnId).optional === true) {
            analyticsData.isOptional = true;
          }

          // selecting all column visibilities sets columns prop to null
          analyticsData.length = newColumns?.length ?? columnIds.allColumnIds?.length;
          analyticsData.column = columnId;
          analyticsData.action = action;
          break;
      }

      this.context.sendAnalyticsEvent(`grid.${eventName}`, analyticsData);
    } catch (error) {
      console.warn('Uncaught error in sendAnalytics', error);
    }
  }

  render() {
    const {inlineSize = true} = this.props;
    const containerClassName = cx(this.state.theme.gridContainer, {
      [stylesUtils.containerWidth]: !inlineSize,
    });

    return (
      <SizeWatcher
        breakpoints={this.state.breakpoints}
        onSizeChange={this.handleSizeChange}
        className={containerClassName}
        offset={this.props.offset}
        type={StickyContainer}
        data-tid={tidUtils.getTid('comp-grid', this.props.tid)}
        ref={this.ref}
      >
        {(receivedBreakpoint, size) => {
          console.log('%cGRID RENDER', 'color:red;', `${size.width}px`);
          this.computeBreakpoint(receivedBreakpoint);

          const className = this.computeClassName();
          const {
            breakpoint,
            state: {selectedKeySet, theme},
            props: {
              grid,
              extraPropsKeyMap,
              dontHighlightSelected,
              onClick,
              onReorder,
              onReorderEnd,
              onMouseOver,
              onMouseLeave,
              count,
            },
          } = this;
          const style = {};

          if (className !== this.className) {
            this.className = className;
          }

          style.gridTemplateColumns = breakpoint.template;

          let selectableAll = false;
          let selectedAll = true;

          const bodyRows = grid.rows.map((row, index) => {
            const selected = selectedKeySet.has(row.key);
            const extraProps = extraPropsKeyMap?.get(row.key);

            if (!selectableAll && row.selectable && !row.disableCheckbox) {
              selectableAll = true;
            }

            if (selectedAll && row.selectable && !selected && !row.disableCheckbox) {
              selectedAll = false;
            }

            return (
              <BodyRow
                index={index}
                grid={grid}
                key={row.key}
                row={row}
                breakpoint={breakpoint}
                extraProps={extraProps}
                theme={theme}
                selected={selected}
                dontHighlightSelected={dontHighlightSelected}
                onSelect={this.handleSelect}
                onClick={onClick}
                onReorder={onReorder}
                onReorderEnd={onReorderEnd}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                component={this.props.component}
              />
            );
          });

          return (
            <>
              <Manager
                grid={grid}
                breakpoint={breakpoint}
                count={count}
                selectedKeySet={selectedKeySet}
                theme={theme}
                themePrefix="manager-"
                onChange={this.handleChange}
                offset={this.props.offset}
              />
              <div className={className} style={style}>
                <HeadRow
                  grid={grid}
                  breakpoint={breakpoint}
                  theme={theme}
                  offset={this.props.offset}
                  selectableAll={selectableAll}
                  selectedAll={selectedAll}
                  onSelectAll={this.handleSelectAll}
                  onCellClick={this.handleChange}
                  component={this.props.component}
                />
                {bodyRows}
              </div>
              {grid.rows.length === 0 && this.props.emptyMessage !== '' && (
                <div className={theme.emptyMessage} data-tid="comp-grid-row-empty">
                  <Banner subText={this.props.addItemLink} type="plain">
                    {this.props.emptyMessage}
                  </Banner>
                </div>
              )}
            </>
          );
        }}
      </SizeWatcher>
    );
  }
}

// Transform grid configuration into breakpoints
function computeBreakpointsFromGridConfig(templates, nextProps) {
  return templates.map(item => {
    if (Array.isArray(item)) {
      return {data: {template: [...item]}};
    }

    const {minWidth, maxWidth, minHeight, maxHeight, props, ...data} = item;
    const {columns} = nextProps.grid;

    if (process.env.NODE_ENV === 'development') {
      if (!Array.isArray(data.template) && typeof data.template !== 'function') {
        throw new TypeError(
          `Template property in grid should be an array or function, but ${typeof data.template} instead`,
        );
      }
    }

    data.template =
      typeof data.template === 'function' ? data.template.call(nextProps.component, columns) : data.template;

    return {minWidth, maxWidth, minHeight, maxHeight, props, data};
  });
}
