/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {
  Component,
  createElement,
  createRef,
  type MutableRefObject,
  type ComponentPropsWithRef,
  type ReactElement,
  type AriaAttributes,
  type RefObject,
} from 'react';
import {generalUtils} from '@illumio-shared/utils/shared';
import {domUtils, reactUtils, tidUtils, typesUtils} from '@illumio-shared/utils';
import {composeThemeFromProps, type Theme, type ThemeProps} from '@css-modules-theme/react';
import MenuDropdown from './MenuDropdown';
import styles from './Menu.css';
import type {MenuItemsProps} from './MenuItems';

type FocusItem = 'first' | 'last';

export type MenuProps = Pick<AriaAttributes, 'aria-busy' | 'aria-disabled' | 'aria-live'> &
  ThemeProps & {
    /** You should only supply MenuItems or Delimiter as children */
    children?: MenuItemsProps['children'];

    label?: typesUtils.ReactStrictNode;
    labelProps?: Record<string, unknown>;

    triggerOnHover?: boolean; // Open on trigger button hover
    triggerOnHoverOpenDebounce?: number; // Delay between hover and opening
    triggerOnHoverCloseTimeout?: number; // Delay between menu unhover and closing
    triggerOnFocusOpenDebounce?: number; // Delay between focus and opening
    triggerOnFocusCloseTimeout?: number; // Delay between blur and closing
    focusItemOnOpen?: FocusItem;
    preventParentAnimate?: boolean;
    keepParentOpen?: boolean;
    noParentScrollContainer?: boolean;
    openOnLoad?: boolean;

    iconBefore?: ReactElement;
    icon?: ReactElement;
    tid?: string;

    disabled?: boolean;

    /** Makes trigger not interactive (not clickable, not tabbable) */
    insensitive?: boolean;

    /**
     * When we open the menu we can prevent the parent scrollable container from being scrolled (lock),
     * or make the menu auto close on scroll ('close'), or do nothing ('none')
     */
    scrollParentBehavior?: 'close' | 'lock' | 'none';

    /**
     * Add to trigger's classes when menu is open.
     * For example, is used on button menu to apply 'active' state on trigger when the menu is opened
     */
    openedTriggerTheme?: string;

    /** A custom target reference instead of the default <nav> element */
    reference?: MutableRefObject<HTMLElement | null>;

    /** Whether add an arrow pointer or not. By default, will point to the `reference` prop or its own <nav> */
    arrow?: boolean;

    /** An optional reference to the element, the middle of which the arrow will point to */
    arrowPointsTo?: MutableRefObject<HTMLElement | null>;

    /** How to align the dropdown, right, or maybe left */
    align?: 'end' | 'start';

    /** Custom element to be aligned with, can be something inside, like an icon. By default, reference or own <nav> */
    alignRelativeTo?: MutableRefObject<HTMLElement | null>;

    // Will be called before opening, can return Promise which will be waited
    onOpen?: () => void;
    onClose?: () => void;

    // On click callback - currently used to remove focus (blur) from the div wrapper in Menu from parent Button
    onClick?: (evt: MouseEvent) => void;
  };

type MenuPropsIn = Readonly<reactUtils.WithDefaultProps<MenuProps, typeof Menu.defaultProps>>;
type MenuState = Readonly<{
  open: boolean;
  focusItemOnOpen?: FocusItem;
  theme: Theme;
}>;

const defaultTid = 'comp-menu';

// don't use _.pick because _.pick doesn't work with identity-obj-proxy used in testing mock for css modules
const themable = {
  // Styles for menu itself
  menu: styles.menu,
  trigger: styles.trigger,
  openedTrigger: styles.openedTrigger,
  triggerLabel: styles.triggerLabel,
  // Styles for dropdown
  dropdown: styles.dropdown,
  subDropdown: styles.subDropdown,
  dropdownActive: styles.dropdownActive,
  dropdownWithArrow: styles.dropdownWithArrow,
  dropdownUp: styles.dropdownUp,
  // Styles for items list
  itemsContainer: styles.itemsContainer,
  itemsExtender: styles.itemsExtender,
  itemsList: styles.itemsList,
  itemContent: styles.itemContent,
  itemsListActive: styles.itemsListActive,
  menuInfo: styles.menuInfo,
};

/**
 * Menu with mouse/key/tab navigation and optional open/close on hover
 */
export default class Menu extends Component<MenuPropsIn, MenuState> {
  static defaultProps = {
    arrow: false,
    align: 'start',
    insensitive: false,
    triggerOnHover: false,
    triggerOnHoverOpenDebounce: 0,
    triggerOnHoverCloseTimeout: 500,
    triggerOnFocusOpenDebounce: 0,
    triggerOnFocusCloseTimeout: 0,
    scrollParentBehavior: 'lock',
    preventParentAnimate: false,
    keepParentOpen: false,
    openOnLoad: false,
  };

  id: string;
  navRef: RefObject<HTMLInputElement>;
  parentScrollContainer: HTMLElement | null;

  triggerRef: RefObject<HTMLInputElement>;
  focusTriggerOnClose?: boolean;

  dropdown: MenuDropdown | null = null;

  blurTimeout?: number;
  focusTimeout?: number;
  mouseEnterTimeout?: number;
  mouseLeaveTimeout?: number;
  dropdownTimeout?: number;

  constructor(props: MenuPropsIn) {
    super(props);

    this.state = {open: false, focusItemOnOpen: props.focusItemOnOpen, theme: composeThemeFromProps(themable, props)};
    this.id = generalUtils.randomString(6, true);
    this.navRef = createRef();
    this.triggerRef = createRef();
    this.parentScrollContainer = null;

    this.saveDropdownRef = this.saveDropdownRef.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    if (props.triggerOnHover) {
      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
      this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    this.handleTriggerClick = this.handleTriggerClick.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleParentScrollToClose = this.handleParentScrollToClose.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  static getDerivedStateFromProps(nextProps: MenuPropsIn) {
    return {theme: composeThemeFromProps(themable, nextProps)};
  }

  componentDidMount() {
    const {insensitive, openOnLoad} = this.props;

    if (!insensitive) {
      this.attachEvents();
    }

    if (openOnLoad) {
      this.open();
    }
  }

  shouldComponentUpdate(nextProps: MenuPropsIn, nextState: MenuState) {
    return this.state.open !== nextState.open || !generalUtils.shallowEqual(nextProps, this.props);
  }

  componentDidUpdate(prevProps: MenuPropsIn, prevState: MenuState) {
    if (!prevState.open && this.state.open) {
      this.manageParentScroll();
    } else if (prevState.open && !this.state.open) {
      this.unmanageParentScroll();

      if (this.props.onClose) {
        this.props.onClose();
      }

      if (this.focusTriggerOnClose) {
        this.getTargetElement()?.focus();
        this.focusTriggerOnClose = false;
      }
    }

    // If we switch 'insensitive' prop, attach/detach the events
    if (this.props.insensitive && !prevProps.insensitive) {
      this.detachEvents();
    } else if (!this.props.insensitive && prevProps.insensitive) {
      this.attachEvents();
    }
  }

  componentWillUnmount() {
    this.detachEvents();
    this.clearTimeouts();

    if (this.state.open) {
      this.unmanageParentScroll();
    }
  }

  private getTargetElement(): HTMLElement | null {
    return this.props.reference?.current ?? this.triggerRef.current;
  }

  private saveDropdownRef(dropdown: MenuDropdown) {
    this.dropdown = dropdown;
  }

  private handleKeyDown(evt: KeyboardEvent) {
    switch (evt.key) {
      case 'Escape':
        evt.stopPropagation();

        if (this.state.open) {
          this.close(true);
        }

        break;
      case ' ':
        evt.preventDefault();
        evt.stopPropagation();
        break;
      case 'Enter':
        evt.preventDefault();
        this.toggle();
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        evt.preventDefault();
        evt.stopPropagation();

        if (!this.state.open) {
          this.open('last');
        } else if (this.dropdown) {
          this.dropdown.scopeFocus('last');
        }

        break;
      case 'ArrowDown':
      case 'ArrowRight':
        evt.preventDefault();
        evt.stopPropagation();

        if (!this.state.open) {
          this.open('first');
        } else if (this.dropdown) {
          this.dropdown.scopeFocus('first');
        }

        break;
      // no default
    }
  }

  private handleTriggerClick(evt: MouseEvent) {
    this.props.onClick?.(evt);
  }

  private handleKeyUp(evt: KeyboardEvent) {
    if (evt.key === ' ') {
      this.toggle();
    }
  }

  private handleFocus() {
    this.clearTimeouts();

    // If the current focus is the result of closing (like no Esc), do not open again
    if (this.focusTriggerOnClose) {
      return;
    }

    this.focusTimeout = window.setTimeout(this.open, this.props.triggerOnFocusOpenDebounce ?? 0);
  }

  private handleBlur() {
    this.clearTimeouts();

    // When the blur event is triggered, `document.activeElement` is not yet updated,
    // so need to schedule in next task, even if the timeout is zero, to check for correct `document.activeElement`
    this.blurTimeout = window.setTimeout(() => {
      if (!this.dropdown?.dropdown?.contains(document.activeElement)) {
        this.close();
      }
    }, this.props.triggerOnFocusCloseTimeout ?? 0);
  }

  private handleMouseEnter(evt: MouseEvent | React.MouseEvent) {
    this.clearTimeouts();

    if (!this.state.open) {
      const element = this.getTargetElement();

      if (evt.target && evt.target === element) {
        element.addEventListener('mousemove', this.handleMouseMove);
      } else {
        this.handleMouseMove();
      }
    }
  }

  private handleMouseMove() {
    // Open on hover when mousemove stops, like debounce
    this.clearTimeouts();

    if (this.props.triggerOnHoverOpenDebounce) {
      this.mouseEnterTimeout = window.setTimeout(this.open, this.props.triggerOnHoverOpenDebounce, false, true);
    } else {
      this.open();
    }
  }

  private handleMouseLeave() {
    this.clearTimeouts();

    const closeTimeout = this.props.triggerOnHoverCloseTimeout;

    if (closeTimeout) {
      this.mouseLeaveTimeout = window.setTimeout(this.close, closeTimeout);
    } else {
      this.close();
    }
  }

  private handleMouseDown(evt: MouseEvent) {
    const element = this.getTargetElement();

    // Prevent putting focus on trigger button if it's not focuesd yet,
    // to save/restore focus on currently focused element on dropdown closing
    evt.preventDefault();

    if (!this.state.open) {
      // If user wants to open menu, focus trigger to remove focus from other element and blur it to remove glow
      element?.focus();
      element?.blur();
    } else if (evt.target === element || element?.contains(evt.target as Node)) {
      // If user clicks on trigger button while menu is opened,
      // it should be closed from this Menu component side (by click event),
      // thus we need prevent mouseDown handling on document in MenuDropdown component
      evt.stopImmediatePropagation();
    }

    this.toggle();
  }

  private handleParentScrollToClose() {
    if (this.state.open) {
      this.close();
    }
  }

  private attachEvents() {
    const {triggerOnHover, onClick, insensitive} = this.props;
    const element = this.getTargetElement();

    if (element && !insensitive) {
      element.addEventListener('keyup', this.handleKeyUp);
      element.addEventListener('keydown', this.handleKeyDown);
      element.addEventListener('mousedown', this.handleMouseDown);

      if (onClick) {
        element.addEventListener('click', this.handleTriggerClick);
      }

      if (triggerOnHover) {
        element.addEventListener('blur', this.handleBlur);
        element.addEventListener('focus', this.handleFocus);
        element.addEventListener('mouseenter', this.handleMouseEnter);
        element.addEventListener('mouseleave', this.handleMouseLeave);
      }
    }
  }

  private detachEvents() {
    const element = this.getTargetElement();

    if (element) {
      element.removeEventListener('click', this.handleTriggerClick);
      element.removeEventListener('keyup', this.handleKeyUp);
      element.removeEventListener('keydown', this.handleKeyDown);
      element.removeEventListener('mousedown', this.handleMouseDown);
      element.removeEventListener('mouseover', this.handleMouseEnter);
      element.removeEventListener('mouseout', this.handleMouseLeave);
    }
  }

  private manageParentScroll() {
    const element = this.getTargetElement();
    const {parent} = domUtils.getScrollParentAndOffsetElements(element!);

    this.parentScrollContainer = parent;

    this.dropdownTimeout = window.setTimeout(() => {
      if (this.dropdown?.dropdown?.scrollIntoView) {
        this.dropdown.dropdown.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }

      if (this.props.scrollParentBehavior === 'lock') {
        this.lockParentScroll();
      } else if (this.props.scrollParentBehavior === 'close') {
        this.listenParentScrollToClose();
      }
    }, 0);
  }

  private unmanageParentScroll() {
    window.clearTimeout(this.dropdownTimeout);

    if (this.props.scrollParentBehavior === 'lock') {
      this.unlockParentScroll();
    } else if (this.props.scrollParentBehavior === 'close') {
      this.unlistenParentScrollToClose();
    }
  }

  private lockParentScroll() {
    const parentScrollContainer = this.parentScrollContainer!;
    const menuOpenValue = parentScrollContainer.dataset.menuOpen;

    if (menuOpenValue) {
      parentScrollContainer.dataset.menuOpen = `${menuOpenValue},${this.id}`;
    } else {
      parentScrollContainer.dataset.menuOpen = this.id;
      parentScrollContainer.classList.add('utils_scrollLock');
    }
  }

  private unlockParentScroll() {
    const parentScrollContainer = this.parentScrollContainer!;
    const menuOpenValue = parentScrollContainer.dataset.menuOpen;

    if (menuOpenValue) {
      const menuOpenResult = menuOpenValue
        .split(',')
        .filter(item => item !== this.id)
        .join(',');

      if (menuOpenResult) {
        parentScrollContainer.dataset.menuOpen = menuOpenResult;
      } else {
        parentScrollContainer.classList.remove('utils_scrollLock');
        delete parentScrollContainer.dataset.menuOpen;
      }
    }
  }

  private listenParentScrollToClose() {
    const parentScrollContainer =
      this.parentScrollContainer === document.documentElement ? window : this.parentScrollContainer!;

    parentScrollContainer.addEventListener('scroll', this.handleParentScrollToClose, {passive: true});
  }

  private unlistenParentScrollToClose() {
    const parentScrollContainer =
      this.parentScrollContainer === document.documentElement ? window : this.parentScrollContainer!;

    parentScrollContainer.removeEventListener('scroll', this.handleParentScrollToClose);
  }

  private clearTimeouts() {
    window.clearTimeout(this.blurTimeout);
    window.clearTimeout(this.focusTimeout);
    window.clearTimeout(this.mouseEnterTimeout);
    window.clearTimeout(this.mouseLeaveTimeout);
    this.mouseEnterTimeout = this.mouseLeaveTimeout = this.focusTimeout = this.blurTimeout = undefined;
  }

  async open(focusItemOnOpen?: FocusItem /*, byTriggerHover*/): Promise<void> {
    if (this.props.onOpen) {
      await this.props.onOpen();
    }

    this.getTargetElement()?.removeEventListener('mousemove', this.handleMouseMove);

    this.setState(state => ({open: true, focusItemOnOpen: focusItemOnOpen || state.focusItemOnOpen}));
  }

  close(focusTriggerOnClose?: boolean): void {
    // Trigger button should be focused
    // if user opened menu without having focus on any other element and is closing menu by pressing Esc
    this.focusTriggerOnClose = focusTriggerOnClose;

    // Reset focused item to props value
    if (!this.props.keepParentOpen) {
      this.setState({open: false, focusItemOnOpen: this.props.focusItemOnOpen});
    }
  }

  toggle(): void {
    this.clearTimeouts();

    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  private renderDropdown() {
    let parent = null;

    if (!this.props.noParentScrollContainer) {
      const element = this.getTargetElement();

      if (element) {
        parent = domUtils.getScrollParentAndOffsetElements(element).parent;
      }
    }

    return (
      <MenuDropdown
        key="dropdown"
        active={this.state.open}
        ref={this.saveDropdownRef}
        anchorRef={this.props.reference ?? this.triggerRef}
        align={this.props.align}
        alignRelativeTo={this.props.alignRelativeTo}
        reference={this.props.reference}
        triggerOnHover={this.props.triggerOnHover}
        arrowRef={this.props.arrowPointsTo ?? this.props.reference ?? this.triggerRef}
        theme={this.state.theme}
        arrow={this.props.arrow}
        focusItemOnOpen={this.state.focusItemOnOpen}
        // eslint-disable-next-line react/jsx-handler-names
        onClose={this.close}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        insensitive={this.props.insensitive}
        keepParentOpen={this.props.keepParentOpen}
        preventParentAnimate={this.props.preventParentAnimate}
        parentScrollContainer={parent}
      >
        {this.props.children}
      </MenuDropdown>
    );
  }

  render() {
    const {
      props,
      props: {disabled, insensitive, label, reference},
      state: {theme},
    } = this;

    const dropdown = this.renderDropdown();

    // If the parent is passing a reference element, render only the dropdown
    if (reference) {
      return dropdown;
    }

    const tids = [props.tid];

    if (disabled) {
      tids.push('disabled');
    }

    const containerProps: ComponentPropsWithRef<'nav'> = {
      'className': theme.menu,
      'data-tid': tidUtils.getTid(defaultTid, tids),
      'aria-disabled': disabled ?? props['aria-disabled'],
    };

    if (!insensitive && props.triggerOnHover) {
      containerProps.onMouseEnter = this.handleMouseEnter;
      containerProps.onMouseLeave = this.handleMouseLeave;
    }

    const triggerProps: ComponentPropsWithRef<'div'> = {
      'ref': this.triggerRef,
      'className': this.state.open ? cx(theme.trigger, theme.openedTrigger, props.openedTriggerTheme) : theme.trigger,
      'tabIndex': insensitive ? -1 : 0,
      'role': 'button',
      // Show data-tid as 'triggered' when menu is open for QA
      'data-tid': `${defaultTid}-trigger${this.state.open ? 'ed' : ''}`,
      'aria-disabled': disabled ?? props['aria-disabled'],
      'aria-expanded': this.state.open ? 'true' : 'false',
      'aria-live': props['aria-live'] ?? 'polite',
      'aria-busy': props['aria-busy'],
    };

    return (
      <nav {...containerProps} ref={this.navRef}>
        <div {...triggerProps}>
          {props.iconBefore}
          {label
            ? createElement(
                'span',
                {
                  ...props.labelProps,
                  'className': theme.triggerLabel,
                  'data-tid': 'comp-menu-title',
                },
                ...(Array.isArray(label) ? label : [label]),
              )
            : null}
          {props.icon}
        </div>
        {dropdown}
      </nav>
    );
  }
}
