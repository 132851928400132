/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {mapQueryNamePrefix} from 'containers/IlluminationMap/Filter/MapFilterConstants';
import intl from '@illumio-shared/utils/intl';

export const createAppGroupName = (labelIds, labels, labelTypes) =>
  labelTypes.map(type => labels[labelIds.find(id => labels[id]?.label?.key === type)]?.label?.value).join(' | ');

export const createAppGroupLabelsQuery = (labelIds, labels) =>
  labelIds.map(id => ({label: {href: labels[id]?.label?.href}}));

export const autoDefaultGraphReportNamePattern = new RegExp(
  `${mapQueryNamePrefix}${intl('IlluminationMap.DefaultGraph')}-\\d+`,
);
