/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select, all, take} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {gridSettings} from './EventsListConfig';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {fetchSelectiveVens} from 'containers/Ven/VenSaga';
import {
  getEventsList,
  getEventUserMap,
  getEventsCount,
  getIsEventsResultShowing,
  getFilterMap,
} from './EventsListState';
import {handleExportResponse} from './EventsListUtils';
import gridSaga from 'components/Grid/GridSaga';
import {isEventsEnabled} from '../EventsState';
import {errorUtils} from '@illumio-shared/utils';

export function* fetchEventLists({filter, force = false} = {}) {
  // queryMaxDefault will be used for exportAll thus cannot assign a reference to prevent
  // mutable assignment in the filter logic below

  const query = {max_results: 1000};

  const userMap = yield select(getEventUserMap);

  // filter: Object Selector filtering, etc... e.g. filter = { event_type: ["API Key Created"] }
  if (filter) {
    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        if (name === 'event_type') {
          query[name] = value.value;
        } else if (name === 'created_by') {
          query[name] = userMap[value.username];
        } else if (name === 'vens' && value.href.includes('agents')) {
          query.created_by = value.href;
        } else if (name === 'severity' || name === 'status') {
          query[name] = value.id;
        } else {
          if (value.from) {
            query[`${name}[gte]`] = value.from;
          }

          if (value.to) {
            query[`${name}[lte]`] = value.to;
          }
        }
      }
    }
  }

  return yield call(apiSaga, 'events.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getEventsList)) || count !== (yield select(getEventsCount))) {
        yield put({type: 'EVENTS_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* fetchingAllExport() {
  const query = {max_results: 1000};
  // Don't need to save result to redux store
  const {data: list} = yield call(apiSaga, 'events.get_collection', {query});

  handleExportResponse(list);
}

export function* watchingExportAll() {
  while (true) {
    yield take('EVENTS_EXPORT_ALL');

    yield call(fetchingAllExport);
  }
}

export function* watchingExport() {
  while (true) {
    // get export data and update the redux store
    yield take('EVENTS_EXPORT');

    const list = yield select(getEventsList);

    handleExportResponse(list);
  }
}

export function* eventsWatchers() {
  yield all([watchingExport(), watchingExportAll()]);
}

export function* fetchEventList(route, refetch = false) {
  const eventsIsEnabled = yield select(isEventsEnabled);

  if (!eventsIsEnabled) {
    throw new errorUtils.RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(fetchAllUsers, {force: refetch});

  yield call(gridSaga, {
    route,
    settings: gridSettings,
    filterMap: getFilterMap,
    *onSaga({filterParams}) {
      if (yield select(getIsEventsResultShowing)) {
        const {list} = yield call(fetchEventLists, {
          filter: filterParams.isEmpty ? undefined : filterParams.valid,
          force: refetch,
        });

        const {agents, vens} = list.reduce(
          (result, event) => {
            const {ven, agent} = event.created_by ?? {};

            if (ven?.hostname || agent?.hostname) {
              return result;
            }

            if (ven) {
              result.vens.push(ven);
            } else if (agent) {
              result.agents.push(agent);
            }

            return result;
          },
          {agents: [], vens: []},
        );

        if (vens.length) {
          yield call(fetchSelectiveVens, vens, refetch, false); //Agent=false orgs/:xorg_id/vens/:ven_uuid
        }

        if (agents.length) {
          yield call(fetchSelectiveVens, agents, refetch, true); // Agent=true orgs/:xorg_id/agents/:agent_id
        }

        return list.length;
      }
    },
  });
}
