/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import _ from 'lodash';
import {produce} from 'immer';
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {call} from 'redux-saga/effects';
import {isKubernetesSupported} from 'containers/App/AppState';
import {Button, TypedMessages, Pill, Icon, Modal} from 'components';
import ServiceEdit from 'containers/Service/Item/Edit/ServiceEdit';
import {domUtils} from '@illumio-shared/utils';
import {validatePortAndOrProtocol, lookupRegexPortProtocol} from 'containers/Service/ServiceUtils';
import {fetchAllServicesHref} from 'containers/Service/List/ServiceListSaga';
import {fetchAnyIPList} from 'containers/IPList/Item/IPListItemSaga';
import Selector from 'containers/Selector/Selector';
import {categorySuggestionRegex} from 'containers/Selector/SelectorUtils';
import tooltipStyles from 'components/Tooltip/Tooltip.css';
import {fetchPolicyServices, fetchConsumerPolicyServices} from '../RulesetItemSaga';
import {resourceType} from 'containers/Ruleset/List/RulesetListConfig';

import styles from '../RulesetItem.css';
import styleUtils from 'utils.css';

export const resourceNames = createSelector([], () => ({
  labels_include: intl('Rulesets.Rules.LabelAndLabelGroups'),
  labels_exclude: `${intl('PolicyGenerator.Excluded')} ${intl('Rulesets.Rules.LabelAndLabelGroups')}`,
  ip_list: intl('Common.IPLists'),
  workload: intl('Common.Workloads'),
  ...(isKubernetesSupported && {
    virtual_service: intl('Common.VirtualServices'),
    usesVirtualServicesAndWorkloads: `'${intl('Common.UsesVirtualServicesWorkloads')}'`,
    usesVirtualServicesOnly: `'${intl('Common.UsesVirtualServices')}'`,
  }),
  ...(!__ANTMAN__ && {virtual_server: intl('Common.VirtualServers')}),
  use_workload_subnets: `'${intl('Rulesets.Rules.UseWorkloadSubnets')}'`,
  container_host: `'${intl('Common.ContainerHost')}'`,
  consuming_security_principals: intl('Common.UserGroups'),
  allWorkloads: `'${intl('Workloads.All')}'`,
  anyIp: `'${intl('IPLists.Any')}'`,
  services: intl('Services.PolicyServices'),
  ports: intl('Rulesets.Rules.PortOrPortRange'),
  allServices: `'${intl('Common.AllServices')}'`,
}));

const redundantResources = {
  labels_include: ['allWorkloads', 'container_host'],
  labels_exclude: [
    'allWorkloads',
    'workload',
    'container_host',
    ...(isKubernetesSupported ? ['virtual_service', 'usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
    ...(__ANTMAN__ ? [] : ['virtual_server']),
  ],
  allWorkloads: [
    'labels_include',
    'labels_exclude',
    'workload',
    'container_host',
    ...(isKubernetesSupported ? ['virtual_service'] : []),
    ...(__ANTMAN__ ? [] : ['virtual_server']),
  ],
  anyIp: [
    'ip_list',
    'use_workload_subnets',
    'container_host',
    'consuming_security_principals',
    ...(isKubernetesSupported ? ['usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
  ],
  ...(isKubernetesSupported && {
    usesVirtualServicesAndWorkloads: [
      'labels_exclude',
      'workload',
      'ip_list',
      'anyIp',
      'use_workload_subnets',
      'container_host',
      'virtual_service',
      'virtual_server',
      'usesVirtualServicesOnly',
    ],
    usesVirtualServicesOnly: [
      'labels_exclude',
      'workload',
      'ip_list',
      'anyIp',
      'use_workload_subnets',
      'container_host',
      'usesVirtualServicesAndWorkloads',
    ],
  }),
  ip_list: [
    'anyIp',
    'use_workload_subnets',
    'container_host',
    'consuming_security_principals',
    ...(isKubernetesSupported ? ['usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
  ],
  workload: [
    'labels_exclude',
    'allWorkloads',
    'labels_exclude',
    'use_workload_subnets',
    'container_host',
    ...(isKubernetesSupported ? ['usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
  ],
  ...(isKubernetesSupported && {
    virtual_service: [
      'allWorkloads',
      'usesVirtualServicesAndWorkloads',
      'use_workload_subnets',
      'labels_exclude',
      'container_host',
    ],
  }),
  ...(!__ANTMAN__ && {
    virtual_server: ['usesVirtualServicesAndWorkloads', 'use_workload_subnets', 'labels_exclude', 'container_host'],
  }),
  use_workload_subnets: [
    'workload',
    'ip_list',
    'anyIp',
    'container_host',
    'consuming_security_principals',
    ...(isKubernetesSupported ? ['virtual_service', 'usesVirtualServicesAndWorkloads', 'usesVirtualServicesOnly'] : []),
    ...(__ANTMAN__ ? [] : ['virtual_server']),
  ],
  services: ['allServices'],
  ports: ['allServices'],
  allServices: ['services', 'ports'],
};

const stickyResourceConfig = {
  sticky: true,
  optionProps: {
    isPill: true,
    noFilter: true,
  },
  selectedProps: {hideResourceName: true},
};

const endpointHistoryProps = {
  enableHistory: true,
  historyKey: 'endpoint',
};

// eslint-disable-next-line no-underscore-dangle
const getParentTippyInstance = evt => evt.target.closest(`.${tooltipStyles.tooltip}`).parentElement._tippy;

const handleDoneTooltip = (evt, {onSelect, option, resource}) => {
  const confirmedValue = {...(typeof option === 'string' ? {value: option} : option), confirmed: true};

  const tippyInstance = getParentTippyInstance(evt);

  tippyInstance?.hide();
  onSelect(evt, {resourceId: resource.id, value: confirmedValue});
};

const handleCancelTooltip = evt => {
  // eslint-disable-next-line no-underscore-dangle
  const tippyInstance = evt.target.closest(`.${tooltipStyles.tooltip}`).parentElement._tippy;

  tippyInstance?.hide();
};

const getWarningContent = (message, {option, resource, onSelect}) => (
  <div>
    <TypedMessages>
      {[
        {
          icon: 'warning',
          content: message,
        },
      ]}
    </TypedMessages>
    <div className={styles.buttons}>
      <Button size="small" text="Yes" onClick={_.partial(handleDoneTooltip, _, {onSelect, resource, option})} />
      <Button color="standard" size="small" text="No" onClick={handleCancelTooltip} />
    </div>
  </div>
);

export const isResourceSelected = (resourceId, values) => {
  const labelExcludeIsRedundant = resourceId === 'labels_exclude';
  const labelIncludeIsRedundant = resourceId === 'labels_include';

  if (labelExcludeIsRedundant || labelIncludeIsRedundant) {
    return [...values.keys()].some(key => key.includes(labelExcludeIsRedundant ? 'exclude' : 'include'));
  }

  return values.has(resourceId);
};

const tooltipProps = {
  trigger: 'click',
  visible: undefined,
  interactive: true,
  flipBehavior: ['right', 'left', 'top', 'bottom'],
  onShow: ({popper}) => {
    popper.focus();
    setTimeout(() => popper.querySelector('button')?.focus(), 500);
  },
  content: options => {
    const {option, values, resource} = options;
    const resourceId = option.resourceId ?? resource.id;
    const redundantSelections = redundantResources[resourceId];

    if (!redundantSelections?.length) {
      return null;
    }

    const replaceResources = [
      ...redundantSelections.reduce((result, id) => {
        if (isResourceSelected(id, values)) {
          result.add(id);
        }

        return result;
      }, new Set()),
    ];

    return replaceResources.length
      ? getWarningContent(
          intl('Rulesets.Rules.SelectingReplacesConfirmation', {
            resourceName: resourceNames()[resourceId],
            selectedResourcesNames: intl.list(replaceResources.map(id => resourceNames()[id])),
          }),
          options,
        )
      : null;
  },
};

const labelResourceRegex = /labels_(include|exclude)/;

const confirmationOnSelect =
  labelTypesNameObj =>
  (evt, {value, values, resource}) => {
    // normalize labels_include / labels_exclude to the label type resource id
    const redundantSelections = redundantResources[resource.id]
      ?.filter(id => isResourceSelected(id, values))
      .flatMap(id => {
        const type = labelResourceRegex.exec(id)?.[1];

        if (type) {
          return Object.keys(labelTypesNameObj).map(labelType => `${labelType}_${type}`);
        }

        return id;
      });

    if (redundantSelections?.length) {
      if (value.confirmed) {
        redundantSelections.forEach(id => values.delete(id));
      } else {
        return values;
      }
    }
  };

const resources = (labelTypesNameObj, isCSFrame = false) => ({
  allWorkloads: {
    sticky: true,
    statics: [intl('Workloads.All')],
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps,
    },
    selectedProps: {hideResourceName: true},
    onSelect: confirmationOnSelect(labelTypesNameObj),
  },
  anyIp: {
    sticky: true,
    *dataProvider() {
      const {data} = yield call(fetchAnyIPList);

      return [{href: data.href, name: intl('IPLists.Any')}];
    },
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps,
    },
    selectedProps: {hideResourceName: true},
    onSelect: confirmationOnSelect(labelTypesNameObj),
  },
  ...(isKubernetesSupported && {
    usesVirtualServicesOnly: {
      sticky: true,
      statics: [intl('Common.UsesVirtualServices')],
      optionProps: {
        isPill: true,
        noFilter: true,
        tooltipProps,
      },
      selectedProps: {hideResourceName: true},
      onSelect: confirmationOnSelect(labelTypesNameObj),
    },
    usesVirtualServicesAndWorkloads: {
      sticky: true,
      statics: [intl('Common.UsesVirtualServicesWorkloads')],
      optionProps: {
        isPill: true,
        noFilter: true,
        tooltipProps,
      },
      selectedProps: {hideResourceName: true},
      onSelect: confirmationOnSelect(labelTypesNameObj),
    },
  }),
  use_workload_subnets: {
    ...stickyResourceConfig,
    statics: [intl('Rulesets.Rules.UseWorkloadSubnets')],
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps,
    },
    selectedProps: {hideResourceName: true},
    onSelect: confirmationOnSelect(labelTypesNameObj),
  },
  container_host: {
    ...stickyResourceConfig,
    statics: [intl('Common.ContainerHost')],
    optionProps: {
      isPill: true,
      noFilter: true,
      tooltipProps: {
        ...tooltipProps,
        content: options => {
          if (options.values.size > 0) {
            return getWarningContent(intl('Rulesets.Rules.SelectingContainerHostConfirmation'), options);
          }
        },
      },
    },
    selectedProps: {hideResourceName: true},
    onSelect: (evt, {value, values}) => {
      if (values.size > 0) {
        if (value.confirmed) {
          values.clear();
        } else {
          return values;
        }
      }
    },
  },
  ip_list: {
    dataProvider: 'ip_lists.autocomplete',
    apiArgs: {query: {max_results: isCSFrame ? 50 : 25}, params: {pversion: 'draft'}},
    optionProps: {
      filterOption: (option, values) =>
        option.href?.includes('ip_lists') &&
        option.name !== intl('IPLists.Any') &&
        !values.get('ip_list')?.some(({href}) => href === option.href),
      allowMultipleSelection: true,
      isPill: true,
      pillProps: ({option}) => ({icon: option.fqdn ? 'domain' : 'allowlist'}),
      tooltipProps,
    },
    onSelect: confirmationOnSelect(labelTypesNameObj),
  },
});

const getEndpointLabelModifier = (advanced, labelTypesNameObj, queryKeywordsRegex, exclusion) => ({
  ...endpointHistoryProps,
  queryKeywordsRegex,
  onSelect: (evt, options = {}) => {
    const redundantResourceResult = confirmationOnSelect(labelTypesNameObj)(evt, options);

    if (redundantResourceResult) {
      return redundantResourceResult;
    }

    // Explicitly replace specific include/exclude keys
    const {values, resource} = options;
    const {confirmed, ...value} = options.value;

    const isInclude = resource.id.includes('include');

    const selectIntoResource = `${value.key}_${isInclude ? 'include' : 'exclude'}`;
    const replaceResource = `${value.key}_${isInclude ? 'exclude' : 'include'}`;

    if (values.has(replaceResource)) {
      if (confirmed) {
        values.delete(replaceResource);
      } else {
        return values;
      }
    }

    const selectedInType = values.get(selectIntoResource) ?? [];

    values.delete(selectIntoResource);

    values.set(selectIntoResource, [...selectedInType, value]);

    return values;
  },
  optionProps: {
    filterOption: (option, values, {id}) => {
      const isInclude = (option.resourceId ?? id).includes('include');

      const skipOption = isInclude ? exclusion : !exclusion || !advanced;

      if (skipOption) {
        // Exclude labels should not show in include category and vice versa
        return false;
      }

      const selectedValues = values.get(`${option.key}_${isInclude ? 'include' : 'exclude'}`);

      return option.href.includes('label') && !selectedValues?.some(({href}) => href === option.href);
    },
    tooltipProps: {
      ...tooltipProps,
      content: options => {
        const {option, values, resource} = options;
        const resourceId = resource.id === 'combined' ? option.resourceId : resource.id;
        const selectingInclude = resourceId.includes('include');

        if (values.has(`${option.key}_${selectingInclude ? 'exclude' : 'include'}`)) {
          return getWarningContent(
            intl('Rulesets.Rules.SelectingLabelConfirmation', {
              selectingInclude,
              typeName: labelTypesNameObj[option.key],
            }),
            options,
          );
        }

        return tooltipProps.content(options);
      },
    },
  },
});

const formatSelectedLabel = ({value: label, resource, disabled, theme, onRemove, highlighted, onClick}) => (
  <Pill.Label
    theme={theme}
    highlighted={highlighted}
    onClick={disabled ? undefined : onClick}
    onClose={disabled ? undefined : onRemove}
    type={label.key}
    group={label.href.includes('label_groups')}
    exclusion={resource.id.includes('exclude')}
    noContextualMenu
  >
    {label.value ?? label.name}
  </Pill.Label>
);

const getEndpointSelectedLabelModifier = ({type, warnings = {}, exclusion = false}) => ({
  ...endpointHistoryProps,
  selectedProps: {
    hideResourceName: true,
    valueJoiner: exclusion ? 'and' : 'or',
    joinerIsPill: false, // set it to false, joiner pill formatting is added in formatResource prop below
    formatValue: ({value, onRemove, onClick, disabled, highlighted}) => (
      <Pill.Label
        position="before"
        type={value.key}
        group={value.href.includes('label_groups')}
        theme={styles}
        exclusion={exclusion}
        noExclusionText
        warning={Boolean(warnings[type])}
        highlighted={highlighted}
        themePrefix="valuePill-"
        onClick={disabled ? undefined : onClick}
        onClose={disabled ? undefined : onRemove}
        noContextualMenu
      >
        {value.value || value.name}
      </Pill.Label>
    ),
    formatResource: ({valuesInResource, formatContent, onClick, onRemove, highlightedChild, theme, saveRef}) => {
      const pillProps = {
        type,
        theme: styles,
        warning: Boolean(warnings[type]),
        exclusion,
      };

      const hasSingleLabel = valuesInResource.length === 1;
      let content;

      if (hasSingleLabel) {
        const value = valuesInResource[0];

        Object.assign(pillProps, {
          group: value.href.includes('label_groups'),
          theme,
          highlighted: highlightedChild,
          onClick,
          onClose: _.partial(onRemove, _, value.href),
          ref: _.partial(saveRef, value.href),
        });

        content = value.name ?? value.value;
      } else {
        Object.assign(pillProps, {
          noAffix: true,
          hideIcon: true,
          themePrefix: 'joinerPill-',
        });

        content = (
          <div className={cx(styleUtils.gapInline, styleUtils.gapHorizontalWrap, styleUtils.gapAlignBaseline)}>
            {formatContent(valuesInResource)}
          </div>
        );
      }

      return (
        <Pill.Label {...pillProps} noContextualMenu>
          {content}
        </Pill.Label>
      );
    },
  },
});

export const advancedResourcesIds = [
  'labels_exclude',
  ...(__ANTMAN__ ? [] : ['virtual_server']),
  'consuming_security_principals',
  'virtual_service',
  'usesVirtualServicesAndWorkloads',
  'use_workload_subnets',
];

const advancedCategoriesIds = ['excludeLabels', 'virtual_service', 'virtual_server', 'consuming_security_principals'];

export const useAdvancedOptionInfoPanel = ({query, categories}) => {
  const parts = categorySuggestionRegex.test(query) ? query.split(categorySuggestionRegex) : [];
  const categoryHintText = parts[2]?.trimStart();

  if (categoryHintText) {
    const matchingCategoryId = categories.find(({name}) =>
      name?.toLowerCase().includes(categoryHintText.toLowerCase()),
    )?.id;

    // Show an info text to search in advanced options
    if (advancedCategoriesIds.includes(matchingCategoryId)) {
      return intl('Rulesets.Rules.UseAdvancedOptions');
    }
  }
};

/**
 * create the label base resource
 * @param {"exclude" | "include"} type exclude or include label
 * @param {Record<string, string>} labelTypesNameObj The mapping from label type key to label type name
 * @param {RegExp} labelTypeInitialRegExp The regexp for label type shortcut
 * @param {boolean=} allowCreate The regexp for label type shortcut
 * @returns
 */
const getLabelResources = ({
  type,
  labelTypesNameObj,
  labelTypeInitialRegExp,
  allowCreate = false,
  noResourceTypeQuery = false,
}) => {
  const labelResourceId = `labels_${type}`;

  return {
    // the labels resource that is rendered. it contain a mixture of all label types
    ...Selector.categoryPresets.labelsAndLabelGroups({
      type,
      resourceId: labelResourceId,
      labelTypesNameObj,
      labelTypeInitialRegExp,
      allowMultipleSelection: false,
      allowCreate,
      resourceType: noResourceTypeQuery ? undefined : resourceType,
    }).resources,
    // each label type has it's own hidden resource, they're used to store selected labels grouped by label types
    ...Object.keys(labelTypesNameObj).reduce((result, key) => ({...result, [`${key}_${type}`]: {hidden: true}}), {}),
  };
};

const getEndpointLabelsResources = ({
  type,
  advanced,
  warnings,
  labelTypesNameObj,
  queryKeywordsRegex,
  noResourceTypeQuery,
  isCSFrame = false,
}) => ({
  infoPanel: advanced ? null : useAdvancedOptionInfoPanel,
  hidden: type === 'exclude' && !advanced,
  resources: produce(
    getLabelResources({type, labelTypesNameObj, labelTypeInitialRegExp: queryKeywordsRegex, noResourceTypeQuery}),
    draft => {
      const labelResourceId = `labels_${type}`;

      _.merge(draft, {
        [labelResourceId]: getEndpointLabelModifier(
          advanced,
          labelTypesNameObj,
          queryKeywordsRegex,
          type === 'exclude',
        ),
        ...Object.keys(labelTypesNameObj).reduce((result, key) => {
          result[`${key}_${type}`] = getEndpointSelectedLabelModifier({
            type: key,
            warnings,
            exclusion: type === 'exclude',
          });

          return result;
        }, {}),
      });
    },
  ),
  isCSFrame,
});

/**
 * The base label category. Can be modified through `mergeProps`.
 * This is used to create
 * - the scope specified resource
 * - the endpoint (provider/consumer) specified resource
 * @param {"exclude" | "include"} type
 * @param {unknown} extra all other category properties
 */
const getLabelsCategory = (type, extra, isCSFrame = false) => {
  return {
    id: `${type}Labels`,
    name:
      type === 'exclude'
        ? intl('Rulesets.Rules.LabelAndLabelGroupsExcept')
        : isCSFrame
        ? intl('Common.Labels')
        : intl('Rulesets.Rules.LabelAndLabelGroups'),
    ...extra,
  };
};

export const getEndpointCategories = ({
  advanced = false,
  showUserGroups,
  showContainerHost,
  showVirtualServers,
  warnings,
  isDenyRule = false,
  labelTypesNameObj,
  queryKeywordsRegex,
  isCSFrame,
  isGlobalConsumer,
  type = '',
  isCSIpList,
}) => {
  const {
    allWorkloads,
    anyIp,
    usesVirtualServicesAndWorkloads,
    usesVirtualServicesOnly,
    use_workload_subnets,
    ip_list,
    container_host,
  } = resources(labelTypesNameObj, isCSFrame);

  const csCategories = new Set([
    'includeLabels',
    ...(isCSIpList ? ['ip_list'] : []),
    ...(type === 'providers' ? ['workload'] : []),
  ]);

  const categories = [
    {
      id: 'stickyCategory',
      sticky: true,
      resources: {
        allWorkloads,
        anyIp: !isCSFrame && anyIp,
        usesVirtualServicesAndWorkloads: {
          ...(!isDenyRule && usesVirtualServicesAndWorkloads),
          hidden: !advanced || (!isKubernetesSupported && !isDenyRule),
        },
        usesVirtualServicesOnly: {
          ...(!isDenyRule && usesVirtualServicesOnly),
          hidden: !advanced || (!isKubernetesSupported && !isDenyRule),
        },
        use_workload_subnets: {...(!isDenyRule && use_workload_subnets), hidden: !advanced},
        ...(showContainerHost && {container_host: {...(!isDenyRule && container_host), hidden: !advanced}}),
      },
    },
    getLabelsCategory(
      'include',
      getEndpointLabelsResources({
        type: 'include',
        warnings,
        advanced,
        labelTypesNameObj,
        queryKeywordsRegex,
        noResourceTypeQuery: isGlobalConsumer,
      }),
      isCSFrame,
    ),
    ...(__ANTMAN__ || isDenyRule
      ? []
      : [
          getLabelsCategory(
            'exclude',
            getEndpointLabelsResources({
              type: 'exclude',
              warnings,
              advanced,
              labelTypesNameObj,
              queryKeywordsRegex,
              noResourceTypeQuery: isGlobalConsumer,
            }),
          ),
        ]),
    {
      id: 'ip_list',
      name: intl('Common.IPLists'),
      infoPanel: advanced ? null : useAdvancedOptionInfoPanel,
      resources: {
        ip_list: {
          ...endpointHistoryProps,
          ...ip_list,
          selectedProps: {
            valueJoiner: 'or',
            pillPropsResource: {warning: Boolean(warnings?.ip_list)},
          },
        },
      },
    },
    ...(isDenyRule && !isCSFrame
      ? []
      : [
          {
            id: 'workload',
            name: isCSFrame ? intl('Common.ServiceTag', {multiple: true}) : intl('Common.Workloads'),
            infoPanel: advanced ? null : useAdvancedOptionInfoPanel,
            resources: {
              workload: {
                ...endpointHistoryProps,
                dataProvider: 'workloads.autocomplete',
                apiArgs: {
                  query: {
                    max_results: isCSFrame ? 50 : 25,
                    ...(isCSFrame && {external_data_reference: 'Azure/serviceTags/public'}),
                  },
                },
                optionProps: {
                  filterOption: (option, values) =>
                    option.href?.includes('workloads') &&
                    !values.get('workload')?.some(({href}) => href === option.href),
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'workload'},
                  tooltipProps,
                },
                selectedProps: {valueJoiner: 'or'},
                onSelect: confirmationOnSelect(labelTypesNameObj),
              },
            },
          },
        ]),
    ...(showUserGroups && !isDenyRule
      ? [
          {
            id: 'consuming_security_principals',
            name: intl('Common.UserGroups'),
            hidden: !advanced,
            resources: {
              consuming_security_principals: {
                ...endpointHistoryProps,
                dataProvider: 'security_principals.autocomplete',
                apiArgs: {query: {max_results: 25}},
                optionProps: {
                  filterOption: option => advanced && option.href?.includes('security_principals'),
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'org'},
                  tooltipProps,
                },
                selectedProps: {valueJoiner: 'or'},
                onSelect: confirmationOnSelect(labelTypesNameObj),
              },
            },
          },
        ]
      : []),
    ...(isKubernetesSupported && !isDenyRule
      ? [
          {
            id: 'virtual_service',
            name: intl('Common.VirtualServices'),
            hidden: !advanced,
            resources: {
              virtual_service: {
                ...endpointHistoryProps,
                dataProvider: 'virtual_services.autocomplete',
                apiArgs: {query: {max_results: 25}, params: {pversion: 'draft'}},
                optionProps: {
                  filterOption: option => advanced && option.href?.includes('virtual_services'),
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'virtual-service'},
                  tooltipProps,
                },
                onSelect: confirmationOnSelect(labelTypesNameObj),
              },
            },
          },
        ]
      : []),
    ...(!__ANTMAN__ && showVirtualServers && !isDenyRule
      ? [
          {
            id: 'virtual_server',
            name: intl('Common.VirtualServers'),
            hidden: !advanced,
            resources: {
              virtual_server: {
                ...endpointHistoryProps,
                dataProvider: 'virtual_servers.autocomplete',
                apiArgs: {query: {max_results: 25}, params: {pversion: 'draft'}},
                optionProps: {
                  filterOption: option => advanced && option.href?.includes('virtual_servers'),
                  allowMultipleSelection: true,
                  isPill: true,
                  pillProps: {icon: 'virtual-server'},
                  tooltipProps,
                },
                selectedProps: {valueJoiner: 'or'},
                onSelect: confirmationOnSelect(labelTypesNameObj),
              },
            },
          },
        ]
      : []),
  ];

  return isCSFrame ? categories.filter(category => csCategories.has(category.id)) : categories;
};

const handleServiceSave = (options, evt, service) => {
  const {onSelect, setCategories, onReturnFocusToInput} = options;

  onSelect(evt, {value: {...service, confirmed: true}, resourceId: 'services'});

  setCategories({policyServices: {active: true}});
  onReturnFocusToInput({force: true});
};

const handleAddClick = (evt, onClick) => {
  domUtils.preventEvent(evt);

  onClick(evt);
};

const getPolicyServicesCategory = (type, labelTypesNameObj) => {
  const keyOrId =
    __ANTMAN__ || __TARGET__ === 'core'
      ? type === 'providers'
        ? 'providerPolicyServices'
        : 'consumerPolicyServices'
      : 'policyServices';

  return {
    id: keyOrId,
    name:
      !(__ANTMAN__ || __TARGET__ === 'core') || type === 'providers'
        ? intl('Services.PolicyServices')
        : intl('Antman.Services.SourceProcessServices'),
    resources: {
      services: {
        enableHistory: true,
        historyKey: __ANTMAN__ || __TARGET__ === 'core' ? keyOrId : 'rulePolicyServices',
        dataProvider: type === 'providers' ? fetchPolicyServices : fetchConsumerPolicyServices,
        optionProps: {
          allowMultipleSelection: true,
          format: ({option}) => (
            <Pill.Service value={option} showPorts={type === 'providers'} insensitive noContextualMenu />
          ),
          tooltipProps,
          filterOption: option =>
            type === 'providers' || type === 'consumers' ? option.name !== intl('Common.AllServices') : option,
        },
        onSelect: confirmationOnSelect(labelTypesNameObj),
        selectedProps: {
          pillPropsValue: {icon: 'service'},
          hideResourceName: true,
        },
      },
    },
  };
};

const getResourceForm = (type, handleServiceSave) => {
  return {
    id: 'serviceForm',
    displayResourceAsCategory: true,
    resources: {
      serviceForm: {
        noEmptyBanner: true,
        statics: [intl('Services.AddNew')],
        optionProps: {
          tooltipProps: {
            ...tooltipProps,
            trigger: 'manual',
            content: options => {
              if (options.values.has('allServices')) {
                return getWarningContent(intl('Rulesets.Rules.AddServiceConfirmation'), options);
              }
            },
          },
          noFilter: true,
          format: options => (
            <Modal.PageInvoker
              edit
              dontRestrainChildren
              title={intl('Common.ServiceSelection.AddNewService')}
              container={ServiceEdit}
              onClose={_.partial(options.onReturnFocusToInput, {force: true})}
              onDone={_.partial(handleServiceSave, options)}
              containerProps={{controlled: true, isConsumer: type === 'consumers'}}
            >
              {({handleOpen}) => (
                <span className={styles.addNewServiceText} onClick={_.partial(handleAddClick, _, handleOpen)}>
                  <Icon position="before" name="add" />
                  {options.option}
                </span>
              )}
            </Modal.PageInvoker>
          ),
        },
        onSelect: (evt, {values, value}) => {
          if (!values.has('allServices') || value.confirmed) {
            let optionElement;

            if (value.confirmed) {
              // Tooltip affirmative confirmation is clicked, optionElement is the reference of this popper
              optionElement = getParentTippyInstance(evt)?.reference;
            } else {
              optionElement = evt.target;
            }

            const addNewElement = optionElement?.firstChild;

            if (addNewElement) {
              domUtils.clickElement(addNewElement, evt);
            }
          } else {
            // Manually trigger confirmation tippy
            // eslint-disable-next-line no-underscore-dangle
            const tippyInstance = evt.target._tippy ?? evt.target.parentElement?._tippy;

            if (tippyInstance && !tippyInstance.state.isMounted) {
              domUtils.preventEvent(evt);
              tippyInstance.show();
            }
          }

          return values;
        },
      },
    },
  };
};

export const providerServiceCategories = ({labelTypesNameObj, isCSFrame, isScopedUser}) => {
  const categories = [
    getPolicyServicesCategory('providers', labelTypesNameObj ?? {}),
    {
      id: 'ports',
      name: intl('Rulesets.Rules.PortOrPortRange'),
      resources: {
        ports: {
          enableHistory: true,
          historyKey: 'rulePorts',
          statics: ({query}) => validatePortAndOrProtocol(query),
          selectedProps: {hideResourceName: true},
          optionProps: {
            filterOption: ({value} = {}) => {
              if (lookupRegexPortProtocol('protocolOnly').test(value?.toUpperCase())) {
                return false;
              }

              return !value.includes(intl('Protocol.ICMP'));
            },
            isPill: true,
            allowMultipleSelection: true,
            tooltipProps,
          },
          onSelect: confirmationOnSelect(labelTypesNameObj),
          validate: query => {
            if (query && validatePortAndOrProtocol(query).length === 0) {
              throw new Error(intl('Port.InvalidPortPortRange'));
            }
          },
        },
      },
    },
    ...(isScopedUser ? [] : [getResourceForm('providers', handleServiceSave)]),
    ...(isCSFrame
      ? []
      : [
          {
            id: 'allServices',
            resources: {
              allServices: {
                sticky: true,
                *dataProvider() {
                  const allServicesHref = yield call(fetchAllServicesHref);

                  return [{href: allServicesHref, name: intl('Common.AllServices')}];
                },
                optionProps: {
                  isPill: true,
                  noFilter: true,
                  tooltipProps,
                },
                selectedProps: {hideResourceName: true},
                onSelect: confirmationOnSelect(labelTypesNameObj),
              },
            },
          },
        ]),
  ];

  return categories;
};

export const consumerServicesCategories = ({labelTypesNameObj, isScopedUser}) => [
  getPolicyServicesCategory('consumers', labelTypesNameObj ?? {}),
  ...(isScopedUser ? [] : [getResourceForm('consumers', handleServiceSave)]),
];

const scopeHistoryProps = {
  enableHistory: true,
  historyKey: 'rulesetScope',
};

const scopeSelectIntoResourceModifier = {
  ...scopeHistoryProps,
  selectedProps: {formatValue: formatSelectedLabel, isPill: false},
  onSelect: (evt, {value, values}) => {
    const typeAlreadySelectedInResourceId = values.has(`${value.key}_include`)
      ? `${value.key}_include`
      : values.has(`${value.key}_exclude`)
      ? `${value.key}_exclude`
      : null;

    if (typeAlreadySelectedInResourceId) {
      values.delete(typeAlreadySelectedInResourceId);
    }
  },
};

/**
 * Get the resource specified for scope picker.
 * This will get merged with the base label resource
 */
const getScopeResources = (type, labelTypesNameObj, labelTypeInitialRegExp) => ({
  resources: produce(getLabelResources({type, labelTypesNameObj, labelTypeInitialRegExp, allowCreate: true}), draft => {
    _.merge(draft, {
      [`labels_${type}`]: {
        ...scopeHistoryProps,
        selectIntoResource: ({value}) => `${value.key}_${type}`,
      },
      ...Object.keys(labelTypesNameObj).reduce(
        (result, key) => ({...result, [`${key}_${type}`]: scopeSelectIntoResourceModifier}),
        {},
      ),
    });
  }),
});

export const getScopeCategories = (labelTypesNameObj, labelTypeInitialRegExp) => [
  getLabelsCategory('include', getScopeResources('include', labelTypesNameObj, labelTypeInitialRegExp)),
  ...(__ANTMAN__
    ? []
    : [getLabelsCategory('exclude', getScopeResources('exclude', labelTypesNameObj, labelTypeInitialRegExp))]),
];

export const ruleOptionsIcons = createSelector([], () => ({
  [intl('Common.SecureConnect')]: 'secure-connect',
  [intl('Common.Stateless')]: 'deny',
  [intl('Common.MachineAuthentication')]: 'machine-auth',
  [intl('Rulesets.Rules.NonCorporateNetworks')]: 'endpoint',
  [intl('Rulesets.Rules.AllNetworks')]: 'network',
}));

const redundantRuleOptions = {
  [intl('Rulesets.Rules.NonCorporateNetworks')]: [
    intl('Rulesets.Rules.AllNetworks'),
    intl('Common.MachineAuthentication'),
    intl('Common.SecureConnect'),
  ],
  [intl('Rulesets.Rules.AllNetworks')]: [
    intl('Rulesets.Rules.NonCorporateNetworks'),
    intl('Common.MachineAuthentication'),
    intl('Common.SecureConnect'),
  ],
  [intl('Common.SecureConnect')]: [
    intl('Rulesets.Rules.NonCorporateNetworks'),
    intl('Rulesets.Rules.AllNetworks'),
    intl('Common.Stateless'),
  ],
  [intl('Common.MachineAuthentication')]: [
    intl('Rulesets.Rules.NonCorporateNetworks'),
    intl('Rulesets.Rules.AllNetworks'),
    intl('Common.Stateless'),
  ],
  [intl('Common.Stateless')]: [intl('Common.SecureConnect'), intl('Common.MachineAuthentication')],
};

export const ruleOptionsCategories = rule => {
  return [
    {
      id: 'ruleOptions',
      resources: {
        ruleOptions: {
          statics: [
            ...('unscoped_consumers' in rule
              ? [intl('Common.SecureConnect'), intl('Common.Stateless'), intl('Common.MachineAuthentication')]
              : []),
            intl('Rulesets.Rules.NonCorporateNetworks'),
            intl('Rulesets.Rules.AllNetworks'),
          ],
          optionProps: {
            allowMultipleSelection: true,
            isPill: true,
            pillProps: ({option}) => ({
              icon: ruleOptionsIcons()[option],
            }),
            tooltipProps: {
              ...tooltipProps,
              content: options => {
                const {option, values} = options;

                const selectedValues = values.get('ruleOptions') ?? [];
                const redundantOptions = redundantRuleOptions[option];
                const replacesValues = selectedValues.filter(value => redundantOptions.includes(value));

                return replacesValues.length
                  ? getWarningContent(
                      intl('Rulesets.Rules.SelectingReplacesConfirmation', {
                        resourceName: `'${option}'`,
                        selectedResourcesNames: intl.list(replacesValues.map(value => `'${value}'`)),
                      }),
                      options,
                    )
                  : null;
              },
            },
          },
          selectedProps: {
            hideResourceName: true,
            pillPropsValue: value => ({
              icon: ruleOptionsIcons()[value],
            }),
          },
          onSelect: (evt, {value, values}) => {
            const selectedValues = values.get('ruleOptions') ?? [];
            const redundantOptions = redundantRuleOptions[value.value ?? value];

            if (selectedValues.some(value => redundantOptions.includes(value))) {
              if (value.confirmed) {
                values.set('ruleOptions', [
                  ...selectedValues.filter(value => !redundantOptions.includes(value)),
                  value.value,
                ]);
              }

              return values;
            }
          },
        },
      },
    },
  ];
};

export const getReceiverCategories = ({warnings, labelTypesNameObj, queryKeywordsRegex}) => [
  getLabelsCategory(
    'include',
    getEndpointLabelsResources({type: 'include', warnings, advanced: true, labelTypesNameObj, queryKeywordsRegex}),
  ),
  {
    id: 'stickyCategory',
    sticky: true,
    resources: {
      allWorkloads: resources(labelTypesNameObj).allWorkloads,
    },
  },
  {
    id: 'workload',
    name: intl('Common.Workloads'),
    resources: {
      workload: {
        ...endpointHistoryProps,
        dataProvider: 'workloads.autocomplete',
        apiArgs: {query: {max_results: 25}},
        optionProps: {
          allowMultipleSelection: true,
          isPill: true,
          pillProps: {icon: 'workload'},
          tooltipProps,
        },
        selectedProps: {valueJoiner: 'or'},
        onSelect: confirmationOnSelect(labelTypesNameObj),
      },
    },
  },
];
