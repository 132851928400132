/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {useCallback, useMemo} from 'react';
import LabelSelector from 'containers/Selector/LabelSelector';
import type {Label} from 'illumio';

type Scope = Map<string, Label>;

export interface ScopeProps {
  scope?: Scope;
  onSelectionChange(scope: Scope): void;
}

// useMemo dependency
const emptyMap = new Map();

export default function ScopeSelector({scope = emptyMap, onSelectionChange}: ScopeProps): JSX.Element {
  const initialItems = useMemo(() => (scope ? Array.from(scope.values()) : []), [scope]);

  const handleLabelChange = useCallback(
    (selection: Label[]) => {
      const final = selection.reduce(
        (result: Scope, {key, href, value, name}: Label) => result.set(key, {key, value, href, name}),
        new Map(),
      );

      onSelectionChange(final);
    },
    [onSelectionChange],
  );

  return (
    <LabelSelector
      tid="labels"
      value={Object.values(initialItems)}
      onChange={handleLabelChange}
      config={{
        hasLabelGroups: true,
        allowCreate: {
          label: true,
        },
        params: {pversion: 'active'},
      }}
      labelEditModifier={{}}
      labelGroupEditModifier={{}}
    />
  );
}
