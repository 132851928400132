/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {EditorState, SelectionState, Modifier} from 'draft-js';

/**
 * Get all text inputs
 *
 * @param {Record} editorState - editorState object
 * @returns {Array<string>} listText
 */
export const getAllContentBlockText = editorState => {
  const current = editorState.getCurrentContent();
  const blockMap = current.getBlockMap();
  const listText = [];

  blockMap.forEach(contentBlock => {
    listText.push(contentBlock.getText());
  });

  return listText;
};

/**
 * Get all non-empty text inputs
 *
 * @param {Record} editorState - editorState object
 * @returns {Array<string>}  listText
 */
export const getAllNonEmptyContentBlockText = editorState => {
  const current = editorState.getCurrentContent();
  const blockMap = current.getBlockMap();
  const listText = [];

  blockMap.forEach(contentBlock => {
    const text = contentBlock.getText();

    if (text) {
      listText.push(text);
    }
  });

  return listText;
};

/**
 * Display editorState object in a string format
 *
 * @param {Record} editorState - editorState object
 * @returns {string}
 */
export const displayEditorStateStringify = editorState => JSON.stringify(editorState.toJS(), null, 4);

/**
 * DraftJS debugger to printout editorState or other to string
 *
 * @params {Record} editorState
 * @returns {compoonent}
 */
export const draftJSDebugger = props => (
  <textarea style={{height: '500px', width: '100%'}} value={displayEditorStateStringify(props.editorState)} />
);

/**
 * Set touched on specific block base on specific selection (e.g. newSelectionState)
 *
 * @param {Record} - editorState
 * @param {Record} - selectionState
 * @returns {Record} - editorState
 */
export const mergeTouchedToBlockData = (editorState, selectionState) => {
  const currentContent = editorState.getCurrentContent();

  // Merge the block data 'touched: true' to contentState by passing in the currentContent and the newly created
  // selectionState.
  // Note: Modifier requires editorState and selectionState to manipulate the contentState
  const newContentState = Modifier.mergeBlockData(currentContent, selectionState, {
    touched: true,
  });

  // New modified EditorState with merged data - use EditorState.set() here and not EditorState.push() to
  // preserve the redoStack.
  // Note: use EditorState.set to update currentContent() only.
  return EditorState.set(editorState, {currentContent: newContentState});
};

/**
 * A onBlur paradigm approach to set the touched property on the previous onFocus
 * content block then return the new immutable changed editorState when navigates within the <Editor>
 *
 * Note:
 * There is a key issue for draft-js-plugin thus logic was written this way.
 * e.g.  https://github.com/draft-js-plugins/draft-js-plugins/issues/1327
 *
 * @param {Record} - editorState
 * @param {string} - lastFocusContentBlockKey = the last focus content block key
 * @returns {Record} newEditorsState - modified editor state || existing editor state
 *                   updateLastFocusContentBlockKey - last previous focused key
 */
export const getModifiedContentBlockTouched = (editorState, lastFocusContentBlockKey) => {
  const currentContent = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  // Get the Start key of the current focus
  const startKey = selectionState.getFocusKey();
  const hasFocus = selectionState.hasFocus;
  // Get the current focus block
  const currentContentBlock = currentContent.getBlockForKey(startKey);

  let newEditorsState = editorState;
  let updateLastFocusContentBlockKey = lastFocusContentBlockKey;

  // Important to check 'currentContentBlock' to exist and hasFocus
  // When content block is destroyed by deleting then the key is no longer available thus need to check 'currentContentBlock'
  if (hasFocus && currentContentBlock && lastFocusContentBlockKey !== startKey) {
    updateLastFocusContentBlockKey = startKey;

    const prevFocusContentBlock = currentContent.getBlockForKey(lastFocusContentBlockKey);

    if (prevFocusContentBlock) {
      const data = prevFocusContentBlock.getData();
      const touched = data.get('touched');

      if (!touched) {
        // Create a new selection to determine which block in the currentContent to change data
        const newSelectionState = SelectionState.createEmpty(lastFocusContentBlockKey);

        // Set touched flag on the specific newSelectionState
        newEditorsState = mergeTouchedToBlockData(editorState, newSelectionState);

        // Important to reset the selection back after merging block data by creating a new editor state
        // Without this call, the position of the editor's cursor will be incorrect
        newEditorsState = EditorState.forceSelection(newEditorsState, selectionState);
      }
    }
  }

  return {
    updateLastFocusContentBlockKey,
    newEditorsState,
  };
};

/** Set the initial start editor selection state for manipulating the touched logic. Without
 * this method the touched won't work properly
 *
 * @params {Record} editorState
 * @returns {Record} - newEditorState
 */
export const setStartEditorStateOffset = editorState => {
  const currentContent = editorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();

  // Extract first block
  const key = blockMap.first().getKey();
  const length = blockMap.first().getLength();

  // Note: don't set hasFocus: true when using EditorState.acceptSelection()
  // newSelection is made to position the cursor by using: anchorKey, anchorOffset, focusKey and focusOffset
  // When anchorOffset === focusOffset cursor is focus NOT selected
  // When anchorOffset > focusOffset (e.g. anchorOffset: 3, focusOffset: 4) position 3 is selected up to position 4
  const newSelection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });

  // acceptSelection() is used to set a new selection but doesn't render newSelection which means
  // don't display the position on the DOM but set the proper newSelection meta data type
  return EditorState.acceptSelection(editorState, newSelection);
};

/** Use this as workaround for Chrome/Safari issue for Tippy's tooltip. When clicking on tooltip's text in the EditorState
 * a run time JS error will occur causing the page to crash. A workload around is to extend Selection.
 * Note: This issue doesn't exist in FireFox
 *
 * Issues Urls
 * https://github.com/facebook/draft-js/pull/1190#issuecomment-320413948
 * https://github.com/facebook/draft-js/issues/1727
 *
 * Solution:
 * https://github.com/facebook/draft-js/issues/1188
 *
 */
export const addExtendSelection = () => {
  const nativeSelectionExtend = Selection.prototype.extend;

  Selection.prototype.extend = function (...args) {
    try {
      return nativeSelectionExtend.apply(this, args);
    } catch {
      // Catch error to prevent a JS runtime crash
      // TODO: This is temporary fix for Chrome, Safari
    }
  };
};
