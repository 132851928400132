/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import PropTypes from 'prop-types';
import {Drawer} from 'components';

InfoPanel.propTypes = {
  title: PropTypes.any,
  showTitle: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default function InfoPanel(props) {
  const {title, showTitle, children, theme, ...drawerProps} = props;

  return (
    <div className={theme.infoPanel}>
      {showTitle ? (
        <Drawer text={title} theme={theme} {...drawerProps}>
          <div className={theme.infoPanelContent}>{children}</div>
        </Drawer>
      ) : (
        <div className={theme.infoPanelContent}>{children}</div>
      )}
    </div>
  );
}
