/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {Label, Service} from 'illumio';
import {hrefUtils} from '@illumio-shared/utils';
import type {Policy, PolicyVersion} from './MapPolicyUtils';
import type {PaginationInteraction} from 'containers/IlluminationMap/ToolBar/Pagination/MapPaginationTypes';

export type EndpointType = 'source' | 'target';
export type EndType = 'focused' | 'source' | 'target';
export type FlowDirection = 'inbound' | 'outbound';
export type Mode = 'full' | 'selective' | 'visibility';
export type State = 'active' | 'closed' | 'new' | 'snapshot' | 'static' | 'timed_out' | 'unknown';
export type Vulnerability = 'critical' | 'high' | 'info' | 'low' | 'medium' | 'none';

export type ManagedEndpointType = 'virtualServer' | 'virtualService' | 'workload';
export type UnmanagedEndpointType = 'fqdn' | 'internet' | 'ipAddress' | 'ipList' | 'privateAddress';
export type EndpointDataType = ManagedEndpointType | UnmanagedEndpointType | '';

export type ProviderConsumerOrder = 'consumerFirst' | 'providerFirst';
export type ModeTypes = 'full' | 'idle' | 'selective' | 'visibility_only';
export type ViewType = 'bidirectional' | 'directional' | 'focused';

export type MapGrouping = string[];
export type MapGroupingSettings = {enabled: boolean; maxItems: number; groupingOrder: string[]};
export type MapDisplay = 'map' | 'mesh' | 'table';
export type MapConnection = 'aggregated' | 'individual';
export type MapPanelTab =
  | 'appGroups'
  | 'rules'
  | 'services'
  | 'summary'
  | 'traffic'
  | 'virtualService'
  | 'vulnerabilities'
  | 'workload'
  | 'workloads';
export type MapMode = 'policy' | 'vulnerability';
export type MapRouteParams = {
  policy?: PolicyVersion;
  display?: MapDisplay;
  connection?: MapConnection;
  tab?: MapPanelTab;
  grouping?: MapGrouping;
  query?: string;
  offset?: number;
  go?: true;
  mapMode?: MapMode;
  viewTable?: boolean | undefined;
};

export type Network = {
  name: string;
  href: hrefUtils.Href;
};

export interface BaseEndpointData {
  ip: string;
  ips?: Set<string>;
  modes?: Set<string>;
  fullIp: string; //Combination of the ip address and the fqdn
  fullIps?: Set<string>;
  deletedWorkloadIps?: Set<string>;
  transmission?: string;
}

export interface ManagedEndpointData extends BaseEndpointData {
  type: ManagedEndpointType;
  details: ManagedDetails;
}

export interface UnmanagedEndpointData extends BaseEndpointData {
  type: UnmanagedEndpointType;
  details: UnmanagedDetails;
}

export type EndpointData = ManagedEndpointData | UnmanagedEndpointData;

const unmanagedEndpoints = new Set(['ipList', 'ipAddress', 'fqdn', 'privateAddress', 'internet']);
export const isUnmanagedEndpoint = (endpoint: EndpointData): endpoint is UnmanagedEndpointData => {
  return unmanagedEndpoints.has(endpoint.type);
};

const managedEndpoints = new Set(['workload', 'virtualService', 'virtualServer']);
export const isManagedEndpoint = (endpoint: EndpointData): endpoint is ManagedEndpointData => {
  return managedEndpoints.has(endpoint.type);
};

export const isUnmanagedWorkload = (endpoint: EndpointData): endpoint is UnmanagedEndpointData => {
  return endpoint.type === 'workload' && endpoint.details.subType === 'unmanaged';
};

export interface ManagedDetails {
  labels: Label[];
  labelObject: Record<string, Label>;
  subType: 'containerWorkload' | 'deleted' | 'idle' | 'kubernetesWorkload' | 'unmanaged' | 'workload';
  osType: string;
  mode: string;
  appGroup: string;
  appGroupId: string;
  href: hrefUtils.Href;
  hostname: string;
  name: string;
}

export interface ManagedEnpointDetails extends ManagedDetails {
  ip: string;
  managedType: EndpointDataType;
}

export interface UnmanagedDetails {
  ipLists: IPEndpoint[] | undefined;
  fqdn: string;
  privateAddressSubnet: string | undefined;
}

export type IPEndpoint = {
  name: string;
  href: hrefUtils.Href;
  size: number;
  rules?: {href?: string; essential_service_rule?: string}[];
  enforcement_boundaries?: {href?: string}[];
  ips?: Set<string>;
};

export type LinkData = {
  index: number;
  key?: string;
  linkKey: string;
  serviceKey: string;
  endpointKey: string;
  graphKey: string;
  flows: number;
  direction: FlowDirection;
  state: State;
  firstDetected: string;
  lastDetected: string;
  sequenceId: number;
  numConnections: number;
  network: Network;
  byteIn: number;
  byteOut: number;
  source: EndpointData;
  target: EndpointData;
  policy: Policy;
  service: MapService;
  links?: Set<LinkData>;
  connectionKeys?: Set<string>;
};

export type UnmanagedWorkloadData = {
  labels?: Label[];
  addresses: Set<string>;
  createWorkloadNames: Set<string>;
  connections: number;
  direction: string;
  href: string;
  name: string;
  rule: Set<object>;
  type: string;
};

export type InboundOutboundService = {
  processName: string | null;
  windowsService: string | null;
  username: string | null;
  services: Service[];
};

export type MapService = {
  port?: string;
  protocol: string;
  protocolNum: number;
  processName?: string;
  windowsService?: string;
  username?: string;
  services?: Service[];
  inbound?: InboundOutboundService;
  outbound?: InboundOutboundService;
};
export type GridRow = {
  key: string;
  selectable: boolean;
  sortable: boolean;
  data: LinkData;
};

export type unmanagedWorkloadsGridData = {
  key: number;
  selectable: boolean;
  sortable: boolean;
  data: UnmanagedWorkloadData;
};
export type ParallelCoordinatesLinks = {
  source: string;
  target: string;
  port: string | null;
  outboundProcess: string | null;
  inboundProcess: string | null;
  aggregatedSet: Set<number>;
};

export type createdWorkload = {
  name: string;
  labels: Record<string, string>[];
  hostname: string;
  interfaces: Record<string, string>[];
};

export type SelectorOptionsType = Record<string, {label: string; tid: string; name: string; value: string}>;

export type Result = {
  queryId: string;
  date: Date;
  href: string;
  flowsCount: number;
  matchesCount: number;
  createdAt: Date;
  updatedAt: Date;
  createdBy: {href: string};
  status: string;
  downloadUrl: string;
  queryParameters: Record<string, unknown>;
};

export type ResultQueryStatusType = 'query-error' | 'query-init' | 'query-success';
export type ResultDownloadStatusType =
  | 'download-error'
  | 'download-init'
  | 'download-loading'
  | 'download-processing'
  | 'download-ready';
export type ResultProcessingStatusType = 'processing' | 'ready';
export type ResultStatusType = ResultDownloadStatusType | ResultProcessingStatusType | ResultQueryStatusType;
export type ResultStatusReason = 'auto' | 'pagination' | 'query';
export type ResultStatusInteraction = PaginationInteraction | 'goButton';
export type ResultParams = {
  queryId: string;
  offset?: number;
  downloadOffset?: number;
  limit?: number;
  pageNumber?: number;
  draft_policy_filter?: string;
  reported_policy_filter?: string;
  transmission?: string;
  ip_filter?: string;
};

export type ResultStatus = {
  key: string;
  interaction?: ResultStatusInteraction;
  error?: string;
  status?: ResultStatusType;
  reason?: ResultStatusReason;
  params?: ResultParams;
  previous?: ResultStatus;
};

export type ResultsStatus = Record<string, ResultStatus>;

export type RouteParams = {
  tab?: string;
  connection?: string;
  id?: string;
  policyItem?: string;
  display?: string;
  policy?: string;
  mapMode?: string;
};

type ResolutionType = 'virtual_services' | 'workloads';

export type LabelResolution = ResolutionType[];
