/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';

export const gridSettings = createSelector([], () => ({
  id: 'coreserviceslist',
  sort: 'recommended',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    coreService: {
      linky: true,
      header: intl('CoreServices.CoreService'),
      value: ({row}) => row.name,
    },
    recommended: {
      header: intl('Common.Recommended'),
      value: ({row}) => row.recommended,
    },
    accepted: {
      header: intl('CoreServices.Accepted'),
      value: ({row}) => row.accepted,
    },
    rejected: {
      header: intl('CoreServices.Rejected'),
      value: ({row}) => row.rejected,
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    id: ?string, // Optional breakpoint id, goes to cellFormat function
    data: ?object, // Object with any data, goes to cellFormat function
    maxWidth: number, // Maximum width for breakpoint, minimum will be calculated automatically, goes to cellFormat function
    template: string | Function, // Columns configuration
  }];
   */
  templates: [
    [
      {columns: ['coreService'], size: 'minmax(200px, 500px)'},
      {columns: ['recommended'], size: 'minmax(100px, 250px)'},
      {columns: ['accepted'], size: 'minmax(100px, 250px)'},
      {columns: ['rejected'], size: 'minmax(100px, 250px)'},
    ],
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['coreService'], size: 'minmax(200px, auto)'},
            {columns: ['recommended', 'accepted', 'rejected'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['coreService'], size: 'minmax(200px, auto)'},
          {columns: ['recommended', 'accepted', 'rejected'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
