/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {all, call, select, put} from 'redux-saga/effects';
import {
  getAuthSecPrincipals,
  getFilteredAuthSecPrincipals,
  getAuthSecPrincipalsCount,
  getFilteredAuthSecPrincipalsCount,
} from './AuthSecPrincipalState';
import apiSaga from 'api/apiSaga';
import {hrefUtils} from '@illumio-shared/utils';

const filterNameToQueryMap = {
  org_auth_security_principals: 'name',
};

export function* fetchAllAuthSecPrincipals({force = false} = {}) {
  return yield call(apiSaga, 'org_auth_security_principals.get_collection', {
    cache: !force,
    *onDone({data: authSecPrincipals, count}) {
      if (
        force ||
        authSecPrincipals !== (yield select(getAuthSecPrincipals)) ||
        count !== (yield select(getAuthSecPrincipalsCount))
      ) {
        yield put({type: 'AUTH_SEC_PRINCIPAL_GET_ALL', data: {authSecPrincipals, count}});
      }

      return {authSecPrincipals, count};
    },
  });
}

export function* fetchFilteredAuthSecPrincipals({filter, force = false} = {}) {
  let query;

  if (!_.isEmpty(filter)) {
    query = Object.entries(filter).reduce((result, [name, [value]]) => {
      if (value) {
        result[filterNameToQueryMap[name]] = value;
      }

      return result;
    }, {});
  }

  return yield call(apiSaga, 'org_auth_security_principals.get_collection', {
    query,
    cache: !force,
    *onDone({data: authSecPrincipals, count}) {
      if (
        force ||
        authSecPrincipals !== (yield select(getFilteredAuthSecPrincipals)) ||
        count !== (yield select(getFilteredAuthSecPrincipalsCount))
      ) {
        yield put({type: 'AUTH_SEC_PRINCIPAL_GET_FILTERED', data: {authSecPrincipals, count}});
      }

      return {authSecPrincipals, count};
    },
  });
}

export function* removeAuthSecPrincipals(authSecPrincipalHrefs) {
  const removed = [];
  const errors = new Map();

  yield all(
    authSecPrincipalHrefs.map(function* (href) {
      try {
        yield call(apiSaga, 'org_auth_security_principal.delete', {
          params: {auth_security_principal_id: hrefUtils.getId(href)},
        });

        removed.push(href);
      } catch (err) {
        const errData = _.get(err, 'data[0]');
        const message = (errData && errData.message) || err.message;
        const hrefs = errors.get(message) || [];

        hrefs.push(href);
        errors.set(message, hrefs);
      }
    }),
  );

  return {removed, errors};
}

export function* addAuthSecPrincipal(data) {
  try {
    const {
      data: {href},
    } = yield call(apiSaga, 'org_auth_security_principals.create', {data});

    return {href};
  } catch (error) {
    error.errorData = _.get(error, 'data[0]');

    throw error;
  }
}

export function* updateAuthSecPrincipal(authSecPrincipalHref, updateData) {
  yield call(apiSaga, 'org_auth_security_principal.update', {
    params: {auth_security_principal_id: hrefUtils.getId(authSecPrincipalHref)},
    data: updateData,
  });
}
