/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {produce, current} from 'immer';
import {type ContextType, PureComponent} from 'react';
import {call, delay} from 'typed-redux-saga';
import createCachedSelector from 're-reselect';
import type {RootState} from '@illumio-shared/utils/redux';
import apiSaga, {type APISagaOptions} from 'api/apiSaga';
import type {SchemaMethodsKey} from 'api/schema';
import type {APICallOptions} from 'api/api';
import {MenuDelimiter, MenuInfo, Pill, type PillProps} from 'components';
import Skeleton from 'components/Skeleton/Skeleton';
import {AppContext} from 'containers/App/AppUtils';
import {hrefUtils, reactUtils} from '@illumio-shared/utils';
import {generalUtils} from '@illumio-shared/utils/shared';
import {getUpdateTypeProps} from '../PillUtils';
import type {LabelSettings, LabelGroup} from 'illumio';
import {getLabelSetting} from 'containers/Label/LabelSettings/LabelSettingState';
import {
  getLabelDisplayStyle,
  getLabelTypeDisplay,
  getLabelTypeInitial,
  getLabelTypeName,
} from 'containers/Label/LabelSettings/LabelSettingsUtils';
import stylesUtils from 'utils.css';

declare module '@illumio-shared/utils/redux' {
  // TODO: should augment user state
  interface RootState {
    readonly labelsettings: LabelSettings[];
  }
}

export interface LabelProps extends Omit<PillProps, 'id'> {
  type?: string;
  // Alternatively, instead of 'link' you can just specify id or href of label/label_group, like 19 or "/orgs/1/labels/19" for label,
  // and "orgs/1/labelgroups/b88711a1-a3fb-4139-a09b-1fa6600a279f" or "b88711a1-a3fb-4139-a09b-1fa6600a279f" for labelgroup
  // In that case Label will automatically create link to {{to: 'labels.item', params: {id}}}, or {{to: 'labelGroups.item.view.tab',
  // params: {id, pversion: 'draft', tab: 'members'}}} where 'id' will be taken from 'id' prop or parsed from 'href' prop
  id?: number | string;
  href?: hrefUtils.Href;
  // policy version
  pversion?: number | string;
  noAffix?: boolean; // noAffix disregard prefix or suffix from the content
  noExclusionText?: boolean; // don't concatenate All XXX except to content
  all?: boolean; // all scopes - displays pill with text 'All'
}

type LabelPropsIn = Readonly<reactUtils.WithDefaultProps<LabelProps, typeof Label.defaultProps>>;

export const getLabelSettingItem = createCachedSelector(
  [
    getLabelSetting,
    (_state: RootState, props: PillProps) => props.type,
    (_state: RootState, props: PillProps) => props.group,
  ],
  (labelSettings, type, group) => {
    const labelSetting = labelSettings?.find((label: LabelSettings) => label.key === type);

    if (labelSetting && group && __ANTMAN__) {
      return produce(labelSetting, (draft: LabelSettings) => {
        draft.display_info.initial = `${labelSetting?.display_info?.initial}G`;
      });
    }

    return labelSetting;
  },
)((_state: RootState, props: PillProps) => props.type);

export default class Label extends PureComponent<LabelPropsIn> {
  static contextType = AppContext;
  static defaultProps = {
    pversion: 'draft',
  };

  // eslint-disable-next-line react/static-property-placement
  declare context: ContextType<typeof AppContext>;
  element: HTMLElement | null | undefined = null;
  groupFetchPromise: Promise<unknown> | null | undefined = null;

  constructor(props: LabelPropsIn) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
    this.fetchGroupInstance = this.fetchGroupInstance.bind(this);
  }

  private getId() {
    return this.props.id ?? hrefUtils.getId(this.props.href);
  }

  private getLabelName(): string {
    const {children, title, type} = this.props;
    let name;

    if (typeof children === 'string' && children) {
      name = children;
    } else if (title) {
      name = title;
    } else {
      name = getLabelTypeName(type);
    }

    return name;
  }

  private getChildrenContent() {
    const {children, noAffix, type} = this.props;
    const initial = getLabelTypeInitial(type);
    const style = getLabelDisplayStyle();

    if (noAffix || !initial || !children) {
      return children;
    }

    let newChildren = children;

    if (style === 'icon_prefix') {
      newChildren = (
        <span>
          <strong>{initial}:&nbsp;</strong>
          {children}
        </span>
      );
    } else if (style === 'icon_suffix') {
      newChildren = (
        <span>
          {children}
          <strong>-{initial}</strong>
        </span>
      );
    }

    return newChildren;
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  private fetchGroupInstance(signal: AbortSignal) {
    const {
      context: {fetcher},
      props: {type, pversion},
    } = this;
    const name = this.getLabelName();
    const id = this.getId();

    if (this.groupFetchPromise) {
      return this.groupFetchPromise;
    }

    this.groupFetchPromise = fetcher.fork(function* () {
      try {
        yield delay(500);

        const {data: labelGroup} = yield* call(
          apiSaga,
          'label_groups.get_instance' as SchemaMethodsKey,
          {
            cache: true,
            cacheMaxAge: 30_000,
            ignoreCodes: [404],
            params: {pversion, label_group_id: id},
          } as APICallOptions & APISagaOptions,
        );

        return labelGroup;
      } catch (err: unknown) {
        const error = generalUtils.toError(err);

        console.error(
          `Could not fetch the instance of the '${name}' Label Group of type '${type}' for its Contextual Menu:\n • ${error.message}`,
        );

        throw error;
      }
    }) as Promise<unknown>;

    this.groupFetchPromise.finally(() => {
      this.groupFetchPromise = null;
    });

    signal.addEventListener('abort', () => {
      fetcher.cancel(this.groupFetchPromise);
    });

    return this.groupFetchPromise;
  }

  renderGroupContextualMenu: PillProps['contextualMenu'] = (items, ...rest) => {
    const menu = produce(items, draft => {
      const firstInfo = draft.find(item => item.type === MenuInfo);

      const typeAttribute = current(firstInfo?.attributes?.[0]);

      if (typeAttribute && firstInfo) {
        firstInfo.props.prefetch = this.fetchGroupInstance;
        firstInfo.props.children = ({isFetching, fetchData}) => {
          const labelGroup = fetchData as LabelGroup;

          return (
            <>
              <div key="type">
                <strong>{`${typeAttribute.key}:`}</strong> {typeAttribute.value}
              </div>
              {isFetching && (
                <Skeleton key="loading">
                  <Skeleton.Block>
                    <Skeleton.Line>
                      <Skeleton.Box />
                      <Skeleton.Box />
                    </Skeleton.Line>
                    <Skeleton.Box />
                  </Skeleton.Block>
                </Skeleton>
              )}
              {!isFetching && labelGroup?.description ? (
                <div key="desc" style={{'--ellipsis-lines': '3'}} className={stylesUtils.ellipsisLines}>
                  <strong>{`${intl('Common.Description')}:`} </strong>
                  {labelGroup.description}
                </div>
              ) : null}
            </>
          );
        };
        delete firstInfo.attributes;

        draft.splice(
          draft.indexOf(firstInfo) + 1,
          0,
          {type: MenuDelimiter, props: {key: 'delimiter2'}},
          {
            type: MenuInfo,
            props: {
              key: 'members',
              prefetch: this.fetchGroupInstance,
              children: ({isFetching, fetchData, theme}) => {
                const labelGroup = fetchData as LabelGroup;

                return (
                  <>
                    {isFetching && (
                      <Skeleton key="loading">
                        <Skeleton.Block>
                          <Skeleton.Box height="var(--26px)" />
                          <Skeleton.Box height="var(--26px)" />
                          <Skeleton.Box height="var(--26px)" />
                        </Skeleton.Block>
                      </Skeleton>
                    )}
                    {!isFetching && labelGroup ? (
                      <div
                        key="members"
                        className={cx(stylesUtils.gapXSmall, {[theme.menuInfoEmpty]: !labelGroup.labels?.length})}
                      >
                        {!labelGroup.labels?.length && <span>{intl('LabelGroups.NoMembers')}</span>}
                        {labelGroup.labels?.map(label => {
                          const isLabelGroup = label?.href.includes('label_groups');

                          return (
                            <Label
                              noContextualMenu
                              key={label?.key}
                              type={label?.key}
                              href={label?.href}
                              group={isLabelGroup}
                            >
                              {isLabelGroup ? label.name : label.value}
                            </Label>
                          );
                        })}
                      </div>
                    ) : null}
                  </>
                );
              },
            },
          },
        );
      }
    });

    return this.props.contextualMenu ? this.props.contextualMenu(menu, ...rest) : menu;
  };

  render() {
    const {
      type,
      id: objectId,
      href,
      children,
      pversion,
      updateType,
      updateTypeTooltip,
      noAffix,
      noExclusionText,
      all,
      ...pillProps
    } = this.props;

    let content: React.ReactElement | string;

    if (all) {
      pillProps.icon = 'scope';
      pillProps.noContextualMenu ??=
        !pillProps.contextualMenu && !pillProps.contextualType && !pillProps.contextualCopyValue;

      content = intl('Common.All');
    } else {
      const id = this.getId();

      if (id) {
        if (pillProps.group) {
          Object.assign(pillProps, {
            link: pillProps.onClick ? null : {to: 'labelGroups.item', params: {id, pversion}},
            ...getUpdateTypeProps({
              object: 'labelGroup',
              updateType,
              updateTypeTooltip,
              deleted: pillProps.deleted,
              pversion,
            }),
          });

          // TODO: Enable with revised ExpandableList
          // if (!pillProps.noContextualMenu) {
          //   pillProps.contextualMenu ??= this.renderGroupContextualMenu;
          // }
        } else {
          Object.assign(pillProps, {
            link: pillProps.onClick ? null : {to: 'labels.item', params: {id}},
          });
        }
      }

      if (type) {
        const {icon = type, foreground_color: frontColor, background_color: backColor} = getLabelTypeDisplay(type);

        Object.assign(pillProps, {
          icon,
          initial: getLabelTypeInitial(type),
          style: {
            ...(backColor?.length > 0 && {'--pill-symbol-background-color': backColor}),
            ...(frontColor?.length > 0 && {'--pill-symbol-color': frontColor}),
          },
        });
      }

      pillProps.pillStyle ??= getLabelDisplayStyle();

      if (!pillProps.noContextualMenu) {
        pillProps.contextualType ??= getLabelTypeName(type);
        pillProps.contextualCopyValue ??= this.getLabelName();
      }

      if (pillProps.exclusion && !noExclusionText) {
        Object.assign(pillProps, {
          exclusionContent: intl('Labels.Excluded', {type: getLabelTypeName(type, true)}),
        });
      }

      content = <>{this.getChildrenContent()}</>;
    }

    return (
      <Pill {...pillProps} ref={this.saveRef}>
        {content}
      </Pill>
    );
  }
}
