/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {StatusIcon} from 'components';
import _ from 'lodash';
import {createSelector} from 'reselect';

// Container Cluster Utils
export const getStatusElement = ({online, lastConnected, errors = []}) => {
  if (errors.length) {
    return <StatusIcon status="error" label={intl('Common.Error')} />;
  }

  if (online) {
    return <StatusIcon status="online" label={intl('Common.InSync')} />;
  }

  if (lastConnected) {
    return <StatusIcon status="error" label={intl('Common.OutOfSync', {when: new Date(lastConnected)})} />;
  }

  return <StatusIcon status="none" label={intl('Common.NotYetConnected')} />;
};

export const getClasModeElement = clasMode => {
  if (clasMode) {
    return intl('Common.Enabled');
  }

  return intl('Common.Disabled');
};

export const sortByStatus = (a, b, sortFactor) => {
  if ((!a.online && !a.lastConnected) || b.online) {
    return -1 * sortFactor;
  }

  if ((!b.online && !b.lastConnected) || a.online) {
    return sortFactor;
  }

  return new Date(a.lastConnected).getTime() > new Date(b.lastConnected).getTime() ? sortFactor : -sortFactor;
};

// Container Workload Profile Utils
export const policyStateOptions = createSelector([], () => [
  {value: 'build', label: intl('Common.Build')},
  {value: 'test', label: intl('Common.Test')},
  {value: 'enforceHighDetail', label: intl('Common.EnforcedHighDetail')},
  {value: 'enforceLowDetail', label: intl('Common.EnforcedLowDetail')},
  {value: 'enforceNoDetail', label: intl('Common.EnforcedNoDetail')},
  {value: 'unmanaged', label: intl('Common.Unmanaged')},
]);

export const policyStateToValues = createSelector([], () => ({
  build: {mode: 'illuminated', log_traffic: false, visibility_level: 'flow_summary'},
  test: {mode: 'illuminated', log_traffic: true, visibility_level: 'flow_summary'},
  enforceHighDetail: {mode: 'enforced', log_traffic: true, visibility_level: 'flow_summary'},
  enforceLowDetail: {mode: 'enforced', log_traffic: true, visibility_level: 'flow_drops'},
  enforceNoDetail: {mode: 'enforced', log_traffic: false, visibility_level: 'flow_off'},
  unmanaged: {mode: 'unmanaged', log_traffic: false, visibility_level: 'flow_summary'},
}));

export const getPolicyState = ({mode, logTraffic, visibilityLevel}) => {
  const finalVisibilityLevel = visibilityLevel === 'flow_full_detail' ? 'flow_summary' : visibilityLevel;

  return (
    _.findKey(policyStateToValues(), option => {
      // For 'mode: enforced' the log_traffic boolean is irrelevant
      if (mode === 'enforced' && option.mode === mode && option.visibility_level === visibilityLevel) {
        return true;
      }

      return (
        option.mode === mode && option.log_traffic === logTraffic && option.visibility_level === finalVisibilityLevel
      );
    }) || 'unmanaged'
  );
};

export const isOpenShift = clusterDetail => (clusterDetail?.manager_type ?? '').toLowerCase().startsWith('openshift');

export const getNamespaceTerm = clusterDetail =>
  isOpenShift(clusterDetail) ? intl('Common.Project') : intl('Common.Namespace');

export const targets = {
  addContainerCluster: {
    actionId: 'container_clusters.create',
    cleanCaches: ['container_clusters.get_collection', 'container_clusters.get_instance', 'labels.get_collection'],
  },
  editContainerCluster: {
    actionId: 'container_cluster.update',
    cleanCaches: ['container_clusters.get_collection', 'container_clusters.get_instance', 'labels.get_collection'],
  },
  addContainerClusterProfile: {
    actionId: 'container_workload_profiles.create',
    cleanCaches: [
      'container_workload_profiles.get_collection',
      'container_workload_profiles.get_instance',
      'labels.get_collection',
    ],
  },
  editContainerClusterProfile: {
    actionId: 'container_workload_profile.update',
    cleanCaches: [
      'container_workload_profiles.get_collection',
      'container_workload_profiles.get_instance',
      'container_workloads.get_collection',
      'labels.get_collection',
    ],
  },
};
