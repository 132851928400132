/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import type {Workload} from 'illumio';

export const getFriendlyName = (workload: Workload): string => {
  if (workload.name) {
    return workload.name;
  }

  if (workload.hostname) {
    return workload.hostname;
  }

  if (workload.agent && workload.agent.instance_id) {
    return workload.agent.instance_id;
  }

  return '';
};
