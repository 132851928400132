/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {hrefUtils} from '@illumio-shared/utils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {
  getUser,
  getAllUsersMap,
  isUserReadOnlyAll,
  isUserWorkloadManagerOnly,
  isUserWorkloadManager,
  isUserScoped,
} from 'containers/User/UserState';
import {gridSettings} from './ReportsListConfig';
import {types} from '../ReportsUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'REPORTSLIST_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'REPORTSLIST_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  regenerating(state = [], action) {
    switch (action.type) {
      case 'REPORTSLIST_ADD_REGENERATE':
        return [...state, action.href];
      case 'REPORTSLIST_REMOVE_REGENERATE':
        return state.filter(href => href !== action.href);
      default:
        return state;
    }
  },
};

export const getAllReports = state => state.exportReports.list;
export const getAllReportsCount = state => state.exportReports.count;
export const getAllReportsInProgress = createSelector(getAllReports, reports =>
  reports.filter(report => report.status === 'running' || report.status === 'pending'),
);
export const getAllReportsComplete = createSelector(getAllReportsInProgress, reports =>
  reports.filter(
    report => (report.result && report.result.href && report.status === 'done') || report.status === 'failed',
  ),
);
export const getAllReportsDone = createSelector(getAllReportsInProgress, reports =>
  reports
    .filter(report => report.result && report.result.href && report.status === 'done')
    .map(report => ({
      ...report,
      filename: `${report.description}.${report.description.includes('CSV') ? 'csv' : 'json'}`,
    })),
);
export const getAllReportsFailed = createSelector(getAllReportsInProgress, reports =>
  reports.filter(report => report.status === 'failed'),
);

export const getUserReports = createSelector([getAllReports, getUser], (reports, user) =>
  reports.filter(report => report.requested_by.href === user.href),
);
export const getUserReportsInProgress = createSelector(getUserReports, reports =>
  reports.filter(report => report.status === 'running' || report.status === 'pending'),
);
export const getUserReportsComplete = createSelector(getUserReports, reports =>
  reports.filter(
    report => (report.result && report.result.href && report.status === 'done') || report.status === 'failed',
  ),
);
export const getUserReportsDone = createSelector(getUserReports, reports =>
  reports
    .filter(report => report.result && report.result.href && report.status === 'done')
    .map(report => ({
      ...report,
      filename: `${report.description}.${report.description.includes('CSV') ? 'csv' : 'json'}`,
    })),
);
export const getUserReportsFailed = createSelector(getUserReports, reports =>
  reports.filter(report => report.status === 'failed'),
);

export const getRegeneratingReports = state => state.exportReports.regenerating;

const getReportsRows = createSelector(
  [getUserReports, getAllUsersMap, getRegeneratingReports],
  (reports, usersMap, regeneratingReports) => {
    return reports.map(item => ({
      key: hrefUtils.getId(item.href),
      selectable: true,
      removable: true,
      downloadable: item.status === 'done',
      regenerating: regeneratingReports.includes(item.href),
      // Fill each Report with user object
      data: {
        ...item,
        requested_by: item.requested_by ? fillUserInfo(usersMap, item.requested_by) : null,
      },
    }));
  },
);

export const getGridSettings = createSelector([isUserReadOnlyAll, gridSettings], (userIsReadOnlyAll, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = userIsReadOnlyAll;

  return {...gridSettings, columns};
});

export const getReportsCount = createSelector(getReportsRows, rows => ({matched: rows.length, total: rows.length}));

export const getReportsListPage = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getReportsRows,
  });

export const getCalculatedSelectorOptions = createSelector(
  [isUserWorkloadManager, isUserWorkloadManagerOnly, isUserScoped],
  (userIsWorkloadManager, userIsWorkloadManagerOnly, userIsScoped) => {
    const optionTypes = {...types()};

    // Workload Manager cannot select rulesets.
    if (optionTypes.rulesets && userIsWorkloadManagerOnly) {
      delete optionTypes.rulesets;
    }

    // Only global users and workload manager can select pairing profiles.
    if (optionTypes.pairingProfiles && userIsScoped && !userIsWorkloadManager) {
      delete optionTypes.pairingProfiles;
    }

    return {
      typesSelectorOptions: Object.entries(optionTypes).map(([type, {label}]) => ({
        value: type,
        name: 'reportType',
        label,
      })),
    };
  },
);
