/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
/**
 * Topic-based publish/subscribe class with ability to call subscription about last published topic and only once.
 *
 * @example
 * Subscription callback will be called twice
 * ```
 * PubSub.subscribe('SomeTopic', data => {console.log(data)});
 * PubSub.publish('SomeTopic', 'anydata');
 * PubSub.publish('SomeTopic', 'anydata2');
 * ```
 *
 * Subscription callback will be called three times (with 'predata', 'postdata', and 'postdata2')
 * ```
 * PubSub.publish('SomeTopic', 'alphadata');
 * PubSub.publish('SomeTopic', 'predata');
 * PubSub.subscribe('SomeTopic', data => {console.log(data)}, {getLast: true});
 * PubSub.publish('SomeTopic', 'postdata');
 * PubSub.publish('SomeTopic', 'postdata2');
 * ```
 *
 * Subscription callback will be called only once (with 'postdata')
 * ```
 * PubSub.subscribe('SomeTopic', data => {console.log(data)}, {getLast: true, once: true});
 * PubSub.publish('SomeTopic', 'postdata');
 * PubSub.publish('SomeTopic', 'postdata2');
 * ```
 *
 * Subscription callback will be called only once (with 'predata')
 * ```
 * PubSub.publish('SomeTopic', 'predata');
 * PubSub.subscribe('SomeTopic', data => {console.log(data)}, {getLast: true, once: true});
 * PubSub.publish('SomeTopic', 'postdata');
 * ```
 *
 * Only the first subscription of each topic will be notified,
 * the second one will not be called, because the first set stopNotify
 * ```
 * PubSub.publish('SomeTopic');
 * PubSub.subscribe('SomeTopic', () => {console.log(data)}, {getLast: true, stopNotify: true}); // Called
 * PubSub.subscribe('SomeTopic', () => {console.log(data)}, {getLast: true}); // Not Called
 * PubSub.subscribe('AnotherTopic', () => {console.log(data)}, {stopNotify: true});  // Called
 * PubSub.subscribe('AnotherTopic', () => {console.log(data)});  // Not Called
 * PubSub.publish('AnotherTopic');
 * ```
 *
 * You can provide an id to `stopNotify`. Only the subsequent subscription with the same `stopNotify` id are ignored
 * ```
 * PubSub.publish('SomeTopic');
 * PubSub.subscribe('SomeTopic', () => {console.log(data)}, {getLast: true, stopNotify: 'qa'}); // Called
 * PubSub.subscribe('SomeTopic', () => {console.log(data)}, {getLast: true, stopNotify: 'qa'}); // Not Called
 * PubSub.subscribe('SomeTopic', () => {console.log(data)}, {getLast: true}); // Called
 * ```
 *
 * You can specify id in 'getLast' prop to kind of connect different subscriptions,
 * so only the first subscription will get previously published data (if exists)
 *
 * Subscription callback will be called three times (with '1_predata', '1_postdata', '2_postdata')
 * ```
 * PubSub.publish('SomeTopic', 'predata');
 * PubSub.subscribe('SomeTopic', data => {console.log('1_' + data)}, {getLast: 'qa'});
 * PubSub.subscribe('SomeTopic', data => {console.log('2_' + data)}, {getLast: 'qa'});
 * PubSub.publish('SomeTopic', 'postdata');
 * ```
 *
 */
export class PubSub {
  constructor() {
    this.reset();
  }

  reset() {
    this.tokenCounter = 0;
    this.topics = new Map();
    this.lastTaken = new Set();
  }

  subscribe(topic, callback, options = {}) {
    const {getLast = false, getLastImmediately = false, once = false, stopNotify = false} = options;
    const token = String(this.tokenCounter++);
    const subscription = {token, once, callback, stopNotify};
    const getLastIsId = typeof getLast === 'string';
    let topicData = this.topics.get(topic);

    if (!topicData) {
      topicData = {
        name: topic,
        published: false,
        stopNotify: new Set(),
        data: undefined,
        prevData: undefined,
        subscriptions: [],
      };
      this.topics.set(topic, topicData);
    }

    // If for this topic something has been already published, and it has not been marked to stop notifying,
    // And if subscription wants to be notified about that previously published data,
    // and for specified getLast string it's a first subscription,
    // call its callback right on next tick
    if (
      topicData.published &&
      topicData.stopNotify !== true &&
      !topicData.stopNotify.has(stopNotify) &&
      (getLast === true || (getLastIsId && !this.lastTaken.has(getLast)))
    ) {
      this.notifySubscriptions([subscription], topicData, {immediate: getLastImmediately});

      if (!once) {
        topicData.subscriptions.push(subscription);
      }
    } else {
      topicData.subscriptions.push(subscription);
    }

    if (getLastIsId && !this.lastTaken.has(getLast)) {
      this.lastTaken.add(getLast);
    }

    return token;
  }

  publish(topic, data, {immediate = false} = {}) {
    const topicData = this.topics.get(topic);
    const now = Date.now();

    if (topicData) {
      topicData.prevData = topicData.data;
      topicData.data = data;
      topicData.published = true;
      topicData.stopNotify = new Set();
      topicData.publishAt = now;

      if (topicData.subscriptions.length) {
        this.notifySubscriptions(topicData.subscriptions, topicData, {immediate});

        if (topicData.subscriptions.some(subscription => subscription.once)) {
          topicData.subscriptions = topicData.subscriptions.filter(subscription => !subscription.once);
        }
      }
    } else {
      this.topics.set(topic, {
        name: topic,
        data,
        published: true,
        stopNotify: new Set(),
        subscriptions: [],
        publishAt: now,
      });
    }
  }

  notifySubscriptions(subscriptions, topicData, {immediate = false} = {}) {
    const notify = () => {
      for (const subscription of subscriptions) {
        if (topicData.stopNotify !== true && !topicData.stopNotify.has(subscription.stopNotify)) {
          subscription.callback(topicData.data, topicData.prevData, {...topicData});
        }

        if (topicData.stopNotify !== true && typeof subscription.stopNotify === 'string') {
          topicData.stopNotify.add(subscription.stopNotify);
        } else if (subscription.stopNotify === true) {
          topicData.stopNotify = true;

          return;
        }
      }
    };

    if (immediate) {
      notify();
    } else {
      Promise.resolve().then(notify);
    }
  }

  unsubscribe(token) {
    for (const topicData of this.topics.values()) {
      if (topicData.subscriptions.some(subscription => subscription.token === token)) {
        topicData.subscriptions = topicData.subscriptions.filter(subscription => subscription.token !== token);

        return true;
      }
    }

    return false;
  }
}

export default new PubSub();
