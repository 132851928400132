/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {createRef, PureComponent} from 'react';
import {connect} from '@illumio-shared/utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import Draggable from 'react-draggable';
import {Icon, Link} from 'components';
import {getHelpPopupSagaFromRoute} from './HelpPopRouteSagaMap';
import {fetchRouteSaga} from './HelpPopSaga';
import {getHelpPopupProps} from './HelpPopupState';
import styles from './HelpPopup.css';

// React to state (redux store) change only if help popup will become visible or it is currently visible (even if new mode will hide it)
// i.e. if our custom areStatesEqual returns true, don't run mapStateToProps
const areStatesEqual = (next, prev) =>
  prev.helppopup.mode === null &&
  next.helppopup.mode === null &&
  next.router.route.name === prev.router.route.name &&
  !getHelpPopupSagaFromRoute(next.router.route.name); // if there is saga for help popup, we want to update

// This component renders the content of the HelpPopup
@connect(getHelpPopupProps, null, null, {areStatesEqual})
export default class HelpPopup extends PureComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.scrollContainer = createRef();

    this.state = {
      x: 0,
      y: 0,
      titleShadow: false,
      footerShadow: false,
    };

    this.draggable = createRef();

    this.openPopOut = this.openPopOut.bind(this);
    this.closePopOut = this.closePopOut.bind(this);

    this.handleDragStop = this.handleDragStop.bind(this);

    this.handleEsc = this.handleEsc.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handlePopOutToggle = this.handlePopOutToggle.bind(this);

    this.saveDraggable = this.saveDraggable.bind(this);
    this.saveDraggingArea = this.saveDraggingArea.bind(this);
    this.saveTitleShadowHelper = this.saveTitleShadowHelper.bind(this);
    this.saveFooterShadowHelper = this.saveFooterShadowHelper.bind(this);

    // Menu should not have close/popin button if it was just opened in separate window manually (means without window.opener)
    this.closable = props.mode !== 'poppedout' || (window && window.opener && window.opener.helpPopupClose);
  }

  componentDidMount() {
    if (this.props.mode === 'poppedout' && window.opener && window.opener.helpPopupClose) {
      window.addEventListener('beforeunload', window.opener.helpPopupClose);
    }

    this.routeSagaTask = this.context.store.runSaga(fetchRouteSaga, {name: this.props.routeName, force: true});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.mode !== prevState.mode || nextProps.routeName !== prevState.routeName) {
      const state = {};

      if (nextProps.routeName !== prevState.routeName) {
        state.routeName = nextProps.routeName;
      }

      if (nextProps.mode !== prevState.mode) {
        state.mode = nextProps.mode;

        if (nextProps.mode === 'inline') {
          // Every time we show draggable inline helppopup (after closed or popped out), set it to default position
          state.x = 0;
          state.y = 0;
        }
      }

      return state;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeName !== prevState.routeName) {
      this.routeSagaTask = this.context.store.runSaga(fetchRouteSaga, {name: this.props.routeName, force: true});
    }

    if (prevState.mode === 'popout' && this.props.mode === 'popout' && this.props.routeName !== prevState.routeName) {
      // Change content of popped out help on main window route change
      this.navigatePopOut(this.props);
    } else if (
      this.props.customHelpPopUpKey !== prevProps.customHelpPopUpKey ||
      this.props.routeParams?.customHelpPopUpKey !== prevProps.routeParams?.customHelpPopUpKey
    ) {
      const customHelpPopUpKey = this.props.customHelpPopUpKey ?? this.props.routeParams?.customHelpPopUpKey;

      if (this.popOutWindow) {
        if (this.popOutWindow.navigate) {
          this.popOutWindow.navigate({params: {customHelpPopUpKey}});
        }
      } else if (this.scrollContainer.current) {
        const selectedNode = this.scrollContainer.current.childNodes[customHelpPopUpKey];

        if (selectedNode) {
          selectedNode.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'});
        }
      }
    } else if (this.props.mode === 'popout') {
      this.openPopOut();
    } else if (this.popOutWindow) {
      this.closePopOut();
    }
  }

  componentWillUnmount() {
    if (this.routeSagaTask.isRunning()) {
      this.routeSagaTask.cancel();
    }
  }

  saveDraggingArea(element) {
    this.draggingAreaDom = element;
  }

  saveDraggable(draggable) {
    this.draggable.current = draggable;

    if (draggable) {
      window.addEventListener('resize', this.handleResize);
    } else {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  saveTitleShadowHelper(element) {
    if (element) {
      this.titleShadowObserver = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting && !this.state.titleShadow) {
          this.setState({titleShadow: true});
        } else if (entry.isIntersecting && this.state.titleShadow) {
          this.setState({titleShadow: false});
        }
      });

      this.titleShadowObserver.observe(element);
    }

    if (!element && this.titleShadowObserver && this.titleShadowHelperDom) {
      this.titleShadowObserver.unobserve(this.titleShadowHelperDom);
    }

    this.titleShadowHelperDom = element;
  }

  saveFooterShadowHelper(element) {
    if (element) {
      this.footerShadowObserver = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting && !this.state.footerShadow) {
          this.setState({footerShadow: true});
        } else if (entry.isIntersecting && this.state.footerShadow) {
          this.setState({footerShadow: false});
        }
      });

      this.footerShadowObserver.observe(element);
    }

    if (!element && this.footerShadowObserver && this.footerShadowHelperDom) {
      this.footerShadowObserver.unobserve(this.footerShadowHelperDom);
    }

    this.footerShadowHelperDom = element;
  }

  handleEsc(evt) {
    if (evt.key === 'Escape') {
      evt.stopPropagation();
      this.handleClose();
    }
  }

  handleDragStop(evt, {x, y}) {
    this.setState({x, y});
  }

  handleResize() {
    if (this.props.mode !== 'inline' || !this.draggable.current) {
      return;
    }

    const {x, y} = this.state;
    const draggableRect = this.draggable.current.getBoundingClientRect();
    const draggingAreaRect = this.draggingAreaDom.getBoundingClientRect();

    let xNew = x;
    let yNew = y;

    // transformX is negative, because we start from right side, so we subtract it from width to actually sum them,
    // and if sum is bigger that allowed width, transformX should become equal to negative width difference
    if (draggableRect.width - x > draggingAreaRect.width) {
      xNew = draggableRect.width - draggingAreaRect.width;

      if (xNew > 0) {
        xNew = 0;
      }
    }

    // transformY is negative, because we start from bottom side, so we subtract it from height to actually sum them,
    // and if sum is bigger that allowed height, transformY should become equal to negative height difference
    if (draggableRect.height - y > draggingAreaRect.height) {
      yNew = draggableRect.height - draggingAreaRect.height;

      if (yNew > 0) {
        yNew = 0;
      }
    }

    if (xNew !== x || yNew !== y) {
      this.setState({x: xNew, y: yNew});
    }
  }

  handleClose() {
    this.props.dispatch({type: 'TOGGLE_HELP_MENU_MODE_WITH_KEY', customHelpPopUpKey: null});

    if (this.props.mode === 'poppedout' && window.opener && window.opener.helpPopupClose) {
      window.opener.helpPopupClose();
    } else {
      this.props.dispatch({type: 'SET_HELP_MENU_MODE', payload: null});
    }
  }

  handlePopOutToggle() {
    this.props.dispatch({type: 'TOGGLE_HELP_MENU_MODE_WITH_KEY', customHelpPopUpKey: null});
    this.props.dispatch({type: 'SET_HELP_MENU_MODE', payload: this.props.mode === 'popout' ? 'inline' : 'popout'});
  }

  handlePopIn() {
    if (window.opener && window.opener.helpPopupPopIn) {
      window.opener.helpPopupPopIn();
    }
  }

  openPopOut() {
    if (!this.popOutWindow) {
      // Open popup window with current route name as help param
      this.popOutWindow = window.open(
        `./#/helppopup?page=${this.props.routeName}`,
        'helppopup',
        'width=360, height=600',
      );

      // Close popout window on current window close
      window.addEventListener('beforeunload', this.closePopOut);

      // PopIn method in popup window will just toggle mode in current window
      window.helpPopupPopIn = this.handlePopOutToggle;
      // Close from popup just call close handler in current window
      window.helpPopupClose = this.handleClose;
    }
  }

  navigatePopOut(props) {
    if (this.popOutWindow) {
      if (this.popOutWindow.navigate) {
        this.popOutWindow.navigate({params: {page: props.routeName}, replace: true});
      } else {
        this.popOutWindow.location.href = `./#/helppopup?page=${props.routeName}`;
      }
    }
  }

  closePopOut() {
    window.removeEventListener('beforeunload', this.closePopOut);

    try {
      // Need to remove beforeunload to prevent calling handleClose from popuot if we call closePopOut from main window
      this.popOutWindow.removeEventListener('beforeunload', window.helpPopupClose);
      this.popOutWindow.close();
    } finally {
      this.popOutWindow = null;
    }

    delete window.helpPopupPopIn;
    delete window.helpPopupClose;
  }

  render() {
    const {mode, cards, customItems, supportUrl, supportPortalSearchBase} = this.props;

    if (!mode || mode === 'popout') {
      return null;
    }

    const helppopup = (
      <div
        tabIndex="-1"
        onKeyDown={this.handleEsc}
        className={cx(styles.body, {[styles.standalone]: mode === 'poppedout'})}
      >
        <div className={cx(styles.titlebar, {[styles.titlebarShadow]: this.state.titleShadow})}>
          <div className={styles.title}>
            <div className={styles.grab}>
              {mode === 'inline' && <Icon name="grab-handle" theme={styles} themePrefix="grab-" />}
            </div>
            {intl('Common.Help')}
          </div>
          {this.closable &&
            (mode === 'poppedout' ? (
              <div className={styles.collapse} onClick={this.handlePopIn}>
                <Icon name="pop-in" />
              </div>
            ) : (
              <div className={styles.expand} onClick={this.handlePopOutToggle}>
                <Icon name="pop-out" />
              </div>
            ))}
          {this.closable && (
            <div className={styles.close} onClick={this.handleClose}>
              <Icon name="close" />
            </div>
          )}
        </div>

        <div className={styles.cards}>
          <div ref={this.saveTitleShadowHelper} />
          {cards.map(card => (
            <div key={card.title} className={styles.card}>
              <div className={styles.cardTitle}>{card.title}</div>
              <div className={styles.cardSubtitle}>{card.subtitle}</div>
              {card.title !== intl('Help.Title.NoHelp') && !card.noLink && (
                <div className={styles.cardLink}>
                  <Link
                    href={supportPortalSearchBase + card.title}
                    target="_blank"
                    theme={styles}
                    themePrefix="cardLink-"
                    themeCompose="merge"
                  >
                    {intl('Help.Menu.ShowMore')}
                    <Icon name="external-link" theme={styles} themePrefix="cardLink-" />
                  </Link>
                </div>
              )}
            </div>
          ))}
          <div ref={this.scrollContainer}>
            {customItems?.map((customItem, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.cardTitle}>{customItem.title}</div>
                <div className={styles.customCardSubtitle}>{customItem.subtitle}</div>
              </div>
            ))}
          </div>
          <span ref={this.saveFooterShadowHelper} />
        </div>

        <div className={cx(styles.footer, {[styles.footerShadow]: this.state.footerShadow})}>
          <Link
            href={`${supportUrl}/index.html`}
            target="_blank"
            theme={styles}
            themePrefix="footer-"
            themeCompose="merge"
          >
            {intl('Help.Menu.VisitSupportPortal')}
            <Icon name="external-link" theme={styles} themePrefix="footer-" />
          </Link>
        </div>
      </div>
    );

    if (mode === 'inline') {
      return (
        <div ref={this.saveDraggingArea} className={styles.draggingArea}>
          <Draggable
            bounds="parent"
            handle={`.${styles.title.split(' ').shift()}`}
            nodeRef={this.draggable}
            position={this.state}
            onStop={this.handleDragStop}
          >
            <div ref={this.saveDraggable} className={styles.draggable}>
              {helppopup}
            </div>
          </Draggable>
        </div>
      );
    }

    return helppopup;
  }
}
