/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import styles from './DateTimePicker.css';
import {tidUtils} from '@illumio-shared/utils';

export default class DateTimePicker extends Component {
  static propTypes = {
    tid: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    onDateChange: PropTypes.func.isRequired,
    active: PropTypes.bool, // active = DateTimePicker should be shown
    direction: PropTypes.oneOf(['up', 'down']), // direction = which direction the DateTimePicker should open i.e. up, down
    small: PropTypes.bool, // true means child Date and Time pickers will have widths of 260px, false means 300px. Default is false
    shadow: PropTypes.bool, // true means the DateTimePicker will have a box shadow around it
    type: PropTypes.oneOf(['from', 'to']),
    enableFutureTimeSelection: PropTypes.bool,
    disablePastTimeSelection: PropTypes.bool,
  };

  static defaultProps = {
    active: false,
    direction: 'down',
    small: false,
    shadow: false,
    enableFutureTimeSelection: false,
    disablePastTimeSelection: false,
  };

  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  handleDateChange(date) {
    const {enableFutureTimeSelection, disablePastTimeSelection} = this.props;
    const dateWithTime = new Date(date);
    const nowdate = new Date();

    if (this.props.date) {
      if (
        !enableFutureTimeSelection &&
        dateWithTime.getFullYear() === nowdate.getFullYear() &&
        dateWithTime.getDate() === nowdate.getDate() &&
        this.props.date.getHours() >= nowdate.getHours()
      ) {
        if (this.props.date.getMinutes() >= nowdate.getMinutes()) {
          dateWithTime.setHours(nowdate.getHours(), nowdate.getMinutes());
        } else {
          dateWithTime.setHours(nowdate.getHours(), this.props.date.getMinutes());
        }
      } else if (
        disablePastTimeSelection &&
        dateWithTime.getFullYear() === nowdate.getFullYear() &&
        dateWithTime.getDate() === nowdate.getDate() &&
        this.props.date.getHours() <= nowdate.getHours()
      ) {
        if (this.props.date.getMinutes() <= nowdate.getMinutes()) {
          dateWithTime.setHours(nowdate.getHours(), nowdate.getMinutes());
        } else {
          dateWithTime.setHours(nowdate.getHours(), this.props.date.getMinutes());
        }
      } else {
        dateWithTime.setHours(this.props.date.getHours(), this.props.date.getMinutes());
      }
    } else {
      dateWithTime.setHours(0, 0);
    }

    this.props.onDateChange(dateWithTime);
  }

  handleTimeChange(time) {
    const newDate = this.props.date ? new Date(this.props.date) : new Date();

    if (!this.props.date) {
      newDate.setHours(0, 0);
    }

    if (time.hour !== undefined) {
      newDate.setHours(time.hour);
    }

    if (time.minute !== undefined) {
      newDate.setMinutes(time.minute);
    }

    this.props.onDateChange(newDate);
  }

  render() {
    const {
      tid,
      date,
      shadow,
      active,
      direction,
      absolute,
      small,
      type,
      enableFutureTimeSelection,
      disablePastTimeSelection,
    } = this.props;
    const dateTimePickerClass = cx(styles.dateTimePicker, {
      [styles.disabled]: !active,
      [styles.smallPicker]: small,
      [styles.absolute]: absolute,
      [styles.shadow]: shadow,
      [styles.openUp]: direction === 'up',
    });

    return (
      <div data-tid={tidUtils.getTid('datetimepicker', tid)} className={dateTimePickerClass}>
        <DatePicker
          active
          tid={tid}
          date={date}
          small={small}
          single={false}
          onDateChange={this.handleDateChange}
          enableFutureTimeSelection={enableFutureTimeSelection}
          disablePastTimeSelection={disablePastTimeSelection}
        />
        <TimePicker
          active
          tid={tid}
          small={small}
          single={false}
          date={date}
          hour={date ? date.getHours() : null}
          minute={date ? date.getMinutes() : null}
          onChange={this.handleTimeChange}
          type={type}
          enableFutureTimeSelection={enableFutureTimeSelection}
          disablePastTimeSelection={disablePastTimeSelection}
        />
      </div>
    );
  }
}
