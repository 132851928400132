/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {Component, createRef, cloneElement, type KeyboardEvent} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {Icon, OptionItem, TypedMessages, type IconProps} from 'components';
import {domUtils, reactUtils, tidUtils} from '@illumio-shared/utils';
import styles from './OptionSelector.css';
import optionItemStyles from './OptionItem.css';
import type {Item} from './OptionItem';
import type {RefObject} from 'use-callback-ref/dist/es5/types';
import type {TypedMessage} from 'components/TypedMessages/TypedMessages';

const defaultSelectorTid = 'comp-field-selector';
const ErrorMessageTid = 'comp-field-error-message';
const WarningMessageTid = 'comp-field-warning-message';

function getSelectableChildren(element: HTMLElement) {
  return Array.from(element.childNodes).filter(
    child => child instanceof HTMLElement && !child.classList.contains(optionItemStyles.notSelectable),
  ) as HTMLElement[];
}

export interface Option extends Item {
  key?: 'custom' | (string & {_who: unknown});
}

export interface CustomPicker {
  label: string;
  component: JSX.Element;
  props?: Record<string, unknown>;
}

export interface OptionSelectorProps extends ThemeProps {
  // An error message to show but by default it isn't shown unless showError is set to true
  // Passing in boolean[true | false] will not show errorMessage
  errorMessage?: boolean | string;
  warningMessage?: boolean | string;
  disableErrorMessage?: boolean;
  options: Option[];
  // arbitrary unique name
  name: string;
  // When value is passed in then it is controlled
  value?: Option;
  // When initialValue is passed in then it is uncontrolled
  initialValue?: Option;
  disabled?: boolean;
  // Icon properties
  iconSettings?: IconProps;
  // Callback that is called on upon change, required in case of controlled behavior or uncontrolled
  onChange?(event?: domUtils.MouseEventLike, option?: Option): void;
  onCancel?(): void;
  // Callback that is called after changed checked state has been rendered. Useful in case of uncontrolled behavior to notify parent
  onAfterChange?(value: unknown, name: string): void;
  placeholder?: string;
  // tid - specific instead of using default 'name' as tid
  tid?: string;
  // valueToNumber: true - convert the value to numeric
  // e.g. <li value='12'>, 12 will convert to Number(12) to sync with formik's values and this Component
  valueToNumber?: boolean;
  // Exclude dropdown options
  excludeOptions?: unknown[];
  // onHandleTypedMessage
  onHandleTypedMessage?(): TypedMessage;
  customPickers?: Record<string, CustomPicker>;
  // title label displayed in front of field
  title?: string;
}

type OptionSelectorPropsIn = Readonly<
  reactUtils.WithDefaultProps<OptionSelectorProps, typeof OptionSelector.defaultProps>
>;

interface OptionSelectorState {
  active: boolean;
  customPickerActive: boolean;
  hiddenScroll: boolean;
  value?: Option;
  focus: boolean;
  controlled: boolean;
}

export default class OptionSelector extends Component<OptionSelectorPropsIn, OptionSelectorState> {
  static defaultProps = {
    disabled: false,
    placeholder: intl('Forms.SelectOption'),
    valueToNumber: false,
    tid: '',
    onChange: _.noop,
    disableErrorMessage: false,
  };

  selector: RefObject<HTMLDivElement>;
  items: RefObject<HTMLDivElement>;

  constructor(props: OptionSelectorPropsIn) {
    super(props);

    this.selector = createRef();

    this.items = createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyDownOpen = this.handleKeyDownOpen.bind(this);

    this.handleItemOnChange = this.handleItemOnChange.bind(this);
    this.handleOnFocusSelector = this.handleOnFocusSelector.bind(this);

    this.getCustomPicker = this.getCustomPicker.bind(this);
    this.handleCustomPickerCancel = this.handleCustomPickerCancel.bind(this);
    this.handleCustomPickerApply = this.handleCustomPickerApply.bind(this);

    this.state = {
      active: false,
      customPickerActive: false,
      hiddenScroll: false,
      value: (props.value === undefined && props.initialValue) || undefined,
      focus: false,
      controlled: false,
    };
  }

  static getDerivedStateFromProps(nextProps: OptionSelectorPropsIn, prevState: OptionSelectorState) {
    // null indicates controlled
    const controlled = nextProps.value === null || typeof nextProps.value === 'object';

    if (controlled && nextProps.value !== prevState.value) {
      // Update value in controlled state.
      return {
        controlled,
        value: nextProps.value,
      };
    }

    return null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
  }

  componentDidUpdate(_: OptionSelectorPropsIn, prevState: OptionSelectorState) {
    // When prevActive === true which means the dropdown selection was previous shown is then
    // closed by prevActive === false, call the parent's controlled onChange handler()
    if (this.state.controlled && prevState.active && !this.state.active) {
      this.props.onChange();
    }

    if (typeof this.props.onAfterChange === 'function' && prevState.value !== this.state.value) {
      this.props.onAfterChange(this.state.value, this.props.name);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
  }

  setSelectedValue(item: Option, evt: domUtils.MouseEventLike): void {
    const {onChange} = this.props;
    const {controlled} = this.state;

    if (controlled) {
      onChange(evt, item);
    } else {
      this.setState({value: item});
    }

    this.setState(prevState => ({
      active: !prevState.active,
    }));
  }

  private getCustomPicker(selectedOption?: Option) {
    const {options, customPickers} = this.props;
    const customPickerName = options.find(option => selectedOption === option && option.key === 'custom')?.value;
    let customPickerElement;
    let customPickerLabel;

    if (customPickerName && customPickers) {
      const customPicker = customPickers[String(customPickerName)];

      if (customPicker) {
        customPickerLabel = customPicker.label;

        if (this.state.customPickerActive) {
          customPickerElement = cloneElement(customPicker.component, {
            onApply: this.handleCustomPickerApply,
            onCancel: this.handleCustomPickerCancel,
            onSave: this.handleCustomPickerApply,
            onClose: this.handleCustomPickerCancel,
            ...customPicker.props,
          });
        }
      }
    }

    return [customPickerElement, customPickerLabel] as const;
  }

  private handleOnFocusSelector() {
    this.setState(() => ({focus: true}));
  }

  private handleClick() {
    this.setState(prevState => ({
      active: !prevState.active,
    }));
  }

  private handleDocumentMouseDown(evt: MouseEvent) {
    if (evt.target instanceof Node && this.selector.current?.contains(evt.target) === false) {
      this.setState({active: false, focus: false});

      // Handle custom picker's cancel
      if (this.state.customPickerActive) {
        this.setState({customPickerActive: false});

        if (this.props.onCancel) {
          this.props.onCancel();
        }
      }
    }
  }

  private handleMiscKeys(evt: KeyboardEvent) {
    let isKeyMatched = false;

    if ((evt.key === 'Tab' && !this.state.customPickerActive) || evt.key === 'Escape') {
      this.setState({active: false, focus: false});
      isKeyMatched = true;
    }

    return isKeyMatched;
  }

  /*
   * Handles the arrow down, up, enter key on the items list
   */
  private handleKeyDown(evt: KeyboardEvent, item: Option) {
    domUtils.preventEvent(evt);

    const isKeyExist = this.handleMiscKeys(evt);

    if (!isKeyExist) {
      // e.target is where the event occurred
      const target = evt.target;

      switch (evt.key) {
        case 'ArrowDown':
          this.focusNextItem(target);
          break;
        case 'ArrowUp':
          this.focusPreviousItem(target);
          break;
        case 'Enter':
          this.handleItemOnChange(evt, item);
          break;
      }
    }
  }

  /*
   * Handles the arrow down, up, enter key on the parent container div not the items list
   */
  private handleKeyDownOpen(evt: KeyboardEvent) {
    evt.stopPropagation();

    const isKeyExist = this.handleMiscKeys(evt);

    if (!isKeyExist) {
      switch (evt.key) {
        case 'ArrowDown':
        case 'Enter':
          this.focusItem(true);
          break;
        case 'ArrowUp':
          this.focusItem(false);
          break;
      }
    }
  }

  private handleItemOnChange(evt: domUtils.MouseEventLike, item: Option) {
    const {onChange} = this.props;
    const {controlled} = this.state;

    if (controlled) {
      onChange(evt, item);
    } else {
      this.setState({value: item});
    }

    if (item.key === 'custom') {
      this.setState(() => ({
        customPickerActive: true,
      }));
    } else {
      this.setState(prevState => ({
        active: !prevState.active,
      }));
    }
  }

  private handleScrollView(node: Element) {
    node.scrollIntoView({behavior: 'auto', block: 'nearest', inline: 'nearest'});
  }

  private handleCustomPickerApply(evt: domUtils.MouseEventLike, value: Option) {
    if (this.props.onChange) {
      this.props.onChange(evt, value);
    }

    this.setState(prevState => ({
      active: !prevState.active,
      customPickerActive: false,
    }));
  }

  private handleCustomPickerCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }

    this.setState(prevState => ({
      active: !prevState.active,
      customPickerActive: false,
    }));
  }

  focusItem(active: boolean): void {
    if (active) {
      this.setState(
        () => ({active, hiddenScroll: true}),
        () => {
          // component is re-rendered and setState complete thus able to access the items for this callback
          // set the first item when the user the arrow down
          const target = this.items.current && getSelectableChildren(this.items.current)[0];

          if (target) {
            target.focus();
          }
        },
      );
    } else {
      this.setState({active});
    }
  }

  focusPreviousItem(focusedItem: EventTarget): void {
    if (this.items.current) {
      const items = getSelectableChildren(this.items.current);

      if (focusedItem instanceof HTMLElement && items.includes(focusedItem)) {
        const focusedItemIndex = items.indexOf(focusedItem);
        // don't loop through - stop at the last item when using the arrow up
        const previousItem = items[focusedItemIndex - 1] || items[0];

        previousItem.focus();
        this.handleScrollView(items[focusedItemIndex]);

        if (this.state.hiddenScroll) {
          this.setState({hiddenScroll: false});
        }
      }
    }
  }

  focusNextItem(focusedItem: EventTarget): void {
    if (this.items.current) {
      const items = getSelectableChildren(this.items.current);

      if (focusedItem instanceof HTMLElement && items.includes(focusedItem)) {
        const focusedItemIndex = items.indexOf(focusedItem);
        const len = items.length - 1;
        // don't loop through - stop at the last item when using the arrow down
        const nextItem = items[focusedItemIndex + 1] || items[len];

        nextItem.focus();
        this.handleScrollView(items[focusedItemIndex]);

        if (this.state.hiddenScroll) {
          this.setState({hiddenScroll: false});
        }
      }
    }
  }

  render() {
    const {
      options,
      name,
      errorMessage,
      disabled,
      iconSettings,
      tid,
      placeholder,
      disableErrorMessage,
      onHandleTypedMessage,
      title,
      warningMessage,
    } = this.props;
    const theme = composeThemeFromProps(styles, this.props);
    const {value, focus} = this.state;
    const active = this.state.active;
    const iconName = this.state.active ? 'up' : 'down';
    const tidName = tid || name;

    const selectorMain = cx(theme.selectorMain, {
      [theme.selectorActive]: active && this.selector.current !== document.activeElement,
      [theme.selectorHighlight]: active,
      [theme.disabled]: disabled,
      [theme.selectorError]: !disableErrorMessage && (errorMessage === undefined || typeof errorMessage === 'string'),
      [theme.focused]: focus,
    });

    let label = placeholder;
    let iconElement: JSX.Element | string = '';

    const [customPickerElement, customPickerLabel] = this.getCustomPicker(value);

    const optionItems = customPickerElement
      ? []
      : options.reduce((option: JSX.Element[], val, index) => {
          // Exclude specific dropdown options.
          if (this.props.excludeOptions?.includes(val.value)) {
            return option;
          }

          let labelIsActive = false;

          if (_.isEqual(value, val)) {
            label = customPickerLabel || val.label;
            labelIsActive = true;
          }

          option.push(
            <OptionItem
              key={index}
              item={val}
              tid={tidName}
              theme={theme}
              labelIsActive={labelIsActive}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleItemOnChange}
            />,
          );

          return option;
        }, []);

    // with Icon
    if (typeof iconSettings === 'object') {
      iconElement = <Icon theme={theme} {...iconSettings} />;
    }

    // By not having css hiddenScroll, the first element is not viewable in the scroll view when overflow-y: scroll is set thus
    // set the overflow-y: hidden to allow the first element to be viewable for first arrow down in the parent selector div
    const dropdownClassName = cx(theme.dropdown, {
      [theme.hiddenScroll]: this.state.hiddenScroll,
      [theme.customPicker]: customPickerElement,
    });

    const labelClassName = cx(theme.selectMainLabel, {[theme.selectorPlaceHolder]: label === placeholder});
    const showCustomMessage = typeof onHandleTypedMessage === 'function';
    const showError = !showCustomMessage && !disableErrorMessage;

    return (
      <>
        <div
          tabIndex={0}
          ref={this.selector}
          data-tid={tidUtils.getTid(defaultSelectorTid, tidName) + (disabled ? ' dropdown-disabled' : '')}
          className={theme.selectorMainWrapper}
          onFocus={this.handleOnFocusSelector}
          onKeyDown={this.handleKeyDownOpen}
        >
          <div className={selectorMain} onClick={disabled === false ? this.handleClick : undefined}>
            {iconElement}
            {title && <label className={theme.title}>{title}</label>}
            <div className={labelClassName}>{customPickerLabel || label}</div>
            <div className={theme.iconCheck}>
              <Icon name={iconName} theme={{...theme, svg: theme.iconSvgArrowDown}} />
            </div>
          </div>
          {this.state.active && (
            <div className={theme.dropdownWrapper}>
              <div ref={this.items} className={dropdownClassName}>
                {customPickerElement || optionItems}
              </div>
            </div>
          )}
        </div>
        {(showCustomMessage || showError || warningMessage) && (
          <div>
            <TypedMessages key="status" gap="gapXSmall">
              {[
                showCustomMessage ? onHandleTypedMessage() : null,

                showError && errorMessage
                  ? {content: errorMessage, color: 'error', fontSize: 'var(--12px)', tid: ErrorMessageTid}
                  : null,

                warningMessage
                  ? {
                      content: warningMessage,
                      color: 'warning',
                      fontSize: 'var(--12px)',
                      tid: WarningMessageTid,
                    }
                  : null,
              ]}
            </TypedMessages>
          </div>
        )}
      </>
    );
  }
}
