/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {stringifyPortObjectReadonly} from 'containers/Service/ServiceUtils';
import type {IconProps} from 'components';
import {portUtils} from '@illumio-shared/utils';

export type UpdateType = 'create' | 'delete' | 'update';

export type UpdateTypeWithTooltip = {
  updateType?: UpdateType;
  updateTypeTooltip?: IconProps['tooltip'];
};

/**
 * Determines if update type needs to be rendered in a pill and returns updateType and updateTypeTooltip
 *
 * @param {*}
 * @returns {Object}
 */
export const getUpdateTypeProps = (
  options: UpdateTypeWithTooltip & {
    deleted?: boolean;
    pversion?: number | string;
    object?: string;
  } = {},
): UpdateTypeWithTooltip | undefined => {
  const {updateType, updateTypeTooltip, deleted, pversion, object} = options;

  // Hide update type when:
  //  1. Object is removed as a part of diffing
  //  2. policy version is active
  //  3. No actual draft exists
  if (deleted || pversion === 'active' || !updateType) {
    return;
  }

  return {
    updateType,
    updateTypeTooltip: updateTypeTooltip ?? intl('Pill.Tooltip.UpdateTypeTooltip', {object, updateType}),
  };
};

const generateServicePort = (servicePorts: portUtils.IcmpObjects[]) =>
  servicePorts.map(value => stringifyPortObjectReadonly(value));

export const getServiceDefinitionString = (
  servicePorts: portUtils.IcmpObjects[],
  showPorts?: number | 'all' | true,
): string => {
  const totalLength = servicePorts.length;
  let portsToDisplay = 3; // By default three service definitions are displayed as secondary text in Pill

  if (typeof showPorts === 'number') {
    portsToDisplay = showPorts;
  } else if (showPorts === 'all') {
    portsToDisplay = totalLength;
  }

  // Service definition is split into two arrays, one with port/Protocol only and another one with process/service name
  // PortProtoDef list is displayed in a single line, processDef are displayed in separate lines as these def can be long
  const {portProtoDef, processDef} = servicePorts.reduce(
    (acc, value) => {
      if (value.process_name || value.service_name) {
        acc.processDef.push(value);
      } else {
        acc.portProtoDef.push(value);
      }

      return acc;
    },
    {portProtoDef: [], processDef: []} as {portProtoDef: portUtils.IcmpObjects[]; processDef: portUtils.IcmpObjects[]},
  );

  let portProtoDefString: string;
  let processDefString: string;
  let portProcessDefJoiner: string;

  if (totalLength > portsToDisplay) {
    // If total definitions is more than that need to be displayed then a +N more string will be added at the end
    // First all the ports only definitions are included in a single line and, then
    // process definition entries will be displayed in multiple lines

    const countMoreString = intl('Common.CountMore', {count: totalLength - portsToDisplay});

    if (portProtoDef.length > portsToDisplay) {
      // If ports definitions are more than portsToDisplay count then add +N more in the first line and return,
      // No process definitions to be displayed in this case
      portProtoDefString = generateServicePort(portProtoDef.slice(0, portsToDisplay)).join(', ');

      return `${portProtoDefString} ${countMoreString}`;
    }

    portProtoDefString = generateServicePort(portProtoDef).join(', ');
    processDefString = generateServicePort(processDef.slice(0, portsToDisplay - portProtoDef.length)).join('\n');
    portProcessDefJoiner = portProtoDefString && processDefString ? '\n' : ''; //Join by newline if both exists

    return `${portProtoDefString}${portProcessDefJoiner}${processDefString}\n${countMoreString}`;
  }

  // Total service definition count is less than numberneed, in this case all the ports only definition is displayed in the first line,
  // and process definitions in separate multiple lines
  portProtoDefString = generateServicePort(portProtoDef).join(', ');
  processDefString = generateServicePort(processDef).join('\n');
  portProcessDefJoiner = portProtoDefString && processDefString ? '\n' : ''; //Join by newline if both exists

  return `${portProtoDefString}${portProcessDefJoiner}${processDefString}`;
};
