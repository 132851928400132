/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {Button} from 'components';
import {getProductsMap} from '../Item/Edit/MyManagedTenantsEditState';
import * as GridUtils from 'components/Grid/GridUtils';
import {clickableColumn} from 'components/Grid/GridUtils';

export const gridSettings = createSelector([getProductsMap], productsMap => ({
  id: 'mytenantslist',
  sort: 'display_name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    display_name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'display_name',
    },
    domain_name: {
      header: intl('Common.Domain'),
      value: 'domain_name',
    },
    product_name: {
      header: intl('Common.Product'),
      value: ({row}) => productsMap.get(row.data.product_name) || row.data.product_name,
    },
    num_paired_server_vens: {
      header: intl('MSP.ServerVENs'),
      value: 'num_paired_server_vens',
    },
    num_paired_endpoint_vens: {
      header: intl('MSP.EndpointVENs'),
      value: 'num_paired_endpoint_vens',
    },
    created_at: {
      isDate: 'L_HH_mm_ss',
      header: intl('Labels.Detail.CreatedAt'),
      value: 'created_at',
    },
    managed_org_url: clickableColumn({
      required: true,
      sortable: false,
      header: intl('LoadBalancers.Detail.Manage'),
      value: ({row: {data}}) => data?.managed_org_url,
      format({value, clickableRef}) {
        return value ? (
          <Button.Link ref={clickableRef} color="standard" size="small" text={intl('MSP.ManageTenant')} href={value} />
        ) : null;
      },
    }),
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['display_name'], size: 'minmax(120px, auto)'},
      {columns: ['domain_name'], size: 'minmax(120px, auto)'},
      {columns: ['product_name'], size: 'minmax(120px, auto)'},
      {columns: ['num_paired_server_vens'], size: 'minmax(100px, auto)'},
      {columns: ['num_paired_endpoint_vens'], size: 'minmax(100px, auto)'},
      {columns: ['created_at'], size: 'minmax(230px, auto)'},
      {columns: ['managed_org_url'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['display_name'], size: 'minmax(100px, auto)'},
            {columns: ['domain_name'], size: 'minmax(100px, auto)'},
            {columns: ['product_name'], size: 'minmax(100px, auto)'},
            {columns: ['num_paired_server_vens'], size: 'minmax(100px, auto)'},
            {columns: ['num_paired_endpoint_vens'], size: 'minmax(100px, auto)'},
            {columns: ['created_at'], size: 'minmax(230px, auto)'},
            {columns: ['managed_org_url'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['display_name'], size: 'minmax(100px, auto)'},
          {columns: ['domain_name'], size: 'minmax(100px, auto)'},
          {columns: ['product_name'], size: 'minmax(100px, auto)'},
          {columns: ['num_paired_server_vens'], size: 'minmax(100px, auto)'},
          {columns: ['num_paired_endpoint_vens'], size: 'minmax(100px, auto)'},
          {columns: ['created_at'], size: 'minmax(230px, auto)'},
          {columns: ['managed_org_url'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['display_name'], size: 'minmax(100px, auto)'},
            {columns: ['domain_name'], size: 'minmax(100px, auto)'},
            {columns: ['product_name'], size: 'minmax(100px, auto)'},
            {
              columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at'],
              size: 'minmax(230px, auto)',
            },
            {columns: ['managed_org_url'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['display_name'], size: 'minmax(100px, auto)'},
          {columns: ['domain_name'], size: 'minmax(100px, auto)'},
          {columns: ['product_name'], size: 'minmax(100px, auto)'},
          {columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at'], size: 'minmax(230px, auto)'},
          {columns: ['managed_org_url'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['display_name'], size: 'minmax(100px, auto)'},
            {columns: ['domain_name', 'product_name'], size: 'minmax(100px, auto)'},
            {
              columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at'],
              size: 'minmax(230px, auto)',
            },
            {columns: ['managed_org_url'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['display_name'], size: 'minmax(100px, auto)'},
          {columns: ['domain_name', 'product_name'], size: 'minmax(100px, auto)'},
          {columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at'], size: 'minmax(230px, auto)'},
          {columns: ['managed_org_url'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['display_name', 'domain_name', 'product_name'], size: 'minmax(100px, auto)'},
            {
              columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at'],
              size: 'minmax(230px, auto)',
            },
            {columns: ['managed_org_url'], size: 'minmax(80px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['display_name', 'domain_name', 'product_name'], size: 'minmax(100px, auto)'},
          {columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at'], size: 'minmax(230px, auto)'},
          {columns: ['managed_org_url'], size: 'minmax(80px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['display_name', 'domain_name', 'product_name'], size: 'minmax(150px, auto)'},
            {
              columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at', 'managed_org_url'],
              size: 'minmax(170px, auto)',
            },
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['display_name', 'domain_name', 'product_name'], size: 'minmax(150px, auto)'},
          {
            columns: ['num_paired_server_vens', 'num_paired_endpoint_vens', 'created_at', 'managed_org_url'],
            size: 'minmax(170px, auto)',
          },
        ];
      },
    },
  ],
}));
