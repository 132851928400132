/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isHealthEnabled} from 'containers/App/AppState';
import {isUserOwner, isUserAdmin} from 'containers/User/UserState';
import listReducers from './List/PCESupportBundlesListState';

export default {
  pceSupportBundles: combineReducers({
    ...listReducers,
  }),
};

export const isPCESupportBundlesEnabled = createSelector(
  [isUserOwner, isUserAdmin, isHealthEnabled],
  (userIsOwner, userIsAdmin, healthIsEnabled) => healthIsEnabled && (userIsOwner || userIsAdmin),
);
