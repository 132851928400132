/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import provisionReducers, {getProvisionCompleted} from './Provision/ProvisionState';
import versionListReducers, {getProvisionVersions} from './Version/List/VersionListState';
import pendingListReducers from './Pending/List/PendingListState';
import versionDetailReducers from './Version/Detail/VersionDetailState';
import {getRouteName} from 'containers/App/AppState';
import {getLocalListenOnlyEnableTime} from 'containers/Health/HealthState';

export default {
  provisioning: combineReducers({
    ...provisionReducers,
    ...versionListReducers,
    ...versionDetailReducers,
    ...pendingListReducers,
    // Calculation progress object (red_agents, online_agents, etc.)
    calcProgress(state = {}, {type, data}) {
      switch (type) {
        case 'PROVISION_CALC_PROGRESS':
          return data;
        default:
          return state;
      }
    },
    // If something is being provisioned right now
    calcInProgress(state = false, {type, data}) {
      switch (type) {
        case 'PROVISION_CALC_PROGRESS':
          return data.red_agents.length > 0 && !data.red_agents_timed_out;
        default:
          return state;
      }
    },
    // Calculation progress override count
    calcProgressOverride(state = {}, {type, data}) {
      switch (type) {
        case 'PROVISION_CALC_PROGRESS_OVERRIDE_COUNT':
          return data;
        default:
          return state;
      }
    },
    // If something is being provisioned right now
    calcInProgressOverride(state = false, {type, data}) {
      switch (type) {
        case 'PROVISION_CALC_PROGRESS_OVERRIDE':
          return data;
        default:
          return state;
      }
    },
  }),
};

export const getCalcProgress = state => state.provisioning.calcProgress;
export const getCalcInProgress = state => state.provisioning.calcInProgress;
export const getCalcProgressOverride = state => state.provisioning.calcProgressOverride;
export const getCalcInProgressOverride = state => state.provisioning.calcInProgressOverride;

export const getProvisioningPage = createSelector(
  [
    getCalcProgress,
    getCalcInProgress,
    getCalcProgressOverride,
    getCalcInProgressOverride,
    getProvisionVersions,
    getProvisionCompleted,
    getLocalListenOnlyEnableTime,
    getRouteName,
  ],
  (provisionProgress, calcInProgress, overrideCount, override, versionList, complete, listenOnlyMode, routeName) => {
    const red = provisionProgress.red_agents && provisionProgress.red_agents.length;
    const timeout = provisionProgress.red_agents_timed_out;
    const total = provisionProgress.online_agents;
    let synced = total - red;

    if (override) {
      synced = total - Math.min(total, overrideCount);
    }

    return {red, total, timeout, override, synced, versionList, complete, listenOnlyMode, routeName, calcInProgress};
  },
);
