/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getAllUsersMap} from 'containers/User/UserState';
import {getRouteCurrentParams} from 'containers/App/AppState';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getLoadBalancers, enableLoadBalancerControls, getNetworkFunctionControls} from '../List/LoadBalancerListState';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'LOADBALANCER_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getLoadBalancerInstance = state => state.loadBalancer.instance;

export const getLoadBalancersMap = createSelector(
  [getLoadBalancers, getLoadBalancerInstance],
  (loadBalancers, loadBalancerInstance) =>
    loadBalancers.reduce(
      (map, loadBalancer) => map.set(loadBalancer.href, loadBalancer),
      new Map(loadBalancerInstance ? [[loadBalancerInstance.href, loadBalancerInstance]] : []),
    ),
);

export const getLoadBalancerItemPage = createSelector(
  [
    getLoadBalancerInstance,
    getAllUsersMap,
    getRouteCurrentParams,
    enableLoadBalancerControls,
    getNetworkFunctionControls,
  ],
  (loadBalancer, usersMap, currentRouteParams, enableSLBControls, nfcs) => {
    const createdBy = fillUserInfo(usersMap, loadBalancer.created_by);
    const updatedBy = fillUserInfo(usersMap, loadBalancer.updated_by);
    const isUserReadOnly = loadBalancer.isUserReadOnly;
    const nfc = nfcs.find(nfc => nfc.href === loadBalancer.nfc.href);
    const nfcHostname = Array.isArray(nfc.hostname) ? nfc.hostname[0] : nfc.hostname; // API provided this string as 1-element array

    return {
      loadBalancer,
      nfcHostname,
      createdBy,
      updatedBy,
      isUserReadOnly,
      currentRouteParams,
      enableSLBControls,
    };
  },
);
