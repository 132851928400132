/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Spinner} from 'components';
import {hrefUtils, webStorageUtils} from '@illumio-shared/utils';
import {unSerializeRule} from '../../LabelRulesUtils';
import styles from './ApplyLabelRules.css';
import {formatTimeLeft} from 'containers/PairingProfile/PairingProfileUtils';

const SAVED_ASYNC_JOB_STORAGE_KEY = 'labelingRules_savedAsyncJob';

export const getAssignLabelsSuccessMessage = ({nWorkloads}) => {
  return (
    <span className={styles.assignLabelsSuccessWrapper}>
      <span className={styles.assignLabelsSuccessMessage}>
        {intl('LabelRules.AssignLabelsSuccess', {count: nWorkloads})}{' '}
      </span>
    </span>
  );
};

export const getGridEmptyMessage = ({error, loading}) => {
  if (error) {
    return intl('LabelRules.SomethingWentWrong');
  }

  if (loading) {
    return (
      <div data-tid="label-rules-side-panel-loading" className={styles.applyRulesLoadingContainer}>
        <Spinner themePrefix="applyRulesSpinner-" theme={styles} color="dark" size="xxlarge" />
        <h3 className={styles.applyRulesLoadingHeader}>{intl('Common.Loading')}</h3>
        <p className={styles.applyRulesLoadingMessage}>{intl('LabelRules.ApplyRulesLoadingMessage')}</p>
      </div>
    );
  }

  return intl('LabelRules.ApplyRulesNoUpdates');
};

/**
 * generates a unique id used to track an async job across multiple requests, and access its result,
 * progress, & error states in the store.
 * @returns {`${string}-${string}-${string}-${string}-${string}`}
 */
export const createJobId = () => crypto.randomUUID();

/**
 * converts the download API's label assignments format to the format used by the UI.
 * @param assignments
 * @returns {{href: *, ruleHref: *, value: *, key: *}[]}
 */
export const unSerializeLabelAssignments = assignments =>
  (assignments ?? []).map(assignment => ({
    href: assignment.href,
    key: assignment.key,
    value: assignment.value,
    ...(assignment.label_mapping_rule?.href ? {ruleHref: assignment.label_mapping_rule.href} : {}),
  }));

/**
 * Parses the list of workloads returned from the 'label_mapping_rules.run' request
 * @param workloads
 * @returns {{hostname, href, assignedLabels, existingLabels}}
 */
export const parseAsyncJobResult = (workloads = []) =>
  workloads.map(item => ({
    href: item.href,
    hostname: item.hostname,
    assignLabels: item?.hasOwnProperty('labels_to_assign')
      ? unSerializeLabelAssignments(item.labels_to_assign)
      : undefined,
    existingLabels: item.existing_labels,
  }));

/**
 * un-serializes an async job's params (the params sent when the job was created)
 * @param params
 * @returns {{assignLabels: boolean, rules: {expression: ({childExpressions: expression[], logicOperator: string}|{attribute: string, value: string, operator: string}), updatedBy, href, position, enabled, labels: *[], updatedAt}[]}}
 */
export const unSerializeAsyncJobParams = params => ({
  ...(params?.hasOwnProperty('rules') ? {rules: params.rules.map(rule => unSerializeRule(rule))} : {}),
  ...(params?.hasOwnProperty('assign_labels') ? {assignLabels: params.assign_labels} : {}),
});

/**
 * parses the response from a get async job call
 * @param job
 * @returns {{result, progress: *, href, params: {assignLabels: boolean, rules: {expression: ({childExpressions: expression[], logicOperator: string}|{attribute: string, value: string, operator: string}), updatedBy, href, position, enabled, labels: *[], updatedAt}[]}, uuid: HrefId, status}}
 */
export const parseAsyncJob = job => ({
  uuid: hrefUtils.getId(job.href),
  href: job.href,
  status: job.status, // "queued", "working", "completed", "failed", "killed", "cancelled"
  progress: job.progress_percent,
  createdAt: job.created_at,
  updatedAt: job.updated_at,
  params: unSerializeAsyncJobParams(job.label_mapping_params),
});

/**
 * parses the assign_labels API response
 * @param result
 * @returns {{href: string, status: string}[]}
 */
export const parseAssignLabels = result =>
  (result ?? []).map(item => ({
    href: item.href,
    // TODO: update "status" comment with other enum values when API schema is complete
    status: item.status, // "updated"
  }));

/**
 * Returns a .csv filename with the current date
 * @param prefix {string} - a prefix for the filename (goes before the date, e.g. foobar_)
 * @returns {`{string}${string}.csv`}
 */
export const getExportCSVFilename = (prefix = 'export') => {
  const dateTime = intl.date(Date.now(), {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    format: 'yMd',
  });

  return `${prefix}${dateTime}.csv`;
};

/**
 * converts the provided csv string into a Blob, and downloads it as a file by creating an objectURL, assigning it to an
 * invisible link, and automatically clicking the link.
 * @param csv {string} - the csv data
 * @param filename {string} - custom filename for the file
 */
export const createCSVFileDownload = ({csv = '', filename = 'export.csv'}) => {
  const blob = new Blob([csv]);
  const objectURL = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');

  anchor.style.display = 'none';
  anchor.href = objectURL;
  anchor.download = filename;
  document.body.append(anchor);
  anchor.click();

  window.URL.revokeObjectURL(objectURL);
  anchor.remove();
};

/**
 * stores the startTime and uuid of the current saved async job in local storage
 * @param uuid {string} - uuid of the async job
 * @param started {Date|timestamp|undefined} - when the job was started; if not provided, current datetime is used;
 */
export const storeSavedAsyncJob = ({uuid, started}) => {
  if (uuid) {
    webStorageUtils.setItem(SAVED_ASYNC_JOB_STORAGE_KEY, {
      started: (started ? new Date(started) : new Date()).toISOString(),
      uuid,
    });
  }
};

/**
 * returns the current saved async job details
 * @returns {unknown}
 */
export const getSavedAsyncJob = () => {
  return webStorageUtils.getItem(SAVED_ASYNC_JOB_STORAGE_KEY);
};

/**
 * removes the current saved async job from local storage
 */
export const clearSavedAsyncJob = () => {
  webStorageUtils.removeItem(SAVED_ASYNC_JOB_STORAGE_KEY);
};

/**
 * returns the remaining milliseconds based on the startDate and percentCompleted
 * @param startDate {date|timestamp} - Date object or timestamp of when the job started
 * @param percentCompleted {number} - integer representing the percentage completed (e.g. 30 for 30%)
 * @returns {number|null} - milliseconds remaining; null if startDate or percentCompleted are falsy;
 */
export const getRemainingTime = (startDate, percentCompleted) => {
  percentCompleted = Math.min(Math.max(percentCompleted, 0), 100);

  if (!startDate || !percentCompleted || Number.isNaN(percentCompleted)) {
    return null;
  }

  const elapsedTime = Date.now() - new Date(startDate).getTime();
  const percentRemaining = 100 - percentCompleted;

  return percentRemaining === 0 ? 0 : Math.round((elapsedTime / percentCompleted) * percentRemaining);
};

/**
 * formats the remaining time value to hours, minutes, and seconds;
 * @param remainingTime {number|null} - remaining time in milliseconds
 * @return {string} - remaining time (e.g. 1 hours, 2 minutes, 3 seconds)
 */
export const formatRemainingTime = remainingTime => {
  return remainingTime ? formatTimeLeft(remainingTime / 1000, true, true) : '';
};
