/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {isUserOwner, getUserId, getAllUsersByIdMap} from 'containers/User/UserState';
import {createSelector} from 'reselect';
import {gridSettings} from './LocalUserDetailConfig';
import {getAccessRestrictionOptions} from '../../UsersAndGroupsUtils';
import {
  getFormattedRoleFromHref,
  isGlobalRoleHref,
  getScopeId,
  getScopeAll,
  getLabelObjects,
  getScopeIdStringFromScope,
  areScopesEqual,
  getNumber,
} from 'containers/RBAC/RBACUtils';
import {getRouteParams} from 'containers/App/AppState';
import {getAuthSecPrincipalByUserName} from 'containers/RBAC/AuthSecPrincipalState';
import {getAccessRestrictions} from 'containers/RBAC/AccessRestriction/List/AccessRestrictionListState';
import {getPermissions} from 'containers/RBAC/PermissionState';
import {isAPIAvailable} from 'api/apiUtils';
import {hrefUtils} from '@illumio-shared/utils';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';

const getAuthSecPrincipalFromRouteParams = createSelector(
  [state => state, getAllUsersByIdMap, getRouteParams],
  (state, users, params) => getAuthSecPrincipalByUserName(state, users.get(getNumber(params.id))?.username),
);

export const getLocalUserScopeRows = createSelector(
  [getPermissions, getRouteParams, getUserId, getAuthSecPrincipalFromRouteParams],
  (permissions, params, currentUserId, authSecPrincipal) => {
    const id = getNumber(params.id);
    const isSelfViewing = currentUserId === id;
    const hasDeletePermission = isAPIAvailable('org_permission.delete');

    const scopeRows = permissions.reduce((result, permission) => {
      if (permission.auth_security_principal.href !== authSecPrincipal?.href) {
        return result;
      }

      const scope = getLabelObjects(permission.scope);
      const scopeExists = result.find(
        item =>
          areScopesEqual(item.scope, scope) && isGlobalRoleHref(permission.role.href) === (item.type === 'global'),
      );

      if (scopeExists) {
        if (__ANTMAN__) {
          result.push({
            key: __ANTMAN__
              ? permission.role.href
              : isGlobalRoleHref(permission.role.href)
              ? 'global'
              : `scoped${getScopeIdStringFromScope(scope)}`,
            type: isGlobalRoleHref(permission.role.href) ? 'global' : 'scoped',
            scope,
            scopeHrefs: permission.scope.length ? getScopeId(permission.scope) : getScopeAll(),
            roles: [getFormattedRoleFromHref(permission.role.href)],
            roleHrefs: [permission.role],
            permissionHrefs: [permission.href],
            data: {
              labelsMap: getMappedLabelsForGrid(scope),
            },
          });
        } else {
          scopeExists.roles.push(getFormattedRoleFromHref(permission.role.href));
          scopeExists.roleHrefs.push(permission.role);
          scopeExists.permissionHrefs.push(permission.href);
        }
      } else {
        result.push({
          key: __ANTMAN__
            ? permission.role.href
            : isGlobalRoleHref(permission.role.href)
            ? 'global'
            : `scoped${getScopeIdStringFromScope(scope)}`,
          type: isGlobalRoleHref(permission.role.href) ? 'global' : 'scoped',
          scope,
          scopeHrefs: permission.scope.length ? getScopeId(permission.scope) : getScopeAll(),
          roles: [getFormattedRoleFromHref(permission.role.href)],
          roleHrefs: [permission.role],
          permissionHrefs: [permission.href],
          data: {
            labelsMap: getMappedLabelsForGrid(scope),
          },
        });
      }

      return result;
    }, []);

    scopeRows.forEach(row => {
      // For current user, lock the row that has 'owner' role to prevent him/her from removing his/her owner role.
      const isLock =
        isSelfViewing &&
        row.type === 'global' &&
        row.roleHrefs.some(roleHref => hrefUtils.getId(roleHref.href) === 'owner');
      const isAllowed = hasDeletePermission && !isLock;

      row.selectable = isAllowed && !__ANTMAN__;
      row.removable = isAllowed;
    });

    return scopeRows;
  },
);

export const getGridSettings = createSelector([gridSettings], gridSettings => {
  const columns = {...gridSettings.columns};

  columns.scope.disabled = __ANTMAN__;
  columns.type.disabled = __ANTMAN__;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getLocalUserScopeRows,
  });

export const getLocalUserDetails = createSelector(
  [
    getGrid,
    isUserOwner,
    getRouteParams,
    getAllUsersByIdMap,
    getUserId,
    getAccessRestrictions,
    getAuthSecPrincipalFromRouteParams,
  ],
  (grid, userIsOwner, params, users, currentUserId, accessRestrictions, authSecPrincipal) => {
    const id = getNumber(params.id);
    const user = users.get(id);
    const accessRestriction = authSecPrincipal?.access_restriction
      ? accessRestrictions.find(({href}) => href === authSecPrincipal.access_restriction.href).name
      : intl('Common.None');
    const isSelfViewing = currentUserId === id;
    let userlock = false;

    const accessRestrictionOptions = getAccessRestrictionOptions(accessRestrictions);

    if (user && user.locked) {
      userlock = true;
    }

    return {
      grid,
      userIsOwner,
      authSecPrincipal,
      userlock,
      user,
      currentUserId,
      isSelfViewing,
      accessRestrictionOptions,
      accessRestriction,
    };
  },
);
