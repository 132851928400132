/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import type {
  ComboId,
  ComboMapping,
  GraphCombo,
  GraphManagedEndpoints,
  GraphUnmanagedEndpoints,
  GraphUnmanagedEndpoint,
  Items,
  RegraphCombine,
} from 'containers/IlluminationMap/Graph/MapGraphTypes';
import {
  isManagedEndpoint,
  type EndpointType,
  type EndpointData,
  type LinkData,
  type ParallelCoordinatesLinks,
} from 'containers/IlluminationMap/MapTypes';
import {getInnerComboType} from 'containers/IlluminationMap/Graph/Utils/MapGraphStyleUtils';
import {getComboLabelIdsFromId} from 'containers/IlluminationMap/Graph/Utils/MapGraphComboUtils';
import type {LabelSettings} from 'illumio';
import type {
  FirstLevelLabel,
  ClickedTick,
} from 'containers/IlluminationMap/ParallelCoordinates/MapParallelCoordinatesTypes';
import intl from '@illumio-shared/utils/intl';
import {portUtils} from '@illumio-shared/utils';

export const checkInnerCombos = (
  comboKey: ComboId,
  clickedTick: string,
  combine: RegraphCombine,
  clickedTickType: EndpointType,
): boolean => {
  const focusComboId = clickedTick.replace(`_${clickedTickType}`, '');
  const focusIds = getComboLabelIdsFromId(focusComboId as ComboId).split(',');
  const innerComboType = getInnerComboType(focusComboId as ComboId, combine);
  const keyType = comboKey.split('_').pop();
  const keyId = comboKey.replace(`_${keyType}`, '');
  const isMatched = (Array.isArray(focusIds) ? focusIds : []).every(id => {
    const focusLabelIdRegex = new RegExp(`${id}\\b`);

    return keyId.includes(`_${innerComboType}_`) && keyId.match(focusLabelIdRegex) && keyType === clickedTickType;
  });

  return isMatched;
};

export const getEndpoints = (combos: Record<ComboId, GraphCombo>, clickedTick: string): string[] => {
  const selectedCombo = combos[clickedTick as ComboId];

  const endpointKeys = Object.keys(selectedCombo.endpoints?.workload || selectedCombo.endpoints?.virtualService || {});

  return endpointKeys;
};

export const isManagedEndpointHref = (href: string): boolean => {
  return (
    href.includes('workloads') ||
    href.includes('virtual_services') ||
    href.includes('virtual_server') ||
    href.includes('container_workloads')
  );
};

export const isIPListHref = (href: string): boolean => {
  return href.includes('ip_lists');
};

export const getManagedTicks = (
  comboMapping: ComboMapping,
  combine: RegraphCombine,
  lastGrouping: string,
  type: EndpointType,
  clickedTickArray: string[],
  managedEndpoints: GraphManagedEndpoints,
): string[] => {
  const combos: Record<ComboId, GraphCombo> = comboMapping.combos;
  const comboKeys: ComboId[] = Object.keys(combos) as ComboId[];

  if (!lastGrouping) {
    if (clickedTickArray.length === 1) {
      const managedEndpointHrefs = Object.keys(managedEndpoints);
      const endpointTickKeys = managedEndpointHrefs.filter(href => href.includes(type));

      return endpointTickKeys;
    }

    if (clickedTickArray.length > 1) {
      const lastClickedEndpoint = clickedTickArray && (clickedTickArray.at(-1) as string);

      if (isManagedEndpointHref(lastClickedEndpoint)) {
        const managedEndpointIps = [...managedEndpoints[lastClickedEndpoint].ips];

        return managedEndpointIps;
      }
    }
  }

  const managedTickKeys = comboKeys.reduce((result: string[], comboKey: ComboId): string[] => {
    if (clickedTickArray.length === 1 && comboKey.includes(`_${lastGrouping}_`) && comboKey.includes(type)) {
      result.push(comboKey);
    }

    if (clickedTickArray.length > 1) {
      const lastClickedTick = clickedTickArray.at(-1) as string;

      if (isManagedEndpointHref(lastClickedTick)) {
        const managedEndpointIps = [...managedEndpoints[lastClickedTick].ips];

        managedEndpointIps.forEach(ip => {
          if (!result.includes(ip)) {
            result.push(ip);
          }
        });
      }

      if (lastClickedTick.includes('_nodes_') && comboKey === lastClickedTick) {
        result.push(...getEndpoints(combos, lastClickedTick));
      }

      if (lastClickedTick.includes(`_${combine.properties[0]}_`)) {
        result.push(...getEndpoints(combos, lastClickedTick));
      }

      if (checkInnerCombos(comboKey, lastClickedTick, combine, type)) {
        result.push(comboKey);
      }
    }

    return result;
  }, []);

  return managedTickKeys;
};

export const getUnmanagedTicks = (
  clickedTickArray: string[],
  unmanagedEndpoints: GraphUnmanagedEndpoints,
): string[] => {
  const clickedUnmanagedEndpoint = clickedTickArray[0];

  if (clickedTickArray.length === 1) {
    return Object.keys(unmanagedEndpoints[clickedUnmanagedEndpoint].items);
  }

  if (clickedTickArray.length === 2 && !clickedUnmanagedEndpoint.includes('internet')) {
    const clickedItem = clickedTickArray[1];
    const addresses = unmanagedEndpoints[clickedUnmanagedEndpoint].items[clickedItem].addresses;

    return [...addresses];
  }

  return [];
};

export const isTickUnmanaged = (clickedTick: string, unmanagedEndpointKeys: string[]): boolean =>
  unmanagedEndpointKeys.includes(clickedTick);

export const getTicksForParallelCoordinates = (
  items: Items,
  comboMapping: ComboMapping,
  grouping: string[],
  clickedTick: ClickedTick,
): string[] => {
  const groupingProperties = [...grouping];
  const lastGrouping = groupingProperties.at(-1) as string;
  const combine = {
    properties: groupingProperties,
    level: grouping.length + 1,
  };

  const unmanagedEndpoints = items.unmanagedEndpoints;
  const unmanagedEndpointKeys = Object.keys(unmanagedEndpoints);
  const clickedTickSource = clickedTick.source;
  const clickedTickTarget = clickedTick.target;
  const ticks = [] as string[];

  if (!clickedTickSource.length) {
    const managedSourceTick = lastGrouping ? `${lastGrouping}_source` : 'managedEndpoints_source';

    ticks.push(managedSourceTick, ...unmanagedEndpointKeys.filter(key => key.includes('source')));
  }

  if (!clickedTickTarget.length) {
    const managedTargetTick = lastGrouping ? `${lastGrouping}_target` : 'managedEndpoints_target';

    ticks.push(managedTargetTick, ...unmanagedEndpointKeys.filter(key => key.includes('target')));
  }

  if (clickedTickSource.length) {
    const firstClickedTick = clickedTickSource[0];

    if (isTickUnmanaged(firstClickedTick, unmanagedEndpointKeys)) {
      ticks.push(...getUnmanagedTicks(clickedTickSource, unmanagedEndpoints));
    } else {
      ticks.push(
        ...getManagedTicks(comboMapping, combine, lastGrouping, 'source', clickedTickSource, items.managedEndpoints),
      );
    }
  }

  if (clickedTickTarget.length) {
    const firstClickedTick = clickedTickTarget[0];

    if (isTickUnmanaged(firstClickedTick, unmanagedEndpointKeys)) {
      ticks.push(...getUnmanagedTicks(clickedTickTarget, unmanagedEndpoints));
    } else {
      ticks.push(
        ...getManagedTicks(comboMapping, combine, lastGrouping, 'target', clickedTickTarget, items.managedEndpoints),
      );
    }
  }

  return ticks;
};

export const isTickAndEndpointGroupingMatched = (
  key: string,
  endpoint: EndpointData,
  unmanagedEndpointKeys: string[],
  type: EndpointType,
): boolean => {
  if (isTickUnmanaged(key, unmanagedEndpointKeys)) {
    return key === `${endpoint.type}_${type}`;
  }

  return isManagedEndpoint(endpoint);
};

export const isTickAndEndpointMatched = (
  key: string,
  endpoint: EndpointData,
  clickedTickArray: string[],
  unmanagedEndpoints: GraphUnmanagedEndpoints,
  type: EndpointType,
  combos: Record<string, GraphCombo>,
): boolean => {
  const unmanagedEndpointKeys = Object.keys(unmanagedEndpoints);
  const firstClickedTick = clickedTickArray[0];

  if (isTickUnmanaged(firstClickedTick, unmanagedEndpointKeys)) {
    const item = unmanagedEndpoints[firstClickedTick].items;

    if (clickedTickArray.length === 1) {
      const addresses = item[key].addresses;

      return addresses.has(`${endpoint.fullIp}_${type}`);
    }

    return key === `${endpoint.ip}_${type}`;
  }

  const managedEndpointHref = isManagedEndpoint(endpoint) ? `${endpoint.details.href}_${type}` : '';
  const lastClickedTick = clickedTickArray.at(-1);

  if (lastClickedTick && isManagedEndpointHref(lastClickedTick)) {
    return key === `${endpoint.ip}_${type}`;
  }

  if (isManagedEndpointHref(key)) {
    return key === managedEndpointHref;
  }

  const endpoints = {
    ...combos[key].endpoints.workload,
    ...combos[key].endpoints.virtualService,
    ...combos[key].endpoints.virtualServer,
  };

  return endpoints.hasOwnProperty(managedEndpointHref);
};

export const getParallelCoordinatesLinks = (
  filteredComboKeys: string[],
  combos: Record<string, GraphCombo>,
  links: LinkData[],
  clickedTick: ClickedTick,
  items: Items,
): Record<string, ParallelCoordinatesLinks> => {
  const clickedTickSource = clickedTick.source;
  const clickedTickTarget = clickedTick.target;
  const sourceKeys: string[] = filteredComboKeys.filter(comboKey => comboKey.includes('source'));
  const targetKeys: string[] = filteredComboKeys.filter(comboKey => comboKey.includes('target'));
  const unmanagedEndpoints = items.unmanagedEndpoints;
  const unmanagedEndpointKeys = Object.keys(unmanagedEndpoints);

  const tickLinks = Object.values(links).reduce(
    (result: Record<string, ParallelCoordinatesLinks>, link: LinkData): Record<string, ParallelCoordinatesLinks> => {
      const source = link.source;
      const target = link.target;

      const sourceTick = sourceKeys.find(sourceKey => {
        if (!clickedTickSource.length) {
          return isTickAndEndpointGroupingMatched(sourceKey, source, unmanagedEndpointKeys, 'source');
        }

        return isTickAndEndpointMatched(sourceKey, source, clickedTickSource, unmanagedEndpoints, 'source', combos);
      });

      const targetTick = targetKeys.find(targetKey => {
        if (!clickedTickTarget.length) {
          return isTickAndEndpointGroupingMatched(targetKey, target, unmanagedEndpointKeys, 'target');
        }

        return isTickAndEndpointMatched(targetKey, target, clickedTickTarget, unmanagedEndpoints, 'target', combos);
      });

      const port = portUtils.isPortValidForProtocol(link.service.protocol, link.service.port)
        ? link.service.port
        : link.service.protocol;

      if (sourceTick && targetTick) {
        const comboLinkKey = [
          sourceTick,
          targetTick,
          port,
          link.service?.outbound?.processName,
          link.service?.inbound?.processName,
        ].join(',');

        result[comboLinkKey] ||= {
          source: sourceTick,
          target: targetTick,
          port: port || '',
          outboundProcess: link.service?.outbound?.processName || '',
          inboundProcess: link.service?.inbound?.processName || '',
          aggregatedSet: new Set(),
        };

        result[comboLinkKey].aggregatedSet.add(link.index);
      }

      return result;
    },
    {},
  );

  return tickLinks;
};

export const getFirstLevelLabelMap = (labels: LabelSettings[]): FirstLevelLabel => {
  const userDefinedLabels = labels.reduce((result: FirstLevelLabel, item: LabelSettings) => {
    result[item.key] = {name: item.display_name, pluralName: item.display_info.display_name_plural};

    return result;
  }, {});

  userDefinedLabels.nodes = {
    name: intl('IlluminationMap.CommonLabels'),
    pluralName: intl('IlluminationMap.CommonLabels'),
  };
  userDefinedLabels.appGroup = {name: intl('Common.AppGroup'), pluralName: intl('Common.AppGroups')};
  userDefinedLabels.managedEndpoints = {name: intl('Common.Workload'), pluralName: intl('Common.Workloads')};

  return userDefinedLabels;
};

export const getFirstLevelUnmanagedMap = (unManagedEndpoints: GraphUnmanagedEndpoints): FirstLevelLabel => {
  return Object.values(unManagedEndpoints).reduce((result: FirstLevelLabel, item: GraphUnmanagedEndpoint) => {
    result[item.type] = {name: item.name, pluralName: item.name};

    return result;
  }, {});
};
