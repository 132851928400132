/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {call, put} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';

export function* fetchCoreServicesSettings() {
  return yield call(apiSaga, 'settings_core_services.get', {
    *afterFetch({data}) {
      yield put({type: 'CORESERVICES_GET_SETTINGS', data});
    },
  });
}

export function* updateCoreServicesSettings(data) {
  yield call(apiSaga, 'settings_core_services.update', {
    data,
  });

  yield call(fetchCoreServicesSettings);
}

export function* updateScannerDetectionSettings(data) {
  yield call(apiSaga, 'settings_core_services.update', {
    data,
  });

  yield call(fetchCoreServicesSettings);
}
