/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {ExpandableLabelList, Pill} from 'components';
import intl from '@illumio-shared/utils/intl';
import {getSelectionType} from 'containers/IlluminationMap/Utils/MapSelectionUtils';
import {getComboMode} from 'containers/IlluminationMap/Graph/Utils/MapGraphStyleUtils';
import _ from 'lodash';
import {getPortAndProtocolString} from 'containers/Service/ServiceUtils';

export const renderTitle = (data, labelTypes) => {
  if (data.links) {
    return `${labelTypes.find(type => type.key === data.level.source)?.label} -> ${
      labelTypes.find(type => type.key === data.level.target)?.label
    } ${intl('Common.Link')}`;
  }

  return `${intl('Common.Workload')} -> ${intl('Common.Workload')} ${intl('Common.Link')}`;
};

export const renderLabel = (label, {showDiscovered} = {}) => {
  const discoveredText = showDiscovered ? `${intl('Common.No')} ${label.name}` : null;

  return label.id === 'discovered' ? (
    discoveredText
  ) : (
    <Pill.Label type={label.key} href={label.href} key={label.href}>
      {label.value}
    </Pill.Label>
  );
};

export const getLabelString = (label, showDiscovered) => {
  const discoveredText = showDiscovered ? `${intl('Common.No')} ${label.name}` : '';

  return label.id === 'discovered' ? discoveredText : label.value;
};

export const renderExpandableLabel = (labels, {showDiscovered} = {}) => {
  return (
    <ExpandableLabelList.Short values={labels} title={intl('Common.Labels')}>
      {label => (
        <div key={label.key}>
          <Pill.Label type={label.key}>{getLabelString(label, showDiscovered)}</Pill.Label>
        </div>
      )}
    </ExpandableLabelList.Short>
  );
};

export const renderServices = data => {
  let services;

  if (data.links) {
    services = Object.values(data.links).reduce((result, link) => ({...result, ...link.services}), {});
  } else {
    services = data.services;
  }

  return Object.values(services).map(service => (
    <div>{`${service.port} ${service.protocol} ${service.processName || ''} ${service.windowsService || ''}`}</div>
  ));
};

export const renderEndpoint = (endpoint, data) => {
  if (data.links) {
    // All the underlying endpoints should have the containing labels
    const componentEndpoint = Object.values(data.links)[0][endpoint];
    const labels = componentEndpoint.labels.filter(label => data[endpoint][data.level[endpoint]].includes(label.value));

    return (
      <ExpandableLabelList.Short values={labels} title={intl('Common.Labels')}>
        {(label, index) => (
          <Pill.Label key={`pill-${label.value}-${index}`} type={label.key}>
            {label.value}
          </Pill.Label>
        )}
      </ExpandableLabelList.Short>
    );
  }

  return data[endpoint].hostname || data[endpoint].name;
};

export const getManagedPanelSubType = selection => {
  if (selection.includes('virtual_services')) {
    return 'virtualService';
  }

  if (selection.includes('container_workloads')) {
    return 'containerWorkload';
  }

  if (selection.includes('kubernetes_workloads')) {
    return 'kubernetesWorkload';
  }

  if (selection.includes('workloads')) {
    return 'workload';
  }

  if (selection.includes('virtual_servers')) {
    return 'virtualServer';
  }
};

export const getComboPanel = (selection, combos, selectionType) => {
  const combo = selection.combo[0];

  if (combos[combo]) {
    return selection.combo[0].includes('_superAppGroups_') ? 'superAppGroup' : selectionType;
  }

  return;
};

export const calculatePanelType = (selection, combos) => {
  const selectionType = getSelectionType(selection);

  switch (selectionType) {
    case 'combo':
      return getComboPanel(selection, combos, selectionType);
    case 'managed':
    case 'unmanaged':
    case 'link':
    case 'comboLink':
      return selectionType;
    default:
      return;
  }
};

const getSupportedPanelTabs = ({mapMode}) => {
  const supported = {
    managed: new Set(['summary', 'traffic']),
    combo: new Set(['summary', 'traffic', 'workloads', 'containerWorkloads', 'virtualServices', 'virtualServers']),
    link: new Set(['summary', 'traffic']),
    comboLink: new Set(['summary', 'traffic']),
    unmanaged: new Set(['summary', 'traffic']),
  };

  if (mapMode === 'vulnerability') {
    supported.managed.add('vulnerabilities');
    supported.combo.add('vulnerabilities');
    supported.link.add('vulnerabilities');
    supported.comboLink.add('vulnerabilities');
  }

  return supported;
};

export function getPanelTab({panelType, tab, mapMode}) {
  const supportedPanelTabs = getSupportedPanelTabs({mapMode});
  const supported = supportedPanelTabs[panelType] || new Set([]);
  const [firstTab] = supported;

  if (supported.has(tab)) {
    return tab;
  }

  return firstTab;
}

export function getComboWorkloads(combo = {}) {
  const workloads = Object.values(combo.endpoints?.workload ?? {});

  return workloads.filter(
    workload =>
      workload?.managedType === 'workload' &&
      workload?.subType !== 'containerWorkload' &&
      workload?.subType !== 'deleted',
  );
}

export function getComboContainerWorkloads(combo = {}) {
  const workloads = Object.values(combo.endpoints?.workload ?? {});

  return workloads.filter(workload => workload?.subType === 'containerWorkload');
}

export function getComboVirtualServices(combo = {}) {
  const virtualServices = Object.values(combo.endpoints?.virtualService ?? {});

  return virtualServices;
}

export function getComboVirtualServers(combo = {}) {
  const virtualServers = Object.values(combo.endpoints?.virtualServers ?? {});

  return virtualServers;
}

export function getSuperAppGroupsCount(combos, type) {
  const superAppGroupType = `_superAppGroups_${type}`;

  return combos[superAppGroupType]?.appGroupCount || 0;
}

export function getEnforcedWorkloadCount(endpoints, mode) {
  if (!endpoints) {
    return;
  }

  const modeObject = _.countBy(getComboMode(endpoints));

  return modeObject[mode] ? intl('Workloads.WithCount', {count: modeObject[mode]}) : null;
}

export function getUnmanagedWorkloadCount(endpoints) {
  if (!endpoints) {
    return;
  }

  const workloads = Object.values(endpoints);

  const count = workloads.filter(
    workload => workload?.managedType === 'workload' && workload?.subType === 'unmanaged',
  )?.length;

  return count ? intl('Workloads.WithCount', {count}) : null;
}

export function getPortProtocolProcess(data) {
  const process = data.processName || data.windowsService;

  return `${getPortAndProtocolString(data)} ${process}`;
}

export const isConnectedComboLinkPanel = (linkDetails, connectedComboId) => {
  let isNextPrevious = false;

  linkDetails.forEach(details => {
    if (connectedComboId.includes('target')) {
      if (details.target.id === connectedComboId) {
        isNextPrevious = true;
      }
    }

    if (connectedComboId.includes('source')) {
      if (details.source.id === connectedComboId) {
        isNextPrevious = true;
      }
    }
  });

  return isNextPrevious;
};

export const getPolicyHeaderForLinkPanel = (policyVersion, isConnectedLinkPanel) => {
  if (isConnectedLinkPanel) {
    return '';
  }

  return policyVersion === 'reported' ? intl('Common.ReportedPolicy') : intl('Common.DraftPolicy');
};

export const getPolicyItemLink = policyItem => {
  switch (policyItem) {
    case 'appGroups':
      return 'appGroups.detail.illumination';

    case 'denyRules':
      return 'boundaries.item.illumination';

    default:
      return 'illumination';
  }
};
