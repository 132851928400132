/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {Component} from 'react';
import {composeThemeFromProps} from '@css-modules-theme/react';
import * as PropTypes from 'prop-types';
import {TypedMessages} from 'components';
import {tidUtils} from '@illumio-shared/utils';
import styles from './FileUpload.css';

const defaultInputTid = 'comp-field-input-file';

export default class FileUpload extends Component {
  static propsTypes = {
    tid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // restrict to only types to upload. e.g. ['.jpg', '.jpeg', '.png']
    acceptedFiles: PropTypes.arrayOf(PropTypes.string),
    errorText: PropTypes.string,
    fileName: PropTypes.string,
    onChange: PropTypes.func,
    // Hide file upload option. e.g. TLS edits
    hideFileUpload: PropTypes.bool,
  };

  static defaultProps = {
    type: 'file',
    acceptedFiles: [],
    hideFileUpload: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      fileName: null,
      onChangeUpload: false, // false: user did not click on a file to upload
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.onChange && nextProps.fileName !== prevState.fileName) {
      return {
        fileName: nextProps.fileName,
      };
    }

    return null;
  }

  async handleOnChange(evt) {
    const {onChange} = this.props;
    const file = evt.currentTarget.files[0];

    if (file) {
      // when user uploads set to onChangeUpload = true to indicate a file just got uplodaded
      this.setState({onChangeUpload: true});

      const parsedFile = await this.readFile(file);

      if (onChange) {
        onChange(file.name, parsedFile, file);
      } else {
        this.setState({
          fileName: file.name,
        });
      }
    }
  }

  /**
   * Reads single file and converts blob into text string.
   * @param file
   * @returns {Promise<string>}
   */
  async readFile(file) {
    if (!file) {
      return '';
    }

    return file.text();
  }

  render() {
    const {tid, name, acceptedFiles, errorText, fileName: propsFileName, hideFileUpload, ...props} = this.props;
    const theme = composeThemeFromProps(styles, this.props);
    const {fileName, onChangeUpload} = this.state;
    let fileElement = fileName;

    // only set fileElement when a File object is passed in and a file upload didn't occur
    if (fileName) {
      const className = cx({[theme.fileElement]: !onChangeUpload && !hideFileUpload});

      fileElement = <span className={className}>{fileName}</span>;
    }

    // use Set to enforce unique: e.g. accept = ['.jpg']
    const accept = [...new Set(acceptedFiles)].join(',');

    const fieldElementProps = {
      'data-tid': tidUtils.getTid(tidUtils.getTid(defaultInputTid, tid)),
      ...props,
      accept,
    };

    return (
      <div className={theme.uploadContainer}>
        {!hideFileUpload && (
          <input
            className={cx(theme.input, {[theme.hideFileInputText]: fileElement})}
            id="input"
            {...fieldElementProps}
            onChange={this.handleOnChange}
          />
        )}
        {fileElement}
        <TypedMessages key="status" gap="gapXSmall">
          {[errorText ? {content: errorText, color: 'error', fontSize: 'var(--12px)'} : null]}
        </TypedMessages>
      </div>
    );
  }
}
