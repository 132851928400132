/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';

// These are all the rows of an Abilities Table. They are different PCE elements that are / are not accessible based on your role.
export const abilityRows = createSelector([], () => ({
  rules: intl('Rulesets.AndRules'),
  enforcementBoundaries: intl('Rulesets.DenyRules'),
  workloadManagement: intl('Common.WorkloadManagement'),
  map: intl('Common.IlluminationMap'),
  appGroupMap: intl('Common.AppGroupMap'),
  appGroupsList: intl('Common.AppGroupsList'),
  illuminationPlus: intl('Common.IlluminationPlus'),
  ...(!__ANTMAN__ && !__MSP__ && {roles: intl('RBAC.ScopesAndRoles')}),
  users: intl('Users.UsersAndGroups'),
  services: intl('Common.Services'),
  ipLists: intl('Common.IPLists'),
  userGroups: intl('Common.UserGroups'),
  labelGroups: intl('Labels.Groups'),
  virtualServices: intl('Common.VirtualServices'),
  virtServers: intl('Common.VirtualServers'),
  labels: intl('Common.Labels'),
  pairingProfiles: intl('PairingProfiles.Profiles'),
  infrastructure: intl('Menu.Infrastructure'),
  blockedTraffic: intl('BlockedTraffic.Name'),
  secSettings: intl('Common.SecuritySettings'),
  appGroupConfig: intl('Common.AppGroupsSettings'),
  myProfile: intl('Users.MyProfile'),
  apiKeys: intl('Users.APIKeys.MyAPIKeys'),
  ssoConfig: intl('Users.SSOConfig.Name'),
  reports: intl('Common.Reports'),
}));

// These are possible combinations of Abilities Table column values
// This is used in abilityTablesForRoles to assign column values to each row
export const abilityCombos = createSelector([], () => ({
  view: {
    view: intl('Common.View'),
  },
  viewScope: {
    view: intl('Common.ViewScope'),
  },
  viewModify: {
    view: intl('Common.View'),
    modify: intl('Common.Modify'),
  },
  viewDelete: {
    view: intl('Common.View'),
    delete: intl('Common.Delete'),
  },
  viewProvision: {
    view: intl('Common.View'),
    provision: intl('Common.Provision'),
  },
  viewScopeProvision: {
    view: intl('Common.ViewScope'),
    provision: intl('Common.Provision'),
  },
  viewModifyProvision: {
    view: intl('Common.View'),
    modify: intl('Common.Modify'),
    provision: intl('Common.Provision'),
  },
  viewScopeModify: {
    view: intl('Common.ViewScope'),
    add: intl('Common.Add'),
    modify: intl('Common.Modify'),
    delete: intl('Common.Delete'),
  },
  viewGenerateDownload: {
    view: intl('Common.View'),
    generate: intl('Common.Generate'),
    download: intl('Common.Download'),
  },
  allExceptProvision: {
    view: intl('Common.View'),
    add: intl('Common.Add'),
    modify: intl('Common.Modify'),
    delete: intl('Common.Delete'),
  },
  all: {
    view: intl('Common.View'),
    add: intl('Common.Add'),
    modify: intl('Common.Modify'),
    provision: intl('Common.Provision'),
    delete: intl('Common.Delete'),
  },
  none: {
    none: intl('Common.None'),
  },
}));

/* Determine which type of roles are able to set 'All' for "All Application, All Environment, All Location" when editing
 * @param {String} - the scope role type
 * @returns {Boolean}
 */
export const isAllAbility = role => ['ruleset_manager', 'ruleset_provisioner', 'workload_manager'].includes(role);

// This is every abilities table for every possible role
// Each row in an abilities table is set using the abilityCombos constant
const abilityTablesForRoles = createSelector([abilityRows], abilityRows => ({
  owner: Object.keys(abilityRows).reduce((res, rowName) => {
    if (
      [
        'workloadManagement',
        'roles',
        'users',
        'labels',
        'pairingProfiles',
        'infrastructure',
        'apiKeys',
        'userGroups',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (['map', 'appGroupMap', 'appGroupsList', 'illuminationPlus'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().view});
    }

    if (['appGroupConfig', 'myProfile', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'secSettings') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModifyProvision});
    }

    if (rowName === 'blockedTraffic') {
      return Object.assign(res, {[rowName]: abilityCombos().viewDelete});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().viewGenerateDownload});
    }

    return Object.assign(res, {[rowName]: abilityCombos().all});
  }, {}),
  admin: Object.keys(abilityRows).reduce((res, rowName) => {
    if (
      ['workloadManagement', 'labels', 'pairingProfiles', 'infrastructure', 'apiKeys', 'userGroups'].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (['map', 'appGroupMap', 'appGroupsList', 'appGroupConfig', 'illuminationPlus'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().view});
    }

    if (['ssoConfig', 'roles', 'users'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'secSettings') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModifyProvision});
    }

    if (rowName === 'blockedTraffic') {
      return Object.assign(res, {[rowName]: abilityCombos().viewDelete});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().all});
  }, {}),
  read_only: Object.keys(abilityRows).reduce((res, rowName) => {
    if (['roles', 'ssoConfig', 'users'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (
      [
        'workloadManagement',
        'appGroupMap',
        'appGroupsList',
        'virtualServices',
        'virtServers',
        'blockedTraffic',
        'illuminationPlus',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().view});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'apiKeys') {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().view});
  }, {}),
  ruleset_manager: Object.keys(abilityRows).reduce((res, rowName) => {
    if (
      [
        'map',
        'roles',
        'ssoConfig',
        'secSettings',
        'appGroupConfig',
        'pairingProfiles',
        'infrastructure',
        'users',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (
      [
        'workloadManagement',
        'appGroupMap',
        'appGroupsList',
        'virtualServices',
        'blockedTraffic',
        'illuminationPlus',
        'virtServers',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScope});
    }

    if (['rules'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScopeModify});
    }

    if (['apiKeys'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().view});
  }, {}),
  limited_ruleset_manager: Object.keys(abilityRows).reduce((res, rowName) => {
    if (
      [
        'map',
        'roles',
        'ssoConfig',
        'secSettings',
        'appGroupConfig',
        'pairingProfiles',
        'infrastructure',
        'users',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (
      [
        'workloadManagement',
        'appGroupMap',
        'appGroupsList',
        'virtualServices',
        'blockedTraffic',
        'illuminationPlus',
        'virtServers',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScope});
    }

    if (['rules'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScopeModify});
    }

    if (['apiKeys'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().view});
  }, {}),
  ruleset_provisioner: Object.keys(abilityRows).reduce((res, rowName) => {
    if (
      [
        'map',
        'roles',
        'ssoConfig',
        'secSettings',
        'appGroupConfig',
        'pairingProfiles',
        'infrastructure',
        'users',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (['rules'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScopeProvision});
    }

    if (
      [
        'rules',
        'workloadManagement',
        'appGroupMap',
        'appGroupsList',
        'virtualServices',
        'blockedTraffic',
        'illuminationPlus',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScope});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'apiKeys') {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().view});
  }, {}),
  ruleset_viewer: Object.keys(abilityRows).reduce((res, rowName) => {
    if (
      [
        'map',
        'roles',
        'ssoConfig',
        'secSettings',
        'appGroupConfig',
        'pairingProfiles',
        'infrastructure',
        'users',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (
      [
        'rules',
        'workloadManagement',
        'appGroupMap',
        'appGroupsList',
        'virtualServices',
        'blockedTraffic',
        'illuminationPlus',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScope});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'apiKeys') {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().view});
  }, {}),
  workload_manager: Object.keys(abilityRows).reduce((res, rowName) => {
    if (
      [
        'rules',
        'map',
        'appGroupMap',
        'illuminationPlus',
        'roles',
        'users',
        'infrastructure',
        'blockedTraffic',
        'secSettings',
        'ssoConfig',
      ].includes(rowName)
    ) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (['appGroupsList', 'virtualServices'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScope});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (['workloadManagement', 'pairingProfiles'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().viewScopeModify});
    }

    if (['apiKeys'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().view});
  }, {}),
  global_object_provisioner: Object.keys(abilityRows).reduce((res, rowName) => {
    if (['services', 'ipLists', 'labelGroups', 'secSettings'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().viewProvision});
    }

    if (['roles', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: abilityCombos().viewModify});
    }

    if (rowName === 'apiKeys') {
      return Object.assign(res, {[rowName]: abilityCombos().allExceptProvision});
    }

    if (rowName === 'reports') {
      return Object.assign(res, {[rowName]: abilityCombos().none});
    }

    return Object.assign(res, {[rowName]: abilityCombos().view});
  }, {}),
}));

// This function takes in a list of roles
// Then it returns a single abilities table which is a union of all the abilities tables for the input roles
// Takes object of possible environment types to further tune abilities
export const getEffectiveAbilitiesTable = roles => {
  const effectiveTable = {};
  const abilityTablesForRolesObj = abilityTablesForRoles();

  Object.keys(abilityRows()).forEach(rowName => {
    effectiveTable[rowName] = roles.reduce((res, role) => {
      // Abilities are additive.  Makes sure they are added up properly.
      const abilities =
        res.view === intl('Common.View')
          ? {...abilityTablesForRolesObj[role][rowName], ...res}
          : {...res, ...abilityTablesForRolesObj[role][rowName]};

      if (abilities.none && Object.keys(abilities).length > 1) {
        delete abilities.none;
      }

      return abilities;
    }, {});
  });

  return effectiveTable;
};
