/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import styles from './LabelRulesListEditRuleNumber.css';
import {useState, useCallback} from 'react';
import {Input} from 'components';
import {parseInputValue} from './LabelRulesListEditRuleNumberUtils';
import {validateRuleNumber} from '../../../LabelRulesUtils';
import intl from '@illumio-shared/utils/intl';
import PropTypes from 'prop-types';

/**
 * The LabelRulesListEditRuleNumber component is used to edit a label rule's rule number;
 * it renders a single input box that works kind of like an HTML "type=number" input without
 * the spinner control; it only allows the user to enter positive integers (excluding 0)
 * whose length is LTE the length of maxRuleNumber; If the user tries to enter non-numeric
 * characters (e.g. a-z or special chars), they are simply ignored;
 */
LabelRulesListEditRuleNumber.propTypes = {
  // ruleNumber acts as the initial value of the input; It should be a number or
  // undefined; undefined results in an empty input;
  ruleNumber: PropTypes.number,

  // maxRuleNumber is the max value for ruleNumber; it is used to validate the
  // user's input; if the input value is an integer that exceeds maxRuleNumber,
  // an error message is shown and onChange is called with undefined;
  maxRuleNumber: PropTypes.number.isRequired,

  // onChange is called when the input's value changes; the only argument is the
  // ruleNumber, which is either a positive integer, or undefined; undefined indicates
  // that the input's current value is either blank, or invalid (probably zero or GT
  // maxRuleNumber).
  onChange: PropTypes.func.isRequired,
};

export default function LabelRulesListEditRuleNumber({ruleNumber, maxRuleNumber, onChange}) {
  const [inputValue, setInputValue] = useState(ruleNumber ? String(ruleNumber) : '');
  const [errorMessage, setErrorMessage] = useState();
  const inputSize = String(maxRuleNumber).length;

  const handleInputChange = useCallback(
    event => {
      // extract a string containing positive integer with length LTE inputSize;
      const parsedInputValue = parseInputValue(event.target.value, inputSize);
      const newRuleNumber = parsedInputValue === '' ? undefined : parseInt(parsedInputValue, 10);
      const valid = validateRuleNumber(newRuleNumber, maxRuleNumber);

      // update the input's value with the parsed input string;
      setInputValue(parsedInputValue);
      // newRuleNumber will be either a positive integer or undefined;
      onChange(newRuleNumber);

      if (valid) {
        // newRuleNumber was valid; clear error message;
        setErrorMessage();
      } else {
        // newRuleNumber is not valid (probably zero or GT maxRuleNumber); show the errorMessage;
        setErrorMessage(intl('LabelRules.RuleNumberValidRange', {maxRuleNumber}));
      }
    },
    [maxRuleNumber, onChange, inputSize],
  );

  return (
    <Input
      size={inputSize}
      theme={styles}
      themePrefix="ruleNumberInput-"
      name="rule-number"
      tid="rule-number-input"
      onChange={handleInputChange}
      value={inputValue}
      error={Boolean(errorMessage)}
      errorMessage={errorMessage}
      disabled={maxRuleNumber === 1}
    />
  );
}
