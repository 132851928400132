/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import {
  getAttributeAndOperatorFromSelectorValues,
  getSupportedAttributeOption,
  getSupportedOperatorOption,
  isExpressionEmpty,
  selectorValuesToExpression,
  isNewAttributeOperator,
  getResourceStatics,
  getExpressionResourceValidator,
  getAttributeSelectorOption,
  getOperatorSelectorOption,
} from './LabelRulesListEditExpressionUtils';
import {
  attributeOptions,
  logicOperatorOptions,
  resourceIds,
  enabledAttributeOptions,
  operatorOptions,
} from './LabelRulesListEditExpressionConstants';
import LabelRulesListEditExpressionContainer from './LabelRulesListEditExpressionContainer';
import styles from './LabelRulesListEditExpression.css';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import LabelRulesListExpressionPills from '../../Common/LabelRulesListExpressionPills';
import _ from 'lodash';

const attributeStatics = enabledAttributeOptions.map(a => getAttributeSelectorOption(a));
const getOperatorStatics = _.memoize(attribute =>
  (attributeOptions[attribute]?.operators ?? []).map(o => getOperatorSelectorOption(o)),
);

const formatOption = ({option, selected}) => {
  const classNames = cx(styles.option, {[styles.selectedOption]: selected});

  return <div className={classNames}>{option.name}</div>;
};

export const getExpressionSelectorCategories = ({
  currentAttribute,
  currentOperator,
  selectorValues,
  onClickCondition,
  selectorOpen,
  containerRef,
}) => {
  const expressionSelectedProps = {
    formatValue: ({value}) => value.name ?? value.value,
    formatResource: options => {
      const {values, onRemove} = options;
      const expression = selectorValuesToExpression(values);
      const newAttributeOperator = isNewAttributeOperator({expression, currentAttribute, currentOperator});
      const expressionIsEmpty = isExpressionEmpty(expression);
      const showAttributeOperatorPill = selectorOpen && newAttributeOperator && (currentAttribute || currentOperator);
      const showAttributeOperatorLogicOperator = showAttributeOperatorPill && !expressionIsEmpty;

      return (
        <div className={styles.expressionWrapper}>
          <LabelRulesListExpressionPills
            theme={styles}
            expression={expression}
            onClickCondition={onClickCondition}
            onRemoveValue={onRemove}
          />
          {showAttributeOperatorPill ? (
            <span className={styles.conditionWrapper}>
              {showAttributeOperatorLogicOperator ? (
                <span className={styles.logicOperator}>{logicOperatorOptions.and.label}</span>
              ) : null}
              <span className={styles.attributeOperatorWrapper}>
                <LabelRulesListExpressionPills
                  theme={styles}
                  expression={{attribute: currentAttribute, operator: currentOperator}}
                />
              </span>
            </span>
          ) : null}
        </div>
      );
    },
  };

  const expressionContainerResource = {
    type: 'container',
    container: LabelRulesListEditExpressionContainer,
    containerProps: ({onDone, close}) => ({
      ref: containerRef,
      title: intl('LabelRules.Values'),
      attribute: currentAttribute,
      operator: currentOperator,
      selectorValues,
      validate: getExpressionResourceValidator({attribute: currentAttribute, operator: currentOperator}),
      onDone,
      close,
    }),
    enableFocusLock: selectorOpen,
    onSelect: (event, options) => {
      const {value} = options;
      const previousValues = selectorValues.get(resourceIds.expression);

      options.values.set(resourceIds.expression, [...previousValues, value]);

      return options.values;
    },
    selectedProps: expressionSelectedProps,
  };

  const expressionListResource = {
    statics: ({query}) => getResourceStatics({query, attribute: currentAttribute, operator: currentOperator}),
    optionProps: {
      allowMultipleSelection: true,
      showCheckbox: true,
      noFilter: true,
      format: ({option}) => {
        return formatOption({option});
      },
    },
    noEmptyBanner: true,
    showTitle: true,
    formatTitle: () => intl('LabelRules.Values'),
    selectedProps: expressionSelectedProps,
  };

  return [
    {
      id: 'expression',
      resources: {
        [resourceIds.attribute]: {
          statics: attributeStatics,
          noEmptyBanner: true,
          showTitle: true,
          formatTitle: () => intl('LabelRules.Attribute'),
          selectIntoResource: resourceIds.attributeAndOperator,
          selectedProps: {
            hideResourceName: true,
          },
          optionProps: {
            noFilter: true,
            showSelected: true,
            format: ({option}) => {
              return formatOption({option, selected: option.value === currentAttribute});
            },
          },
        },
        [resourceIds.operator]: {
          statics: getOperatorStatics(currentAttribute),
          noEmptyBanner: true,
          showTitle: true,
          formatTitle: () => intl('LabelRules.Operator'),
          selectIntoResource: resourceIds.attributeAndOperator,
          selectedProps: {
            hideResourceName: true,
          },
          optionProps: {
            noFilter: true,
            showSelected: true,
            format: ({option}) => {
              return formatOption({option, selected: option.value === currentOperator});
            },
          },
        },
        [resourceIds.attributeAndOperator]: {
          hidden: true,
          optionProps: {
            allowMultipleSelection: true,
          },
          selectedProps: {
            hidden: true,
          },
          onSelect: (event, options) => {
            const {value: selectedOption} = options;
            let {attribute, operator} = getAttributeAndOperatorFromSelectorValues(options.values);

            // try to find the best attribute and operator options based on the attribute/operator selections; we use
            // getSupportedAttributeOption and getSupportedOperatorOption to do this; if the attribute was cleared or is
            // invalid, we automatically select the defaultAttributeOption; if the operator option was cleared or is
            // invalid for the attribute, we select the first option in the attribute's operators array from constants;
            if (selectedOption.resourceKey === resourceIds.attribute) {
              // an attribute option was selected; pick the best operator based on their new attribute selection;
              attribute = getSupportedAttributeOption(selectedOption);
              operator = getSupportedOperatorOption(attribute, operator);
            } else if (selectedOption.resourceKey === resourceIds.operator) {
              // an operator option was selected; use their newly selected operator, or pick a supported one if it is
              // invalid for the attribute;
              attribute = getSupportedAttributeOption(attribute);
              operator = getSupportedOperatorOption(attribute, selectedOption);
            }

            options.values.set(resourceIds.attributeAndOperator, [attribute, ...(operator ? [operator] : [])]);

            return options.values;
          },
        },
        [resourceIds.expression]:
          currentAttribute === attributeOptions.os.id && currentOperator === operatorOptions.is.id
            ? expressionListResource
            : expressionContainerResource,
      },
    },
  ];
};
