/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName, getRoutePreviousName, getRouteCurrentParams} from 'containers/App/AppState';
import {getVirtualServerVersions} from '../VirtualServerItemState';
import {isUserReadOnly, isUserGlobalObjectProvisioner, isUserRulesetProvisioner} from 'containers/User/UserState';

export const getWorkloadFilter = createSelector([getVirtualServerVersions], ({versions: {pversionObj}}) => ({
  managed: [true],
  ...(pversionObj?.providers && {labelsAndLabelGroups: pversionObj.providers.map(label => label.label)}),
}));

export const getVirtualServerMembers = createSelector(
  [
    getVirtualServerVersions,
    isUserReadOnly,
    isUserGlobalObjectProvisioner,
    isUserRulesetProvisioner,
    getRoutePreviousName,
    getRouteName,
    getRouteCurrentParams,
  ],
  (
    {versions, isOldVersion, pversionObjIsDeleted},
    userIsReadOnly,
    userIsGlobalObjectProvisioner,
    userIsRulesetProvisioner,
    previousRouteName,
    routeName,
    currentRouteParams,
  ) => {
    const {pversionObj, prevPversionObj} = versions;
    const userIsReadOnlyAndNotGPOP = userIsReadOnly && !userIsGlobalObjectProvisioner;
    const pversionObjLabels = pversionObj?.providers.length ? pversionObj.providers.map(label => label.label) : [];
    const prevPversionObjLabels = prevPversionObj?.providers.length
      ? prevPversionObj.providers.map(label => label.label)
      : [];

    return {
      versions,
      pversionObjLabels,
      prevPversionObjLabels,
      isOldVersion,
      pversionObjIsDeleted,
      userIsReadOnly,
      userIsReadOnlyAndNotGPOP,
      userIsRulesetProvisioner,
      previousRouteName,
      routeName,
      currentRouteParams,
    };
  },
);
