/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './CoreServicesListConfig';
import {createSelector} from 'reselect';
import {hrefUtils} from '@illumio-shared/utils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'CORESERVICES_GET_SUMMARY':
        return action.data.list;
      default:
        return state;
    }
  },

  listCount(state = {}, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_SUMMARY':
        return action.data.count;
      default:
        return state;
    }
  },

  types(state = [], action) {
    switch (action.type) {
      case 'CORESERVICES_GET_TYPES':
        return action.data.list;
      default:
        return state;
    }
  },

  typesCount(state = {}, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_TYPES':
        return action.data.count;
      default:
        return state;
    }
  },

  listLastRunAt(state = null, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_SUMMARY':
        return action.data.lastRunAt;
      default:
        return state;
    }
  },
  scannerDetectionLastRunAt(state = null, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_SUMMARY':
        return action.data.scannerDetectionLastRunAt;
      default:
        return state;
    }
  },
};

export const getCoreServicesList = state => state.coreServices.list;
export const getCoreServicesListCount = state => state.coreServices.listCount;
export const getCoreServiceTypes = state => state.coreServices.types;
export const getCoreServiceTypesCount = state => state.coreServices.typesCount;
export const getCoreServicesListLastRunAt = state => state.coreServices.listLastRunAt;
export const getScannerDetectionLastRunAt = state => state.coreServices.scannerDetectionLastRunAt;

export const getCoreServiceSummaryRows = createSelector([getCoreServicesList, getCoreServiceTypes], (list, types) =>
  list.map(coreService => ({
    ...coreService,
    name: types.find(type => type.href === coreService.core_service_type?.href)?.name,
    key: hrefUtils.getId(coreService.core_service_type?.href),
  })),
);

export const getCoreServicesListPage = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getCoreServiceSummaryRows,
  });
