/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Button, Spinner, StatusIcon, Icon} from 'components';
import {UserName} from 'containers';
import {apiPrefix} from 'api/api';
import * as GridUtils from 'components/Grid/GridUtils';
import {getOptionLabel} from '../PCESupportBundlesUtils';
import styles from './PCESupportBundlesList.css';
import {createSelector} from 'reselect';

const spinnerTheme = {spinner: styles['icon-spinner']};

const statusMap = createSelector(
  [],
  () =>
    new Map([
      ['pending', {tooltip: intl('PCESupportBundles.Status.Pending'), label: intl('Common.Pending'), type: 'pending'}],
      [
        'cancelled',
        {tooltip: intl('PCESupportBundles.Status.Cancelled'), label: intl('Common.Canceled'), type: 'info'},
      ],
      ['error', {tooltip: intl('PCESupportBundles.Status.Error'), label: intl('Common.Failed'), type: 'error'}],
      ['busy', {tooltip: intl('PCESupportBundles.Status.Busy'), label: intl('Common.Busy'), type: 'info'}],
      ['retry', {tooltip: intl('PCESupportBundles.Status.Retry'), label: intl('Common.Retry'), type: 'error'}],
      ['purged', {tooltip: intl('PCESupportBundles.Status.Purged'), label: intl('Common.Deleted'), type: 'info'}],
      ['failed', {tooltip: intl('PCESupportBundles.Status.Failed'), label: intl('Common.Failed'), type: 'error'}],
      ['partial', {tooltip: intl('PCESupportBundles.Status.Partial'), label: intl('Health.Partial'), type: 'info'}],
      ['missing', {tooltip: intl('PCESupportBundles.Status.Missing'), label: intl('Common.Missing'), type: 'error'}],
      [
        'rejected',
        {tooltip: intl('PCESupportBundles.Status.Rejected'), label: intl('CoreServices.Rejected'), type: 'error'},
      ],
      ['blocked', {tooltip: intl('PCESupportBundles.Status.Blocked'), label: intl('Common.Blocked'), type: 'error'}],
      ['unknown', {tooltip: intl('PCESupportBundles.Status.Unknown'), label: intl('Common.Unknown'), type: 'error'}],
      ['corrupt', {tooltip: intl('PCESupportBundles.Status.Corrupt'), label: intl('Common.Corrupt'), type: 'error'}],
    ]),
);

export const options = [
  {
    value: {from: {value: 'now', key: 'string'}, to: {value: -1, key: 'hour'}},
    key: 'preset',
  },
  {
    value: {from: {value: 'now', key: 'string'}, to: {value: -2, key: 'hour'}},
    key: 'preset',
  },
  {
    value: {from: {value: 'now', key: 'string'}, to: {value: -4, key: 'hour'}},
    key: 'preset',
  },
  {
    value: {from: {value: 'now', key: 'string'}, to: {value: -8, key: 'hour'}},
    key: 'preset',
  },
  {
    value: {from: {value: 'now', key: 'string'}, to: {value: -24, key: 'hour'}},
    key: 'preset',
  },
  {
    value: {from: {value: 'now', key: 'string'}, to: {value: -72, key: 'hour'}},
    key: 'preset',
  },
  {
    value: 'customPicker',
    key: 'custom',
  },
];

export const getDateTimeRangePickerOptions = createSelector([], () =>
  options.map(option => {
    return {
      ...option,
      label: getOptionLabel(option.key === 'preset' ? Math.abs(option.value.to.value) : '', option.key),
    };
  }),
);

function formatStatus(statusCode, statusMap) {
  if (!statusCode) {
    return '';
  }

  if (statusCode === 'in-progress') {
    return (
      <>
        <Spinner color="dark" theme={spinnerTheme} />
        <span className={styles.downloadText}>{intl('SupportReports.Generating')}</span>
      </>
    );
  }

  if (statusCode !== 'done') {
    const {type, label, tooltip} = statusMap.get(statusCode) || {};

    return (
      <StatusIcon
        tooltip={tooltip}
        tooltipProps={{arrow: false}}
        key={type}
        status={type}
        label={label}
        title={Icon.getTitle(type)}
      />
    );
  }

  return intl('Common.Completed');
}

function formatAction(data) {
  const {download_url: downloadUrl} = data;

  if (downloadUrl) {
    return (
      <Button.Link
        color="standard"
        size="small"
        text={intl('Common.Download')}
        link={downloadUrl.href ? {href: `${apiPrefix}${downloadUrl.href}`} : downloadUrl}
      />
    );
  }

  return null;
}

export const gridSettings = createSelector([statusMap], statusMap => ({
  id: 'pcerptlist',
  sort: '-createdOn',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    name: {
      header: intl('Common.Name'),
      value: 'name',
      format: ({value}) => value,
    },
    createdOn: {
      header: intl('Common.GeneratedAt'),
      isDate: 'L_HH_mm_ss',
      value: 'requested_at',
    },
    generatedBy: GridUtils.clickableColumn({
      header: intl('Common.GeneratedBy'),
      value: ({row}) => row.data.created_by,
      format: ({value, clickableRef}) => <UserName user={value} ref={clickableRef} />,
    }),
    status: {
      header: intl('Common.Status'),
      value: 'status',
      format: ({value}) => formatStatus(value, statusMap),
    },
    action: {
      header: intl('Common.Action'),
      format: ({row}) => formatAction(row.data),
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(100px, auto)'},
      {columns: ['createdOn'], size: 'minmax(100px, auto)'},
      {columns: ['generatedBy'], size: 'minmax(120px, auto)'},
      {columns: ['status'], size: 'minmax(120px, auto)'},
      {columns: ['action'], size: 'minmax(120px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
            {columns: ['status', 'action'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
          {columns: ['status', 'action'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
            {columns: ['status', 'action'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'generatedBy', 'createdOn'], size: 'minmax(100px, auto)'},
          {columns: ['status', 'action'], size: 'minmax(120px, auto)'},
        ];
      },
    },
  ],
}));
