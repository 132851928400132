/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {delay, call, spawn, select, put} from 'redux-saga/effects';
// Reflect kvpairs content into session storage in dev mode so it becomes visible in Session Storage section of DevTools
import {webStorageUtils} from '@illumio-shared/utils';
import {getUserId} from '../UserState';
import apiSaga from 'api/apiSaga';

export function* fetchKvpairs({key, force = false}) {
  // Make sure we fetched all user to get user data
  const userId = yield select(getUserId);

  const {data} = yield call(apiSaga, 'kvpairs.get_instance', {
    cache: !force,
    params: {user_id: userId, key},
  });

  if (__DEV__) {
    if (data) {
      webStorageUtils.setSessionItem(key, data);
    } else {
      webStorageUtils.removeSessionItem(key);
    }
  }

  return data;
}

export function* updateKvpairs({key, data}) {
  const userId = yield select(getUserId);

  yield call(apiSaga, 'kvpair.update', {
    params: {user_id: userId, key},
    data,
  });

  if (__DEV__) {
    webStorageUtils.setSessionItem(key, data);
  }
}

export function* removeKvpairs({key}) {
  const userId = yield select(getUserId);

  yield call(apiSaga, 'kvpair.delete', {
    params: {user_id: userId, key},
  });

  if (__DEV__) {
    webStorageUtils.removeSessionItem(key);
  }
}

export function* fetchUserSettings() {
  const settings = yield call(fetchKvpairs, {key: 'settings', force: true});

  if (settings === undefined) {
    // if there are no settings in kvpairs yet, make non-blocking call to create them once
    yield spawn(updateUserSettings, {settings: {}, dispatch: false});
  } else {
    yield put({type: 'SET_USER_SETTINGS', data: settings});

    return settings;
  }
}

export function* updateUserSettings({settings, dispatch = true, kvDelay = 1000}) {
  if (dispatch) {
    // Immediately update settings in the store
    yield put({type: 'SET_USER_SETTINGS', data: settings});
  }

  if (kvDelay) {
    // But debounce kvpairs update with some delay in case user changes settings frequently
    yield delay(kvDelay);
  }

  yield call(updateKvpairs, {key: 'settings', data: settings});
}
