/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import Pill from '../Pill';
import {hrefUtils} from '@illumio-shared/utils';
import type {PillDiffProps} from '../PillDiff';
import type {IPList} from 'illumio';

export type IPListDiffProps = Omit<PillDiffProps, 'oldValue' | 'value'> & {
  value?: IPList | IPList[];
  oldValue?: IPList | IPList[];
  // policy version
  pversion?: number | string;
};

export default function IPListDiff(props: IPListDiffProps): JSX.Element {
  const {value, oldValue, pversion = 'draft', ...extra} = props;

  const getIPListPill = (iplist?: IPList) =>
    iplist && {key: hrefUtils.getId(iplist.href), pill: <Pill.IPList value={iplist} pversion={pversion} />};

  const pillDiffProps: PillDiffProps = extra;

  pillDiffProps.value = Array.isArray(value) ? value.map(iplist => getIPListPill(iplist)) : [getIPListPill(value)];
  pillDiffProps.oldValue = Array.isArray(oldValue)
    ? oldValue.map(iplist => getIPListPill(iplist))
    : [getIPListPill(oldValue)];

  return <Pill.Diff {...pillDiffProps} />;
}
