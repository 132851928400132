/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {call} from 'redux-saga/effects';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {cachedResponses} from 'api/apiCache';

export function* createService({params, data}) {
  return yield call(apiSaga, 'services.create', {
    params,
    data,
    *onDone() {
      cachedResponses.removeByMethodName('services.get_collection'); // invalidate list after new item created
    },
  });
}

export function* updateService({params, data}) {
  yield call(apiSaga, 'service.update', {
    params,
    data,
    *onDone() {
      // Invalidate instance
      apiCachedResponses.removeByMethodName('services.get_instance');
      // Invalidate list
      apiCachedResponses.removeByMethodName('services.get_collection');
      // invalidate rules of group
      apiCachedResponses.removeByMethodName('rule_sets.get_instance');
    },
  });
}
