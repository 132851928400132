/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {PureComponent} from 'react';
import styles from './FilterPanel.css';
import type {FilterPanelOption} from './FilterPanel';
import {typesUtils} from '@illumio-shared/utils';

export type Category = 'by Event' | 'by Generated' | 'by Severity' | 'by Timestamp';

export interface FilterOptionProps {
  active?: boolean;
  category: string;
  className: string;
  onClick(category: string, subcategory?: string): void;
  onHover(element: FilterOption | FilterPanelOption): void;
  saveRef(element: FilterOption | FilterPanelOption): void;
  selected?: boolean;
  subcategory?: string;
  children: typesUtils.ReactStrictNode;
}

export default class FilterOption extends PureComponent<FilterOptionProps> {
  option: HTMLElement | null;

  constructor(props: FilterOptionProps) {
    super(props);

    this.option = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: HTMLDivElement) {
    this.option = element;

    this.props.saveRef(this);
  }

  private handleClick() {
    const {category, onClick, subcategory} = this.props;

    onClick(category, subcategory);
  }

  private handleHover() {
    this.props.onHover(this);
  }

  render() {
    const {active, children, subcategory, category, className, selected} = this.props;

    const classes = cx(styles[className], {
      [styles.selected]: selected,
      [styles.active]: active,
    });

    return (
      <div
        data-tid={subcategory ?? _.kebabCase(category)}
        className={classes}
        tabIndex={-1}
        ref={this.saveRef}
        onMouseMove={this.handleHover}
        onClick={this.handleClick}
      >
        {children}
      </div>
    );
  }
}
