/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {Component, createElement, createRef, Fragment} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {TypedMessages} from 'components';
import {tidUtils} from '@illumio-shared/utils';
import styles from './Textarea.css';

export default class Textarea extends Component {
  static propTypes = {
    // By default we set autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" attributes to dom element
    // You can override any of those attributes
    value: PropTypes.string,
    // Whether textarea should have border and error message
    error: PropTypes.bool,
    // Whether textarea should have border and error message
    success: PropTypes.bool,
    // Whether textarea should have border and error message
    warn: PropTypes.bool,
    errorMessage: PropTypes.string,
    tid: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    // rows is the amount of lines for textarea
    rows: PropTypes.number,
    // cols is the width for textarea
    cols: PropTypes.number,
    // subtext for help info
    subText: PropTypes.any,
    // themr object
    theme: PropTypes.object,
    noWrap: PropTypes.bool, // Do not apply wrapper 'div'
  };

  static defaultProps = {
    rows: 3, // specifies the height of the text area (in lines). e.g rows: 3, a height of 3 lines
    noWrap: false,
  };

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.textarea = createRef();
    this.inputEvent = new Event('input', {bubbles: true});
    this.blurEvent = new Event('blur');

    this.clear = this.clear.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.onChange) {
      return {value: nextProps.value};
    }

    return null;
  }

  componentDidMount() {
    Object.defineProperty(this.textarea.current, 'clear', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: this.clear,
    });
  }

  handleChange(evt) {
    if (this.props.onChange) {
      this.props.onChange(evt);
    } else {
      this.setState({value: evt.target.value});
    }
  }

  /**
   * QA helper to clear textarea fields. Otherwise, these fields get re-populated sporadically during re-renders.
   *
   * A programatic event is used here in order to trigger any parent handlers that may update value such as a form.
   */
  clear() {
    // Get setter
    const setValue = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this.textarea.current), 'value').set;

    // Clear field
    setValue.call(this.textarea.current, '');
    // Triggers input event and blur to indicate the textarea has been touched
    this.textarea.current.dispatchEvent(this.inputEvent);
    this.textarea.current.dispatchEvent(this.blurEvent);
  }

  render() {
    const {tid, error, errorMessage, noWrap, subText, theme, success, warn, ...textareaProps} = mixThemeWithProps(
      styles,
      this.props,
    );

    const {value} = this.state;

    return createElement(
      noWrap ? Fragment : 'div',
      noWrap ? null : {className: theme.wrapper},
      <textarea
        key="textarea"
        // Default attributes
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        // Attributes specified by parent
        {...textareaProps}
        // Local state attributes
        value={value}
        ref={this.textarea}
        onChange={this.handleChange}
        data-tid={tidUtils.getTid('comp-field-textarea', tid)}
        className={cx(theme.textarea, {
          [theme.error]: error,
          [theme.success]: success,
          [theme.warn]: warn,
          [theme.fullWidth]: !textareaProps.cols, // Don't add textarea width when cols exist
        })}
      />,
      <TypedMessages key="status" gap="gapXSmall">
        {[
          error && errorMessage
            ? {content: errorMessage, color: 'error', fontSize: 'var(--12px)', tid: `${tid}-error`}
            : null,
          subText ? {content: subText, color: 'gray', fontSize: 'var(--12px)'} : null,
        ]}
      </TypedMessages>,
    );
  }
}
