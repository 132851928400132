/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {Icon} from 'components';
import DayPicker from 'react-day-picker';
import styles from './DateTimePicker.css';

export default class DatePicker extends Component {
  static propTypes = {
    tid: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    onDateChange: PropTypes.func.isRequired,
    single: PropTypes.bool, // single = DatePicker used alone and not as part of DateTimePicker
    active: PropTypes.bool, // active = DatePicker should be shown
    direction: PropTypes.oneOf(['up', 'down']), // direction = which direction the DatePicker should open i.e. up, down
    small: PropTypes.bool, // true means width = 260px, false means width = 300px. Default is true
    shadow: PropTypes.bool, // true means the Datepicker will have a box shadow around it
    enableFutureTimeSelection: PropTypes.bool,
    disablePastTimeSelection: PropTypes.bool,
  };

  static defaultProps = {
    single: true,
    active: false,
    direction: 'down',
    small: true,
    shadow: false,
    enableFutureTimeSelection: false,
    disablePastTimeSelection: false,
  };

  constructor(props) {
    super(props);

    this.shortWeekdays = [
      intl('DateTimeInput.ShortDays.SunSat'),
      intl('DateTimeInput.ShortDays.Mon'),
      intl('DateTimeInput.ShortDays.TuesThurs'),
      intl('DateTimeInput.ShortDays.Wed'),
      intl('DateTimeInput.ShortDays.TuesThurs'),
      intl('DateTimeInput.ShortDays.Fri'),
      intl('DateTimeInput.ShortDays.SunSat'),
    ];

    this.handleDayClick = this.handleDayClick.bind(this);
  }

  handleDayClick(day, {disabled, selected}) {
    // selected is true if the user clicks the already selected date.
    // If the user chooses a disabled or already selected date, don't do anything.
    if (disabled || selected) {
      return;
    }

    this.props.onDateChange(day);
  }

  render() {
    const {shadow, single, active, direction, small, enableFutureTimeSelection, disablePastTimeSelection} = this.props;

    const pickerClass = cx(styles.datePicker, {
      [styles.disabled]: !active,
      [styles.shadow]: shadow,
      [styles.openUp]: single && direction === 'up',
      [styles.absolute]: single,
      [styles.smallSection]: small,
    });

    return (
      <div className={pickerClass}>
        <div className={styles.arrows}>
          <Icon name="back" />
          <Icon name="next" />
        </div>
        <DayPicker
          onDayClick={this.handleDayClick}
          showOutsideDays
          selectedDays={this.props.date}
          weekdaysShort={this.shortWeekdays}
          modifiers={{
            weekdaysShort: this.shortWeekdays,
          }}
          disabledDays={
            enableFutureTimeSelection
              ? disablePastTimeSelection
                ? {before: new Date()}
                : null
              : {
                  after: new Date(),
                }
          }
        />
      </div>
    );
  }
}
