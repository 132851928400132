/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {getAdvancedRulesetDisplay} from 'containers/App/AppState';
import {
  getAllUsersMap,
  getUserPermissions,
  isUserReadOnlyAll,
  isUserReadOnlyOrProvisioner,
  isUserScoped,
} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {categories, gridSettings} from './RulesetListConfig';
import {hrefUtils} from '@illumio-shared/utils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import {isOnboardingObject} from 'antman/utils/general';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'RULESET_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'RULESET_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getRulesets = state => state.ruleset.list;
export const getRulesetsCount = state => state.ruleset.count;

const getRulesetsRows = createSelector(
  [getRulesets, getAllUsersMap, isUserReadOnlyAll],
  (rulesets, usersMap, userIsReadOnlyAll) =>
    rulesets.map(item => {
      // Make Ruleset not selectable for read only user, since it cannot be removed, provisioned, or updated
      // Make Ruleset used by Xpress onboarding wizards not selectable
      const isSelectable =
        !userIsReadOnlyAll &&
        Boolean(item.caps.length) &&
        (!__ANTMAN__ || !isOnboardingObject(item.external_data_reference));

      return {
        key: item.href,
        selectable: isSelectable,
        // Ruleset state can be enabled or disabled only if user has write caps and item is not deletion pending
        writable:
          !userIsReadOnlyAll && item.caps.includes('write') && (!item.update_type || item.update_type !== 'delete'),
        // Ruleset can only if provisioned if user has provision caps
        provisionable: !userIsReadOnlyAll && item.caps.includes('provision') && item.update_type,
        // Fill each ruleset with user object
        data: {...item, updated_by: fillUserInfo(usersMap, item.updated_by)},
      };
    }),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, gridSettings, getAdvancedRulesetDisplay, isUserScoped],
  (userIsReadOnlyAll, gridSettings, advancedRulesetDisplay, userIsScoped) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = userIsReadOnlyAll;

    if (!__ANTMAN__) {
      columns.scope.optional = !userIsScoped && !advancedRulesetDisplay;
    }

    return {...gridSettings, columns};
  },
);

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getRulesetsRows,
    filterMap: getFilterMap,
  });

export const isScopeRequired = createSelector(getUserPermissions, userPermissions => {
  const permissionsWithScopesAll = userPermissions.filter(permission => permission.scope.length === 0);

  return !permissionsWithScopesAll.some(permission => {
    const role = hrefUtils.getId(permission.role.href);

    //Scope is optional for owner, admin or a full ruleset manager with 'All' scopes
    return role === 'owner' || role === 'admin' || role === 'ruleset_manager';
  });
});

export const getRulesetsPage = createStructuredSelector({
  count: getRulesetsCount,
  grid: getGrid,
  categories,
  userIsReadOnlyOrProvisioner: isUserReadOnlyOrProvisioner,
  userIsScoped: isUserScoped,
  scopeIsRequired: isScopeRequired,
  advancedRulesetDisplay: getAdvancedRulesetDisplay,
});
