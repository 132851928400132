/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';

export const getOptionLabel = (value, key) => {
  switch (key) {
    case 'preset':
      return intl('Explorer.LastHours', {count: value});
    case 'custom':
      return intl('Common.Custom');
    default:
      return '';
  }
};
