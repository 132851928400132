/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import Visibility from 'visibilityjs';
import {eventChannel, type EventChannel} from 'redux-saga';
import {call, cancel, delay, fork, take, race, put, type SagaGenerator} from 'typed-redux-saga';
import {errorUtils} from '@illumio-shared/utils';

/**
 *  Add interface with proper function parameter types.
 *  Issue: https://github.com/redux-saga/redux-saga/issues/1177
 * */
interface StartPolling {
  saga: () => SagaGenerator<void>;
  sagaBeforeStart: () => SagaGenerator<void>;
  instantStart: boolean;
  interval: number;
}

/**
 * Saga to listen to page visibility change
 *
 * Produce three actions:
 * 'DOCUMENT_HAS_BECOME_VISIBLE' immediately after page becomes visible (user switched to current tab)
 * 'DOCUMENT_HAS_BECOME_HIDDEN' immediately after page becomes hidden (user switched to other tab)
 * 'DOCUMENT_HAS_BECOME_HIDDEN_DELAYED' after given timeout after page becomes hidden.
 *                                      Useful when you want to set some threshold on hide action, in case user can switch back shortly.
 *
 * Examples:
 *
 * It's convenient to listen to one of these actions in race to cancel other sagas:
 * yield race({
 *   task: call(fetchSomething),
 *   documentVisibility: take('DOCUMENT_HAS_BECOME_HIDDEN'),
 * });
 *
 * It's also possible to listen to any page visibility state change using race:
 * const state = yield race({
 *   visible: take('DOCUMENT_HAS_BECOME_VISIBLE'),
 *   hidden: take('DOCUMENT_HAS_BECOME_HIDDEN'),
 * });
 *
 */
export const startPageVisibilitySaga = (() => {
  const createVisibilityChangeChanel = (): EventChannel<boolean> =>
    eventChannel(emitter => {
      const listener = Visibility.change((_evt, state) => {
        if (__DEV__) {
          console.log(`%cDocument has become ${document.visibilityState}`, 'color:orange;');
        }

        emitter(state === 'visible');
      });

      return (): void => {
        if (typeof listener === 'number') {
          Visibility.unbind(listener);
        }
      };
    });

  return function* (hiddenDelay = 5000): SagaGenerator<void> {
    let delayedTask = null;

    /** type SagaReduxCall has values that is subtype of call() thus use double assertion is ok */
    const chanel = yield* call(createVisibilityChangeChanel);

    function* delayedHiddenAction(timeout: unknown): SagaGenerator<void> {
      try {
        yield* delay(timeout as number);
        yield* put({type: 'DOCUMENT_HAS_BECOME_HIDDEN_DELAYED', delay: timeout});
      } finally {
        delayedTask = null;
      }
    }

    try {
      while (true) {
        const visible = yield* take(chanel);

        if (delayedTask) {
          yield* cancel(delayedTask);
        }

        yield* put({type: visible ? 'DOCUMENT_HAS_BECOME_VISIBLE' : 'DOCUMENT_HAS_BECOME_HIDDEN'});

        if (!visible && hiddenDelay > 0) {
          delayedTask = yield* fork(delayedHiddenAction, hiddenDelay);
        }
      }
    } finally {
      if (delayedTask) {
        yield* cancel(delayedTask);
      }

      yield* call([chanel, chanel.close]);
    }
  };
})();

/**
 * Calls specified saga every given interval while page is visible.
 * When page becomes hidden it stops calling saga or cancels running one (if still running).
 * Considers page hidden after given threshold (not immediately, because user might switch back pretty quickly).
 *
 * @param saga Saga to call
 * @param sagaBeforeStart Saga to call and wait before starting polling loop
 * @param interval Period in milliseconds
 * @param instantStart Whether we should start immediately or after first interval
 * @returns
 */
export function* startPolling({
  saga,
  sagaBeforeStart,
  interval = 10_000,
  instantStart = false,
}: StartPolling): SagaGenerator<void> {
  if (sagaBeforeStart) {
    yield* call(sagaBeforeStart);
  }

  if (!instantStart) {
    // If start is not instant, wait for standard interval first
    yield* delay(interval);
  }

  let lastCallTime;

  while (true) {
    if (Visibility.hidden()) {
      // Wait for document to become visible again to start polling
      yield* take('DOCUMENT_HAS_BECOME_VISIBLE');
    }

    // Keep polling until document become hidden (with delay)
    yield* race([
      take('DOCUMENT_HAS_BECOME_HIDDEN_DELAYED'),
      call(function* polling() {
        while (true) {
          try {
            typeof saga === 'function' ? yield* call(saga) : yield saga; // eslint-disable-line no-unused-expressions
            lastCallTime = Date.now();
          } catch (error) {
            if (!(error instanceof errorUtils.RequestError)) {
              // We should keep polling on request error, for instance, if client lost connection it still can be restored
              throw error;
            }
          }

          yield* delay(interval);
        }
      }),
    ]);

    if (lastCallTime !== undefined) {
      const timePassedSinceLastCall = Date.now() - lastCallTime;

      if (timePassedSinceLastCall < interval) {
        // We should wait for the left of given interval before start waiting for page to become visible again,
        // in case user switches back faster than interval supposed to finish
        yield* delay(interval - timePassedSinceLastCall);
      }
    }
  }
}
