/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {PureComponent} from 'react';
import {Button} from 'components';
import type {ButtonProps} from 'components/Button/Button';
import {reactUtils, typesUtils} from '@illumio-shared/utils';
import _ from 'lodash';

export type CopyButtonProps = ButtonProps & {
  copyText: string;
  onCopy?: () => void;
};

interface CopyButtonStates {
  saving: boolean;
  error: unknown;
  onProgressDone: (value: unknown) => void;
}

export default class CopyButton extends PureComponent<CopyButtonProps, CopyButtonStates> {
  state: CopyButtonStates = {
    saving: false,
    error: null,
    onProgressDone: _.noop,
  };

  constructor(props: CopyButtonProps) {
    super(props);

    this.handleCopy = this.handleCopy.bind(this);
  }

  async handleCopy(): Promise<void> {
    const {copyText, onCopy} = this.props;

    try {
      await reactUtils.setStateAsync({saving: true, error: null}, this);
      await navigator.clipboard.writeText(copyText);
      await new Promise(onProgressDone => this.setState({onProgressDone, saving: false}));

      if (onCopy) {
        onCopy();
      }
    } catch (error) {
      this.setState({error, saving: false});
      console.error('Error while copying to clipboard:\n', error);
    }
  }

  render(): typesUtils.ReactStrictNode {
    const {copyText, onCopy, ...props} = this.props;
    const {saving, onProgressDone, error} = this.state;

    return (
      <Button
        color="standard"
        icon="copy"
        tooltip={intl('Common.CopyToClipboard')}
        onClick={this.handleCopy}
        onProgressDone={onProgressDone}
        progressCompleteWithCheckmark
        progress={saving}
        progressError={error !== null}
        {...props}
      />
    );
  }
}
