/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './ContainerClusterServiceBackendsConfig';
import {getNamespaceTerm} from '../../ContainerClusterUtils';
import {getContainerClusterDetail} from '../ContainerClusterDetailState';

export default {
  serviceBackendList(state = [], action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_SERVICE_BACKENDS':
        return action.data.serviceBackendList;
      default:
        return state;
    }
  },

  serviceBackendCount(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_SERVICE_BACKENDS':
        return action.data.serviceBackendCount;
      default:
        return state;
    }
  },
};

export const getContainerClusterServiceBackends = state => state.containerClusters.serviceBackendList;
export const getContainerClusterServiceBackendsCount = state => state.containerClusters.serviceBackendCount;

const getContainerClusterServiceBackendsRows = createSelector([getContainerClusterServiceBackends], backends =>
  backends.map(item => ({
    key: item.name + item.created_at,
    data: {
      ...item,
    },
  })),
);

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getContainerClusterServiceBackendsRows,
  });

export const getContainerClusterServiceBackendsPage = createSelector(
  [getContainerClusterDetail, getGrid, getContainerClusterServiceBackendsCount],
  (clusterDetail, grid, count) => {
    grid.columns.get('namespace').header = getNamespaceTerm(clusterDetail);

    return {grid, count};
  },
);
