/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import * as PropTypes from 'prop-types';
import {connect, useSelector} from '@illumio-shared/utils/redux';
import {useRef, useContext, useMemo} from 'react';
import {Icon, Link, Modal} from 'components';
import {getPCEBuild, getVersion} from 'containers/App/AppState';
import styles from './ASPVersion.css';
import stylesUtils from 'utils.css';
import {AppContext} from 'containers/App/AppUtils';
import {getWhatsNewDocUrl} from 'containers/Popup/WhatsNewPopup/WhatsNewPopupState';
import cx from 'classnames';

const linkToPatents = 'https://www.illumio.com/patents';
const linkToTerms = 'https://www.illumio.com/eula';
const linkToPrivacy = 'https://www.illumio.com/privacy-policy';

export default connect(state => ({
  ASPVersion: getVersion(state),
  PCEBuild: getPCEBuild(state),
}))(ASPVersion);

ASPVersion.propTypes = {
  // Handler on close
  onClose: PropTypes.func,
  // Or link on close
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function ASPVersion({link, onClose, ASPVersion, PCEBuild}) {
  const buttonRef = useRef();
  const whatsNewDocUrl = useSelector(getWhatsNewDocUrl);
  const {navigate} = useContext(AppContext);

  const handleClose = () => {
    if (link) {
      navigate(link);
    }

    onClose?.();
  };

  const versionSection = useMemo(
    () => (
      <div className={stylesUtils.gapSmall}>
        <div className={cx(stylesUtils.centerFlexAlign, stylesUtils.gapHorizontal, stylesUtils.gapXLarge)}>
          <div className={styles.version}>
            {intl('VersionMismatch.PCE')} {ASPVersion}-{PCEBuild}
          </div>
          {!__ANTMAN__ && (
            <Link href={whatsNewDocUrl} target="_blank" theme={styles} themeCompose="merge">
              {intl('Common.WhatsNew')}
            </Link>
          )}
        </div>
        <div className={styles.version}>
          {intl('VersionMismatch.UI')} {process.env.UI_VERSION}
        </div>
      </div>
    ),
    [whatsNewDocUrl, ASPVersion, PCEBuild],
  );

  return (
    <Modal.Alert
      gap="gapLarge"
      closeRef={buttonRef}
      buttonProps={{tid: 'ok', text: intl('Common.OK'), onClick: handleClose, ref: buttonRef}}
      title={intl('Common.AboutThisProduct')}
    >
      <Icon name="illumio-logo" theme={styles} />
      {versionSection}
      <div className={stylesUtils.gapXLarge}>
        <div className={stylesUtils.gapSmall}>
          <Link href={linkToPatents} target="_blank" theme={styles} themeCompose="merge">
            {intl('Common.Patents')}
          </Link>
          <Link href={linkToTerms} target="_blank" theme={styles} themeCompose="merge">
            {intl('Common.TermsAndAgreements')}
          </Link>
          <Link href={linkToPrivacy} target="_blank" theme={styles} themeCompose="merge">
            {intl('Common.PrivacyPolicy')}
          </Link>
        </div>
        <div className={stylesUtils.gapMedium}>{intl('Illumio.Copyright')}</div>
      </div>
    </Modal.Alert>
  );
}
