/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {fetchPendingObjectList} from 'containers/Provisioning/Pending/List/PendingListSaga';
import {gridSettings} from './VersionListConfig';
import {getProvisionVersions} from './VersionListState';
import {generalUtils} from '@illumio-shared/utils/shared';
import gridSaga from 'components/Grid/GridSaga';
import {isUserWithReducedScope} from 'containers/User/UserState';
import {errorUtils} from '@illumio-shared/utils';

export function* fetchProvisionVersions({force = false} = {}) {
  const query = {max_results: 500}; // we are getting everything, the count is not honored.

  return yield call(apiSaga, 'sec_policies.get_collection', {
    query,
    cache: !force,
    *onDone({data: list}) {
      if (force || list !== (yield select(getProvisionVersions))) {
        yield put({type: 'PROVISION_VERSIONS_GET_LIST', data: {list}});
      }

      return {list};
    },
  });
}

export function* fetchProvisionVersionsList(route, refetch = false) {
  const userIsWithReducedScope = yield select(isUserWithReducedScope);

  // Users with limited scoped read are not allowed to access this component.
  if (userIsWithReducedScope) {
    throw new errorUtils.RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: gridSettings,
    *onSaga() {
      const [{list}, draft = {}] = yield all([
        call(fetchProvisionVersions, {force: refetch}),
        call(fetchPendingObjectList, {force: refetch}),
        call(fetchAllUsers, {force: refetch}),
      ]);

      let listLen = list.length;

      if (!generalUtils.isDeepEmpty(draft)) {
        listLen++;
      }

      return listLen;
    },
  });
}
