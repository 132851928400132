/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import type {Simplify} from 'type-fest';
import * as PropTypes from 'prop-types';
import {
  Children,
  Fragment,
  useRef,
  Component,
  isValidElement,
  useEffect,
  useCallback,
  type JSXElementConstructor,
  type ReactNode,
  type ComponentType,
  type ReactElement,
} from 'react';
import {generalUtils} from '@illumio-shared/utils/shared';
import {typesUtils} from '.';
import {createSelectorCreator, defaultMemoize} from 'reselect';

export type MutuallyExclusiveTrueValidator = (
  props: {[prop: string]: unknown},
  propName: string,
  componentName: string,
) => Error | undefined;

/**
 * A helper generic to return props which are declared in defaultProps, as required
 */
export type DefaultProps<Props extends {[Key in keyof DefaultType]?: unknown}, DefaultType> = Required<
  Pick<Props, keyof DefaultType>
>;

/**
 * A helper generic to return a final props type, with the default props not being optional.
 * Implementation can be replaced with `SetRequired` from typ-fest, once 'Except' is fixed in WebStorm
 */
export type WithDefaultProps<Props extends {[Key in keyof DefaultType]?: unknown}, DefaultType> = Simplify<
  Omit<Props, keyof DefaultType> & Required<Pick<Props, keyof DefaultType>>
>;

/**
 * Asynchronous version of setState.
 * Returns promise that is resolved once setState second parameter is called (after componentDidUpdate)
 *
 * @param newState Regular setState parameter, that can be object or function
 * @param Instance (this) of your component
 * @returns Promise resolved with updated state object
 */
export const setStateAsync = <K extends keyof S, S, C extends Component>(
  newState: ((prevState: Readonly<S>) => Pick<S, K> | null) | {[key: string]: unknown},
  instance: C,
): Promise<C['state']> =>
  new Promise(resolve => {
    instance.setState(newState, () => resolve(instance.state));
  });

/**
 * Returns an array of not false/null/undefined children with unwrapped (flattened) Fragment
 * Can get a function as children to call
 *
 * @param children Children from props
 * @param options
 * @param {Object|Function|String} [options.match] If only components of specified type should be returned
 * @returns
 */
export function unwrapChildren<T extends ReactNode>(
  children: T | T[] | (() => T | T[]),
  options: {
    match?: JSXElementConstructor<any> | string; // eslint-disable-line @typescript-eslint/no-explicit-any
  } = {},
): typesUtils.TruthFull<T>[] {
  let actualChildren: T | T[];

  if (typeof children === 'function') {
    actualChildren = children();
  } else {
    actualChildren = children;
  }

  return (Array.isArray(actualChildren) ? actualChildren : (Children.toArray(actualChildren) as T[])).reduce(
    (result: typesUtils.TruthFull<T>[], child) => {
      if (child !== false && child !== null && child !== undefined) {
        if (isValidElement(child) && child.type === Fragment) {
          const unWrap = unwrapChildren(child.props.children, options);

          result.push(...unWrap);
        } else if (options.match === undefined || (isValidElement(child) && child.type === options.match)) {
          result.push(child as typesUtils.TruthFull<T>);
        }
      }

      return result;
    },
    [],
  );
}

/**
 * PropType to validate that if target prop is true, all other specified props are not true
 *
 * @param List of other boolean prop names that should be mutually exclusive with current one
 * @returns PropTypes validator or TypeError
 */
export const mutuallyExclusiveTrueProps = (...exclusiveProps: string[]): MutuallyExclusiveTrueValidator | TypeError => {
  if (!exclusiveProps.length) {
    throw new TypeError('Exclusive true props must be specified');
  }

  if (exclusiveProps.some(x => typeof x !== 'string')) {
    throw new TypeError('Exclusive true props must be strings');
  }

  return function mutuallyExclusiveTrueValidator(
    props: {[prop: string]: unknown},
    propName: string,
    componentName: string,
  ): Error | undefined {
    const value = props[propName];

    if (value !== undefined && typeof value !== 'boolean') {
      return new Error(`Prop '${propName}' supplied to '${componentName}' should be a Boolean`);
    }

    if (value === true) {
      const otherTrueProps = exclusiveProps.filter(prop => props[prop] === true);

      if (otherTrueProps.length > 1) {
        return new Error(
          `If in ${componentName} '${propName}' prop is true, '${intl.list(
            otherTrueProps.map(p => `'${p}'`),
          )}' can't be set to true`,
        );
      }
    }
  };
};

/**
 * Gets list of boolean prop names and enforces that only one of them is allowed to be true
 * Returns object of PropTypes for given prop names that should be mixed into target propTypes
 *
 * @param List of other boolean prop names that should be mutually exclusive with current one
 * @returns Object of props that should be mixed into propTypes
 */
export const mutuallyExclusiveTruePropsSpread = (...exclusiveProps: string[]): Error | {[key: string]: unknown} => {
  if (exclusiveProps.length < 2) {
    throw new TypeError('You should specify two or more exclusive true props');
  }

  if (exclusiveProps.some(x => typeof x !== 'string')) {
    throw new TypeError('Exclusive true props must be strings');
  }

  let checkedCounter = 0;

  function mutuallyExclusiveTruePropsSpreadValidator(
    props: {[prop: string]: unknown},
    propName: string,
    componentName: string,
  ): Error | undefined {
    const value = props[propName];

    if (checkedCounter === exclusiveProps.length) {
      checkedCounter = 0;
    }

    checkedCounter++;

    if (value !== undefined && typeof value !== 'boolean') {
      return new Error(`Prop '${propName}' supplied to '${componentName}' should be a Boolean`);
    }

    if (checkedCounter === 1) {
      const trueProps = exclusiveProps.filter(prop => props[prop] === true);

      if (trueProps.length > 1) {
        return new Error(
          `Component ${componentName} can't have ${intl.list(
            trueProps.map(p => `'${p}'`),
          )} props to be true at the same time`,
        );
      }
    }
  }

  return exclusiveProps.reduce(
    (props, prop) => {
      props[prop] = mutuallyExclusiveTruePropsSpreadValidator;

      return props;
    },
    {} as {[key: string]: unknown},
  );
};

/**
 * React hook to compare new value with the previous one, and return the previous one if they are deeply equal.
 * Useful to compare complex objects in hooks dependencies to bail out of their updates.
 *
 * @param value
 * @returns
 */
export const useDeepCompareMemo = <T>(value: T): T => {
  const ref = useRef<T>();

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current!;
};

/**
 * PropType to describe a ref prop
 */
export const PropTypeRef = PropTypes.oneOfType([
  // Either a function
  PropTypes.func,
  // Or any value saved in the `current` prop
  PropTypes.shape({current: PropTypes.any}),
]);

/**
 * A conventional way to get the HTML element of a component
 * @param node the react element
 * @returns {HTMLElement}
 */
export const getNativeElement = (
  node?: HTMLElement | (ReactElement & WithElement) | null,
): HTMLElement | null | undefined => {
  if (!node) {
    return node;
  }

  // in the case of <Button>, the HTML element is in `button`
  // in most cases, there is an `element` instance member for HTML element
  // in all other cases, we assume the node itself is the HTML element (native html tags e.g. <div>)
  return node instanceof HTMLElement ? node : node.button ?? node.element;
};

/**
 * Conventionally some React component class exposes the underlying DOM node through an `element` property
 */
export type WithElement<E extends Element = HTMLElement> = {
  element?: E | null;
  button?: E | null;
};

/**
 * Check if an object is a react element of certain component type
 * @param obj The object to be tested
 * @param componentType The react component type, either the component function or component class
 * @returns boolean
 */
export const isReactElementOf = <P>(obj: ReactNode, componentType: ComponentType<P>): obj is ReactElement<P> => {
  return isValidElement(obj) && obj.type === componentType;
};

/**
 * Custom hook to save the previous value
 * @param value - any value
 * @param initialValue - initial value (returned first time hook is called)
 * @returns the previous value
 */
export function usePrevious(value: unknown, initialValue?: unknown): unknown {
  const ref = useRef<unknown>(initialValue);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

/**
 * Custom React hook that combines 'onClick' and 'onDoubleClick' into a single useCallback.
 * The useCallback returned by the hook should be passed to the element's 'onClick' prop.
 * The time between clicks for a 'double-click' can be controlled using the 'delay' argument.
 *
 * @param onClick - called after a single click event
 * @param onDoubleClick - called after a double click event
 * @param delay - length of time between clicks (milliseconds)
 */
export function useMultiClickHandler({
  onClick,
  onDoubleClick,
  delay = 250,
}: {
  onClick?: (params: {id: string | null}) => void;
  onDoubleClick?: (params: {id: string | null}) => void;
  delay?: number;
}): (params: {id: string | null}) => void {
  const clickTime = useRef<number | undefined>();
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const clickCounter = useRef<number>(0);

  const reset = useCallback((timeoutCopy?: NodeJS.Timeout | undefined) => {
    if (timeout.current || timeoutCopy) {
      clearTimeout(timeout.current ?? timeoutCopy);
    }

    timeout.current = undefined;
    clickTime.current = undefined;
    clickCounter.current = 0;
  }, []);

  const handleFirstClick = useCallback(
    (time: number, params: {id: string | null}) => {
      if (clickTime.current === time) {
        reset();

        return onClick?.(params);
      }
    },
    [onClick, reset],
  );

  const handleSecondClick = useCallback(
    (params: {id: string | null}) => {
      if (timeout.current) {
        reset();

        return onDoubleClick?.(params);
      }
    },
    [onDoubleClick, reset],
  );

  const clickHandler = useCallback(
    (params: {id: string | null}) => {
      let returnValue;

      clickCounter.current++;

      switch (clickCounter.current) {
        case 1:
          const time = Date.now();

          clickTime.current = time;
          timeout.current = setTimeout(() => {
            returnValue = handleFirstClick(time, params);
          }, delay);
          break;
        case 2:
          returnValue = handleSecondClick(params);
          break;
        default:
          break;
      }

      return returnValue;
    },
    [handleFirstClick, handleSecondClick, delay],
  );

  useEffect(() => {
    // Create a copy of the timeout so it is available when useEffect's cleanup hook is called.
    const timeoutCopy = timeout.current;

    return () => {
      reset(timeoutCopy);
    };
  }, [reset]);

  return clickHandler;
}

// Create a "selector creator" that uses lodash.isEqual for incoming arguments instead of ===
// https://github.com/reduxjs/reselect#customize-equalitycheck-for-defaultmemoize
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _.isEqual);

// Create a "selector creator" that uses shallowEqual for incoming arguments instead of ===
export const createShallowEqualSelector = createSelectorCreator(defaultMemoize, generalUtils.shallowEqual);

/**
 * Splits a string of classes, then maps over each class returning the value of the class (key) found in the styles object
 * The result is passed to cx which joins the classes back together
 *
 * @param styles
 * @param classes
 * @returns css
 */
export const classSplitter = (styles: {[css: string]: string}, classes?: string): string =>
  classes ? cx(classes.split(' ').map(cl => styles[cl])) : '';

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: (event?: MouseEvent | TouchEvent) => void,
): void {
  useEffect(() => {
    if (ref === null) {
      return;
    }

    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [handler, ref]);
}
