/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {getHelpUrl, getParsedUIVersionNumber, getProductName} from 'containers/App/AppState';

export const getWhatsNewDocUrl = state => {
  const origin = getHelpUrl(state);

  const {major, minor} = getParsedUIVersionNumber(state);
  const product = getProductName(state);

  return `${origin}/${product}/${major}.${minor}/Content/LandingPages/Guides/whats-new-in-${major}-${minor}.htm`;
};
