/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import GridLocal from '../GridLocal';
import stylesGridUtils from 'components/Grid/GridUtils.css';
import styles from './GridDiff.css';

const removeRowHighLight = {className: stylesGridUtils.removeRows};
const addedRowHighlight = {className: stylesGridUtils.addRows};

GridDiff.propTypes = {
  // Usually diff should be showed only on draft pages
  noDiff: PropTypes.bool,

  settings: PropTypes.object.isRequired, //Grid config
  rows: PropTypes.array.isRequired,
  prevRows: PropTypes.array,
};

export default function GridDiff(props) {
  const {noDiff = false, settings, rows, prevRows, ...gridProps} = props;

  if (noDiff) {
    return <GridLocal settings={settings} rows={rows} {...gridProps} theme={styles} />;
  }

  const rowsMap = new Map();

  for (const row of rows) {
    rowsMap.set(row.key, row);
  }

  const extraPropsKeyMap = new Map();

  const {unchangedRows, removedRows} = prevRows.reduce(
    (acc, row) => {
      if (rowsMap.has(row.key)) {
        rowsMap.delete(row.key);
        acc.unchangedRows.push(row);
      } else {
        acc.removedRows.push({status: 'remove', ...row});
        extraPropsKeyMap.set(row.key, removeRowHighLight);
      }

      return acc;
    },
    {unchangedRows: [], removedRows: []},
  );

  const addedRows = Array.from(rowsMap.values(), row => ({status: 'add', ...row}));

  addedRows.forEach(row => extraPropsKeyMap.set(row.key, addedRowHighlight));

  return (
    <GridLocal
      settings={settings}
      rows={[...addedRows, ...unchangedRows, ...removedRows]}
      extraPropsKeyMap={extraPropsKeyMap}
      {...gridProps}
      theme={styles}
    />
  );
}

// To avoid importing GridDiff separately
GridLocal.Diff = GridDiff;
