/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteCurrentParams, getRouteName} from 'containers/App/AppState';
import {isUserReadOnly} from 'containers/User/UserState';
import {portIP} from '../FlowCollectionFiltersUtils';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'FLOWCOLLECTIONFILTERS_GET_ITEM':
        return action.data;
      default:
        return state;
    }
  },
};

export const getFlowCollectionFilter = state => state.flowCollectionFilters.instance;

export const getFlowCollectionFiltersEdit = createSelector(
  [getFlowCollectionFilter, getRouteName, getRouteCurrentParams, isUserReadOnly],
  (flowCollection, routeName, currentRouteParams, userIsReadOnly) => {
    const flowCollectionFilter =
      routeName === 'app.flowCollectionFilters.create'
        ? {
            action: 'drop',
            transmission: 'broadcast',
            network: flowCollection.network,
            source: flowCollection.data_source,
          }
        : {
            action: flowCollection.action === 'drop' ? 'drop' : 'aggregate',
            transmission: {
              B: 'broadcast',
              M: 'multicast',
              U: 'unicast',
            }[flowCollection.transmission],
            network: flowCollection.network,
            source: flowCollection.data_source,
            protocol: flowCollection.target.proto,
            destip: flowCollection?.target[portIP.destIP],
            destport: flowCollection?.target[portIP.destPort],
            srcip: flowCollection?.target[portIP.srcIP],
            srcport: flowCollection?.target[portIP.srcPort],
          };

    return {
      flowCollectionFilter,
      routeName,
      currentRouteParams,
      userIsReadOnly,
    };
  },
);
