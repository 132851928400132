/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError, {type ApplicationErrorOptions} from './Application';

export interface APIErrorOptions<T = unknown> extends ApplicationErrorOptions {
  data?: T;
  response?: Partial<Response>;
  timeout?: boolean;
}

/**
 * API error
 */
export default class APIError<T = unknown> extends ApplicationError {
  data?: T;
  timeout?: boolean;
  response?: Partial<Response>;

  constructor(params: APIErrorOptions<T> | string = {}) {
    if (typeof params === 'string') {
      params = {code: params};
    }

    const {data, response = {}, timeout, ...details} = params;
    const messages = [details.message ?? response.statusText ?? ''];

    if (Array.isArray(data)) {
      for (const item of data) {
        if (typeof item?.message === 'string') {
          messages.push(item.message);
        }
      }
    }

    details.message = messages.join('\n • ');
    _.defaults(details, {
      trace: false,
      code: 'API_ERROR',
      statusCode: 400,
    });

    super(details);
    this.name = 'APIError';

    this.data = data;
    this.timeout = timeout;
    this.response = response;
  }
}
