/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {Item} from 'regraph';
import {locale} from '@illumio-shared/utils/intl/locale';
import type {TimeRange} from './MapTimeMachine';
import type {ItemsMetadata, DateTimeUnit, SequenceIdRange} from './MapTimeMachineTypes';
import type {GraphLinks, Items} from 'containers/IlluminationMap/Graph/MapGraphTypes';
import type {ResultStatus} from 'containers/IlluminationMap/MapTypes';

export const formatTimeRange = (range: TimeRange): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return `${range.start.toLocaleString(locale, options)} - ${range.end.toLocaleString(locale, options)}`;
};

export const minDate = (d1: Date, d2: Date): Date => (d2.getTime() < d1.getTime() ? d2 : d1);

export const maxDate = (d1: Date, d2: Date): Date => (d2.getTime() > d1.getTime() ? d2 : d1);

export const getTimeDeltaPercentage = (d1: Date, d2: Date): number =>
  100 * ((d1.getTime() - d2.getTime()) / d1.getTime());

export const areTimeRangesOverlapping = (r1: TimeRange, r2: TimeRange, includeEdges = true): boolean =>
  includeEdges ? r1.start <= r2.end && r1.end >= r2.start : r1.start < r2.end && r1.end > r2.start;

export const areTimeRangesEqual = (r1: TimeRange, r2: TimeRange): boolean =>
  r1.start?.getTime() === r2.start?.getTime() && r1.end?.getTime() === r2.end?.getTime();

export const truncateDateTime = (date: Date, unit: DateTimeUnit = 'milliseconds'): Date => {
  const newDate = new Date(date);

  switch (unit) {
    case 'hours':
      newDate.setUTCHours(0);
    case 'minutes':
      newDate.setUTCMinutes(0);
    case 'seconds':
      newDate.setUTCSeconds(0);
    case 'milliseconds':
      newDate.setUTCMilliseconds(0);
      break;
  }

  return newDate;
};

export const truncateTimeRange = (range: TimeRange, unit?: DateTimeUnit): TimeRange => ({
  start: truncateDateTime(range.start, unit),
  end: truncateDateTime(range.end, unit),
});

export const isTimeRangeValid = (range?: {start?: Date; end?: Date}): boolean =>
  range?.start instanceof Date && range?.end instanceof Date && range.start.getTime() <= range.end.getTime();

export const adjustDate = (date: Date, amount: number, unit: DateTimeUnit = 'seconds'): Date => {
  const newDate = new Date(date);
  let timeFactor = 1;

  switch (unit) {
    case 'years':
      newDate.setUTCFullYear(date.getUTCFullYear() + amount);
      break;
    case 'months':
      newDate.setUTCMonth(date.getUTCMonth() + amount);
      break;
    case 'days':
      newDate.setUTCDate(date.getUTCDate() + amount);
      break;
    case 'hours':
      timeFactor *= 60;
    case 'minutes':
      timeFactor *= 60;
    case 'seconds':
    case 'milliseconds':
      timeFactor *= 1000;
      newDate.setTime(date.getTime() + Math.round(timeFactor * amount));
      break;
  }

  return newDate;
};

export const getSequenceIdRange = ({
  items,
  timeRange,
}: {
  items: Items;
  timeRange?: TimeRange;
}): {
  start: number;
  end: number;
} => {
  const {links} = timeRange ? filterGraphItemsByTimeRange({items, range: timeRange}) : items;
  const {start, end} = Object.values(links).reduce(
    (result: {start?: number; end?: number}, link) => ({
      start: Math.min(result.start ?? link.data.sequenceId, link.data.sequenceId),
      end: Math.max(result.end ?? link.data.sequenceId, link.data.sequenceId),
    }),
    {},
  );

  return {start: start ?? 0, end: end ?? 0};
};

export function computeTimeMachineData(graphItems: Items): {
  items: Record<string, Item>;
  metadata: ItemsMetadata;
} {
  const links: Record<string, Item> = {};
  const timeRange: {start?: Date; end?: Date} = {};
  const sequenceIdRange: {start?: number; end?: number} = {};

  Object.entries(graphItems.links ?? {}).forEach(([key, link]) => {
    const {firstDetected, lastDetected, numConnections, sequenceId} = link.data ?? {};

    if (sequenceId !== undefined) {
      sequenceIdRange.start = Math.min(sequenceId, sequenceIdRange.start ?? sequenceId);
      sequenceIdRange.end = Math.max(sequenceId, sequenceIdRange.end ?? sequenceId);
    }

    if (firstDetected !== undefined) {
      timeRange.start = minDate(firstDetected, timeRange.start ?? firstDetected);
    }

    if (lastDetected !== undefined) {
      timeRange.end = maxDate(lastDetected, timeRange.end ?? lastDetected);
    }

    // @ts-ignore - figure out why value is not supported.
    links[key] = {...link, value: numConnections, times: [{time: {start: firstDetected, end: lastDetected}}]};
  });

  return {
    items: {...links},
    metadata: {
      sequenceIdRange: sequenceIdRange as SequenceIdRange,
      timeRange: timeRange as TimeRange,
    },
  };
}

export function filterGraphItemsByTimeRange({items, range}: {items: Items; range: TimeRange}): Items {
  const {start, end} = range ?? {};

  if (!start || !end) {
    return items;
  }

  const {links, endpointIds} = Object.entries(items.links ?? {}).reduce(
    (result: {links: GraphLinks; endpointIds: Set<string>}, [key, link]) => {
      const {id1, id2, firstDetected, lastDetected} = link.data;

      if (areTimeRangesOverlapping({start, end}, {start: firstDetected, end: lastDetected})) {
        result.links[key] = link;
        result.endpointIds.add(id1);
        result.endpointIds.add(id2);
      }

      return result;
    },
    {links: {}, endpointIds: new Set([])},
  );

  const managedEndpoints = Object.fromEntries(
    Object.entries(items.managedEndpoints ?? {}).filter(([id]) => endpointIds.has(id)),
  );

  const unmanagedEndpoints = Object.fromEntries(
    Object.entries(items.unmanagedEndpoints ?? {}).filter(
      ([type, endpoints]) =>
        endpointIds.has(type) || Object.keys(endpoints.items ?? {}).some(id => endpointIds.has(id)),
    ),
  );

  return {
    links: links ?? {},
    managedEndpoints: managedEndpoints ?? {},
    unmanagedEndpoints: unmanagedEndpoints ?? {},
  };
}

export const getDragDirection = (timeRange: TimeRange, newTimeRange: TimeRange): 'left' | 'none' | 'right' => {
  if (
    newTimeRange.start.getTime() < timeRange.start.getTime() ||
    newTimeRange.end.getTime() < timeRange.end.getTime()
  ) {
    return 'right';
  }

  if (
    newTimeRange.start.getTime() > timeRange.start.getTime() ||
    newTimeRange.end.getTime() > timeRange.end.getTime()
  ) {
    return 'left';
  }

  return 'none';
};

export const clampTimeRange = (timeRange: TimeRange, validTimeRange: TimeRange): TimeRange => ({
  start: new Date(minDate(timeRange.start, validTimeRange.end)),
  end: new Date(maxDate(timeRange.end, validTimeRange.start)),
});

export const abbreviateNumber = (
  value: number,
  options?: {showApproximatePrefix?: boolean; approximatePrefix?: string},
): string => {
  const {showApproximatePrefix = false, approximatePrefix = '~'} = options ?? {};

  if (Math.round(value) < 10e11) {
    const base = Math.floor(Math.log(Math.abs(Math.round(value))) / Math.log(10e2));
    const suffix = 'kmb'[Math.min(2, base - 1)];
    const precision = Math.round(value) > 10e8 ? 2 : Math.round(value) > 10e5 ? 1 : 0;
    const adjustor = Math.pow(10, precision);
    const adjusted =
      Math.round(value) < 1000 ? Math.round(value) : Math.round((value / Math.pow(1000, base)) * adjustor) / adjustor;
    const exact = adjusted * Math.pow(1000, base) === value;
    const prefix = showApproximatePrefix && !exact ? approximatePrefix : '';

    return `${prefix}${adjusted.toFixed(adjusted % 1 === 0 ? 0 : precision)}${suffix ?? ''}`;
  }

  return '';
};

export const getTimeMachineProgressState = ({
  resultStatus,
}: {
  resultStatus: ResultStatus | undefined;
}): {
  timeMachineBusy: boolean;
  dataJustLoaded: boolean;
} => {
  const ready = resultStatus?.status === 'ready';
  const readyAfterDownloadProcessing = ready && resultStatus?.previous?.status === 'download-processing';
  const readyAfterPostDownloadProcessing =
    ready &&
    resultStatus?.previous?.status === 'processing' &&
    resultStatus?.previous?.previous?.status === 'download-processing';
  const dataJustLoaded = readyAfterDownloadProcessing || readyAfterPostDownloadProcessing;

  return {
    timeMachineBusy: !ready,
    dataJustLoaded,
  };
};
