/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {PureComponent, type MouseEventHandler} from 'react';
import Modal from '../Modal';
import {Button, type ButtonProps} from 'components';
import type {ModalFooterProps} from '../ModalFooter';
import type {ModalContentProps} from '../ModalContent';
import type {ModalHeaderProps} from '../ModalHeader';
import {typesUtils} from '@illumio-shared/utils';

// Default cancel button looks like link with Cancel text
const defaultCancelProps = {
  noFill: true,
  tid: 'cancel',
  text: intl('Common.Cancel'),
};
// Default confirm button
const defaultConfirmPropsValue = {
  tid: 'ok',
  text: intl('Common.OK'),
  progressCompleteWithCheckmark: true,
};

// List of props specific to Alert modal, all extra props will be passed down to rendered Modal as is
interface ConfirmationModalProps extends Omit<ModalContentProps, 'children'>, Omit<ModalHeaderProps, 'children'> {
  onCancel: () => void; // If you want to specify cancel callback only and take button props from defaultCancelProps
  cancelProps?: ButtonProps; // Object with custom props for a button, will be merged with defaultButtonProps and onClose (if passed)

  onConfirm: (action?: unknown) => void; // If you want to specify confirm callback only and take button props from defaultConfirmProps
  confirmProps: ButtonProps; // Or object with custom props for confirm button, will be merged with defaultConfirmProps

  // Shortcut to disable cancel button and set progress state to confirm one
  // If you need different or more button state, you can pass cancel and confirm objects manually
  confirmIsInProgress?: boolean;
  defaultConfirmProps?: ButtonProps;
  footerProps?: ModalFooterProps;
  onClose?: MouseEventHandler;
  children: typesUtils.ReactStrictNode;
}

export default class ConfirmationModal extends PureComponent<ConfirmationModalProps> {
  render() {
    const {
      children,
      onCancel,
      cancelProps,
      onConfirm,
      confirmProps,
      confirmIsInProgress,
      defaultConfirmProps = defaultConfirmPropsValue,
      // Destructure <Modal.Header> props
      title = intl('Common.AreYouSure'),
      noCloseIcon,
      // Destructure <Modal.Content> props
      insensitive,
      notScrollable,
      noPaddings,
      gap,
      footerProps = {},
      ...modalProps
    } = this.props;
    const headerProps = {title, noCloseIcon};
    const contentProps = {insensitive, notScrollable, noPaddings, gap};

    if (!modalProps.onClose && onCancel) {
      modalProps.onClose = onCancel;
    }

    return (
      <Modal {...modalProps}>
        <Modal.Header {...headerProps} />
        <Modal.Content {...contentProps}>{children}</Modal.Content>
        <Modal.Footer {...footerProps}>
          <Button onClick={onCancel} {...defaultCancelProps} {...cancelProps} />
          <Button onClick={onConfirm} progress={confirmIsInProgress} {...defaultConfirmProps} {...confirmProps} />
        </Modal.Footer>
      </Modal>
    );
  }
}
