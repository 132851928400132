/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {getRestrictionTextFromExclusions} from '../AccessRestrictionUtils';
import {createSelector} from 'reselect';

export const gridSettings = createSelector([], () => ({
  id: 'accessrestrictionslist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    description: {
      header: intl('Common.Description'),
      value: 'description',
    },
    ipaddress: {
      header: intl('PolicyGenerator.Addresses'),
      value: 'ips',
      sort: ({value}) => value.join('').toLowerCase(),
      format: ({value}) => (value.length ? value.map(item => <div key={item}>{item}</div>) : null),
    },
    restrictionappliedto: {
      header: intl('AccessRestriction.RestrictionAppliedTo'),
      value: ({row}) => getRestrictionTextFromExclusions(row.data.enforcement_exclusions),
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breakpoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['description'], size: 'minmax(120px, auto)'},
      {columns: ['ipaddress'], size: 'minmax(120px, auto)'},
      {columns: ['restrictionappliedto'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name'], size: 'min-content'},
            {columns: ['description'], size: 'minmax(120px, auto)'},
            {columns: ['ipaddress'], size: 'minmax(120px, auto)'},
            {columns: ['restrictionappliedto'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name'], size: 'min-content'},
          {columns: ['description'], size: 'minmax(120px, auto)'},
          {columns: ['ipaddress'], size: 'minmax(120px, auto)'},
          {columns: ['restrictionappliedto'], size: 'minmax(140px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'description'], size: 'minmax(140px, auto)'},
            {columns: ['ipaddress', 'restrictionappliedto'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'description'], size: 'minmax(140px, auto)'},
          {columns: ['ipaddress', 'restrictionappliedto'], size: 'minmax(140px, auto)'},
        ];
      },
    },
    {
      maxWidth: 500,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'description', 'ipaddress', 'restrictionappliedto'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'description', 'ipaddress', 'restrictionappliedto'], size: 'minmax(140px, auto)'},
        ];
      },
    },
  ],
}));
