/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

import {getParsedUIVersionNumber} from 'containers/App/AppState';
import {fetchKvpairs, updateKvpairs} from 'containers/User/Settings/SettingsSaga';
import {call, spawn, select} from 'redux-saga/effects';
import {BroadcastChannel} from 'broadcast-channel';

export function* fetchWhatsNew() {
  return yield call(fetchKvpairs, {key: 'whats_new'});
}

export function* updateWhatsNewCheckVersion(version) {
  yield spawn(updateKvpairs, {key: 'whats_new', data: version});
}

export const broadcast = new BroadcastChannel('whatsNewChannel');

export function* dismissWhatsNew() {
  const currentVersion = yield select(getParsedUIVersionNumber);

  yield call(updateWhatsNewCheckVersion, currentVersion);

  // send an action into broadcast channel
  broadcast.postMessage({type: 'WHATSNEW_DISMISS', data: currentVersion});
}
