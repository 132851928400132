/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import settingsReducers from './Settings/CoreServicesSettingsState';
import listReducers from './List/CoreServicesListState';
import itemReducers from './Item/CoreServicesItemState';

export default {
  coreServices: combineReducers({
    ...settingsReducers,
    ...listReducers,
    ...itemReducers,
  }),
};
