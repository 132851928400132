/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from '../FormUtils';
import DateTimeRangePicker from 'components/DateTimeRangePicker/DateTimeRangePicker';
import {areOptionsEqual} from 'components/DateTimeRangePicker/DateTimeRangePickerUtils';

FormDateTimeRangePicker.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,
  customPickers: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),

  // By default FormSelectorOld calls Selector onSelectionChange method and set selection changes to form values
  // If you want to intercept selection change and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,

  // Might depend on other fields
  ...whenPropTypes,
};

export function FormDateTimeRangePicker(props) {
  // Control field reset when dependecies are not true
  const {
    [forwardRefSymbol]: ref,
    onChange,
    options,
    form,
    field,
    type,
    ...dateTimeRangePickerProps
  } = useWhen(props.form, props.field, props);
  const {name, value} = field;

  dateTimeRangePickerProps.ref = ref;
  dateTimeRangePickerProps.options = options;
  dateTimeRangePickerProps.name = name;
  dateTimeRangePickerProps.initialValue = value;

  dateTimeRangePickerProps.onChange = newValue => {
    const values = _.get(form.values, name);
    const unchanged = areOptionsEqual(values, newValue);

    if (!unchanged) {
      form.setFieldValue(name, newValue);

      if (!form.touched[name]) {
        form.setFieldTouched(name, true);
      }
    }
  };

  return <DateTimeRangePicker {...dateTimeRangePickerProps} />;
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(props => <Field {...props} component={FormDateTimeRangePicker} />, {
  hoistSource: FormDateTimeRangePicker,
});
