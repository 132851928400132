/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName, getRouteParams} from 'containers/App/AppState';
import {getAllUsersMap, isUserReadOnlyClusterInsensitive, isSuperclusterMember} from 'containers/User/UserState';
import {isLocalPce} from 'containers/App/AppUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getContainerClusterDetail} from '../../ContainerClusterDetailState';
import {getNamespaceTerm} from 'containers/ContainerCluster/ContainerClusterUtils';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {
  getDisplayNames,
  getLabelSelectorOptions,
  getLabelSelectorOptionsMap,
} from 'containers/Label/LabelSettings/LabelSettingState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './ContainerClusterContainerWorkloadProfileDetailConfig';
import {gridSettings as gridEditSettings} from './ContainerClusterContainerWorkloadProfileDetailEditConfig';
import _ from 'lodash';
import {Pill} from 'components';
import {getContainerClusterContainerWorkloadProfileDefault} from 'containers/ContainerCluster/Detail/Profile/List/ContainerClusterContainerWorkloadProfileListState';

export default {
  containerWorkloadProfileDetail(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_WORKLOAD_PROFILE_GET_DETAIL':
        return action.data.containerWorkloadProfileDetail;
      default:
        return state;
    }
  },
};

export const getContainerWorkloadProfileDetail = state => state.containerClusters.containerWorkloadProfileDetail;

const ANY_CONTAINER_LABEL_ACCEPTED = {labels: []};

export const getLabelRows = createSelector(
  [getContainerWorkloadProfileDetail, getContainerClusterContainerWorkloadProfileDefault, getRouteName],
  (detail, defaultProfile = {mode: 'unmanaged'}, routeName) => {
    const isDefault = routeName.endsWith('defaultedit') || routeName.endsWith('default');
    const isEdit = routeName.endsWith('edit') || routeName.endsWith('create');
    const labelArray = isDefault ? defaultProfile : detail;

    return labelArray
      ? labelArray.labels?.map(label => {
          return {
            key: label.key,
            selectable: true,
            data: {
              ...label,
              multi: label.restriction ?? [],
              single: isEdit && label.assignment ? [label.assignment] : label.assignment,
              nolabel: label.restriction?.length === 0,
              optionValue: label.restriction?.length ? 'multi' : label.assignment ? 'single' : 'noLabel',
            },
          };
        })
      : [];
  },
);

export const getGridSettings = createSelector(
  [getRouteName, gridSettings, gridEditSettings],
  (routeName, gridSettings, gridEditSettings) => {
    const isEdit = routeName.endsWith('edit') || routeName.endsWith('create');
    const clonedGrid = _.cloneDeep(isEdit ? gridEditSettings : gridSettings);
    const columns = {...clonedGrid.columns};

    return {...clonedGrid, columns};
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getLabelRows,
  });

export const getContainerWorkloadProfileDetailPage = createSelector(
  [
    getContainerClusterDetail,
    getContainerWorkloadProfileDetail,
    getRouteParams,
    getAllUsersMap,
    isSuperclusterMember,
    isUserReadOnlyClusterInsensitive,
    getClusters,
    getLocalFQDN,
    getDisplayNames,
    getGrid,
    getLabelRows,
    getLabelSelectorOptions,
    getGridSettings,
    getLabelSelectorOptionsMap,
    getRouteName,
    getRouteParams,
    getContainerClusterContainerWorkloadProfileDefault,
  ],
  (
    clusterDetail,
    detail,
    {id, profileId, pairingKey},
    usersMap,
    isMember,
    userIsReadOnlyClusterInsensitive,
    clusters,
    local,
    labelTypesNameObj,
    grid,
    initialRows,
    labelSelectorOptions,
    gridSettings,
    typeMap,
    routeName,
    routeParams,
    defaultProfile = {mode: 'unmanaged'},
  ) => {
    const isDefault = routeName.endsWith('defaultedit') || routeName.endsWith('default');
    const isBulkLabelEdit = routeName.endsWith('labelsedit');

    const {created_by, updated_by} = (isDefault ? defaultProfile : detail) || ANY_CONTAINER_LABEL_ACCEPTED; // defaultProfile for `/create` case

    const labelStatics = [];

    initialRows?.forEach(row => {
      if (row.data.multi.length !== 0) {
        row.data.multi.forEach(multi => {
          labelStatics.push({...multi, key: row.key});
          multi.key = row.key;
        });
      }

      if (row.data.single) {
        labelStatics.push({...row.data.single, key: row.key});

        if (Array.isArray(row.data.single)) {
          row.data.single[0].key = row.key;
        }
      }
    });

    const categories = [
      {
        id: 'labelTypes',
        name: 'Type',
        resources: {
          labelTypes: {
            statics: initialRows?.map(row => ({id: row.key, value: typeMap.get(row.key)})),
            optionProps: {
              allowMultipleSelection: false,
            },
          },
        },
      },
      {
        id: 'labels',
        name: 'Labels',
        resources: {
          labels: {
            statics: labelStatics,
            optionProps: {
              format: ({option}) => (
                <Pill.Label type={option.key} noContextualMenu>
                  {option.value}
                </Pill.Label>
              ),
              allowMultipleSelection: false,
              tooltipProps: option => ({content: labelTypesNameObj[option.key]}),
            },
            selectedProps: {
              formatValue: ({value, onRemove, disabled}) => {
                return (
                  <Pill.Label type={value.key} onClose={disabled ? undefined : onRemove} noContextualMenu>
                    {value.value}
                  </Pill.Label>
                );
              },
            },
          },
        },
      },
      {
        id: 'noAllow',
        displayResourceAsCategory: true,
        resources: {
          noAllow: {
            statics: ['No Labels Allowed'],
            noEmptyBanner: true,
            //Todo discuss this
            optionProps: {
              isPill: true,
            },
            selectedProps: {hideResourceName: true},
          },
        },
      },
    ];

    const {profilesList} = routeParams;

    return {
      isLocalPce: isLocalPce({clusters, local, pceFqdn: clusterDetail.pce_fqdn}),
      allowCreateTypes: isMember ? [] : ['labels'],
      clusterName: clusterDetail.name,
      namespaceTerm: getNamespaceTerm(clusterDetail),
      ...(isDefault ? defaultProfile : detail),
      id,
      profileId,
      pairingKey,
      userIsReadOnlyClusterInsensitive,
      created_by_user: fillUserInfo(usersMap, created_by),
      updated_by_user: fillUserInfo(usersMap, updated_by),
      labelTypesNameObj,
      grid,
      initialRows,
      labelSelectorOptions,
      gridSettings,
      categories,
      isDefault,
      isBulkLabelEdit,
      defaultProfile,
      typeMap,
      profilesList,
    };
  },
);
