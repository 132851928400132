/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import type {IconName} from 'components';

const protectionSchemaIconMapping = new Map<string, IconName>([
  ['AD-Domain-Services', 'ad'],
  ['ADFS-Federation', 'ad-federation'],
  ['AD-Certificate', 'ad-certificate'],
  ['ADLDS', 'ad-lightweight-directory'],
  ['ADRMS', 'ad-rights-management'],
  ['Hyper-V', 'server'],
  ['FileAndStorage-Services', 'denylist'],
  ['UpdateServices', 'provision'],
  ['WDS', 'capslock'],
  ['Remote-Desktop-Services', 'remote-desktop'],
  ['RemoteAccess', 'cloud-workload'],
  ['Print-Services', 'copy'],
  ['None', 'cancel'],
]);

export const getProtectionSchemaIcon = (serverRole = ''): IconName => {
  return protectionSchemaIconMapping.get(serverRole) ?? 'lock';
};
