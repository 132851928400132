/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, getCategories} from './ScopeListConfig';
import {getOrgId} from 'containers/User/UserState';
import {getLabelObjects, getScopeIdStringFromScope} from 'containers/RBAC/RBACUtils';
import {isAPIAvailable} from 'api/apiUtils';

import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';

export const getFilterMap = createSelector([getCategories], categories => getAllResourcesObject(categories));

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'SCOPE_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  count(state = {}, action) {
    switch (action.type) {
      case 'SCOPE_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getScopes = state => state.permissions.scope.list;
export const getScopesCount = state => state.permissions.scope.count;
export const getScopeRows = createSelector(getScopes, scopes =>
  scopes.map(({scope, ...rest}) => {
    const isAllowed = isAPIAvailable('org_permission.delete');
    const labelObjects = getLabelObjects(scope);

    return {
      key: getScopeIdStringFromScope(labelObjects),
      selectable: isAllowed,
      removable: isAllowed,
      scope: labelObjects,
      data: {
        labelsMap: getMappedLabelsForGrid(labelObjects),
        ...rest,
      },
    };
  }),
);

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getScopeRows,
    filterMap: getFilterMap,
  });

export const getScopeListPage = createSelector(
  [getGrid, getScopesCount, getOrgId, getCategories],
  (grid, count, orgId, categories) => {
    return {count, grid, orgId, categories};
  },
);
