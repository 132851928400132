/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import {Pill} from 'components';
import styles from './DateTimeCustomPicker.css';
import cx from 'classnames';

/*
 *  This is a component which looks like a selector but just holds a value
 *  It is used in the DateTimeCustomPicker for the from and to fields
 */
export default class DateTimeCustomPickerInput extends PureComponent {
  static propTypes = {
    focused: PropTypes.bool, // whether or not the input is focused or not (used for blue border styling)
    type: PropTypes.oneOf(['from', 'to']),
    value: PropTypes.instanceOf(Date), // current value for the input
    placeholder: PropTypes.string, // string to display when there is no value
    formatValue: PropTypes.func, // fn to format the value (date) to a string
    setActive: PropTypes.func,
    removeDateTimeValue: PropTypes.func, // fn to be executed when value is removed from the input. This fn accepts a type argument.
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleRemoveValue = this.handleRemoveValue.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleClick() {
    this.props.setActive(this.props.type);
  }

  handleRemoveValue() {
    this.props.removeDateTimeValue(this.props.type);
  }

  handleKeyDown(evt) {
    const {type, setActive, removeDateTimeValue} = this.props;

    if (evt.key === 'Backspace') {
      setActive(type);
      removeDateTimeValue(type);
    } else if (evt.key === 'Enter') {
      setActive(type);
    }
  }

  render() {
    const {placeholder, focused, value, formatValue} = this.props;

    const selectorStyle = cx(styles.selector, {
      [styles.focused]: focused,
    });

    return (
      <div className={selectorStyle} onClick={this.handleClick} onKeyDown={this.handleKeyDown} tabIndex={0}>
        <div className={styles.item} data-tid="comp-combobox-selected-value">
          {value ? <Pill.Label onClose={this.handleRemoveValue}>{formatValue(value)}</Pill.Label> : placeholder}
        </div>
      </div>
    );
  }
}
