/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';

export const calcAppGroupName = labels =>
  labels
    .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
    .map(label => label.value)
    .join(' | ');

export const calcAppGroupMemberCount = appGroup => {
  return (
    (appGroup?.num_workloads || 0) +
    (appGroup?.num_virtual_services || 0) +
    (appGroup?.num_virtual_servers || 0) +
    (appGroup?.num_container_workloads || 0)
  );
};

const getEnforcementString = type => {
  const policyStates = {
    enforced: intl('Workloads.Full'),
    idle: intl('Common.Idle'),
    mixed: intl('Policy.Mixed'),
    selective: intl('Workloads.Selective'),
    unknown: intl('Common.Unknown'),
    unmanaged: intl('Common.Unmanaged'),
    visibility: intl('Common.VisibilityOnly'),
  };

  return policyStates[type];
};

export const calcAppGroupEnforcement = (appGroup, modeKeys) => {
  const {mode = [], container_workload_mode = []} = appGroup || {};

  const enforcedMode = mode.reduce((enforced, m, i) => {
    enforced[i] = m + container_workload_mode[i];

    return enforced;
  }, []);

  const enforcementDetails = (modeKeys || []).reduce(
    (result, key, index) => ({...result, [key]: enforcedMode[index]}),
    {},
  );

  // Filter enforcement modes that are not 'unmanaged' and non-zero values.
  const nonUnmanagedEnforcements = Object.keys(enforcementDetails).filter(
    key => key !== 'unmanaged' && enforcementDetails[key] > 0,
  );

  let enforcement;

  // If there are any enforcements other than 'unmanaged'
  if (nonUnmanagedEnforcements.length) {
    enforcement = nonUnmanagedEnforcements.length > 1 ? 'mixed' : nonUnmanagedEnforcements[0];
  } else if (enforcementDetails.unmanaged > 0) {
    // unmanaged enforcement type with a non-zero value.
    enforcement = 'unmanaged';
  }

  return getEnforcementString(enforcement);
};

/**
 * Parses app group nodes and assigns the appropriate labels to each one
 * @param nodes - array of app groups
 * @param labels - labels object
 * @returns [] - array of app group nodes
 */
export const parseAppGroupNodes = (nodes, labels) => {
  return (nodes ?? []).map(node => {
    return {
      ...node,
      labels: node.label_ids.map(id => ({
        ...labels[id].label,
        id,
      })),
    };
  });
};

/**
 * parses the app group rule coverage API response; converts the array of rule coverage objects into an object that maps
 * app group id (href) to a coverage object; drops any falsy items from the coverage array;
 * @param coverage - array of rule coverage objects
 * @return {[id: string]: coverageObject}
 */
export const parseAppGroupRuleCoverage = (coverage = []) =>
  coverage.reduce((result, item) => {
    if (item) {
      result[item.href] = item;
    }

    return result;
  }, {});

/**
 * returns two arrays (idsWithCoverage and idsWithoutCoverage); The idsWithCoverage array contains the ids of the app
 * groups that the server returned coverage for; The idsWithoutCoverage array contains the ids of the app groups that
 * the server did not return coverage for.
 * @param ids - array of app group ids sent to the observed rule coverage bulk API
 * @param coverage - object with app group ids as keys, and coverage objects returned from the bulk API as values.
 * @return {idsWithCoverage: string[], idsWithoutCoverage: string[]} - arrays of app group ids with and without coverage
 */
export const getCoverageStatusForAppGroupIds = (ids, coverage) => {
  const idsWithCoverage = new Set(Object.values(coverage ?? {}).map(item => item.href));
  const idsWithoutCoverage = ids.filter(id => !idsWithCoverage.has(id));

  return {idsWithCoverage: [...idsWithCoverage], idsWithoutCoverage};
};

/**
 * accepts the current coverage redux state, and the coverage object returned from the API;
 * returns the updated state and ensures that the items with the most recent updated_at are kept;
 * @param state - current redux state
 * @param coverage - parsed coverage object returned from API
 * @return {[p: string]: coverageObjecț} - updated redux state with latest coverage
 */
export const mergeLatestCoverageWithState = (state, coverage) => ({
  ...state,
  ...Object.fromEntries(
    Object.entries(coverage ?? {}).filter(([id, item]) => {
      return (
        item?.updated_at &&
        (!state[id] || new Date(state[id].updated_at).getTime() <= new Date(item.updated_at).getTime())
      );
    }),
  ),
});
