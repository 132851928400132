/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserScoped} from 'containers/User/UserState';
import listReducers from './List/NetworkListState';
import itemReducers from './Item/NetworkItemState';

export default {
  networks: combineReducers({
    ...listReducers,
    ...itemReducers,
  }),
};

export const isNetworkEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
