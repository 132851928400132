/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import libraryReducers from './Library/VenLibraryState';
import itemReducers from './Item/VenItemState';
import listReducer from './List/VenListState';
import upgradeReducer from './Upgrade/VenUpgradeState';
import {createSelector} from 'reselect';
import {isUserScoped, isUserWorkloadManager} from 'containers/User/UserState';

export default {
  ven: combineReducers({
    ...libraryReducers,
    ...itemReducers,
    ...listReducer,
    ...upgradeReducer,
  }),
};

export const isVenLibraryEnabled = createSelector(
  [isUserScoped, isUserWorkloadManager],
  (userIsScoped, userIsWorkloadManager) => !userIsScoped || userIsWorkloadManager,
);
