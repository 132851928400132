/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from './intl';
import {generalUtils} from '@illumio-shared/utils/shared';
import {createSelector} from 'reselect';

/**
 * Port objects
 */
export interface PortObjects<T = number> {
  port: T;
  to_port: number;
  proto: number;
  process_name: string;
  service_name: string;

  protocol?: string | {[port: string]: string};
  protocolNum?: number;
}

/**
 * ICMP code and type. Make IcmpObjects a subtype of PortObjects
 */
export interface IcmpObjects<T = number> extends PortObjects<T> {
  icmp_code: number | string;
  icmp_type: number | string;
}

/**
 * Port Ranges. Make PortRanges a subtypes of PortObjects
 *
 * @example
 * e.g. protocol = {
 *        6: intl('Protocol.TCP'),
 *        17: intl('Protocol.UDP'),
 * }
 */
export interface PortRanges<T = number> extends PortObjects<T> {
  protocol: {[port: string]: string};
}

/** Nominal Types */
export type ValidIcmpTypeCode = IcmpObjects['icmp_type'] & {_brand: 'icmp_type'};
export type ValidPort = PortObjects<number | string>['port'] & {_brand: 'valid_port'};
export type PortRangesEqual = IcmpObjects & {_brand: 'port_ranges_equal'};
export type PortRangesOverlapping = PortRanges & {_brand: 'port_ranges_overlap'};

// 1 => 00001; 123 => 00123
export const stringifyPortForSort = (port: number): string => String(port).padStart(5, '0');

//  1- icmp
// 58 - icmpv6
export const getICMPProtocols = (): number[] => [1, 58];

export const ProtocolMap: Record<string, string> = {
  '-1': intl('Protocol.Any'),
  '1': intl('Protocol.ICMP'),
  '2': intl('Protocol.IGMP'),
  '4': intl('Protocol.IPv4'),
  '6': intl('Protocol.TCP'),
  '17': intl('Protocol.UDP'),
  '27': intl('Protocol.RDP'),
  '41': intl('Protocol.IPv6'),
  '47': intl('Protocol.GRE'),
  '50': intl('Protocol.ESP'),
  '58': intl('Protocol.ICMPv6'),
  '62': intl('Protocol.CFTP'),
  '64': intl('Protocol.SATEXPAK'),
  '65': intl('Protocol.KRYPTOLAN'),
  '66': intl('Protocol.RVD'),
  '67': intl('Protocol.IPPC'),
  '94': intl('Protocol.IPIP'),
  '121': intl('Protocol.SMP'),
};

// Internet Control Message Protocol version 6 is icmpv6. Retired icmp6.
export const ReverseProtocolMap: Record<string, number> = Object.keys(ProtocolMap).reduce(
  (result, key) => ({
    ...result,
    [ProtocolMap[key].toUpperCase()]: Number(key),
  }),
  {},
);

export function isPortValidForProtocol(protocol: number | string, port: number | string | undefined): boolean {
  const validPortProtocols = [-1, 6, 17, 'any', 'TCP', 'UDP'];

  if (typeof port === 'string') {
    return Boolean(port);
  }

  return !_.isNil(port) && !isNaN(port) && (validPortProtocols.includes(protocol) || Boolean(port));
}

export const reverseLookupProtocol = (protocol: number | string): number | string => {
  if (!protocol || protocol === intl('Protocol.Any')) {
    return -1;
  }

  if (_.isNumber(protocol)) {
    return protocol;
  }

  return ReverseProtocolMap[protocol.toUpperCase()] || Number(protocol);
};

/**
 * return protocol name by id, if not found return number instead
 * @param protocol
 */

export const lookupProtocol = (inputProtocol: number | string): string => {
  const protocol = reverseLookupProtocol(inputProtocol);

  if (_.isNaN(protocol)) {
    return inputProtocol.toString().toUpperCase();
  }

  return ProtocolMap[protocol];
};

export const stringifyPortObjectSort = (value: PortObjects): string =>
  [
    value.port >= 0 && stringifyPortForSort(value.port),
    value.to_port && `- ${stringifyPortForSort(value.to_port)}`,
    value.proto && value.proto !== -1 && lookupProtocol(value.proto),
    value.process_name,
    value.service_name,
  ]
    .filter(item => item)
    .join(' ');

/**
 *  Check for valid port
 *
 * @param port Port can be a string or number
 * @param min
 * @returns
 */
export const isValidPort = (port: PortObjects<number | string>['port'], min = 0): port is ValidPort => {
  if (_.isNil(port)) {
    return false;
  }

  return generalUtils.isValidNumber(port, min, 65_535);
};

export const isValidIcmpTypeCode = (type: IcmpObjects['icmp_type']): type is ValidIcmpTypeCode =>
  generalUtils.isValidNumber(type, 0, 255);

export const portProtocolRegex = {
  tcpPortProtocol: /\d+\s+(t|tc|tcp)$/i,
  udpPortProtocol: /\d+\s+(u|ud|udp)$/i,
  icmpPortProtocol: /\d+\s+(i|ic|icm|icmp)$/i,
  icmpv6PortProtocol: /\d+\s+(icmpv|icmpv6)$/i,
};

export const portProtocolMap = createSelector([], () => ({
  tcp: {
    text: intl('Protocol.TCP'),
    value: 6,
  },
  udp: {
    text: intl('Protocol.UDP'),
    value: 17,
  },
}));

export const arePortRangesOverlapping = (
  oldRange?: PortRanges,
  newRange?: PortRanges,
): oldRange is PortRangesOverlapping => {
  if (!oldRange || !newRange) {
    return false;
  }

  if (oldRange.protocol !== newRange.protocol) {
    return false;
  }

  const oldService = oldRange.service_name || null;
  const newService = newRange.service_name || null;

  if (oldService !== newService) {
    return false;
  }

  const oldProcess = oldRange.process_name || null;
  const newProcess = newRange.process_name || null;

  if (oldProcess !== newProcess) {
    return false;
  }

  if (
    !oldRange.protocol &&
    !oldService &&
    !oldProcess &&
    _.isNil(oldRange.port) &&
    _.isNil(oldRange.to_port) &&
    !oldRange.process_name &&
    !oldRange.service_name
  ) {
    return false;
  }

  if (oldRange.port === -1 || newRange.port === -1) {
    //-1 means all, so if the protocol, process, and service match its overlapping
    return true;
  }

  if (oldRange.port === undefined && newRange.port === undefined) {
    //if no port defined, then overlapping process name
    return true;
  }

  if (_.isNil(oldRange.to_port) && _.isNil(newRange.to_port)) {
    return oldRange.port === newRange.port;
  }

  if (_.isNil(oldRange.to_port)) {
    return oldRange.port >= newRange.port && oldRange.port <= newRange.to_port;
  }

  if (_.isNil(newRange.to_port)) {
    return newRange.port >= oldRange.port && newRange.port <= oldRange.to_port;
  }

  return oldRange.port <= newRange.to_port && newRange.port <= oldRange.to_port;
};

export const arePortRangesEqual = (oldRange?: IcmpObjects, newRange?: IcmpObjects): oldRange is PortRangesEqual => {
  if (!oldRange && !newRange) {
    return true;
  }

  if (!oldRange || !newRange) {
    return false;
  }

  const compactOldRange = {...oldRange};
  const compactNewRange = {...newRange};

  let kOld: keyof IcmpObjects;

  for (kOld in compactOldRange) {
    if (!compactOldRange[kOld]) {
      delete compactOldRange[kOld];
    }
  }

  let kNew: keyof IcmpObjects;

  for (kNew in compactNewRange) {
    if (!compactNewRange[kNew]) {
      delete compactNewRange[kNew];
    }
  }

  const keysToCompare = ['port', 'to_port', 'icmp_type', 'icmp_code', 'proto', 'service_name', 'process_name'] as const;

  let keyTo: (typeof keysToCompare)[number];

  for (keyTo of keysToCompare) {
    let oldValue = compactOldRange[keyTo];
    let newValue = compactNewRange[keyTo];

    if (keyTo === 'port') {
      // If no port value is present, default all ports, i.e. -1.
      oldValue ||= -1;
      newValue ||= -1;
    }

    if (oldValue !== newValue) {
      return false;
    }
  }

  return true;
};

export const portRegex = '(?::+(\\d+))$';

export const protos = createSelector([], () => ({
  6: intl('Protocol.TCP'),
  17: intl('Protocol.UDP'),
}));

export const portProtocol = (proto: PortObjects['proto']): string | undefined => {
  const protocol = new Map([
    [6, intl('Protocol.TCP')],
    [17, intl('Protocol.UDP')],
  ]);

  return protocol.get(proto);
};
