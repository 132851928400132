/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserScoped, isUserWorkloadManager} from 'containers/User/UserState';
import listReducers from './List/PairingProfileListState';
import instanceReducers from './Item/PairingProfileItemState';

export default {
  pairingprofiles: combineReducers({
    ...listReducers,
    ...instanceReducers,
  }),
};

export const isPairingProfilesEnabled = createSelector(
  [isUserScoped, isUserWorkloadManager],
  (userIsScoped, userIsWorkloadManager) => !userIsScoped || userIsWorkloadManager,
);
