/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {Fragment, Component, createRef, createElement} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {Icon, TypedMessages} from 'components';
import {tidUtils} from '@illumio-shared/utils';
import styles from './Input.css';

export default class Input extends Component {
  static propTypes = {
    // By default we set autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" attributes to dom element
    // You can override any of those attributes
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // Whether input should have border and  error message
    error: PropTypes.bool,
    success: PropTypes.bool,
    warn: PropTypes.bool,
    errorMessage: PropTypes.node,
    hideErrorMessage: PropTypes.bool,
    tid: PropTypes.string.isRequired,
    // text, password, etc.
    type: PropTypes.string,
    onChange: PropTypes.func,
    // Validated field message
    validatedMessage: PropTypes.string,
    // Icon properties
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // subtext for help info
    subText: PropTypes.any,
    // themr object
    theme: PropTypes.object,
    noWrap: PropTypes.bool, // Do not apply wrapper 'div'
    readOnly: PropTypes.bool,
    displayMessages: PropTypes.bool,
  };

  static defaultProps = {
    type: 'text',
    noWrap: false,
  };

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.input = createRef();
    this.inputEvent = new Event('input', {bubbles: true});
    this.blurEvent = new Event('blur');

    this.clear = this.clear.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.onChange || nextProps.readOnly) {
      return {value: nextProps.value};
    }

    return null;
  }

  componentDidMount() {
    Object.defineProperty(this.input.current, 'clear', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: this.clear,
    });
  }

  handleChange(evt) {
    if (this.props.onChange) {
      this.props.onChange(evt);
    } else {
      this.setState({value: evt.target.value});
    }
  }

  /**
   * QA helper to clear input fields. Otherwise, these fields get re-populated sporadically during re-renders.
   *
   * A programatic event is used here in order to trigger any parent handlers that may update value such as a form.
   */
  clear() {
    // Get setter
    const setValue = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this.input.current), 'value').set;

    // Clear field
    setValue.call(this.input.current, '');
    // Triggers input event and blur to indicate the input has been touched
    this.input.current.dispatchEvent(this.inputEvent);
    this.input.current.dispatchEvent(this.blurEvent);
  }

  render() {
    const {
      tid,
      error,
      errorMessage,
      hideErrorMessage,
      validatedMessage,
      warningMessage,
      icon,
      noWrap,
      subText,
      theme,
      success,
      warn,
      displayMessages = true,
      ...inputProps
    } = mixThemeWithProps(styles, this.props);

    const {value} = this.state;

    let iconElement;

    if (icon) {
      if (typeof icon === 'string') {
        iconElement = <Icon key="icon" name={icon} theme={theme} themePrefix="icon-" />;
      } else if (typeof icon === 'object') {
        iconElement = <Icon key="icon" theme={theme} themePrefix="icon-" {...icon} />;
      }
    }

    return createElement(
      noWrap ? Fragment : 'div',
      noWrap ? null : {className: theme.wrapper},
      iconElement,
      <input
        key="input"
        // Default attributes
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        // Attributes specified by parent
        {...inputProps}
        // Local state attributes
        value={value}
        ref={this.input}
        onChange={this.handleChange}
        data-tid={tidUtils.getTid('comp-field-input', tid)}
        className={cx(theme.input, {
          [theme.withIcon]: icon,
          [theme.error]: error,
          [theme.success]: success || validatedMessage,
          [theme.warn]: warn,
        })}
      />,
      // Render TypedMessages unconditionally, but its children conditionally to make their animation work for show/hide
      displayMessages ? (
        <TypedMessages key="status" gap="gapXSmall" theme={theme}>
          {[
            !hideErrorMessage && error && errorMessage
              ? {
                  content: errorMessage,
                  color: 'error',
                  fontSize: 'var(--12px)',
                  tid: tidUtils.getTid('comp-field-error', tid),
                }
              : null,
            !error && validatedMessage
              ? {
                  content: validatedMessage,
                  color: 'success',
                  fontSize: 'var(--12px)',
                  tid: tidUtils.getTid('comp-field-error', tid),
                }
              : null,
            !error && warningMessage
              ? {
                  content: warningMessage,
                  color: 'warning',
                  fontSize: 'var(--12px)',
                  tid: tidUtils.getTid('comp-field-error', tid),
                }
              : null,
            subText ? {content: subText, color: 'gray', fontSize: 'var(--12px)'} : null,
          ]}
        </TypedMessages>
      ) : null,
    );
  }
}
