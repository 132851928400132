/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getRouteParams} from 'containers/App/AppState';
import {getVensHrefMap} from 'containers/Ven/List/VenListState';
import {gridSettings, categories} from './EventsListConfig';
import {isUserOwner, getAllUsers, getAllUsersMap} from 'containers/User/UserState';
import {eventUtils} from 'utils';
import {webStorageUtils} from '@illumio-shared/utils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {produce} from 'immer';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

let cachedFilterPanel = {};
const menu = {
  [intl('Events.ByEvent')]: [],
  [intl('Events.BySeverity')]: [
    intl('Common.Error'),
    intl('Common.Warning'),
    intl('SystemSettings.LogForwarding.Severity.Info'),
  ],
  [intl('Events.ByTimestamp')]: [],
  [intl('Events.ByGenerated')]: [],
};
// subcategories that always appear  are added here, even with a count of 0
const items = {
  [intl('Common.Error')]: {count: 0, key: 'severity'},
  [intl('Common.Warning')]: {count: 0, key: 'severity'},
  [intl('SystemSettings.LogForwarding.Severity.Info')]: {count: 0, key: 'severity'},
};

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'EVENTS_GET_LIST':
        cachedFilterPanel = {};

        return action.data.list;
      default:
        return state;
    }
  },
  count(state = {}, action) {
    switch (action.type) {
      case 'EVENTS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
  filter(state = {}, action) {
    switch (action.type) {
      case 'EVENTS_FILTER_PANEL_SET':
        webStorageUtils.setSessionItem('filterPanel', action.data);

        return action.data;
      default:
        return state;
    }
  },
};

export const getEventsList = state => state.events.list;
export const getEventsCount = state => state.events.count;
export const getEventsFilter = state => state.events.filter;
export const getAgents = state => getVensHrefMap(state, true);
export const getVens = state => getVensHrefMap(state, false);

export const getIsEventsResultShowing = createSelector([getRouteParams], params => params.section === 'list');
const getResultedEventsList = createSelector([getIsEventsResultShowing, getEventsList], (showing, list) =>
  showing ? list : [],
);
const getResultedEventsCount = createSelector([getIsEventsResultShowing, getEventsCount], (showing, count) =>
  showing ? count : {matched: 0, total: 0},
);

const getEventsRows = createSelector(
  [getResultedEventsList, getEventsFilter, isUserOwner, getAgents, getVens, getAllUsersMap],
  (events, filter, userIsOwner, agents, vens, usersMap) => {
    let eventsData = events;
    let filterItemExists = false; // check if applied filter is still applicable in the new data set

    if (!_.isEmpty(filter)) {
      eventsData = events.filter(event => {
        let parsedValue = event[filter.key];

        if (filter.key === 'timestamp') {
          parsedValue = eventUtils.getFilterFriendlyTimestamp(parsedValue);
        } else if (filter.key === 'created_by') {
          parsedValue = eventUtils.getEventGeneratedBy(parsedValue);
        }

        const rowMatchesFilter = filter.value === parsedValue;

        if (rowMatchesFilter) {
          filterItemExists = true;
        }

        return rowMatchesFilter;
      });
    }

    const {subcategory = '', category = ''} = filter;

    // determine if selected filter should come from saved filter object based on if filter is relevant
    // filter is relevant if filter is found in data set, or if filter item always shows up regardless of count
    const selected = filterItemExists || items[subcategory] ? category + subcategory : null;

    // if filter doesn't apply to current events list and the selected item is now all events, return all events
    if (!filterItemExists && !selected) {
      eventsData = events;
    }

    const rows = eventsData.map(item => ({
      key: item.href,
      selectable: userIsOwner,
      removable: userIsOwner,
      data: {
        ...item,
        hostname:
          item.created_by?.ven?.hostname ??
          item.created_by?.agent?.hostname ??
          vens[item.created_by?.ven?.href]?.hostname ??
          agents[item.created_by?.agent?.href]?.hostname,
        created_by: produce(item.created_by, draft => {
          if (draft?.user) {
            draft.user = fillUserInfo(usersMap, draft.user);
          }

          if (draft?.service_account) {
            draft.service_account = fillUserInfo(usersMap, draft.service_account);
          }
        }),
      },
    }));

    return {rows, filterItemExists, selected};
  },
);

export const getEventUserMap = createSelector(getAllUsers, users => {
  const userMap = users.reduce((result, {href, username}) => {
    result[username] = href;

    return result;
  }, {});

  userMap[intl('Common.System')] = 'system';
  userMap.anonymous = '/users/-1';

  return userMap;
});

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

export const getFilterPanelValues = createSelector(
  [getResultedEventsList, getEventsFilter, getEventsRows, getRouteParams],
  (events, filter, {selected}, routeParams) => {
    if (!_.isEmpty(filter) && !_.isEmpty(cachedFilterPanel)) {
      // use the old item counts generated from the unfiltered data set
      return {...cachedFilterPanel, selected, show: routeParams.showQuickFilter};
    }

    const menuItems = _.cloneDeep(menu);
    const itemCounts = _.cloneDeep(items);

    events.forEach(data => {
      const event = data.event_type;
      const severity = eventUtils.getEventSeverity(data.severity);
      const creator = eventUtils.getEventGeneratedBy(data.created_by);
      const timestamp = eventUtils.getFilterFriendlyTimestamp(data.timestamp);

      // need to add raw key and value in itemCounts so the grid knows how to filter out rows (see getEventsRows)
      if (!itemCounts[severity] || itemCounts[severity].count === 0) {
        itemCounts[severity] = {count: 0, value: data.severity, key: 'severity'};
      }

      if (!itemCounts[event]) {
        itemCounts[event] = {count: 0, value: data.event_type, key: 'event_type'};
        menuItems[intl('Events.ByEvent')].push(event);
      }

      if (!itemCounts[creator]) {
        itemCounts[creator] = {count: 0, value: creator, key: 'created_by'};
        menuItems[intl('Events.ByGenerated')].push(creator);
      }

      if (!itemCounts[timestamp]) {
        itemCounts[timestamp] = {count: 0, value: timestamp, key: 'timestamp'};
        menuItems[intl('Events.ByTimestamp')].push(timestamp);
      }

      [severity, event, creator, timestamp].forEach(val => {
        // Check count property is numeric before increment
        if (itemCounts[val] && typeof itemCounts[val].count === 'number') {
          itemCounts[val].count++;
        }
      });
    });

    menuItems[intl('Events.ByEvent')].sort((a, b) => itemCounts[b] - itemCounts[a]);
    menuItems[intl('Events.ByGenerated')].sort((a, b) => itemCounts[b] - itemCounts[a]);
    menuItems[intl('Events.ByTimestamp')].sort(
      (a, b) => eventUtils.getTimestampFromString(b) - eventUtils.getTimestampFromString(a),
    );

    cachedFilterPanel = {menuItems, itemCounts};

    return {menuItems, itemCounts, selected, show: routeParams.showQuickFilter};
  },
);

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: createSelector(getEventsRows, rows => rows.rows),
    filterMap: getFilterMap,
  });

export const getEventsListsPage = createSelector(
  [getGrid, getResultedEventsCount, getEventsRows, getFilterPanelValues, categories, getIsEventsResultShowing],
  (grid, count, eventsRows, filterPanel, categories, showingResult) => {
    let eventsCount = count;

    if (filterPanel.selected) {
      eventsCount = {...count, matched: eventsRows.rows.length};
    }

    return {
      grid,
      count: eventsCount,
      categories,
      filterPanel,
      showingResult,
    };
  },
);
