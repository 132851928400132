/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

export function addToPast(present, past, limit) {
  const truncatedPast = limit === undefined ? past : past.slice(Math.max(past.length - limit + 1, 0));

  return [...truncatedPast, present];
}

export function addToFuture(present, future, limit) {
  const truncatedFuture = limit === undefined ? future : future.slice(0, limit - 1);

  return [present, ...truncatedFuture];
}

export function actionTypeExistsInActionTypesArray(type, actionTypes) {
  return actionTypes.includes(type) ? type : undefined;
}

/**
 * Reducer enhancer that adds undo/redo functionality to the provided reducer. Based on the undoable reducer enhancer
 * example from https://redux.js.org/usage/implementing-undo-history#second-attempt-writing-a-reducer-enhancer.
 *
 * @param reducer {function} - reducer to add undo/redo functionality to
 * @param limit {number} - maximum number of items to store in history
 * @param undoActionType {string} - custom action type for undo actions
 * @param redoActionType {string} - custom action type for redo actions
 * @param clearHistoryActionTypes {array} - array of actions types that should clear past & future
 * @returns {(function({past: [], future: [], present: *}=, *): ({past: *[], future, present: *}))|*}
 */
export function undoable(reducer, rawConfig = {}) {
  const config = {
    limit: undefined,
    undoActionType: 'UNDO',
    redoActionType: 'REDO',
    clearHistoryActionTypes: ['CLEAR_HISTORY'],
    ...rawConfig,
  };
  const initialState = {
    past: [],
    present: reducer(undefined, {}),
    future: [],
  };

  return function (state = initialState, action) {
    const {past, present, future} = state;

    switch (action.type) {
      case undefined:
        return state;
      case config.undoActionType:
        if (past.length === 0) {
          return state;
        }

        const previous = past.at(-1);
        const newPast = past.slice(0, -1);

        return {
          past: newPast,
          present: previous,
          future: addToFuture(present, future, config.limit),
        };
      case config.redoActionType:
        if (future.length === 0) {
          return state;
        }

        const next = future[0];
        const newFuture = future.slice(1);

        return {
          past: addToPast(present, past, config.limit),
          present: next,
          future: newFuture,
        };
      case actionTypeExistsInActionTypesArray(action.type, config.clearHistoryActionTypes):
        // clear past & future
        return {
          past: [],
          present: reducer(present, action),
          future: [],
        };
      default:
        // delegate handling action to the passed reducer
        const newPresent = reducer(present, action);

        if (present !== newPresent) {
          return {
            past: addToPast(present, past, config.limit),
            present: newPresent,
            future: [],
          };
        }

        return state;
    }
  };
}
