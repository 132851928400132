/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {errorUtils, hrefUtils} from '@illumio-shared/utils';
import Schema, {type SchemaClass, type SchemaClassesKey, type SchemaMethod, type SchemaMethodsKey} from './schema';

const parameterRegex = /:\w+/g;

export interface APIMethod {
  key: string;
  className: string;
  methodName: string;
  path: string;
  params: string[];
  regexp: RegExp;
  match: (href: hrefUtils.Href) => boolean;
  matchParams: (href: hrefUtils.Href) => Record<string, string> | undefined;
  prettyAuthz: SchemaMethod['pretty_authz'];
  allowedOnNonleader: SchemaMethod['allowed_on_non_leader'];
  orgRequired: boolean;
  operationType: SchemaMethod['operation_type'];
  httpMethod: SchemaMethod['http_method'];
}

/**
 * Map of schema methods with path, params, and regexp.
 *
 * @example
 * [
 *   'workloads.get_collection': {
 *      key: 'workloads.get_collection', className: 'workloads', methodName: 'get_collection',
 *      isInstance: false, httpMethod: 'GET', operationType: 'read',
 *      params: ['xorg_id', 'workload_id'], orgRequired: true,
 *      path: '/orgs/:xorg_id/workloads/:workload_id',
 *      regexp: /^\/orgs\/(\w+)\/workloads\/(\w+)\/?$/},
 *   },
 *   'networks.get_instance': {
 *      key: 'networks.get_instance', className: 'networks', methodName: 'get_instance',
 *      isInstance: true, httpMethod: 'GET', operationType: 'read',
 *      params: ['xorg_id', 'id'], orgRequired: true,
 *      path: '/orgs/:xorg_id/networks/:id',
 *      regexp: /^\/orgs\/(\w+)\/networks\/(\w+)\/?$/},
 *   },
 *   ...
 * ]
 */
export const schemaMethodsMap = new Map<SchemaMethodsKey, APIMethod>();

type SchemaAutocompleteMethodsKey = `${SchemaClassesKey}.autocomplete`;

// The same map but only of methods with 'autocomplete'
export const schemaAutocompleteMethodsMap = new Map<SchemaAutocompleteMethodsKey, APIMethod>();

for (const [className, classContent] of Object.entries(Schema.classes).concat([['', {methods: Schema.methods}]]) as [
  string,
  SchemaClass,
][]) {
  for (const [methodName, method] of Object.entries(classContent.methods)) {
    const key = `${className}${className ? '.' : ''}${methodName}`;

    // Get path from schema method, for instance, '/orgs/:xorg_id/workloads/:workload_id'
    const {path, pretty_authz: prettyAuthz, allowed_on_non_leader: allowedOnNonleader} = method;
    // Get array of param names in that path, for example above: ['xorg_id', 'workload_id']
    const params = (path.match(parameterRegex) || []).map(param => param.substr(1));

    // Constract regular expression to match hrefs with that path
    // For example above: /^\/orgs\/([\w-]+)\/workloads\/([\w-]+)\/?$/
    const regexp = new RegExp(`^${path.replaceAll(parameterRegex, '([\\w-]+)')}\\/?$`);

    // Function to test if a given href matches the path
    const match = (href: hrefUtils.Href) => regexp.test(href); // eslint-disable-line unicorn/consistent-function-scoping -- https://github.com/sindresorhus/eslint-plugin-unicorn/issues/792
    // Function to get object of params value for a given href. Returns undefined if path doesn't match
    // For example above if href is '/orgs/3/workloads/123' result will be {xorg_id: 3, workload_id: 123}
    // eslint-disable-next-line unicorn/consistent-function-scoping -- https://github.com/sindresorhus/eslint-plugin-unicorn/issues/792
    const matchParams = (href: hrefUtils.Href) => {
      if (params.length) {
        const matchedParams = href.match(regexp);

        if (matchedParams) {
          return params.reduce((result: Record<string, string>, param, index) => {
            result[param] = matchedParams[index + 1];

            return result;
          }, {});
        }

        return;
      }

      if (match(href)) {
        return {};
      }
    };

    const isAutocompletable = classContent.collection && methodName === 'autocomplete';

    const result = {
      key,
      className,
      methodName,
      path,
      params,
      regexp,
      match,
      matchParams,
      prettyAuthz,
      allowedOnNonleader,
      orgRequired: params.includes('xorg_id'),
      operationType: method.operation_type,
      httpMethod: method.http_method,
    };

    schemaMethodsMap.set(key as SchemaMethodsKey, result);

    if (isAutocompletable) {
      schemaAutocompleteMethodsMap.set(className as SchemaAutocompleteMethodsKey, result);
    }
  }
}

/**
 * Returns object of {param:value} for a given href
 *
 * @param {String} href - Any valid href, like '/orgs/3/workloads/123'
 * @returns {Object}
 */
export function getHrefParams(href: hrefUtils.Href): Partial<Record<string, string>> {
  if (!href) {
    return {};
  }

  for (const method of schemaMethodsMap.values()) {
    const params = method.matchParams(href);

    if (params) {
      return params;
    }
  }

  return {};
}

/**
 * Convert xxxlabels into the special character query.
 * xxxlabels is a query parameter which consists of a list of labels, and need to have double-quotes around each URI
 * The default key is 'labels'
 * They are only used for a certain set of APIs, like the workloads.
 */
export function getXXXLabelsQueryParam(
  labelArray: string[][] = [],
  key = 'labels',
): Partial<Record<string, string>> | undefined {
  if (!labelArray.length) {
    return;
  }

  const result = labelArray.map(labels => `["${labels.join('","')}"]`);

  if (result.length === 1 && result[0] === '[""]') {
    result[0] = '[]';
  }

  // Result looks like :
  // 'labels=[["labelHref,"labelHref"],["labelHref"]]'
  return {
    [key]: `[${result.join(',')}]`,
  };
}

// export const getSchemaClass = (className: string): SchemaClass | undefined => Schema.classes[className];
// export const getInstanceMethod = (resource: string) => getSchemaClass(resource)?.get_instance;
// export const getCollectionMethod = (resource: string) => getSchemaClass(resource)?.get_collection;

/**
 * Returns uri by filling method's path with passed parameters.
 * Validate existense of method, type and number of passed parameters
 *
 * @example
 * getMethodParameterizedPath({method: 'workloads.get_collection', orgId: 1, params: {id: 7}}) => '/orgs/1/networks/7'
 * getMethodParameterizedPath({method: {key: 'workloads.get_collection', ...}, orgId: 1, params: {id: 7}}) => '/orgs/1/networks/7'
 *
 * @param {String|Object} method - Method's key or method object
 * @param {Number} orgId  - User's organization id
 * @param {Object} params - Object with parameters values
 *
 * @returns {String}
 */
export const getMethodParameterizedPath = ({
  method: methodOrKey,
  orgId,
  params = {},
}: {
  method: APIMethod | SchemaMethodsKey;
  orgId?: string;
  params?: Record<string, string>;
}): string => {
  // we know that schemaMethodsMap.get(methodOrKey) must exist because of the key constraint
  // so it's ok to use null assertion here to get rid of the undefined value
  const method = typeof methodOrKey === 'string' ? schemaMethodsMap.get(methodOrKey)! : methodOrKey;

  if (!method) {
    throw new errorUtils.APIError({
      code: 'BAD_PARAMS',
      statusCode: 400,
      message: `API method '${methodOrKey}' does not exist!`,
      trace: true,
    });
  }

  if (orgId && method.params.includes('xorg_id')) {
    params = {...params, xorg_id: orgId};
  }

  const uri = method.params.reduce((result, param) => {
    const value = params[param];

    if (__DEV__) {
      const type = typeof value;

      // As dev check, throw error if type of passed params is not string/number
      if (type === 'undefined' || value === null) {
        throw new errorUtils.APIError({
          code: 'BAD_PARAMS',
          statusCode: 400,
          trace: true,
          message: `Undefined param '${param}' for the path '${method.path}' of '${method.key}' schema method`,
        });
      }

      if (type !== 'string' && type !== 'number' && type !== 'bigint') {
        throw new errorUtils.APIError({
          code: 'BAD_PARAMS',
          statusCode: 400,
          trace: true,
          message: `Param '${param}' for the path '${method.path}' of '${method.key}' schema method should be a string/number, but value '${value}' with type '${type}' was passed`,
        });
      }
    }

    return result.replace(`:${param}`, value);
  }, method.path);

  if (__DEV__) {
    // As dev check, throw error if number of passed params is greater than number of actual params
    const passedParams = Object.keys(params);

    if (passedParams.length !== method.params.length) {
      const extraParams = passedParams
        .filter(param => !method.params.includes(param))
        .map(param => `${param}: ${params[param]}`);
      const moreThanOne = extraParams.length > 1;

      throw new errorUtils.APIError({
        code: 'BAD_PARAMS',
        statusCode: 400,
        trace: true,
        message: `Unknown param${moreThanOne ? 's' : ''} '${extraParams.join(',')}' for the path '${method.path}' of '${
          method.key
        }' schema method ${moreThanOne ? 'were' : 'was'} passed`,
      });
    }
  }

  return uri;
};

/**
 * Returns uri by filling path with passed parameters.
 * Finds a method by path and calls getMethodParameterizedPath function,
 * that means it validates passed params and existence of method
 *
 * @example
 * getParameterizedPath({path: '/orgs/:xorg_id/networks/:network_id', orgId: 1, params: {network_id: 7}}) => '/orgs/1/networks/7'
 *
 * @param {String} path   - Method's path
 * @param {Number} orgId  - User's organization id
 * @param {Object} params - Object with parameters values
 *
 * @returns {String}
 */
export const getParameterizedPath = ({
  path,
  orgId,
  params,
}: {
  path?: string;
  orgId?: string;
  params?: Record<string, string>;
}): string => {
  for (const method of schemaMethodsMap.values()) {
    if (method.path === path) {
      return getMethodParameterizedPath({method, orgId, params});
    }
  }

  throw new errorUtils.APIError({
    code: 'BAD_PARAMS',
    statusCode: 400,
    message: `API method for path '${path}' does not exist!`,
    trace: true,
  });
};

/**
 * Returns uri by filling path with passed parameters
 * Doesn't check method to validate passed params
 *
 * @example
 * getParameterizedPathLoosely({path: '/orgs/:xorg_id/networks/:id', orgId: 1, params: {id: 7}}) => '/orgs/1/networks/7'
 *
 * @param {String} path   - Method's path
 * @param {Number} orgId  - User's organization id
 * @param {Object} params - Object with parameters values
 *
 * @returns {String}
 */
export const getParameterizedPathLoosely = ({
  path = '',
  orgId = '',
  params = {},
}: {
  path?: string;
  orgId?: string;
  params?: Record<string, string>;
}): string =>
  Object.entries(params).reduce(
    (result, [key, value]) => result.replace(`:${key}`, value),
    path.replace(':xorg_id', orgId),
  );

let roleNames = new Set<string>();

export const setRoleNames = (names: typeof roleNames): void => {
  if (names !== roleNames) {
    roleNames = names;
  }
};

let hostIsSuperclusterMember = false;

export const setHostIsSupercluserMember = (isHostSupercluserMember: boolean): boolean =>
  (hostIsSuperclusterMember = isHostSupercluserMember);

export const isAPIAvailable = (...apiNames: SchemaMethodsKey[]): boolean =>
  apiNames.every(apiName => {
    let result = false;

    if (schemaMethodsMap.has(apiName)) {
      const {prettyAuthz, allowedOnNonleader} = schemaMethodsMap.get(apiName)!;

      result =
        hostIsSuperclusterMember && !allowedOnNonleader
          ? false
          : !Array.isArray(prettyAuthz) || prettyAuthz.some(authz => roleNames.has(authz));
    }

    return result;
  });

// A maximum flag to determine max workloads
// TODO: This is here temporary until API can give UI a flag
export const maxWorkloads = 100;
