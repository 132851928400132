/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {isValidElement, Fragment, createElement} from 'react';
import Button from 'components/Button/Button';
import Form from 'components/Form/Form';
import Modal from 'components/Modal/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import ModalContent from 'components/Modal/ModalContent';
import ModalFooter from 'components/Modal/ModalFooter';
import * as PropTypes from 'prop-types';
import {Machine} from 'xstate';
import {useMachine} from '@xstate/react';
import {defaultConfig, defaultActions, defaultServices, defaultGuards} from './defaultConfig';
import errorProcessors from './errorMachines';
import ModalMachineAuto from './ModalMachineAuto';

const getEmptyContext = () => ({
  notifications: [],
});

ModalMachine.propTypes = {
  // Xstate actions should be functions that are fire and forget side-effects
  // Executed in 'entry', 'exit' properties of a state node or in transitions
  // Can be used to modify State Machine context, e.g.:
  // {'nameOfAction': assign({someProperty: ctx => ctx.otherProperty})}
  actions: PropTypes.shape({
    // Can be used to override default close action
    close: PropTypes.func,
  }),
  // Function will be passed to Modal and will be called by default if close action is not defined
  onClose: PropTypes.func,
  // Customizable configuration for State Machine
  customConfig: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // Conditional checks that are used for transient transitions or in onDone or onError outcomes of service
  // Key of this object is a name of guard function and value is function that returns a Boolean
  guards: PropTypes.object,
  // Object where key is a name of service and value is function that returns Promise instance
  // {'apiCall': (ctx, e) => new Promise(resolve => resolve(e.data)}
  services: PropTypes.object,
  // Object with custom props for a Modal component
  modalProps: PropTypes.object,
  // If formsProps exist all Modal subcomponents will be wrapped with Form per each State Node
  // In case of function machine will be passed as the first argument and Formik options as second e.g.:
  // (machine, options) => {
  //    if (machine.matches('modal.open') {
  //      return renderStuff();
  //    }
  // };
  // To get a list of all errors for a single href you can pass {multiErrors: true} to modalProps
  // You can then use this errors list in a customErrorMessage to display errors.
  children: PropTypes.oneOfType([
    PropTypes.shape({
      formProps: PropTypes.object,
      header: PropTypes.oneOfType([
        PropTypes.shape({
          props: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
          children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        }),
        PropTypes.func,
        PropTypes.node,
      ]),
      content: PropTypes.oneOfType([
        PropTypes.shape({
          props: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
          children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        }),
        PropTypes.func,
        PropTypes.node,
      ]),
      footer: PropTypes.oneOfType([
        PropTypes.shape({
          props: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
          children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        }),
        // If footer passed as array it will be wrapped in buttons automatically
        PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            buttonProps: PropTypes.object,
          }),
        ),
        PropTypes.func,
        PropTypes.node,
      ]),
    }),
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  // Mention certain states that will skip rendering modal completely
  skipStates: PropTypes.arrayOf(PropTypes.string),
};

function ModalMachine(props) {
  const [state, send, service] = useMachine(
    () => {
      let machineConfig;
      const {actions, customConfig, guards, services, initialContext} = props;

      if (typeof customConfig === 'function') {
        machineConfig = customConfig(defaultConfig());
      } else if (typeof customConfig === 'object') {
        machineConfig = customConfig;
      } else {
        machineConfig = defaultConfig();
      }

      return Machine(machineConfig)
        .withConfig({
          actions: {...defaultActions(props), ...actions},
          guards: {...defaultGuards(), ...guards},
          services: {...defaultServices(), ...services},
        })
        .withContext({...initialContext, ...getEmptyContext()});
    },
    {devTools: process.env.NODE_ENV === 'development'},
  );

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production' && __LOG_XSTATE__ === true) {
    service.onTransition(current => {
      console.log(`%cCURRENT STATE = ${JSON.stringify(current.value)}`, 'color: blue; background-color: white;');
    });
    service.onEvent(event => console.log(`%cEVENT = ${event.type}`, 'color: red; background-color: white;'));
  }

  const renderModalComponents = (stateView, options) => {
    // the machine state returns valid element instead of predefined props for modal we can still render it
    if (isValidElement(stateView)) {
      return stateView;
    }

    const {header, content, footer} = stateView;

    const modalComponents = [
      [ModalHeader, header],
      [ModalContent, content],
      [ModalFooter, footer],
    ];

    return modalComponents.map(
      ([type, comp], idx) =>
        comp &&
        (typeof comp === 'function'
          ? comp(send, options, state)
          : isValidElement(comp)
          ? createElement(type, {key: idx}, comp)
          : createElement(
              type,
              typeof comp.props === 'function' ? {...comp.props(options), key: idx} : {...comp.props, key: idx},
              typeof comp.children === 'function'
                ? comp.children(send, options)
                : Array.isArray(comp)
                ? comp.map(({type, buttonProps}, idx) => (
                    <Button
                      key={idx === comp.length - 1 ? 'primary' : idx}
                      onClick={_.partial(send, {type})}
                      {...buttonProps}
                    />
                  ))
                : comp.children,
            )),
    );
  };

  const {children} = props;
  let stateView;

  if (typeof children === 'object') {
    stateView = children;
  } else if (typeof children === 'function') {
    stateView = children(state, service);

    if (stateView && stateView.type === Fragment) {
      stateView = stateView.props.children;

      if (Array.isArray(stateView)) {
        stateView = stateView.find(view => typeof view === 'object');
      }
    }
  }

  let onClose = props.onClose;

  // Clicking on header close should trigger the event when it is specified
  // We can either have a CANCEL (in case of confirmation Or when an api call is in process)
  // Or a CLOSE event (in case of alert Or Info modal)
  if (state.nextEvents.includes('CLOSE')) {
    onClose = _.partial(send, {type: 'CLOSE'});
  } else if (state.nextEvents.includes('CANCEL')) {
    onClose = _.partial(send, {type: 'CANCEL'});
  }

  const modalProps = {...props.modalProps, onClose};

  if (props.skipStates?.some(stateString => state.matches(stateString))) {
    return null;
  }

  return (
    <Modal {...modalProps}>
      {stateView &&
        (stateView.formProps ? (
          <Form {...stateView.formProps}>{options => renderModalComponents(stateView, options)}</Form>
        ) : (
          renderModalComponents(stateView)
        ))}
    </Modal>
  );
}

// Additional machines to process different API patterns
ModalMachine.errorProcessors = errorProcessors;
ModalMachine.Auto = ModalMachineAuto;

export default ModalMachine;
