/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {IterableElement} from 'type-fest';

export const availableMetrics = [
  'ProtectionReadiness',
  'ProtectedWorkloads',
  'ProtectionReadyWorkloadsByGranularity',
  'PortProtectionBySeverity',
  'PortProtectionByPortType',
  'ProtectionCoverage',
  'WorkloadsByRansomwareExposure',
  'WorkloadExposureByGranularity',
  'ProtectionCoverageByGranularity',
  'TopRiskyApplications',
  'RecommendedActions',
  'TopRiskyServices',
] as const;

// exclude these widgets from the dashboard
export const excludedMetrics = [] as const;

export type MetricId = IterableElement<typeof availableMetrics>;

// Ransomware Protection Dashboard - Time Series Statistics
type TimeSeriesProperty =
  | 'num_managed_workloads'
  | 'num_workloads_by_exposure'
  | 'ransomware_protection_coverage_percent';

export interface TimeSeriesStatisticsParam {
  property: TimeSeriesProperty;
  resolution?: 'day' | 'month' | 'quarter' | 'week';
  max_results?: number;
}

type TimeSeriesData = {
  start_date: string;
  end_date: string;
};

export type WorkloadCountTimeSeriesData = TimeSeriesData & {
  data: {count: number | null};
};

export type WorkloadExposureTimeSeriesData = TimeSeriesData & {
  data: {[E in ExposureLevel]: number};
};

export type ProtectionCoverageTimeSeriesData = TimeSeriesData & {
  data: {percentage: number} | null;
};

export interface TimeSeriesStatistics<T extends TimeSeriesProperty> {
  property: T;
  time_series: T extends 'num_managed_workloads'
    ? WorkloadCountTimeSeriesData[]
    : T extends 'num_workloads_by_exposure'
    ? WorkloadExposureTimeSeriesData[]
    : T extends 'ransomware_protection_coverage_percent'
    ? ProtectionCoverageTimeSeriesData[]
    : never;
}

export enum GRANULARITIES {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

// Ransomware Protection Dashboard - Risk Summary
export type RiskLevel = 'critical' | 'high' | 'low' | 'medium';
export type PortCategory = 'admin' | 'legacy';
export type ExposureLevel = RiskLevel | 'fully_protected';

export type ExposureLevelMap = {[E in ExposureLevel]: {name: string; color: string; colorNewUI: string}};

export interface RiskSummary {
  ransomware: {
    num_total_workloads: number;
    num_protected_workloads: number;
    num_risky_services: number;
    num_unenforced_workloads: number;
    workloads_by_exposure: {
      [E in ExposureLevel]: number;
    };
    risky_ports_by_severity: {
      [R in RiskLevel]: {
        num_protected_ports: number;
        num_unprotected_ports: number;
      };
    };
    risky_ports_by_category: {
      [R in PortCategory]: {
        num_protected_ports: number;
        num_unprotected_ports: number;
      };
    };
    top_risky_applications: {
      app_group: {href: string};
      labels: {href: string; key: string; value: string}[];
      num_workloads: number;
      ransomware_protection_percent: number;
    }[];
    top_risky_services: {
      href: string;
      name: string;
      ransomware_protection_percent: number;
    }[];
    ransomware_protection_coverage_percent: number;
    last_updated_at: string;
  } | null;
}

export type ProtectionScoreLevel = 'excellent' | 'good' | 'poor';

export type ScoreMap = {[P in ProtectionScoreLevel]: {color: string; title: string}};

export interface RiskyService {
  href?: string;
  name?: string;
  risk_details?: {
    ransomware?: {
      average_protection_percent?: number;
    };
  };
}
