/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {EnumBarItem} from 'components/Chart/BarChart';
import type {CircleChartData} from 'components/MetricsWidgets/CircleChartWidget';
import intl from '@illumio-shared/utils/intl';
import {
  type ExposureLevel,
  type ExposureLevelMap,
  GRANULARITIES,
  type PortCategory,
  type ProtectionCoverageTimeSeriesData,
  type ProtectionScoreLevel,
  type RiskLevel,
  type ScoreMap,
  type WorkloadExposureTimeSeriesData,
} from './MetricsConfig';
import type {BarFormatterParams} from 'components/ECharts/echartsType';

export const mapGranularityToParam = (granularity: GRANULARITIES): 'day' | 'month' | 'quarter' | 'week' => {
  if (granularity === GRANULARITIES.WEEKLY) {
    return 'week';
  }

  if (granularity === GRANULARITIES.MONTHLY) {
    return 'month';
  }

  if (granularity === GRANULARITIES.QUARTERLY) {
    return 'quarter';
  }

  return 'day';
};

export const mapGranularityToTitle = (granularity: GRANULARITIES): 'Daily' | 'Monthly' | 'Quarterly' | 'Weekly' => {
  if (granularity === GRANULARITIES.WEEKLY) {
    return 'Weekly';
  }

  if (granularity === GRANULARITIES.MONTHLY) {
    return 'Monthly';
  }

  if (granularity === GRANULARITIES.QUARTERLY) {
    return 'Quarterly';
  }

  return 'Daily';
};

export const convertToMonthDate = (dateString: string): string => {
  // EYE-109289 use the midnight time where stats was usually collected
  const utcString = `${dateString}T00:00:00Z`;

  return intl.date(new Date(utcString), {month: '2-digit', day: '2-digit', timeZone: 'UTC'});
};

export const mapTimeSeriesDataToBarChartData = (
  timeSeriesData: {start_date: string; end_date: string; data: {count: number | null}}[],
  totalAssets: number | null,
): EnumBarItem[][] => {
  const stack1: EnumBarItem[] = []; // grey bars
  const stack2: EnumBarItem[] = []; // blue bars

  timeSeriesData.forEach(({data, end_date}) => {
    const count = data?.count ?? null;
    const monthDate = convertToMonthDate(end_date); // assume end_date in yyyy-mm-dd format

    if (totalAssets !== null) {
      // Show no workload in the tooltip if no count is presented
      if (count === null) {
        stack1.unshift({
          id: String(monthDate),
          color: '#E3F2FC',
          label: intl('Common.NoDataWorkload'),
          tooltip: true,
          value: totalAssets,
        });
      } else if (count === 0) {
        stack1.unshift({
          id: String(monthDate),
          color: '#E3F2FC',
          label: intl('Workloads.WithCount', {count}),
          tooltip: true,
          value: totalAssets,
        });
      } else {
        stack1.unshift({id: String(monthDate), color: '#E3F2FC', tooltip: false, value: totalAssets});
      }
    }

    const label =
      totalAssets && count
        ? `${count} of ${intl('Workloads.WithCount', {count: totalAssets})}`
        : count === null
        ? intl('Common.NoDataWorkload')
        : String(count);

    if (count === null) {
      stack2.unshift({
        id: String(monthDate),
        color: '#1085FD',
        tooltip: false,
        value: null,
      });
    } else {
      stack2.unshift({
        id: String(monthDate),
        color: '#1085FD',
        label,
        tooltip: true,
        value: count === 0 ? null : count,
      });
    }
  });

  return [stack1, stack2];
};

export const ransomwareExposureMap: ExposureLevelMap = {
  fully_protected: {
    name: intl('Common.Protected'),
    color: 'rgb(81, 184, 103)', // --green-500
    colorNewUI: 'rgb(71, 196, 98)', // --lightning--green-400
  },
  critical: {
    name: intl('Common.Critical'),
    color: 'rgb(239, 83, 80)', // --red-400
    colorNewUI: 'rgb(238, 111, 17)', // --lightning--orange-500
  },
  high: {
    name: intl('Common.High'),
    color: 'rgb(236, 159, 72)', // --orange-400
    colorNewUI: 'rgb(6, 83, 163)', // --lightning--blue-800
  },
  medium: {
    name: intl('Common.Medium'),
    color: 'rgb(76, 144, 255)', // --blue-a200
    colorNewUI: 'rgb(16, 133, 253)', // --lightning--blue-500
  },
  low: {
    name: intl('Common.Low'),
    color: 'rgb(187, 222, 251)', // --blue-100
    colorNewUI: 'rgb(181, 218, 251)', // --lightning--blue-100
  },
};

export const portSeverityMap: {[R in RiskLevel]: {name: string; color: string; colorNewUI: string}} = {
  critical: {
    name: `${intl('Common.Critical')} ${intl('Common.Severity')}`,
    color: 'rgb(239, 83, 80)', // --red-400
    colorNewUI: 'rgb(238, 111, 17)', // --lightning--orange-500
  },
  high: {
    name: `${intl('Common.High')} ${intl('Common.Severity')}`,
    color: 'rgb(236, 159, 72)', // --orange-400
    colorNewUI: 'rgb(6, 83, 163)', // --lightning--blue-800
  },
  medium: {
    name: `${intl('Common.Medium')} ${intl('Common.Severity')}`,
    color: 'rgb(76, 144, 255)', // --blue-a200
    colorNewUI: 'rgb(16, 133, 253)', // --lightning--blue-500
  },
  low: {
    name: `${intl('Common.Low')} ${intl('Common.Severity')}`,
    color: 'rgb(187, 222, 251)', // --blue-100
    colorNewUI: 'rgb(181, 218, 251)', // --lightning--blue-100
  },
};

export const portTypeMap: {[P in PortCategory]: {name: string; color: string; colorNewUI: string}} = {
  legacy: {
    name: intl('RansomwareDashboard.LegacyPorts'),
    color: 'rgb(76, 144, 255)',
    colorNewUI: 'rgb(238, 111, 17)',
  },
  admin: {
    name: intl('Role.Admin'),
    color: 'rgb(187, 222, 251)',
    colorNewUI: 'rgb(71, 196, 98)',
  },
};

export const scoreMap = {
  poor: {color: '#ea4542', title: intl('Common.Poor')},
  good: {color: '#fca24f', title: intl('Common.Good')},
  excellent: {color: '#47c462', title: intl('Common.Excellent')},
};

export const mapExposureCountsToArray = (countsByExposure: {[E in ExposureLevel]: number}): {
  count: number;
  value: ExposureLevel;
}[] =>
  (['critical', 'high', 'medium', 'low', 'fully_protected'] as ExposureLevel[]).map(k => ({
    count: countsByExposure[k],
    value: k,
  }));

export const mapExposureDataToCircleChartData = (
  exposureData: {count: number; value: ExposureLevel}[],
  linkFn?: ({name, value}: {name: string; value: ExposureLevel}) => {to: string; params?: {[key: string]: unknown}},
): CircleChartData[] =>
  exposureData.map(({count, value}) => {
    const {name, color} = ransomwareExposureMap[value];

    return {
      id: value,
      label: name,
      tooltip: `${name} - ${intl.num(count)}`,
      value: count,
      color,
      ...(linkFn ? {link: linkFn({name, value})} : {}),
    };
  });

export const tooltipFormatter = (params: BarFormatterParams): string => {
  const {name, seriesName, data, value} = params;

  return `<div data-tid="echart-tooltip" xmlns="http://www.w3.org/1999/html">
<span>${intl('RansomwareDashboard.PortCategory', {port: name})}</span>
<br>
<span>${intl('RansomwareDashboard.PortCategoryCount', {
    port: seriesName,
    count: data.extraData[value],
  })}</span>
<br>
</div>`;
};

export const stackedBarYAxisFormatter = (value: number): string => {
  if (value === 0) {
    return '';
  }

  return value.toString(); // or some other string representation
};

export const xAxisLabelFormatter = (params: string): string => `${params}%`;

export const yAxisLabelFormatter = (params: string): string => intl('RansomwareDashboard.PortCategory', {port: params});

/**
 * Generates the option configuration for a percentage-based chart.
 * @param {Record<string, {protectedCount: number; unprotectedCount: number}>} data - Data containing protectedCount and unprotectedCount for each category.
 * @param {Record<string, {name: string; color: string; colorNewUI: string}>} typeMap - A map of category names to objects containing color information.
 * @returns {Object} - The option configuration for the percentage-based chart.
 */
export const getPercentageChartOption = (
  data: Record<string, {protectedCount: number; unprotectedCount: number}>,
  typeMap: Record<string, {name: string; color: string; colorNewUI: string}>,
): {
  yAxis: {
    axisLabel: {formatter: (params: string) => string; show: boolean};
    data: string[];
    axisLine: {show: boolean};
    axisTick: {show: boolean};
    inverse: boolean;
    type: string;
  };
  xAxis: {axisLabel: {formatter: (params: string) => string; show: boolean}; type: string};
  grid: {bottom: number};
  legend: {orient: string; data: string[]};
  series: {
    barWidth: number;
    stack: string;
    data: {extraData: Record<string, number>; value: number}[];
    barGap: string;
    name: string;
    type: string;
    barCategoryGap: number;
  }[];
  tooltip: {formatter: (params: BarFormatterParams) => string; axisPointer: {type: string}; trigger: string};
} => {
  // Extracting the categories and values
  const categories = Object.keys(data).map(d => typeMap[d].name);
  const protectedDataPercentages = [] as {extraData: Record<string, number>; value: number}[];
  const unprotectedDataPercentages = [] as {extraData: Record<string, number>; value: number}[];

  Object.keys(data).forEach(category => {
    const protectedPercentage = Math.round(
      (100 * data[category as PortCategory].protectedCount) /
        (data[category as PortCategory].protectedCount + data[category as PortCategory].unprotectedCount),
    );
    const unprotectedPercentage = 100 - protectedPercentage;

    protectedDataPercentages.push({
      value: protectedPercentage,
      // Store protected/ unprotected count as value by its percentage as key, to be utilized in tooltip
      extraData: {
        [protectedPercentage]: data[category as PortCategory].protectedCount,
        [unprotectedPercentage]: data[category as PortCategory].unprotectedCount,
      },
    });
    unprotectedDataPercentages.push({
      value: unprotectedPercentage,
      extraData: {
        [protectedPercentage]: data[category as PortCategory].protectedCount,
        [unprotectedPercentage]: data[category as PortCategory].unprotectedCount,
      },
    });
  });

  return {
    grid: {bottom: 32},
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      formatter: tooltipFormatter,
    },
    legend: {
      orient: 'horizontal',
      data: [intl('Common.Protected'), intl('RansomwareDashboard.Unprotected')],
    },
    yAxis: {
      type: 'category',
      data: categories,
      inverse: true,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        formatter: yAxisLabelFormatter,
      },
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        formatter: xAxisLabelFormatter,
      },
    },
    series: [
      {
        name: intl('Common.Protected'),
        type: 'bar',
        stack: 'total',
        data: protectedDataPercentages,
        barGap: '20%',
        barCategoryGap: 100, // todo: bar category gap is not working in ECharts
        barWidth: 10,
      },
      {
        name: intl('RansomwareDashboard.Unprotected'),
        type: 'bar',
        stack: 'total',
        data: unprotectedDataPercentages,
        barCategoryGap: 100,
        barGap: '20%',
        barWidth: 10,
      },
    ],
  };
};

export const getSeriesDataBySeverity = (
  data: WorkloadExposureTimeSeriesData[],
  severity: ExposureLevel,
): (number | null)[] => {
  return data.map(item => item.data?.[severity] ?? null).reverse();
};

export const getProtectionCoverage = (data: ProtectionCoverageTimeSeriesData[]): (number | null)[] => {
  return data
    .map(item => {
      if (typeof item?.data?.percentage === 'number') {
        return Math.floor(item.data.percentage);
      }

      return null;
    })
    .reverse();
};

export const getStackedBarChartOption = (
  data: WorkloadExposureTimeSeriesData[],
  typeMap: ExposureLevelMap,
): {
  yAxis: {
    type: string;
    minInterval: number;
    axisLabel: {formatter: (params: number) => string};
  };
  xAxis: {data: string[]; axisTick: {show: boolean}; type: string};
  grid: {bottom: number};
  legend: {orient: string; data: string[]};
  series: {stack: string; data: (number | null)[]; name: string; type: string}[];
  tooltip: {formatter: (params: Record<string, string>[]) => string | undefined; trigger: string};
} => {
  const legendLabels = ['critical', 'high', 'medium', 'low', 'fully_protected'].map(
    type => typeMap[type as ExposureLevel].name,
  );
  const xAxisLabels = data.map(item => convertToMonthDate(item.end_date)).reverse();

  return {
    grid: {bottom: 32},
    legend: {
      orient: 'horizontal',
      data: legendLabels,
    },
    xAxis: {
      type: 'category',
      data: xAxisLabels,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
      axisLabel: {
        formatter: stackedBarYAxisFormatter,
      },
    },
    series: [
      {
        name: 'Critical',
        type: 'bar',
        stack: 'stack1', // set the stack option
        data: getSeriesDataBySeverity(data, 'critical'),
      },
      {
        name: 'High',
        type: 'bar',
        stack: 'stack1', // set the stack option to group by category
        data: getSeriesDataBySeverity(data, 'high'),
      },
      {
        name: 'Medium',
        type: 'bar',
        stack: 'stack1', // set the stack option to group by category
        data: getSeriesDataBySeverity(data, 'medium'),
      },
      {
        name: 'Low',
        type: 'bar',
        stack: 'stack1', // set the stack option to group by category
        data: getSeriesDataBySeverity(data, 'low'),
      },
      {
        name: 'Protected',
        type: 'bar',
        stack: 'stack1', // set the stack option to group by category
        data: getSeriesDataBySeverity(data, 'fully_protected'),
      },
    ].reverse(),
    tooltip: {
      trigger: 'axis',
      formatter: (params: Record<string, string>[]): string | undefined => {
        // Check if all data in the stack is undefined
        const allUndefined = params.every(param => param.data === null);

        if (allUndefined) {
          return; // Return nothing to hide the tooltip
        }

        return `<div data-tid="echart-tooltip" xmlns="http://www.w3.org/1999/html">
        ${params
          .reverse()
          .map(({data, seriesName}) => `<span>${seriesName}: ${data}</span>`)
          .join('<br />')}
        </div>`;
      },
    },
  };
};

export const getLineChartOption = (
  data: ProtectionCoverageTimeSeriesData[],
): {
  yAxis: {
    type: string;
    min?: number;
    max?: number;
  };
  xAxis: {data: string[]; axisTick: {show: boolean}; type: string};
  grid: {bottom: number};
  series: {data: (number | null)[]; name: string; type: string}[];
  tooltip: {formatter: (params: Record<string, string>[]) => string | undefined; trigger: string};
} => {
  const xAxisLabels = data.map(item => convertToMonthDate(item.end_date)).reverse();

  return {
    grid: {bottom: 32},

    xAxis: {
      type: 'category',
      data: xAxisLabels,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100,
    },
    series: [
      {
        name: 'Line series',
        type: 'line',
        data: getProtectionCoverage(data),
      },
    ].reverse(),
    tooltip: {
      trigger: 'axis',
      formatter: (params: Record<string, unknown>[]): string | undefined => {
        // Check if all data in the stack is undefined
        const allUndefined = params.every(param => param.data === null);

        if (allUndefined) {
          return; // Return nothing to hide the tooltip
        }

        const [{name, value}] = params;
        const tid = `protection-coverage-by-granularity-tooltip-${name}-${value}`;

        return `<div data-tid="${tid}"><strong>${name}</strong>: ${value}%</div>`;
      },
    },
  };
};

export const getScoreLevel = (score: number): ProtectionScoreLevel => {
  if (score < 50) {
    return 'poor';
  }

  if (score < 80) {
    return 'good';
  }

  return 'excellent';
};

export const gaugeChartAxisLabelFormatter = (value: number): string => {
  if (value === 100) {
    return '100';
  }

  if (value === 0) {
    return '0';
  }

  if (value === 80) {
    return '80';
  }

  if (value === 50) {
    return '50';
  }

  return '';
};

export const gaugeChartDetailFormatter = (value: number): string => scoreMap[getScoreLevel(value)].title;

export const getGaugeChartOption = (
  data: number,
  scoreMap: ScoreMap,
  size?: 'extraSmall' | 'large' | 'medium' | 'small',
): {
  series: {
    pointer: {
      offsetCenter: (number | string)[];
      icon: string;
      length: string;
      width: string;
      itemStyle: {borderColor: string; color: string; borderWidth: number};
    };
    startAngle: number;
    data: {name: string; value: number}[];
    max: number;
    center: string[];
    endAngle: number;
    type: string;
    title: {
      offsetCenter: (number | string)[];
      rich: {
        value: {color: string; fontSize: number; lineHeight: number; fontWeight: number};
        percent: {verticalAlign: string; color: string; fontSize: number};
      };
    };
    axisLabel: {formatter: (value: number) => string; color: string; distance: number; fontSize: number};
    min: number;
    axisLine: {lineStyle: {color: (number | string)[][]; width: number}; roundCap: boolean};
    axisTick: {show: boolean};
    splitLine: {show: boolean};
    detail: {
      offsetCenter: (number | string)[];
      formatter: (value: number) => string;
      color: string;
      fontSize: string;
      valueAnimation: boolean;
    };
    radius: string;
  }[];
} => {
  return {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        center: size === 'small' || size === 'extraSmall' ? ['60%', '90%'] : ['50%', '70%'],
        radius: size === 'extraSmall' ? '105%' : size === 'small' ? '125%' : size === 'medium' ? '70%' : '100%',
        min: 0,
        max: 100,
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 8,
            color: [
              [0.5, scoreMap.poor.color],
              [0.8, scoreMap.good.color],
              [1, scoreMap.excellent.color],
            ],
          },
        },
        pointer: {
          icon: 'circle',
          length: size === 'small' || size === 'extraSmall' ? '20%' : '15%',
          width: size === 'small' || size === 'extraSmall' ? '20%' : '15%',
          offsetCenter: size === 'small' || size === 'extraSmall' ? [0, '-83.5%'] : [0, '-88.5%'],
          itemStyle: {
            color: 'white',
            borderColor: scoreMap[getScoreLevel(data)].color,
            borderWidth: 5,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: '#A3B6C7',
          fontSize: size === 'extraSmall' ? 9 : size === 'small' ? 11 : 13,
          distance: size === 'extraSmall' ? -35 : size === 'small' ? -42 : -45,
          // todo: use several axis to create the gap
          formatter: gaugeChartAxisLabelFormatter,
        },
        title: {
          offsetCenter: size === 'small' || size === 'extraSmall' ? [0, '-20%'] : [0, '-12%'],
          rich: {
            value: {
              fontSize: size === 'small' || size === 'extraSmall' ? 32 : size === 'medium' ? 36 : 45,
              color: '#3b4857',
              lineHeight: 35,
              fontWeight: 600,
            },
            percent: {
              fontSize: 21,
              color: '#3b4857',
              verticalAlign: 'bottom',
            },
          },
        },
        detail: {
          fontSize: size === 'small' || size === 'extraSmall' ? '0em' : '1.5em',
          offsetCenter: [0, '-45%'],
          valueAnimation: true,
          formatter: gaugeChartDetailFormatter,
          color: 'inherit',
        },
        data: [
          {
            value: data,
            name: `{value|${data}}{percent|%}`,
          },
        ],
      },
    ],
  };
};

export const breakpoints = [{size: {isExtraSmall: false}}, {maxWidth: 210, size: {isExtraSmall: true}}];

export interface Size {
  isExtraSmall: boolean;
}
