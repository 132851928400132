/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserScoped} from 'containers/User/UserState';
import listReducers from './List/ContainerClusterListState';
import summaryReducers from './Detail/ContainerClusterDetailState';
import profilesListReducers from './Detail/Profile/List/ContainerClusterContainerWorkloadProfileListState';
import profileDetailReducers from './Detail/Profile/Detail/ContainerClusterContainerWorkloadProfileDetailState';
import serviceBackendsReducers from './Detail/ServiceBackend/ContainerClusterServiceBackendsState';
import {isKubernetesSupported} from 'containers/App/AppState';

export default {
  containerClusters: combineReducers({
    ...listReducers,
    ...summaryReducers,
    ...profilesListReducers,
    ...profileDetailReducers,
    ...serviceBackendsReducers,
  }),
};

export const isContainerClustersEnabled = createSelector(
  isUserScoped,
  userIsScoped => !userIsScoped && isKubernetesSupported,
);
