/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {isUserReadOnly, isUserScoped} from 'containers/User/UserState';

export default {
  offlineSettings(state = [], action) {
    switch (action.type) {
      case 'WORKLOADS_GET_SETTINGS':
        return action.data.settings;
      case 'UPDATE_WORKLOADS_SETTINGS':
        return action.settings;
      default:
        return state;
    }
  },
};

export const getSettings = state => state.offlineSettings;

export const getWorkloadSettings = createSelector([getSettings, isUserReadOnly], (data, isUserReadOnly) => {
  const disconnectTimeout = data.workload_disconnected_timeout_seconds.find(
    obj => !obj.scope.length && obj.ven_type === 'server',
  );
  const uninstallTimeout = data.ven_uninstall_timeout_hours.find(obj => !obj.scope.length);
  const goodbyeTimeout = data.workload_goodbye_timeout_seconds.find(
    obj => !obj.scope.length && obj.ven_type === 'server',
  );
  const disconnectNotificationTimeout = data.workload_disconnected_notification_seconds.find(
    obj => !obj.scope.length && obj.ven_type === 'server',
  );

  const endpointDisconnectTimeout = data.workload_disconnected_timeout_seconds.find(
    obj => !obj.scope.length && obj.ven_type === 'endpoint',
  );
  const endpointGoodbyeTimeout = data.workload_goodbye_timeout_seconds.find(
    obj => !obj.scope.length && obj.ven_type === 'endpoint',
  );
  const endpointDisconnectNotificationTimeout = data.workload_disconnected_notification_seconds.find(
    obj => !obj.scope.length && obj.ven_type === 'endpoint',
  );

  const settings = {
    disconnectTimeout: disconnectTimeout?.value,
    goodbyeTimeout: goodbyeTimeout?.value,
    disconnectNotificationTimeout: disconnectNotificationTimeout?.warning,
    uninstallTimeout: uninstallTimeout?.value,
    endpointDisconnectTimeout: endpointDisconnectTimeout?.value,
    endpointGoodbyeTimeout: endpointGoodbyeTimeout?.value,
    endpointDisconnectNotificationTimeout: endpointDisconnectNotificationTimeout?.warning,
  };

  return {...settings, isUserReadOnly};
});

export const isOfflineTimersEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
