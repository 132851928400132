/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import {useCallback, useRef, useState, useLayoutEffect, useMemo, useContext} from 'react';
import {reactUtils} from '@illumio-shared/utils';
import {Button} from 'components';
import ValuePanel from './ValuePanel/ValuePanel';
import {AppContext} from 'containers/App/AppUtils';
import {INPUT_ID, INPUT_ID_EXPANDABLE, SEARCHBAR_ID, SEARCHBAR_CONTAINER_ID, COLUMN_WIDTH} from './SelectorUtils';

export default function SearchBar(props) {
  const {
    store: {prefetcher},
  } = useContext(AppContext);
  const {
    theme,
    saveRef,
    inputProps: {onBlur} = {},
    placeholder,
    error,
    focusLockGroupName,
    noActiveIndicator,
    errors = {},
    suggestion,
    allResources,
    active,
    disabled,
    insensitive,
    hasFocusLockWithContainerResource,
    hideClearAll: hideClearAllProp,
    query,
    activeCategory,
    registerHandlers,
    searchBarMaxHeight,
    onSelectedValueClick,
    onSetHighlighted,
    onMouseLeave,
    onValueRemove,
    onKeyDown,
    onInputChange,
    onClearValues,
    onToggle,
    onReturnFocus,
    onSearchBarClick,
    setCategoryPanelOnRight,
    title: titleProp,
    hideOptions,
    isExpander,
  } = props;
  const autoFocus = !prefetcher.scrollRestored && props.autoFocus;
  const values = reactUtils.useDeepCompareMemo(props.values);
  const searchBarContainerRef = useRef(null);
  const inputRef = useRef();
  const prevValues = useRef(null);
  const [activeIndicatorLeft, setActiveIndicatorLeft] = useState(null);
  const saveRefCallbackInput = useCallback(
    element => {
      inputRef.current = element;
      saveRef(isExpander ? INPUT_ID_EXPANDABLE : INPUT_ID, element);

      if (isExpander) {
        onReturnFocus({force: true});
      }
    },
    [saveRef, isExpander, onReturnFocus],
  );
  const saveRefSearchBar = useCallback(
    element => {
      saveRef(SEARCHBAR_ID, element);
    },
    [saveRef],
  );

  const saveRefSearchBarContainer = useCallback(
    element => {
      searchBarContainerRef.current = element;
      saveRef(SEARCHBAR_CONTAINER_ID, element);
    },
    [saveRef],
  );

  const handleChange = useCallback(evt => onInputChange(evt, evt.target.value), [onInputChange]);
  const handleBlur = useCallback(
    evt => {
      if (active) {
        return;
      }

      onMouseLeave(evt); // reset highlighted and suggestion
      onBlur?.(evt);
    },
    [active, onMouseLeave, onBlur],
  );

  useLayoutEffect(() => {
    if (prevValues.current !== values) {
      const searchBarLeft = searchBarContainerRef.current?.getBoundingClientRect().left;
      const inputLeft = inputRef.current?.getBoundingClientRect().left;
      const offset = inputLeft - searchBarLeft;

      setActiveIndicatorLeft(offset);

      setCategoryPanelOnRight?.(inputLeft < COLUMN_WIDTH); // not enough space for category column
    }

    prevValues.current = values;
  }, [values, setCategoryPanelOnRight]);

  const isInsensitive = insensitive || disabled;

  const hasError = error || Object.values(errors).some(resourceError => !_.isEmpty(resourceError));

  const searchBarContainerClasses = cx(theme.searchBarContainer, {
    [theme.disabled]: disabled,
    [theme.error]: hasError,
    [theme.expander]: isExpander,
    [theme.clickable]: !isInsensitive,
  });

  const title = useMemo(
    () => (typeof titleProp === 'function' ? titleProp({active, activeCategory, values}) : titleProp),
    [titleProp, active, activeCategory, values],
  );

  // SearhBar either shows an activeIndicator Or a placeholder
  const showActiveIndicator = active && !noActiveIndicator && !activeCategory.noActiveIndicator && !title;
  const inputPlaceholder = active ? activeCategory.placeholder ?? placeholder : placeholder;

  const legendContent = title || (showActiveIndicator ? activeCategory.name : undefined);

  const inputProps = {
    // Default attributes
    autoComplete: 'off',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    spellCheck: 'false',
    autoFocus,
    ...props.inputProps,
    onBlur: handleBlur,
    ...(isInsensitive && {tabIndex: -1}),
  };

  const legendClasses = cx(theme.legend, {
    [theme.title]: Boolean(title),
    [theme.show]: showActiveIndicator || Boolean(title),
    [theme.expander]: isExpander,
  });

  const hideClearAll = useMemo(() => {
    if (hideClearAllProp || values.size === 0) {
      return true;
    }

    return !Array.from(values.values()).some(resourceValues => resourceValues?.some(item => !item?.sticky));
  }, [hideClearAllProp, values]);

  const hasVisibleSelections = [...values].some(([id]) => !allResources[id].selectedProps?.hidden);

  return (
    <div
      className={searchBarContainerClasses}
      ref={saveRefSearchBarContainer}
      onClick={isInsensitive ? undefined : onSearchBarClick}
    >
      <fieldset
        className={cx(theme.searchBar, {
          //Focus can move to container elements in focusLock
          [theme.focused]: !hasError && active && !hasFocusLockWithContainerResource && !isExpander,
        })}
      >
        <legend
          className={legendClasses}
          {...(showActiveIndicator && activeIndicatorLeft ? {style: {marginLeft: activeIndicatorLeft}} : {})}
        >
          <span data-tid="comp-selector-legend">{legendContent}</span>
        </legend>
      </fieldset>
      <div ref={saveRefSearchBar} className={cx(theme.items, {[theme.expander]: isExpander})}>
        {!isExpander && hasVisibleSelections && (
          <ValuePanel
            theme={theme}
            saveRef={saveRef}
            insensitive={insensitive}
            disabled={disabled}
            values={hideOptions ? [] : values}
            errors={errors}
            allResources={allResources}
            onRemove={onValueRemove}
            registerHandlers={registerHandlers}
            searchBarMaxHeight={searchBarMaxHeight}
            onSelectedValueClick={onSelectedValueClick}
            onSetHighlighted={onSetHighlighted}
            onMouseLeave={onMouseLeave}
          />
        )}
        <FocusLock
          group={focusLockGroupName}
          disabled={!hasFocusLockWithContainerResource}
          autoFocus={false}
          className={cx(theme.inputPanel, {[theme.show]: !isExpander && (active || !hasVisibleSelections)})} // hide if expander as the background height should not increase
        >
          {query && suggestion && !query.includes(suggestion) ? (
            <input tabIndex="-1" readOnly className={theme.suggestion} value={`${query}${suggestion}`} />
          ) : null}
          <input
            readOnly={isExpander}
            disabled={isInsensitive}
            data-tid="comp-selector-input"
            type="text"
            ref={saveRefCallbackInput}
            className={theme.input}
            value={query}
            placeholder={isExpander || inputPlaceholder === legendContent ? undefined : inputPlaceholder}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            {...inputProps}
          />
        </FocusLock>
      </div>
      {!hideClearAll && (
        <Button
          tid="clear"
          theme={theme}
          noFill
          color="standard"
          insensitive={insensitive}
          disabled={disabled}
          icon="close"
          size="small"
          tooltip={intl('InstantSearch.ClearAll')}
          onClick={onClearValues}
        />
      )}
      <Button
        tid="toggle"
        noFill
        color="standard"
        tabIndex="-1"
        theme={theme}
        insensitive={insensitive}
        disabled={disabled}
        icon={active ? 'up' : 'down'}
        size="small"
        onClick={onToggle}
        tooltip={active ? intl('Common.Close') : intl('Common.Open')}
      />
    </div>
  );
}
