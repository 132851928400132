/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {hrefUtils} from '@illumio-shared/utils';
import {isAPIAvailable} from 'api/apiUtils';
import {isLabelBasedNetworkEnabled} from 'containers/App/AppState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './NetworkListConfig';
import {getAllUsersMap} from 'containers/User/UserState';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'NETWORK_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'NETWORK_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getNetworks = state => state.networks.list;
export const getNetworksCount = state => state.networks.count;

const getNetworkRows = createSelector(
  [getNetworks, getAllUsersMap, isLabelBasedNetworkEnabled],
  (networks, usersMap, isLabelBasedNetworkEnabled) => {
    // API doesn't support caps thus use is isAPIAvailable() for both selectable
    const apiAvailable = isAPIAvailable('network.update');

    return networks.map(item => {
      const {user_managed} = item;

      return {
        key: item.href,
        network_id: hrefUtils.getId(item.href),
        selectable: user_managed && apiAvailable && isLabelBasedNetworkEnabled,
        removable: user_managed && apiAvailable,
        data: {
          ...item,
          scopes: Array.isArray(item.scopes[0]) ? item.scopes : [[]],
          user_managed,
          updated_by: fillUserInfo(usersMap, item.updated_by),
        },
      };
    });
  },
);

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

export const getGridSettings = createSelector(
  [isLabelBasedNetworkEnabled, gridSettings],
  (isLabelBasedNetworkEnabled, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.scopes.disabled = !isLabelBasedNetworkEnabled;

    return {...gridSettings, columns};
  },
);
const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getNetworkRows,
    filterMap: getFilterMap,
  });

export const getNetworksPage = createStructuredSelector({
  grid: getGrid,
  count: getNetworksCount,
  categories,
  apAvailable: () => isAPIAvailable('network.update'),
  isLabelBasedNetworkEnabled,
});
