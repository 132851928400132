/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {PureComponent} from 'react';
import {connect} from '@illumio-shared/utils/redux';
import {MenuItem, Button} from 'components';
import {getPendingCount} from 'containers/Provisioning/Pending/List/PendingListState';
import styles from './ProvisionMenu.css';
import ProvisionProgressMenu from './ProvisionProgressMenu';
import {AppContext} from 'containers/App/AppUtils';

@connect(state => ({count: getPendingCount(state)}))
export default class ProvisionMenu extends PureComponent {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
  }

  renderContent() {
    const {count} = this.props;

    return (
      <>
        <MenuItem link="pending" text={intl('Provision.DraftChangeCount', {count})} data-tid="comp-navbar-provision" />
        <MenuItem
          link="versions.list"
          text={intl('Provision.Versions', {multiple: true})}
          data-tid="comp-navbar-versions"
        />
        <MenuItem link="provisioning" text={<ProvisionProgressMenu />} data-tid="comp-navbar-provision-bar" />
      </>
    );
  }

  render() {
    const {count} = this.props;

    return (
      <Button.Menu
        theme={styles}
        themePrefix="provision-"
        menuNoDropdownIcon
        size="large"
        color="standard"
        data-tid="comp-navbar-provision"
        menuAlign="left"
        menu={this.renderContent}
        icon="provision"
        counter={count}
        counterColor="orange"
      />
    );
  }
}
