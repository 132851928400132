/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getAllUsersMap, isUserReadOnly} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './SecureGatewayListConfig';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'SECURE_CONNECT_GATEWAYS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'SECURE_CONNECT_GATEWAYS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getSecureGateways = state => state.securegateways.list;
export const getSecureGatewaysCount = state => state.securegateways.count;

const getSecureGatewaysRows = createSelector(
  [getSecureGateways, getAllUsersMap, isUserReadOnly],
  (secureGateways, usersMap, userIsReadOnly) =>
    secureGateways.map(item => ({
      key: item.href,
      // Secure gateway is in draft mode if it has pending status
      draft: Boolean(item.update_type),
      selectable: !userIsReadOnly && item.created_by?.href !== '/users/0',
      // Secure gateway is removable only if it's active or with pending changes expect pending deletion
      removable: !userIsReadOnly && (!item.update_type || item.update_type !== 'delete'),
      // Return secure gateway data
      data: {...item, ip_address: item.addresses[0]},
    })),
);

export const getGridSettings = createSelector([isUserReadOnly, gridSettings], (userIsReadOnly, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = userIsReadOnly;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getSecureGatewaysRows,
  });

export const getSecureGatewaysPage = createSelector(
  [getGrid, getSecureGatewaysCount, isUserReadOnly],
  (grid, count, userIsReadOnly) => {
    const rowsMap = new Map();

    for (const row of grid.rows) {
      rowsMap.set(row.key, row);
    }

    return {grid, rowsMap, count, userIsReadOnly};
  },
);
