/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {saveAs} from 'file-saver';

export const getStaticMappingEvents = eventTypes => {
  const obj = eventTypes.reduce((obj, val) => {
    if (!val) {
      return obj;
    }

    obj[val.label] = val.value;

    return obj;
  }, {});

  return obj;
};

export const getEventTokenMap = eventTypes => {
  const obj = eventTypes.reduce((obj, val) => {
    if (!val) {
      return obj;
    }

    obj[val.value] = {value: val.value, desc: val.label()};

    return obj;
  }, {});

  return obj;
};

export const handleExportResponse = data => {
  const currentdate = new Date();
  const datetime = `${currentdate.getFullYear()}-${
    currentdate.getMonth() + 1
  }-${currentdate.getDate()}--${currentdate.getHours()}-${currentdate.getMinutes()}`;
  const fileName = `Org-Events-${datetime}.txt`;
  const blob = new Blob([JSON.stringify(data)], {type: 'text/plain;charset=utf-8'});

  saveAs(blob, fileName);
};
