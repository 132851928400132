/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'ACCESS_RESTRICTION_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getAccessRestrictionInstance = state => state.accessrestrictions.instance;
