/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';

type DiscardChangesModalProps = {
  modalProps: {
    title: string;
    confirmProps: {
      text: string;
    };
  };
  content: string;
};

export const getDiscardChangesModalProps = (object: string): DiscardChangesModalProps => ({
  modalProps: {title: intl('Common.DiscardUnsavedChanges'), confirmProps: {text: intl('Common.Discard')}},
  content: object
    ? intl('Common.DiscardUnsavedChangesToObjectMessage', {object})
    : intl('Common.DiscardUnsavedChangesMessage'),
});
