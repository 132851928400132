/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {StatusIcon, Tooltip, Form} from 'components';
import {populateDynamicOptionsCategory} from 'containers/Selector/Presets';
import {validatePortAndOrProtocol} from '../../ServiceUtils';

export const getGridSettings = ({os = 'linux', isEdit = true}) => ({
  id: 'serviceDefinitionEdit',
  showColumns: false,
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  //call getInitialRow from editableGrid when adding new row to get inital values and set form value
  getInitialRow: key => ({key, selectable: true, data: {portProto: [], process_name: '', service_name: ''}}),
  columns: {
    checkboxes: {},
    modified: {
      format: ({row}) => row.modified && <StatusIcon status="modified-diff" />,
      sortable: false,
      disabled: !isEdit,
    },
    error: {
      format: ({row}) =>
        row.errorMsg ? (
          <Tooltip content={row.errorMsg}>
            <StatusIcon status="error" />
          </Tooltip>
        ) : null,
      sortable: false,
    },
    portProto: {
      header: intl('Common.PortAndOrProtocol'),
      ...((__ANTMAN__ || __TARGET__ === 'core') && {
        disabled: os === 'windows_egress',
      }),
      format: ({row}) => (
        <Form.ObjectSelector
          name={`rows[${row.idx}].data.portProto`}
          tid={`rows[${row.idx}].data.portProto`}
          placeholder={intl('Port.PortProtocolPlaceHolder')}
          initialValue={row.initialValues?.portProto ?? []}
          categories={[
            populateDynamicOptionsCategory({
              getOptions: validatePortAndOrProtocol,
              id: 'portProto',
              name: intl('Common.PortAndOrProtocol'),
              placeholder: intl('Port.PortProtocolPlaceHolder'),
              errorMessage: intl('Common.InvalidProtocolMessage'),
              emptyBannerContent: intl('ObjectSelector.TypeToShowObject', {object: intl('Common.PortAndOrProtocol')}),
            }),
          ]}
          footerProps={{noFilteringTips: true}}
        />
      ),
      sortable: false, // each column is not sortable; We are not sorting this list.
      showAsterisk: os === 'linux',
    },
    processName: {
      header: intl('Port.ProcessPath'),
      format: ({row}) => (
        <Form.Input
          name={`rows[${row.idx}].data.process_name`}
          tid={`rows[${row.idx}].data.process_name`}
          placeholder={intl('Port.ProcessNamePlaceHolder')}
        />
      ),
      sortable: false,
      value: 'process_name',
      disabled: os === 'linux',
    },
    serviceName: {
      header: __ANTMAN__ ? intl('Antman.Services.ServiceName') : intl('Services.WindowsService'),
      format: ({row}) => (
        <Form.Input
          name={`rows[${row.idx}].data.service_name`}
          tid={`rows[${row.idx}].data.service_name`}
          placeholder={intl('Common.WinServiceMessage')}
        />
      ),
      sortable: false,
      value: 'service_name',
      disabled: os === 'linux',
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['modified'], size: 'min-content'},
      {columns: ['error'], size: 'min-content'},
      {columns: ['portProto'], size: 'minmax(150px, auto)'},
      {columns: ['processName'], size: 'minmax(150px, auto)'},
      {columns: ['serviceName'], size: 'minmax(150px, auto)'},
    ],
    {
      maxWidth: 640,
      template: [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['modified'], size: 'min-content'},
        {columns: ['error'], size: 'min-content'},
        {columns: ['portProto', 'processName', 'serviceName'], size: 'minmax(150px, auto)'},
      ],
    },
  ],
});
