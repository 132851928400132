/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from '.';

export const getEnforcementIntlByState = (function () {
  const intlByType = {
    visibility: intl('Common.VisibilityOnly'),
    enforced: intl('Workloads.Full'),
    idle: intl('Common.Idle'),
    unmanaged: intl('Common.Unmanaged'),
    unknown: intl('Common.Unknown'),
    selective: intl('Workloads.Selective'),
  };

  return (type: string) => intlByType[type as keyof typeof intlByType] || intl('Common.NotEnforced');
})();

export const getPolicyIntlByState = (function () {
  const intlByType = {
    idle: intl('Common.Idle'),
    visibility: intl('Common.VisibilityOnly'),
    selective: intl('Workloads.Selective'),
    enforced: intl('Workloads.Full'),
    unmanaged: intl('Common.Unmanaged'),
    unknown: intl('Common.Unknown'),
  };

  return (type: string) => intlByType[type as keyof typeof intlByType] || intl('Common.NotEnforced');
})();

export const getFlowVisibilityIntl = (function () {
  const intlByType = {
    flow_summary: intl('Map.FlowVisibility.HighDetail'),
    flow_drops: intl('Map.FlowVisibility.LowDetail'),
    flow_off: intl('Map.FlowVisibility.NoDetail'),
    mixed: intl('Map.FlowVisibility.MixedDetail'),
  };

  return (type: string) => intlByType[type as keyof typeof intlByType];
})();

export const getWorkloadStatusIntl = (function () {
  const intlByType = {
    none: intl('Common.NA'),
    online: intl('Common.Online'),
    offline: intl('Workloads.Status.Offline'),
    out_of_sync: intl('Workloads.Status.Syncing'), // shouldn't be reached, no icon is designed
    suspended: intl('Workloads.Status.Suspended'),
    syncing: intl('Workloads.Status.Syncing'),
    unmanaged: intl('Common.Unmanaged'),
    uninstalling: intl('Workloads.Status.Uninstalling'), // shouldn't be reached, no icon is designed
    applied: intl('Common.Active'),
    staged: intl('Common.Staged'),
  };

  return (type: string) => intlByType[type as keyof typeof intlByType];
})();
