/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createContext, createElement, Children} from 'react';
import {type TippyProps, useSingleton} from '@tippyjs/react/headless';
import Tooltip, {type TooltipProps} from './Tooltip';
import {typesUtils} from '@illumio-shared/utils';

interface Context {
  target: TippyProps['singleton'];
  props: Partial<TooltipProps>;
}

export type SingletonProps = typesUtils.UnionOmit<Partial<TooltipProps>, 'children'> & {
  children: typesUtils.ReactStrictNode;
};

export const SingletonContext = createContext<Context | null>(null);

export default function Singleton(singletonProps: SingletonProps): JSX.Element {
  const {children, ...props} = singletonProps;

  const [source, target] = useSingleton({
    // overrides are used so different tooltips within a singleton can have different properties
    overrides: ['placement'],
  });

  // why render a tooltip here? https://github.com/atomiks/tippyjs-react#headless-singleton
  return createElement(
    SingletonContext.Provider,
    {value: {target, props}},
    // Spread the children to make sure its shape doesn't change when switch between single child and multiple children, like in ModalFooter
    ...Children.toArray(children),
    // Add a tooltip that will be actually rendered
    <Tooltip key="tooltip" source={source} {...props} />,
  );
}
