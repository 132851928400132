/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';
import {MenuItem, MenuDelimiter} from 'components';

export const USA = 'United States of America';

export const usStates = {
  AL: intl('States.AL'),
  AK: intl('States.AK'),
  AS: intl('States.AS'),
  AZ: intl('States.AZ'),
  AR: intl('States.AR'),
  CA: intl('States.CA'),
  CO: intl('States.CO'),
  CT: intl('States.CT'),
  DE: intl('States.DE'),
  DC: intl('States.DC'),
  FM: intl('States.FM'),
  FL: intl('States.FL'),
  GA: intl('States.GA'),
  GU: intl('States.GU'),
  HI: intl('States.HI'),
  ID: intl('States.ID'),
  IL: intl('States.IL'),
  IN: intl('States.IN'),
  IA: intl('States.IA'),
  KS: intl('States.KS'),
  KY: intl('States.KY'),
  LA: intl('States.LA'),
  ME: intl('States.ME'),
  MH: intl('States.MH'),
  MD: intl('States.MD'),
  MA: intl('States.MA'),
  MI: intl('States.MI'),
  MN: intl('States.MN'),
  MS: intl('States.MS'),
  MO: intl('States.MO'),
  MT: intl('States.MT'),
  NE: intl('States.NE'),
  NV: intl('States.NV'),
  NH: intl('States.NH'),
  NJ: intl('States.NJ'),
  NM: intl('States.NM'),
  NY: intl('States.NY'),
  NC: intl('States.NC'),
  ND: intl('States.ND'),
  MP: intl('States.MP'),
  OH: intl('States.OH'),
  OK: intl('States.OK'),
  OR: intl('States.OR'),
  PW: intl('States.PW'),
  PA: intl('States.PA'),
  PR: intl('States.PR'),
  RI: intl('States.RI'),
  SC: intl('States.SC'),
  SD: intl('States.SD'),
  TN: intl('States.TN'),
  TX: intl('States.TX'),
  UT: intl('States.UT'),
  VT: intl('States.VT'),
  VI: intl('States.VI'),
  VA: intl('States.VA'),
  WA: intl('States.WA'),
  WV: intl('States.WV'),
  WI: intl('States.WI'),
  WY: intl('States.WY'),
};

export const getAddTenantsMenu = (productsMap, supportProducts) => {
  const products = supportProducts.map(product => (
    <MenuItem
      link={{to: 'mymanagedtenants.create', params: {product}}}
      tid={`add-${product}-tenant`}
      text={intl('MSP.ProductTenant', {product: productsMap.get(product)})}
      key={product}
    />
  ));
  const lastIndex = products.length - 1;

  return products.flatMap((product, index) => (index < lastIndex ? [product, <MenuDelimiter />] : product));
};
