/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {call} from 'redux-saga/effects';
import {getHelpPopupSagaFromRoute} from './HelpPopRouteSagaMap';

export function* fetchRouteSaga(data) {
  const routeSaga = getHelpPopupSagaFromRoute(data.name);

  if (routeSaga) {
    yield call(routeSaga, {...data});
  }
}
