/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {useSelector} from '@illumio-shared/utils/redux';
import {useCallback, useContext, useEffect, useState} from 'react';
import {Icon, Link} from 'components';
import {AppContext} from 'containers/App/AppUtils';
import {hrefUtils} from '@illumio-shared/utils';
import {dismissWhatsNew, fetchWhatsNew, broadcast} from './WhatsNewPopupSaga';
import {fetchVensByCondition} from 'containers/Ven/Upgrade/VenUpgradeSaga';
import {fetchPCEUpgradeRuleset} from 'containers/Ruleset/Item/RulesetItemSaga';
import {getParsedUIVersionNumber, getProductName, getUIVersion} from 'containers/App/AppState';
import {getPCEUpgradeRuleset} from 'containers/Ruleset/Item/RulesetItemState';
import {getVensVenTypeEndpoint} from 'containers/Ven/Upgrade/VenUpgradeState';
import {getWhatsNewDocUrl} from './WhatsNewPopupState';

import styles from './WhatsNewPopup.css';

const isVersionOlder = (v1, v2) => {
  return v1.major < v2.major || v1.minor < v2.minor || v1.patch < v2.patch;
};

const fetcherTimeout = 5000;
export default function WhatsNewPopup() {
  const [visible, setVisible] = useState(false);

  const versionString = useSelector(getUIVersion);
  const version = useSelector(getParsedUIVersionNumber);
  const product = useSelector(getProductName);
  const docUrl = useSelector(getWhatsNewDocUrl);
  const endpointVENCount = useSelector(getVensVenTypeEndpoint);
  const upgradeRuleset = useSelector(getPCEUpgradeRuleset);

  const {fetcher} = useContext(AppContext);

  useEffect(() => {
    // Defer fetch by 5 seconds
    const fetcherTimer = setTimeout(() => {
      if (!__MSP__) {
        fetcher.spawn(fetchVensByCondition, {condition: 'ven_type', value: 'endpoint'});
        fetcher.spawn(fetchPCEUpgradeRuleset);
      }

      fetcher.fork(fetchWhatsNew).then(checkedVersion => {
        setVisible(
          // user never dimissed what's new ever before, we show the popup
          !checkedVersion ||
            // or the last dimissed version is older than the current one
            isVersionOlder(checkedVersion, version),
        );
      });
    }, fetcherTimeout);

    return () => clearTimeout(fetcherTimer);
  }, [fetcher, version]);

  useEffect(() => {
    const handleMessage = ({type, data: checkedVersion}) => {
      if (type === 'WHATSNEW_DISMISS') {
        setVisible(!checkedVersion || isVersionOlder(checkedVersion, version));
      }
    };

    broadcast.addEventListener('message', handleMessage);

    return () => broadcast.removeEventListener('message', handleMessage);
  }, [version]);

  const handleWhatsNewClose = useCallback(async () => {
    fetcher.spawn(dismissWhatsNew);
    setVisible(false);
  }, [fetcher]);

  return (
    visible && (
      <div className={styles.whatsnew} data-tid="whatsnew">
        <Icon name="close" onClick={handleWhatsNewClose} theme={styles} themePrefix="close-" />
        <div>
          <div className={styles.title}>{intl('WhatsNew.NewAnnouncement')}</div>
          <div className={styles.content}>
            {intl('WhatsNew.IsReleased', {
              product: __MSP__ ? _.toUpper(product) : _.capitalize(product),
              version: versionString,
            })}
          </div>
        </div>
        <div>
          <Link href={docUrl} theme={styles} target="_blank" themeCompose="merge">
            {intl('WhatsNew.SeeWhatsNew')}
          </Link>
        </div>
        {!__MSP__ && endpointVENCount > 0 && !_.isEmpty(upgradeRuleset) && (
          <div className={styles.secondary}>
            {intl('WhatsNew.RulesForNonDomainAdapters', {
              a: ([text]) => {
                return (
                  <>
                    <br />
                    <br />
                    <Link
                      to="rulesets.item"
                      theme={styles}
                      themeCompose="merge"
                      params={{id: hrefUtils.getId(upgradeRuleset.href), pversion: 'draft', tab: 'intrascope'}}
                      target="_blank"
                    >
                      {text}
                    </Link>
                  </>
                );
              },
            })}
          </div>
        )}
      </div>
    )
  );
}
