/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {AppContext} from 'containers/App/AppUtils';

export default class TabUnderline extends PureComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {left: 0, width: 0};
  }

  setDimensions({center, width}) {
    this.setState({left: center, width: Math.ceil(width - 2)});
  }

  render() {
    const {
      props: {theme},
      state: {left, width},
    } = this;

    return (
      <div
        style={{transform: `translateX(${left}px) translateX(-50%)`, width: `${width}px`}}
        className={theme.underline}
      />
    );
  }
}
