/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {Component, createRef} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {Icon} from 'components';
import {tidUtils} from '@illumio-shared/utils';
import {generalUtils} from '@illumio-shared/utils/shared';
import styles from './Checkbox.css';
import stylesUtils from 'utils.css';

export default class Checkbox extends Component {
  static propTypes = {
    tid: PropTypes.string,
    name: PropTypes.string,

    // Optional string assigned to the checkbox, useful in groups, when many checkboxes have the same name. Default is 'on'
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#Value
    value: PropTypes.string,

    // Whether checkbox should have error stroke color
    error: PropTypes.bool,
    // Whether checkbox should be disabled, also makes it insensitive
    disabled: PropTypes.bool,
    // Whether checkbox should not be sensitive to click (pointer-events: none)
    insensitive: PropTypes.bool,
    // Whether checkbox should be not visible, but still occupy its space
    hidden: PropTypes.bool,
    // When checkbox should be hoverable but not clickable, because parent will handle the click event
    // Useful in case of controlled component, when clicking on parent element. Like MenuItem handles click, not checkbox itself
    notChangeable: PropTypes.bool,

    // By default checkbox input is _uncontrolled_, that means dom will be automatically updated by component itself on label click.
    // You can pass 'checked' attribute to make it _controlled_ by your parent component,
    // in which case click on the checkbox will just invoke onChange handler where parent can decide to rerender checkbox with new value
    checked: PropTypes.bool,
    // Callback that is called on upon change, required in case of controlled behavior ('checked' prop is specified by parent)
    // Gets object with pressed keys as last argument
    onChange: PropTypes.func,

    // Callback that is called after changed checked state has been rendered. Useful in case of _uncontrolled_ behavior to notify parent
    // Gets object with pressed keys as last argument
    onAfterChange: PropTypes.func,
    // can set the initial state for _uncontrolled_ checkboxes
    initiallyChecked: PropTypes.bool,

    // Optional label text regardless of checked/unchecked state. Is bold when `subLabel` is specified
    label: PropTypes.node,
    // Optional text on the next line below label, only shown if label is shown
    subLabel: PropTypes.node,
    // Optional label for the checked state. Useful in uncontrolled version, to avoid tracking state in parent component
    // Is bold when `subLabelChecked` is specified
    labelChecked: PropTypes.node,
    // Optional text on the next line below label for the checked state, only shown if labelChecked is shown
    subLabelChecked: PropTypes.node,
    // Any markup that should go after label/sublabel.
    // Useful for showing some nested dependend form element aligned with the label text
    nested: PropTypes.node,
    // The same on checked state
    nestedChecked: PropTypes.node,

    // Custom checkmark icons for the check/uncheck states
    checkmarkIconOn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    checkmarkIconOff: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    // Custom icons for the check/uncheck states that replace the whole checkbox
    // When only iconOn is specified, uncheck state will be empty and stroke of iconOn will be displayed on hover
    // When iconOn and iconOff are the same, uncheck state will render only stroke if the icon
    // When iconOn and iconOff are different, each will be rendered for a corresponding state
    iconOn: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    iconOff: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    // Custom props for a hidden <input/> checkbox, for instance for specifying custom data-tid
    inputProps: PropTypes.object,
    // Custom props for a <label> around the input, for instance for specifying custom data-tid
    labelProps: PropTypes.object,
    tooltip: PropTypes.node,
    tooltipProps: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {checked: props.initiallyChecked ?? false};

    this.pressedKeys = {};
    this.checkbox = createRef();
    // Generate unique id to tied input and text labels
    this.id = generalUtils.randomString(5, true);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleLabelClick = this.handleLabelClick.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const controlled = typeof nextProps.checked === 'boolean';

    if (controlled && nextProps.checked !== prevState.checked) {
      return {controlled, checked: nextProps.checked};
    }

    if (controlled !== prevState.controlled) {
      return {controlled, checked: Boolean(prevState.checked)};
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (typeof this.props.onAfterChange === 'function' && prevState.checked !== this.state.checked) {
      this.props.onAfterChange(this.state.checked, this.pressedKeys);
    }
  }

  handleKeyDown(evt) {
    this.wasFocusedBeforeClick = true;

    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  }

  handleMouseDown(evt) {
    this.wasFocusedBeforeClick = document.activeElement === this.checkbox.current;

    if (this.props.onMouseDown) {
      this.props.onMouseDown(evt);
    }
  }

  handleLabelClick(evt) {
    const {notChangeable} = this.props;

    // If component is notChangeable don't change value and exit handler to let parent's handler do the job if needed
    if (notChangeable) {
      return evt.preventDefault();
    }

    this.pressedKeys = {
      alt: Boolean(evt.altKey),
      ctrl: Boolean(evt.ctrlKey),
      meta: Boolean(evt.metaKey),
      shift: Boolean(evt.shiftKey),
    };

    this.labelWasClicked = true;
    evt.stopPropagation(); // To prevent parent click event

    // Remove text selection, that browser adds from the previous focused element.
    // Might happen if user clicks to check/uncheck very quickly or in case of shift+click
    if (typeof window.getSelection === 'function') {
      window.getSelection().removeAllRanges();
    }

    // In Firefox shift+click on checkbox's label doesn't work
    // https://bugzilla.mozilla.org/show_bug.cgi?id=812389
    // To workaround that, call click on checkbox manually
    if (this.pressedKeys.shift && browser.engine.gecko) {
      this.checkbox.current.click();
    }
  }

  handleCheckboxClick(evt) {
    // To prevent 'handleLabelClick' one more time in case of Firefox manual click call on shift+click
    if (this.labelWasClicked) {
      evt.stopPropagation();
      this.labelWasClicked = false;
    }
  }

  handleCheckboxChange(evt) {
    const {notChangeable, onChange} = this.props;

    // If checkbox wasn't focused when click started, blur it to remove focus glow
    if (!this.wasFocusedBeforeClick && document.activeElement === this.checkbox.current) {
      this.checkbox.current.blur();
    }

    this.wasFocusedBeforeClick = false;

    if (notChangeable) {
      return;
    }

    const {controlled} = this.state;
    const checking = this.checkbox.current.checked;

    if (controlled) {
      onChange(evt, checking, this.pressedKeys, this.props);
    } else {
      this.setState({checked: checking});
    }

    this.labelWasClicked = false;
  }

  render() {
    const {
      name,
      value,
      tid,
      error,
      disabled,
      insensitive,
      hidden,
      notChangeable,
      label,
      subLabel,
      labelChecked,
      subLabelChecked,
      nested,
      nestedChecked,
      iconOn,
      iconOff,
      checkmarkIconOn,
      checkmarkIconOff,
      onChange,
      onAfterChange,
      theme,
      initiallyChecked,
      tooltip,
      tooltipProps,
      // Custom input properties
      inputProps: {...inputProps} = {},
      labelProps: {...labelProps} = {},
      // Other label wrapper properties
      ...checkboxProps
    } = mixThemeWithProps(styles, this.props);

    const {checked} = this.state;
    const text = checked && labelChecked !== undefined ? labelChecked : label;
    const subText = checked && subLabelChecked !== undefined ? subLabelChecked : subLabel;
    const nestedElements = checked && nestedChecked !== undefined ? nestedChecked : nested;
    const defaultCheckmarkIcon = checkmarkIconOn && !checkmarkIconOff ? checkmarkIconOn : 'check';
    const customCheckmarkIcon = checked ? checkmarkIconOn : checkmarkIconOff;
    const showSide = Boolean(text || subText || nestedElements);
    let box;

    if (iconOn || iconOff) {
      let icon;
      let themePrefix;

      if (iconOff) {
        if (iconOff === iconOn) {
          themePrefix = 'customBoxSame-';
        } else {
          themePrefix = 'customBoxDifferent-';
        }

        icon = checked ? iconOn : iconOff;
      } else {
        themePrefix = 'customBoxSingle-';
        icon = iconOn;
      }

      if (typeof icon === 'string') {
        box = <Icon name={icon} theme={theme} themePrefix={themePrefix} />;
      } else {
        box = icon;
      }
    } else {
      let checkmarkIcon;

      if (!customCheckmarkIcon || typeof customCheckmarkIcon === 'string') {
        checkmarkIcon = (
          <Icon
            name={customCheckmarkIcon || defaultCheckmarkIcon}
            theme={theme}
            themePrefix="box-"
            tooltip={tooltip}
            tooltipProps={tooltipProps}
          />
        );
      } else {
        checkmarkIcon = customCheckmarkIcon;
      }

      box = <div className={cx(theme.box, {[theme.boxFilled]: checked || customCheckmarkIcon})}>{checkmarkIcon}</div>;
    }

    checkboxProps.className = cx(theme.checkbox, {
      [theme.error]: error,
      [theme.hidden]: hidden,
      [theme.checked]: checked,
      [theme.disabled]: disabled,
      [stylesUtils.insensitive]: insensitive || (!tooltip && disabled),
    });

    labelProps.className = cx(theme.labelBox, {[theme.noText]: !showSide});
    labelProps.onClick = this.handleLabelClick;
    labelProps.onMouseDown = this.handleMouseDown;
    labelProps.onKeyDown = this.handleKeyDown;

    labelProps['data-tid'] ||= 'checkbox-clickable';

    inputProps.name = name;
    inputProps.value = value;
    inputProps.type = 'checkbox';
    inputProps.checked = checked;
    inputProps.disabled = disabled;
    inputProps.ref = this.checkbox;
    inputProps.className = theme.input;
    inputProps.onClick = this.handleCheckboxClick;
    inputProps.onChange = this.handleCheckboxChange;

    inputProps['data-tid'] ||= 'checkbox-input';

    inputProps.id ||= this.id;

    if (insensitive || disabled || notChangeable) {
      inputProps.tabIndex = -1;
    }

    const tids = [tid];

    if (checked) {
      tids.push('checked');
    }

    checkboxProps['data-tid'] = tidUtils.getTid('checkbox', tids);

    return (
      <div {...checkboxProps}>
        <div className={theme.checkboxContent}>
          <label {...labelProps}>
            <input {...inputProps} />
            {box}
          </label>

          {showSide && (
            <div className={theme.side}>
              {text ? (
                <label
                  htmlFor={inputProps.id}
                  className={cx(theme.labelText, {[stylesUtils.bold]: subText})}
                  onClick={this.handleLabelClick}
                  onMouseDown={this.handleMouseDown}
                  onKeyDown={this.handleKeyDown}
                  data-tid="checkbox-text"
                >
                  {text}
                </label>
              ) : null}
              {subText ? (
                <label
                  htmlFor={inputProps.id}
                  className={theme.labelSubText}
                  onClick={this.handleLabelClick}
                  onMouseDown={this.handleMouseDown}
                  onKeyDown={this.handleKeyDown}
                  data-tid="checkbox-subtext"
                >
                  {subText}
                </label>
              ) : null}
            </div>
          )}
        </div>

        {nestedElements ? <div className={theme.nestedElements}>{nestedElements}</div> : null}
      </div>
    );
  }
}
