/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';
import {convertLegacyQueries, getExplorerQuery} from 'containers/IlluminationMap/Filter/MapFilterApiUtils';
import {getDifferenceInDays} from '@illumio-shared/utils/intl/utils';
import * as FormUtils from 'components/Form/FormUtils';

export const getLatestUniquelyLabelledQueries = (queries = [], max) => {
  const uniqueQueries = new Map();
  const sortedQueries = queries.slice().sort((queryA, queryB) => {
    if (!queryA?.date && !queryB?.date) {
      return 0;
    }

    if (!queryA?.date) {
      return 1;
    }

    if (!queryB?.date) {
      return -1;
    }

    return new Date(queryB.date).getTime() - new Date(queryA.date).getTime();
  });

  sortedQueries.some(query => {
    if (max !== undefined && uniqueQueries.size >= max) {
      return true;
    }

    if (!uniqueQueries.get(query.label)) {
      uniqueQueries.set(query.label, query);
    }

    return false;
  });

  return Array.from(uniqueQueries.values());
};

export function getFormattedDays(nDays) {
  if (nDays <= 1) {
    return intl('Common.HoursNumber', {count: 24});
  }

  if (nDays > 90) {
    return intl('DateTimeInput.Anytime', {count: nDays});
  }

  return intl('Common.DaysNumber', {count: nDays});
}

export function populateCategories(appGroupStatics) {
  return [
    {
      id: 'appgroups',
      name: intl('Common.AppGroups'),
      resources: {
        allAppGroups: {
          sticky: true,
          statics: [intl('Common.All')],
          optionProps: {
            isPill: true,
          },
          selectedProps: {hideResourceName: true},
        },
        appgroups: {
          statics: appGroupStatics,
          optionProps: {
            allowMultipleSelection: true,
            isPill: true,
          },
          selectedProps: {
            hideResourceName: true,
          },
        },
      },
    },
  ];
}

export function populateRuleSetsCategories(ruleSetStatics) {
  return [
    {
      id: 'rule_sets',
      name: intl('Common.Name'),
      resources: {
        allRuleSets: {
          sticky: true,
          statics: [intl('Common.All')],
          optionProps: {
            isPill: true,
          },
          selectedProps: {hideResourceName: true},
        },
        rulesets: {
          statics: ruleSetStatics,
          optionProps: {
            allowMultipleSelection: true,
            isPill: true,
          },
          selectedProps: {
            hideResourceName: true,
          },
        },
      },
    },
  ];
}

export const transformQueries = ({queries, appGroups, userSettings, services}) =>
  convertLegacyQueries(
    (queries || []).filter(({filters}) => filters && !filters.dateFrom && !filters.dateTo),
    appGroups,
    services,
  ).map(({filters, queryName}) => ({
    ...getExplorerQuery({filters, nameTag: ''}),
    query_name: queryName,
    max_results: userSettings.explorerMaxDownloadResults,
  }));

/**
 * Generates the payload for saving report
 * @param {Object} options - Configuration object for generating the payload.
 * @param {string} options.name - The name of the report.
 * @param {Object} options.template - An object containing details about the template, such as href.
 * @param {Object} options.timeRange - An object containing time range details for the report, such as value (number of days).
 * @param {boolean} options.emailReport - Whether or not to send the report by email.
 * @param {Date} options.scheduledTime - A JavaScript Date object for scheduled time of the report.
 * @param {string} options.recurrence - The frequency of report generation (e.g., 'daily', 'weekly', 'monthly', 'once').
 * @param {boolean} options.isExplorerExport - If true, handles explorer-specific payload generation.
 * @param {Object} options.filters - An object containing filters to be applied to the report (only used if isExplorerExport is true).
 * @param {boolean} options.isAppGroupExport - If true, handles app group-specific payload generation.
 * @param {Array} options.appGroups - An array of selected application groups (only used if isAppGroupExport is true)
 * @param {Object} options.appGroupOptions - An object containing additional details about the application groups, like appGroupMap and appGroupData (only used if isAppGroupExport is true).
 * @param {boolean} options.isRuleHitCount - If true, handles app rule-hit cout specific payload generation.
 * @param {Array} options.ruleSets - An array of ruleSets (only used if isRuleHitCount is true)
 * @param {boolean} options.generateReportNow - If true, the payload is generated for immediate report creation.
 *
 * @returns {Object} payload - Payload object containing properties required for API request.
 */
export const getSaveRecurrencePayload = ({
  name,
  template,
  timeRange,
  emailReport,
  scheduledTime,
  recurrence,
  isExplorerExport,
  filters,
  isAppGroupExport,
  appGroups,
  appGroupOptions,
  isRuleHitCount,
  ruleSets,
  ruleSetOptions,
  generateReportNow,
}) => {
  const payload = {
    name,
    report_template: {href: template.href},
    report_parameters: {
      report_time_range: {last_num_days: timeRange?.value},
    },
    send_by_email: emailReport,
    report_format: 'pdf',
    scheduled_at: scheduledTime.toISOString(),
    report_generation_frequency: recurrence,
  };

  if (isExplorerExport) {
    // Use the previously stored filters if a new filter was not selected.

    Object.assign(payload, {
      report_parameters: {
        ...payload.report_parameters,
        filters: {
          ...filters,
          sources: {
            ...filters.sources,
            include: filters.sources.include.map(source => (Array.isArray(source) ? source : [source])),
          },
          destinations: {
            ...filters.destinations,
            include: filters.destinations.include.map(dest => (Array.isArray(dest) ? dest : [dest])),
          },
        },
        report_time_range: {
          ...payload.report_time_range,
          last_num_days: getDifferenceInDays(filters.start_date, filters.end_date),
        },
      },
    });

    delete payload.report_format;
  } else if (isAppGroupExport) {
    Object.assign(payload.report_parameters, {
      app_groups: FormUtils.getSelectionsFromEntries(appGroups).map(appGroupValue => {
        const appGroupHref = appGroupOptions.appGroupMap[appGroupValue];

        //Get the labelQuery information based on the href
        return appGroupOptions?.appGroupData[appGroupHref]?.labelsQuery || [];
      }),
    });
  } else if (isRuleHitCount) {
    const ruleSetsHrefs = FormUtils.getSelectionsFromEntries(ruleSets)
      .map(ruleSetName => ruleSetOptions.ruleSet[ruleSetName])
      .filter(value => value !== undefined);
    const result = ruleSetsHrefs.length > 0 ? ruleSetsHrefs : [];

    Object.assign(payload, {
      report_parameters: {
        ...payload.report_parameters,
        rule_sets: result,
      },
    });

    delete payload.report_format;
  }

  if (generateReportNow) {
    payload.description = name;
    delete payload.scheduled_at;
    delete payload.report_generation_frequency;
    delete payload.name;
  }

  return payload;
};
