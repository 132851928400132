/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useState, useCallback, type MouseEvent, type KeyboardEvent} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import * as PropTypes from 'prop-types';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {domUtils, typesUtils} from '@illumio-shared/utils';
import {Icon} from 'components';
import styles from './Drawer.css';
import {forwardRefFactory, forwardRefSymbol, type ForwardRefProps} from 'react-forwardref-utils';

export type DrawerProps = ForwardRefProps<HTMLDivElement> &
  ThemeProps & {
    tid?: string;
    text: JSX.Element | string;
    infoIcon?: JSX.Element;
    secondaryText?: string;
    initiallyClosed?: boolean;
    children: typesUtils.ReactStrictNode;
    closed?: boolean;
    iconPosition?: 'after' | 'before';
    onChange?: (evt: KeyboardEvent | MouseEvent) => void;
  };

Drawer.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired, // left aligned text on the handle of the drawer
  secondaryText: PropTypes.string, // right aligned text on the handle of the drawer
  initiallyClosed: PropTypes.bool, // we want the drawer open by default
  children: PropTypes.node.isRequired, // no children, no drawer.

  // controlled component props
  closed: PropTypes.bool, // control the drawer's state via props
  onChange: PropTypes.func, // handler passed down that gets called when the drawer's handle is clicked
};

// only set overflow visible on 'opened' transitionEnd. If you set it instantly, the drawer's content will overflow while opening
// but you want the overflow to be hidden right as the drawer starts closing
const variants = {
  opened: {
    height: 'auto',
    opacity: 1,
    overflow: 'hidden',
    paddingTop: 'var(--offset-small)',
    transition: {duration: domUtils.isMotionReduced() ? 0 : 0.4},
    transitionEnd: {overflow: 'visible'},
  },
  closed: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    paddingTop: 0,
    transition: {duration: domUtils.isMotionReduced() ? 0 : 0.3},
  },
};

function Drawer(props: DrawerProps): JSX.Element {
  const {
    tid,
    children,
    closed,
    initiallyClosed = false,
    onChange,
    text,
    infoIcon,
    secondaryText,
    theme,
    [forwardRefSymbol]: ref,
    iconPosition = 'before',
  } = mixThemeWithProps(styles, props);

  const [closedLocally, setClosedLocally] = useState(initiallyClosed);
  const handleClick = useCallback(
    (evt: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => {
      if (onChange) {
        // if drawer is controlled, just call onChange prop and let parent decide what to do
        onChange(evt);
      } else {
        setClosedLocally((isClosed: boolean) => !isClosed);
      }
    },
    [setClosedLocally, onChange],
  );
  const isClosed = closed ?? closedLocally;
  const icon = <Icon name={isClosed ? 'up' : 'down'} position={iconPosition} theme={theme} />;

  return (
    <div className={theme.drawer} ref={ref}>
      {infoIcon ? (
        <div className={theme.titleContainer}>
          <div
            tabIndex={0}
            className={cx(theme.handle, {[theme.condensed]: !secondaryText})}
            onClick={handleClick}
            onKeyPress={handleClick}
            data-tid={tid || 'drawer-handle'}
          >
            {iconPosition === 'before' ? icon : null}
            <div className={theme.innerHandle}>
              <div className={theme.text}>{text}</div>
              {secondaryText ? <div className={theme.secondaryText}>{secondaryText}</div> : null}
            </div>
            {iconPosition === 'after' ? icon : null}
          </div>
          {infoIcon}
        </div>
      ) : (
        <div
          tabIndex={0}
          className={cx(theme.handle, {[theme.condensed]: !secondaryText})}
          onClick={handleClick}
          onKeyPress={handleClick}
          data-tid={tid || 'drawer-handle'}
        >
          {iconPosition === 'before' ? icon : null}
          <div className={theme.innerHandle}>
            <div className={theme.text}>{text}</div>
            {secondaryText ? <div className={theme.secondaryText}>{secondaryText}</div> : null}
          </div>
          {iconPosition === 'after' ? icon : null}
        </div>
      )}

      <AnimatePresence initial={false}>
        <motion.div
          key="drawer"
          initial="closed"
          animate={isClosed ? 'closed' : 'opened'}
          exit="closed"
          variants={variants}
          className={theme.drawerContent}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default forwardRefFactory(Drawer);
