/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getAllReports, getAllReportsCount, getGridSettings} from './ReportsListState';
import gridSaga from 'components/Grid/GridSaga';

export function* fetchReportsLists({force = false, priority = null} = {}) {
  return yield call(apiSaga, 'jobs.get_collection', {
    priority,
    headers: {Prefer: 'respond-async'},
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getAllReports)) || count !== (yield select(getAllReportsCount))) {
        yield put({type: 'REPORTSLIST_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removeReport({href}) {
  yield call(apiSaga, 'job.delete', {
    params: {job_id: href},
    hrefs: [href],
  });
}

export function* fetchReportsListPage(route, refetch = false) {
  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    *onSaga() {
      const [{list}] = yield all([call(fetchReportsLists, {force: refetch}), call(fetchAllUsers, {force: refetch})]);

      return list.length;
    },
  });
}
