/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {Field} from 'formik';
import * as PropTypes from 'prop-types';
import {useWhen} from '../FormUtils';
import {forwardRefSymbol, forwardRefFactory} from 'react-forwardref-utils';
import FileUpload from './FileUpload';

FormFileUpload.propsTypes = {
  tid: PropTypes.string.isRequired,
  // arbitrary  unique name mapped to formik's values object that is initialized
  name: PropTypes.string.isRequired,
  // restrict to only types to upload. e.g. ['.jpg', '.jpeg', '.png']
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
};

export function FormFileUpload(props) {
  const {[forwardRefSymbol]: ref, form, field, ...fileProps} = useWhen(props.form, props.field, props);
  const {tid, acceptedFiles, hideFileUpload} = fileProps;
  const {touched, errors, setFieldValue, setFieldTouched, values} = form;
  const {name} = field;
  const errorText = touched[name] && errors.hasOwnProperty(name) && errors[name];
  //Adding invisible formik field if form is inside of other form to preserve values
  const fileNameField = `${name}_filename`;
  const fileUploadProps = {
    tid,
    name,
    acceptedFiles,
    errorText,
    fileName: values[fileNameField],
    hideFileUpload,
  };

  fileUploadProps.onChange = (fileName, parsedFile) => {
    if (values[name] !== parsedFile) {
      setFieldValue(name, parsedFile);

      if (values.hasOwnProperty(fileNameField)) {
        setFieldValue(fileNameField, fileName);
      }
    }

    if (!touched[name]) {
      setFieldTouched(name, true);
    }
  };

  return <FileUpload {...fileUploadProps} />;
}

export default forwardRefFactory(props => <Field {...props} component={FormFileUpload} />, {
  hoistSource: FormFileUpload,
});
