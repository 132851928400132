/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps} from '@css-modules-theme/react';
import intl from '@illumio-shared/utils/intl';
import {ExpandableLabelList, type ExpandableLabelListProps} from 'components';
import ExpandableScopeListShort from './ExpandableScopeListShort';
import styles from './ExpandableScopeList.css';

export interface ExpandableScopeListProps<Value>
  extends Omit<ExpandableLabelListProps<Value>, 'dontMergeGroups' | 'initialGroupCount' | 'renderGroup' | 'values'> {
  /** Values is always an array of arrays (labels) */
  values: Value[][];

  /** Number of scopes visible at first */
  initialScopeCount?: ExpandableLabelListProps<Value>['initialGroupCount'];

  /** A custom function to render each scope */
  renderScope?: ExpandableLabelListProps<Value>['renderGroup'];
}

export default function ExpandableScopeList<T>(props: ExpandableScopeListProps<T>): JSX.Element {
  const {initialScopeCount, renderScope, ...listProps}: ExpandableLabelListProps<T> & ExpandableScopeListProps<T> =
    mixThemeWithProps(styles, props);

  listProps.dontMergeGroups = true;
  listProps.initialGroupCount = initialScopeCount ?? 3;
  listProps.modalTitle ??= intl('Common.Scopes');
  listProps.showMoreText ??= ({groupsLeft, groupValuesLeft}) => {
    return intl('Common.CountMoreLabelsAndScopes', {
      labelsLeft: groupValuesLeft,
      scopesLeft: groupsLeft,
      bothLeft: Boolean(groupValuesLeft > 0 && groupsLeft),
    });
  };

  listProps.renderGroup =
    renderScope ??
    ((items, index) => (
      <div key={index} className={styles.scope}>
        {items}
      </div>
    ));

  return <ExpandableLabelList {...listProps} />;
}

ExpandableScopeList.Short = ExpandableScopeListShort;
