/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {call, delay, put, select, spawn, takeEvery, cancel} from 'redux-saga/effects';
import {errorUtils} from '@illumio-shared/utils';
import apiSaga from 'api/apiSaga';
import {getAppGroups} from './AppGroupState';
import {isIlluminationApiEnabled} from 'containers/App/AppState';
import _ from 'lodash';
import {getCoverageStatusForAppGroupIds, parseAppGroupNodes, parseAppGroupRuleCoverage} from './AppGroupUtils';

export function* fetchAppGroupSummary({rebuild = false, force = false} = {}) {
  try {
    return yield call(apiSaga, 'app_group_summary.get', {
      query: {include_stale: false},
      cache: !force,
      headers: rebuild ? {'cache-control': 'no-cache'} : {},
      timeout: 100_000,
      *onDone({data}) {
        if (data) {
          const {nodes, labels} = data;

          const parsedNodes = parseAppGroupNodes(nodes, labels);

          yield put({type: 'APPGROUPSUMMARY_GET_LIST', data: {nodes: parsedNodes, labels}});
          yield put({type: 'APPGROUPSUMMARY_GET_DETAILS', data: {...data, nodes: parsedNodes}});

          return {...data, nodes: parsedNodes};
        }

        // if the api returned no data, a rebuild needs to be initiated; call again and trigger a rebuild;
        return yield call(fetchAppGroupSummary, {rebuild: true, force: true});
      },
    });
  } catch (error) {
    if (error instanceof errorUtils.TimeoutError || (error instanceof errorUtils.APIError && error.timeout)) {
      yield delay(30_000); // wait a bit before trying again

      // try again without rebuild (because if rebuild was true for the previous call, the rebuild was already triggered)
      return yield call(fetchAppGroupSummary, {rebuild: false, force});
    }

    throw error;
  }
}

/**
 * fetches app group rule coverage for one or more app group ids using the observed rule coverage bulk API;
 * this saga can fire both success and failure actions from the same call because coverage may have been returned
 * for some ids, but not for others; the success action will be fired with the successful ids and their coverage;
 * the failure action will be fired with the app group ids without coverage;
 * @param ids - array of app group ids
 * @return {Generator<*, *, *>}
 */
export function* fetchAppGroupRuleCoverageByIds({ids, isRetry = false}) {
  if (!ids?.length) {
    return;
  }

  if (!isRetry) {
    yield put({type: 'APP_GROUP_LIST_RULE_COVERAGE_LOADING', ids});
  }

  let coverage;

  try {
    const {data} = yield call(apiSaga, 'app_groups.observed_rule_coverage', {
      data: {app_group_ids: ids},
      timeout: 200_000,
    });

    coverage = parseAppGroupRuleCoverage(data);
  } catch (error) {
    if (error instanceof errorUtils.TimeoutError || (error instanceof errorUtils.APIError && error.timeout)) {
      yield delay(30_000); // Wait a bit before trying again

      return yield call(fetchAppGroupRuleCoverageByIds, {ids, isRetry: true});
    }

    console.error(error);
    yield put({type: 'APP_GROUP_LIST_RULE_COVERAGE_FAILED', ids});
    throw error;
  }

  const {idsWithCoverage, idsWithoutCoverage} = getCoverageStatusForAppGroupIds(ids, coverage);

  if (idsWithCoverage.length) {
    // coverage was returned for one or more app group ids; mark as successful and save their coverage data;
    yield put({type: 'APP_GROUP_LIST_RULE_COVERAGE_SUCCESS', ids: idsWithCoverage, data: coverage});
  }

  if (idsWithoutCoverage.length) {
    // no coverage was returned for one or more app group ids; mark them as failed;
    yield put({type: 'APP_GROUP_LIST_RULE_COVERAGE_FAILED', ids: idsWithoutCoverage});
  }
}

/**
 * listens for an APP_GROUP_INSTANCE_RULE_COVERAGE_FAILED action for a given id;
 * when the action is detected, it waits a moment and then fires an action to clear
 * the error state;
 * @param id
 * @return {Generator<*, void, *>}
 */
export function* watchRecalculateCoverageError({id}) {
  const task = yield takeEvery(['APP_GROUP_INSTANCE_RULE_COVERAGE_FAILED'], function* (action) {
    if (action.id === id) {
      // wait for recalculate coverage error animation to finish
      yield delay(800);
      // clear the recalculate coverage error, so it does not continue to show
      yield put({type: 'APP_GROUP_INSTANCE_RULE_COVERAGE_CLEAR_ERROR', id});
      yield cancel(task);
    }
  });
}

/**
 * fetches the rule coverage for one app group
 * @param id - id of app group
 * @returns {Generator<*, void, *>}
 */
export function* fetchAppGroupInstanceRuleCoverage({id}) {
  yield put({type: 'APP_GROUP_INSTANCE_RULE_COVERAGE_LOADING', id});

  try {
    const {data} = yield call(apiSaga, 'app_group.observed_rule_coverage', {
      params: {app_group_id: id},
      headers: {'cache-control': 'no-cache'},
      timeout: 200_000, // large timeout because observed rule coverage can take up to 3 min for some app groups;
    });

    yield put({type: 'APP_GROUP_INSTANCE_RULE_COVERAGE_SUCCESS', data, id});
  } catch (error) {
    console.error(error);
    yield spawn(watchRecalculateCoverageError, {id});
    yield put({type: 'APP_GROUP_INSTANCE_RULE_COVERAGE_FAILED', id});
  }
}

export function* prefetchAppGroupTabs() {
  const appGroups = yield select(getAppGroups);
  const illuminationApiEnabled = yield select(isIlluminationApiEnabled);

  if (illuminationApiEnabled && _.isEmpty(appGroups)) {
    yield call(fetchAppGroupSummary, {});
  }
}
