/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {call, put, select, fork} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {cachedResponses} from 'api/apiCache';
import {fetchVenLibraries} from 'containers/Ven/Library/VenLibrarySaga';
import {isSuperclusterMember} from 'containers/User/UserState';
import {isPairingProfilesEnabled} from '../PairingProfileState';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {getPairingProfileInstance} from './PairingProfileItemState';
import {getRouteParams} from 'containers/App/AppState';
import {errorUtils} from '@illumio-shared/utils';
import {isAPIAvailable} from 'api/apiUtils';

export function* fetchPairingProfileInstance({id, representation = 'pairing_profile_labels', force = false}) {
  return yield call(apiSaga, 'pairing_profiles.get_instance', {
    params: {pairing_profile_id: id},
    query: {representation},
    cache: !force,
    *onDone({data}) {
      if (force || data !== (yield select(getPairingProfileInstance))) {
        yield put({type: 'PAIRING_PROFILES_GET_INSTANCE', data});
      }

      return data;
    },
  });
}

export function* fetchPairingProfileItem({params}, refetch = false) {
  try {
    const pairingProfilesIsEnabled = yield select(isPairingProfilesEnabled);

    if (!pairingProfilesIsEnabled) {
      throw new errorUtils.RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
    }

    const label = yield call(fetchPairingProfileInstance, {force: refetch, id: params.id});

    yield call(fetchSelectiveUsers, [label.created_by, label.updated_by], refetch);

    return label;
  } catch {
    throw new errorUtils.RedirectError({to: 'pairingProfiles', proceedFetching: true, thisFetchIsDone: true});
  }
}

export function* fetchPairingProfileEdit({name}, refetch = false) {
  const userIsSuperClusterMember = yield select(isSuperclusterMember);

  if (
    (name.endsWith('edit') && (!isAPIAvailable('pairing_profile.update') || userIsSuperClusterMember)) ||
    (name.endsWith('create') && (!isAPIAvailable('pairing_profiles.create') || userIsSuperClusterMember))
  ) {
    throw new errorUtils.RedirectError({to: 'pairingProfiles.list', proceedFetching: true, thisFetchIsDone: true});
  }

  yield fork(fetchVenLibraries, {force: refetch});
}

export function* createPairingProfile(payload) {
  return yield call(apiSaga, 'pairing_profiles.create', {
    data: payload,
    *onDone() {
      // After pairing profile is created invalidate pairing profile list cache
      cachedResponses.removeByMethodName('pairing_profiles.get_collection');

      // Invalid labels list cache
      cachedResponses.removeByMethodName('labels.get_collection');
    },
  });
}

export function* updatePairingProfile(id, payload) {
  return yield call(apiSaga, 'pairing_profile.update', {
    params: {pairing_profile_id: id},
    data: payload,
    *onDone() {
      // Invalid pairing profile instance
      cachedResponses.removeByMethodName('pairing_profiles.get_instance');
      // Invalidate pairing profile list
      cachedResponses.removeByMethodName('pairing_profiles.get_collection');

      // Invalid labels list cache
      cachedResponses.removeByMethodName('labels.get_collection');
    },
  });
}

export function* deletePairingProfile(groupId) {
  const {id} = yield select(getRouteParams);

  return yield call(apiSaga, 'pairing_profile.delete', {
    params: {pairing_profile_id: id || groupId},
    *onDone() {
      // Invalidate cache for pairing profile list
      cachedResponses.removeByMethodName('pairing_profiles.get_collection');

      // Invalid labels list cache
      cachedResponses.removeByMethodName('labels.get_collection');
    },
  });
}

export function* deletePairingKeysForPairingProfile(pairingProfileId) {
  yield call(apiSaga, 'pairing_profile.pairing_keys', {
    params: {pairing_profile_id: pairingProfileId},
    *onDone() {
      // Invalidate cache for pairing profile list
      return cachedResponses.removeByMethodName('pairing_profiles.get_collection');
    },
  });
}
