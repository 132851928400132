/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {Component} from 'react';
import {AppContext} from 'containers/App/AppUtils';

/**
 * Fetcher is a component that provides several methods to work with sagas, like fork/spawn.
 *
 * It's rendered as PrefetchRouteChildren -> Fetcher -> Container for each matching route.
 * Basically, each container will have own Fetcher is a wrapper, so when a page container unmounts due to navigation,
 * Fetcher makes sure that all the forked sagas will be canceled automatically in its componentWillUnmount, because they unmount together.
 *
 * Fetcher takes the closest AppContext object, mixes own instance in it as a 'fetcher' prop, and passes it down as a new AppContext object.
 * Context Provider can be nexted in itself multiple times, and the closest one wins when it comes to consuming it in a component,
 * that is how we make sure that `fetcher` is always relevant to current container and it's children, including Modal that it might render.
 */
export default class Fetcher extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.unmounted = false;

    this.tasks = [];
    this.originalContextValue = context;
    this.contextValue = {...context, fetcher: this};
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.cancelForked();
  }

  fork(saga, ...args) {
    if (!this.unmounted) {
      const {task, promise} = this.run(saga, ...args);

      this.tasks.push({type: 'fork', task, promise});

      return promise;
    }
  }

  spawn(saga, ...args) {
    if (!this.unmounted) {
      const {task, promise} = this.run(saga, ...args);

      this.tasks.push({type: 'spawn', task, promise});

      return promise;
    }
  }

  run(saga, ...args) {
    const {task, promise} = this.context.store.runSagaToPromise(saga, ...args);

    promise
      // Disregard the error, since this promise branch is used only to drop the task,
      // and we expect the error to be caught by an actual caller
      .catch(() => {})
      .finally(() => {
        this.dropTasks(task);
      });

    return {task, promise};
  }

  cancelForked() {
    this.cancel(this.tasks.filter(item => item.type === 'fork').map(item => item.promise));
  }

  cancelTasks(tasks) {
    this.cancel(tasks.map(task => task.toPromise()));
  }

  cancel(promises) {
    if (!Array.isArray(promises)) {
      promises = [promises];
    }

    const foundTasks = promises.reduce((result, promise) => {
      const foundTask = this.tasks.find(item => item.promise === promise);

      if (foundTask) {
        result.push(foundTask);
      }

      return result;
    }, []);

    if (foundTasks.length) {
      for (const {task} of foundTasks) {
        if (task.isRunning()) {
          task.cancel();
        }
      }

      this.dropTasks(foundTasks);
    }
  }

  dropTasks(tasks) {
    if (!Array.isArray(tasks)) {
      tasks = [tasks];
    }

    this.tasks = this.tasks.filter(item => !tasks.includes(item.task));
  }

  render() {
    if (this.originalContextValue !== this.context) {
      this.originalContextValue = this.context;
      this.contextValue = {...this.context, fetcher: this};
    }

    return <AppContext.Provider value={this.contextValue}>{this.props.children}</AppContext.Provider>;
  }
}
