/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {Form, StatusIcon} from 'components';
import intl from '@illumio-shared/utils/intl';

export const gridSettings = createSelector([], () => ({
  id: 'networklabelslist',
  getInitialRow: key => ({
    key,
    selectable: true,
    warning: '',
    data: {labels: []},
  }),
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: false,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    modified: {
      format: ({row}) => row.modified && <StatusIcon status="modified-diff" />,
      sortable: false,
    },
    rowStatus: {
      sortable: false,
    },
    labels: {
      header: intl('Common.Scopes'),
      sortable: false,
      format: ({row, component}) => {
        return (
          <Form.LabelSelector
            tid={`labels[${row.idx}]`}
            name={`labels[${row.idx}].data.labels`}
            placeholder={intl('Labels.Select', {multiple: true})}
            config={{
              hasLabelGroups: false,
              allowMultipleSelection: false,
              allowCreate: true,
            }}
            onChange={_.partial(component.handleLabelChange, row.idx)}
          />
        );
      },
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['modified'], size: 'min-content'},
      {columns: ['rowStatus'], size: 'max-content'},
      {columns: ['labels'], size: 'minmax(250px, auto)'},
    ],
    {
      maxWidth: 1024,
      template() {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['modified'], size: 'min-content'},
          {columns: ['rowStatus'], size: 'max-content'},
          {columns: ['labels'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['modified'], size: 'min-content'},
          {columns: ['rowStatus'], size: 'max-content'},
          {columns: ['labels'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 480,
      template() {
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['modified'], size: 'min-content'},
          {columns: ['rowStatus'], size: 'max-content'},
          {columns: ['labels'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
