/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import intl from '@illumio-shared/utils/intl';
import * as GridUtils from 'components/Grid/GridUtils';

export const gridSettings = createSelector([], () => ({
  id: 'corporateiplist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: ({row}) => row.data.name,
    },
    addresses: {
      header: intl('PolicyGenerator.Addresses'),
      value: ({row}) => {
        let result = row.data.public_snat_ips;

        if (row.data.public_snat_ips.length > 1) {
          result = `${row.data.public_snat_ips[0]} ${intl('Common.CountMore', {
            count: row.data.public_snat_ips.length - 1,
          })}`;
        }

        return result;
      },
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['name'], size: 'minmax(70px, auto)'},
      {columns: ['addresses'], size: 'minmax(120px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name'], size: 'minmax(70px, auto)'},
            {columns: ['addresses'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name'], size: 'minmax(70px, auto)'},
          {columns: ['addresses'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'addresses'], size: 'minmax(140px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'addresses'], size: 'minmax(140px, auto)'},
        ];
      },
    },
  ],
}));
