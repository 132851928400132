/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {createElement, isValidElement} from 'react';
import {Tooltip} from 'components';
import {reactUtils, tidUtils, typesUtils} from '@illumio-shared/utils';
import type {ThemeProps} from '@css-modules-theme/react';
import type {TooltipProps} from 'components/Tooltip/Tooltip';

const defaultTid = 'comp-toolgroup';

type ToolGroupProps = ThemeProps & {
  tid?: string;
  expand?: boolean;

  /**
   * whether we should render a singleton or not.
   *
   * Default: `false` - by default we render the ToolGroup inside of a singleton
   */
  noSingleton?: boolean;

  /** we can pass props to the singleton if we want */
  singletonProps?: TooltipProps;
  children?: typesUtils.ReactStrictNode;

  /** append child tid on each tool group item */
  appendChildTid?: boolean;
};

export default function ToolGroup(props: ToolGroupProps): JSX.Element {
  const {children, theme, expand, noSingleton = false, singletonProps, appendChildTid = false} = props;
  const items = reactUtils.unwrapChildren(children).map((child, index) => {
    const dataTid =
      isValidElement(child) && appendChildTid && child.props.tid
        ? `elem-toolgroup-item ${child.props.tid}`
        : 'elem-toolgroup-item';

    return (
      <div
        key={isValidElement(child) && child.key ? child.key : index}
        className={theme!.toolGroupItem}
        data-tid={dataTid}
      >
        {child}
      </div>
    );
  });

  const toolgroup = createElement(
    'div',
    {
      'data-tid': tidUtils.getTid(defaultTid, props.tid),
      'className': cx(theme!.toolGroup, {[theme!.toolGroupExpand]: expand}),
    },
    ...items,
  );

  return noSingleton ? toolgroup : <Tooltip.Singleton {...singletonProps}>{toolgroup}</Tooltip.Singleton>;
}
