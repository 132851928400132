/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserScoped} from 'containers/User/UserState';
import listReducers, {getNetworkFunctionControls} from './List/LoadBalancerListState';
import instanceReducers from 'containers/LoadBalancer/Item/LoadBalancerItemState';

export default {
  loadBalancer: combineReducers({
    ...listReducers,
    ...instanceReducers,
  }),
};

export const isLoadBalancerEnabled = createSelector(
  [isUserScoped, getNetworkFunctionControls],
  (userIsScoped, nfcs) => !userIsScoped && Boolean(nfcs?.length),
);
