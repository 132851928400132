/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';

export const getCustomOptionLabel = value => {
  if (!value) {
    return '';
  }

  if (value.key === 'preset') {
    return value.label;
  }

  const {from, to} = value;

  const formattedFrom = Number.isNaN(Date.parse(from?.value))
    ? intl('DateTimeInput.Anytime')
    : intl.date(new Date(from?.value), 'l_HH_mm');

  const formattedTo = Number.isNaN(Date.parse(to?.value))
    ? intl.date(new Date(), 'l_HH_mm')
    : intl.date(new Date(to?.value), 'l_HH_mm');

  return `${formattedFrom} - ${formattedTo}`;
};

export function formatCustomDateTimeValue(value) {
  const result = {};

  if (!value) {
    return result;
  }

  if (['custom', 'preset'].includes(value.key)) {
    return value;
  }

  const {from, to} = value;

  result.from = {
    value: from,
    key: Number.isNaN(Date.parse(from)) ? 'string' : 'date',
  };

  result.to = {
    value: to,
    key: Number.isNaN(Date.parse(to)) ? 'string' : 'date',
  };

  return {value: result, key: 'userInput', label: getCustomOptionLabel(result)};
}

export const getFormattedDateTimeRange = range => {
  const dateTimeRange = {};

  if (!range) {
    return dateTimeRange;
  }

  const {from, to} = range.value;

  if (range.key === 'preset') {
    const now = new Date();

    dateTimeRange.from = new Date(now.getTime() + to.value * 3600 * 1000);
    dateTimeRange.to = now;
  } else {
    dateTimeRange.from = from.key === 'string' ? from.value.toLowerCase() : from.value;
    dateTimeRange.to = to.value;
  }

  return dateTimeRange;
};

export const areOptionsEqual = (src, dest) =>
  src.key === dest.key &&
  src.value?.from?.value === dest.value?.from?.value &&
  src.value?.to?.value === dest.value?.to?.value;
