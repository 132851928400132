/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import type {TimePickerTime, TimePickerValue, TimePickerValueNumber} from './TimePickerTypes';

const VALUE_HMS_PATTERN = /^(\d\d):(\d\d):(\d\d)$/; // hh:mm:ss pattern with capture groups
const VALUE_HM_PATTERN = /^(\d\d):(\d\d)$/; // hh:mm pattern with capture groups

/**
 * returns the number as a string containing 2 digits; if the number is LT 10, it prefixes a zero;
 * @param value - number to prefix with zeros
 */
const zeroFill = (value: number): TimePickerValueNumber => String(value).padStart(2, '0') as TimePickerValueNumber;

/**
 * returns true if the hour value is valid; false otherwise;
 * @param hour {number|undefined} - hour value
 * @return {boolean}
 */
export const isValidHour = (hour?: number): boolean => {
  return Number.isInteger(hour) && (hour as number) >= 0 && (hour as number) <= 23;
};

/**
 * returns true if the minute value is valid; false otherwise;
 * @param minute {number|undefined} - minute value
 * @return {boolean}
 */
export const isValidMinute = (minute?: number): boolean => {
  return Number.isInteger(minute) && (minute as number) >= 0 && (minute as number) <= 59;
};

/**
 * returns true second number is valid;
 * @param second {number|undefined} - integer 0-59
 * @return {boolean} - true if valid; false otherwise;
 */
export const isValidSecond = (second?: number): boolean => {
  return Number.isInteger(second) && (second as number) >= 0 && (second as number) <= 59;
};

/**
 * returns true if the time is valid; false otherwise;
 * @param time {TimePickerTime|undefined}
 * @return {boolean}
 */
export const isValidTime = (time: TimePickerTime | undefined): boolean => {
  return (
    Boolean(time) &&
    isValidHour(time?.hour) &&
    isValidMinute(time?.minute) &&
    (time?.second === undefined || isValidSecond(time?.second))
  );
};

/**
 * Converts a time value string to a TimePickerTime object;
 * @param value {TimePickerValue|''} - string value representing time (in 24hr format)
 * @return {TimePickerTime|undefined} - time object (in 24hr format)
 */
export const stringToTime = (value: TimePickerValue | ''): TimePickerTime | undefined => {
  const hmsMatch = (value ?? '').match(VALUE_HMS_PATTERN);
  const hmMatch = (value ?? '').match(VALUE_HM_PATTERN);

  if (hmsMatch) {
    const hour = parseInt(hmsMatch[1], 10);
    const minute = parseInt(hmsMatch[2], 10);
    const second = parseInt(hmsMatch[3], 10);

    return {hour, minute, second};
  }

  if (hmMatch) {
    const hour = parseInt(hmMatch[1], 10);
    const minute = parseInt(hmMatch[2], 10);

    return {hour, minute};
  }

  return;
};

/**
 * Converts a time object to a string representing the time;
 * @param time {TimePickerTime} - time object in 24hr format;
 * @return {TimePickerValue} - string representing the time in 24hr format;
 */
export const timeToString = (time: TimePickerTime | undefined): TimePickerValue | undefined => {
  if (!isValidTime(time)) {
    return;
  }

  if (time?.second !== undefined) {
    return `${zeroFill(time.hour)}:${zeroFill(time.minute)}:${zeroFill(time.second)}`;
  }

  return time ? `${zeroFill(time?.hour)}:${zeroFill(time?.minute)}` : undefined;
};

/**
 * Converts a Date object to a time picker time; Extracts the hour and minute from
 * the date object, and returns them in an object.
 * @param date {Date} - Date object with the time.
 * @return {TimePickerTime} - object with hour and minute in 24hr format
 */
export const dateToTime = (date: Date): TimePickerTime => ({
  hour: date.getHours(),
  minute: date.getMinutes(),
});

/**
 * converts a time picker time to a Date object; The date will be the current day,
 * and the time will be the time specified by the time picker time; returns undefined
 * if the time object is invalid;
 * @param time {TimePickerTime} - object with hour and minute in 24hr format
 * @return {Date} - returns a Date with the time set to the time param's time,
 *                  and the current date.
 */
export const timeToDate = (time: TimePickerTime | undefined): Date | undefined => {
  if (!isValidTime(time)) {
    return;
  }

  const d = new Date();

  d.setHours((time as TimePickerTime).hour);
  d.setMinutes((time as TimePickerTime).minute);
  d.setSeconds(0);
  d.setMilliseconds(0);

  return d;
};
