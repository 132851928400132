/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import instanceReducers from './Item/CorporatePublicIPsItemState';
import listReducers from './List/CorporatePublicIPsListState';

export default {
  corporatepublicips: combineReducers({
    ...instanceReducers,
    ...listReducers,
  }),
};
