/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {type ComponentPropsWithoutRef, PureComponent} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './Badge.css';
import {typesUtils} from '@illumio-shared/utils';
import {AppContext, type AppContextValue} from 'containers/App/AppUtils';

const badgeTextMap: Record<string, string> = {
  new: intl('Common.New'),
  preview: intl('Common.Preview'),
  created: intl('Common.Created'),
  updated: intl('Common.Updated'),
  deleted: intl('Common.Deleted'),
  disabled: intl('Common.Disabled'),
  info: intl('Common.Info'),
  low: intl('Common.Low'),
  medium: intl('Common.Medium'),
  high: intl('Common.High'),
  critical: intl('Common.Critical'),
  toBeDeprecated: intl('Common.ToBeDeprecated'),
} as const;

export const badges = Object.keys(badgeTextMap);

type Badges = keyof typeof badgeTextMap;

export type BadgeProps = ComponentPropsWithoutRef<'div'> &
  ThemeProps & {
    type: (typeof badgeTextMap)[Badges];
    children?: typesUtils.ReactStrictNode;
    className?: string;
    large?: boolean;
  };

export default class Badge extends PureComponent<BadgeProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/static-property-placement
  declare context: AppContextValue;

  render(): JSX.Element | null {
    const {type, children, theme, large, ...props} = mixThemeWithProps(styles, this.props);

    props.className = `${theme.badge} ${theme[type]}${large ? ` ${theme.large}` : ''}`;
    props['data-tid'] = `comp-badge ${type}`;

    return <div {...props}>{children || badgeTextMap[type]}</div>;
  }
}
