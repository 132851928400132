/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {generalUtils} from '@illumio-shared/utils/shared';

export type VulnerabilityGrid = {
  severityScore: number;
  vulnerabilityExposureScore: number;
  internetExposure?: boolean;
  exposureApplicable?: boolean;
};

type Vulnerability = {
  key: number;
  severity: 'critical' | 'high' | 'info' | 'low' | 'medium' | 'none';
  display: string;
  minIncl?: number;
  maxIncl?: number;
  minExcl?: number;
  maxExcl?: number;
};

export const vulnerabilities: Vulnerability[] = [
  {key: 0, severity: 'none', display: intl('Common.None'), minIncl: -generalUtils.int32, maxExcl: 0},
  {key: 1, severity: 'info', display: intl('Common.Info'), minIncl: 0, maxIncl: 0},
  {key: 2, severity: 'low', display: intl('Common.Low'), minExcl: 0, maxIncl: 4},
  {key: 3, severity: 'medium', display: intl('Common.Medium'), minExcl: 4, maxIncl: 7},
  {key: 4, severity: 'high', display: intl('Common.High'), minExcl: 7, maxIncl: 9},
  {key: 5, severity: 'critical', display: intl('Common.Critical'), minExcl: 9, maxIncl: generalUtils.int32},
];

export const getVulnerabilityByScore = (severityScore: number): Vulnerability => {
  for (const vulnerability of vulnerabilities) {
    const minInclusive = vulnerability.hasOwnProperty('minIncl');

    if (
      (minInclusive && vulnerability.minIncl! <= severityScore) ||
      (!minInclusive && vulnerability.minExcl! < severityScore)
    ) {
      const maxInclusive = vulnerability.hasOwnProperty('maxIncl');

      if (
        (maxInclusive && vulnerability.maxIncl! >= severityScore) ||
        (!maxInclusive && vulnerability.maxExcl! > severityScore)
      ) {
        return vulnerability;
      }
    }
  }

  return vulnerabilities[0];
};

export const genVulnerabilityScore = (vulnerability: Vulnerability): number | undefined => {
  const minInclusive = vulnerability.hasOwnProperty('minIncl');
  const maxInclusive = vulnerability.hasOwnProperty('maxIncl');

  if (minInclusive && maxInclusive) {
    return _.random(vulnerability.minIncl!, vulnerability.maxIncl!, true);
  }

  if (minInclusive && !maxInclusive) {
    return generalUtils.randomInclusiveToExclusive(vulnerability.minIncl!, vulnerability.maxExcl!);
  }

  if (!minInclusive && maxInclusive) {
    return generalUtils.randomExclusiveToExclusive(vulnerability.minExcl!, vulnerability.maxIncl!);
  }

  if (!minInclusive && !maxInclusive) {
    return generalUtils.randomExclusiveToExclusive(vulnerability.minExcl!, vulnerability.maxExcl!);
  }
};

export const roundNumber = (function () {
  const s = 10;
  const k = 1000;
  const m = 1_000_000;
  const b = 1_000_000_000;
  const t = 1_000_000_000_000;

  return (value: number) => {
    if (!value) {
      return value;
    }

    if (value < 0.1) {
      return 0.1;
    }

    if (value < s) {
      return Math.round(value * 10) / 10;
    }

    if (value < k) {
      return Math.round(value);
    }

    let quotient;
    let postfix;

    if (value <= m) {
      quotient = value / k;
      postfix = 'K';
    } else if (value <= b) {
      quotient = value / m;
      postfix = 'M';
    } else if (value <= t) {
      quotient = value / b;
      postfix = 'B';
    } else {
      quotient = value / t;
      postfix = 'T';
    }

    return (quotient < 100 ? Math.round(quotient * 10) / 10 : Math.round(quotient)) + postfix;
  };
})();

export const sortVulnerabilities = ({
  valueA,
  valueB,
  sortFactor,
}: {
  valueA: VulnerabilityGrid | undefined;
  valueB: VulnerabilityGrid | undefined;
  sortFactor: number;
}): number => {
  // Handle empty vulnerabilities
  if (!valueA && !valueB) {
    return 0;
  }

  if (!valueA) {
    return -1 * sortFactor;
  }

  if (!valueB) {
    return sortFactor;
  }

  // If the VES scores are the same, use the severity
  if (valueA.vulnerabilityExposureScore === valueB.vulnerabilityExposureScore) {
    // If the severity is also the same use the wide exposure
    if (valueA.severityScore === valueB.severityScore) {
      return valueA.internetExposure ? sortFactor : -1 * sortFactor;
    }

    return valueA.severityScore > valueB.severityScore ? sortFactor : -1 * sortFactor;
  }

  if (valueA.vulnerabilityExposureScore === null) {
    return -1 * sortFactor;
  }

  if (valueB.vulnerabilityExposureScore === null) {
    return sortFactor;
  }

  return valueA.vulnerabilityExposureScore > valueB.vulnerabilityExposureScore ? sortFactor : -1 * sortFactor;
};
