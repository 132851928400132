/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useRef, useCallback, useState, useEffect, type ReactElement} from 'react';
import type {Theme} from '@css-modules-theme/react';
import {Tooltip, Checkbox} from 'components';
import {domUtils, tidUtils, typesUtils} from '@illumio-shared/utils';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {Instance, PopperElement} from 'tippy.js';

type MouseEventCallback = (event: domUtils.MouseEventLike, id: number | string) => void;

type OptionProps = {
  theme: Theme;
  saveRef(id: number | string, element: HTMLLIElement | null): void;
  tid?: string;
  id: number | string;
  text: ReactElement;
  active?: boolean;
  checked?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  insensitive?: boolean;
  highlighted?: boolean;
  onClick: MouseEventCallback;
  onMouseOver: MouseEventCallback;
  onMouseLeave: domUtils.MouseEventLikeHandler;
  tooltip?: TooltipProps['content'];
  tooltipProps?: typesUtils.UnionOmit<TooltipProps, 'content'>;
  dropdownTippyInstance?: Element;
};

export default function Option(props: OptionProps): JSX.Element | null {
  const {
    theme,
    tid,
    id,
    text,
    active,
    disabled,
    hidden,
    insensitive,
    highlighted,
    onClick,
    onMouseOver,
    onMouseLeave,
    checked,
    tooltip,
    tooltipProps = {},
    saveRef,
    dropdownTippyInstance,
  } = props;

  const elementRef = useRef<HTMLElement | null>();
  const spanRef = useRef<HTMLSpanElement | null>();
  const popperElement = useRef<PopperElement>();
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);

  const saveRefCallback = useCallback(
    (element: HTMLLIElement) => {
      elementRef.current = element;
      saveRef(id, element);
    },
    [id, saveRef],
  );

  const {fast = false, instant = false, delay: [delayShow, delayHide] = [], ...restTooltipProps} = tooltipProps;
  const handleMouseOver = useCallback(
    (evt: domUtils.MouseEventLike) => {
      spanRef.current = elementRef.current?.querySelector('span');

      if (spanRef.current && spanRef.current.scrollHeight > spanRef.current.clientHeight) {
        setTooltipIsVisible(true);
      }

      if (elementRef.current) {
        if (!tooltipIsVisible && elementRef.current?.scrollHeight > elementRef.current?.clientHeight) {
          setTooltipIsVisible(true);
        }
      }

      onMouseOver(evt, id);
    },
    [id, onMouseOver, tooltipIsVisible],
  );
  const handleClick = useCallback((evt: domUtils.MouseEventLike) => onClick(evt, id), [id, onClick]);
  const handleTippyCreate = useCallback((instance: Instance) => (popperElement.current = instance.popper), []);

  useEffect(() => {
    // Set tooltip visible with a delay
    if (!tooltip || !highlighted) {
      //Skip if: option is not highlighted or no tooltip
      return;
    }

    const delay = instant ? 0 : fast ? 100 : delayShow ?? 500;

    // Add delay to show tooltip
    const showTooltipTimeout = setTimeout(() => {
      setTooltipIsVisible(true); // show tooltip after a delay
    }, delay);

    return () => clearTimeout(showTooltipTimeout);
  }, [tooltip, highlighted, fast, delayShow, instant]);

  useEffect(() => {
    // Hide tooltip with a delay
    if (!tooltipIsVisible || highlighted) {
      //Skip if: Tooltip was not visible Or option is still in highlighted
      return;
    }

    const delay = instant ? 0 : fast ? 250 : delayHide ?? delayShow ?? 0;

    // Add delay to show/hide tooltip
    const hideTooltipTimeout = setTimeout(() => {
      setTooltipIsVisible(false); //hide tooltip after a delay
    }, delay);

    return () => clearTimeout(hideTooltipTimeout);
  }, [tooltipIsVisible, highlighted, fast, instant, delayShow, delayHide]);

  if (hidden) {
    return null;
  }

  const isInsensitive = insensitive || disabled;

  const optionTheme = cx(theme.option, {
    [theme.active]: active && !highlighted,
    [theme.highlighted]: highlighted && !isInsensitive,
    [theme.disabled]: disabled,
    [theme.clickable]: !active && !isInsensitive,
  });

  const tids = [tid];

  if (disabled) {
    tids.push('disabled');
  }

  if (insensitive) {
    tids.push('insensitive');
  }

  if (!text) {
    return null;
  }

  return (
    <>
      <li
        data-tid={tidUtils.getTid('comp-selector-option', tids)}
        aria-controls={restTooltipProps.interactive ? popperElement.current?.id : undefined}
        aria-label={String(id)}
        className={optionTheme}
        tabIndex={-1}
        onClick={isInsensitive ? undefined : handleClick}
        ref={saveRefCallback}
        onMouseMove={isInsensitive ? undefined : handleMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {typeof checked === 'boolean' ? (
          <Checkbox notChangeable theme={theme} themePrefix="option-" checked={checked} label={text} />
        ) : (
          text
        )}
      </li>
      <Tooltip
        noSingleton
        reference={elementRef.current}
        content={tooltip || spanRef.current?.textContent || elementRef.current?.textContent}
        visible={tooltipIsVisible}
        appendTo={dropdownTippyInstance}
        onCreate={handleTippyCreate}
        {...restTooltipProps}
      />
    </>
  );
}
