/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {Component, createContext} from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {whenPropTypes} from '../FormUtils';
import styles from './Radio.css';
import stylesUtils from 'utils.css';

export const RadioContext = createContext(null);
export default class RadioGroup extends Component {
  static propTypes = {
    // Name that corresponds to your form's schema
    name: PropTypes.string.isRequired,
    // Selected Radio
    value: PropTypes.string,

    // Children can be either a function, that will get current name/values/form as arguments
    // and that is called on render to return any further renderable content that contains FormRadio,
    // or renderable content can be provided directly
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

    // By default state of group and values are automatically handled by FormRadio itself (uncontrolled).
    // If you want to intercept change event and handle it manually, pass onChange handler to make it controlled by parent component
    onChange: PropTypes.func,

    // By default radios are swrapped into a div with .gapLarge class.
    // Set noWrap to true to return radios as is, without any wrapper or styling
    noWrap: PropTypes.bool,
    // By default wrapper has .gapLarge class, set horizontal to true to also add .gapHorizontalWrap class
    horizontal: PropTypes.bool,
    // Or you can specify other gap classes or any other classes by simply passing className prop
    // Like <Form.RadioGroup className={`${styles.gapSmall} ${styles.gapAlbatross}`}> for smaller gaps in albatross line
    className: PropTypes.string,

    // Might depend on other fields
    ...whenPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      noWrap: false,
      horizontal: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // When onChange exist the event will bubble up to parent thus the parent will
    // pass down the updated nextProps (e.g. nextProps.value) thus it is possible for nextProps.value
    // to be empty while onChange exist to be a controlled component
    const controlled = typeof nextProps.onChange === 'function';

    if (controlled && nextProps.value !== prevState.value) {
      return {value: nextProps.value};
    }

    return null;
  }

  handleChange(evt, radioProps) {
    const {onChange} = this.props;

    this.setState({value: radioProps.value});

    if (onChange) {
      onChange(evt, radioProps);
    }
  }

  render() {
    const {
      props: {name, children, noWrap},
      state: {value},
    } = this;

    let content = typeof children === 'function' ? children(name, value) : children;

    if (!noWrap) {
      const {name, onChange, noWrap, horizontal, theme, children, ...groupElementProps} = mixThemeWithProps(
        styles,
        this.props,
      );

      groupElementProps.className ||= cx(stylesUtils.gapLarge, {[stylesUtils.gapHorizontalWrap]: horizontal});

      content = <div {...groupElementProps}>{content}</div>;
    }

    // Disabling Context Provider values check since we handle this at the getDerivedStateFromProps level.
    // As such, rerenders should only occur if value changes for controlled components.
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const context = {
      name,
      value,
      onChange: this.handleChange,
    };

    return <RadioContext.Provider value={context}>{content}</RadioContext.Provider>;
  }
}
