/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Link} from 'components';
import {hrefUtils} from '@illumio-shared/utils';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';

/**
[{
  header: string | Function,
  key: string | Function,
  value: string | Function,
  format: node | Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  isDate: boolean | string,
}];
*/
export const gridSettings = createSelector([], () => ({
  id: 'cclusterservicebackendlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    name: {
      header: intl('Common.Name'),
      value: 'name',
      required: true,
    },
    kind: {
      header: intl('Common.ResourceType'),
      value: 'kind',
    },
    namespace: {
      // header is dynamic generated based on container cluster type, which is defined in ContainerClusterContainerWorkloadProfileListState
      value: 'namespace',
    },
    virtualService: {
      linky: true,
      header: intl('Common.VirtualService'),
      value: ({row}) => (row.data.virtual_service ? row.data.virtual_service.name : null),
      format: ({value, row}) =>
        value ? (
          <Link
            to="virtualServices.item"
            params={{id: hrefUtils.getId(row.data.virtual_service.href), pversion: 'draft'}}
          >
            {value}
          </Link>
        ) : null,
    },
    updatedAt: {
      isDate: true,
      header: intl('Common.LastModifiedOn'),
      value: ({row}) => row.data.updated_at || row.data.created_at || null,
    },
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['name'], size: 'minmax(150px, auto)'},
      {columns: ['kind'], size: 'minmax(150px, auto)'},
      {columns: ['namespace'], size: 'minmax(200px, auto)'},
      {columns: ['virtualService'], size: 'minmax(200px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(200px, auto)'},
    ],
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['name', 'kind'], size: 'minmax(150px, auto)'},
            {columns: ['namespace'], size: 'minmax(150px, auto)'},
            {columns: ['virtualService'], size: 'minmax(200px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['name', 'kind'], size: 'minmax(150px, auto)'},
          {columns: ['namespace'], size: 'minmax(150px, auto)'},
          {columns: ['virtualService'], size: 'minmax(200px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['name', 'kind'], size: 'minmax(150px, auto)'},
            {columns: ['namespace', 'virtualService'], size: 'minmax(200px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['name', 'kind'], size: 'minmax(150px, auto)'},
          {columns: ['namespace', 'virtualService'], size: 'minmax(200px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['name', 'kind', 'namespace'], size: 'minmax(150px, auto)'},
            {columns: ['virtualService', 'updatedAt'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['name', 'kind', 'namespace'], size: 'minmax(150px, auto)'},
          {columns: ['virtualService', 'updatedAt'], size: 'minmax(200px, auto)'},
        ];
      },
    },
  ],
}));
