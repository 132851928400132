/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';

const CAPTURE_STACK_TRACE_SUPPORT = Boolean(Error.captureStackTrace);
const FIREFOX_ERROR_INFO = /@(.+?):(\d+):(\d+)$/;
const errorMsgs: Record<string, string> = {};

export type ApplicationErrorJSONRepresent = Pick<ApplicationError, 'code' | 'details' | 'message'> & {
  type: ApplicationError['name'];
};

export interface ApplicationErrorOptions {
  code?: string;
  message?: string;
  statusCode?: number;
  trace?: boolean;
}

/**
 * Thor main error type
 */
export default class ApplicationError extends Error {
  code: string;
  trace: boolean;
  statusCode: number;
  details?: Record<string, unknown>;

  fileName?: string;
  lineNumber?: number;
  columnNumber?: number;

  constructor(data: ApplicationErrorOptions | string = {}) {
    if (typeof data === 'string') {
      data = {code: data};
    }

    const {code = 'UNHANDLED_ERROR', message, statusCode = 500, trace = true, ...details} = data;

    super(message || errorMsgs[code] || code); // Native Error contructor accepts message
    this.name = 'ApplicationError';

    this.code = code;
    this.trace = trace;
    this.details = details as Record<string, unknown>;
    this.statusCode = statusCode;

    if (trace) {
      // Ensure we get a proper stack trace in most Javascript environments
      if (CAPTURE_STACK_TRACE_SUPPORT) {
        // V8 environments (Chrome and Node.js)
        Error.captureStackTrace!(this, ApplicationError);
      } else {
        // Firefox workaround
        const {stack} = new Error(this.message);

        if (stack) {
          // Skipping first line in stack (it's the line where we have create our `new Error`)
          const stacks = stack.split('\n').slice(1);

          // Trying to get file name, line number and column number from the first line in stack
          const [, fileName, lineNumber, columnNumber] = FIREFOX_ERROR_INFO.exec(stacks[0] || '') || [];

          this.stack = stacks.join('\n');
          this.fileName = fileName || undefined;
          this.lineNumber = lineNumber ? Number(lineNumber) : undefined;
          this.columnNumber = columnNumber ? Number(columnNumber) : undefined;
        }
      }
    }
  }

  toJSON(): ApplicationErrorJSONRepresent {
    const result: ApplicationErrorJSONRepresent = {
      type: this.name,
      code: this.code,
      message: this.message,
    };

    if (!_.isEmpty(this.details)) {
      result.details = this.details;
    }

    return result;
  }
}
