/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {sagaUtils} from '@illumio-shared/utils';
import {call, delay, spawn, take, put, select} from 'redux-saga/effects';
import {isUserWithReducedScope} from 'containers/User/UserState';
import {getCalcInProgress, getCalcInProgressOverride} from './ProvisioningState';
import {getProvisionCompleted} from './Provision/ProvisionState';
import {fetchProvisionVersions} from './Version/List/VersionListSaga';
import {fetchPendingObjectList, fetchPendingCount} from './Pending/List/PendingListSaga';

let pollingTask = null;

export function* fetchPending() {
  let needToRestartPolling = false;

  if (pollingTask && pollingTask.isRunning()) {
    // If there is polling, stop it to restart later after current fetch
    yield call(stopFetchPendingPolling);
    needToRestartPolling = true;
  }

  yield call(fetchPendingObjectList, {force: true});

  if (needToRestartPolling) {
    yield call(startFetchPendingPolling);
  }
}

export function* startFetchPendingPolling({instantStart = false} = {}) {
  if (!(yield select(isUserWithReducedScope)) && (!pollingTask || !pollingTask.isRunning())) {
    pollingTask = yield spawn(sagaUtils.startPolling, {
      interval: 15_000,
      instantStart,
      *saga() {
        yield call(fetchPendingCount, {priority: 0});
      },
    });
  }
}

export function* stopFetchPendingPolling() {
  if (pollingTask && pollingTask.isRunning()) {
    pollingTask.cancel();
    pollingTask = null;
  }
}

// Check that provisioning is in progress
// If current value is true, it is probably being checked every 2 seconds by checkProcessingActionListener
// and cached value will be immidiatly returned. Pass 'force' to override.
export function* checkCalcProgress({force = false} = {}) {
  let progressState = yield select(getCalcInProgress);

  if (progressState && !force) {
    return progressState;
  }

  const {data: ruleProgress} = yield call(apiSaga, 'dashboards.rule_calc_progress');

  yield put({type: 'PROVISION_CALC_PROGRESS', data: ruleProgress});

  progressState = yield select(getCalcInProgress);

  // If a progress override is set for Supercluster setups
  if (yield select(getCalcInProgressOverride)) {
    if (progressState) {
      // If the red_agents has become non empty, start relying on that and
      // Reset the override
      yield put({type: 'PROVISION_CALC_PROGRESS_OVERRIDE', data: false});
    }

    return true;
  }

  return progressState;
}

// Listen to action to check provisioning progress status.
// If api returns true, check every 2s until it becomes false, and resume listening to action after it
export function* checkProcessingActionListener() {
  while (yield take('PROVISION_CHECK_CALC_PROGRESS')) {
    while (!(yield select(isUserWithReducedScope)) && (yield call(checkCalcProgress, {force: true}))) {
      yield delay(2000);
    }
  }
}

export function* fetchProvisioning({params}) {
  yield call(fetchProvisionVersions); //fetch versions to get object id for 'view last commit' navigation

  let complete;

  //Populate provision complete banner on provisioning page
  if (params.complete) {
    //pop-up clicked from new pages
    try {
      complete = JSON.parse(params.complete); //params in new pages are stringified
    } catch (error) {
      console.warn(`Can not parse value (${params.complete}) from params`, error);
    }
  } else if (params.isComplete) {
    //pop-up clicked from old pages
    complete = {isComplete: true};

    if (params.workloadsAffected) {
      complete.workloadsAffected = 0;
    }
  } else {
    //Provisioning page is navigated from provision route, in this case clear selection so that back operation
    // redirect to pending list and isCompleteNothing flag to set to false to disable pop-up display in zero workload affected case
    yield put({type: 'PROVISION_SELECTION', data: {selection: {}}});
    complete = yield select(getProvisionCompleted);
  }

  //Take params to populate success banner on provisioning page when navigated from provisioning progress pop-up click
  yield put({type: 'PROVISION_COMPLETED', data: {...complete, showProvisionProgressPopup: false}});
}
