/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {isSuperclusterMember} from 'containers/User/UserState';
import {gridSettings} from './AccessRestrictionListConfig';
import {isAPIAvailable} from 'api/apiUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'ACCESS_RESTRICTIONS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'ACCESS_RESTRICTIONS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getAccessRestrictions = state => state.accessrestrictions.list;
export const getAccessRestrictionsCount = state => state.accessrestrictions.count;

const getAccessRestrictionRows = createSelector(getAccessRestrictions, accessRestrictions => {
  const hasWritePermissions = isAPIAvailable('access_restriction.delete');

  return accessRestrictions.map(item => ({
    key: item.href,
    selectable: hasWritePermissions || !isSuperclusterMember,
    removable: hasWritePermissions || !isSuperclusterMember,
    // Return access restriction data
    data: {...item},
  }));
});

export const getGridSettings = createSelector(
  [isSuperclusterMember, gridSettings],
  (isSuperclusterMember, gridSettings) => {
    const columns = {...gridSettings.columns};
    const hasWritePermissions = isAPIAvailable('access_restriction.delete');

    columns.checkboxes.disabled = !hasWritePermissions || isSuperclusterMember;

    return {...gridSettings, columns};
  },
);

export const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getAccessRestrictionRows,
  });

export const getAccessRestrictionsListPage = createSelector([getGrid, getAccessRestrictionsCount], (grid, count) => {
  const rowsMap = new Map();

  for (const row of grid.rows) {
    rowsMap.set(row.key, row);
  }

  const hasWritePermissions = isAPIAvailable('access_restriction.delete');

  return {grid, rowsMap, count, hasWritePermissions};
});
