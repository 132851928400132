/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {dateToTime, isValidTime} from './TimePicker/TimePickerUtils';
import _ from 'lodash';

export const schedulerFrequencyOptions = [
  {label: intl('Reports.Daily'), value: 'daily'},
  {label: intl('Reports.Weekly'), value: 'weekly'},
  {label: intl('Reports.Monthly'), value: 'monthly'},
];

export const daysOfWeekOptions = [
  {label: 'S', value: 'sunday'},
  {label: 'M', value: 'monday'},
  {label: 'T', value: 'tuesday'},
  {label: 'W', value: 'wednesday'},
  {label: 'T', value: 'thursday'},
  {label: 'F', value: 'friday'},
  {label: 'S', value: 'saturday'},
];

export const daysOfWeekIndexMap = daysOfWeekOptions.reduce((map, o, i) => ({...map, [o.value]: i}), {});

export const daysOfWeekList = daysOfWeekOptions.map(o => o.value);

export const daysOfMonthOptions = Array.from(Array.from({length: 31}).keys()).map(day => ({
  value: day + 1,
  label: String(day + 1),
}));

export const firstAndLastMonthOptions = [
  {label: 'first', value: 1},
  {label: 'last', value: 31},
];

/**
 * returns the number of days in a given month; it works by passing 0 as the date,
 * which returns the last day of the previous month.
 * @param year - year: 4-digit year (e.g. 2024, 2020, etc.)
 * @param month - month: indexed by 0 (e.g. 11 == December, 0 == January, etc.);
 * @return {number} - number of days in the month
 */
export const daysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

export const adjustDaysOfWeek = (daysOfWeek, offset) => {
  return daysOfWeek.map(day => {
    const dayIndex = daysOfWeekIndexMap[day];
    const utcDayIndex = (((dayIndex - offset) % daysOfWeekList.length) + daysOfWeekList.length) % daysOfWeekList.length;

    return daysOfWeekList[utcDayIndex];
  });
};

export const convertScheduleToLocal = ({scheduleTime, frequency, dayOfWeek, dayOfMonth}) => {
  const date = new Date(scheduleTime);
  let localDayOfWeek = dayOfWeek;
  let localDayOfMonth = dayOfMonth;

  if (frequency === 'monthly') {
    localDayOfMonth = date.getDate();
  } else if (frequency === 'weekly') {
    const dayOffset = date.getUTCDay() - date.getDay();

    localDayOfWeek = adjustDaysOfWeek(dayOfWeek, dayOffset);
  }

  return {
    scheduleTime,
    frequency,
    dayOfWeek: localDayOfWeek,
    dayOfMonth: localDayOfMonth,
  };
};

export const convertScheduleToUTC = ({scheduleTime, frequency, dayOfWeek, dayOfMonth}) => {
  const date = new Date();

  // set the timew
  date.setHours(scheduleTime.hour);
  date.setMinutes(scheduleTime.minute);
  date.setSeconds(0);
  date.setMilliseconds(0);

  let utcDayOfWeek = dayOfWeek;
  let utcDayOfMonth = dayOfMonth;

  if (frequency === 'monthly') {
    const maxDate = daysInMonth(date.getFullYear(), date.getMonth());

    date.setDate(Math.min(dayOfMonth, maxDate));
    utcDayOfMonth = date.getUTCDate();
  } else if (frequency === 'weekly') {
    const dayOffset = date.getDay() - date.getUTCDay();

    utcDayOfWeek = adjustDaysOfWeek(dayOfWeek, dayOffset);
  }

  return {
    scheduleTime: date.toISOString(),
    frequency,
    dayOfWeek: utcDayOfWeek,
    dayOfMonth: utcDayOfMonth,
  };
};

export const unSerializeApplyLabelRulesSettings = ({
  automatic_label_application_on_ven_activation: applyOnVenActivation = false,
  rule_based_label_maker_schedule_enabled: scheduleEnabled = false,
  rule_based_label_maker_schedule,
} = {}) => {
  const {schedule_time, frequency, day_of_week, day_of_month} = rule_based_label_maker_schedule ?? {};
  const schedule =
    frequency && schedule_time
      ? convertScheduleToLocal({
          scheduleTime: schedule_time,
          frequency,
          dayOfWeek: day_of_week,
          dayOfMonth: day_of_month,
        })
      : {};

  return {
    applyOnVenActivation,
    scheduleEnabled,
    scheduleTime: schedule.scheduleTime ? dateToTime(new Date(schedule.scheduleTime)) : undefined,
    scheduleFrequency: frequency,
    scheduleDayOfWeek: new Set(schedule.dayOfWeek ?? []),
    scheduleDayOfMonth: schedule.dayOfMonth,
  };
};

export const serializeApplyLabelRulesSettings = ({
  applyOnVenActivation: automatic_label_application_on_ven_activation,
  scheduleEnabled: rule_based_label_maker_schedule_enabled,
  scheduleTime: schedule_time,
  scheduleFrequency: frequency,
  scheduleDayOfWeek: day_of_week,
  scheduleDayOfMonth: day_of_month,
} = {}) => {
  const schedule = rule_based_label_maker_schedule_enabled
    ? convertScheduleToUTC({
        scheduleTime: schedule_time,
        frequency,
        dayOfWeek: [...day_of_week],
        dayOfMonth: day_of_month,
      })
    : undefined;

  return {
    automatic_label_application_on_ven_activation,
    rule_based_label_maker_schedule_enabled,
    ...(rule_based_label_maker_schedule_enabled
      ? {
          rule_based_label_maker_schedule: {
            frequency,
            schedule_time: schedule.scheduleTime,
            ...(frequency === 'weekly' ? {day_of_week: schedule.dayOfWeek ?? []} : {}),
            ...(frequency === 'monthly' ? {day_of_month: schedule.dayOfMonth} : {}),
          },
        }
      : {}),
  };
};

export const areApplyLabelRulesSettingsDirty = (updatedSettings, currentSettings) => {
  const serializedUpdated = serializeApplyLabelRulesSettings(updatedSettings);
  const serializedCurrent = serializeApplyLabelRulesSettings(currentSettings);

  return !_.isEqual(serializedUpdated, serializedCurrent);
};

export const areApplyLabelRulesSettingsValid = updatedSettings => {
  const {
    applyOnVenActivation,
    scheduleEnabled,
    scheduleFrequency,
    scheduleTime,
    scheduleDayOfWeek,
    scheduleDayOfMonth,
  } = updatedSettings;
  const applyOnVenActivationValid = typeof applyOnVenActivation === 'boolean';
  const scheduleEnabledValid = typeof scheduleEnabled === 'boolean';
  let scheduleValid = !scheduleEnabled;

  if (scheduleEnabled && scheduleFrequency === 'daily') {
    scheduleValid = isValidTime(scheduleTime);
  } else if (scheduleEnabled && scheduleFrequency === 'weekly') {
    scheduleValid = isValidTime(scheduleTime) && Boolean(scheduleDayOfWeek?.size);
  } else if (scheduleEnabled && scheduleFrequency === 'monthly') {
    scheduleValid =
      isValidTime(scheduleTime) &&
      Number.isInteger(scheduleDayOfMonth) &&
      scheduleDayOfMonth >= 1 &&
      scheduleDayOfMonth <= 31;
  }

  return applyOnVenActivationValid && scheduleEnabledValid && scheduleValid;
};

const zeroFill = value => String(value).padStart(2, '0');

const formatTime12h = time => {
  const ampm = time.hour >= 12 ? 'PM' : 'AM';
  const hour12 = time.hour === 0 ? 12 : time.hour > 12 ? time.hour - 12 : time.hour;

  return `${zeroFill(hour12)}:${zeroFill(time.minute)}${ampm}`;
};

const formatDayOfMonth = number => {
  if (number === 1) {
    return 'first day';
  }

  if (number === 31) {
    return 'last day';
  }

  if (number > 3 && number < 21) {
    return `${number}th`;
  }

  switch (number % 10) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

const formatDaysOfWeek = (days = []) => {
  return days.map(day => `${day.charAt(0).toUpperCase()}${day.slice(1, 3)}`).join(', ');
};

export const getScheduleDescription = applyLabelRulesSettings => {
  const {
    applyOnVenActivation,
    scheduleEnabled,
    scheduleFrequency,
    scheduleTime,
    scheduleDayOfWeek,
    scheduleDayOfMonth,
  } = applyLabelRulesSettings;

  if (applyOnVenActivation && !scheduleEnabled) {
    return intl('LabelRules.SchedulerDescriptionVenActivation');
  }

  if (scheduleEnabled && scheduleFrequency === 'daily') {
    return intl('LabelRules.SchedulerDescriptionDaily', {time: formatTime12h(scheduleTime), applyOnVenActivation});
  }

  if (scheduleEnabled && scheduleFrequency === 'weekly') {
    return intl('LabelRules.SchedulerDescriptionWeekly', {
      time: formatTime12h(scheduleTime),
      days: formatDaysOfWeek([...scheduleDayOfWeek]),
      applyOnVenActivation,
    });
  }

  if (scheduleEnabled && scheduleFrequency === 'monthly') {
    return intl('LabelRules.SchedulerDescriptionMonthly', {
      time: formatTime12h(scheduleTime),
      day: formatDayOfMonth(scheduleDayOfMonth),
      applyOnVenActivation,
    });
  }

  return '';
};
