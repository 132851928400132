/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Selector} from 'containers';
import {useCallback, useMemo, useRef, useState} from 'react';
import styles from './LabelRulesListEditExpression.css';
import {
  ExpressionSelectorFooter,
  expressionToSelectorValues,
  getAttributeAndOperatorFromSelectorValues,
  getSupportedAttributeOption,
  getSupportedOperatorOption,
  isSelectorValuesExpressionEmpty,
  selectorValuesToExpression,
} from './LabelRulesListEditExpressionUtils';
import {getExpressionSelectorCategories} from './LabelRulesListEditExpressionConfig';
import {resourceIds, attributeOptions, operatorOptions} from './LabelRulesListEditExpressionConstants';
import cx from 'classnames';

export default function LabelRulesListEditExpression({expression, onChange}) {
  const [{attribute: currentAttribute, operator: currentOperator}, setCurrentSelections] = useState({
    attribute: null,
    operator: null,
  });
  const [selectorOpen, setSelectorOpen] = useState(false);

  const containerRef = useRef(null);

  // convert the expression to a Map of selector values.
  const selectorValues = useMemo(
    () => expressionToSelectorValues({expression, currentAttribute, currentOperator}),
    [expression, currentAttribute, currentOperator],
  );

  const onClickCondition = useCallback(
    (event, {attribute, operator}) => {
      if (selectorOpen) {
        // don't close the selector on click of a condition pill
        event.stopPropagation();
      }

      setCurrentSelections({attribute, operator});
    },
    [selectorOpen],
  );

  // get the selector categories
  const expressionCategories = useMemo(
    () =>
      getExpressionSelectorCategories({
        currentAttribute,
        currentOperator,
        selectorValues,
        onClickCondition,
        selectorOpen,
        containerRef,
      }),
    [currentAttribute, currentOperator, selectorValues, onClickCondition, selectorOpen, containerRef],
  );

  const showPlaceholder = useMemo(() => {
    return !(selectorValues.get(resourceIds.expression) ?? []).some(item => !item.dummy);
  }, [selectorValues]);

  const handleExpressionOnSelectionChange = useCallback(
    values => {
      let {attribute, operator} = getAttributeAndOperatorFromSelectorValues(values);

      attribute = getSupportedAttributeOption(attribute);
      operator = getSupportedOperatorOption(attribute, operator);

      const {value: selectedAttribute = null} = attribute ?? {};
      const {value: selectedOperator = null} = operator ?? {};

      if (selectedAttribute !== currentAttribute || selectedOperator !== currentOperator) {
        setCurrentSelections({attribute: selectedAttribute, operator: selectedOperator});
      }

      const expression = selectorValuesToExpression(values) ?? {};

      onChange(expression);
    },
    [currentAttribute, currentOperator, onChange],
  );

  const onSelectorOpen = useCallback(() => {
    if (!selectorOpen) {
      const selections =
        currentAttribute && currentOperator
          ? {attribute: currentAttribute, operator: currentOperator}
          : {attribute: attributeOptions.hostname.id, operator: operatorOptions.is.id};

      setSelectorOpen(true);
      setCurrentSelections(selections);
    }
  }, [currentAttribute, currentOperator, selectorOpen]);

  const onSelectorClose = useCallback(() => {
    if (selectorOpen) {
      setSelectorOpen(false);
      setCurrentSelections({attribute: null, operator: null});
      containerRef.current?.setInputValue('');
    }
  }, [selectorOpen, containerRef]);

  const selectorValuesExpressionIsEmpty = useMemo(
    () => isSelectorValuesExpressionEmpty(selectorValues),
    [selectorValues],
  );

  return (
    <div className={cx(styles.container, {[styles.hideInput]: !selectorValuesExpressionIsEmpty})}>
      <Selector
        tid="expression-selector"
        themePrefix="expressionSelector-"
        theme={styles}
        footer={ExpressionSelectorFooter}
        hideClearAll={!selectorOpen}
        placeholder={showPlaceholder ? intl('LabelRules.ChooseCondition') : ''}
        inputProps={{readOnly: true}}
        values={selectorValues}
        categories={expressionCategories}
        onSelectionChange={handleExpressionOnSelectionChange}
        onOpen={onSelectorOpen}
        onClose={onSelectorClose}
        alwaysTakeValuesFromProps
        dropdownMinHeight={0}
      />
    </div>
  );
}
