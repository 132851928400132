/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';

export default {
  interfaces: combineReducers({
    map(state = {}, action) {
      switch (action.type) {
        case 'INTERFACES_GET_MAP':
          return action.data.interfaceMap;
        default:
          return state;
      }
    },
    forSwitch(state = [], action) {
      switch (action.type) {
        case 'INTERFACES_GET_FOR_SWITCH':
          return action.data.interfaceList;
        default:
          return state;
      }
    },
  }),
};

export const getInterfaces = state => state.interfaces.map;
export const getInterfacesForSwitch = state => state.interfaces.forSwitch;
