/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {createSelector} from 'reselect';
import {hrefUtils} from '@illumio-shared/utils';
import {isAPIAvailable} from 'api/apiUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getRouteName, getOrgSettings} from 'containers/App/AppState';
import {gridSettings, categories} from './VenListConfig';
import {getUserPermissions} from 'containers/User/UserState';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'VEN_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'VEN_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  vensMap(state = {}, action) {
    switch (action.type) {
      case 'VENS_GET_ITEM':
        if (action.item && !_.isEqual(action.item, state[action.item.href])) {
          return {...state, [action.item.href]: action.item};
        }

        return state;
      default:
        return state;
    }
  },
  agentsMap(state = {}, action) {
    switch (action.type) {
      case 'AGENTS_GET_ITEM':
        if (action.item && !_.isEqual(action.item, state[action.item.href])) {
          return {...state, [action.item.href]: action.item};
        }

        return state;
      default:
        return state;
    }
  },
  pending(state = 0, action) {
    switch (action.type) {
      case 'VENS_UPGRADE_PENDING_TRUE':
        return action.data;
      default:
        return state;
    }
  },
  healthError(state = 0, action) {
    switch (action.type) {
      case 'VENS_HEALTH_ERROR':
        return action.data;
      default:
        return state;
    }
  },
  active(state = 0, action) {
    switch (action.type) {
      case 'VENS_STATUS_ACTIVE':
        return action.data;
      default:
        return state;
    }
  },
  warning(state = 0, action) {
    switch (action.type) {
      case 'VENS_HEALTH_WARNING':
        return action.data;
      default:
        return state;
    }
  },
  suspended(state = 0, action) {
    switch (action.type) {
      case 'VENS_STATUS_SUSPENDED':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVens = state => state.ven.list;
export const getVensCount = state => state.ven.count;
export const getVensHrefMap = (state, isAgent) => (isAgent ? state.ven.agentsMap : state.ven.vensMap);
export const getVensUpgradePendingTrue = state => state.ven.pending;
export const getVensHealthError = state => state.ven.healthError;
export const getVensActive = state => state.ven.active;
export const getVensWarning = state => state.ven.warning;
export const getVensSuspended = state => state.ven.suspended;

const getVensRows = createSelector(getVens, vens =>
  vens.map(item => ({
    key: item.href,
    id: hrefUtils.getId(item.href),
    selectable: Array.isArray(item.caps) && item.caps.length > 0,
    data: {
      ...item,
      labelsMap: getMappedLabelsForGrid(item.labels),
    },
  })),
);

export const getCategories = createSelector([categories, getVensCount], (categories, count) => {
  if (!count.staticMode) {
    // If none of the workloads have static policy applied then
    // remove security settings static policy related filters
    categories = categories.reduce((result, category) => {
      if (category.id === 'security_policy_received_at' || category.id === 'security_policy_update_mode') {
        return result;
      }

      if (category.id === 'policy_health') {
        const statics = category.resources.policy_health.statics;

        result.push({
          ...category,
          resources: {
            policy_health: {statics: statics.filter(policyHealth => policyHealth !== intl('Common.Staged'))},
          },
        });

        return result;
      }

      result.push(category);

      return result;
    }, []);
  }

  return categories;
});

export const getFilterMap = createSelector([getCategories], categories =>
  categories.reduce((result, {resources}) => ({...result, ...resources}), {}),
);

export const getGridSettings = createSelector(
  [(_, props) => props.gridOnly, gridSettings, getRouteName, (_, props) => props?.hideGroupColumn],
  (gridOnly, gridSettings, routeName, hideGroupColumn) => {
    const clonedGrid = _.cloneDeep(gridSettings);
    const columns = {...clonedGrid.columns};

    columns.labels.disabled = hideGroupColumn;

    const settings = {...clonedGrid, columns};
    const zeroActionEnabled = !isAPIAvailable('vens.unpair') && !isAPIAvailable('vens.upgrade');

    if (!gridOnly && !zeroActionEnabled) {
      return settings; // leave settings alone
    }

    columns.checkboxes.disabled = gridOnly || routeName.includes('app.containerClusters'); // display no checkboxes for gridOnly mode, which hides controls

    return settings;
  },
);

const getGrid = (state, props) =>
  getGridSelector(state, {
    settings: () => getGridSettings(state, props),
    rows: getVensRows,
    filterMap: getFilterMap,
  });

export const getVensPage = createSelector(
  [
    (state, props) => getGrid(state, props),
    getVensCount,
    getVensUpgradePendingTrue,
    getVensHealthError,
    getVensActive,
    getVensWarning,
    getVensSuspended,
    getCategories,
    getOrgSettings,
    getUserPermissions,
  ],
  (
    grid,
    count,
    pending,
    healthError,
    active,
    warning,
    suspended,
    categories,
    {ven_maintenance_token_required},
    userPermissions,
  ) => {
    const rowsMap = new Map();

    for (const row of grid.rows) {
      rowsMap.set(row.key, row);
    }

    const generateTokenEnabled = userPermissions.some(permission => {
      const role = hrefUtils.getId(permission.role.href);

      return role === 'owner' || role === 'admin' || (role === 'workload_manager' && permission.scope.length === 0);
    });

    return {
      grid,
      rowsMap,
      count,
      pending,
      healthError,
      active,
      suspended,
      warning,
      categories,
      ven_maintenance_token_required,
      generateTokenEnabled,
    };
  },
);
