/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {ExpandableList, StatusIcon} from 'components';
import stylesUtils from 'utils.css';
import styles from './Detail/HealthDetail.css';
import {createSelector} from 'reselect';

export const dataUnits = ['terabyte', 'gigabyte', 'megabyte', 'kilobyte'];

/**
 * Provide defaults so that intl can interpolate the DaysHrsMinSec template.
 * @type {{weeks: number, days: number, hours: number, minutes: number, seconds: number}}
 */
const wdhmsDefaults = {weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0};

/**
 * Convert seconds into Weeks, Days, Hours, Minutes and Seconds
 *
 * @param valueInSeconds
 * @returns {{weeks: number, days: number, hours: number, minutes: number, seconds: number}}
 */
export const secondsToWDHMS = valueInSeconds => {
  const num = Number(valueInSeconds);
  const weeks = Math.floor(num / (3600 * 24 * 7));
  const days = Math.floor(num / (3600 * 24));
  const hours = Math.floor((num / 3600) % 24);
  const minutes = Math.floor((num % 3600) / 60);
  const seconds = Math.floor((num % 3600) % 60);

  return {weeks, days, hours, minutes, seconds};
};

/**
 * Return hours minutes seconds in string format
 * Show only minutes if <= 59 minutes
 * Show only hours if <= 23 hours
 * Show only days otherwise
 *
 * @param value       Seconds
 * @returns {string}  Formatted seconds
 */
export const getPceUptime = value => {
  const {days, hours, minutes} = secondsToWDHMS(value);
  let pceUptime;

  if (days) {
    pceUptime = intl('Health.DaysHrsMinSec', {...wdhmsDefaults, days});
  } else if (hours) {
    pceUptime = intl('Health.DaysHrsMinSec', {...wdhmsDefaults, hours});
  } else if (minutes) {
    pceUptime = intl('Health.DaysHrsMinSec', {...wdhmsDefaults, minutes});
  } else {
    pceUptime = intl('Health.DaysHrsMinSec', wdhmsDefaults);
  }

  return pceUptime;
};

/**
 * Converts seconds into a whole and partial unit of time with an upper bound of days and lower bound of seconds.
 *
 * Example results:
 *  5 days 2 hours
 *  1 hour 3 minutes
 *  30 seconds
 * @param secondsToConvert
 * @returns {string}
 */
export const getUnitOfTimeWithMultiple = secondsToConvert => {
  const {days, hours, minutes, seconds} = secondsToWDHMS(secondsToConvert);

  let convertedSeconds;

  if (days) {
    convertedSeconds = intl('Health.DaysHrsMinSec', {...wdhmsDefaults, days, hours});
  } else if (hours) {
    convertedSeconds = intl('Health.DaysHrsMinSec', {...wdhmsDefaults, hours, minutes});
  } else if (minutes) {
    convertedSeconds = intl('Health.DaysHrsMinSec', {...wdhmsDefaults, minutes, seconds});
  } else if (seconds) {
    convertedSeconds = intl('Health.DaysHrsMinSec', {...wdhmsDefaults, seconds});
  }

  return convertedSeconds;
};

/**
 * Based on a timestamp
 * return how long ago that was
 *
 * @param time String of timestamp
 * @return {time: integer, unit: string}  Object time and units
 */
export const getDuration = time => {
  const differenceInSeconds = (Date.now() - new Date(time).getTime()) / 1000;
  const {weeks, days, hours, minutes, seconds} = secondsToWDHMS(differenceInSeconds);

  return weeks
    ? {time: weeks, unit: intl('Health.Weeks', {weeks})}
    : days
    ? {time: days, unit: intl('Health.Days', {days})}
    : hours
    ? {time: hours, unit: intl('Health.Hours', {hours})}
    : minutes
    ? {time: minutes, unit: intl('Health.Minutes', {minutes})}
    : seconds
    ? {time: seconds, unit: intl('Health.Secs', {seconds})}
    : {};
};

/**
 * Format friendly date
 *
 * @param date String of ISO date
 * @return date  String of friendly date
 */
export const formatDate = date => intl.date(date, intl.formats.date.l_h_mm);

/**
 * 'Error', 'Warning', 'Inuse' corresponding icon colors
 * @type {{normal: string, warning: string, critical: string}}
 */
export const statusIcons = {
  normal: 'inuse',
  warning: 'warning',
  critical: 'error',
};

/**
 * Maps node status to intl'd display text.
 * @type {{normal: (string|ReactElement|*), warning: (string|ReactElement|*),
 * critical: (string|ReactElement|*), unknown: (string|ReactElement|*)}}
 */
export const statusDisplayText = createSelector([], () => ({
  normal: intl('Health.Normal'),
  warning: intl('Common.Warning'),
  critical: intl('Common.Critical'),
  unknown: intl('Common.Unknown'),
}));

/**
 * Displays node status with icon
 *
 * For normal (inuse) case we do not want to apply the icon color to the text. All other
 * cases will apply their icon color to the text unless it is of type unknown.
 *
 * @param status
 * @returns {string|ReactElement}
 */
export const getNodeStatusDisplayText = status => {
  switch (status) {
    case 'normal':
      return <StatusIcon theme={styles} status={statusIcons[status]} label={statusDisplayText()[status]} noTextColor />;
    case 'unknown':
      return intl('Common.Unknown');
    default:
      return <StatusIcon status={statusIcons[status]} label={statusDisplayText()[status]} />;
  }
};

export const getUsageBadge = value => {
  if (!value) {
    return;
  }

  const {status, percent} = value;

  return (
    <StatusIcon
      theme={styles}
      status={statusIcons[status]}
      label={intl('Health.Percent', {val: percent / 100})}
      noTextColor={status === 'normal'}
    />
  );
};

/**
 * Depending on the node_type, return the appropriate human readable type
 *
 * @param string        one of 'core0', 'core1', 'core', 'app', 'data0', 'data1', 'snc0', 'virtual_appliance0'
 * @param role          one of 'primary', 'replica'
 * @return {string}     Friendly UI string
 */
export const getNodeTypeString = (string, role) => {
  if (string.includes('core')) {
    // TODO: handle 'app' node type
    return intl('Health.CoreNode');
  }

  // At the moment we only support one more data node, so this is most likely always going to be data1
  if (string.includes('data')) {
    return `${_.upperFirst(string)} ${intl('Health.Node', {role})}`;
  }

  if (string === 'snc0') {
    return intl('Health.SNC');
  }

  if (string === 'virtual_appliance0') {
    return intl('Health.VirtualAppliance');
  }

  if (string === 'citus_coordinator') {
    return intl('Health.CoordinatorNode', {role});
  }

  if (string === 'citus_worker') {
    return intl('Health.WorkerNode', {role});
  }
};

/**
 * Convert backend returned non intlized service status to intlized string
 *
 * @param string      Status of the service
 * @return {string}   Intlized Status
 */
export const getServiceStatus = string => {
  switch (string) {
    case 'running':
      return intl('Common.Running');
    case 'not_running':
      return intl('Health.NotRunning');
    case 'partial':
      return intl('Health.Partial');
    case 'optional':
      return intl('Common.Optional');
    case 'unknown':
      return intl('Common.Unknown');
    default:
      // The status is empty if the service doesn't run on the specific node
      return '';
  }
};

export const getDatabaseReplicationlag = value => {
  if (!value) {
    return <StatusIcon status="warning" label={intl('Common.Unknown')} />;
  }

  const {status, lag_seconds: seconds} = value;

  return (
    <StatusIcon
      theme={styles}
      status={statusIcons[status]}
      label={intl('Health.Seconds', {seconds})}
      noTextColor={status === 'normal'}
    />
  );
};

/**
 * Return an array of Health messages (or notifications) to be showed in
 * the Health Detail page
 *
 * @param pceData   Object
 */
export const generateMessages = pceData => {
  // pceData Object contains multiple fields with the key "status".
  // For the fields whose "status" is not normal, synthetically add a
  // message (or notification) for UI display purposes.
  //
  // The API only returns notifications which cannot be inferred
  // from the response (like time drift between nodes,
  // incorrect cluster type configuration, etc.).
  // The purpose of this method is to generate an overall summary of all
  // the PCE attributes which are in bad state. The overall summary is
  // generated in the form of English messages which are then displayed in
  // the PCE Health detail page — along with the notifications returned by the API.
  const additionalNotifications = [];
  const normalStatus = {status: 'normal'};
  // Not all pceData Objects will have the network key
  const networkReplication = pceData.network && pceData.network.replication;

  if (_.get(pceData, 'nodes', []).length) {
    pceData.nodes.forEach(node => {
      const {cpu = normalStatus, disk = [{value: normalStatus}], memory = normalStatus, services = normalStatus} = node;
      const nodeHostname = _.upperFirst(node.hostname);

      if (node.runlevel !== 5) {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeRunlevelNotFive', {
            node: nodeHostname,
          }),
        });
      }

      if (cpu.status !== 'normal') {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeCpuStatus', {
            node: nodeHostname,
            status: statusDisplayText()[cpu.status] && statusDisplayText()[cpu.status].toLowerCase(),
          }),
        });
      }

      if (disk.some(({value = {}}) => value.status !== 'normal')) {
        let diskStatus = '';

        disk.forEach(({value = {}}) => {
          if (value.status === 'warning') {
            diskStatus = 'warning';
          }

          if (value.status === 'critical') {
            diskStatus = 'critical';
          }
        });

        additionalNotifications.push({
          message: intl('Health.Notifications.NodeDiskStatus', {
            node: nodeHostname,
            status: statusDisplayText()[diskStatus] && statusDisplayText()[diskStatus].toLowerCase(),
          }),
        });
      }

      if (memory.status !== 'normal') {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeMemoryStatus', {
            node: nodeHostname,
            status: statusDisplayText()[memory.status] && statusDisplayText()[memory.status].toLowerCase(),
          }),
        });
      }

      if (services.status !== 'normal') {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeServicesStatus', {
            node: nodeHostname,
            status: statusDisplayText()[services.status] && statusDisplayText()[services.status].toLowerCase(),
          }),
        });
      }
    });
  }

  if (networkReplication && networkReplication.some(({value = {}}) => value.status && value.status !== 'normal')) {
    let replicationStatus = '';

    networkReplication.forEach(({value = {}}) => {
      if (value.status === 'warning') {
        replicationStatus = 'warning';
      }

      if (value.status === 'critical') {
        replicationStatus = 'critical';
      }
    });

    additionalNotifications.push({
      message: intl('Health.Notifications.NodeNetworkReplicationStatus', {
        status: statusDisplayText()[replicationStatus] && statusDisplayText()[replicationStatus].toLowerCase(),
      }),
    });
  }

  return additionalNotifications;
};

export const getPceHealth = (status, showMemberRollingUpgradeStatus) =>
  showMemberRollingUpgradeStatus ? (
    intl('Common.Unavailable')
  ) : (
    <StatusIcon
      theme={styles}
      status={statusIcons[status]}
      label={_.capitalize(status)}
      noTextColor={status === 'normal'}
    />
  );

export const getUpgradeStatus = status => (
  <StatusIcon
    theme={styles}
    status={status ? statusIcons.warning : statusIcons.normal}
    label={status ? 'Pending' : 'Complete'}
    noTextColor={!status}
  />
);

export const getIllumincationSync = timestamp => {
  if (!timestamp) {
    return intl('Common.Never');
  }

  const dateObj = new Date(timestamp);

  // If timestamp is before a week ago, show the timestamp as is in a certain format
  if (dateObj < intl.utils.subtractTime(new Date(), 'd', 7)) {
    return intl.date(dateObj, intl.formats.date.L_HH_mm);
  }

  const differenceInSeconds = (Date.now() - dateObj.getTime()) / 1000;

  // Show 'Just Now' for less than sixty seconds ago
  if (differenceInSeconds < 60) {
    return intl('Map.JustNow');
  }

  return intl('Health.Ago', {
    time: getPceUptime(differenceInSeconds),
  });
};

export const sectionTitle = (section, divider = true) => {
  const title = [];

  if (divider) {
    title.push({divider});
  }

  title.push({title: section});

  return title;
};

export const getPceUptimeWithTitle = value => {
  const pceUptime = getPceUptime(value);
  // Calculates and displays the difference between a timestanmp and the current time as a title.
  const upSinceDateTitle = intl('Health.UpSinceDate', {when: new Date(Date.now() - value * 1000)});

  return <span title={upSinceDateTitle}>{pceUptime}</span>;
};

// Formatting API PCE type values
export const pceType = createSelector([], () => ({
  leader: intl('Health.Leader'),
  member: intl('Health.Member'),
}));

// Mapping of DB names to readable display text
export const replicationDbNamesMap = createSelector([], () => ({
  policy_db: intl('Health.PolicyDatabase'),
  traffic: intl('Health.TrafficDatabase'),
  reporting: intl('Health.ReportingDatabase'),
  citus_coordinator: intl('Health.CoordinatorNodes'),
  citus_worker: intl('Health.WorkerNodes'),
}));

/**
 * Give a pce data, determine if member is in a rolling upgrade with specific status
 *
 * @param inUpgrade  boolean
 * @param pceData    object
 * @returns {Boolean}  true - user is member in rolling upgrade, false - user is not a member and not in rolling upgrade
 */
export const isRollingUpgradeUnknown = (inUpgrade, pceData) =>
  inUpgrade && pceData.type === 'unknown' && pceData.status === 'critical';

/**
 * Creates generic node info object for Health Detail section with provided title
 *
 * @param title node info section title
 * @returns {{cpuUsage: Array, memoryUsage: Array, nodeRunlevel: Array, count: number,
 * nodeUptime: Array, header: Array, diskUsage: Array, title: *, nodeStatus: Array}}
 */
export const createNodeInfoObject = title => ({
  title,
  count: 0,
  header: [],
  nodeUptime: [],
  nodeRunlevel: [],
  nodeStatus: [],
  cpuUsage: [],
  memoryUsage: [],
  diskUsage: [],
  metrics: [],
});

export const formatNotifications = notifications => {
  if (notifications?.length > 0) {
    return (
      <ExpandableList values={notifications} modalTitle={intl('Common.Notifications')}>
        {({message}, index) => (
          <div className={cx(styles.messages, stylesUtils.paddingXSmallBottom)} key={index}>
            {message}
          </div>
        )}
      </ExpandableList>
    );
  }

  return null;
};

// Use to get 00:00 format in a date or 00 in a time unit
function pad(value) {
  return String(value).padStart(2, '0');
}

// Returns UTC offset like (-8:00)
function getDateOffset(date) {
  const sign = date.getTimezoneOffset() > 0 ? '-' : '+';
  const offset = Math.abs(date.getTimezoneOffset());
  // Dividing by 60min to get offset since getTimezoneOffset returns minutes from offset
  const hours = Math.floor(offset / 60);
  // In case of 30min or non whole hour offsets like Pacific/Marquesas
  const minutes = offset % 60;

  return `${sign}${pad(hours)}:${pad(minutes)}`;
}

// Formats a health clusters "last generated at (health check)" timestamp to help troubleshoot.
// Displays date in local time formatted in yyyy-mm-dd, hr:min:sec (utc offset) in e.g. 2021-03-23, 14:42:53 (-8:00)
export const formatLastHealthGeneratedTimestamp = timestamp => {
  if (!timestamp) {
    return intl('Health.UnableToGetLastGeneratedTS');
  }

  try {
    const date = new Date(timestamp);
    const day = date.getDate();
    // Month starts at 0 so add one to get to calendar month
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const offset = getDateOffset(date);

    return `${year}-${pad(month)}-${pad(day)}, ${pad(hours)}:${pad(minutes)}:${pad(seconds)} (${offset})`;
  } catch (error) {
    console.error(error);

    return intl('Health.UnableToGetLastGeneratedTS');
  }
};
