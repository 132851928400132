/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';

// Get Service Definition instructions
export const getServiceDefinitionInstructions = os => [
  ...(os === 'windows_egress'
    ? []
    : [
        {
          header: intl('Services.Edit.Info.PortInstruction'),
          message: (
            <>
              443 {intl('Protocol.TCP')}
              <br />
              1000-2000 {intl('Protocol.UDP')}
            </>
          ),
        },
        {
          header: intl('Services.Edit.Info.ProtocolInstruction'),
          message: intl.list(
            [
              intl('Protocol.GRE'),
              intl('Protocol.ICMP'),
              intl('Protocol.ICMPv6'),
              intl('Protocol.IGMP'),
              intl('Protocol.IPIP'),
              intl('Protocol.IPv4'),
              intl('Protocol.IPv6'),
            ],
            {style: 'narrow'},
          ),
        },
        {
          header: intl('Services.Edit.Info.ICMPInstruction'),
          message: (
            <>
              8 {intl('Protocol.ICMP')}
              <br />
              3/2 {intl('Protocol.ICMP')}
              <br />
              133 {intl('Protocol.ICMPv6')}
            </>
          ),
        },
      ]),
  ...(os === 'linux'
    ? []
    : [
        {
          header: intl('Services.Edit.Info.ProcessPathInstruction'),
          message: (
            <>
              {intl('Port.WindowsServiceExample')}
              <br />
              {intl('Port.WindowsServiceExampleUNC')}
              <br />
              {intl('Port.WindowsServiceExampleWithSystemEnvVariable')}
            </>
          ),
        },
        {
          header: intl('Services.Edit.Info.ProcessNameInstruction'),
          message: intl('Services.Edit.Info.InstructionsExample.ProcessName'),
        },
      ]),
];
