/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {type ChangeEvent, useCallback, useContext, useMemo} from 'react';
import {useSelector} from '@illumio-shared/utils/redux';
import {AppContext} from 'containers/App/AppUtils';
import {Checkbox, Button} from 'components';
import {getUnfilteredQuickFilters, getMapRouteParams, getPolicyVersion} from '../../MapState';
import MapToolBarButton from '../Button/MapToolBarButton';
import {getQuickFilterApi} from '../../Utils/MapTrafficQueryRequestUtils';
import type {RouteParams} from '../../MapTypes';
import styles from './MapQuickFilter.css';
import type {PolicyVersion} from 'containers/IlluminationMap/MapPolicyUtils';
import {didAnyGlobalQuickFiltersChange} from './MapQuickFilterUtils';

export type ConnectionQuickFilterValue =
  | 'allowed'
  | 'blocked'
  | 'broadcast'
  | 'inbound'
  | 'intrascope'
  | 'iplist'
  | 'multicast'
  | 'outbound'
  | 'potentiallyblocked'
  | 'private'
  | 'public'
  | 'unicast'
  | 'unknown';

export type ConnectionQuickFilterApiKey = 'ip_property' | 'none' | 'policy' | 'transmission';
export type ConnectionQuickFilterApi = {
  ip_property?: string;
  transmission?: string;
  draft_policy_decision?: string;
  reported_policy_decision?: string;
};

export type ConnectionQuickFilter = {
  label: string;
  value: ConnectionQuickFilterValue;
  apiKey: ConnectionQuickFilterApiKey;
};

const connectionQuickFilterBaseOptions: ConnectionQuickFilter[] = [
  {label: intl('Common.Blocked'), value: 'blocked', apiKey: 'policy'},
  {label: intl('Common.PotentiallyBlocked'), value: 'potentiallyblocked', apiKey: 'policy'},
  {label: intl('Common.Unknown'), value: 'unknown', apiKey: 'policy'},
  {label: intl('Common.IPList'), value: 'iplist', apiKey: 'ip_property'},
  {label: intl('Common.PrivateAddress'), value: 'private', apiKey: 'ip_property'},
  {label: intl('Common.PublicAddress'), value: 'public', apiKey: 'ip_property'},
  {label: intl('Map.Traffic.Unicast'), value: 'unicast', apiKey: 'transmission'},
  {label: intl('Map.Traffic.Broadcast'), value: 'broadcast', apiKey: 'transmission'},
  {label: intl('Map.Traffic.Multicast'), value: 'multicast', apiKey: 'transmission'},
  {label: intl('Common.Inbound'), value: 'inbound', apiKey: 'none'},
  {label: intl('Common.Outbound'), value: 'outbound', apiKey: 'none'},
  {label: intl('Map.Traffic.Filter.AllowIntraGroup'), value: 'intrascope', apiKey: 'none'},
];

export const connectionQuickFilterOptions: ConnectionQuickFilter[] = [
  {label: intl('Common.Allowed'), value: 'allowed', apiKey: 'policy'},
  ...connectionQuickFilterBaseOptions,
];

export const getConnectionQuickFilterOptionValues: (
  blockedTraffic?: boolean,
) => ConnectionQuickFilterValue[] = blockedTraffic =>
  (blockedTraffic ? connectionQuickFilterBaseOptions : connectionQuickFilterOptions).map(({value}) => value);

export const getFiltersWithoutExclusions = (
  current: Set<ConnectionQuickFilterValue>,
  exclude: Set<ConnectionQuickFilterValue>,
): Set<ConnectionQuickFilterValue> => {
  const filters = new Set(current);

  [...exclude].forEach(filter => filters.delete(filter));

  return filters;
};

export const getExcludedFilters = (policyItem: string, policy: PolicyVersion): Set<ConnectionQuickFilterValue> => {
  const excluded: ConnectionQuickFilterValue[] = policy === 'draft' ? ['unknown'] : [];

  if (policyItem === 'denyRules') {
    return new Set([...excluded, 'allowed']);
  }

  return new Set(excluded);
};

export default function MapQuickFilter({
  onChange,
  excludeFilters,
  blockedTraffic = false,
}: {
  onChange: (apiFilters: ConnectionQuickFilterApi) => void;
  excludeFilters: Set<ConnectionQuickFilterValue>;
  blockedTraffic?: boolean;
}): JSX.Element {
  const {dispatch, navigate} = useContext(AppContext);
  const {display} = useSelector(getMapRouteParams) as RouteParams;
  const policy = useSelector(getPolicyVersion);
  const unfilteredSelections: Set<ConnectionQuickFilterValue> = useSelector(getUnfilteredQuickFilters);

  const selections = useMemo(
    () => getFiltersWithoutExclusions(unfilteredSelections, excludeFilters),
    [unfilteredSelections, excludeFilters],
  );
  const savedExclusions = useMemo(
    () => new Set([...excludeFilters].filter(filter => unfilteredSelections.has(filter))),
    [excludeFilters, unfilteredSelections],
  );

  const allChecked = useMemo(
    () =>
      getConnectionQuickFilterOptionValues(blockedTraffic).every(
        value => selections.has(value) || excludeFilters.has(value),
      ),
    [selections, excludeFilters, blockedTraffic],
  );

  const updateConnectionDirections = useCallback(
    (newSelections: Set<ConnectionQuickFilterValue>, oldSelections: Set<ConnectionQuickFilterValue>) => {
      const filteredOldSelections = getFiltersWithoutExclusions(oldSelections, excludeFilters);
      const filteredNewSelections = getFiltersWithoutExclusions(newSelections, excludeFilters);
      const oldApiFilters = getQuickFilterApi(filteredOldSelections, policy) as ConnectionQuickFilterApi;
      const apiFilters = getQuickFilterApi(filteredNewSelections, policy) as ConnectionQuickFilterApi;

      dispatch({type: 'MAP_SET_QUICK_FILTERS', data: filteredNewSelections});
      dispatch({type: 'MAP_SET_UNFILTERED_QUICK_FILTERS', data: new Set([...savedExclusions, ...newSelections])});

      if (didAnyGlobalQuickFiltersChange(filteredOldSelections, filteredNewSelections)) {
        dispatch({type: 'MAP_OPEN_COMBOS_CLEAR_HISTORY'});
      }

      if (!_.isEqual(apiFilters, oldApiFilters)) {
        navigate({params: {offset: 0}, replace: true});

        onChange(apiFilters);
      }
    },
    [navigate, dispatch, onChange, policy, excludeFilters, savedExclusions],
  );

  const handleAllCheckboxChange = useCallback(() => {
    updateConnectionDirections(new Set(getConnectionQuickFilterOptionValues(blockedTraffic)), new Set(selections));
  }, [selections, updateConnectionDirections, blockedTraffic]);

  const handleCheckboxChange = useCallback(
    (_event: ChangeEvent, checked: boolean, _options: unknown, {value}: {value: ConnectionQuickFilterValue}) => {
      const oldSelections = new Set(selections);

      if (checked) {
        selections.add(value);
      } else {
        selections.delete(value);
      }

      updateConnectionDirections(new Set(selections), oldSelections);
    },
    [selections, updateConnectionDirections],
  );

  const renderDropdownContent = useCallback(() => {
    return (
      <div className={styles.dropdownContainer}>
        <div className={styles.optionsContainer}>
          <div className={styles.title}>{intl('IlluminationMap.GlobalFilters')}</div>
          {(blockedTraffic ? connectionQuickFilterBaseOptions : connectionQuickFilterOptions).map(({label, value}) => (
            <div key={value}>
              {value === 'inbound' && display === 'map' ? (
                <>
                  <div className={styles.gap} />
                  <div className={styles.title}>{intl('IlluminationMap.SelectedGroupTrafficFilters')}</div>
                </>
              ) : null}
              {value === 'unicast' || value === 'iplist' ? <div className={styles.divider} /> : null}
              {!excludeFilters.has(value) &&
                (display === 'map' || (value !== 'inbound' && value !== 'outbound' && value !== 'intrascope')) && (
                  <Checkbox
                    theme={styles}
                    themePrefix="filterOption-"
                    label={label}
                    value={value}
                    tid={`quick-filter-${value}`}
                    checked={selections.has(value)}
                    onChange={handleCheckboxChange}
                  />
                )}
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <Button
            text={intl('Common.ResetToDefault')}
            onClick={handleAllCheckboxChange}
            disabled={allChecked}
            tid="reset-quick-filters"
          />
        </div>
      </div>
    );
  }, [display, selections, handleCheckboxChange, handleAllCheckboxChange, allChecked, excludeFilters, blockedTraffic]);

  return (
    <div className={cx(styles.container, {filterSelected: true})}>
      <MapToolBarButton
        text={intl('Common.Filter')}
        tid="quick-filter"
        mode="custom-dropdown"
        theme={styles}
        color="primary"
        beforeIcon={allChecked ? undefined : 'filter'}
        renderContent={renderDropdownContent}
      />
    </div>
  );
}
