/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getRouteParams, getRouteCurrentParams, isSupercluster} from 'containers/App/AppState';
import {getFQDNList} from 'containers/Health/HealthState';
import {isUserReadOnly, isUserScoped} from 'containers/User/UserState';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {superclusterGridSettings, nonSuperclusterGridSettings} from './EventsConfigConfig';
import {secondsToDays} from '../EventsUtils';

export default {
  settings(state = [], action) {
    switch (action.type) {
      case 'EVENTS_GET_SETTINGS':
        return action.data.settings;
      case 'UPDATE_EVENTS_SETTINGS':
        return action.settings;
      default:
        return state;
    }
  },

  destinations(state = [], action) {
    switch (action.type) {
      case 'EVENTS_GET_DESTINATIONS':
        return action.data.destinations;
      default:
        return state;
    }
  },

  syslogEnabled(state = false, action) {
    switch (action.type) {
      case 'EVENTS_GET_DESTINATIONS':
        return true;
      case 'EVENTS_GET_DESTINATIONS_FAILED':
        return false;
      default:
        return state;
    }
  },
};
export const getGridSettings = createSelector(
  [isSupercluster, superclusterGridSettings, nonSuperclusterGridSettings],
  (isSupercluster, superclusterGridSettings, nonSuperclusterGridSettings) =>
    isSupercluster ? superclusterGridSettings : nonSuperclusterGridSettings,
);

export const getSettings = state => state.events.settings;
export const getDestinations = state => state.events.destinations;
export const getSyslogEnabled = state => state.events.syslogEnabled;

const getDestinationsRows = createSelector([getDestinations, isUserReadOnly], (destinations, userIsReadOnly) =>
  destinations.map(item => ({
    key: item.href,
    selectable: !userIsReadOnly,
    removable: !userIsReadOnly,
    data: item,
  })),
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getDestinationsRows,
  });

export const getEventSettings = createSelector(
  [getGrid, getSettings, getDestinations, getRouteCurrentParams, getSyslogEnabled, isUserReadOnly],
  (grid, data, destinations, {mode}, syslogEnabled, userIsReadOnly) => {
    const settings = {
      severity: data.audit_event_min_severity,
      retentionPeriod: secondsToDays(data.audit_event_retention_seconds),
      format: data.format,
      destinations,
      syslogEnabled,
    };

    return {isEdit: mode === 'edit', settings, grid, userIsReadOnly};
  },
);

export const getEventDestinations = createSelector(
  [getDestinations, getEventSettings, getRouteParams, getFQDNList],
  (data, settings, routeParams, fqdnList) => {
    let destinations = data;
    const currentDestination = routeParams.id ? destinations.find(dest => dest.href.endsWith(routeParams.id)) : null;

    if (currentDestination) {
      destinations = data.filter(dest => dest !== currentDestination);
      // find current destination and move it to the front so it doesn't get filtered out after uniq
      destinations.unshift(currentDestination);
    }

    let localDestination = destinations.find(destination => destination.type === 'local_syslog');

    // if there's no destination with Local repo, prepend a local destination so user can still select it
    if (!localDestination) {
      const local = {description: 'Local', type: 'local_syslog', href: 'local'};

      destinations.unshift(local);
      localDestination = local;
    }

    // unique destinations by port/protocol/address to remove duplicates from page's repo options
    destinations = _.uniqBy(destinations, obj =>
      obj.remote_syslog
        ? obj.remote_syslog.port + obj.remote_syslog.protocol + obj.remote_syslog.address
        : obj.description,
    ).sort((a, b) => {
      if (a.description === 'Local') {
        return -1;
      }

      if (b.description === 'Local') {
        return 1;
      }

      return a.description.localeCompare(b.description);
    });

    const destinationMap = destinations.reduce((result, destination) => {
      result[destination.href] = {...destination};

      return result;
    }, {});

    // use parent settings' severity. temporary
    const severity = settings && settings.settings && settings.settings.severity;

    return {destinationMap, destinations, currentDestination, severity, localDestination, pceList: fqdnList};
  },
);

export const isEventsConfigEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
