/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getAllUsersMap, isUserReadOnly} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './VersionListConfig';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {
  getPendingProvisioningRows,
  getCounts,
  getTotalCount,
} from 'containers/Provisioning/Pending/List/PendingListState';
import {isKubernetesSupported} from 'containers/App/AppState';

export default {
  versionList(state = [], action) {
    switch (action.type) {
      case 'PROVISION_VERSIONS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
};

export const getProvisionVersions = state => state.provisioning.versionList;

export const getVersionsRows = createSelector(
  [getProvisionVersions, getCounts, getTotalCount, getAllUsersMap, isUserReadOnly],
  (provisionVersions, counts, totalCount, usersMap, userIsReadOnly) => {
    const processedRows = provisionVersions.map(item => ({
      key: item.href,
      // Fill each Version item with user object
      data: {
        ...item,
        object_counts: {
          ...item.object_counts,
          // collapse these fields in to a single settings object
          settings: (item.object_counts?.firewall_settings ?? 0) + (item.object_counts?.essential_service_rules ?? 0),
        },
        created_by: fillUserInfo(usersMap, item.created_by),
        userIsReadOnly,
      },
    }));
    let last = 1;

    if (processedRows.length > 0) {
      processedRows[0].data.special = 'active';
      last += processedRows[0].data.version;
    }

    if (totalCount > 0) {
      // add draft row if applicable
      processedRows.push({
        key: 'draft',
        data: {
          version: last,
          created_by: {},
          object_counts: {
            groups: counts.rule_sets - 1 || 0,
            rule_sets: counts.rule_sets || 0,
            ip_lists: counts.ip_lists || 0,
            services: counts.services || 0,
            settings: counts.settings || 0,
            label_groups: counts.label_groups || 0,
            virtual_servers: (!__ANTMAN__ && counts.virtual_servers) || 0,
            secure_connect_gateways: counts.secure_connect_gateways || 0,
            virtual_services: (isKubernetesSupported && counts.virtual_services) || 0,
            enforcement_boundaries: counts.enforcement_boundaries || 0,
          },
          special: 'draft',
        },
      });
    }

    return processedRows;
  },
);

export const getGridSettings = createSelector([gridSettings], gridSettings => {
  const columns = {...gridSettings.columns};

  columns.enforcementBoundaryRules.disabled = false;
  columns.workloads.disabled = false;
  columns.outbound.disabled = true;
  columns.labelGroups.disabled = false;
  columns.settings.disabled = false;
  columns.virtualServers.disabled = __ANTMAN__;
  columns.secureConnectGateways.disabled = false;
  columns.virtualServices.disabled = !isKubernetesSupported;
  columns.restore.disabled = false;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getVersionsRows,
  });

export const getProvisionVersionsPage = createSelector([getGrid, getPendingProvisioningRows], (grid, pending) => ({
  grid,
  pending,
}));
