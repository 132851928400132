/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {useState, useEffect, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {getDurationOptionLabel} from './DurationPickerUtils';
import OptionSelector from 'components/Form/OptionSelector/OptionSelector';
import styles from './DurationPicker.css';

const custom = {customPicker: styles.customPicker};

DurationPicker.propTypes = {
  name: PropTypes.string,
  duration: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultOptionValue: PropTypes.number,
};

export default function DurationPicker(props) {
  const {name, duration, options, defaultOptionValue, onChange} = props;
  const [initialDuration, setInitialDuration] = useState(duration);
  const selectedOption = useMemo(() => {
    const option = options.find(option => option.key === duration.key && option.value === duration.value);

    return option || options.find(option => option.key === 'custom');
  }, [duration, options]);

  const customPickerName = useMemo(() => {
    return options.find(option => option.key === 'custom');
  }, [options])?.value;

  const customPicker = props.customPickers?.[customPickerName];

  const handleCancel = useCallback(() => {
    if (onChange) {
      onChange({value: initialDuration?.value, key: initialDuration?.key});
    }
  }, [onChange, initialDuration]);

  const customPickerProps = {
    ...(customPicker && {
      onCancel: handleCancel,
      customPickers: {
        [customPickerName]: {
          component: customPicker,
          props: {
            duration: initialDuration,
          },
          label: getDurationOptionLabel(initialDuration?.value, initialDuration?.key, defaultOptionValue),
        },
      },
    }),
  };

  useEffect(() => {
    if (duration.key !== 'custom') {
      setInitialDuration(duration);
    }
  }, [duration]);

  const handleChange = useCallback(
    (event, value) => {
      if (value) {
        if (onChange) {
          onChange({value: value.value, key: value.key});
        }
      }
    },
    [onChange],
  );

  return (
    <OptionSelector
      name={name}
      theme={custom}
      onChange={handleChange}
      options={options}
      value={selectedOption}
      disableErrorMessage
      {...customPickerProps}
    />
  );
}
