/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {Label} from 'illumio';
import type {ManagedDetails, ManagedEnpointDetails, EndpointDataType, EndType} from '../../MapTypes';
import type {GraphManagedEndpoint, LabelType} from '../MapGraphTypes';

export const getNodeId = (labelsObject: Record<string, Label>, labelTypes: LabelType[]): string => {
  if (!labelsObject) {
    return labelTypes.map(() => 'discovered').join(',');
  }

  return labelTypes
    .map(type => {
      const key = type.key;
      const label = labelsObject[key];

      if (label) {
        return `${key}:${label.id}`;
      }

      return `${key}:discovered`;
    })
    .join(',');
};

export const getLabelIds = (
  labelsObject: Record<string, Label> = {},
  labelTypes: LabelType[],
): Record<string, string> => {
  return labelTypes.reduce(
    (result, type) => {
      const key = type.key;
      const label = labelsObject[key];

      if (label) {
        result[key] = `${key}:${label.id}`;
      } else {
        result[key] = `${key}:discovered`;
      }

      return result;
    },
    {} as Record<string, string>,
  );
};

export const createManagedEndpoint = (
  endpoint: ManagedEnpointDetails,
  labelTypes: LabelType[],
): GraphManagedEndpoint => {
  const {subType, appGroupId, labelObject} = endpoint;

  const appGroup = appGroupId;
  const nodes = getNodeId(labelObject, labelTypes);
  const labelIdsObj = getLabelIds(labelObject, labelTypes);

  const data = subType === 'deleted' ? {nodes: 'deleted'} : {...labelIdsObj, nodes, appGroup};

  return {
    type: 'managedEndpoint',
    ...endpoint,
    fullName: endpoint.name,
    ip: '',
    ips: new Set([]),
    data,
  };
};

export const getManagedEndpoint = (
  endpoint: ManagedDetails,
  type: EndpointDataType,
  ip: string,
  labelTypes: LabelType[],
  end: EndType,
): GraphManagedEndpoint => {
  const nodeId =
    end === 'focused'
      ? getNodeId(endpoint.labelObject, labelTypes)
      : `${getNodeId(endpoint.labelObject, labelTypes)}_${end}`;
  const appGroupId = end === 'focused' ? endpoint.appGroupId : `${endpoint.appGroupId}_${end}`;
  const href = end === 'focused' ? endpoint.href : `${endpoint.href}_${end}`;

  return {
    type: 'managedEndpoint',
    managedType: type,
    ...endpoint,
    fullName: endpoint.name,
    ip,
    ips: new Set([ip]),
    data:
      endpoint.subType === 'deleted'
        ? {nodes: 'deleted'}
        : {
            ...getLabelIds(endpoint.labelObject, labelTypes),
            nodes: nodeId,
            appGroup: appGroupId,
            endType: end,
            href,
          },
  };
};
