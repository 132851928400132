/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {gridSettings} from './SupportReportsListConfig';
import {getAllUsersMap, isUserReadOnlyClusterInsensitive} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getRouteName} from 'containers/App/AppState';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'SUPPORTREPORTSLIST_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },

  pcelist(state = [], action) {
    switch (action.type) {
      case 'PCE_SUPPORTREPORTSLIST_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'SUPPORTREPORTSLIST_GET_LIST':
        return {matched: action.data.length, total: action.data.length};
      default:
        return state;
    }
  },
};

export const getSupportReports = state => state.supportReports.list;
export const getPCEReports = state => state.supportReports.pcelist;
export const getSupportReportsCount = state => state.supportReports.count;

const getSupportReportsRows = createSelector([getSupportReports, getAllUsersMap], (supportReports, usersMap) =>
  supportReports.map(item => ({
    key: item.href,
    // Service is in draft mode if it has pending status
    draft: Boolean(item.update_type),
    // Make Service created by system not selectable, since it cannot be removed or provisioned
    selectable: !item.created_by || item.created_by.href !== '/users/0',
    // Service is removable only if it's active or with pending changes expect pending deletion
    removable: !item.update_type || item.update_type !== 'delete',
    // Fill each Service with user object
    data: {
      ...item,
      name: (item.ven && (item.ven.name || item.ven.hostname)) || '',
      created_by: fillUserInfo(usersMap, item.created_by),
      updated_by: fillUserInfo(usersMap, item.updated_by),
    },
  })),
);

export const getGridSettings = createSelector([getRouteName, gridSettings], (routeName, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.venName.disabled = routeName.startsWith('app.workloads.vens');

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getSupportReportsRows,
  });

export const getSupportReportsListPage = createSelector(
  [getGrid, getSupportReportsCount, isUserReadOnlyClusterInsensitive, getPCEReports],
  (grid, count, userIsReadOnly, reports) => {
    const pceReport = reports.length
      ? {
          ...reports[0],
          key: reports[0].href,
          created_at: new Date(reports[0].created_at),
        }
      : undefined;

    return {grid, count, userIsReadOnly, pceReport};
  },
);
