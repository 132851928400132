/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {select, call} from 'redux-saga/effects';
import {Selector} from 'containers';
import apiSaga from 'api/apiSaga';
import {portUtils} from '@illumio-shared/utils';
import {getOrgId} from 'containers/User/UserState';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';
import {getNoLabelHref, sortOptions} from 'containers/Selector/SelectorUtils';
import {produce} from 'immer';

export const getQuery = (query, keyword, values) => ({
  query,
  ...(values.get('labelsAndLabelGroups')?.length
    ? {
        selected_scope: JSON.stringify(
          values
            .get('labelsAndLabelGroups')
            .filter(({href}) => !href.includes('exists') && !href.includes('all'))
            .map(({href}) => ({[href.includes('label_groups') ? 'label_group' : 'label']: {href}})),
        ),
      }
    : {}),
});

export const populateFacetCategory = ({id, name, facetName, resourceType, noPartial = false, query = {}, params}) => ({
  id,
  name,
  resources: {
    [id]: {
      dataProvider: `${resourceType}.facets`,
      apiArgs: {query: {facet: facetName ?? id, getQuery, ...query}, ...(params && {params})},
      allowPartial: !noPartial,
    },
  },
});

export const populateProtocolFacetCategory = ({id, facetName, name, resourceType, query = {}, params}) => {
  return {
    id,
    name,
    resources: {
      [id]: {
        *dataProvider(apiOptions) {
          const {data} = yield call(apiSaga, `${resourceType}.facets`, apiOptions);

          const matches = (
            resourceType === 'services' || resourceType === 'discovered_virtual_servers'
              ? data?.matches.filter(protocol => String(protocol) !== '-1')
              : data?.matches
          )?.map(proto => ({
            id: String(proto),
            value: portUtils.lookupProtocol(proto),
          }));

          return matches;
        },
        apiArgs: {
          query: {
            facet: facetName ?? id,
            getQuery: query => {
              const protocol = portUtils.reverseLookupProtocol(query);

              return {query: protocol === -1 ? '' : String(protocol)};
            },
            ...query,
          },
          ...(params && {params}),
        },
        allowPartial: false,
      },
    },
  };
};

export const populatePortFacetCategory = options => {
  return produce(populateFacetCategory({...options, noPartial: true}), draft => {
    draft.resources[options.id].dataProvider = function* (apiOptions) {
      const {data} = yield call(apiSaga, `${options.resourceType}.facets`, apiOptions);

      if (options.resourceType === 'services' || options.resourceType === 'discovered_virtual_servers') {
        return data?.matches.filter(match => match !== -1);
      }

      return data?.matches;
    };
  });
};

// FIXME: this should be in Selector Presets
export const populateAutoCompleteCategory = ({
  hideResourceName = false,
  id,
  name,
  noPartial = false,
  params,
  resourceType,
}) => ({
  id,
  name,
  resources: {
    [id]: {
      dataProvider: `${resourceType}.autocomplete`,
      apiArgs: {...(params && {params})},
      allowPartial: !noPartial,
      selectedProps: {
        hideResourceName,
      },
    },
  },
});

/**
 *
 * @param {{
 *  resourceType?: string;
 *  hasLabelGroups?: boolean;
 *  hasAll?: boolean;
 *  hasNoLabels?: boolean;
 *  allowCreate?: boolean;
 *  allowMultipleSelection?: boolean;
 *  labelTypesNameObj: Record<string, string>;
 *  labelTypeInitialRegExp: RegExp,
 *  query?: Record;
 *  params?: Record;
 * }} options
 * @returns labelsAndLabelGroups categories
 */
export const populateLabelsCategories = ({
  resourceType,
  hasLabelGroups = false,
  hasAll = false,
  hasNoLabels = false,
  allowCreate = false,
  allowMultipleSelection = false,
  labelTypeInitialRegExp,
  labelTypesNameObj,
  query = {},
  params,
  tooltipProps,
  onSelect,
}) => [
  Selector.categoryPresets.labelsAndLabelGroups({
    hasTypeList: true,
    resourceType,
    hasLabelGroups,
    allowCreate,
    allowMultipleSelection,
    labelTypeInitialRegExp,
    labelTypesNameObj,
    query,
    params,
    tooltipProps,
    onSelect,
  }),
  ...(hasNoLabels
    ? [
        {
          id: 'noLabel',
          name: intl('Common.NoLabel'),
          resources: {
            noLabel: {
              selectIntoResource: 'labelsAndLabelGroups',
              *statics({query, values}) {
                const orgId = yield select(getOrgId);
                const labelTypesNameObj = yield select(getDisplayNames);

                // TODO: use resource.conflict to implement this
                const selectedNoLabels =
                  values.get('labelsAndLabelGroups')?.filter(({href}) => href.includes('exists')) ?? [];

                const noLabels = Object.entries(labelTypesNameObj)
                  .map(([key, name]) => ({
                    href: getNoLabelHref(orgId, key),
                    value: intl('Common.NoLabels', {name}),
                    key,
                  }))
                  .filter(({href}) => !selectedNoLabels.some(selected => selected.href === href));

                return sortOptions(noLabels, query);
              },
            },
          },
        },
      ]
    : []),
  ...(hasAll
    ? [
        {divider: true},
        {
          id: 'allLabel',
          name: intl('Common.All'),
          displayResourceAsCategory: true,
          resources: {
            allLabel: {
              noEmptyBanner: true,
              statics: [intl('Common.All')],
              optionProps: {
                isPill: true,
                pillProps: {icon: 'scope', noContextualMenu: true},
              },
              selectedProps: {
                hideResourceName: true,
                pillPropsValue: {icon: 'scope', noContextualMenu: true},
              },
              onSelect: (evt, {values}) => {
                if (values.has('labelsAndLabelGroups')) {
                  values.delete('labelsAndLabelGroups');
                }
              },
            },
          },
        },
      ]
    : []),
];

export const provisionStatusCategory = {
  id: 'update_type',
  name: intl('Provision.Status'),
  resources: {
    update_type: {
      statics: [
        {value: 'create', text: intl('Provision.PendingAddition')},
        {value: 'delete', text: intl('Provision.PendingDeletion')},
        {value: 'update', text: intl('Provision.PendingModification')},
      ],
      optionProps: {
        idPath: 'value',
        textPath: 'text',
      },
    },
  },
};

/**
 * Convert the filter object into API query for cases where the query values are primitives.
 * Non-primitive values are ignored and warned in development
 * @param {Record<string, string[]>} filter filters from the url param
 * @param {{max_results: number}} query initial queries
 * @returns {Record<string, string>} query
 */
export function filterToQuery(filter, query = {}) {
  if (filter) {
    const unprocessed = __DEV__ ? new Set() : undefined;

    for (const [name, [value]] of Object.entries(filter)) {
      if (value) {
        const queryValue = value.id ?? value.value ?? value;

        // query shouldn't have non-primitives as the value
        if (typeof queryValue === 'object') {
          unprocessed?.add(name);
        } else {
          query[name] = queryValue;
        }
      }
    }

    if (__DEV__ && unprocessed.size > 0) {
      console.warn(
        `[${filterToQuery.name}] These filters are not processed:`,
        Array.from(unprocessed.values()).join(', '),
        '. Make sure you handle them explicitly',
      );
    }
  }

  return query;
}
