/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Icon, Pill, Tooltip} from 'components';
import {attributeLabels, logicOperatorLabels, operatorLabels, valueLabels} from '../../LabelRulesConstants';
import cx from 'classnames';
import React, {useMemo} from 'react';
import {mixThemeWithProps} from '@css-modules-theme/react';
import styles from './LabelRulesListExpressionPills.css';
import {conditionValueToSelectorOption} from '../Edit/Expression/LabelRulesListEditExpressionUtils';

const ConditionValue = ({theme, option, truncateValues, onRemoveValue = null}) => {
  const {attribute, name, value: valueId} = option;
  const handleRemoveValue = useMemo(
    () =>
      onRemoveValue
        ? event => {
            event.stopPropagation();
            onRemoveValue(event, valueId);
          }
        : _.noop,
    [onRemoveValue, valueId],
  );

  return (
    <>
      {truncateValues ? (
        <Tooltip content={valueLabels[attribute]?.[name] ?? name} inline>
          {valueLabels[attribute]?.[name] ?? name}
        </Tooltip>
      ) : (
        valueLabels[attribute]?.[name] ?? name
      )}
      {onRemoveValue ? (
        <span className={theme.conditionValueRemoveButton} onClick={handleRemoveValue}>
          <Icon theme={theme} themePrefix="conditionValueRemoveIcon-" name="close" />
        </span>
      ) : null}
    </>
  );
};

const ConditionPill = ({theme, condition, truncateValues = false, onClickCondition = null, onRemoveValue = null}) => {
  const conditionValueClassNames = cx(theme.conditionValue, {
    [theme.conditionValueTruncated]: truncateValues,
  });
  const conditionIsClickable = Boolean(onClickCondition);
  const handleClickCondition = conditionIsClickable
    ? _.partial(onClickCondition, _, {attribute: condition.attribute, operator: condition.operator})
    : _.noop;

  return (
    <Pill themePrefix="conditionPill-" theme={theme} insensitive={!conditionIsClickable} onClick={handleClickCondition}>
      <span className={theme.conditionAttribute}>{attributeLabels[condition.attribute]}</span>
      <span className={theme.conditionOperator}>{operatorLabels[condition.operator]}</span>
      <>
        {(condition.values ?? []).map((value, index) => {
          // convert the expression's condition to a selector option; it passed as an
          // argument to the onRemoveValue click handler when it is called;
          const option = conditionValueToSelectorOption({
            attribute: condition.attribute,
            operator: condition.operator,
            value,
          });

          return (
            <React.Fragment key={index}>
              {index > 0 ? <span className={theme.conditionLogicOperator}>{logicOperatorLabels.or}</span> : null}
              <span className={conditionValueClassNames}>
                <ConditionValue
                  theme={theme}
                  truncateValues={truncateValues}
                  onRemoveValue={onRemoveValue}
                  option={option}
                />
              </span>
            </React.Fragment>
          );
        })}
      </>
    </Pill>
  );
};

const ExpressionPills = ({
  theme,
  expression,
  truncateValues = false,
  onClickCondition = null,
  onRemoveValue = null,
}) => {
  if (!expression) {
    return null;
  }

  if (!expression.hasOwnProperty('childExpressions')) {
    // expression is a condition; just render the condition
    return (
      <ConditionPill
        theme={theme}
        condition={expression}
        truncateValues={truncateValues}
        onClickCondition={onClickCondition}
        onRemoveValue={onRemoveValue}
      />
    );
  }

  // expression is complex; render the child expressions recursively;
  return (
    <>
      {(expression.childExpressions ?? []).map((childExpr, index) => (
        <div key={index} className={styles.conditionWrapper}>
          {index > 0 ? (
            <span className={theme.expressionLogicOperator}>{logicOperatorLabels[expression.logicOperator]}</span>
          ) : null}
          <ExpressionPills
            theme={theme}
            expression={childExpr}
            truncateValues={truncateValues}
            onClickCondition={onClickCondition}
            onRemoveValue={onRemoveValue}
          />
        </div>
      ))}
    </>
  );
};

export default function LabelRulesListExpressionPills(props) {
  const {
    theme,
    expression,
    truncateValues = false,
    onClickCondition = null,
    onRemoveValue = null,
  } = mixThemeWithProps(styles, props);

  return (
    <ExpressionPills
      theme={theme}
      expression={expression}
      truncateValues={truncateValues}
      onClickCondition={onClickCondition}
      onRemoveValue={onRemoveValue}
    />
  );
}
