/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {gridSettings, getDateTimeRangePickerOptions} from './PCESupportBundlesListConfig';
import {getAllUsersMap} from 'containers/User/UserState';
import {isAPIAvailable} from 'api/apiUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'PCE_SUPPORTBUNDLES_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'PCE_SUPPORTBUNDLES_GET_LIST':
        return {matched: action.data.length, total: action.data.length};
      default:
        return state;
    }
  },
};

export const getPCESupportBundles = state => state.pceSupportBundles.list;
export const getPCESupportBundlesCount = state => state.pceSupportBundles.count;

const getPCESupportBundlesRows = createSelector([getPCESupportBundles, getAllUsersMap], (supportReports, usersMap) => {
  const isRemoveEnabled = isAPIAvailable('support_bundle_request.delete');

  return supportReports.map(item => ({
    key: item.href,
    selectable: isRemoveEnabled,
    removable: isRemoveEnabled,
    data: {
      ...item,
      name: item.name,
      created_by: fillUserInfo(usersMap, item.created_by),
      updated_by: fillUserInfo(usersMap, item.updated_by),
    },
  }));
});

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getPCESupportBundlesRows,
  });

export const getPCESupportBundlesListPage = createSelector(
  [getGrid, getPCESupportBundles, getPCESupportBundlesCount, getDateTimeRangePickerOptions],
  (grid, rows, count, dateTimeRangePickerOptions) => {
    const reportCreationPending = rows.some(row => ['pending', 'in-progress'].includes(row.status));
    const isGenerateReportEnabled = isAPIAvailable('support_bundle_requests.create');
    const isRemoveEnabled = isAPIAvailable('support_bundle_request.delete');

    return {grid, count, dateTimeRangePickerOptions, reportCreationPending, isGenerateReportEnabled, isRemoveEnabled};
  },
);
