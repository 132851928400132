/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {UserName} from 'containers';
import * as GridUtils from 'components/Grid/GridUtils';
import {getServicePortsSortString, getServicePortsString} from '../ServiceUtils';
import {isRansomwareReadinessEnabled} from 'containers/App/AppState';
import styles from './ServiceList.css';
import {createSelector} from 'reselect';
import {
  populateFacetCategory,
  populatePortFacetCategory,
  populateProtocolFacetCategory,
  provisionStatusCategory,
} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getProvisionStatusColumn} from 'components/Grid/GridUtils';

const facetOptions = {
  resourceType: 'services',
  params: {pversion: 'draft'},
};

export const categories = createSelector([isRansomwareReadinessEnabled], isRansomwareReadinessEnabled => {
  return [
    populateFacetCategory({...facetOptions, id: 'name', name: intl('Common.Name')}),
    populatePortFacetCategory({...facetOptions, id: 'port', name: intl('Port.Port')}),
    populateProtocolFacetCategory({
      id: 'proto',
      facetName: 'protocol',
      resourceType: 'services',
      params: {pversion: 'draft'},
      name: intl('Common.Protocol'),
    }),
    populateFacetCategory({...facetOptions, id: 'description', name: intl('Common.Description')}),
    provisionStatusCategory,
    ...(isRansomwareReadinessEnabled
      ? [
          {
            id: 'is_ransomware',
            name: intl('RansomwareDashboard.RansomwareRisky'),
            resources: {
              is_ransomware: {
                statics: [
                  {id: 'true', value: intl('Common.Yes')},
                  {id: 'false', value: intl('Common.No')},
                ],
              },
            },
          },
        ]
      : []),
  ];
});

export const gridSettings = createSelector([isRansomwareReadinessEnabled], isRansomwareReadinessEnabled => ({
  id: 'servicelist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: getProvisionStatusColumn(),
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    portProtos: {
      header: intl('Port.Protocol'),
      value: ({row}) => {
        if (
          row.data.name === intl('Common.AllServices') ||
          !(row.data.windows_egress_services || row.data.windows_services || row.data.service_ports)
        ) {
          return intl('Common.ALL');
        }

        return getServicePortsString(
          row.data.windows_egress_services || row.data.windows_services || row.data.service_ports,
        );
      },
      format: ({value}) => <span className={styles.serviceListPorts}>{value}</span>,
      sort: ({row}) => {
        if (!row.data.service_ports) {
          return getServicePortsSortString(row.data.windows_services).toLowerCase();
        }

        return getServicePortsSortString(row.data.service_ports).toLowerCase();
      },
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) =>
        row.data.updated_by && <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
    desc: {
      header: intl('Common.Description'),
      value: 'description',
    },
    ransomwarePortType: {
      header: intl('RansomwareDashboard.PortType'),
      value: ({row}) => row.data.risk_details.ransomware?.category ?? '',
      format: ({value}) => _.startCase(value),
      optional: true,
      disabled: !isRansomwareReadinessEnabled,
    },
    ransomwareSeverity: {
      header: intl('Common.Severity'),
      value: ({row}) => row.data.risk_details.ransomware?.severity ?? '',
      format: ({value}) => _.startCase(value),
      optional: true,
      disabled: !isRansomwareReadinessEnabled,
    },
    ransomwareOsPlatforms: {
      header: intl('Common.OS'),
      value: ({row}) => row.data.risk_details.ransomware?.os_platforms ?? [],
      format: ({value}) =>
        value
          .map(os => _.startCase(os))
          .sort()
          .join(', '),
      optional: true,
      disabled: !isRansomwareReadinessEnabled,
    },
  },

  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['portProtos'], size: 'minmax(120px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
      {columns: ['desc'], size: 'minmax(100px, auto)'},
      {columns: ['ransomwarePortType'], size: 'minmax(100px, auto)'},
      {columns: ['ransomwareSeverity'], size: 'minmax(100px, auto)'},
      {columns: ['ransomwareOsPlatforms'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['portProtos'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
            {columns: ['ransomwarePortType'], size: 'minmax(100px, auto)'},
            {columns: ['ransomwareSeverity'], size: 'minmax(100px, auto)'},
            {columns: ['ransomwareOsPlatforms'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['portProtos'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
          {columns: ['ransomwarePortType'], size: 'minmax(100px, auto)'},
          {columns: ['ransomwareSeverity'], size: 'minmax(100px, auto)'},
          {columns: ['ransomwareOsPlatforms'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['portProtos'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(100px, auto)'},
            {
              columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
              size: 'minmax(100px, auto)',
            },
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['portProtos'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(100px, auto)'},
          {
            columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
            size: 'minmax(100px, auto)',
          },
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'portProtos'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(80px, auto)'},
            {
              columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
              size: 'minmax(100px, auto)',
            },
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'portProtos'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(80px, auto)'},
          {
            columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
            size: 'minmax(100px, auto)',
          },
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'portProtos', 'status'], size: 'minmax(100px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['desc'], size: 'minmax(80px, auto)'},
            {
              columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
              size: 'minmax(100px, auto)',
            },
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'portProtos', 'status'], size: 'minmax(100px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['desc'], size: 'minmax(80px, auto)'},
          {
            columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
            size: 'minmax(100px, auto)',
          },
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'portProtos', 'status'], size: 'minmax(150px, auto)'},
            {columns: ['updatedAt', 'updatedBy', 'desc'], size: 'minmax(170px, auto)'},
            {
              columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
              size: 'minmax(100px, auto)',
            },
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'portProtos', 'status'], size: 'minmax(150px, auto)'},
          {columns: ['updatedAt', 'updatedBy', 'desc'], size: 'minmax(170px, auto)'},
          {
            columns: ['ransomwarePortType', 'ransomwareSeverity', 'ransomwareOsPlatforms'],
            size: 'minmax(100px, auto)',
          },
        ];
      },
    },
  ],
}));
