/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {ResultParams, ResultStatus} from 'containers/IlluminationMap/MapTypes';
import _ from 'lodash';

export const GO_QUERY_STATUS_KEY = 'filters-go';
export const hasMatchingQueryIdParam = (a?: ResultStatus, b?: ResultStatus): boolean =>
  Boolean(a?.params?.queryId && b?.params?.queryId && a?.params?.queryId === b?.params?.queryId);

export const isQuerying = (resultStatus?: ResultStatus): boolean => resultStatus?.status === 'query-init';

export const isQueryError = (resultStatus?: ResultStatus): boolean => resultStatus?.status === 'query-error';

export const isDownloading = (resultStatus?: ResultStatus): boolean =>
  resultStatus?.status === 'download-init' || resultStatus?.status === 'download-loading';

export const isDownloadingQuery = (queryStatus?: ResultStatus, downloadStatus?: ResultStatus): boolean =>
  Boolean(hasMatchingQueryIdParam(queryStatus, downloadStatus) && isDownloading(downloadStatus));

export const isProcessing = (resultStatus?: ResultStatus): boolean => resultStatus?.status === 'processing';

export const isDownloadProcessing = (resultStatus?: ResultStatus): boolean =>
  resultStatus?.status === 'download-processing';

export const isDownloadError = (resultStatus?: ResultStatus): boolean => resultStatus?.status === 'download-error';

export const isDownloadQueryError = (queryStatus?: ResultStatus, downloadStatus?: ResultStatus): boolean =>
  Boolean(hasMatchingQueryIdParam(queryStatus, downloadStatus) && isDownloadError(downloadStatus));

export const hasMatchingDownloadQueryParams = (resultStatus: ResultStatus, queryParams?: ResultParams): boolean => {
  const {params} = resultStatus;
  const {queryId, offset, draft_policy_filter, reported_policy_filter, transmission, ip_filter} = queryParams ?? {};

  return _.isEqual(
    {offset: offset ?? 0, queryId, draft_policy_filter, reported_policy_filter, transmission, ip_filter},
    {
      offset: params?.offset ?? 0,
      queryId: params?.queryId,
      draft_policy_filter: params?.draft_policy_filter,
      reported_policy_filter: params?.reported_policy_filter,
      transmission: params?.transmission,
      ip_filter: params?.ip_filter,
    },
  );
};

export const isAnyProcessing = (resultStatuses: Record<string, ResultStatus>): boolean => {
  return Object.values(resultStatuses).some(resultStatus => {
    return isQuerying(resultStatus) || isDownloading(resultStatus) || isDownloadProcessing(resultStatus);
  });
};

export const getDownloadParams = ({
  resultStatus,
  offset,
  maxResults,
}: {
  resultStatus?: ResultStatus;
  offset?: number;
  maxResults?: number;
}): {offset?: number; limit?: number} => ({
  offset: (resultStatus?.reason === 'pagination' ? resultStatus.params?.downloadOffset ?? offset : offset) ?? 0,
  limit: resultStatus?.reason === 'pagination' ? resultStatus.params?.limit ?? maxResults : maxResults,
});

export const isAlreadyDownloaded = (resultStatus?: ResultStatus, queryParams?: ResultParams): boolean =>
  Boolean(
    resultStatus &&
      hasMatchingDownloadQueryParams(resultStatus, queryParams) &&
      resultStatus?.status !== 'download-init',
  );

export const isDownloadAlreadyInitialized = (
  resultStatus?: ResultStatus,
  queryParams?: {queryId: string; offset: number},
): boolean => resultStatus?.status === 'download-init' && hasMatchingDownloadQueryParams(resultStatus, queryParams);
