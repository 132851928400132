/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Component, createRef} from 'react';
import {connect} from '@illumio-shared/utils/redux';
import {createStructuredSelector} from 'reselect';
import {Helmet} from 'react-helmet';
import {Button, Icon, MenuItem, MenuDelimiter} from 'components';
import * as UserSelectors from 'containers/User/UserState';
import {getVersionMismatch} from 'containers/App/AppState';
import {AppContext} from 'containers/App/AppUtils';
import styles from './UserMenu.css';

const getUserMenu = createStructuredSelector({
  userName: UserSelectors.getUserName,
  userIsExternal: UserSelectors.isUserExternal,
  getVersionMismatch,
  orgName: UserSelectors.getOrgName,
  userIsMSPOwner: UserSelectors.isUserMSPOwner,
  mspOrgUrl: UserSelectors.getMspOrgUrl,
  chargebeeUrl: UserSelectors.getChargebeeUrl,
  chargebeeSiteName: UserSelectors.getChargebeeSiteName,
});

@connect(getUserMenu)
export default class UserMenu extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.iconRef = createRef();
    this.state = {displayTooltip: true};
    this.saveIconRef = this.saveIconRef.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleDisplayTooltip = this.handleDisplayTooltip.bind(this);
    this.handleChargebeeSubscription = this.handleChargebeeSubscription.bind(this);
  }

  saveIconRef(icon) {
    this.iconRef.current = icon?.element;
  }

  handleLogout() {
    this.props.dispatch({type: 'LOGOUT', unsavedPendingWarning: this.context.store.prefetcher.formIsDirty});
  }

  handleDisplayTooltip() {
    this.setState(prevState => ({displayTooltip: !prevState.displayTooltip}));
  }

  handleChargebeeSubscription() {
    const cbInstance = window.Chargebee?.getInstance();

    if (cbInstance) {
      cbInstance.createChargebeePortal().open();
    }
  }

  // Pass content as function to prevent creating react elements at the beginning, create only when dropdown is open
  renderContent() {
    const {getVersionMismatch, userIsExternal, userIsMSPOwner, mspOrgUrl, chargebeeSiteName, chargebeeUrl} = this.props;

    const logoutElement = (
      <MenuItem
        onSelect={this.handleLogout}
        data-tid="comp-navbar-logout"
        theme={styles}
        themePrefix="itemWithIcon-"
        text={
          <>
            <Icon name="export" />
            {intl('Users.Logout')}
          </>
        }
      />
    );

    // return immediately to not show the admin and other options
    if (getVersionMismatch !== 0) {
      return logoutElement;
    }

    return (
      <>
        {__MSP__ && userIsMSPOwner && chargebeeUrl && chargebeeSiteName && (
          <>
            {!window.Chargebee && (
              <Helmet>
                <script src={chargebeeUrl} data-cb-site={chargebeeSiteName} async />
              </Helmet>
            )}
            <MenuItem
              onSelect={this.handleChargebeeSubscription}
              data-tid="comp-navbar-mymanagedtenants"
              theme={styles}
              themePrefix="itemWithIcon-"
              text={
                <>
                  <Icon name="virtual-service" />
                  {intl('MSP.MySubscription')}
                </>
              }
            />
          </>
        )}
        <MenuItem
          link="myprofile"
          data-tid="comp-navbar-myprofile"
          theme={styles}
          themePrefix="itemWithIcon-"
          text={
            <>
              <Icon name="admin" />
              {intl('Users.MyProfile')}
            </>
          }
        />
        <MenuItem
          link="myroles.list"
          data-tid="comp-navbar-myroles"
          theme={styles}
          themePrefix="itemWithIcon-"
          text={
            <>
              <Icon name="group" />
              {intl('Users.MyRoles')}
            </>
          }
        />
        {userIsMSPOwner && mspOrgUrl && (
          <MenuItem
            link={{href: mspOrgUrl}}
            data-tid="comp-navbar-mymanagedtenants"
            theme={styles}
            themePrefix="itemWithIcon-"
            text={
              <>
                <Icon name="group" />
                {intl('MSP.MyManagedTenants')}
              </>
            }
          />
        )}
        {!__MSP__ && !userIsExternal && (
          <MenuItem
            link="apikeys.list"
            data-tid="comp-navbar-apikeys"
            theme={styles}
            themePrefix="itemWithIcon-"
            text={
              <>
                <Icon name="key" />
                {intl('Users.APIKeys.MyAPIKeys')}
              </>
            }
          />
        )}
        <MenuDelimiter />
        <MenuItem
          link={{params: {showVersion: true}, replace: true, scrollTop: false, noUnsavedPendingWarning: true}}
          data-tid="comp-navbar-about"
          theme={styles}
          themePrefix="itemWithIcon-"
          text={intl('Common.ProductVersion')}
        />
        <MenuDelimiter />
        {logoutElement}
      </>
    );
  }

  render() {
    const {userName, orgName} = this.props;
    const tooltip = (
      <div className={styles.organization}>
        <div className={styles.title}>{intl('Common.Organization')}</div>
        <div>{orgName}</div>
      </div>
    );

    return (
      <Button.Menu
        size="large"
        icon="userprof"
        color="standard"
        tid="Navbar-user"
        text={userName}
        menuAlign="right"
        theme={styles}
        themePrefix="username-"
        menu={this.renderContent}
        tooltip={tooltip}
      />
    );
  }
}
