/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';

export default {
  networkEnforcementNodes: combineReducers({
    networkEnforcementNodeInstance(state = {}, action) {
      switch (action.type) {
        case 'NETWORK_ENFORCEMENT_NODE_GET_INSTANCE':
          return action.data.networkEnforcementNodeInstance;
        default:
          return state;
      }
    },
    list(state = [], action) {
      switch (action.type) {
        case 'NETWORK_ENFORCEMENT_NODES_GET_LIST':
          return action.data.networkEnforcementNodes;
        default:
          return state;
      }
    },
    listGetting(state = false, action) {
      switch (action.type) {
        case 'NETWORK_ENFORCEMENT_NODES_GETTING_LIST':
          return true;
        case 'NETWORK_ENFORCEMENT_NODES_GET_LIST':
          return false;
        default:
          return state;
      }
    },
  }),
};

export const getNetworkEnforcementNodeInstance = state => state.networkEnforcementNodes.networkEnforcementNodeInstance;
export const getNetworkEnforcementNodes = state => state.networkEnforcementNodes.list;
export const getNetworkEnforcementNodesGetting = state => state.networkEnforcementNodes.listGetting;

export const getNetworkEnforcementNodeSupportedSwitches = createSelector(
  getNetworkEnforcementNodeInstance,
  networkEnforcementNode => {
    const supportedDevices = networkEnforcementNode.supported_devices;
    const supportedSwitches = supportedDevices && supportedDevices.find(device => device.device_type === 'switch');
    const supportedManufacturers = (supportedSwitches && supportedSwitches.manufacturers) || [];

    return supportedManufacturers.reduce((result, manufacturer) => {
      const manufacturerName = manufacturer.manufacturer;
      const models = manufacturer.models;

      result[manufacturerName] = models.map(model => ({
        label: model.model,
        value: model.model,
      }));

      return result;
    }, {});
  },
);

export const getSupportedDevicesStaticValues = createSelector(getNetworkEnforcementNodes, networkEnforcementNodes => {
  const uniqueDeviceTypes = new Set([]);
  const uniqueManufacturers = new Set([]);
  const uniqueModels = new Set([]);

  networkEnforcementNodes.forEach(node => {
    const device = node.supported_devices && node.supported_devices.find(device => device.device_type === 'switch');

    if (device) {
      uniqueDeviceTypes.add(device.device_type);

      const manufacturers = device.manufacturers;

      manufacturers.forEach(manufacturer => {
        uniqueManufacturers.add(manufacturer.manufacturer);

        const models = manufacturer.models;

        models.forEach(model => {
          if (model.model) {
            uniqueModels.add(model.model);
          }
        });
      });
    }
  });

  return {
    manufacturer: [...uniqueManufacturers],
    model: [...uniqueModels],
  };
});
