/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Button, ButtonGroup, Icon} from 'components';
import styles from './LabelRulesList.css';
import {validateRule} from '../LabelRulesUtils';
import intl from '@illumio-shared/utils/intl';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import cx from 'classnames';

export const NEW_LABEL_RULE_HREF = 'NEW_LABEL_RULE_HREF';
export const NEW_LABEL_RULE_EDITOR_STATE = {href: NEW_LABEL_RULE_HREF, expression: {}, labels: []};

/**
 * checks if the ruleEditorState has changes to the ruleNumber; NOTE: if the
 * ruleEditorState's ruleNumber is undefined, we consider it unchanged because
 * we wouldn't make an update call in that case;
 * @param rule
 * @param ruleEditorState
 * @returns {boolean}
 */
export const ruleNumberChanged = ({rule, ruleEditorState}) => {
  return (
    Boolean(ruleEditorState) &&
    ruleEditorState.ruleNumber !== undefined &&
    ruleEditorState.ruleNumber !== rule.ruleNumber
  );
};

/**
 * checks if the ruleEditorState has any changes to the expression or labels;
 * @param rule
 * @param ruleEditorState
 * @returns {boolean}
 */
export const ruleExpressionOrLabelsChanged = ({rule, ruleEditorState}) =>
  Boolean(ruleEditorState) && !_.isEqual(_.omit(ruleEditorState, 'ruleNumber'), _.omit(rule, 'ruleNumber'));

/**
 * checks if the ruleEditorState has any changes that can be saved;
 * @param rule
 * @param ruleEditorState
 * @returns {boolean}
 */
export const ruleChanged = ({rule, ruleEditorState}) =>
  ruleNumberChanged({rule, ruleEditorState}) || ruleExpressionOrLabelsChanged({rule, ruleEditorState});

export const formatButtons = options => {
  const {
    row,
    row: {data: {isInEditMode, ruleEditorState, rule, saveInProgress = false, saveError = false} = {}},
    component: {onEditClick, onSave, onCancelClick, maxRuleNumber},
  } = options;
  const ruleIsDirty = ruleChanged({rule, ruleEditorState});
  const ruleIsValid = validateRule(ruleEditorState, {maxRuleNumber});
  const saveIsDisabled = !ruleIsDirty || !ruleIsValid;

  if (!isInEditMode) {
    return (
      <div className={styles.actions}>
        <Button
          color="standard"
          size="small"
          noFill
          tid="edit"
          icon="edit"
          onClick={_.partial(onEditClick, row)}
          tooltip={intl('Common.Edit')}
        />
      </div>
    );
  }

  return (
    <ButtonGroup color="standard" size="small" noFill>
      <Button
        tid="save"
        icon="save"
        onClick={_.partial(onSave, row)}
        disabled={saveIsDisabled}
        progress={saveInProgress}
        progressError={saveError}
        tooltip={intl('Common.Save')}
      />
      <Button tid="cancel" icon="cancel" onClick={onCancelClick} tooltip={intl('Common.Cancel')} />
    </ButtonGroup>
  );
};

export const formatArrow = () => (
  <div className={styles.arrowContainer}>
    <Icon name="arrow-right" />
  </div>
);

export const formatStatus = ({row}) => {
  const {
    isNewRule,
    rule: {enabled},
  } = row.data;

  return isNewRule ? null : (
    <div className={cx(styles.ruleStatusWrapper, {[styles.disabled]: !enabled})}>
      <Icon theme={styles} themePrefix="ruleStatusIcon-" name={enabled ? 'inuse' : 'cancel'} />
      <span className={styles.ruleStatusText}>{enabled ? intl('Common.Enabled') : intl('Common.Disabled')}</span>
    </div>
  );
};

/**
 * invokes the unsaved warning for unsaved label rule changes; it returns a promise
 * that resolves to a boolean value; if the user clicks 'cancel' in the unsaved modal,
 * the promise will return false; if the user clicks 'confirm' in the unsaved modal,
 * the form will be marked as 'clean' and the promise will return true;
 * @returns {Promise<boolean>}
 */
export const invokeUnsavedWarning = async () => {
  const answer = await new Promise(resolve =>
    PubSub.publish('UNSAVED.WARNING', {
      resolve,
      ...getDiscardChangesModalProps(),
    }),
  );

  if (answer === 'cancel') {
    return false;
  }

  PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});

  return true;
};
