/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps} from '@css-modules-theme/react';
import intl from '@illumio-shared/utils/intl';
import {ExpandableList, type ExpandableListProps} from 'components';
import {getSortedLabelsAndLabelGroups} from 'containers/Label/LabelSettings/LabelSettingsUtils';
import ExpandableLabelListShort from './ExpandableLabelListShort';
import styles from './ExpandableLabelList.css';

export interface ExpandableLabelListProps<Value> extends ExpandableListProps<Value> {
  labelTypeAccessor?: string;
  labelGroupTypeAccessor?: string;
  dontMergeGroups?: boolean;
}

export default function ExpandableLabelList<T>(props: ExpandableLabelListProps<T>): JSX.Element {
  const {
    labelTypeAccessor,
    labelGroupTypeAccessor,
    dontMergeGroups = false,
    values,
    ...listProps
  } = mixThemeWithProps(styles, props);

  listProps.initialCount ??= 20;
  listProps.showInModalFrom ??= 50;
  listProps.modalTitle ??= intl('Common.Labels');
  listProps.showMoreText ??= 'Common.CountMoreLabels';

  const sortedValues = getSortedLabelsAndLabelGroups(
    values,
    labelTypeAccessor,
    labelGroupTypeAccessor,
    dontMergeGroups,
  );

  return <ExpandableList {...listProps} values={sortedValues} />;
}

ExpandableLabelList.Short = ExpandableLabelListShort;
