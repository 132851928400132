/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Component} from 'react';
import {connect} from '@illumio-shared/utils/redux';
import {ProgressBar} from 'components';
import styles from './ProvisionMenu.css';
import {getProvisioningPage} from '../ProvisioningState';

@connect(getProvisioningPage)
export default class ProvisionProgressMenu extends Component {
  render() {
    const {calcInProgress} = this.props;
    //TODO const fillPercent = total ? synced / total * 100 : 0;

    return (
      <div className={styles.provisionCountProgressItem}>
        {intl('Provision.ProvisioningStatus')}
        {calcInProgress && (
          <span className={styles.progressBar}>
            <ProgressBar tid="statusbar" />
          </span>
        )}
      </div>
    );
  }
}
