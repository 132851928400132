/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {ApplicationError} from '.';
import type {ApplicationErrorOptions} from './Application';

interface UIInterruptionErrorOptions extends ApplicationErrorOptions {
  // These are collected from the usage
  cancel?: boolean;
  back?: boolean;
}

/**
 * UIInterruption
 */
export default class UIInterruptionError extends ApplicationError {
  data: UIInterruptionErrorOptions;

  constructor(data: UIInterruptionErrorOptions = {}) {
    super({
      trace: false,
      message: data.message,
      code: 'UI_INTERRUPTION',
    });
    this.name = 'UIInterruptionError';

    this.data = data;
  }
}
