/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useContext} from 'react';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {Form, TextLabel} from 'components';
import {useWhen, isRequiredField} from '../FormUtils';
import {generalUtils} from '@illumio-shared/utils/shared';
import * as PropTypes from 'prop-types';

FormLabel.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string, // unique name to handle errors key mapping
  errors: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.element),
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  // toggle to disable CSS, Icon, Asterisk
  disabled: PropTypes.bool,
  // true: show asterisk, false: don't show asterisk
  showAsterisk: PropTypes.bool,
  // force to show error without looking at errors{} and touched{} variables
  forceShowError: PropTypes.bool,
  // show field validated checkmark
  showValidatedCheckMark: PropTypes.bool,
  // themr object
  theme: PropTypes.object,
  lineHeight: PropTypes.string,
  // name in form schema for label line-height alignment
  nameAlign: PropTypes.string,
};

export function FormLabel(props) {
  const {[forwardRefSymbol]: ref, form, field, ...labelProps} = useWhen(props.form, props.field, props);

  labelProps.disabled ??= false;

  const schema = useContext(Form.SchemaContext);
  const {
    forceShowError = false,
    showValidatedCheckMark = false,
    showAsterisk,
    nameAlign,
    lineHeight,
    ...textLabelProps
  } = labelProps;
  const {errors, touched, values} = form;
  const {name, value} = field;

  //highlight the error and match them with touched fields
  if (Array.isArray(touched[name]) && Array.isArray(errors[name])) {
    if (touched[name].length && errors[name].length) {
      //touched can be nested with multiple objects
      textLabelProps.showError =
        forceShowError ||
        Object.entries(generalUtils.flattenObject(touched[name])).some(
          ([path, value]) =>
            value === true && (_.has(errors[name], path) || _.has(errors[name], `${path.split('.').shift()}.rowError`)),
        );
    }
  } else if (touched === true || touched.hasOwnProperty(name)) {
    textLabelProps.showError = (errors.hasOwnProperty(name) && !labelProps.disabled) || forceShowError;
    textLabelProps.showValidatedCheckMark =
      showValidatedCheckMark && !errors.hasOwnProperty(name) && value.length !== 0;
  }

  const isRequired = isRequiredField(schema, name, values);

  if (lineHeight) {
    textLabelProps.lineHeight = lineHeight;
  } else {
    const schemaObj = schema.fields[nameAlign || name];
    // eslint-disable-next-line no-underscore-dangle
    const type = schemaObj && schemaObj._label !== 'radio' && schemaObj._type;

    switch (type) {
      case 'string':
        textLabelProps.paddingTop = 'var(--inputAndtextArea-vertical-padding)';
        textLabelProps.lineHeight = 'var(--inputAndtextArea-line-height)';
        break;
      case 'array':
      case 'object':
      case 'number':
        textLabelProps.paddingTop = 'var(--optionSelector-vertical-padding)';
        textLabelProps.lineHeight = 'var(--optionSelector-line-height)';
        break;
    }
  }

  textLabelProps.showAsterisk = typeof showAsterisk === 'boolean' ? showAsterisk : isRequired;

  return <TextLabel {...textLabelProps} />;
}

export default forwardRefFactory(props => <Field {...props} component={FormLabel} />, {hoistSource: FormLabel});
