/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import {ipUtils, portUtils} from '@illumio-shared/utils';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {RegexPortProtocolMap} from 'containers/Service/ServiceUtils';
import {operatorOptions} from './LabelRulesListEditExpressionConstants';

const HOSTNAME_PATTERN = /^[\w.-]+$/;

const protocolRegexMap = {
  gre: {proto: 47, text: intl('Protocol.GRE'), regex: /^(g|gr|gre)$/i},
  icmp: {proto: 1, text: intl('Protocol.ICMP'), regex: /^(i|ic|icm|icmp)$/i},
  icmpv6: {proto: 58, text: intl('Protocol.ICMPv6'), regex: /^(i|ic|icm|icmp|icmpv|icmpv6)$/i},
  igmp: {proto: 2, text: intl('Protocol.IGMP'), regex: /^(i|ig|igm|igmp)$/i},
  ipip: {proto: 94, text: intl('Protocol.IPIP'), regex: /^(i|ip|ipi|ipip)$/i},
  ipv4: {proto: 4, text: intl('Protocol.IPv4'), regex: /^(i|ip|ipv|ipv4)$/i},
  ipv6: {proto: 41, text: intl('Protocol.IPv6'), regex: /^(i|ip|ipv|ipv6)$/i},
  tcp: {proto: 6, text: intl('Protocol.TCP'), regex: /^(t|tc|tcp)$/i},
  udp: {proto: 17, text: intl('Protocol.UDP'), regex: /^(u|ud|udp)$/i},
};

/**
 *
 * Accept query string and will return an array of range : ['89-90', '89-90 TCP', '80-90 UDP']
 * TODO: find a way to import this code from MapFilterUtils instead of copying it over;
 *
 * @param {*} key : unused
 * @param {*} query : String of query in form of ' # - # protocol', 'protocol #-#', '#-#'
 * @returns array of object in shape of { value: /string/ } and return [] if no match
 */
export const validatePortRangeSearch = (key, query) => {
  // Make sure query not just empty space or has urgly non-word
  if (/(^ *$)|[^\s\w-]/.test(query)) {
    return [];
  }

  const result = [];

  let ranges;

  //  Try extract #-# or similar pattern.
  try {
    ranges = query
      .match(/\d+\s*-+\s*\d+/)
      .shift()
      .split(/\s*-+\s*/)
      .sort((a, b) => a - b);

    // No more that 2 numbers
    if (query.match(/\d+/g).length > 2) {
      return [];
    }
  } catch {
    // If no match, null,  return []
    return [];
  }

  //  Extract protocol
  const protocol = query.split(/[^A-Za-z]+/).filter(Boolean);

  // if numbers are valid port and range
  const valueStart = Number(ranges[0]) < Number(ranges[1]) ? Number(ranges[0]) : Number(ranges[1]);
  const valueEnd = Number(ranges[0]) > Number(ranges[1]) ? Number(ranges[0]) : Number(ranges[1]);

  if (valueStart < valueEnd && portUtils.isValidPort(ranges[0]) && portUtils.isValidPort(ranges[1])) {
    // Query string contains only port and no protocol,
    if (_.isEmpty(protocol)) {
      const range = `${valueStart}-${valueEnd}`;

      // In this case add both UDP and TCP protocol options in dropdown
      result.push(
        {
          value: range,
          detail: {port: valueStart, toPort: valueEnd},
        },
        {
          value: `${range} ${portUtils.portProtocolMap().tcp.text}`,
          detail: {proto: portUtils.portProtocolMap().tcp.value, port: valueStart, toPort: valueEnd},
        },
        {
          value: `${range} ${portUtils.portProtocolMap().udp.text}`,
          detail: {proto: portUtils.portProtocolMap().udp.value, port: valueStart, toPort: valueEnd},
        },
      );

      return result;
    }

    // Query string contains port with one type of protocol chars, in this case add the matching protocol option to drop down list
    if (protocol.length === 1) {
      // check for TCP
      if (portUtils.portProtocolRegex.tcpPortProtocol.test(`${valueEnd} ${protocol[0]}`)) {
        result.push({
          value: `${valueStart}-${valueEnd} ${portUtils.portProtocolMap().tcp.text}`,
          detail: {proto: portUtils.portProtocolMap().tcp.value, port: valueStart, toPort: valueEnd},
        });
      } else if (portUtils.portProtocolRegex.udpPortProtocol.test(`${valueEnd} ${protocol[0]}`)) {
        result.push({
          value: `${valueStart}-${valueEnd} ${portUtils.portProtocolMap().udp.text}`,
          detail: {proto: portUtils.portProtocolMap().udp.value, port: valueStart, toPort: valueEnd},
        });
      }
    }
  }

  return result;
};

/**
 *
 * Accept query string and search for port with available protocol or port with protocol if protocol is specified
 * TODO: find a way to import this code from MapFilterUtils instead of copying it over;
 *
 * @param {*} key : unused
 * @param {*} query : query string in shape of '#', "# protocol", 'protocol #'
 * @returns array of object in shape of  {value: /string/, detail: {proto: /number/, port: /number/}} and return [] if no match
 */
export const validatePortSearch = (key, query) => {
  //  Make sure query not just empty space or contains urgly non-words
  if (/(^ *$)|[^\s\w/]/.test(query)) {
    return [];
  }

  // Extract Port [] and Protocol []
  const port = query.match(/\d+/g);
  const protocol = query.split(/[^A-Za-z]+/).filter(Boolean);

  //-------     CASE 1: port number in ## format : 0-255 -------------------------------

  // Must be one digit number in range 0-65535
  if (port && port.length === 1 && portUtils.isValidPort(port[0])) {
    // ProtocolMap
    const protocolName = portUtils.ProtocolMap[Number(port[0])];
    const protocolValue = protocolName ? `Protocol ${port[0]} (${protocolName})` : `Protocol ${port[0]}`;

    // No Spefic protocol
    if (_.isEmpty(protocol)) {
      const result = [
        {value: `Port ${port[0]}`, detail: {port: Number(port[0])}},
        {
          value: `${port[0]} ${portUtils.portProtocolMap().tcp.text}`,
          detail: {proto: portUtils.portProtocolMap().tcp.value, port: Number(port[0])},
        },
        {
          value: `${port[0]} ${portUtils.portProtocolMap().udp.text}`,
          detail: {proto: portUtils.portProtocolMap().udp.value, port: Number(port[0])},
        },
        {value: protocolValue, detail: {proto: Number(port[0])}},
      ];

      return result;
    }

    //  Only one protocol allow at a time for specificity
    if (protocol.length === 1) {
      // TCP protocol
      if (portUtils.portProtocolRegex.tcpPortProtocol.test(`${port[0]} ${protocol[0]}`)) {
        return [
          {
            value: `${port[0]} ${portUtils.portProtocolMap().tcp.text}`,
            detail: {proto: portUtils.portProtocolMap().tcp.value, port: Number(port[0])},
          },
        ];
      }

      // UDP protocol
      if (portUtils.portProtocolRegex.udpPortProtocol.test(`${port[0]} ${protocol[0]}`)) {
        return [
          {
            value: `${port[0]} ${portUtils.portProtocolMap().udp.text}`,
            detail: {proto: portUtils.portProtocolMap().udp.value, port: Number(port[0])},
          },
        ];
      }
    }
  }

  return [];
};

export const lookupRegexPortProtocol = type => RegexPortProtocolMap()[type];

/**
 * validate acceptable strings that represent protocol without port.
 * @param key
 * @param query
 */
export const validateProtocolOnly = (key, query) => {
  return Object.values(protocolRegexMap).reduce((result, {proto, text, regex}) => {
    if (regex.test(query)) {
      result.push({
        value: text,
        detail: {proto},
      });
    }

    return result;
  }, []);
};

/**
 * validates a regular expression and throws an error if invalid.
 * @param query
 */
export const validateRegex = ({query} = {}) => {
  let errorMessage;

  try {
    // eslint-disable-next-line no-new
    new RegExp(query);
  } catch (error) {
    if (!(error instanceof SyntaxError)) {
      console.error(error);
    }

    errorMessage = error.message ?? 'Invalid regular expression';
  }

  if (query && errorMessage) {
    throw new Error(errorMessage);
  }
};

/**
 * validates an ip address, ip range, or cidr block; if the operator is "isIn",
 * it validates the query as an ip or cidr block; otherwise it validates as an
 * ip address; throws an error if the validation fails;
 * @param query
 * @param operator
 */
export const validateIP = ({query = '', operator} = {}) => {
  query ??= '';

  let isValidIP = false;
  let isValidCIDR = false;
  let isValidIPRange = false;
  let errorMessage = '';

  if (operator === operatorOptions.isIn.id) {
    try {
      ipUtils.parseIPOnlyCIDR(query);
      isValidCIDR = true;
    } catch (error) {
      if (!(error instanceof TypeError)) {
        console.error(error);
      }
    }

    try {
      ipUtils.parseIPRange(query);
      isValidIPRange = true;
    } catch (error) {
      if (!(error instanceof TypeError)) {
        console.error(error);
      }
    }

    errorMessage = 'Invalid CIDR or IP range';
  } else {
    try {
      ipUtils.parseIPNoCIDR(query);
      isValidIP = true;
    } catch (error) {
      if (!(error instanceof TypeError)) {
        console.error(error);
      }
    }

    errorMessage = 'Invalid IP address';
  }

  if (query && !isValidIP && !isValidCIDR && !isValidIPRange) {
    throw new Error(errorMessage);
  }
};

/**
 * validates a hostname; throws an exception if the validation fails;
 * @param query
 * @param operator
 */
export const validateHostname = ({query = '', operator} = {}) => {
  if (query && operator === operatorOptions.regex.id) {
    validateRegex({query});
  } else if (query && !HOSTNAME_PATTERN.test(query)) {
    throw new TypeError('Invalid hostname');
  }
};

/**
 * validates a process; if the operator is regex, it validates as a regular expression;
 * otherwise, validates as a string; throws if the validation fails;
 * @param query
 * @param operator
 */
export const validateProcess = ({query = '', operator} = {}) => {
  if (query && operator === operatorOptions.regex.id) {
    validateRegex({query});
  }
};

export const validatePortAndOrProtocol = ({query, operator} = {}) => {
  query = (query ?? '').trim();

  if (!query) {
    return [];
  }

  // the "port" attribute's "is" operator supports...
  // - port number (e.g. 80)
  // - protocol number (e.g. 6)
  // - named protocol (e.g. TCP)
  // - port and protocol (e.g. 80 TCP, TCP 65535)
  if (operator === operatorOptions.is.id) {
    let portProtoMatches = [];

    if (lookupRegexPortProtocol('protocolOnlyIncludingTCPUDP').test(query.toUpperCase())) {
      portProtoMatches = validateProtocolOnly('', query);
    } else {
      portProtoMatches = validatePortSearch('', query);
    }

    if (!portProtoMatches?.length) {
      throw new TypeError(intl('Common.InvalidProtocolMessage'));
    }

    return portProtoMatches;
  }

  // the "port" attribute's "isIn" operator supports...
  // - port range (e.g. 80-81)
  // - port range and protocol (e.g. 80-81 TCP, TCP 0-65535)
  if (operator === operatorOptions.isIn.id) {
    const portRangeMatches = validatePortRangeSearch('', query) ?? [];

    if (!portRangeMatches.length) {
      throw new TypeError(intl('Common.InvalidPortRangeMessage'));
    }

    return portRangeMatches;
  }

  return [];
};

/**
 * validates an OS; if the operator is regex, it validates it as a regular expression;
 * otherwise, validates as any string; throws if validation fails;
 * @param query
 * @param operator
 */
export const validateOS = ({query = '', operator} = {}) => {
  if (query && operator === operatorOptions.regex.id) {
    validateRegex({query});
  }
};
