/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import apiSaga from 'api/apiSaga';
import {select, call, all} from 'redux-saga/effects';
import {Pill} from 'components';
import {getParameterizedPathLoosely} from 'api/apiUtils';
import {getOrgId} from 'containers/User/UserState';
import LabelEdit from 'containers/Label/Edit/LabelEdit';
import LabelGroupEdit from 'containers/LabelGroup/Edit/LabelGroupEdit';
import {getDiscardChangesModalProps} from 'components/UnsavedPendingWarning/UnsavedPendingWarningUtils';
import {sortOptions} from './SelectorUtils';
import {getLabelsQueryParams} from './SelectorSaga';
import styleUtils from 'utils.css';
import {getDisplayNames, getLabelSetting} from 'containers/Label/LabelSettings/LabelSettingState';
import {generalUtils} from '@illumio-shared/utils/shared';

export const ADD_NEW_LABEL_ID = 'ADD_NEW_LABEL_ID';
export const ADD_NEW_LABEL_GROUP_ID = 'ADD_NEW_LABEL_GROUP_ID';

export const formatSelectedLabel = ({
  value,
  onRemove,
  insensitive,
  theme,
  onClick,
  disabled,
  highlighted,
  resource,
}) => {
  const {
    optionProps: {allowMultipleSelection},
    selectedProps: {joinerIsPill = true},
  } = resource;

  return (
    <Pill.Label
      noContextualMenu
      theme={theme}
      highlighted={highlighted}
      themePrefix={allowMultipleSelection && joinerIsPill ? 'joinerValuePill-' : undefined}
      onClick={disabled || insensitive ? undefined : onClick}
      onClose={disabled || insensitive ? undefined : onRemove}
      type={value.key}
      group={value.href?.includes('label_groups')}
    >
      {value.value || value.name}
    </Pill.Label>
  );
};

export const formatSelectedLabelResource = ({valuesInResource, formatContent, resource: {selectedProps}, theme}) => {
  const labelsByTypeArray = Object.values(_.groupBy(valuesInResource, 'key'));

  return labelsByTypeArray.map((labelsByType, index) => {
    return (
      <div key={index} className={cx(styleUtils.gapInline, styleUtils.gapHorizontal, styleUtils.gapAlignBaseline)}>
        {index > 0 && selectedProps.resourceJoiner && (
          <div className={theme.joiner}>{selectedProps.resourceJoiner}</div>
        )}
        {formatContent(labelsByType)}
      </div>
    );
  });
};

const getNoLabelHref = (orgId, key) =>
  getParameterizedPathLoosely({
    orgId,
    params: {key, exists: false},
    path: '/orgs/:xorg_id/labels?key=:key&exists=:exists',
  });

export const reshuffleLabels = (evt, args) => {
  const {value, values, resource} = args;
  const objType = resource.id;

  if (values.has(objType)) {
    // Selected labels needs to be re-shuffled:
    // move all the selected labels that have the same type as the incoming selection to the end of the selection
    const [labelsInSelectedType, labelsInRemainingTypes] = _.partition(
      values.get(objType),
      ({key}) => key === value.key,
    );

    values.set(objType, [...labelsInRemainingTypes, ...labelsInSelectedType]);
  }
};

export function populateLabelTypesCategory({showInitial = false} = {}) {
  return {
    id: 'labelTypes',
    name: intl('Labels.Type'),
    resources: {
      labelTypes: {
        name: intl('Common.Type'),
        *statics({query}) {
          const setting = yield select(getLabelSetting);

          return sortOptions(
            setting.map(({display_info: {initial}, display_name, key, href}) => ({
              href,
              key,
              value: display_name,
              initial,
            })),
            query,
          );
        },
        optionProps: {
          idPath: 'key',
          format({option, formattedText}) {
            const label = (
              <Pill.Label noAffix position="before" type={option.key} noContextualMenu>
                {formattedText}
              </Pill.Label>
            );

            return showInitial ? (
              <div className={cx(styleUtils.gapXSmall, styleUtils.gapHorizontal, styleUtils.gapAlignBaseline)}>
                <div style={{width: 'var(--30px)', textAlign: 'right'}}>{option.initial}:</div>
                {label}
              </div>
            ) : (
              label
            );
          },
        },
      },
    },
  };
}

export function enableLabelTypeShortcut(
  labelResources,
  {labelResourceId = 'labelsAndLabelGroups', labelTypeInitialRegExp},
) {
  if (!(labelTypeInitialRegExp instanceof RegExp)) {
    throw new TypeError('Selector Labels category with label type shortcut must provide the prefix regular expression');
  }

  const labelTypeId = `${labelResourceId}_labelTypes`;

  const labelResource = labelResources[labelResourceId];

  if (!labelResource) {
    if (__DEV__) {
      console.error('enableLabelTypeShortcut must use on a LabelResource');
    }

    return;
  }

  const labelTypeResource = populateLabelTypesCategory({showInitial: true}).resources.labelTypes;

  labelTypeResource.hidden = ({keyword}) => keyword !== ':';
  labelTypeResource.queryKeywordsRegex = labelTypeInitialRegExp;
  labelTypeResource.onSelect = (evt, {values, setQuery, value}) => {
    setQuery(`${value.initial}:`);

    return values;
  };
  labelResources[labelTypeId] = labelTypeResource;

  if (__DEV__ && ('hidden' in labelResource || 'queryKeywordsRegex' in labelResource)) {
    console.warn("overriding label resource 'hidden' and 'queryKeywordsRegex'");
  }

  labelResource.queryKeywordsRegex = labelTypeInitialRegExp;
  labelResource.hidden = ({keyword}) => keyword === ':';

  return labelResources;
}

/**
 * Add the ability to create new label / label group.
 */
function enableLabelCreation(
  labelResources,
  {labelResourceId = 'labelsAndLabelGroups', labelTypeInitialRegExp, enabled = {label: true}},
) {
  const labelFormResourceId = `${labelResourceId}_labelForm`;
  const labelGroupFormResourceId = `${labelResourceId}_labelGroupForm`;

  const labelResource = labelResources[labelResourceId];

  labelResource.allowCreateOptions = (query, exactMatches) => {
    const showLabelGroupCreate = enabled.labelGroup && !exactMatches.some(({href}) => href?.includes('label_groups'));
    const showLabelCreate = enabled.label && !exactMatches.some(({href}) => href?.includes('labels'));

    return [
      ...(showLabelCreate ? [{id: ADD_NEW_LABEL_ID, value: `${query} (${intl('Labels.New')})`, isCreate: true}] : []),
      ...(showLabelGroupCreate
        ? [{id: ADD_NEW_LABEL_GROUP_ID, value: `${query} (${intl('LabelGroups.New')})`, isCreate: true}]
        : []),
    ];
  };
  labelResource.onCreateEnter = ({id}) => (id === ADD_NEW_LABEL_ID ? labelFormResourceId : labelGroupFormResourceId);

  if (enabled.label) {
    labelResources[labelFormResourceId] = {
      type: 'container',
      enableFocusLock: true,
      selectIntoResource: labelResourceId,
      container: LabelEdit,
      unsavedWarningData: {...getDiscardChangesModalProps('label')},
      hidden: true,
      queryKeywordsRegex: labelTypeInitialRegExp,
      containerProps: {
        controlled: true,
        buttonAlign: 'bottom',
        formProps: {id: labelFormResourceId},
        getContainerProps: ({query, onDone, onCancel}) => ({
          label: {value: query},
          onDone,
          onCancel,
        }),
      },
    };

    if (typeof enabled.label === 'object') {
      _.merge(labelResources[labelFormResourceId], enabled.label);
    }
  }

  if (enabled.labelGroup) {
    labelResources[labelGroupFormResourceId] = {
      type: 'container',
      enableFocusLock: true,
      selectIntoResource: labelResourceId,
      container: LabelGroupEdit,
      unsavedWarningData: {...getDiscardChangesModalProps('label_group')},
      hidden: true,
      queryKeywordsRegex: labelTypeInitialRegExp,
      containerProps: {
        controlled: true,
        buttonAlign: 'bottom',
        formProps: {id: labelGroupFormResourceId},
        getContainerProps: ({query, onDone, onCancel}) => ({
          labelGroup: {
            detail: {draft: {name: query}},
          },
          onDone,
          onCancel,
        }),
      },
    };

    if (typeof enabled.labelGroup === 'object') {
      _.merge(labelResources[labelGroupFormResourceId], enabled.labelGroup);
    }
  }

  return labelResources;
}

/**
 * This function always create a new object so consumer
 * can mutate the object freely using _.merge().
 * @param {{
 * resourceId?: string;
 * type?: 'include' | 'exclude';
 * labelType?: string;
 * labelTypesNameObj?: Record<string, string>,
 * labelTypeInitialRegExp?: RegExp,
 * hasTypeList?: boolean,
 * hasLabelGroups?: boolean,
 * resourceType?: string,
 * query?: Record<string, string>,
 * params?: Record<string, string>,
 * hasExists?: boolean,
 * hasAll?: boolean,
 * allowMultipleSelection?: boolean,
 * allowCreate?: boolean | {label: boolean | Record<string, unknown>, labelGroup: boolean | Record<string, unknown>},
 * }} param0
 * @returns
 */
export function populateLabelsCategory({
  resourceId,
  type = 'include',
  // a label type' key that limit the type of label[group] in the result
  labelType,
  labelTypesNameObj,
  labelTypeInitialRegExp,
  // whether show the label type shortcut
  // if label type is limited only to one, we shouldn't show the shortcut type list by default
  hasTypeList = !labelType,
  hasLabelGroups = true,
  resourceType,
  query,
  params,
  hasExists = false,
  hasAll = false,
  allowMultipleSelection = true,
  allowCreate = true,
  tooltipProps,
  // TODO: one we have built-in confirmation in Selector ListResource, we can use conflict instead
  onSelect,
}) {
  const labelResourceId = resourceId ?? 'labelsAndLabelGroups';

  // cloneDeep so mutation on the object doesn't leak into other objects
  // e.g. `query` or `params`
  const resources = _.cloneDeep({
    [labelResourceId]: {
      *dataProvider(apiOptions) {
        const {exclude_labels = [], exclude_label_groups = [], ...query} = apiOptions.query ?? {};

        query.max_results = 25;

        const {pversion = 'draft', ...params} = apiOptions.params ?? {};

        const [{data: {matches: labels = []} = {}}, {data: labelGroups} = {}] = yield all([
          call(apiSaga, 'labels.autocomplete', {
            params,
            query: {...query, ...(exclude_labels.length && {exclude_labels: JSON.stringify(exclude_labels)})},
          }),
          ...(hasLabelGroups
            ? [
                call(apiSaga, 'label_groups.autocomplete', {
                  params: {...params, pversion},
                  query: {
                    ...query,
                    ...(exclude_label_groups.length && {
                      exclude_label_groups: JSON.stringify(exclude_label_groups),
                    }),
                  },
                }),
              ]
            : []),
        ]);

        let options = labels;

        if (labelGroups) {
          options = [
            ...options,
            ...(labelGroups.matches?.map(({name, ...labelGroup}) => ({...labelGroup, value: name})) ?? []),
          ];
        }

        const orgId = yield select(getOrgId);
        const labelTypesNameObj = yield select(getDisplayNames);

        let noLabels = [];

        if (hasExists) {
          noLabels = Object.entries(labelTypesNameObj).map(([key, name]) => ({
            href: getNoLabelHref(orgId, key),
            value: intl('Common.NoLabels', {name}),
            key,
          }));

          if (query.key || query.exclude_keys) {
            noLabels = noLabels.filter(
              ({key}) => (!query.key || query.key === key) && !query.exclude_keys?.includes(key),
            );
          }
        }

        if (query.query) {
          return sortOptions([...noLabels, ...options], query.query);
        }

        return [...noLabels, ...sortOptions(options, query.query)];
      },
      includeSelectedResources: [labelResourceId],
      // TODO: support `labelType`
      queryKeywordsRegex: labelTypeInitialRegExp,
      apiArgs: {
        query: {
          resource_type: resourceType,
          *getQuery(...args) {
            const query = yield call(getLabelsQueryParams, ...args);

            query.key = labelType ?? query.key;

            return query;
          },
          ...query,
        },
        ...(params && {params}),
      },
      optionProps: {
        isPill: true,
        format: args => {
          const {option: label, fomattedOption, resource, values, footerCheckbox, theme} = args;

          if (label.isCreate) {
            return fomattedOption;
          }

          const {isNotStyle, ...labelPillProps} =
            generalUtils.callableValue(resource.optionProps.pillProps, {
              option: label,
              resource,
              values,
              footerCheckbox,
            }) ?? {};

          Object.assign(labelPillProps, {
            theme,
            themePrefix: labelPillProps.disabled ? 'pillDisabled-' : isNotStyle ? 'joinerValuePill-' : undefined,
            insensitive: true,
            noContextualMenu: true,
            type: label.key,
            group: label.href?.includes('label_groups'),
            exclusion: type === 'exclude',
          });

          let content = <Pill.Label {...labelPillProps}>{label.value ?? label.name}</Pill.Label>;

          if (isNotStyle) {
            content = (
              <Pill
                exclusionContent={isNotStyle ? intl('Common.IsNot') : undefined}
                exclusion={isNotStyle}
                theme={theme}
                themePrefix="isNotPill-"
              >
                {content}
              </Pill>
            );
          }

          return content;
        },
        filterOption: option =>
          (hasLabelGroups || !option.href?.includes('label_groups')) &&
          (hasExists || !option.href?.includes('exists')) &&
          (hasAll || !option.href?.includes('all')),
        allowMultipleSelection,
        tooltipProps: tooltipProps || {
          content: ({option: label}) => labelTypesNameObj?.[label.key],
        },
        hint: option => labelTypesNameObj?.[option.key],
      },
      selectedProps: {
        valueJoiner: 'and',
        hideResourceName: true,
        isPill: false,
        formatValue: formatSelectedLabel,
        formatResource: formatSelectedLabelResource,
      },
      onSelect: onSelect ?? reshuffleLabels,
      conflict: (selected, incoming) => {
        if (incoming.resource.optionProps?.allowMultipleSelection) {
          return false;
        }

        return selected.resource.id === 'allLabel' || selected.value.key === incoming.value.key;
      },
    },
  });

  if (hasTypeList) {
    enableLabelTypeShortcut(resources, {labelResourceId, labelTypeInitialRegExp});
  }

  if (allowCreate) {
    enableLabelCreation(resources, {
      labelResourceId,
      labelTypeInitialRegExp,
      enabled: typeof allowCreate === 'object' ? allowCreate : {label: true, labelGroup: hasLabelGroups},
    });
  }

  return {
    id: 'labelsAndLabelGroups',
    name: hasLabelGroups ? intl('Rulesets.Rules.LabelAndLabelGroups') : intl('Common.Labels'),
    resources,
  };
}

/**
 * Use for category that dynamically generates options based on query.
 * E.g. PortAndOrProtocol, Port, PortRange
 */
export const populateDynamicOptionsCategory = ({
  /** getOptions Must always return an array */
  getOptions,
  id,
  name,
  placeholder,
  allowMultipleSelection = false,
  errorMessage,
  emptyBannerContent,
  conflict,
}) => ({
  id,
  name,
  placeholder,
  noActiveIndicator: true,
  resources: {
    [id]: {
      name,
      statics: ({query}) => {
        if (query === '') {
          return;
        }

        return getOptions(query);
      },
      noHistory: true,
      selectedProps: {hideResourceName: true},
      validate: query => {
        if (query?.length && getOptions(query).length === 0) {
          throw new Error(errorMessage);
        }
      },
      emptyBannerContent,
      optionProps: {allowMultipleSelection},
      conflict,
    },
  },
});

export const populateServiceCategory = ({id = 'service', name = intl('Common.Service'), conflict} = {}) => ({
  id,
  name,
  resources: {
    [id]: {
      dataProvider: 'services.autocomplete',
      apiArgs: {params: {pversion: 'draft'}},
      selectedProps: {
        hideResourceName: true,
        pillPropsValue: {icon: 'service', noContextualMenu: true},
      },
      optionProps: {
        format: ({option}) => <Pill.Service value={option} insensitive noContextualMenu />,
      },
      conflict,
    },
  },
});

export const CategoryPresets = {
  labelsAndLabelGroups: populateLabelsCategory,
  service: populateServiceCategory,
};
