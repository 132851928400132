/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import apiSaga from 'api/apiSaga';
import {eventChannel} from 'redux-saga';
import Visibility from 'visibilityjs';
import {UNAUTHORIZED, FORBIDDEN} from 'http-status-codes';
import * as progressBar from 'progressBar';
import {BroadcastChannel} from 'broadcast-channel';
import PubSub from 'pubsub';
import {errorUtils, webStorageUtils, hrefUtils} from '@illumio-shared/utils';
import * as UserSelectors from 'containers/User/UserState';
import {call, delay, select, spawn, put, fork, take, all} from 'redux-saga/effects';
import {toggleXpressIntlMapping} from './AppUtils';
import {startFetchPendingPolling, stopFetchPendingPolling} from 'containers/Provisioning/ProvisioningSaga';
import {startFetchHealthPolling, stopFetchHealthPolling} from 'containers/Health/HealthSaga';
import {
  getVersionMismatch,
  isCoreServicesEnabled,
  getWillBeCSFrame,
  getRouteName,
  isUIAnalyticsEnabled,
  isIlluminationApiEnabled,
} from './AppState';
import {fetchVenLibraries} from 'containers/Ven/Library/VenLibrarySaga';
import {fetchDeviceTypes} from 'containers/LoadBalancer/List/LoadBalancerListSaga';
import {loginUser, logoutUser, startFetchUserOrgPolling, stopFetchUserOrgPolling} from 'containers/User/UserSagas';
import {fetchUserSettings} from 'containers/User/Settings/SettingsSaga';
import {fetchSelectorHistory} from 'containers/Selector/SelectorSaga';
import {isMyManagedTenantsEnabled} from 'msp/containers/MyManagedTenants/MyManagedTenantsState';
import {fetchLabelSettings} from 'containers/Label/LabelSettings/LabelSettingsSaga';
import {fetchCoreServicesSettings} from 'containers/CoreServices/Settings/CoreServicesSettingsSaga';
import {fetchMSPTenants} from 'msp/containers/MyManagedTenants/List/MyManagedTenantsListSaga';
import {fetchAppGroupSummary} from 'containers/AppGroups/AppGroupSaga';
import {createPairingProfile, updatePairingProfile} from 'containers/PairingProfile/Item/PairingProfileItemSaga';
import {fetchPairingProfiles} from 'containers/PairingProfile/List/PairingProfileListSaga';
import {filterToQuery} from 'containers/Selector/GridFilter/GridFilterUtils';
import {isContainerClustersEnabled} from 'containers/ContainerCluster/ContainerClusterState';

export function* fetchCommonData(options) {
  if (Visibility.state() === 'prerender') {
    // If document is prerendering (like in Safari) before user actually pressed enter on the url,
    // wait for any other visibility state to actually start fetching data.
    // It's ok to fetch and render on hidden page, for instance if user opened new tab in background (like cmd+enter)
    yield take(['DOCUMENT_HAS_BECOME_VISIBLE', 'DOCUMENT_HAS_BECOME_HIDDEN']);
  }

  let user = yield select(UserSelectors.getUser);

  if (!user.href) {
    try {
      // Try to login user on PCE with or without authToken
      // If without, browser will send pce_session cookie, that we can't read from javascript (it is HttpOnly)
      user = yield call(loginUser, {authToken: window.authToken});
    } catch (err) {
      if (err instanceof errorUtils.APIError) {
        // If user session does not exist, save url and redirect to login server
        // Other errors will be handled by the App.js or NavigationAlert.js
        // 401
        if ([UNAUTHORIZED].includes(err.response.status)) {
          saveUriForLoginRedirect();

          window.location = err.response?.headers?.get('x-redirect') ?? _loginHref_;

          return yield delay(1e4); // Because redirection is not instant
        }

        // If user doesn't have a permission redirect to a special endpoint login server endpoint EYE-71824
        // 403
        if ([FORBIDDEN].includes(err.response.status) && err.data?.[0]?.token === 'rbac_no_permission_to_this_org') {
          saveUriForLoginRedirect();

          window.location = _noAccessLoginHref_;

          return yield delay(1e4); // Because redirection is not instant
        }
      }

      throw err;
    }

    if (!user.href || _.isEmpty(user.orgs)) {
      progressBar.end();

      return;
    }

    // check version mismatch, e.g. 0 !=== PCE and UI are different
    if ((yield select(getVersionMismatch)) !== 0 && options.name !== 'app.versionmismatch') {
      throw new errorUtils.RedirectError({to: 'versionmismatch', proceedFetching: true, thisFetchIsDone: true});
    }

    // Get the set of enabled features via optional features api and org flags
    if (!__MSP__) {
      yield call(fetchOptionalFeatures);
    }

    if (__ANTMAN__) {
      toggleXpressIntlMapping();
    }

    const isOwner = yield select(UserSelectors.isUserOwner);
    const isAdmin = yield select(UserSelectors.isUserAdmin);
    const isScopedUser = yield select(UserSelectors.isUserScoped);
    const coreServicesIsEnabled = yield select(isCoreServicesEnabled);
    const illuminationApiIsEnabled = yield select(isIlluminationApiEnabled);
    const containerClustersIsEnabled = yield select(isContainerClustersEnabled);

    if (!__MSP__) {
      yield fork(fetchOrgInstance);

      // Get organization's settings
      yield fork(fetchOrgSettings);
    }

    if (!__ANTMAN__ && !__MSP__) {
      yield fork(fetchLabelSettings);
    }

    // Get the user settings
    yield fork(fetchUserSettings);

    // Get Core Service settings
    if ((isOwner || isAdmin) && coreServicesIsEnabled) {
      // we need to fork because rendering core services in side navigation depends on this information
      yield fork(fetchCoreServicesSettings);
    }

    if (!__MSP__ && isScopedUser && illuminationApiIsEnabled) {
      yield spawn(fetchAppGroupSummary);
    }

    if (__MSP__) {
      const routeName = yield select(getRouteName);

      if (routeName === 'app.landing') {
        const myManagedTenantsIsEnabled = yield select(isMyManagedTenantsEnabled);

        if (myManagedTenantsIsEnabled) {
          const data = yield call(fetchMSPTenants, {force: true});

          // For users who have used the product previously, take them directly to the Managed Tenants page.
          if (data?.length > 0) {
            throw new errorUtils.RedirectError({to: 'mymanagedtenants', proceedFetching: true, thisFetchIsDone: true});
          }
        }
      }
    }

    if (containerClustersIsEnabled) {
      yield fork(fetchClasMode);
    }

    if (__ANTMAN__) {
      const {data} = yield call(apiSaga, 'protection_schemas.get_collection', {cache: false});

      yield put({
        type: 'SET_PROTECTION_SCHEMAS',
        data: [...data, {rule_set_external_data_reference: 'None', name: intl('Common.None')}],
      });

      const xpressPairingProfiles = yield fetchPairingProfiles({
        filter: {
          external_data_reference: ['xpress_server_default'],
          external_data_set: ['com.illumio.ilo_defaults.pairingprofiles'],
        },
        force: true,
      });

      const dayInSeconds = 60 * 60 * 24;
      const external_data_set = 'com.illumio.ilo_defaults.pairingprofiles';

      if (xpressPairingProfiles.count.matched === 0) {
        yield createPairingProfile({
          name: 'Xpress Default',
          enabled: true,
          enforcement_mode: 'idle',
          key_lifespan: dayInSeconds,
          external_data_reference: 'xpress_server_default',
          external_data_set,
        });
      } else if (
        xpressPairingProfiles.list[0].external_data_set === external_data_set &&
        xpressPairingProfiles.list[0].key_lifespan !== dayInSeconds
      ) {
        const {href} = xpressPairingProfiles.list[0];

        yield call(updatePairingProfile, hrefUtils.getId(href), {key_lifespan: dayInSeconds});
      }
    }

    if (__ANTMAN__) {
      try {
        // Initialize 3rd party feature flag service
        const {initClient} = yield import(/* webpackChunkName: 'featureFlags' */ 'utils/featureFlags');
        const featureFlagClient = initClient(user, window.location);

        // Feature Flag client is ready to use once initialization is complete
        yield featureFlagClient.waitUntilReady();

        // Set up default feature flags
        const {featureFlagDefaults} = yield import(
          /* webpackChunkName: 'featureFlagDefaults' */ 'antman/utils/featureFlagDefaults'
        );

        yield put({type: 'GET_FEATURE_FLAG_DEFAULTS', data: featureFlagDefaults});

        // Set up configured feature flags in 3rd party service
        const flags = featureFlagClient.allFlags();

        yield put({type: 'GET_FEATURE_FLAGS', data: flags});
      } catch (error) {
        console.error(`Error attempting to initialize Feature Flags: ${error}`);
      }

      if (!webStorageUtils.getItem('theme')) {
        // for new customers who won't have theme set localStorage
        const newTheme = 'lightning';

        webStorageUtils.setItem('theme', newTheme);
        yield put({type: 'SET_THEME', theme: newTheme});
        document.documentElement.dataset.theme = newTheme;
      }
    }

    // Default Xpress Lightning flags
    if (__ANTMAN__) {
      // Set up default feature flags
      const {featureFlagDefaults} = yield import(
        /* webpackChunkName: 'featureFlagDefaults' */ 'antman/utils/featureFlagDefaults'
      );

      yield put({type: 'GET_FEATURE_FLAG_DEFAULTS', data: featureFlagDefaults});
    }

    const uiAnalyticsIsEnabled = yield select(isUIAnalyticsEnabled);
    const isCSFrame = yield select(getWillBeCSFrame);

    if (uiAnalyticsIsEnabled) {
      const loginUrl = new URL(user.login_url);

      try {
        import(/* webpackChunkName: 'analytics' */ 'utils/analytics').then(({default: loadDAPAnalytics}) => {
          loadDAPAnalytics(user, isCSFrame);
        });
      } catch (error) {
        console.error(`Error attempting to initialize DAP analytics: ${error}`);
      }

      try {
        import(/* webpackChunkName: 'logrocket' */ 'logrocket').then(({default: LogRocket}) => {
          // Setup analytics
          const appID = __ANTMAN__
            ? 'rgjofh/masked-commercial-staging'
            : `rgjofh/${__TARGET__.toLowerCase()}-production`;

          LogRocket.init(appID, {
            shouldCaptureIP: false,
            ...(!__ANTMAN__ && {
              dom: {
                isEnabled: true,
                inputSanitizer: true,
                textSanitizer: true,
              },
            }),
            network: {
              isEnabled: false,
            },
          });

          // Since Org IDs + User IDs are not guaranteed to be unique between clusters
          // lets tie the hostname to the org ID and user ID.
          LogRocket.identify(user.id, {
            orgId: user.org_id,
            hostname: loginUrl.hostname,
          });
        });
      } catch (error) {
        console.error(`Error attempting to initialize LogRocket analytics: ${error}`);
      }
    }
  }
}

// Polling saga called after all content is rendered for the first time
export function* fetchDeferredPollingData() {
  // Get selector history
  yield spawn(fetchSelectorHistory);

  yield spawn(startFetchUserOrgPolling, {instantStart: false});

  if (!__MSP__) {
    // Start provision polling cycle to show provision count on menu (doesn't block this saga) except for workload manager
    yield spawn(startFetchPendingPolling, {instantStart: true});

    yield spawn(startFetchHealthPolling, {instantStart: true});
  }
}

// One-off saga, that is called from App.js after all content is rendered for the first time, and
// starts making some calls after that to not block the AppSaga itself or current route children's saga
export function* fetchDeferredData() {
  if (!__MSP__) {
    // Check if any VENs are installed to determine whether to display in main menu
    yield spawn(fetchVenLibraries);

    yield spawn(fetchDeviceTypes); // show SLB page when >= 1 NFCs
  }

  yield spawn(watchUserOrgChanges);

  if (!__MSP__) {
    // Check provision calculation progress status (doesn't block this saga)
    yield put({type: 'PROVISION_CHECK_CALC_PROGRESS'});
  }
}

// Restart polling fetches on user org permission changes
export function* watchUserOrgChanges() {
  while (true) {
    if (!__MSP__) {
      yield call(fetchDeferredPollingData);
    }

    yield take('USER_GET_ORG_SUCCESS');

    yield all([
      ...(__MSP__ ? [] : [call(stopFetchPendingPolling), call(stopFetchHealthPolling)]),
      call(stopFetchUserOrgPolling),
    ]);
  }
}

const logoutBroadcastChannel = new BroadcastChannel('userLogoutChannel', {webWorkerSupport: false});
const logoutSagaChannel = () =>
  eventChannel(emitter => {
    logoutBroadcastChannel.onmessage = event => {
      emitter({url: event.data});
    };

    return logoutBroadcastChannel.close;
  });

export function* watchLogout() {
  // Spawn a saga to transform logout channel to LOGOUT action
  yield spawn(function* () {
    const {url} = yield take(yield call(logoutSagaChannel));

    yield put({type: 'LOGOUT', url, broadcasted: true});
  });

  while (true) {
    const {url, broadcasted = false, unsavedPendingWarning} = yield take('LOGOUT');

    if (unsavedPendingWarning) {
      const answer = yield new Promise(resolve => {
        PubSub.publish('UNSAVED.WARNING', {resolve});
      });

      if (answer === 'cancel') {
        continue;
      }
    }

    // In case of logout/session expiration
    // Reset formIsDirty in prefetcher to prevent 'beforeunload' displaying browser native leave page warning
    PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});

    const user = yield select(UserSelectors.getUser);

    const redirectUrl = url || `${user.login_url ?? _loginPureHref_ ?? ''}/logout`;

    // clear PCE session
    yield call(logoutUser, user.id);

    webStorageUtils.removeItem('user_id');
    webStorageUtils.removeItem(`${browser.code}WarningClosed`);
    webStorageUtils.clearSession();

    saveUriForLoginRedirect();

    if (!broadcasted) {
      logoutBroadcastChannel.postMessage(redirectUrl);
    }

    window.location = redirectUrl;
  }
}

export function saveUriForLoginRedirect() {
  const uri = window.getUri();

  if (uri !== '/landing') {
    window.saveUriForLoginRedirect(uri);
  }
}

export function* fetchOptionalFeatures() {
  try {
    const {data} = yield call(apiSaga, 'optional_features.get');

    yield put({type: 'OPTIONAL_FEATURES', data});
  } catch (error) {
    console.warn('Could not fetch Optional Features:', error.message);
  }
}

export function* updateOptionalFeatures(data) {
  yield call(apiSaga, 'optional_features.update', {data});
  yield call(fetchOptionalFeatures);
}

export function* fetchOrgInstance() {
  const {data} = yield call(apiSaga, 'orgs.get_instance');

  yield put({type: 'GET_ORG_INSTANCE', data});
}

export function* updateOrgDefaultAppGroups(orgId, data) {
  yield call(apiSaga, 'org.update', {params: {xorg_id: orgId}, data});
}

export function* updateOrgInstance({requireSecPolicyCommitMessage, reverseProviderConsumer}) {
  yield call(apiSaga, 'org.update', {
    data: {
      require_sec_policy_commit_message: requireSecPolicyCommitMessage === 'yes',
      reverse_provider_consumer_column: reverseProviderConsumer === 'consumerFirst',
    },
  });
  yield call(fetchOrgInstance);
}

export function* fetchOrgSettings() {
  const {data} = yield call(apiSaga, 'settings.get');

  yield put({type: 'GET_ORG_SETTINGS', data});
}

export function* updateOrgSettings(data) {
  yield call(apiSaga, 'settings.update', {data});
  yield call(fetchOrgSettings);
}

export function* fetchClasMode() {
  return yield call(apiSaga, 'container_clusters.get_collection', {
    query: filterToQuery({clas_mode: [true]}, {max_results: 0}),
    cache: false,
    *onDone({headers}) {
      const clasMode = Number(headers.get('x-matched-count')) > 0;

      yield put({type: 'SET_CLAS_MODE', data: {clasMode}});
    },
  });
}
