/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {produce} from 'immer';
import {createSelector} from 'reselect';
import {getRouteCurrent, getVersionMismatch, isHealthEnabled} from 'containers/App/AppState';
import {
  isUserReadOnly,
  isUserReadOnlyAll,
  isUserAdmin,
  isUserOwner,
  isSuperclusterMember,
  isUserWithReducedScope,
} from 'containers/User/UserState';
import {getClusters, getLocalCluster, getInRollingUpgrade} from 'containers/Health/HealthState';
import {isRollingUpgradeUnknown} from 'containers/Health/HealthUtils';

export default {
  header: produce((draft, action) => {
    if (action.type === 'HEADER_PUSH') {
      // Append new header values into the stack
      draft.push(action.values);
    } else if (action.type === 'HEADER_POP') {
      // Remove header values from the stack
      draft.splice(
        draft.findIndex(item => item.id === action.id),
        1,
      );
    } else if (action.type === 'HEADER_REPLACE') {
      // Replace header values in the stack
      draft[draft.findIndex(item => item.id === action.values.id)] = action.values;
    }
  }, []),
};

export const getHeaderState = createSelector(
  state => state.header,
  array => array.reduce((result, {id, ...item}) => ({...result, ...item, ids: result.ids.concat(id)}), {ids: []}),
);

export const getHeaderHealthData = createSelector(
  [isSuperclusterMember, getClusters, getLocalCluster, isHealthEnabled, getInRollingUpgrade],
  (isSuperclusterMember, allClusters, localCluster, healthEnabled, inUpgrade) => {
    // Don't show status and count from other clusters on Supercluster Member
    // as  in the UI, the other member information needs to be suppressed
    const clusters = isSuperclusterMember && localCluster ? [localCluster] : allClusters;

    let clusterCount = 0;
    let clusterStatus = 'Normal';

    clusters.forEach(healthStatus => {
      const status = healthStatus.status;
      const clusterUnknown = isRollingUpgradeUnknown(inUpgrade, healthStatus);

      // Don't set any clusterCount or clusterStatus during unknown rolling upgrade
      if (!clusterUnknown) {
        if (status !== 'normal') {
          clusterCount++;
        }

        if (status === 'warning' && status !== 'critical') {
          clusterStatus = 'Degraded';
        }

        if (status === 'critical') {
          clusterStatus = 'Error';
        }
      }
    });

    return {
      clusterCount,
      clusterStatus,
      healthEnabled,
    };
  },
);

export const getHeaderProps = createSelector(
  [
    getHeaderState,
    getRouteCurrent,
    isUserReadOnly,
    isUserReadOnlyAll,
    isUserAdmin,
    isUserOwner,
    isUserWithReducedScope,
    getHeaderHealthData,
    getVersionMismatch,
  ],
  (
    headerState,
    route,
    userIsReadOnly,
    userIsReadOnlyAll,
    userIsAdmin,
    userIsOwner,
    userIsWithReducedScope,
    {clusterCount, clusterStatus, healthEnabled},
    versionMismatch,
  ) => {
    const result = {
      ...headerState,
      route,
      userIsOwner,
      userIsAdmin,
      userIsReadOnly,
      userIsReadOnlyAll,
      userIsWithReducedScope,
      clusterCount,
      clusterStatus,
      healthEnabled: healthEnabled && (userIsOwner || userIsAdmin || userIsReadOnly),
      versionMismatch,
    };

    return result;
  },
);
