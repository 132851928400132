/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {useCallback, useState, useRef, type KeyboardEvent, type MouseEvent} from 'react';
import {Icon, Link} from 'components';
import {useSelector} from '@illumio-shared/utils/redux';
import {getContainersPropertiesMap} from 'containers/ContainersProperties';
import {getRouteCurrent, getRouteParams} from 'containers/App/AppState';
import {motion, AnimatePresence} from 'framer-motion';
import styles from './SideNavigation.css';
import type {IconName} from 'components/Icon/IconName';
import {tidUtils, typesUtils} from '@illumio-shared/utils';

export interface SideNavigationItemProps {
  children?: (typesUtils.ReactStrictNode & {props: Record<string, string>})[];
  container: string;
  defaultOpen?: boolean;
  collapsed?: boolean;
  groupIsActive?: boolean;
}

interface ContainerPropsIn {
  viewName: string;
  linkProps: {
    to: string;
    href?: string;
    target?: string;
    params?: Record<string, string>;
    replace?: boolean;
  };
  icon: IconName;
  isAvailable: boolean;
}

type ContainerProps = Map<string, ContainerPropsIn>;

const defaultTid = 'comp-sidenav-item';

export default function SideNavigationItem(props: SideNavigationItemProps): JSX.Element {
  const itemProps = useSelector(getContainersPropertiesMap) as ContainerProps;
  const params = useSelector(getRouteParams);
  const currentRoute = useSelector(getRouteCurrent);
  const focuser = useRef<HTMLElement | null>(null);

  const {children, container, defaultOpen, collapsed, groupIsActive} = props;
  const {viewName, linkProps, icon, isAvailable} = itemProps.get(container) || {};
  const [open, setOpen] = useState(defaultOpen || groupIsActive || false);
  const [focused, setFocused] = useState(false);
  const tids = [viewName];
  const tidProps: Record<string, string> = {};
  let content;

  tidProps['data-tid'] = tidUtils.getTid(defaultTid, tids);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const focusElement = useCallback((element: HTMLElement) => {
    element.focus();
    focuser.current = element;
  }, []);

  const handleMouseOver = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();

      if (!focuser.current && evt.target instanceof HTMLElement) {
        focusElement(evt.target);
      } else if (document.activeElement !== focuser.current) {
        focusElement(focuser.current!);
      }
    },
    [focusElement],
  );

  const handleMouseLeave = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    setFocused(false);

    if (focuser.current && document.activeElement === focuser.current) {
      focuser.current.blur();
    }
  }, []);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    // Unhighlight row on focuser blur
    setFocused(false);
  }, []);

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      const {keyCode} = evt;

      if (keyCode === 13) {
        toggle();
      }
    },
    [toggle],
  );

  if (linkProps && viewName && icon && isAvailable) {
    content = (
      <Link {...linkProps} {...tidProps} themePrefix="menuItem-" theme={styles} activityTrack>
        <div className={styles.menuName}>
          <Icon
            name={icon}
            themePrefix="menu-"
            theme={styles}
            tooltip={collapsed && viewName}
            tooltipProps={{right: true, theme: styles, themePrefix: 'icon-'}}
          />
          <span className={styles.name}>{viewName}</span>
        </div>
      </Link>
    );
  } else if (viewName && icon && isAvailable) {
    content = (
      <div className={styles.menuName}>
        <Icon name={icon} themePrefix="menu-" theme={styles} />
        <span className={styles.name}>{viewName}</span>
      </div>
    );
  }

  // filter children that have isAvailable as false
  const availableMenuItems = children?.filter(item => {
    const {container} = item.props;
    const {isAvailable} = itemProps.get(container) || {};

    return isAvailable;
  });

  if (isAvailable && availableMenuItems?.length && !collapsed) {
    return (
      <ul className={styles.menu}>
        <li
          tabIndex={0}
          onClick={toggle}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          className={cx(styles.menuInformation, {
            [styles.activeGroup]: groupIsActive,
            [styles.menuOpen]: open,
            [styles.menuClosed]: !open,
            [styles.focused]: focused,
          })}
          {...tidProps}
        >
          {content}
          <Icon name={open ? 'up' : 'down'} themePrefix={open ? 'upArrow-' : 'downArrow-'} theme={styles} />
        </li>
        <AnimatePresence initial={false}>
          {open &&
            availableMenuItems?.map((item, index) => {
              const {container} = item.props;
              const {viewName, linkProps} = itemProps.get(container) || {};
              const tids = [viewName];
              let display = viewName;
              let active;

              // illumination param is table, but viewName for it is Traffic
              if (viewName === 'Traffic') {
                display = 'table';
              }

              // special edge case for Xpress - workloads, servers & endpoints share same route differentiated by type param
              // additionally, VENS page is subroute of workloads, so that case needs to be handled too
              if (__ANTMAN__ && linkProps?.to === 'workloads' && currentRoute.name.includes('workloads')) {
                active = !currentRoute.name.includes('vens') && params?.type === linkProps.params?.type;
              } else if (params.display) {
                active = params.display === display?.toLowerCase();
              }

              return (
                <motion.li
                  className={cx(styles.menuItem, {[styles.focused]: focused})}
                  key={index}
                  initial="collapsed"
                  animate="open"
                  variants={{
                    open: {opacity: 1, height: 'auto'},
                    collapsed: {opacity: 0, height: 0},
                  }}
                  transition={{duration: 0.2, ease: 'easeInOut'}}
                >
                  <Link
                    themePrefix="menuItem-"
                    active={active}
                    activityTrack
                    theme={styles}
                    data-tid={tidUtils.getTid(defaultTid, tids)}
                    {...linkProps}
                  >
                    {viewName as string}
                  </Link>
                </motion.li>
              );
            })}
        </AnimatePresence>
      </ul>
    );
  }

  return <li className={styles.menuItem}>{content}</li>;
}
