/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {isUserReadOnly} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {selectedGridSettings, dependencyGridSettings} from './ProvisionConfig';
import {getSecPolicyRows} from 'containers/Provisioning/Pending/List/PendingListState';
import {hasListenOnlyMember} from 'containers/Health/HealthState';
import {requireProvisionNote, getRouteName, getIsCSFrame} from 'containers/App/AppState';

import {getProvisionCounts, formatCountsForTally, getProvisionCountsTotal} from '../ProvisioningUtils';

export default {
  // List of selected objects for provision/revert by user
  selection(state = {}, action) {
    switch (action.type) {
      case 'PROVISION_SELECTION':
        return action.data.selection;
      default:
        return state;
    }
  },
  // Sec policies dependencies list with query params (check saga)
  dependencies(state = [], action) {
    // Don't set if current and new are both empty
    if (action.type === 'PROVISION_GET_DEPENDENCIES' && (action.data.length || state.length)) {
      return action.data;
    }

    return state;
  },
  // Sec policies create result
  provisionCompleted(state = {}, action) {
    switch (action.type) {
      case 'PROVISION_COMPLETED':
        return action.data;
      default:
        return state;
    }
  },
};

export const getSelection = state => state.provisioning.selection;
export const getDependencies = state => state.provisioning.dependencies;
export const getProvisionCompleted = state => state.provisioning.provisionCompleted;

export const getListForGrid = createSelector(
  [getSelection, getDependencies, getSecPolicyRows],
  (selection, dependencies, secPolicies) => {
    const selectedList = [];
    const dependencyList = [];
    const changeSubset = selection.change_subset;

    if (!_.isEmpty(changeSubset)) {
      Object.values(changeSubset).forEach(items => {
        for (const item of items) {
          const secPolicy = secPolicies.find(secPolicy => secPolicy.key === item.href);

          if (secPolicy) {
            secPolicy.dependencies = [];

            selectedList.push(secPolicy);
          }
        }
      });

      //Each object in dependencies array (a row in dependency grid) contains the list of objects that requires it
      dependencies.forEach(obj => {
        const objType = Object.keys(obj.dependency)[0]; //type of object is the first prop of dependency object
        const alreadySelected = selectedList.findIndex(o => o.key === obj.dependency[objType].href);

        if (alreadySelected !== -1) {
          return;
        }

        const dependencySecPolicy = secPolicies.find(secPolicy => secPolicy.key === obj.dependency[objType].href);

        if (dependencySecPolicy) {
          dependencySecPolicy.requiredBy = [];

          if (obj.required_by) {
            Object.keys(obj.required_by).forEach(objType => {
              return obj.required_by[objType].forEach(item => {
                const selectedListObjIndex = selectedList.findIndex(o => o.key === item.href);

                if (selectedListObjIndex !== -1) {
                  selectedList[selectedListObjIndex].dependencies.push(dependencySecPolicy.key);
                }

                const requiredByObj = secPolicies.find(secPolicy => secPolicy.key === item.href);

                if (!dependencySecPolicy.requiredBy.some(({key}) => key === requiredByObj.key)) {
                  dependencySecPolicy.requiredBy.push(requiredByObj);
                }
              });
            });
          }

          //Ensure all requiredBy fields into single outbound requiredBy property so duplicate outbound rows are not created

          dependencyList.push(dependencySecPolicy);
        }
      });
    }

    return {selectedList, dependencyList};
  },
);

const getSelectedGridRows = createSelector(getListForGrid, list => list.selectedList);
const getDependencyGridRows = createSelector(getListForGrid, list => list.dependencyList);

const getSelectedGrid = state =>
  getGridSelector(state, {
    settings: selectedGridSettings,
    rows: getSelectedGridRows,
  });

const getDependencyGrid = state =>
  getGridSelector(state, {
    settings: dependencyGridSettings,
    rows: getDependencyGridRows,
  });

export const getProvisionPage = createSelector(
  [getSelectedGrid, getDependencyGrid, isUserReadOnly, hasListenOnlyMember, requireProvisionNote],
  (selectedGrid, dependencyGrid, userIsReadOnly, hasListenOnlyMember, requireProvisionNote) => {
    const counts = getProvisionCounts([...selectedGrid.rows, ...dependencyGrid.rows]);
    const tallyItems = [
      {children: 'Total : ', count: getProvisionCountsTotal(counts)},
      ...formatCountsForTally(counts),
    ];

    return {
      selectedGrid,
      dependencyGrid,
      tallyItems,
      userIsReadOnly,
      hasListenOnlyMember,
      requireProvisionNote,
    };
  },
);

export const getProvisionPopupContent = createSelector(
  [
    getSelectedGridRows,
    getDependencyGridRows,
    isUserReadOnly,
    hasListenOnlyMember,
    requireProvisionNote,
    selectedGridSettings,
    dependencyGridSettings,
    getRouteName,
    getIsCSFrame,
  ],
  (
    selectedGridRows,
    dependencyGridRows,
    userIsReadOnly,
    hasListenOnlyMember,
    requireProvisionNote,
    selectedGridSettings,
    dependencyGridSettings,
    routeName,
    isCSFrame,
  ) => {
    const counts = getProvisionCounts([...selectedGridRows, ...dependencyGridRows]);
    const tallyItems = [
      {children: 'Total : ', count: getProvisionCountsTotal(counts)},
      ...formatCountsForTally(counts),
    ];

    // Remove any enforcement boundary rows(undefined typeLabel rows) to be provisioned from grid list.
    // Enf boundaries were added as part of secPolicyRows to show modal
    // with revert error modal when a deny rule is associated specific policy versions like service/iprange
    // in service edit and iprange edit pages in EDGE.
    // Refer: https://jira.illum.io/browse/EYE-79660

    return {
      selectedGridSettings,
      dependencyGridSettings,
      selectedGridRows,
      dependencyGridRows,
      tallyItems,
      userIsReadOnly,
      hasListenOnlyMember,
      requireProvisionNote,
      routeName,
      isCSFrame,
    };
  },
);
