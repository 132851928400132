/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {ipUtils} from '.';
import type {DifferenceComparisonWithIntersection} from './types';

interface IpList {
  created_at: Date;
  created_by: {href: string};
  deleted_at: Date;
  deleted_by: string | null;
  description: string;
  fqdns: {[name: string]: boolean | string}[];
  href: string;
  ip_ranges: {from_ip: string; exclusion: boolean; type?: string; removed?: boolean}[];
  exclusion: boolean;
  from_ip: string;
  name: string;
  update_type: string | null;
  updated_at: Date;
  updated_by: {href: string};
  updatedBy: {[name: string]: string};
}

interface IpOptions {
  nameType?: string;
  descriptionType?: string;
}

/**
 * Compare both the oldList and newList ip versions then combine them by updating the type flag.
 * Having the type flag is needed for Edit and View to see the old and new.
 *
 * @param oldList active version
 * @param newList draft version
 * @returns
 */
export const ipListsDiff: DifferenceComparisonWithIntersection<IpList, IpOptions> = (newList, oldList) => {
  // newList will always exist regardless when ip is deleted, active or in draft
  const redLines = _.cloneDeep(newList);

  let type = 'old';

  if (oldList && newList && redLines !== undefined) {
    redLines.nameType = oldList.name === newList.name ? 'old' : 'new';
    redLines.descriptionType = oldList.description === newList.description ? 'old' : 'new';
  } else {
    // If only one list is valid choose the default type
    type = newList ? 'new' : 'old';

    if (redLines) {
      redLines.nameType = type;
      redLines.descriptionType = type;
    }
  }

  // Set the ip_range types
  if (redLines?.ip_ranges) {
    redLines.ip_ranges.map(redRange => {
      redRange.type = type;

      // If not found in the old list, mark it new
      if (oldList && !_.some(oldList.ip_ranges, oldRange => ipUtils.areIpRangesEqual(oldRange, redRange))) {
        redRange.type = 'new';
      }

      return redRange;
    });
  }

  // Add deleted ranges and mark as state = removed
  if (oldList && newList) {
    if (redLines?.ip_ranges) {
      oldList.ip_ranges.forEach(oldRange => {
        // Old range is not found in the new set of ranges add it to the front of the red-lines
        if (!_.some(redLines.ip_ranges, redRange => ipUtils.areIpRangesEqual(oldRange, redRange))) {
          const deletedRange = oldRange;

          deletedRange.type = 'old';
          deletedRange.removed = true;
          redLines.ip_ranges.unshift(deletedRange);
        }
      });
    }
  }

  if (redLines?.fqdns) {
    redLines.fqdns.map(redFqdn => {
      redFqdn.type = type;

      // If not found in the old list, mark it new
      if (oldList && !_.some(oldList.fqdns, oldFqdn => oldFqdn.fqdn === redFqdn.fqdn)) {
        redFqdn.type = 'new';
      }

      return redFqdn;
    });
  }

  // Add deleted fqdns and mark as state = removed
  if (oldList && newList) {
    if (redLines?.fqdns) {
      oldList.fqdns.forEach(oldFqdn => {
        // Fqdn is not found in the new set of fqdns add it to the front of the red-lines
        if (!_.some(redLines.fqdns, redFqdn => oldFqdn.fqdn === redFqdn.fqdn)) {
          const deletedFqdn = oldFqdn;

          deletedFqdn.type = 'old';
          deletedFqdn.removed = true;
          redLines.fqdns.unshift(deletedFqdn);
        }
      });
    }
  }

  return redLines;
};
