/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserScoped} from 'containers/User/UserState';
import listReducers from './List/SecureGatewayListState';
import instanceReducers from './Item/SecureGatewayItemState';

export default {
  securegateways: combineReducers({
    ...instanceReducers,
    ...listReducers,
  }),
};

export const isSecureGatewayEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
