/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {
  Component,
  Children,
  createRef,
  type MutableRefObject,
  type FocusEvent,
  type ReactElement,
  type ReactNode,
  type MouseEvent as MouseEventReact,
} from 'react';
import {domUtils, reactUtils, tidUtils} from '@illumio-shared/utils';
import MenuItemsContainer, {type MenuItemsContainerProps} from './MenuItemsContainer';
import {dropdownHorizontal, dropdownVertical, type MotionDirection, type TransitionStyle} from './motions';
import styles from './Menu.css';
import MenuItems from './MenuItems';
import {MenuItem} from '..';
import type {Selection} from 'd3-selection';
import intl from '@illumio-shared/utils/intl';
import type {MenuItemProps} from './MenuItem';
import Tooltip from 'components/Tooltip/Tooltip';
import {AnimatePresence, motion, LayoutGroup} from 'framer-motion';

const slope = (ax: number, ay: number, bx: number, by: number) => (by - ay) / (bx - ax);
const slopeToleranceTop = 10;
const slopeToleranceBottom = 50;

type FocusItem = 'first' | 'last' | false;

export type DropdownTransitionStyle = {
  opacity: number;
  x: number;
  y: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

interface MenuDropdownProps {
  children?: MenuItemsContainerProps['children'];
  keepParentOpen?: boolean;
  preventParentAnimate?: boolean;
  dir?: MotionDirection;
  style?: DropdownTransitionStyle;
  subDropdown?: boolean;
  active?: boolean;
  parentFocusedItem?: MenuItemsContainerProps['parentFocusedItem'];
  arrow?: boolean;
  insensitive?: boolean;
  anchorRef?: MutableRefObject<HTMLElement | null>;
  arrowRef?: MutableRefObject<HTMLElement | null>;
  alignRelativeTo?: MutableRefObject<HTMLElement | null>;
  align?: string;
  reference?: MutableRefObject<HTMLElement | null>;
  triggerOnHover?: boolean;
  parentScrollContainer?: HTMLElement | null;

  onClose(a?: boolean): void;
  onScope?(): void;
  saveSubDropdownItemsRef?(items: MenuItems): void;
  focusItemOnOpen?: FocusItem;

  onMouseEnter?: (evt: MouseEvent | React.MouseEvent) => void;
  onMouseLeave?: (evt: domUtils.MouseEventLike) => void;

  theme: Record<string, string>;

  tid?: string;
}

type MenuDropdownState = Readonly<{
  showSubDropdown: boolean;
  // Offset relative to the target/reference, to prevent viewport overflow
  offsetTop: number;
  offsetLeft: number;
  // Offset of the arrow, the middle of which should point to the middle of arrowRef
  arrowOffsetLeft: number;
  // Whether the vertical direction of the dropdown is upwards
  up: boolean;
}>;

export default class MenuDropdown extends Component<MenuDropdownProps, MenuDropdownState> {
  subDropdownConfig: TransitionStyle[];
  items: MenuItem[];
  itemList: MenuItems | null;
  subDropdownItemsList: MenuItems | null;

  focusedItem?: MenuItem | null;
  mouseLastX = 0;
  mouseLastY = 0;
  mouseLastItem?: MenuItem | null;
  focuseScoped?: boolean;

  dropdown?: HTMLElement | null;
  subDropdown?: MenuDropdown | null;
  focusedElementRef: MutableRefObject<HTMLElement | null>;

  mouseHoverSlopeTimeout?: number;

  savedFocusedElement?: HTMLElement;
  focusChildrenChangeDirection?: -1 | 0 | 1;
  // initialize with NaN so that comparison operation with it is always false
  focusChildrenIndex = NaN;

  stateIntentionTimeout?: number;

  // these are for debug purpose, see the last section of this file
  lineUp?: Selection<SVGLineElement, unknown, HTMLElement, unknown> | null;
  lineDown?: Selection<SVGLineElement, unknown, HTMLElement, unknown> | null;

  constructor(props: MenuDropdownProps) {
    super(props);

    this.state = {showSubDropdown: false, offsetTop: 0, offsetLeft: 0, arrowOffsetLeft: 0, up: false};
    this.subDropdownConfig = [];
    this.items = [];
    this.itemList = null;
    this.subDropdownItemsList = null;

    this.focusedElementRef = createRef();

    this.renderSubDropdown = this.renderSubDropdown.bind(this);
    this.updatePosition = this.updatePosition.bind(this);

    this.saveDropdownDivRef = this.saveDropdownDivRef.bind(this);
    this.saveItemsRef = this.saveItemsRef.bind(this);
    this.saveSubDropdownRef = this.saveSubDropdownRef.bind(this);
    this.saveSubDropdownItemsRef = this.saveSubDropdownItemsRef.bind(this);

    this.focusItem = this.focusItem.bind(this);
    this.applyStateIntention = this.applyStateIntention.bind(this);

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleItemFocus = this.handleItemFocus.bind(this);
    this.handleItemMouse = this.handleItemMouse.bind(this);
    this.handleDropdownClick = this.handleDropdownClick.bind(this);
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);

    this.handleSubDropdownClose = this.handleSubDropdownClose.bind(this);
    this.handleSubDropdownScope = this.handleSubDropdownScope.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<MenuDropdownProps>) {
    if (!prevProps.active && this.props.active) {
      if (!this.props.subDropdown) {
        if (this.props.focusItemOnOpen) {
          // Primary dropdown should set focus on itself automatically
          this.scopeFocus(this.props.focusItemOnOpen);
        }

        // Only primary dropdown should listen to mouse click outside of dropdown to close itself and subdropdowns
        this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this);
        // Synchronously registered event handlers are called at initialization in react 18
        // https://github.com/facebook/react/issues/24657#issuecomment-1149582288
        setTimeout(() => {
          document.addEventListener('mousedown', this.handleDocumentMouseDown);
        });
      }

      const focusedChild = this.items.find(
        item =>
          item.props.initiallyFocused ||
          traverseMenuItems(item.props.children, child => Boolean(child.props.initiallyFocused)),
      );

      if (focusedChild) {
        this.focusItem(focusedChild);
      }

      this.updatePosition();
    } else if (!this.props.active && prevProps.active) {
      this.setState({showSubDropdown: false, offsetTop: 0, offsetLeft: 0, arrowOffsetLeft: 0, up: false});
      this.subDropdownConfig = [];
      this.items = [];
      this.itemList = null;
      this.subDropdownItemsList = null;
      this.focusedElementRef = createRef();
      this.resetScopeFocus(false);

      if (!this.props.subDropdown) {
        this.restoreFocusOnSavedElement();
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
      }
    }

    if (prevProps.insensitive && !this.props.insensitive) {
      this.detachEventsFromDropdown();
    }
  }

  componentWillUnmount() {
    this.items = [];
    this.resetScopeFocus(false);

    if (!this.props.subDropdown) {
      this.restoreFocusOnSavedElement();
      document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }
  }

  private saveDropdownDivRef(dropdown: HTMLElement | null) {
    this.dropdown = dropdown;

    if (!dropdown) {
      this.detachEventsFromDropdown();
    } else if (this.props.reference && this.props.triggerOnHover) {
      this.attachEventsToDropdown();
    }
  }

  private saveSubDropdownRef(dropdown: MenuDropdown | null) {
    this.subDropdown = dropdown;

    if (!dropdown) {
      this.subDropdownItemsList = null;
    }
  }

  private saveItemsRef(itemList: MenuItems) {
    this.itemList = itemList;
    this.items = itemList.items;

    if (this.props.subDropdown) {
      this.props.saveSubDropdownItemsRef?.(itemList);
    }
  }

  private saveSubDropdownItemsRef(itemList: MenuItems) {
    this.subDropdownItemsList = itemList;
  }

  private handleDropdownClick(evt: MouseEventReact) {
    // Stop propagating the click further up, for example, to the grid row
    evt.stopPropagation();
  }

  private handleDocumentMouseDown(evt: Event) {
    if ((evt.target as HTMLElement).closest(`[${Tooltip.InteractiveDataAttribute}='true']`)) {
      return;
    }

    // If clicking outside of dropdown
    if (evt.target !== this.dropdown && !this.dropdown?.contains(evt.target as Node)) {
      // Reset focus scoping right away to allow setting focus into other elements (input, etc) by this click
      this.resetScopeFocus(true);

      // Close menu
      if (this.props.keepParentOpen) {
        (this.subDropdownConfig[0]?.data as MenuItem)?.setParentFocus(false);
        this.subDropdownConfig = [];
        this.setState({showSubDropdown: false, offsetTop: 0, offsetLeft: 0, arrowOffsetLeft: 0, up: false});
      } else {
        this.props.onClose();
      }
    }
  }

  private handleDocumentKeyDown(evt: KeyboardEvent) {
    let {key} = evt;

    if (evt.key === 'Tab') {
      key = evt.shiftKey ? 'ArrowUp' : 'ArrowDown';
    }

    switch (key) {
      case 'Escape':
        evt.stopPropagation();
        // Closing by pressing Esc should focus trigger button if no elements had been focused before opening menu
        this.subDropdownConfig = [];
        this.setState({showSubDropdown: false, offsetTop: 0, offsetLeft: 0, arrowOffsetLeft: 0, up: false});
        this.props.onClose(true);
        break;
      case 'ArrowUp':
        evt.preventDefault();
        this.focusPreviousItem();
        break;
      case 'ArrowRight':
        evt.preventDefault();

        if (this.subDropdown && this.subDropdownConfig.length) {
          this.resetScopeFocus(false);
          this.subDropdown.scopeFocus('first');
        } else {
          this.focusNextItem();
        }

        break;
      case 'ArrowLeft':
        evt.preventDefault();

        if (this.props.subDropdown) {
          this.resetScopeFocus(true, true);
        } else {
          this.focusPreviousItem();
        }

        break;
      case 'ArrowDown':
        evt.preventDefault();
        this.focusNextItem();
        break;
      // no default
    }
  }

  private handleItemFocus(evt: FocusEvent, item: MenuItem) {
    evt.stopPropagation();
    this.focusItem(item);
  }

  private handleItemClick(evt: domUtils.MouseEventLike, item: MenuItem) {
    // Close menu after click. If it is a link, close only if user wants to open it in the same browser context
    // Don't close if user pressed Ctrl, Cmd, etc.
    if (
      !item.props.noCloseOnClick &&
      !item.props.notSelectable &&
      (!item.props.link ||
        domUtils.isClickInBrowsingContext(
          evt,
          typeof item.props.link === 'string' ? undefined : item.props.link.target,
        ))
    ) {
      (this.subDropdownConfig[0]?.data as MenuItem)?.setParentFocus(false);

      this.subDropdownConfig = [];
      this.setState({showSubDropdown: false, offsetTop: 0, offsetLeft: 0, arrowOffsetLeft: 0, up: false});
      this.props.onClose();
    }
  }

  private handleItemMouse(evt: MouseEventReact, item: MenuItem) {
    evt.stopPropagation();
    this.clearMouseSlopeTimeout();

    if (!this.subDropdownItemsList || !this.focusedItem || !this.focusedItem.props.children) {
      this.focusItem(item);
    } else if (item !== this.focusedItem || item !== this.mouseLastItem) {
      const rect = this.subDropdownItemsList.calcRect();

      if (rect !== null) {
        const decreasingSlope = slope(evt.pageX, evt.pageY, rect.left, rect.top - slopeToleranceTop);
        const increasingSlope = slope(evt.pageX, evt.pageY, rect.left, rect.bottom + slopeToleranceBottom);
        const prevDecreasingSlope = slope(this.mouseLastX, this.mouseLastY, rect.left, rect.top - slopeToleranceTop);
        const prevIncreasingSlope = slope(
          this.mouseLastX,
          this.mouseLastY,
          rect.left,
          rect.bottom + slopeToleranceBottom,
        );

        if (decreasingSlope > prevDecreasingSlope || increasingSlope < prevIncreasingSlope) {
          // If slope is decreasing, focus new item
          this.focusItem(item);
        } else {
          // Do nothing If slope is increasing,
          // but set timeout to focus on new item if user stops moving mouse suddenly, before reaching subdropdown
          this.mouseHoverSlopeTimeout = window.setTimeout(this.focusItem, 100, item);
        }
      }
    }

    this.mouseLastX = evt.pageX;
    this.mouseLastY = evt.pageY;
    this.mouseLastItem = item;
  }

  private handleSubDropdownClose(val?: boolean) {
    this.subDropdownConfig = [];
    this.setState({showSubDropdown: false, offsetTop: 0, offsetLeft: 0, arrowOffsetLeft: 0, up: false});
    this.props.onClose(val);
  }

  private handleSubDropdownScope() {
    this.resetScopeFocus(false);
  }

  scopeFocus(focusOnItem: FocusItem): void {
    if (this.focuseScoped) {
      return;
    }

    this.focuseScoped = true;

    // Handle keys events
    document.addEventListener('keydown', this.handleDocumentKeyDown);

    // Save focus on parent dropdown's item if it is currently focused, to restore focus on it by pressing left arrow
    // If none is focused (that's mean <body> is activeElement),
    // then pressing Esc will focus trigger button by default and click on outside of dropdown will focus something else
    if (
      this.props.subDropdown &&
      document.activeElement &&
      document.activeElement.classList.contains(styles.item) &&
      this.dropdown &&
      !this.dropdown.contains(document.activeElement)
    ) {
      this.savedFocusedElement = document.activeElement as HTMLElement;
    }

    if (focusOnItem) {
      this.focusItem(this.items[focusOnItem === 'first' ? 0 : this.items.length - 1]);
    } else if (focusOnItem !== false) {
      // If focusOnItem has not been set, we need to focus on dropdown itself
      // But if dropdown is taller than screen, browser's top will jump to dropdown's top,
      // to avoid this we creaded empty element 'focuser' in the very beginning of dropdown
      this.dropdown!.querySelector<HTMLElement>(`.${styles.focuser}`)!.focus();
      this.focusedItem = null;
      this.focusedElementRef.current = null;
    }

    this.props.onScope?.();
  }

  private resetScopeFocus(restoreFocus: boolean, cascadeDown?: boolean) {
    if (this.focuseScoped) {
      this.focusedItem = null;
      this.focusedElementRef.current = null;
      this.mouseLastX = 0;
      this.mouseLastY = 0;
      this.mouseLastItem = null;
      this.focuseScoped = false;

      //console.log('RESET SCOPE', this.dropdown, restoreFocus);
      // Remove listeners
      document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }

    if (cascadeDown) {
      this.clearStateIntention();
      this.subDropdownConfig = [];

      if (this.subDropdown) {
        this.subDropdown.resetScopeFocus(false, cascadeDown);
      }

      if (this.state.showSubDropdown) {
        this.setState({showSubDropdown: false});
      }
    }

    this.clearMouseSlopeTimeout();

    if (restoreFocus) {
      this.restoreFocusOnSavedElement();
    }
  }

  private restoreFocusOnSavedElement() {
    // Reset focus on element that was focused before current dropdown
    if (this.savedFocusedElement && typeof this.savedFocusedElement.focus === 'function') {
      this.savedFocusedElement.focus();
    }
  }

  private clearMouseSlopeTimeout() {
    if (this.mouseHoverSlopeTimeout) {
      window.clearTimeout(this.mouseHoverSlopeTimeout);
      this.mouseHoverSlopeTimeout = undefined;
    }
  }

  private attachEventsToDropdown() {
    if (this.props.onMouseEnter) {
      this.dropdown?.addEventListener('mouseenter', this.props.onMouseEnter);
    }

    if (this.props.onMouseLeave) {
      this.dropdown?.addEventListener('mouseleave', this.props.onMouseLeave);
    }
  }

  private detachEventsFromDropdown() {
    if (this.props.onMouseEnter) {
      this.dropdown?.removeEventListener('mouseenter', this.props.onMouseEnter);
    }

    if (this.props.onMouseLeave) {
      this.dropdown?.removeEventListener('mouseleave', this.props.onMouseLeave);
    }
  }

  // Check if the items list overflows the viewport,
  // compute offset to prevent it and rerender with margins
  private updatePosition() {
    const {
      state,
      itemList,
      props: {parentScrollContainer, anchorRef, arrowRef, arrow, subDropdown},
    } = this;

    if (subDropdown) {
      return;
    }

    if (!itemList?.rect || !anchorRef?.current) {
      console.error(`Can't update the menu position, because itemList or anchorRef is empty`);

      return;
    }

    const {rect} = itemList;
    const anchorRect = anchorRef.current.getBoundingClientRect();
    const viewportWidth = domUtils.getViewportWidth();
    const viewportHeight = domUtils.getViewportHeight();
    let parentTop = 0;
    let parentBottom = 0;

    if (parentScrollContainer) {
      const parentRect = parentScrollContainer.getBoundingClientRect();

      parentTop = parentRect.top;
      parentBottom = viewportHeight - parentRect.bottom;
    }

    // By how many pixels it should indent from the viewport borders, to fit its shadows and look pretty
    const clearance = viewportWidth >= 960 ? 6 : 3;
    const offsetClearance = 50;
    // A gap between the anchor and the dropdown, upward and downward respectively
    const gapUp = 5;
    const gapDown = 3;
    // Width of the pseudo arrow
    const arrowWidth = 14;

    let offsetTop = 0;
    let offsetLeft = 0;
    let arrowOffsetLeft = 0;
    let up = false;

    if (rect.left < clearance) {
      // If, for some reason, the menu overflows the left viewport boundary,
      // move it to make fully visible
      offsetLeft = Math.ceil(clearance - rect.left);
    } else if (rect.right + clearance > viewportWidth) {
      // But if the menu overflows the viewport on the right,
      // then move the menu to the left to fit its content, but limit by the viewport left boundary
      offsetLeft = Math.max(Math.ceil(clearance - rect.left), Math.floor(viewportWidth - rect.right - clearance));
    }

    const availableHeightUp = anchorRect.top - gapUp - clearance - parentTop;
    const availableHeightDown = viewportHeight - parentBottom - anchorRect.bottom - gapDown - clearance;

    // If the menu overflows the viewport vertically,
    // and there is more space above the anchor than below it,
    // then make the menu appear above the anchor
    if (rect.bottom > viewportHeight && availableHeightUp > availableHeightDown) {
      // If the available height on top is less than the size of the rect move the menu down on top of the anchor element
      // by the amount it goes over plus a clearance to prevent the scrollable parent from scrolling
      const heightOffset =
        parentScrollContainer && availableHeightUp < rect.height
          ? rect.height - availableHeightUp + offsetClearance
          : rect.height;

      offsetTop = -anchorRect.height - heightOffset - gapUp;
      up = true;
    } else if (parentScrollContainer && availableHeightDown < rect.height + offsetClearance) {
      // If the available height below is less than the size of the rect move the menu up on top of the anchor element
      // by the amount it goes over plus a clearance to prevent the scrollable parent from scrolling
      offsetTop = availableHeightDown - rect.height - offsetClearance;
    }

    // If we want to show the arrow, we need to point its center to the middle of the arrowRef,
    // but keep it within the dropdown dimensions
    if (arrow && arrowRef?.current) {
      const referenceRect = arrowRef.current.getBoundingClientRect();

      arrowOffsetLeft = Math.max(
        0,
        Math.min(
          rect.width - arrowWidth,
          referenceRect.left + referenceRect.width / 2 - arrowWidth / 2 - rect.left - offsetLeft,
        ),
      );
    }

    if (
      up !== state.up ||
      offsetTop !== state.offsetTop ||
      offsetLeft !== state.offsetLeft ||
      arrowOffsetLeft !== state.arrowOffsetLeft
    ) {
      this.setState({offsetTop, offsetLeft, arrowOffsetLeft, up});
    }
  }

  focusItem(item: MenuItem, fromKeyPress?: boolean): void {
    if (!this.focuseScoped) {
      if (this.subDropdown) {
        this.subDropdown.resetScopeFocus(false, true);
      }

      if (this.props.subDropdown) {
        this.props.onScope?.();
      }

      this.scopeFocus(false);
    }

    if (!item) {
      return;
    }

    if (item !== this.focusedItem) {
      this.focusedItem = item;
      this.focusedElementRef.current = item.itemElement;

      if (this.subDropdownConfig.length) {
        (this.subDropdownConfig[0].data as MenuItem).setParentFocus(false);
      }

      if (item.props.children) {
        const focusChildrenIndex = this.items.indexOf(this.focusedItem);

        if (focusChildrenIndex > this.focusChildrenIndex) {
          this.focusChildrenChangeDirection = 1;
        } else if (focusChildrenIndex < this.focusChildrenIndex) {
          this.focusChildrenChangeDirection = -1;
        } else {
          this.focusChildrenChangeDirection = 0;
        }

        this.focusChildrenIndex = focusChildrenIndex;

        this.subDropdownConfig = [
          {
            key: 'dropdown',
            data: this.focusedItem,
            style: dropdownHorizontal.open(item.offsetTop),
          },
        ];

        this.focusedItem.setParentFocus(true);

        this.declareStateIntention({showSubDropdown: true}, fromKeyPress ? 0 : undefined);
      } else if (this.subDropdownConfig.length) {
        this.subDropdownConfig = [];
        this.declareStateIntention({showSubDropdown: false}, fromKeyPress ? 0 : undefined);
      }

      if (item.props.onOriginFocus) {
        item.props.onOriginFocus();
      }
    }

    item.focus();
  }

  focusNextItem(): void {
    const {items, focusedItem} = this;
    let index = focusedItem ? items.indexOf(focusedItem) : -1;
    let nextItem;

    if (index === -1) {
      // index: -1 : no item is focused when all items are disabled
      return;
    }

    do {
      index = index < items.length - 1 ? index + 1 : 0;
      nextItem = items[index];
    } while ((nextItem.props.disabled || nextItem.props.notSelectable) && nextItem !== focusedItem);

    if (nextItem !== focusedItem) {
      this.focusItem(nextItem, true);
    }
  }

  focusPreviousItem(): void {
    const {items, focusedItem} = this;
    let index = focusedItem ? items.indexOf(focusedItem) : -1;
    let previousItem;

    if (index === -1) {
      // index: -1 : no item is focused when all items are disabled
      return;
    }

    do {
      index = index > 0 ? index - 1 : items.length - 1;

      previousItem = items[index];
    } while ((previousItem.props.disabled || previousItem.props.notSelectable) && previousItem !== focusedItem);

    if (previousItem !== focusedItem) {
      this.focusItem(previousItem, true);
    }
  }

  private declareStateIntention(intentionalState: Partial<MenuDropdownState>, delay = 100) {
    this.clearStateIntention();

    if (delay) {
      this.stateIntentionTimeout = window.setTimeout(this.applyStateIntention, delay, intentionalState);
    } else {
      this.applyStateIntention(intentionalState);
    }
  }

  private clearStateIntention() {
    if (this.stateIntentionTimeout) {
      window.clearTimeout(this.stateIntentionTimeout);
      this.stateIntentionTimeout = undefined;
    }
  }

  private applyStateIntention(intentionalState: Partial<MenuDropdownState>) {
    this.setState(state => ({...state, ...intentionalState}));
  }

  private addPositioning(config: DropdownTransitionStyle): DropdownTransitionStyle {
    const {align, alignRelativeTo, reference} = this.props;

    const alignTo = alignRelativeTo ?? reference;
    const result = {...config};

    if (alignTo?.current) {
      result.top = alignTo.current.offsetTop + alignTo.current.offsetHeight;

      if (align === 'start') {
        result.left = alignTo.current.offsetLeft;
      } else if (align === 'end') {
        result.right = alignTo.current.offsetLeft + alignTo.current.offsetWidth;
      }
    } else if (align === 'start') {
      result.left = 0;
    } else if (align === 'end') {
      result.right = 0;
    }

    return result;
  }

  private renderSubDropdown() {
    const config = this.subDropdownConfig[0];

    if (!config) {
      return;
    }

    const {style, data: focusedItem} = config;
    const children = focusedItem === null ? undefined : (focusedItem as MenuItem).props.children;

    return (
      <MenuDropdown
        key="sub"
        subDropdown
        active={this.state.showSubDropdown}
        anchorRef={this.focusedElementRef}
        parentFocusedItem={focusedItem as MenuItem}
        style={style as DropdownTransitionStyle}
        theme={this.props.theme}
        dir={this.focusChildrenChangeDirection}
        ref={this.saveSubDropdownRef}
        saveSubDropdownItemsRef={this.saveSubDropdownItemsRef}
        onScope={this.handleSubDropdownScope}
        onClose={this.handleSubDropdownClose}
        keepParentOpen={this.props.keepParentOpen}
      >
        {children}
      </MenuDropdown>
    );
  }

  render() {
    const {
      props,
      props: {theme, subDropdown, style: subDropdownStyles, active, parentFocusedItem, tid, dir = 0, arrow},
      state: {offsetTop, offsetLeft, arrowOffsetLeft, up},
    } = this;

    const style = (
      subDropdown ? subDropdownStyles : this.addPositioning(dropdownVertical.open as unknown as DropdownTransitionStyle)
    ) as DropdownTransitionStyle;

    const dropdownProps = {
      'ref': this.saveDropdownDivRef,
      'className': cx(theme.dropdown, {
        [theme.dropdownActive]: active,
        [theme.subDropdown]: subDropdown,
        [theme.dropdownWithArrow]: arrow,
        [theme.dropdownUp]: up,
      }),
      'style': {
        opacity: style.opacity,
        transform: `translate3d(${style.x ?? 0}px, ${(up ? -1 : 1) * (style.y ?? 0)}px, 0)`,
        ...(typeof style.top === 'number' && {top: `${style.top}px`}),
        ...(typeof style.left === 'number' && {left: `${style.left}px`}),
        ...(typeof style.right === 'number' && {right: `${style.right}px`}),
        ...(typeof style.bottom === 'number' && {bottom: `${style.bottom}px`}),
        ...(offsetTop !== 0 && {marginTop: `${offsetTop}px`}),
        ...(offsetLeft !== 0 && {marginLeft: `${offsetLeft}px`}),
        ...(arrowOffsetLeft !== 0 && {'--menu-dropdown-arrow-left': `${arrowOffsetLeft}px`}),
      },
      'data-tid': tidUtils.getTid('comp-menu-dropdown', tid),
      'onClick': this.handleDropdownClick,
    };

    const animation = subDropdown
      ? {
          transition: {duration: 0.1},
          initial: dropdownHorizontal.enter(style.y),
          animate: style,
          exit: dropdownHorizontal.leave(style.y),
        }
      : !this.props.preventParentAnimate && {
          initial: this.addPositioning(dropdownVertical.enter(up ? -1 : 1) as unknown as DropdownTransitionStyle),
          animate: this.addPositioning(dropdownVertical.open as unknown as DropdownTransitionStyle),
          exit: this.addPositioning(dropdownVertical.leave(up ? -1 : 1) as unknown as DropdownTransitionStyle),
          transition: {duration: 0.1},
        };

    return (
      <AnimatePresence>
        {active && (
          <LayoutGroup id={dropdownProps['data-tid']}>
            {/*force rerender when value of up changes to use the latest animation data*/}
            <motion.div key={`${dropdownProps['data-tid']}}-${up}`} {...dropdownProps} {...animation}>
              {/*tabIndex='-1' removed due to chrome bug when inside sticky header*/}
              {!props.focusItemOnOpen && <span className={styles.focuser} />}

              <MenuItemsContainer
                key={dropdownProps['data-tid']}
                saveItemsRef={this.saveItemsRef}
                dir={dir}
                theme={theme}
                parentFocusedItem={parentFocusedItem}
                onItemClick={this.handleItemClick}
                onItemFocus={this.handleItemFocus}
                onItemMouse={this.handleItemMouse}
                aria-label={subDropdown ? intl('Common.SubDropdownMenu') : intl('Common.MainDropdownMenu')}
                updateDropdownPosition={this.updatePosition}
              >
                {this.props.children}
              </MenuItemsContainer>
              {this.renderSubDropdown()}
            </motion.div>
          </LayoutGroup>
        )}
      </AnimatePresence>
    );
  }
}

function traverseMenuItems(
  children: ReactNode | undefined,
  predicate: (item: ReactElement<MenuItemProps>) => boolean,
): boolean {
  if (!children) {
    return false;
  }

  return Children.toArray(children).some(
    child =>
      reactUtils.isReactElementOf(child, MenuItem) &&
      (predicate(child) || traverseMenuItems(child.props.children, predicate)),
  );
}

// Set to true if you want to see forgiving mouse movement paths
// eslint-disable-next-line no-constant-condition, no-constant-binary-expression -- Set to true if you want to see forgiving mouse movement paths
if (false && __DEV__ && typeof window === 'object') {
  import('d3-selection').then(({select}) => {
    const svg = select('body')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('style', 'position:absolute;top:0;left:0;pointer-events:none;z-index:99999');

    // @ts-ignore
    const handleItemMouseOrigin = MenuDropdown.prototype.handleItemMouse;
    // @ts-ignore
    const saveSubDropdownRefOrigin = MenuDropdown.prototype.saveSubDropdownRef;
    const componentWillUnmountOrigin = MenuDropdown.prototype.componentWillUnmount;

    // @ts-ignore
    MenuDropdown.prototype.handleItemMouse = function (...args) {
      if (this.subDropdownItemsList && this.mouseLastX) {
        const rect = this.subDropdownItemsList.calcRect();

        this.lineUp ||= svg.append('line').style('stroke', 'green');
        this.lineDown ||= svg.append('line').style('stroke', 'green');

        this.lineUp
          .attr('x1', this.mouseLastX)
          .attr('y1', this.mouseLastY)
          .attr('x2', rect?.left ?? 0)
          .attr('y2', (rect?.top ?? 0) - slopeToleranceTop);
        this.lineDown
          .attr('x1', this.mouseLastX)
          .attr('y1', this.mouseLastY)
          .attr('x2', rect?.left ?? 0)
          .attr('y2', (rect?.bottom ?? 0) + slopeToleranceBottom);
      }

      handleItemMouseOrigin.apply(this, args);
    };

    MenuDropdown.prototype.componentWillUnmount = function (...args) {
      if (this.lineUp) {
        this.lineUp.remove();
      }

      if (this.lineDown) {
        this.lineDown.remove();
      }

      componentWillUnmountOrigin.apply(this, args);
    };

    // @ts-ignore
    MenuDropdown.prototype.saveSubDropdownRef = function (dropdown) {
      if (!dropdown) {
        if (this.lineUp) {
          this.lineUp.remove();
          this.lineUp = null;
        }

        if (this.lineDown) {
          this.lineDown.remove();
          this.lineDown = null;
        }
      }

      saveSubDropdownRefOrigin.call(this, dropdown);
    };
  });
}
