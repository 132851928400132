/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {all, call, put, select} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {fetchSelectiveUsers} from 'containers/User/UserSagas';
import {getServiceDetail, getServiceVersions} from './ServiceItemState';
import {errorUtils} from '@illumio-shared/utils';

export function* fetchService({id, pversion = 'draft', force = false}) {
  const {data: service} = yield call(apiSaga, 'services.get_instance', {
    cache: !force,
    params: {service_id: id, pversion},
  });

  yield put({type: 'SERVICES_GET_INSTANCE', data: service});
}

export function* fetchServiceItem({name, params}) {
  const isEditPage = name.endsWith('.edit');
  const isDraftPage = params.pversion === 'draft';
  const pversion = Number(params.pversion);
  const isOldVersion = !_.isNaN(pversion) && pversion > 0;

  if (isEditPage && params.pversion !== 'draft') {
    throw new errorUtils.RedirectError({params: {id: params.id, pversion: 'draft'}, proceedFetching: true});
  }

  const [{data: active}, {data: draft}, oldPversionObj, oldPrevPversionObj] = yield all([
    call(apiSaga, 'services.get_instance', {
      ignoreCodes: [404],
      params: {service_id: params.id, pversion: 'active'},
    }),
    call(apiSaga, 'services.get_instance', {
      ignoreCodes: [404],
      params: {service_id: params.id, pversion: 'draft'},
    }),
    ...(isOldVersion
      ? [
          call(apiSaga, 'services.get_instance', {
            ignoreCodes: [404],
            params: {service_id: params.id, pversion},
          }),
        ]
      : []),
    ...(isOldVersion && pversion > 1
      ? [
          call(apiSaga, 'services.get_instance', {
            ignoreCodes: [404],
            params: {service_id: params.id, pversion: pversion - 1},
          }),
        ]
      : []),
  ]);

  // If both draft and active are not valid, redirect to the list page
  // draft and active is undefined for deleted policy object
  // Redirect to list page if pversion is invalid
  const validItem = oldPversionObj || oldPrevPversionObj || draft || active;
  const validPversion = isDraftPage || isOldVersion || ['draft', 'active'].includes(params.pversion);

  if (!isEditPage && (!validItem || !validPversion || (isOldVersion && !oldPversionObj))) {
    throw new errorUtils.RedirectError({to: 'services.list', proceedFetching: true, thisFetchIsDone: true});
  }

  const data = {
    detail: {
      active,
      draft,
      ...(oldPversionObj ? {oldPversionObj: oldPversionObj?.data} : {}),
      ...(oldPrevPversionObj ? {oldPrevPversionObj: oldPrevPversionObj?.data} : {}),
    },
  };

  const cached = yield select(getServiceDetail);

  if (
    !cached.detail ||
    active !== cached.detail.active ||
    draft !== cached.detail.draft ||
    oldPversionObj?.data !== cached.oldPversion ||
    oldPrevPversionObj?.data !== cached.oldPrevPversion
  ) {
    yield put({type: 'SERVICES_GET_DETAIL', data});
  }

  const {created_by: createdBy, updated_by: updatedBy} = (yield select(getServiceVersions)).versions?.pversionObj ?? {};

  yield call(fetchSelectiveUsers, [createdBy, updatedBy]);

  // If there is no real draft (no pending changes), redirect to active version
  if (isDraftPage && !isEditPage && !draft.update_type) {
    throw new errorUtils.RedirectError({
      params: {id: params.id, pversion: 'active'},
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  return {data};
}
