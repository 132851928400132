/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

// pattern that matches integers GTE 1
const POSITIVE_INTEGER_PATTERN = /\d+/;

/**
 * parses the input string and extracts a positive integer with length LTE maxLength;
 * it effectively extracts the positive integer portion of the string and ignores any
 * non-numeric characters;
 * - if the string contains a positive integer, it extracts and returns it; for example,
 *   if the user enters '3a' or '-3', the function will return '3';
 * - if the input string is longer than maxLength, the return value is truncated;
 *   for example, if the input is '456' and maxLength is 2, '45' will be returned;
 * - if the string does not contain a positive integer (e.g. 'abc'), an empty string
 *   is returned;
 * @param inputValue {string} - user's input string
 * @param maxLength {number} - max length of return value
 * @returns {string}
 */
export const parseInputValue = (inputValue, maxLength = 0) => {
  const [parsedValue] = (inputValue ?? '').match(POSITIVE_INTEGER_PATTERN) ?? [''];

  return parsedValue.slice(0, maxLength);
};
