/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {useSelector} from '@illumio-shared/utils/redux';
import {Fragment, useRef, useLayoutEffect, useEffect, useMemo, useState, useCallback} from 'react';
import {
  default as getContainersPropertiesMap,
  getContainerPropertiesByRouteMap,
} from '../../containers/ContainersProperties';
import {getRouteName} from 'containers/App/AppState';
import styles from './Breadcrumbs.css';
import {Icon, Link, Button, MenuItem} from 'components';
import type {IconName} from '../Icon/IconName';

type ItemModel = {
  viewName?: string;
  icon?: IconName;
  linkProps?: {to?: string};
  collapsed?: boolean;
};

const RESIZE_DELAY_MS = 50;
const BREADCRUMBS_WIDTH_BUFFER_PX = 100;
const ROUTE_MAP_EXCEPTIONS = [
  'app.dashboard',
  'app.health.list',
  'app.myprofile',
  'app.myroles.list',
  'app.apikeys.list',
  'app.provisioning',
  'app.appGroups.detail.illumination',
  'app.boundaries.item.illumination',
  'app.workloads.item.illumination',
];

export default function Breadcrumbs(): JSX.Element {
  const propertiesMap = useSelector(getContainersPropertiesMap);
  const propertiesByRouteMap = useSelector(getContainerPropertiesByRouteMap);
  const routeName = useSelector(getRouteName);

  const renderWrapperRef = useRef<HTMLDivElement>(null);
  const toggleTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const constructBreadcrumbsList = useCallback(
    (route: string): ItemModel[] => {
      const splitRoute: string[] = route?.split('.');

      const routeMaps = splitRoute.reduce(
        (acc, route) => (acc.length === 0 ? [route] : [...acc, `${acc.at(-1)}.${route}`]),
        [] as string[],
      );

      let isVirtualRouteLimitReached = false;

      const routeMapProperties = routeMaps.reverse().reduce((acc, route) => {
        let routeContainer = propertiesByRouteMap.get(route);

        if (!routeContainer) {
          return acc;
        }

        if (routeContainer.redirectTo) {
          routeContainer = propertiesByRouteMap.get(routeContainer.redirectTo);
        }

        let routeProperties = propertiesMap.get(routeContainer.group || routeContainer.container) as ItemModel;
        let subRouteContainer;

        // In some cases the route properties are located in the parent route container
        if (!routeProperties) {
          subRouteContainer = propertiesByRouteMap.get(
            routeContainer.parent?.redirectTo || routeContainer.parent?.name,
          );
          routeProperties = propertiesMap.get(subRouteContainer?.container) as ItemModel;

          if (!routeProperties) {
            subRouteContainer = propertiesByRouteMap.get(routeContainer.parent?.parent?.redirectTo);
            routeProperties = propertiesMap.get(subRouteContainer?.container) as ItemModel;
          }
        }

        // Do not add routes to the breadcrumbs that are in the exception array
        if (
          ROUTE_MAP_EXCEPTIONS.includes(routeContainer.name) ||
          ROUTE_MAP_EXCEPTIONS.includes(subRouteContainer?.name)
        ) {
          return acc;
        }

        // Do not add any item for which we could not retrieve the properties
        if (!routeProperties) {
          return acc;
        }

        // Only one virtual route is allowed per breadcrumbs
        if (!routeProperties?.linkProps) {
          if (isVirtualRouteLimitReached) {
            return acc;
          }

          isVirtualRouteLimitReached = true;
        }

        // Do not add duplicates
        if (acc.findIndex(item => item.viewName === routeProperties.viewName) >= 0) {
          return acc;
        }

        return [...acc, routeProperties];
      }, [] as ItemModel[]);

      return routeMapProperties;
    },
    [propertiesByRouteMap, propertiesMap],
  );

  const breadcrumbsItems = useMemo(
    () => constructBreadcrumbsList(routeName).reverse(),
    [constructBreadcrumbsList, routeName],
  );

  const [renderItems, setRenderItems] = useState<ItemModel[]>(breadcrumbsItems);
  const [isMeasurement, setIsMeasurement] = useState<boolean>(true);

  const calculateBreadcrumbsSize = useCallback(() => {
    if (!renderWrapperRef.current) {
      return;
    }

    const maxWidth = (renderWrapperRef.current?.getBoundingClientRect().width || 0) - BREADCRUMBS_WIDTH_BUFFER_PX;
    let currentWidth = 0;

    // Find the first item on which truncation should be applied
    const truncationIndex =
      [...renderWrapperRef.current.children].findIndex(element => {
        currentWidth += element.getBoundingClientRect().width;

        return element.classList.contains(styles.item) && currentWidth > maxWidth;
      }) / 2;

    // Construct a new list of breadcrumbs items with 'collapsed' property set to true for the truncated item
    const newItems =
      truncationIndex < 0
        ? breadcrumbsItems
        : breadcrumbsItems.reduce((acc, item, index): ItemModel[] => {
            if (index > truncationIndex) {
              return acc;
            }

            const newItem: ItemModel = {
              ...item,
              collapsed: index === truncationIndex,
            };

            return [...acc, newItem];
          }, [] as ItemModel[]);

    if (isMeasurement) {
      setRenderItems(newItems);
      setIsMeasurement(false);
    }
  }, [breadcrumbsItems, isMeasurement]);

  // Check if Breadcrumbs fits the page every time items to be rendered change
  useLayoutEffect(() => {
    calculateBreadcrumbsSize();
  }, [renderItems, calculateBreadcrumbsSize]);

  // Whenever desired list of Breadcrumbs items changes, render those items
  // This step will then trigger another calculation of Breadcrumbs size to see if it fits the page
  useLayoutEffect(() => {
    setRenderItems(breadcrumbsItems);
    setIsMeasurement(true);
  }, [breadcrumbsItems]);

  useEffect(() => {
    calculateBreadcrumbsSize();

    const resizeBreadcrumbs = () => {
      clearTimeout(toggleTimeoutRef.current);
      toggleTimeoutRef.current = setTimeout(() => {
        setRenderItems(breadcrumbsItems);
        setIsMeasurement(true);
      }, RESIZE_DELAY_MS);
    };

    window.addEventListener('resize', resizeBreadcrumbs);

    return () => window.removeEventListener('resize', resizeBreadcrumbs);
  }, [calculateBreadcrumbsSize, breadcrumbsItems]);

  const renderDropdownMenu = useCallback(
    () =>
      breadcrumbsItems
        .filter(({linkProps}, index) => index >= renderItems.length - 1 && linkProps)
        .map(({viewName, linkProps, icon}) => <MenuItem text={viewName} link={linkProps?.to} icon={icon} />),
    [breadcrumbsItems, renderItems],
  );

  const renderBreadcrumbsItem = useCallback(
    ({icon, viewName, linkProps, collapsed}: ItemModel) => {
      const tid = `comp-breadcrumbs-item-${viewName}`;

      if (collapsed) {
        return (
          <Button.Menu
            noFill
            icon={icon}
            color="standard"
            theme={styles}
            themePrefix="breadcrumbCollapsed-"
            text="..."
            tid={tid}
            menuNoDropdownIcon
            menuProps={{triggerOnHover: true, align: 'start'}}
            menu={renderDropdownMenu()}
          />
        );
      }

      const itemContent = (
        <div className={styles.itemContent} data-tid={tid}>
          {icon && <Icon position="before" name={icon} />}
          <span className={styles.name}>{viewName || 'Unknown'}</span>
        </div>
      );

      return linkProps ? <Link to={linkProps?.to}>{itemContent}</Link> : itemContent;
    },
    [renderDropdownMenu],
  );

  return (
    <div ref={renderWrapperRef} className={styles.wrapper} data-tid="comp-breadcrumbs">
      {renderItems.map((item, index, array) => (
        <Fragment key={index}>
          <div className={styles.item}>{renderBreadcrumbsItem(item)}</div>
          {index < array.length - 1 && <Icon name="right" theme={styles} themePrefix="separator-" />}
        </Fragment>
      ))}
    </div>
  );
}
