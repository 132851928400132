/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {createContext, useRef, useEffect} from 'react';
import {Formik, Form as FormikForm, type FormikConfig, type FormikProps} from 'formik';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import FormCheckbox from './Checkbox/FormCheckbox';
import FormCheckboxGroup from './Checkbox/FormCheckboxGroup';
import FormInput from './Input/FormInput';
import FormColorPicker from './ColorPicker/FormColorPicker';
import FormTextarea from './Textarea/FormTextarea';
import FormRadioGroup from './Radio/FormRadioGroup';
import Radio from './Radio/Radio';
import FormLabel from './Label/FormLabel';
import FormLabelSelector from './FormLabelSelector';
import FormFileUpload from './FileUpload/FormFileUpload';
import FormOptionSelector from './OptionSelector/FormOptionSelector';
import FormSelector from './FormSelector';
import FormGrid from './Grid/FormGrid';
import FormDurationPicker from './FormDurationPicker/FormDurationPicker';
import FormDateTimeRangePicker from './FormDateTimeRangePicker/FormDateTimeRangePicker';
import {reactUtils, tidUtils, typesUtils} from '@illumio-shared/utils';
import {generalUtils} from '@illumio-shared/utils/shared';
import stylesUtils from 'utils.css';
import * as FormUtils from './FormUtils';
import {DraftJSPlugins, DraftJSUtils, FormIPListEditor} from './DraftJS';
import styles from './Form.css';
import OptionSelectorStyles from './OptionSelector/OptionSelector.css';
import type {ObjectSchema} from 'yup';

export interface FormProps<Values extends Record<never, never>> extends FormikConfig<Values>, ThemeProps {
  'children': typesUtils.ReactStrictNode | ((formik: FormikProps<Values>) => typesUtils.ReactStrictNode);
  // Yup validation schema
  'schemas': ObjectSchema<Partial<Values> | undefined>;
  // Gap class to wrap children, by default is just `.gap` to inherit any other parent gap size
  'gap'?: string;
  // do not show cancel confirmation on unsaved Pending changes
  'allowLeaveOnDirty'?: boolean;
  'autoFocus'?: boolean;
  // will refresh the form when new initialValues are provided
  'enableReinitialize'?: boolean;
  'tid'?: string;
  'data-tid'?: string;
  'className'?: string;
  'id'?: string;
  'isDirtyCallback'?: (dirty: boolean) => void;
}

export default function Form<Values extends Record<never, never>>(props: FormProps<Values>): JSX.Element {
  let {
    'schemas': validationSchema,
    className,
    gap = 'gap',
    theme,
    children,
    tid,
    'data-tid': dataTid,
    allowLeaveOnDirty = false,
    autoFocus = true,
    isDirtyCallback,
    ...formikProps
  } = mixThemeWithProps(styles, props);
  const isDirtyRef = useRef(false);
  const isSubmittingRef = useRef(false);
  const id = useRef<string | undefined>();

  // Assign form a random id until we move to formik 2 and it can accept a ref
  id.current ||= `form_${generalUtils.randomString(6, true)}`;

  // Check if focus is already inside the form and set focus on the first element if not
  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const timeout = setTimeout(() => {
      const form = document.querySelector(`#${id.current}`);

      if (form && !form.contains(document.activeElement)) {
        form.querySelector<HTMLInputElement>(`input, textarea, .${OptionSelectorStyles.selectorMain}`)?.focus();
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [autoFocus]); // To run it only on mount

  dataTid ||= tidUtils.getTid('comp-form', tid);

  className = cx(theme.form, reactUtils.classSplitter(stylesUtils, gap), className);
  formikProps.validationSchema = validationSchema;
  formikProps.onSubmit ??= _.noop;
  formikProps.enableReinitialize ??= false;

  return (
    <Form.SchemaContext.Provider value={validationSchema}>
      <Formik {...formikProps}>
        {form => {
          if (!allowLeaveOnDirty) {
            if (isDirtyRef.current !== form.dirty) {
              isDirtyRef.current = form.dirty;

              PubSub.publish(
                'FORM.DIRTY',
                {id: props.id ?? id.current, dirty: form.dirty, resetForm: form.resetForm},
                {immediate: true},
              );

              isDirtyCallback?.(form.dirty);
            } else if (form.dirty && isSubmittingRef.current !== form.isSubmitting) {
              isSubmittingRef.current = form.isSubmitting;

              PubSub.publish(
                'FORM.DIRTY',
                {id: props.id ?? id.current, dirty: !form.isSubmitting, resetForm: form.resetForm},
                {immediate: true},
              );

              isDirtyCallback?.(!form.isSubmitting);
            }
          }

          //remove span and move className back to FormikForm when new version of Formik support ref
          return (
            <FormikForm id={props.id ?? id.current} className={className} data-tid={dataTid}>
              {typeof children === 'function' ? children(form) : children}
            </FormikForm>
          );
        }}
      </Formik>
    </Form.SchemaContext.Provider>
  );
}

// Create context for passing schema down to form components
Form.SchemaContext = createContext<ObjectSchema>(undefined!);

// Add form components as static properties, so consumers can render <Form><Form.Checkbox/></Form> without a need to import them separately
Form.Checkbox = FormCheckbox;
Form.CheckboxGroup = FormCheckboxGroup;
Form.Input = FormInput;
Form.ColorPicker = FormColorPicker;
Form.Textarea = FormTextarea;
Form.RadioGroup = FormRadioGroup;
Form.Radio = Radio;
Form.Selector = FormOptionSelector;
Form.Utils = FormUtils;
Form.Label = FormLabel;
Form.LabelSelector = FormLabelSelector;
Form.FileUpload = FormFileUpload;
Form.ObjectSelector = FormSelector;
Form.Grid = FormGrid;
Form.IPListEditor = FormIPListEditor;
Form.DurationPicker = FormDurationPicker;
Form.DateTimeRangePicker = FormDateTimeRangePicker;
Form.DraftJS = {
  Utils: DraftJSUtils,
  Plugins: DraftJSPlugins,
};
Form.emptyMessage = ' ';
