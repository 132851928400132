/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './ContainerClusterListConfig';
import {getClusters} from 'containers/Health/HealthState';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getContainerClusters = state => state.containerClusters.list;
export const getContainerClustersCount = state => state.containerClusters.count;

const getContainerClusterRows = createSelector(getContainerClusters, containerClusters =>
  containerClusters.map(item => ({
    key: item.href,
    removable: Array.isArray(item.caps) && item.caps.includes('write'),
    data: {
      ...item,
    },
  })),
);

export const getGridSettings = createSelector([getClusters, gridSettings], (clusters, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.pce.disabled = clusters.length <= 1;

  return {...gridSettings, columns};
});

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getContainerClusterRows,
    filterMap: getFilterMap,
  });

export const getContainerClustersPage = createStructuredSelector({
  grid: getGrid,
  count: getContainerClustersCount,
  categories,
});
