/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getAllUsersMap, isWorkloadManagerReadOnly, isSuperclusterMember} from 'containers/User/UserState';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './PairingProfileListConfig';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {isAPIAvailable} from 'api/apiUtils';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';
import {isOnboardingObject} from 'antman/utils/general';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'PAIRING_PROFILE_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'PAIRING_PROFILE_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getPairingProfiles = state => state.pairingprofiles.list;
export const getPairingProfilesCount = state => state.pairingprofiles.count;

const getPairingProfilesRows = createSelector([getPairingProfiles, getAllUsersMap], (pairingProfile, usersMap) =>
  pairingProfile.map(item => {
    // Make Pairing Profile not selectable for read only user, since it cannot be removed, provisioned, or updated
    const isAllowed =
      Array.isArray(item?.caps) &&
      item.caps.includes('write') &&
      // Make Pairing Profile used by Xpress onboarding wizards not selectable
      (!__ANTMAN__ || (!item.is_default && !isOnboardingObject(item.external_data_reference)));

    return {
      key: item.href,
      selectable: isAllowed,
      removable: isAllowed,
      data: {
        ...item,
        updated_by: fillUserInfo(usersMap, item.updated_by),
        labelsMap: getMappedLabelsForGrid(item.labels),
      },
    };
  }),
);

export const getGridSettings = createSelector(gridSettings, gridSettings => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = !isAPIAvailable('pairing_profiles.delete');

  return {...gridSettings, columns};
});

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getPairingProfilesRows,
    filterMap: getFilterMap,
  });

export const getPairingProfilesPage = createSelector(
  [getPairingProfilesCount, getGrid, isWorkloadManagerReadOnly, isSuperclusterMember, categories],
  (count, grid, readOnlyUser, userIsSuperClusterMember, categories) => {
    // Selector parameters based on filter and config

    const userIsReadOnly = readOnlyUser || userIsSuperClusterMember;

    return {grid, count, categories, userIsReadOnly, userIsSuperClusterMember};
  },
);
