/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {useContext, useLayoutEffect, useRef} from 'react';
import {AppContext} from 'containers/App/AppUtils';

// AppProps component receives the following props plus all the theme* props
AppProps.propTypes = {
  // Do not apply frame styling to the App's content div (background, margins, borders etc)
  noContentFrame: PropTypes.bool,
  // Make the whole app not sensitive to user interaction
  insensitive: PropTypes.bool,
};

export default function AppProps(props) {
  const {upsert, remove} = useContext(AppContext);
  const idRef = useRef();

  // Remove theme from stack on unmount
  useLayoutEffect(
    () => () => {
      remove(idRef.current);
    },
    [remove, idRef],
  ); // remove, idRef never actually change, so specifying them as dependencies is the same as no dependecies

  // Call add/update theme on each render, upsert will actual rerender only if new and previous props are not equal deeply
  useLayoutEffect(() => {
    idRef.current = upsert(props, idRef.current);
  });

  return null;
}
