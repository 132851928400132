/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {composeThemeFromProps, type ThemeProps, Compose} from '@css-modules-theme/react';
import styles from './ReminderPopup.css';
import {Icon, Link, type LinkProps} from 'components';

import {typesUtils} from '@illumio-shared/utils';
import type {IconProps} from 'components/Icon/Icon';

export interface Duration {
  time?: number;
  unit?: string;
}

export type ReminderPopupProps = ThemeProps & {
  tid?: string;
  type?: 'critical' | 'warning';
  title?: typesUtils.ReactStrictNode;
  message?: typesUtils.ReactStrictNode | (() => typesUtils.ReactStrictNode);
  duration?: Duration | null;
  linkLabel: string;

  /** Link parameters */
  link?: Partial<LinkProps> | string;
  onClose: IconProps['onClick'];
};

export default function ReminderPopup(props: ReminderPopupProps): JSX.Element {
  const {tid, type, title, message, duration, link, linkLabel, onClose} = props;
  const theme = composeThemeFromProps(styles, props);

  const counter = duration && (
    <div data-tid="elem-counter" className={theme.counter}>
      {duration.time === 0 ? (
        <div className={theme.counterToday}>{intl('Common.Today')}</div>
      ) : (
        <>
          <div className={theme.counterTimeLeft}>{duration.time}</div>
          <div className={theme.counterTimeUnit}>{duration.unit}</div>
        </>
      )}
    </div>
  );

  let linkElement;

  if (link) {
    const linkProps = {
      'data-tid': 'elem-link',
      ...(!duration && {theme, themePrefix: 'expired-', themeCompose: 'merge' as Compose}),
      ...(typeof link === 'string' ? {to: link} : link),
      'children': linkLabel,
    };

    linkElement = <Link {...linkProps} />;
  }

  return (
    <div
      className={`${theme.reminder} ${type ? theme[type] : ''}`}
      data-tid={tid ? `comp-reminder-${tid}` : 'comp-reminder'}
    >
      {counter}
      <div className={duration ? theme.withCounter : theme.withoutCounter}>
        <div className={theme.title} data-tid="elem-title">
          {title}
        </div>
        <Icon name="close" onClick={onClose} theme={theme} themePrefix="close-" />
        <div className={theme.message} data-tid="elem-subtitle">
          {typeof message === 'function' ? message() : message}
        </div>
        {linkElement}
      </div>
    </div>
  );
}
