/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {List} from 'immutable';

/**
 * Process all current blockMaps then return all the ip data integrity for each block
 *
 * @params (Record) - editorState
 * @returns {List<Map>} - ipItems
 */
export const processIpItems = editorState => {
  const currentContent = editorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();

  let ipItems = List();

  blockMap.forEach(block => {
    const data = block.getData();
    // ip holds all the validation meta data for API
    const ip = data.get('ip');

    ipItems = ipItems.push(ip);
  });

  return ipItems;
};

/**
 * Get all items that are not removed.
 *
 * @params {Array} - items: ip_ranges, fqdn
 * @returns {Array} - unremoved items
 */
export const excludeRemovedItems = items => items.filter(item => !item.removed);
