/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select, put} from 'redux-saga/effects';
import {errorUtils} from '@illumio-shared/utils';
import {getVenLibraries, getAllVens, getVenLibraryCount, getGridSettings, getFilterMap} from './VenLibraryState';
import gridSaga from 'components/Grid/GridSaga';
import {isVenLibraryEnabled} from '../VenState';

export function* fetchVenLibraries({force = false} = {}, query = {representation: 'expanded_images'}) {
  if (yield select(isVenLibraryEnabled)) {
    return yield call(apiSaga, 'ven_software_releases.get_collection', {
      query,
      cache: !force,
      *onDone({data: list, count}) {
        if (force || list !== (yield select(getVenLibraries)) || count !== (yield select(getVenLibraryCount))) {
          yield put({type: 'VEN_LIBRARIES_GET_LIST', data: {list, count}});
        }

        return {list: yield select(getAllVens), count};
      },
    });
  }
}

export function* fetchVenLibraryList(route, refetch = false) {
  const venLibraryIsEnabled = yield select(isVenLibraryEnabled);

  if (!venLibraryIsEnabled) {
    throw new errorUtils.RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap,
    *onSaga() {
      const {list} = yield call(fetchVenLibraries, {force: refetch});

      return list.length;
    },
  });
}
