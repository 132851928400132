/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserOwner, isUserAdmin} from 'containers/User/UserState';
import listReducers from './List/AccessRestrictionListState';
import instanceReducers from './Item/AccessRestrictionItemState';

export default {
  accessrestrictions: combineReducers({
    ...instanceReducers,
    ...listReducers,
  }),
};

export const isAccessRestrictionEnabled = createSelector(
  [isUserOwner, isUserAdmin],
  (userIsOwner, userIsAdmin) => userIsOwner || userIsAdmin,
);
