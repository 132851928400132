/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';

export const SECONDS_PER_HOUR = 3600;
export const SECONDS_PER_DAY = 86_400;
export const SECONDS_PER_MONTH = 2_592_000;

const timeUnitInSeconds = new Map([
  ['second', 1],
  ['hour', SECONDS_PER_HOUR],
  ['day', SECONDS_PER_DAY],
  ['month', SECONDS_PER_MONTH],
]);

export const getDurationOption = (options, optionToFind) =>
  options.find(option => option.value === optionToFind.value && option.key === optionToFind.key);

export const convertTimeUnitToSeconds = (value, unit) => {
  const num = Number(value);

  if (Number.isNaN(num)) {
    return 0;
  }

  if (num === -1) {
    return -1;
  }

  return (timeUnitInSeconds.get(unit) || 0) * num;
};

export const convertSecondsToTimeUnit = (value, zeroTimeUnit = 'day') => {
  const num = Number(value);

  if (Number.isNaN(num)) {
    return {value: 0, key: 'day'};
  }

  // Convert seconds to the preferred unit order: infinity/day/hour/second.
  if (num === -1) {
    return {value: -1, key: 'infinity'};
  }

  if (num === 0) {
    return {value: 0, key: zeroTimeUnit};
  }

  if (num % SECONDS_PER_DAY === 0) {
    return {value: num / SECONDS_PER_DAY, key: 'day'};
  }

  if (num % SECONDS_PER_HOUR === 0) {
    return {value: num / SECONDS_PER_HOUR, key: 'hour'};
  }

  return {value: num, key: 'second'};
};

export const convertSecondsToTimeUnitValue = (value, unit) => {
  const num = Number(value);

  if (Number.isNaN(num)) {
    return 0;
  }

  return timeUnitInSeconds.has(unit) ? Math.floor(num / timeUnitInSeconds.get(unit)) : 0;
};

export const getDurationOptionLabel = (value, key, defaultValueInSeconds) => {
  let str = '';
  const defaultMarker =
    !Number.isNaN(defaultValueInSeconds) && convertTimeUnitToSeconds(value, key) === defaultValueInSeconds
      ? ` (${intl('Common.Default')})`
      : '';

  switch (key) {
    case 'second':
      str = intl('Common.SecondsNumber', {count: Number(value)});
      break;
    case 'hour':
      str = intl('Common.HoursNumber', {count: Number(value)});
      break;
    case 'day':
      str = intl('Common.DaysNumber', {count: Number(value)});
      break;
    case 'month':
      str = intl('Common.MonthsNumber', {count: Number(value)});
      break;
    case 'custom':
      return intl('Common.Custom');
    case 'infinity':
      return `${intl('Common.NeverExpires')}${defaultMarker}`;
    default:
      return '';
  }

  return `${str.toLocaleLowerCase()}${defaultMarker}`;
};

export const areDurationsEqual = (a, b) => a.value === b.value && a.key === b.key;
