/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

/**
 * Generate a string of tid for an element
 *
 * @param prefix Prefix to be used
 * @param tids Tids can be a string or list
 * @returns The name of the string
 */
export const getTid = (prefix: string, tids?: (string | undefined)[] | string): string => {
  if (Array.isArray(tids)) {
    return tids.reduce((result: string, tid) => {
      if (tid) {
        result += ` ${prefix}-${tid}`;
      }

      return result;
    }, prefix);
  }

  if (tids) {
    return `${prefix} ${prefix}-${tids}`;
  }

  return String(prefix);
};
