/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select, put, all} from 'redux-saga/effects';
import {getVens, getVensCount, getFilterMap} from './VenListState';
import {gridSettings} from './VenListConfig';
import gridSaga from 'components/Grid/GridSaga';
import {fetchHealth} from 'containers/Health/HealthSaga';
import {
  fetchActiveVens,
  fetchErrorVens,
  fetchSuspendedVens,
  fetchPendingVens,
  fetchWarningVens,
} from 'containers/Ven/Upgrade/VenUpgradeSaga';

export function* fetchVens({containerCluster, filter, force = false} = {}) {
  const query = {representation: 'ven_labels'};

  if (containerCluster) {
    query.container_clusters = JSON.stringify([{href: containerCluster}]);
  }

  if (filter) {
    for (const [name, values] of Object.entries(filter)) {
      // filter value is the first element of the array since most of the filters in workloads list page are single select
      const value = values[0];

      switch (name) {
        case 'name':
        case 'hostname':
          const queryValue = typeof value === 'object' ? value.value : value;

          query[queryValue.includes('*') ? `advanced_search_${name}` : name] = queryValue;
          break;
        case 'last_heartbeat_at':
        case 'version':
          if (value.from) {
            query[`${name}[gte]`] = value.from;
          }

          if (value.to) {
            query[`${name}[lte]`] = value.to;
          }

          break;
        case 'condition':
          switch (value === 'authentication_recovery') {
            case true:
              query.disconnected_before = new Date(value.value).toJSON();

              break;
            default:
              query[name] = typeof value === 'string' ? value : value.id;
          }

          break;
        case 'disconnected_before':
          query.disconnected_before = new Date(value.value).toJSON();

          break;
        case 'container_clusters':
          query.container_clusters = JSON.stringify([value.href]);

          break;
        case 'labelsAndLabelGroups':
          query.xxxlabels = [values.map(({href}) => href)];

          break;
        default:
          query[name] = typeof value === 'object' ? value.id : value;
      }
    }
  }

  return yield call(apiSaga, 'vens.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getVens)) || count !== (yield select(getVensCount))) {
        yield put({type: 'VEN_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* fetchVenListAdditionalData() {
  yield all([
    call(fetchErrorVens, {force: true}),
    call(fetchActiveVens, {force: true}),
    call(fetchSuspendedVens, {force: true}),
    call(fetchPendingVens, {force: true}),
    call(fetchWarningVens, {force: true}),
  ]);
}

export function* fetchVenList(route, refetch = false, options = {}) {
  const {params} = route;
  const {
    getGridSettings = gridSettings,
    getFilters = getFilterMap,
    containerCluster,
    ignoreScope,
    customFilter,
  } = options;

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilters,
    *onSaga({filterParams}) {
      const [{list}] = yield all([
        call(fetchVens, {
          containerCluster,
          ignoreScope,
          params,
          filter: filterParams.isEmpty ? customFilter : {...filterParams.valid, ...customFilter},
          force: refetch,
        }),
        call(fetchHealth, {force: refetch}),
      ]);

      return list.length;
    },
  });
}
