/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
export default {
  settings(state = false, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_SETTINGS':
        return action.data.enabled;
      default:
        return state;
    }
  },
  scanner_settings(state = false, action) {
    switch (action.type) {
      case 'CORESERVICES_GET_SETTINGS':
        return action.data.scanner_enabled === undefined ? state : action.data.scanner_enabled;
      default:
        return state;
    }
  },
};

export const getCoreServiceSettings = state => (state.coreServices?.settings ? 'enabled' : 'disabled');
export const getScannerDetectionSettings = state => (state.coreServices?.scanner_settings ? 'enabled' : 'disabled');
