/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {fetchHealthMetricDescriptions} from 'containers/Health/HealthSaga';

export function getHelpPopupSagaFromRoute(routeName) {
  if (routeName?.startsWith('app.health.detail')) {
    return fetchHealthMetricDescriptions;
  }

  return;
}
