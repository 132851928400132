/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
/* TODO: This should move to Node.js in Asgard which will derive locale from saved user settings and Accept-Language */
/* TODO: And then it will set it a global variable in index.html, so polyfills can also read from it   */

import {match} from '@formatjs/intl-localematcher';
import config from 'config';

declare global {
  interface Navigator {
    userLanguage: string;
  }
}

// Take client preferred language from browser navigator
// If navigator doesn't exist (for example in tests), take default language from config
const browserLocale =
  (typeof navigator === 'object' && (navigator.languages || navigator.userLanguage || navigator.language)) ||
  config.langs[0] ||
  'en';

const browserLocales = Array.isArray(browserLocale) ? (browserLocale as string[]) : [browserLocale as string];

export const localeFallback = 'en';
export const localeDefault = config.locales[0] || localeFallback;

// Final user's locale, for example, 'en-US'
export const locale = match(browserLocales, config.locales, localeDefault);
// Final user's language, for example, 'en'
export const lang = locale.split('-')[0]; // Result user's language, for example, 'en'
