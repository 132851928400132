/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getRouteName, getRouteParams, isReportingEnabled} from 'containers/App/AppState';
import {isSuperclusterUser, isFlowAnalyticsEnabled} from 'containers/User/UserState';
import {getHelpPopup} from 'containers/HelpPopup/HelpPopupState';
import {
  getInRollingUpgrade,
  getClusters,
  getActiveWhileUpgradeFinished,
  getLocalListenOnlyEnableTime,
  getClustersMap,
} from 'containers/Health/HealthState';
import {isRollingUpgradeUnknown} from 'containers/Health/HealthUtils';

export const isHealthMetricsEnabled = createSelector(
  getRouteParams,
  getClustersMap,
  getClusters,
  (routeParams, clustersMap, clusters) => {
    const fqdn = routeParams.fqdn;
    const pceData = fqdn === 'local' ? clusters[0] : clustersMap[fqdn];

    return Boolean(pceData?.groups);
  },
);

export const getHealthDetailPage = createSelector(
  [
    isFlowAnalyticsEnabled,
    getInRollingUpgrade,
    getClustersMap,
    getClusters,
    getActiveWhileUpgradeFinished,
    isSuperclusterUser,
    isHealthMetricsEnabled,
    isReportingEnabled,
    getLocalListenOnlyEnableTime,
    getRouteParams,
    getRouteName,
    getHelpPopup,
  ],
  (
    isFlowAnalyticsEnabled,
    inUpgrade,
    clustersMap,
    clusters,
    activeWhileUpgradeFinished,
    userIsSuperclusterUser,
    healthMetricsEnabled,
    reportingIsEnabled,
    listenOnlyEnableTime,
    routeParams,
    routeName,
    helpPopup,
  ) => {
    const fqdn = routeParams.fqdn;
    const {group = 'Node'} = routeParams;
    let pceData = fqdn === 'local' ? clusters[0] : clustersMap[fqdn];

    if (userIsSuperclusterUser) {
      listenOnlyEnableTime = pceData && pceData.listen_only_mode_enabled_at;
    }

    /**
     * Initialize superclusterReplicationValues for Supercluster Replication Section
     */
    const superclusterReplicationValues = _.get(pceData, 'network.replication', []).filter(
      ({type}) => type === 'intercluster',
    );

    /**
     * Initialize illuminationSyncValues for Illumination Sync Section
     */
    const illuminationSyncValues = _.get(pceData, 'network.illumination_sync', []).find(
      ({fqdn}) => fqdn === pceData.fqdn,
    );

    /**
     * Initialize count map and allRunning boolean for PCE Service Alerts Section
     */
    const count = {notRunning: 0, optional: 0, degraded: 0};

    if (pceData && pceData.nodes && pceData.nodes.length) {
      pceData.nodes.forEach(node => {
        count.notRunning = count.notRunning + _.get(node, 'services.not_running', []).length;
        count.optional = count.optional + _.get(node, 'services.optional', []).length;
        count.degraded =
          count.degraded + _.get(node, 'services.partial', []).length + _.get(node, 'services.unknown', []).length;
      });
    }

    // Initialize nodes = [] to an array during a super cluster rolling upgrade when the cluster is in critical state.
    // The health/ API won't return nodes reference thus need to initialize to empty []. After speaking with backend engineers,
    // during rolling upgrade in critical state, the health/API may not return all necessary properties like : nodes
    if (pceData && !pceData.nodes) {
      pceData = {...pceData, nodes: []};
    }

    const allRunning = count.notRunning + count.optional + count.degraded === 0;
    const unreachableClusters = clusters.filter(({status}) => status === 'error').map(({fqdn}) => fqdn);

    // When type='unknown' then member is in a rolling upgrade with status critical
    const showMemberRollingUpgradeStatus = isRollingUpgradeUnknown(inUpgrade, pceData);

    return {
      group,
      allRunning,
      count,
      illuminationSyncValues,
      isFlowAnalyticsEnabled,
      pceData,
      clusters,
      listenOnlyEnableTime,
      userIsSuperclusterUser,
      superclusterReplicationValues,
      unreachableClusters,
      inRollingUpgrade: userIsSuperclusterUser && inUpgrade,
      activeWhileUpgradeFinished: userIsSuperclusterUser && activeWhileUpgradeFinished,
      showMemberRollingUpgradeStatus,
      healthMetricsEnabled,
      reportingIsEnabled,
      healthCustomItems: helpPopup.customItems[routeName] || [],
    };
  },
);
