/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {createSelector, createStructuredSelector} from 'reselect';
import {isAPIAvailable} from 'api/apiUtils';
import {isMultipleEndpointCorporateIPsEnabled} from 'containers/App/AppState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './CorporatePublicIPsListConfig';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'CORPORATE_IP_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
};

export const getCorporateIPList = state => state.corporatepublicips.list;

export const getCorporateIPListRows = createSelector([getCorporateIPList], corporateIPList => {
  return corporateIPList.map(item => ({
    key: item.href,
    selectable: item.name !== 'default',
    data: item,
  }));
});

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getCorporateIPListRows,
  });

export const getCorporateIPListPage = createStructuredSelector({
  hasWritePermission: () => isAPIAvailable('corporate_ips_setting.update'),
  list: getCorporateIPList,
  grid: getGrid,
  isMultipleGroupsEnabled: isMultipleEndpointCorporateIPsEnabled,
});
