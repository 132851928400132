/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import type {Writable} from 'type-fest';
import type {Theme} from '@css-modules-theme/react';
import {Component, createRef, type MutableRefObject, type ReactElement} from 'react';
import MenuItems, {type MenuItemsProps} from './MenuItems';
import {items as itemsMotions, type MotionDirection, type TransitionStyle} from './motions';
import {motion} from 'framer-motion';
import {MenuItem} from 'components';

type MotionStyle = {width: number; height: number};

interface BaseProps {
  dir?: MotionDirection;
  tid?: string;
  theme: Theme;

  saveItemsRef(menuItems: MenuItems): void;

  parentFocusedItem?: MenuItem | boolean;

  updateDropdownPosition?: () => void;
}

export interface MenuItemsContainerProps extends Omit<Partial<MenuItemsProps>, keyof BaseProps>, BaseProps {}

type MenuItemsContainerState = Readonly<{
  itemsKeyRef: MutableRefObject<number>;

  /**
   * Cache to save key and rectangle (width, height) values for each items list
   * Map<children:{key, rect}>
   */
  itemsCacheMap: Map<MenuItemsProps['children'], {key: string; rect: {width: number; height: number}}>;

  /**
   * Items that are currently rendered (have dom elements) to track really active items list
   * Map<children:itemListInstance>
   */
  showingItemsListsMap: Map<MenuItemsProps['children'], MenuItems>;
  itemsContainerMotionConfig: MotionStyle;
  itemsTransitionMotionConfig: TransitionStyle[];
  children: MenuItemsContainerProps['children'];
  parentFocusedItem?: MenuItemsContainerProps['parentFocusedItem'];
}>;

export default class MenuItemsContainer extends Component<MenuItemsContainerProps, MenuItemsContainerState> {
  transitionMotionForDimention: ReactElement | null = null;

  constructor(props: MenuItemsContainerProps) {
    super(props);

    // Items list key as simple increasing sequence number
    const itemsKeyRef = createRef() as MenuItemsContainerState['itemsKeyRef'];

    itemsKeyRef.current = 1;
    this.state = {
      itemsKeyRef,

      itemsCacheMap: new Map(),

      showingItemsListsMap: new Map(),

      // By default container size is zero, it will be calculated after first items list render
      itemsContainerMotionConfig: {width: 0, height: 0},

      // At the first show children should be in opened position
      itemsTransitionMotionConfig: [
        {
          key: String(itemsKeyRef.current),
          data: props.children,
          style: {y: 0, opacity: 1},
        },
      ],

      children: props.children,
    };

    this.saveItemsRef = this.saveItemsRef.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Readonly<MenuItemsContainerProps>, prevState: MenuItemsContainerState) {
    const newState: Writable<Partial<MenuItemsContainerState>> = {parentFocusedItem: nextProps.parentFocusedItem};
    const children = nextProps.children;

    if (children !== prevState.children) {
      const itemsCache = prevState.itemsCacheMap.get(children);

      newState.children = children;

      if (itemsCache) {
        // If we know dimensions of new children (they were rendered sometime before current children),
        // set this height as target right away (don't need to wait saveItemsRef)
        // and use its key to return list back if it's in closing animation state
        newState.itemsContainerMotionConfig = itemsCache.rect;

        newState.itemsTransitionMotionConfig = [
          {
            data: children,
            key: itemsCache.key,
            style: itemsMotions.listActive,
          },
        ];
      } else if (nextProps.parentFocusedItem === prevState.parentFocusedItem) {
        // If parent's focused item is the same, that means menu is being re-rendered (and now there is no dropdown animation),
        // and we just need to update children with the same motion style
        newState.itemsTransitionMotionConfig = [
          {
            data: children,
            key: prevState.itemsTransitionMotionConfig[0].key,
            style: prevState.itemsTransitionMotionConfig[0].style,
          },
        ];
      } else {
        // If we are going to render new children for the first time,
        // stop height animation (for instance, if width/height is being animated in different direction),
        // by assigning plain values to motion config and wait for the new children's dimensions in saveItemsRef
        const currentConfig = prevState.itemsContainerMotionConfig;

        newState.itemsContainerMotionConfig = {
          width: currentConfig.width,
          height: currentConfig.height,
        };

        newState.itemsTransitionMotionConfig = [
          {
            data: children,
            key: String(++prevState.itemsKeyRef.current),
            style: itemsMotions.listActive,
          },
        ];
      }
    }

    return newState;
  }

  componentDidUpdate() {
    // Need to check if active list has been changed
    // It can happen when user's pointer hovers previously hovered element before its items list is destroyed.
    // For instance, user move pointer forth and back over items with children as quickly as transition motion from
    // one item list to another is not finished and there will be no saveItemsRef on returned items list
    this.notifyUpperDropdownOnRefChange();
  }

  private saveItemsRef(itemList: MenuItems) {
    const incoming = Boolean(itemList.listElement);

    if (incoming) {
      // Add rendered items list to map
      this.state.showingItemsListsMap.set(itemList.props.children, itemList);

      if (!this.state.itemsCacheMap.get(itemList.props.children)) {
        this.updateSize(itemList);
      }
    } else {
      // Delete unmounted items list from map
      this.state.showingItemsListsMap.delete(itemList.props.children);
    }

    this.notifyUpperDropdownOnRefChange();
  }

  private updateSize(itemList: MenuItems) {
    const {rect} = itemList;

    const dim = {width: rect?.width ?? 0, height: rect?.height ?? 0};

    // Render one more time with new dimensions
    // Update invokes render on next tick, after componentDidMount/DidUpdate
    this.setState(prevState => ({
      itemsContainerMotionConfig: {
        width: dim.width,
        height: dim.height,
      },

      // Save items list dimensions for transitioning to it next time without waiting for the ref
      itemsCacheMap: prevState.itemsCacheMap.set(itemList.props.children, {
        key: prevState.itemsTransitionMotionConfig[0].key,
        rect: dim,
      }),
    }));
  }

  private notifyUpperDropdownOnRefChange() {
    // It is called when component itself or ref for underlying itemList have been updated
    // to notify upper dropdown component that active items list probably has been changed
    const activeItemList = this.state.showingItemsListsMap.get(this.props.children);

    if (activeItemList) {
      this.props.saveItemsRef(activeItemList);
    }
  }

  render() {
    const containerConfig = this.state.itemsContainerMotionConfig;

    const {
      props: {dir, children, saveItemsRef, parentFocusedItem, updateDropdownPosition, ...menuItemsProps},
    } = this;
    const motionConfig = this.state.itemsTransitionMotionConfig[0];
    const localAnimation = {
      initial: itemsMotions.listEnter(this.props.dir),
      animate: {
        ...itemsMotions.listActive,
        ...(containerConfig.width && containerConfig.height
          ? {opacity: 1, width: containerConfig.width, height: containerConfig.height}
          : {opacity: 1}),
      },
      exit: itemsMotions.listLeave(this.props.dir),
      transition: itemsMotions.config,
    };

    return (
      <motion.div
        {...localAnimation}
        key={motionConfig.key}
        className={this.props.theme.itemsContainer}
        initial={false}
        style={{width: `${containerConfig.width}px`, height: `${containerConfig.height}px`}}
      >
        <div className={this.props.theme.itemsExtender}>
          <MenuItems
            {...menuItemsProps}
            key={motionConfig.key}
            active={motionConfig.key === this.state.itemsTransitionMotionConfig[0].key}
            style={motionConfig.style as MenuItemsProps['style']}
            updateContainerSize={this.updateSize}
            saveMenuItemsRef={this.saveItemsRef}
          >
            {motionConfig.data as MenuItemsProps['children']}
          </MenuItems>
        </div>
      </motion.div>
    );
  }
}
