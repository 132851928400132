/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

/* Core-js polyfills go first */
import './core-js';
/* Get user locale from a module until Node.js on the backend sets it in index.html */
import {locale} from '@illumio-shared/utils/intl/locale';

// If browser doesn't support Cookie Store API, load polyfill
// See https://caniuse.com/cookie-store-api
// Remove along with `cookie-store` package once Firefox and Safari support it (versions tbd)
if (typeof cookieStore !== 'object' || typeof cookieStore.set !== 'function') {
  await import(/* webpackChunkName: 'vendor/polyfills/cookie' */ 'cookie-store');
}

/**
 * If the browser doesn't support Intl.DisplayNames V2, load polyfill
 * https://formatjs.io/docs/polyfills/intl-displaynames
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames
 * Remove along with `@formatjs/intl-displaynames` package once shouldPolyfill returns false.
 *
 * V1: https://github.com/tc39/proposal-intl-displaynames
 * Chrome 81+ https://chromestatus.com/feature/4965112605573120 but has bugs https://bugs.chromium.org/p/chromium/issues/detail?id=1176979
 * Safari 14.1+ https://developer.apple.com/documentation/safari-release-notes/safari-14_1-release-notes
 * Firefox 86+ https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/86
 *
 * V2: https://github.com/tc39/proposal-intl-displaynames-v2
 * Chrome 95+ https://chromestatus.com/feature/5082027281874944
 * Safari 15.4+ https://developer.apple.com/documentation/safari-release-notes/safari-15_4-release-notes#JavaScript
 * Firefox 86+ https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/86
 */
const displayLocale = (
  await import(/* webpackMode: 'eager' */ '@formatjs/intl-displaynames/should-polyfill')
).shouldPolyfill(locale);

if (displayLocale) {
  (await import(/* webpackChunkName: 'vendor/polyfills/intl-display' */ './intl-display')).polyfill(displayLocale);
}

/**
 * If the browser doesn't support Intl.NumberFormat Unified API (V2) or V3, load polyfill
 * https://formatjs.io/docs/polyfills/intl-numberformat (Doesn't support 'formatRange' well yet in version 8.5.0)
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * Remove along with `@formatjs/intl-unified-numberformat` package once we drop support for previous browser versions
 *
 * V2: https://github.com/tc39/proposal-unified-intl-numberformat and https://v8.dev/features/intl-numberformat
 * Chrome 77+ https://v8.dev/features/intl-numberformat
 * Safari 14.1+ https://developer.apple.com/documentation/safari-release-notes/safari-14_1-release-notes
 * Firefox 78+ https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/78
 *
 * V3: https://github.com/tc39/proposal-intl-numberformat-v3
 * Chrome 106+  https://chromestatus.com/feature/5707621009981440
 * Safari 15.4+ https://developer.apple.com/documentation/safari-release-notes/safari-15_4-release-notes#JavaScript
 * Firefox 116+ https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/116
 */
let numberLocale = (
  await import(/* webpackMode: 'eager' */ '@formatjs/intl-numberformat/should-polyfill')
).shouldPolyfill(locale);

// Manually check for `formatRange` support since shouldPolyfill doesn't check it yet
// Can remove when will start checking correctly https://github.com/formatjs/formatjs/pull/3860#issuecomment-1532312677
if (!numberLocale) {
  try {
    new Intl.NumberFormat(locale, {style: 'currency', currency: 'USD'}).formatRange(1, 2);
  } catch {
    // Manually call Intl.LocaleMatcher polyfill since there is no browser support of it currently.
    // It also bundled with every @formatjs 'should-polyfill', so no need to import eagerly or lazily
    // https://formatjs.io/docs/polyfills/intl-localematcher
    const {match} = await import('@formatjs/intl-localematcher');

    numberLocale = match(
      [locale],
      // Load the list of supported locales not lazily, because it's already included in should-polyfill
      (await import('@formatjs/intl-numberformat/supported-locales.generated')).supportedLocales,
      'en',
    );
  }
}

if (numberLocale) {
  (await import(/* webpackChunkName: 'vendor/polyfills/intl-number' */ './intl-number')).polyfill(numberLocale);
}

export {};
