/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserScoped} from 'containers/User/UserState';
import configReducers from './Config/EventsConfigState';
import eventsListReducers from './List/EventsListState';
import eventsDetailReducers from './Detail/EventsDetailState';

export default {
  events: combineReducers({
    ...eventsListReducers,
    ...eventsDetailReducers,
    ...configReducers,
  }),
};

export const isEventsEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
