/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {assign, raise} from 'xstate';

// mergeable machine that handle errors from batch API calls where all {message, token} objects are included in response
export const batchErrors = {
  states: {
    modal: {
      states: {
        open: {
          // preserving initialy selected hrefs so we can reference them later
          entry: assign({selectedHrefs: ctx => ctx.hrefs}),
        },
      },
    },
    app: {
      states: {
        complete: {
          on: {
            // make an API call again by transitioning to process state with hrefs that didn't have any error message
            'PROCESS.RETRY': {actions: assign({hrefs: ctx => [...ctx.hrefsLeftToRemove]}), target: 'process'},
          },
        },
      },
    },
    errorProcessor: {
      id: 'batchErrors',
      initial: 'idle',
      states: {
        idle: {
          on: {
            ERROR: 'processError',
          },
        },
        processError: {
          // save ProcessAPIError information within machine context
          entry: assign(({processResult: {data}}) => ({...data})),
          always: [
            // transient transition with two different outcomes:
            // 1. we completed retry logic and now all errors have been processed;
            // 2. we should retry an API call with hrefs that didn't have any errors;
            {
              target: 'doneWithErrors',
              actions: raise('DONE.WITH.ERRORS'),
              cond: ctx => ctx.totalErrors === ctx.hrefs.length || ctx.statusCode === 500,
            },
            {target: 'processRetry', actions: raise('PROCESS.RETRY')},
          ],
        },
        doneWithErrors: {},
        processRetry: {},
      },
    },
  },
};

export const settledErrors = {
  states: {
    modal: {
      states: {
        open: {
          // preserving initially selected hrefs so we can reference them later
          entry: assign({selectedHrefs: ctx => ctx.hrefs}),
        },
      },
    },
    errorProcessor: {
      id: 'settledErrors',
      initial: 'idle',
      states: {
        idle: {
          on: {
            // since everything is getting wrapped over here to Promise.allSettled all errors will be suppressed
            // we need to trigger error state in modal machine by looking on responses
            // two conditional transitions are available over here:
            // 1. if we have errors, we need go over all of them and accumulate all errors for later consumption
            // 2. we had no errors, we don't need to trigger an error state.
            DONE: [
              {
                target: 'processError',
                // condition is based on 'process' state of app machine
                // API error will be saved in context as 'processResult'
                // All API responses will be within array that has objects with 'status' of Promise and resolve or reject result as 'reason'
                cond: ctx => ctx.processResult.data.some(({status}) => status === 'rejected'),
                actions: assign(ctx => {
                  let {
                    processResult: {data},
                    errorsMap,
                    errorsHrefs,
                  } = ctx;

                  data
                    .filter(({status}) => status === 'rejected')
                    .forEach(({reason: response}) => {
                      response.errorsMap.forEach((message, token) => {
                        errorsHrefs = [...errorsHrefs, ...response.errorsHrefs];

                        if (errorsMap.get(token)) {
                          const {hrefs, message} = errorsMap.get(token);

                          errorsMap.set(token, {hrefs: [...hrefs, ...response.errorsHrefs], message});
                        } else {
                          errorsMap.set(token, {hrefs: response.errorsHrefs, message});
                        }
                      });
                    });

                  return {
                    errorsMap,
                    errorsHrefs,
                  };
                }),
              },
              {target: 'noErrors'},
            ],
          },
        },
        // sending event to modal machine to inform that we had some errors and error state is needed
        processError: {
          entry: raise('DONE.WITH.ERRORS'),
        },
        noErrors: {},
      },
    },
  },
};

export default {
  batchErrors,
  settledErrors,
};
