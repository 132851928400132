/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {ExpandableScopeList, Pill, StatusIcon} from 'components';
import {UserName} from 'containers';
import * as GridUtils from 'components/Grid/GridUtils';
import {
  populateFacetCategory,
  populateLabelsCategories,
  provisionStatusCategory,
} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {getProvisionStatusColumn} from 'components/Grid/GridUtils';
import stylesUtils from 'utils.css';
import styles from './RulesetList.css';

export const resourceType = 'rule_sets';
export const categories = createSelector(
  [getDisplayNames, getTypeInitialRegExp],
  (labelTypesNameObj, labelTypeInitialRegExp) => [
    populateFacetCategory({id: 'name', name: intl('Common.Name'), resourceType, params: {pversion: 'draft'}}),
    ...populateLabelsCategories({
      resourceType,
      hasNoLabels: true,
      labelTypesNameObj,
      labelTypeInitialRegExp,
    }),
    populateFacetCategory({
      id: 'description',
      name: intl('Common.Description'),
      resourceType,
      params: {pversion: 'draft'},
    }),
    provisionStatusCategory,
  ],
);

/**
 [{
 header: string | Function,

 key: string | Function,
 value: string | Function,
 format: node | Function,
 sort: Function, // Getter for sorting value
 sortFunction: Function, // Custom sort function
 sortable: [true]boolean,
 isDate: boolean | string,
 }];
 */

export const rulesetScopeColumn = {
  header: intl('Common.Scopes'),
  sortable: false,
  disabled: __ANTMAN__,
  value: 'scopes',
  refs: ({value}) =>
    value[0].reduce((result, {label, label_group}) => {
      result[label?.href ?? label_group.href] = label => label?.element;

      return result;
    }, {}),
  onMouseOver: ({evt, elements}) => {
    return Object.values(elements).every(element => !element?.contains(evt.target));
  },
  format: ({value, refs}) => {
    if (!value[0]?.length) {
      return <Pill.Label all />;
    }

    return (
      <ExpandableScopeList.Short values={value} labelTypeAccessor="label.key" labelGroupTypeAccessor="label_group.key">
        {({label, label_group, exclusion}) => {
          const props = {exclusion};

          if (label_group) {
            props.group = true;
            props.key = label_group.href;
            props.type = label_group.key;
            props.href = label_group.href;
            props.ref = refs[label_group.href];
          } else {
            props.key = label.href;
            props.type = label.key;
            props.href = label.href;
            props.ref = refs[label.href];
          }

          return (
            <Pill.Label {...props} themePrefix="rulesetListGrid-" theme={styles}>
              {label_group ? label_group?.name : label.value}
            </Pill.Label>
          );
        }}
      </ExpandableScopeList.Short>
    );
  },
};

export const gridSettings = createSelector([], () => ({
  id: 'rulesetlist',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {},
    status: getProvisionStatusColumn(),
    state: {
      header: intl('Common.Status'),
      value: 'enabled',
      format: ({value}) => (
        <StatusIcon
          position="before"
          status={value ? 'inuse' : 'disabled-status'}
          label={
            <span className={stylesUtils.semiBold}>{value ? intl('Common.Enabled') : intl('Common.Disabled')}</span>
          }
        />
      ),
    },
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    scope: rulesetScopeColumn,
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
   // Possible dimensions of breakpoint, go to format function
   minWidth: ?number,
   maxWidth: ?number,
   minHeight: ?number,
   maxHeight: ?number,

   // Required columns configuration for breapoint
   template: array | Function,

   // Optional breakpoint id, goes to format function
   id: ?string,
   // Optional props that will be merged to Grid container element
   props: ?object
   // Optional object with any data, goes to format function
   data: ?object,
   }];
   */
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['status'], size: 'min-content'},
      {columns: ['state'], size: 'minmax(120px, auto)'},
      {columns: ['name'], size: 'minmax(120px, auto)'},
      {columns: ['scope'], size: 'minmax(350px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(130px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(130px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['state'], size: 'minmax(120px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['scope'], size: 'minmax(400px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(130px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(130px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['state'], size: 'minmax(120px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['scope'], size: 'minmax(400px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(130px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(130px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1200,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['state'], size: 'minmax(120px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
            {columns: ['scope'], size: 'minmax(400px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['state'], size: 'minmax(120px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['scope'], size: 'minmax(400px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['status'], size: 'min-content'},
            {columns: ['name', 'state'], size: 'minmax(120px, auto)'},
            {columns: ['scope'], size: 'minmax(400px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['status'], size: 'min-content'},
          {columns: ['name', 'state'], size: 'minmax(120px, auto)'},
          {columns: ['scope'], size: 'minmax(400px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'state', 'status'], size: 'minmax(200px, auto)'},
            {columns: ['scope'], size: 'minmax(200px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'state', 'status'], size: 'minmax(200px, auto)'},
          {columns: ['scope'], size: 'minmax(200px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['name', 'scope', 'state', 'status'], size: 'minmax(170px, auto)'},
            {columns: ['updatedAt', 'updatedBy'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {columns: ['name', 'scope', 'state', 'status'], size: 'minmax(170px, auto)'},
          {columns: ['updatedAt', 'updatedBy'], size: 'minmax(150px, auto)'},
        ];
      },
    },
  ],
}));
