/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {useSelector as useReduxSelector} from 'react-redux';

// eslint-disable-next-line import/export
export * from 'react-redux';

/*

In react-redux v8.0.0+, the root state type is no longer inferred in its methods.
One can either type it every time like this:

useSelector((state: RootState) => state...)

or create custom typed methods and import them, which is recommended and
is what's done here.

https://redux.js.org/usage/usage-with-typescript
"While it's possible to import the RootState and AppDispatch types into
each component, it's better to create pre-typed versions of the
useDispatch and useSelector hooks for usage in your application ...
it saves you the need to type (state: RootState) every time"

For the sake of consistency, all react-redux imports, not just useSelector,
are now enforced with an eslint rule to be from this file.

RootState is declared as {} since our state is generated dynamically, and we
are not able to import its type.

*/

export interface RootState {}

// eslint-disable-next-line import/export
export const useSelector = useReduxSelector as <T>(
  ...args: Parameters<typeof useReduxSelector<RootState, T>>
) => ReturnType<typeof useReduxSelector<RootState, T>>;
