/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {hrefUtils} from '@illumio-shared/utils';
import intl from '@illumio-shared/utils/intl';
import {Pill, Link} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';

export const getApplyLabelsRulesConfig = () => ({
  id: 'applyruleslist',
  showCapacity: true,
  capacity: 50,
  capacities: [25, 50, 100, 250, 500],
  showPagination: true,
  maxPage: Number.MAX_SAFE_INTEGER,
  columns: {
    hostname: {
      header: intl('Common.Hostname'),
      sortable: false,
      value: ({row}) => row.data.hostname,
      ...GridUtils.clickableColumn({
        format: ({value, row, clickableRef}) => (
          <Link to="workloads.item" params={{id: hrefUtils.getId(row.data.href)}} ref={clickableRef}>
            {value}
          </Link>
        ),
      }),
    },
    assignlabel: {
      header: intl('Antman.Workloads.NewLabels'),
      sortable: false,
      format: ({row}) =>
        row.data.assignLabels?.map(label => (
          <Pill.Label key={label.href ?? `${label.key}-${label.value}`} noContextualMenu type={label.key}>
            {label.value}
          </Pill.Label>
        )),
    },
    existinglabels: {
      header: intl('Common.ExistingLabels'),
      sortable: false,
      format: ({row}) => {
        return row.data.existingLabels?.map(label => (
          <Pill.Label key={label.href ?? `${label.key}-${label.value}`} noContextualMenu type={label.key}>
            {label.value}
          </Pill.Label>
        ));
      },
    },
  },
  templates: [
    [
      {columns: ['hostname'], size: 'minmax(60px, auto)'},
      {columns: ['assignlabel'], size: 'minmax(100px, auto)'},
      {columns: ['existinglabels'], size: 'minmax(100px, auto)'},
    ],
  ],
});
