/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {combineReducers} from 'redux';
import {isUserScoped} from 'containers/User/UserState';
import {getRouteParams} from 'containers/App/AppState';
import {calcAppGroupName, mergeLatestCoverageWithState} from './AppGroupUtils';
import vulnerabilitiesReducers from './Vulnerabilities/AppGroupVulnerabilitiesState';

export default {
  appGroup: combineReducers({
    ...vulnerabilitiesReducers,
    appGroups(state = [], action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_LIST':
          return action.data.nodes;
        default:
          return state;
      }
    },
    appGroupSummary(state = {nodes: [], labels: []}, action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_DETAILS':
          return action.data;
        default:
          return state;
      }
    },
    appGroupLabels(state = [], action) {
      switch (action.type) {
        case 'APPGROUPSUMMARY_GET_LIST':
          return action.data.labels;
        default:
          return state;
      }
    },

    /**
     * stores the rule coverage for app groups; state is an object of appGroupId -> coverage; if the appGroupId is not
     * in state, we either haven't requested it yet or did not receive it; otherwise the app group id should have
     * coverage in state;
     * @param state
     * @param action
     * @return {{}|*}
     */
    appGroupRuleCoverage(state = {}, action) {
      switch (action.type) {
        case 'APP_GROUP_LIST_RULE_COVERAGE_SUCCESS':
          // merge new coverage items into state, and ensure that the items with latest 'updated_at' are kept;
          return mergeLatestCoverageWithState(state, action.data);
        case 'APP_GROUP_INSTANCE_RULE_COVERAGE_SUCCESS':
          // merge new coverage item into state if it has the latest 'updated_at';
          return mergeLatestCoverageWithState(state, {[action.id]: action.data});
        default:
          return state;
      }
    },
    /**
     * stores the ui state for app group rule coverage; the state is an object that maps app group ids to their rule
     * coverage ui state; each rule coverage ui state object consists of the properties below.
     * inProgress - the automatically triggered call to load the app group's coverage is in progress
     * hasError - coverage was not returned from the automatically triggered call to load the app group's coverage
     * @param state
     * @param action
     * @return {{}}
     */
    appGroupRuleCoverageUIState(state = {}, action) {
      switch (action.type) {
        case 'APP_GROUP_LIST_RULE_COVERAGE_LOADING':
          action.ids.forEach(id => {
            state[id] = {...state[id], inProgress: true, hasError: false};
          });

          return {...state};
        case 'APP_GROUP_LIST_RULE_COVERAGE_SUCCESS':
          action.ids.forEach(id => {
            state[id] = {...state[id], inProgress: false, hasError: false};
          });

          return {...state};
        case 'APP_GROUP_LIST_RULE_COVERAGE_FAILED':
          action.ids.forEach(id => {
            state[id] = {...state[id], inProgress: false, hasError: true};
          });

          return {...state};
        default:
          return state;
      }
    },
    /**
     * stores the ui state for app group coverage recalculation; the state is an object that maps app group ids to their
     * coverage recalculation ui state; each coverage recalculation ui state object consists of the properties below.
     * inProgress - an on-demand coverage recalculation call is in progress for the app group id
     * hasError - the previous on-demand coverage recalculation call failed for the app group id
     * @param state
     * @param action
     * @return {[id: string]: {inProgress: boolean, hasError: boolean}}
     */
    appGroupRuleCoverageRecalculationUIState(state = {}, action) {
      switch (action.type) {
        case 'APP_GROUP_INSTANCE_RULE_COVERAGE_LOADING':
          return {...state, [action.id]: {...state[action.id], inProgress: true, hasError: false}};
        case 'APP_GROUP_INSTANCE_RULE_COVERAGE_SUCCESS':
          return {...state, [action.id]: {...state[action.id], inProgress: false, hasError: false}};
        case 'APP_GROUP_INSTANCE_RULE_COVERAGE_FAILED':
          return {...state, [action.id]: {...state[action.id], inProgress: false, hasError: true}};
        case 'APP_GROUP_INSTANCE_RULE_COVERAGE_CLEAR_ERROR':
          return {...state, [action.id]: {...state[action.id], hasError: false}};
        default:
          return state;
      }
    },
    appGroupListVulnerabilities(state = {}, action) {
      switch (action.type) {
        case 'APPGROUP_LIST_GET_VULNERABILITIES':
          return action.data;
        default:
          return state;
      }
    },
  }),
};

export const getAppGroups = state => state.appGroup.appGroups;
export const getAppGroupSummary = state => state.appGroup.appGroupSummary;
export const getAppGroupLabels = state => state.appGroup.appGroupLabels;
export const getAppGroupRuleCoverage = state => state.appGroup.appGroupRuleCoverage;
export const getAppGroupListVulnerabilities = state => state.appGroup.appGroupListVulnerabilities;
export const getAppGroupRuleCoverageUIState = state => state.appGroup.appGroupRuleCoverageUIState;
export const getAppGroupRuleCoverageRecalculationUIState = state =>
  state.appGroup.appGroupRuleCoverageRecalculationUIState;

export const isAppGroupsEnabled = createSelector([getAppGroups, isUserScoped], (appGroups, isUserScoped) => {
  return !isUserScoped || Object.keys(appGroups).length;
});

export const getAppGroupCaps = createSelector([getAppGroups, getRouteParams], (appGroups, routeParams) => {
  const id = routeParams.previd || routeParams.id;

  return appGroups.find(appGroup => appGroup.href === id)?.caps || {workloads: [], rule_sets: []};
});

export const isTrafficAvailableForAppGroup = createSelector([getAppGroupCaps], caps => caps.rule_sets.includes('read'));

export const getAppGroupName = createSelector(
  [getAppGroups, getAppGroupLabels, getRouteParams],
  (appGroups, labels, routeParams) => {
    const id = routeParams.previd || routeParams.id;
    const appGroupLabelIds = appGroups.find(appGroup => appGroup.href === id)?.label_ids;

    return appGroupLabelIds && calcAppGroupName(appGroupLabelIds?.map(id => labels[id]?.label));
  },
);

export const getAppGroupParams = createSelector(
  [getAppGroups, getAppGroupLabels, getRouteParams],
  (appGroups, labels, routeParams) => {
    const id = routeParams.previd || routeParams.id;
    const appGroupLabelIds = appGroups.find(appGroup => appGroup.href === id)?.label_ids;
    const appGroupHrefs = appGroupLabelIds?.map(id => labels[id]?.label);

    return appGroupLabelIds && appGroupHrefs;
  },
);

/**
 * selector that returns an object that maps app group ids to their computed coverage data; used to compute the coverage
 * data needed in the coverage column and app group search;
 */
export const getAppGroupsWithRuleCoverage = createSelector([getAppGroupRuleCoverage], appGroupCoverage => {
  return Object.entries(appGroupCoverage).reduce((result, [id, coverageObject]) => {
    const name = calcAppGroupName(coverageObject.labels);
    const {intra_group, inter_group, ip_list, num_workloads} = coverageObject;
    const totalServices =
      (intra_group?.num_services || 0) + (inter_group?.num_services || 0) + (ip_list?.num_services || 0);
    const totalRules = (intra_group?.num_rules || 0) + (inter_group?.num_rules || 0) + (ip_list?.num_rules || 0);
    const coverage = totalServices ? totalRules / totalServices : 0;
    const members = num_workloads;

    result[id] = {name, coverage, members, href: id, totalServices, totalRules};

    return result;
  }, {});
});

/**
 * selector that returns an array of app groups with coverage data sorted by members; used as options in AppGroupSearch;
 */
export const getAppGroupsWithRuleCoverageList = createSelector([getAppGroupsWithRuleCoverage], appGroups => {
  return Object.values(appGroups).sort((a, b) => (a.members < b.members ? 1 : a.members > b.members ? -1 : 0));
});
