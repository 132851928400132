/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {Icon, Spinner} from 'components';
import styles from './Banner.css';
import {typesUtils} from '@illumio-shared/utils';

const spinnerTheme = {spinner: styles['icon-spinner']} as const;
const iconTheme = {icon: styles['icon-icon']} as const;
const iconNameByType = {
  success: 'check',
  warning: 'warning',
  error: 'error',
} as const;

export type BannerProps = ThemeProps & {
  type: 'error' | 'info' | 'plain' | 'plainNoFill' | 'progress' | 'success' | 'warning';
  children?: typesUtils.ReactStrictNode;
  subText?: typesUtils.ReactStrictNode;
  annexItems?: {
    count: number;
    title: typesUtils.ReactStrictNode;
  }[];
  justify?: 'center' | 'start';
};

export default function Banner(props: BannerProps): JSX.Element {
  const {
    type,
    children,
    subText,
    annexItems,
    justify = ['plain', 'plainNoFill'].includes(type) ? 'center' : 'start',
  } = props;
  const theme = composeThemeFromProps(styles, props);
  const withIcon = type === 'progress' || type === 'success' || type === 'error' || type === 'warning';
  const isCentered = justify === 'center';

  /*-------------------------------------------------|centerer|-------------------------------------------------+
  |        |------------------------------------------|banner|---------------------------------------|          |
  |        ||----------------------|main|-----------------------|-|------------|annexes|------------||          |
  |        |||---------------------|core|----------------------||-||----|annex|---|-|---|annex|----|||          |
  |        ||||--icon--|-|--------------|text|----------------|||-||              |-|              |||          |
  |        ||||        |-|                                    |||-||              |-|              |||          |
  |        ||||        |-|                                    |||-||    Rules     |-|     Rules    |||          |
  |        ||||   OK   |-|      Provisioning is complete      |||-||              |-|              |||          |
  |        ||||        |-|                                    |||-||              |-|              |||          |
  |        ||||        |-|                                    |||-||      20      |-|       20     |||          |
  |        ||||--------|-|------------------------------------|||-||              |-|              |||          |
  |        |||-------------------------------------------------||-||              |-|              |||          |
  |        |||---------------------|subText|-------------------||-||              |-|              |||          |
  |        |||                 All is up to date               ||-||              |-|              |||          |
  |        |||-------------------------------------------------||-||--------------|-|--------------|||          |
  |        ||---------------------------------------------------|-|---------------------------------||          |
  |        |-----------------------------------------------------------------------------------------|          |
  +------------------------------------------------------------------------------------------------------------*/
  return (
    <div className={theme.centerer}>
      <div className={cx(theme[type], {[styles.withIcon]: withIcon})}>
        <div className={theme.main}>
          <div className={theme.core}>
            {type === 'progress' && (
              <div className={theme.icon}>
                <Spinner color="dark" theme={spinnerTheme} />
              </div>
            )}
            {(type === 'success' || type === 'warning' || type === 'error') && (
              <div className={theme.icon}>
                <Icon name={iconNameByType[type]} theme={iconTheme} />
              </div>
            )}
            {children ? (
              <div className={cx(theme.text, {[styles.textCentered]: isCentered})} data-tid="comp-banner-text">
                {children}
              </div>
            ) : null}
          </div>
          {subText ? (
            <div className={cx(theme.subText, {[styles.subtextCentered]: isCentered})} data-tid="comp-banner-subtext">
              {subText}
            </div>
          ) : null}
        </div>

        {annexItems && (
          <div className={theme.annexes}>
            {annexItems.map((item, index) => (
              <div key={index} className={theme.annex}>
                <div className={theme.annexTitle}>{item.title}</div>
                <div className={theme.annexCount}>{intl.num(item.count)}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
