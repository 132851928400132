/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import rulesListReducers from './List/LabelRulesListState';
import applyLabelRulesReducers from './List/Apply/ApplyLabelRulesState';
import applyLabelRulesSchedulerReducers from './List/Apply/Scheduler/ApplyLabelRulesSchedulerState';

export default combineReducers({
  ...rulesListReducers,
  ...applyLabelRulesReducers,
  ...applyLabelRulesSchedulerReducers,
});
