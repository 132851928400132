/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useCallback, useState} from 'react';
import FocusLock from 'react-focus-lock';
import {typesUtils} from '@illumio-shared/utils';
import {Gateway} from '..';
import CutoutGateway, {type CutoutGatewayProps} from './CutoutGateway';
import styleUtils from 'utils.css';
import cx from 'classnames';
import {assignRef} from 'use-callback-ref';
import {forwardRefFactory, forwardRefSymbol, type ForwardRefProps} from 'react-forwardref-utils';

export interface CutoutProps
  extends Pick<CutoutGatewayProps, 'offset'>,
    typesUtils.ComponentExternalPropsWithoutRef<'div'>,
    ForwardRefProps<HTMLDivElement> {
  /** If the cutout appears */
  visible?: boolean;

  children: typesUtils.ReactStrictNode;
}

// TODO: ensure only one Cutout is render in the page
function Cutout({
  children,
  visible,
  offset,
  className,
  [forwardRefSymbol]: ref,
  ...wrapperProps
}: CutoutProps): JSX.Element {
  const [elem, setElem] = useState<HTMLElement>();
  const elemRef = useCallback(
    (node: HTMLElement) => {
      if (ref) {
        assignRef(ref, node as HTMLDivElement);
      }

      setElem(node);
    },
    [ref],
  );

  return (
    <>
      {visible && <Gateway offset={offset} into="cutout" elem={elem} />}
      <FocusLock
        ref={elemRef}
        className={cx({[styleUtils.globalSensitive]: visible}, className)}
        disabled={!visible}
        lockProps={wrapperProps}
      >
        {children}
      </FocusLock>
    </>
  );
}

Cutout.Gateway = CutoutGateway;

export default forwardRefFactory(Cutout);
