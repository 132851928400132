/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import * as qs from 'qs';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import PQueue, {AbortError} from 'p-queue';
import {errorUtils} from '@illumio-shared/utils';
import type {AsyncReturnType} from 'type-fest';
import JSONBig from 'json-bigint-keep-object-prototype-methods';

export type FetcherType = 'form' | 'json';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

// Create a queue to make sure we have a consistent number of fetch requests across browsers, to avoid overloading the server
export const standardQueue: PQueue = new PQueue({concurrency: 6});

interface FetcherCommonOptions extends Pick<RequestInit, 'credentials' | 'method' | 'mode' | 'redirect'> {
  url: string;
  headers?: Record<string, string>;
  // query parameters
  query?: Record<string, unknown>;
  strictNullHandling?: boolean;
  timeout?: number;
  parse?: boolean;
  parseBigInt?: boolean;
  ignoreCodes?: number[];
  queue?: PQueue;
  priority?: number;
}

// These two unions ensure that when type is 'json', then data can be any object
// and when type is 'form', data can only be an object with string values
interface FetcherJsonOptions extends FetcherCommonOptions {
  type?: 'json';
  data?: unknown;
}

interface FetcherFormOptions extends FetcherCommonOptions {
  type?: 'form';
  data?: Record<string, string>;
}

interface FetcherTextOptions extends FetcherCommonOptions {
  type?: 'text';
  data?: string;
}

export type FetcherOptions = FetcherFormOptions | FetcherJsonOptions | FetcherTextOptions;

export interface FetchResult {
  response: AsyncReturnType<typeof fetch>;
  data: unknown;
}

export interface FetcherResult {
  promise: Promise<FetchResult>;
  abort(): void;
}

export default function fetcher({
  url, // Target url
  headers = {}, // HTTP headers
  method = 'GET', // HTTP method
  query = {}, // List of key/value for constructing query parameters
  strictNullHandling = false, // Allows for passing of null query parameters
  // To use Discriminated Unions, we can't destruct data and type directly
  //
  // data /* Data to send, will be transformed according to method and type
  //          If method is PUT or POST will be passed as 'body' property to request
  //          If not - will be added to query parameters */,
  // type = 'json', // Data type for PUT and POST methods. If 'json' - data will be stringified, if 'form' - encoded
  redirect = 'follow', // Redirect mode: follow, error, or manual. Can't be polyfilled on top of XHR
  mode = 'same-origin', // Mode of the request: cors, no-cors, cors-with-forced-preflight, same-origin, or navigate
  credentials = 'same-origin' /* Whether the user agent should send cookies from the other domain in the case of cors.
                                  Values: omit, same-origin or include */,
  timeout, // How long to wait for response before promise will be rejected, in ms
  parse = true, // Parse response body using JSON.parse, or just return string as is if false
  parseBigInt = true /* Use JSONBig instead of native JSON to convert big numbers in the json string to BigInt.
                        JSONBig is 3-5x slower than the native JSON, and it blocks the main thread,
                        plus we add up an overhead of parsing text first.
                        If you know that the response doesn't have big numbers and data is big, turn it off! */,
  ignoreCodes = [] /* List of codes that will not produce RequestStatusError and will not parse response body, keeping 'data' undefined
                       If you want response body to be put into the `data` property, pass parse: false */,
  queue = standardQueue, // A queue where the request will be added. A custom one can be passed with another concurrency
  priority = 10 /* Set the priority of the request to balance it inside the queue. Requests with greater priority will be scheduled first.
                   For example, polling can be given a lower priority, while saving (put/post) - higher priority */,
  ...opts
}: FetcherOptions): FetcherResult {
  const controller = new AbortController();
  const requestHeaders = {...headers};

  const promise = queue
    .add(
      async ({signal}): Promise<FetchResult> => {
        const options: RequestInit = {
          mode,
          method,
          redirect,
          credentials,
          signal,
        };

        opts.type ??= 'json';

        if (opts.data) {
          if (method === 'PUT' || (method === 'POST' && opts.data)) {
            // For PUT/POST methods we must pass even empty object as data-binary param
            if (opts.type === 'json') {
              // Send data as JSON
              options.body = JSONBigIntNative.stringify(opts.data);
              requestHeaders['Content-Type'] ??= 'application/json';
            } else if (opts.type === 'form') {
              // This line assumes that when opts.type === 'form', data is an object of string values,
              // so we enforce this in the type using discriminated union.

              // Send data as encoded form (key=value&key=value)
              options.body = _.map(
                opts.data,
                (val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
              ).join('&');

              requestHeaders['Content-Type'] ??= 'application/x-www-form-urlencoded; charset=utf-8';
            } else if (opts.type === 'text') {
              options.body = opts.data;

              requestHeaders['Content-Type'] ??= 'text/plain';
            }
          } else {
            query = {...query, data: opts.data};
          }
        }

        options.headers = new Headers(requestHeaders);

        const queryString = qs.stringify(query, {encode: true, arrayFormat: 'brackets', strictNullHandling});

        if (queryString) {
          url += `?${queryString}`;
        }

        const response = await fetch(url, options);
        let content: unknown;
        let data: unknown;

        if (
          parse &&
          !ignoreCodes.includes(response.status) &&
          response.headers.get('content-type')?.toLowerCase().includes('application/json')
        ) {
          if (parseBigInt) {
            // If we need to parse the json with BigIntNumber, then we need to wait for the body to download first,
            // and then manually parse it with JSONBigIntNative on the next step
            content = await response.text();
          } else {
            // Start immediately parsing as json, off the main thread as we keep downloading the body,
            // which is 3-10x times faster than waiting for download to complete and then parse with JSONBigIntNative
            content = await response.json();
          }
        } else {
          // If no parsing needed, simply wait for the body to finish downloading
          content = await response.text();
        }

        // If we receive text and 'parse: true' - we still want to try parsing the response.
        // For example, 'kvpairs' responds with text/plain, but we store there mostly jsons.
        // Or if we need to parse object containing big numbers, we need to download text first and parse with BigInt.
        // If parsing fails, we'll return text, and will print the suggestion to turn off the 'parse' option
        if (typeof content === 'string' && content.length && parse && !ignoreCodes.includes(response.status)) {
          try {
            data = parseBigInt ? JSONBigIntNative.parse(content) : JSON.parse(content);
          } catch (error) {
            if (__DEV__) {
              console.warn(`Did you mean to pass 'parse: false' to '${url}'?`);
              console.error(
                `Error while manually parsing the response of '${url}' with the following content:\n`,
                content,
                error,
              );
            }

            data = content;
          }
        } else if (content) {
          data = content;
        }

        // Opaque response type means that no-cors mode was set,
        // and it's ok to get the asset despite the server not supporting CORS,
        // however, the response type will be opaque for these responses,
        // meaning you won't be able to examine the response, resulting in no idea of the status or the content of the response
        if (
          response.type !== 'opaque' &&
          !ignoreCodes.includes(response.status) &&
          response.status >= 400 &&
          response.status < 600
        ) {
          throw new errorUtils.RequestStatusError({
            data,
            response,
            request: {url, method, headers: requestHeaders},
            timeout: [408, 460, 504, 524, 598].includes(response.status),
            message: `Cannot ${method} ${url} (${response.status} ${response.statusText})`,
          });
        }

        return {response, data};
      },
      {priority, throwOnTimeout: true, timeout, signal: controller.signal},
    )
    .catch((error: Error) => {
      if (error instanceof errorUtils.RequestStatusError) {
        throw error;
      }

      if (error.name === 'TimeoutError') {
        throw new errorUtils.TimeoutError({timeout, request: {url, method, headers: requestHeaders}});
      }

      if (error.name === 'AbortError' || error instanceof AbortError) {
        throw new errorUtils.RequestAbort({request: {url, method, headers: requestHeaders}});
      }

      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        // 'TypeError. Failed to fetch' means that server can't be reach
        // or (if mode is cors) requested asset is not on the same origin and the server isn't returning the CORS headers
        // (you can overcome cors case by setting the mode of the request to no-cors)
        throw new errorUtils.RequestError({
          noConnection: true,
          request: {url, method, headers: requestHeaders},
          message: intl('Common.ConnectionFailed'),
        });
      }

      throw new errorUtils.RequestError({
        message: error.message,
        request: {url, method, headers: requestHeaders},
      });
    });

  const result = {
    promise,
    abort: () => controller.abort(),
  };

  return result;
}
