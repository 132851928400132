/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserMSPOwner, isUserAdmin} from 'containers/User/UserState';
import listReducers from './List/MyManagedTenantsListState';
import detailReducers from './Item/MyManagedTenantsItemState';

export default {
  mspTenants: combineReducers({
    ...listReducers,
    ...detailReducers,
  }),
};

export const isMyManagedTenantsEnabled = createSelector(
  [isUserMSPOwner, isUserAdmin],
  (userIsMSPOwner, userIsAdmin) => __MSP__ && (userIsMSPOwner || userIsAdmin),
);
