/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import type {ComponentPropsWithoutRef} from 'react';
import styles from './Menu.css';

// Pick classnames for current component from styles object
const delimiterStyles = {
  delimiter: styles.delimiter,
};

export type MenuDelimiterProps = ComponentPropsWithoutRef<'hr'> & ThemeProps;

export default function MenuDelimiter(props: MenuDelimiterProps): JSX.Element {
  const {theme, ...rest} = mixThemeWithProps(delimiterStyles, props);

  const hrprops: ComponentPropsWithoutRef<'hr'> = rest;

  hrprops.className = theme.delimiter;

  return <hr {...hrprops} />;
}
