/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle} from 'react';
import {Input} from 'components';
import cx from 'classnames';
import styles from './LabelRulesListEditExpression.css';
import {
  getContainerInputPlaceholderText,
  isExpressionValueSelected,
  getResourceStatics,
} from './LabelRulesListEditExpressionUtils';
import _ from 'lodash';
import {KEY_DOWN, KEY_ESCAPE, KEY_RETURN, KEY_TAB, KEY_UP} from 'keycode-js';
import {domUtils} from '@illumio-shared/utils';

const LabelRulesListEditExpressionContainer = forwardRef(function LabelRulesListEditExpressionContainer(props, ref) {
  const {title, attribute, operator, validate, onDone, close, selectorValues} = props;
  const showExpressionInput = Boolean(attribute && operator);
  const placeHolderText = getContainerInputPlaceholderText({attribute, operator});

  const [{error, errorMessage}, setErrorState] = useState({error: false, errorMessage: ''});
  const [inputValue, setInputValue] = useState('');
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1);

  const inputRef = useRef(null);

  const options = useMemo(
    () =>
      inputValue
        ? getResourceStatics({query: inputValue, attribute, operator}).filter(
            option => !isExpressionValueSelected({value: option.value, values: selectorValues}),
          )
        : [],
    [attribute, operator, selectorValues, inputValue],
  );

  const handleValueChange = useCallback(
    event => {
      setInputValue(event.target.value);
    },
    [setInputValue],
  );

  const handleOptionClick = useCallback(
    (event, option) => {
      onDone(event, option);
      setInputValue('');
    },
    [onDone, setInputValue],
  );

  const handleOptionMouseEnter = useCallback((event, index) => {
    setHighlightedOptionIndex(index);
  }, []);

  const handleOptionMouseLeave = useCallback(() => {
    setHighlightedOptionIndex(-1);
  }, []);

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_TAB && highlightedOptionIndex > -1) {
        domUtils.preventEvent(event);
        handleOptionClick(event, options[highlightedOptionIndex]);

        return;
      }

      // return key -> select the highlighted item
      if (event.keyCode === KEY_RETURN && highlightedOptionIndex > -1) {
        handleOptionClick(event, options[highlightedOptionIndex]);

        return;
      }

      // down arrow -> move the highlighted option down (index + 1)
      if (event.keyCode === KEY_DOWN) {
        setHighlightedOptionIndex(Math.min(highlightedOptionIndex + 1, options.length - 1));

        return;
      }

      // up arrow -> move the highlighted option up (index - 1)
      if (event.keyCode === KEY_UP) {
        setHighlightedOptionIndex(Math.max(highlightedOptionIndex - 1, -1));

        return;
      }

      if (event.keyCode === KEY_ESCAPE) {
        close();

        return;
      }
    },
    [handleOptionClick, options, highlightedOptionIndex, close],
  );

  const focusInput = useCallback(() => {
    inputRef.current?.input?.current?.focus();
  }, [inputRef]);

  useEffect(() => {
    try {
      validate({query: inputValue, operator});
      setErrorState({error: false, errorMessage: ''});
    } catch (error) {
      setErrorState({error: true, errorMessage: error.message});
    }
  }, [attribute, operator, inputValue, validate]);

  useEffect(() => {
    const input = inputRef.current?.input.current;

    if (input) {
      input?.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      input?.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputRef, handleKeyDown]);

  // reset the highlighted index when the options change
  useEffect(() => {
    if (options.length > 0) {
      setHighlightedOptionIndex(0);
    } else {
      setHighlightedOptionIndex(-1);
    }
  }, [options]);

  // clear input value and focus input when attribute and/or operator changes; this happens in a few cases:
  // 1. when selector first opens
  // 2. when the user clicks a pill
  // 3. when user selects a new attribute/operator
  useEffect(() => {
    setInputValue('');

    const timeout = setTimeout(() => {
      inputRef.current?.input?.current?.focus();
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [attribute, operator]);

  useImperativeHandle(
    ref,
    () => {
      return {
        setInputValue,
        focusInput,
      };
    },
    [focusInput],
  );

  const expressionValueInput = useMemo(() => {
    return (
      <div className={styles.containerContent}>
        <Input
          theme={styles}
          themePrefix="containerInput-"
          ref={inputRef}
          tid="expressionInputValue"
          name="expressionInputValue"
          value={inputValue}
          onChange={handleValueChange}
          placeholder={placeHolderText}
          error={error}
          errorMessage={errorMessage}
        />

        {!error && options.length ? (
          <div className={styles.options}>
            {options.map((option, index) => (
              <div
                data-tid={`expression-value-option-${option.value}`}
                key={index}
                className={cx(styles.option, styles.containerOption, {
                  [styles.containerHighlightedOption]: highlightedOptionIndex === index,
                })}
                onClick={_.partial(handleOptionClick, _, option)}
                onMouseEnter={_.partial(handleOptionMouseEnter, _, index)}
                onMouseLeave={_.partial(handleOptionMouseLeave, _, index)}
              >
                {option.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }, [
    inputValue,
    handleValueChange,
    placeHolderText,
    error,
    errorMessage,
    options,
    handleOptionClick,
    handleOptionMouseEnter,
    handleOptionMouseLeave,
    highlightedOptionIndex,
  ]);

  return (
    <div className={styles.containerContainer}>
      <div className={styles.containerInfoPanel}>{title}</div>
      {showExpressionInput && expressionValueInput}
    </div>
  );
});

export default LabelRulesListEditExpressionContainer;
