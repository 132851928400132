/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import PropTypes from 'prop-types';
import styles from './LabelRulesListViewLabels.css';
import {Pill} from 'components';

LabelRulesListViewLabels.propTypes = {
  labels: PropTypes.array,
};

export default function LabelRulesListViewLabels({labels}) {
  return (
    <div data-tid="rule-labels" className={styles.container}>
      {(labels ?? []).map(label => (
        <Pill.Label key={label.href} noContextualMenu type={label.key}>
          {label.value}
        </Pill.Label>
      ))}
    </div>
  );
}
