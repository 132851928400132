/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {useContext} from 'react';
import * as PropTypes from 'prop-types';
import {AppContext} from 'containers/App/AppUtils';
import {ModalMachine, Notifications, TypedMessages} from 'components';
import {reactUtils} from '@illumio-shared/utils';
import intl from '@illumio-shared/utils/intl';

ModalMachineAuto.propTypes = {
  modalProps: PropTypes.object,
  // Boolean props to define how saga will be processed:
  // settled - every API call will be wrapped in Promise.allSettled function
  // batch - expect one API call, but there are multiple objects to process in the response
  settled: PropTypes.bool,
  batch: PropTypes.bool,
  ...reactUtils.mutuallyExclusiveTruePropsSpread('batch', 'settled'),

  // This function will be called when modal is in close state
  onClose: PropTypes.func.isRequired,

  // Saga function in order to process items within modal
  saga: PropTypes.func.isRequired,
  // items to process within modal operation
  hrefs: PropTypes.any,
  // post 'process' state function, usually re-fetching or navigating is done over here
  onDone: PropTypes.any,

  // if operation is done on the list page, we will grab necessary information from grid for display
  rowsMap: PropTypes.object,
  listItem: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),

  initialContext: PropTypes.object,

  // Modal.Content props are getting passed only for open and submitting states
  contentProps: PropTypes.object,

  // passing true for this prop when using batch or settled props, allows us to ignore renderItem function
  skipListRendering: PropTypes.bool,

  // all items over here are our en.js string keys, e.g. 'Common.Workload'
  // or it can be a function that will pass context from xstate
  children: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    confirmMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    submitProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    cancelProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    customFooter: PropTypes.func,
    formProps: PropTypes.object,
    renderItem: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.func)]),
    error: PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      itemSuccessMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      customErrorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      closeProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }),
  }),
};

const defaultButtonProps = {
  cancelProps: {text: intl('Common.Cancel'), tid: 'cancel', noFill: true},
  submitProps: {text: intl('Common.Remove'), tid: 'remove', progressCompleteWithCheckmark: true},
  closeProps: {text: intl('Common.Close'), tid: 'close', progressError: true, progressCompleteWithCheckmark: true},
};

function ModalMachineAuto(props) {
  const {
    hrefs,
    settled,
    batch,
    saga,
    onClose,
    onDone,
    children,
    listItem,
    rowsMap,
    initialContext,
    payload,
    renderItem: customRenderItem,
    contentProps,
    skipListRendering,
    ...mmProps
  } = props;
  const {fetcher} = useContext(AppContext);

  let config;
  let process;

  if (settled) {
    config = defaultConfig => _.merge(defaultConfig, ModalMachine.errorProcessors.settledErrors);
    process = ({hrefs, args}) => Promise.allSettled(hrefs.map(href => fetcher.spawn(saga, {href, ...args})));
  } else if (batch) {
    config = defaultConfig => _.merge(defaultConfig, ModalMachine.errorProcessors.batchErrors);
    process = ({hrefs, args}) => fetcher.spawn(saga, {hrefs, ...args});
  } else if (payload) {
    process = () => {
      if (saga.constructor.name === 'GeneratorFunction') {
        return fetcher.spawn(saga, hrefs, payload);
      }

      return saga();
    };
  } else {
    process = () => {
      if (saga.constructor.name === 'GeneratorFunction') {
        return fetcher.spawn(saga, hrefs);
      }

      return saga();
    };
  }

  return (
    <ModalMachine
      customConfig={config}
      onClose={onClose}
      services={{
        process,
        onProcessDone: onDone ?? Promise.resolve(),
      }}
      initialContext={{
        ...initialContext,
        errorsHrefs: [],
        errorsMap: new Map(),
        hrefs,
        rowsMapCopy: new Map(rowsMap),
      }}
      {...mmProps}
    >
      {({matches, context, toStrings}) => {
        const {cancelProps, closeProps, submitProps, customFooter, error: {customErrorMessage} = {}} = children;
        const customMessage =
          typeof customErrorMessage === 'function'
            ? customErrorMessage
            : (token, message) =>
                typeof customErrorMessage?.[token] === 'function'
                  ? customErrorMessage[token](context)
                  : customErrorMessage?.[token] ?? message ?? intl('Error.Unknown');
        const customSubmitProps = typeof submitProps === 'function' ? submitProps(context) : submitProps;

        const footer = customFooter ?? [
          {
            type: 'CANCEL',
            buttonProps: {...defaultButtonProps.cancelProps, ...cancelProps},
          },
          {
            type: 'SUBMIT',
            buttonProps: {
              ...defaultButtonProps.submitProps,
              onProgressDone: context.onProgressDone,
              progress: matches('modal.submitting.progress'),
              progressError: context.noConnection,
              ...customSubmitProps,
            },
          },
        ];

        const errorFooter = [
          {
            type: 'CLOSE',
            buttonProps: {
              ...defaultButtonProps.closeProps,
              ...closeProps,
            },
          },
        ];

        const noInternetNotification = context.notifications.length > 0 && (
          <Notifications>{context.notifications}</Notifications>
        );

        if (!settled && !batch) {
          if (matches('modal.open') || matches('modal.submitting')) {
            const {title, confirmMessage, notifications, formProps} = children;

            return {
              formProps,
              header: {props: {title: typeof title === 'function' ? title(context) : title}},
              content: {
                props: contentProps,
                children: (send, options) => (
                  <>
                    {noInternetNotification}
                    {notifications && <Notifications>{notifications}</Notifications>}
                    {typeof confirmMessage === 'function' ? confirmMessage(context, send, options) : confirmMessage}
                  </>
                ),
              },
              footer,
            };
          }

          if (matches('modal.error')) {
            const {error: {title, notifications} = {}} = children;
            const {errors} = context;

            let errorMessages;

            if (typeof customErrorMessage === 'function') {
              errorMessages = customErrorMessage(context);
            } else if (errors) {
              // customerMessage() will handle all customErrorMessage objects or undefined customErrorMessage
              errorMessages = (
                <TypedMessages key="errors">
                  {[
                    {
                      icon: 'error',
                      content: customMessage(errors.token, errors.message),
                    },
                  ]}
                </TypedMessages>
              );
            }

            return {
              header: {props: {title: title ?? children.title}},
              content: {
                children: (
                  <>
                    {noInternetNotification}
                    {notifications && <Notifications>{notifications}</Notifications>}
                    {errorMessages}
                  </>
                ),
              },
              footer: errorFooter,
            };
          }
        }

        const {selectedHrefs, rowsMapCopy} = context;

        const renderItem = key => {
          const {cells} = rowsMapCopy.get(key);

          return (
            <li key={key}>
              {intl.list(
                listItem.map(item =>
                  Array.isArray(item) ? item[1](cells.get(item[0])?.value) : cells.get(item)?.value,
                ),
                {style: 'narrow', type: 'unit'},
              )}
            </li>
          );
        };

        const renderListItems = hrefs => {
          if (_.isObject(customRenderItem)) {
            let customRenderItemFunc = null;

            if (typeof customRenderItem === 'function') {
              customRenderItemFunc = customRenderItem;
            } else {
              // state.value from xState is a nested object for nested states
              // state.toStrings() returns a list of accessor strings of the nested states
              for (const stateString of toStrings().reverse()) {
                if (stateString.startsWith('modal')) {
                  // remove the 'modal.' prefix
                  const modalStateString = stateString.substr(6);

                  if (modalStateString in customRenderItem) {
                    customRenderItemFunc = customRenderItem[modalStateString];
                    break;
                  }
                }
              }
            }

            return customRenderItemFunc && <ol>{Array.from(hrefs, _.partial(customRenderItemFunc, context))}</ol>;
          }

          return <ol>{Array.from(hrefs, renderItem)}</ol>;
        };

        if (matches('modal.open') || matches('modal.submitting')) {
          const {title, confirmMessage, notifications, formProps} = children;

          return {
            formProps,
            header: {props: {title: typeof title === 'function' ? title(context) : title}},
            content: {
              props: contentProps,
              children: (send, options) => (
                <>
                  {noInternetNotification}
                  {notifications && <Notifications>{notifications}</Notifications>}
                  {typeof confirmMessage === 'function' ? confirmMessage(context, send, options) : confirmMessage}
                  {!skipListRendering && renderListItems(selectedHrefs)}
                </>
              ),
            },
            footer,
          };
        }

        if (matches('modal.error')) {
          const {statusCode, errorsMap, errorsHrefs, selectedHrefs} = context;
          const {error: {title, itemSuccessMessage} = {}} = children;

          const header = {
            props: {
              title:
                (typeof title === 'function' ? title(context) : title) ??
                (typeof children.title === 'function' ? children.title(context) : children.title),
            },
          };

          if (statusCode === 500) {
            return {
              header,
              content: {
                children: (
                  <>
                    {noInternetNotification}
                    <TypedMessages>
                      {[{icon: 'error', content: intl('EventUtils.RequestInternalServer')}]}
                    </TypedMessages>
                  </>
                ),
              },
              footer: errorFooter,
            };
          }

          if (__DEV__ && !errorsHrefs.length) {
            throw new Error('No error hrefs found. Please add hrefs: [href] to the yield apiSaga call');
          }

          const successHrefs = _.difference(selectedHrefs, errorsHrefs);

          let successMessages = successHrefs.length > 0 && (
            <TypedMessages key="success">
              {[
                {
                  icon: 'success',
                  content: (
                    <>
                      {itemSuccessMessage({successHrefs})}:{renderListItems(successHrefs)}
                    </>
                  ),
                },
              ]}
            </TypedMessages>
          );

          const errorMessages = errorsMap && (
            <TypedMessages key="errors">
              {Array.from(errorsMap.entries(), ([token, messageWithHrefs]) => {
                let message;
                let hrefs;

                if (typeof messageWithHrefs === 'object') {
                  ({message, hrefs} = messageWithHrefs);
                } else {
                  message = messageWithHrefs;
                  successMessages = [];
                }

                return {
                  icon: 'error',
                  content: (
                    <>
                      {customMessage(token, message)}:{renderListItems(hrefs)}
                    </>
                  ),
                };
              })}
            </TypedMessages>
          );

          return {
            header,
            content: {
              children: (
                <>
                  {noInternetNotification}
                  {errorMessages}
                  {successMessages}
                </>
              ),
            },
            footer: errorFooter,
          };
        }
      }}
    </ModalMachine>
  );
}

export default ModalMachineAuto;
