/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {getAllUsersMap, isSuperclusterMember} from 'containers/User/UserState';
import {getGridSelector, getUrlFilterParam} from 'components/Grid/GridSelectors';
import {gridSettings} from './PendingListConfig';
import {
  formatCountsForTally,
  getProvisionMenuCounts,
  getProvisionCountsTotal,
  getTypeAndRoute,
  calculatedItemTypeStaticValues,
} from 'containers/Provisioning/ProvisioningUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {isKubernetesSupported} from 'containers/App/AppState';
import {hrefUtils} from '@illumio-shared/utils';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import intl from '@illumio-shared/utils/intl';

export default {
  // List of objects waiting to be provisioned by user
  pending(state = {}, {type, data}) {
    switch (type) {
      case 'PROVISION_GET_PENDING':
        return data ?? state;
      default:
        return state;
    }
  },
  pendingCount(state = 0, {type, count}) {
    switch (type) {
      case 'PROVISION_GET_PENDING':
        return count ?? state;
      default:
        return state;
    }
  },
  pendingOutboundAllowRuleset(state = null, action) {
    switch (action.type) {
      case 'OUTBOUND_POLICY_ALLOWRULESET':
        return action.data;
      default:
        return state;
    }
  },
};

export const getPending = state => state.provisioning.pending;
export const getPendingCount = state => state.provisioning.pendingCount;
export const getOutboundAllowRulesetId = state => hrefUtils.getId(state.provisioning.pendingOutboundAllowRuleset?.href);
export const getOutboundAllowRulesetHref = state => state.provisioning.pendingOutboundAllowRuleset?.href;

export const getCounts = createSelector([getPending, getOutboundAllowRulesetId], (items, outboundAllowRulesetId) => {
  return getProvisionMenuCounts(items, outboundAllowRulesetId);
});

export const getTotalCount = createSelector([getCounts], counts => {
  if (__ANTMAN__) {
    counts = _.omit(counts, ['virtual_servers', ...(isKubernetesSupported ? [] : ['virtual_services'])]);
  }

  return getProvisionCountsTotal(counts);
});

export const getGridSettings = createSelector(
  [isSuperclusterMember, gridSettings],
  (superclusterMember, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.checkboxes.disabled = superclusterMember;

    return {...gridSettings, columns};
  },
);

export const categories = createSelector(getCounts, counts => {
  if (__ANTMAN__) {
    counts = _.omit(counts, ['virtual_servers', ...(isKubernetesSupported ? [] : ['virtual_services'])]);
  }

  return [
    {
      id: 'type',
      name: intl('Provision.Item'),
      resources: {
        type: {
          statics: calculatedItemTypeStaticValues(counts),
        },
      },
    },
  ];
});

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

const getFilters = state => getUrlFilterParam(state, {settings: getGridSettings, filterMap: getFilterMap});

export const getSecPolicyRows = createSelector(
  [getPending, getAllUsersMap, getOutboundAllowRulesetId],
  (pending, usersMap, outboundAllowRulesetId) => {
    let secPolicyRows = [];

    const pendingEntries = Object.entries(pending).reduce((result, [objType, items]) => {
      if (Array.isArray(items)) {
        for (const item of items) {
          if (objType === 'rule_sets' && hrefUtils.getId(item.href) === outboundAllowRulesetId) {
            continue;
          }

          result.push({
            key: item.href,
            selectable: item.caps?.includes('provision'),
            data: {
              ...item,
              type: objType,
              ...getTypeAndRoute(objType),
              updated_by: fillUserInfo(usersMap, item.updated_by),
            },
          });
        }
      } else if (objType === 'firewall_settings') {
        result.push({
          key: items.href,
          selectable: items.caps?.includes('provision'),
          data: {
            ...items,
            type: objType,
            ...getTypeAndRoute(objType),
            updated_by: fillUserInfo(usersMap, items.updated_by),
          },
        });
      }

      return result;
    }, []);

    secPolicyRows = secPolicyRows.concat(pendingEntries);

    return secPolicyRows;
  },
);

export const getPendingProvisioningRows = createSelector([getSecPolicyRows, getFilters], (rows, {valid: filter}) => {
  let result;

  if (filter.type?.length > 0) {
    // currently we only support the type filter
    result = rows.filter(row => row.data.caps && row.data.typeLabel === filter.type[0]);
  } else if (__ANTMAN__) {
    result = rows.filter(
      row =>
        (row.data.type !== 'virtual_servers' && row.data.caps) ||
        (isKubernetesSupported && row.data.type === 'virtual_services'),
    );
  } else {
    result = rows.filter(row => row.data.caps);
  }

  return result;
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getPendingProvisioningRows,
    filterMap: getFilterMap,
  });

export const getPendingProvisioningPage = createSelector(
  [getGrid, getCounts, categories],
  (grid, counts, categories) => {
    if (__ANTMAN__) {
      counts = _.omit(counts, ['virtual_servers', ...(isKubernetesSupported ? [] : ['virtual_services'])]);
    }

    const tallyItems = formatCountsForTally(counts);
    const total = getProvisionCountsTotal(counts);

    return {grid, tallyItems, total, categories};
  },
);
