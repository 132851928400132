/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';
import {Selector} from 'containers';
import styles from './LabelRulesListEditLabels.css';
import {useCallback, useContext, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from '@illumio-shared/utils/redux';
import {getLabelsHrefMap} from '../../../../List/LabelListState';
import {fetchLabelInstance} from '../../../../LabelSaga';
import {getTypeInitialRegExp} from '../../../../LabelSettings/LabelSettingState';
import {hrefUtils} from '@illumio-shared/utils';
import {AppContext} from 'containers/App/AppUtils';

LabelRulesListEditLabels.propTypes = {
  labels: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default function LabelRulesListEditLabels({labels, onChange}) {
  const {fetcher} = useContext(AppContext);

  const labelsHrefMap = useSelector(getLabelsHrefMap);
  const labelTypeInitialRegExp = useSelector(getTypeInitialRegExp);

  const [selectorOpen, setSelectorOpen] = useState(false);

  const labelValues = useMemo(() => new Map([['labelsAndLabelGroups', labels ?? []]]), [labels]);

  const labelsCategories = useMemo(() => {
    return [
      Selector.categoryPresets.labelsAndLabelGroups({
        allowMultipleSelection: false,
        hasTypeList: true,
        hasLabelGroups: false,
        labelTypeInitialRegExp,
      }),
    ];
  }, [labelTypeInitialRegExp]);

  const handleLabelsOnChange = useCallback(
    values => {
      const labels = values.get('labelsAndLabelGroups') ?? [];

      labels.forEach(label => {
        // fetch the label if it does not exist in redux
        if (label.href && !labelsHrefMap[label.href]) {
          const labelId = hrefUtils.getId(label.href);

          fetcher.spawn(fetchLabelInstance, {id: labelId, force: true});
        }
      });

      onChange(labels);
    },
    [onChange, labelsHrefMap, fetcher],
  );

  const handleSelectorOpen = useCallback(() => {
    setSelectorOpen(true);
  }, []);

  const handleSelectorClose = useCallback(() => {
    setSelectorOpen(false);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.labelsWrapper}>
        <Selector
          tid="rule-editor-labels"
          theme={styles}
          themePrefix="labelsSelector-"
          categories={labelsCategories}
          placeholder={labels?.length ? '' : intl('LabelRules.AssignLabel')}
          hideClearAll={!selectorOpen}
          hideCategoryPanel
          values={labelValues}
          onSelectionChange={handleLabelsOnChange}
          onOpen={handleSelectorOpen}
          onClose={handleSelectorClose}
        />
      </div>
    </div>
  );
}
