/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import * as PropTypes from 'prop-types';
import {whenPropTypes, useWhen} from '../FormUtils';
import ColorPicker from './ColorPicker';

FormColorPicker.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  ...whenPropTypes,
};

export function FormColorPicker(props) {
  const {
    [forwardRefSymbol]: ref,
    form,
    field,
    onBlur,
    onChange,
    ...colorPickerProps
  } = useWhen(props.form, props.field, props);

  const {name, value} = field;
  const {errors, touched, handleChange, handleBlur} = form;
  // An error border will be displayed by default if the name for this input appears as a key in the errors object.
  const error = _.get(touched, name) === true && _.has(errors, name);
  // An error message will be displayed if the value for this inputs key is not undefined.
  const errorMessage = error && _.get(errors, name);

  colorPickerProps.name = name;
  colorPickerProps.value = value;
  colorPickerProps.ref = ref;
  colorPickerProps.onChange = onChange || handleChange;
  colorPickerProps.onBlur = onBlur || handleBlur;
  colorPickerProps.error = error;

  if (errorMessage) {
    colorPickerProps.errorMessage = errorMessage;
  }

  return <ColorPicker {...colorPickerProps} type="color" />;
}

export default forwardRefFactory(props => <Field {...props} component={FormColorPicker} />, {
  hoistSource: FormColorPicker,
});
