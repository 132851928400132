/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import ExpandableScopeList, {type ExpandableScopeListProps} from './ExpandableScopeList';

const shortDefaults = {
  initialCount: 8,
  initialScopeCount: 1,
  showInModalFrom: 25,
};

// Policy Object view pages with overwritten default props
export default function ExpandableLabelListShort<T>(
  props: Omit<ExpandableScopeListProps<T>, 'initialCount' | 'initialScopeCount' | 'showInModalFrom'>,
): JSX.Element {
  return <ExpandableScopeList {...props} {...shortDefaults} />;
}
