/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {useCallback, useRef} from 'react';
import {Resizable} from 're-resizable';
import {Button, Gateway, type IconName} from 'components';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './SidePanel.css';
import styleUtils from 'utils.css';
import cx from 'classnames';
import {reactUtils} from '@illumio-shared/utils';

type SidePanelProps = ThemeProps & {
  panelWidth?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  open?: boolean;
  onClickOutside?: (event?: MouseEvent | TouchEvent) => void;
  headerIconName?: IconName;
  noHeader?: boolean;
  title?: string;
  subtitle?: string;
};

const SidePanel = (props: SidePanelProps): JSX.Element | null => {
  const {theme} = mixThemeWithProps(styles, props);
  const {onClose, onClickOutside, panelWidth = '75%', children, noHeader, title, subtitle, open} = props;

  const panelWidthPixel = panelWidth.includes('%')
    ? (window.innerWidth * parseFloat(panelWidth)) / 100
    : parseFloat(panelWidth);

  const panelRef = useRef(null);

  reactUtils.useOnClickOutside(panelRef, event => {
    if (onClickOutside) {
      // parent can determine if the click should close the panel
      onClickOutside(event);

      return;
    }

    onClose?.();
  });

  const handleCloseClicked = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Gateway into="sidepanel">
      <div style={{display: open ? 'contents' : 'none'}} ref={panelRef}>
        <Resizable
          maxWidth="100%"
          data-tid="side-panel"
          className={theme.sidePanel}
          defaultSize={{
            width: panelWidthPixel,
            height: 'auto',
          }}
          boundsByDirection
          enable={{
            topLeft: false,
            top: false,
            topRight: false,
            right: false,
            bottomRight: false,
            bottom: false,
            bottomLeft: false,
            left: true,
          }}
        >
          <div className={theme.sidePanelContent}>
            {!noHeader && (
              <div className={theme.sidePanelHeader}>
                <div className={cx(theme.sidePanelHeaderMainContent, styleUtils.gapHorizontal, styleUtils.gapLarge)}>
                  <div>
                    <div className={theme.sidePanelHeaderTitle}>{title || intl('Common.NA')}</div>
                    <div className={theme.sidePanelHeaderSubtitle}>{subtitle}</div>
                  </div>
                </div>
                <Button onClick={handleCloseClicked} color="standard" noFill theme={theme} icon="close" size="small" />
              </div>
            )}
            {children}
          </div>
        </Resizable>
      </div>
    </Gateway>
  );
};

export default SidePanel;
