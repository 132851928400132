/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Icon, Link} from 'components';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import cx from 'classnames';
import styles from './HorizontalTrain.css';

export type TrainProps = ThemeProps & {
  noIcon?: boolean;
  disabled?: boolean;

  /** indexed like an array for simpler implementation and consumption of the component */
  currentStep: number;

  /**  how many steps you want */
  numSteps: number;

  /** must equal the length of numSteps. If no text supplied, 'Step 1', 'Step 2' etc. will be rendered */
  text?: (string | {text: string; linkTo: string})[];

  color?: 'primary' | 'secondary';
};

// Inspecting the train in the browser will be helpful in understanding the grid layout
export default function HorizontalTrain(props: TrainProps): JSX.Element {
  const {
    numSteps,
    currentStep,
    text,
    disabled = false,
    noIcon = false,
    theme,
    color = 'primary',
  } = mixThemeWithProps(styles, props);

  const renderTrain = () => {
    // first and last cell in the first row of the grid will be empty.
    const numCells = numSteps * 2 - 2;

    return Array.from({length: numCells}, (_, i) => {
      const isEnabled = !disabled && currentStep * 2 >= i; // enable or disable a line/circle in the train
      const showCheck = currentStep * 2 > i; // 2 cells per circle
      const showFinalCheck = currentStep === numSteps; // current step is zero based indexing, while numsteps is a natural number

      // in regards to the grid column computation - the first cell is empty (+1) and grid isn't 0 based like arrays (+1)
      return (
        <div key={i} className={theme.cell} data-tid="horizontal-train-cell" style={{gridColumn: i + 2}}>
          <div
            className={theme[isEnabled ? `${color}Line` : 'disabledLine']}
            data-tid={`line-${isEnabled ? 'enabled' : 'disabled'}`}
          />
          {
            // every second cell in the grid will have a circle in the beginning of the cell
            i % 2 === 0 && (
              <div
                className={theme[isEnabled ? `${color}Circle` : 'disabledCircle']}
                data-tid={`circle-${isEnabled ? 'enabled' : 'disabled'}`}
              >
                {!noIcon && isEnabled && showCheck && <Icon name="check" theme={theme} themePrefix="train-" />}
              </div>
            )
          }
          {
            // the last circle in the train has to be appended to the end of the cell
            i === numCells - 1 && (
              <div
                className={cx(theme[isEnabled ? `${color}Circle` : 'disabledCircle'], theme.lastCircle)}
                data-tid={`circle-${isEnabled ? 'enabled' : 'disabled'}`}
              >
                {
                  // only add check to the last circle if user completed the last step
                  !noIcon && showFinalCheck && <Icon name="check" theme={theme} themePrefix="train-" />
                }
              </div>
            )
          }
        </div>
      );
    });
  };

  const renderText = () => {
    // no text, no problem. It'll get generated for you - 'Step 1', 'Step 2'...
    if (!text?.length) {
      return Array.from({length: numSteps}, (_, i) => (
        <div key={i} className={theme[disabled || currentStep < i ? 'disabledText' : `${color}Text`]}>
          {intl('Common.Step', {step: i + 1})}
        </div>
      ));
    }

    if (__DEV__ && text.length !== numSteps) {
      console.error(
        `The 'text' array contains ${text.length} strings, which is not equal to the number of steps (${numSteps}).`,
      );
    }

    return text.map((element, i) => (
      <div
        key={i}
        className={theme[disabled || currentStep < i ? 'disabledText' : `${color}Text`]}
        data-tid={`elem-${(typeof element === 'string' ? element : element.text).toLowerCase().replace(' ', '-')}`}
      >
        {typeof element === 'string' ? (
          element
        ) : (
          <Link theme={theme} themePrefix={`${color}-`} to={element.linkTo} disabled={disabled || currentStep < i}>
            {element.text}
          </Link>
        )}
      </div>
    ));
  };

  return (
    <div
      className={theme.wrapper}
      data-tid="horizontal-train-wrapper"
      style={{gridTemplateColumns: `repeat(${numSteps * 2}, 1fr)`}}
    >
      {renderTrain()}
      {renderText()}
    </div>
  );
}
