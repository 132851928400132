/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {errorUtils} from '@illumio-shared/utils';
import {all, delay, call, select, take, put, spawn, fork} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getDependencies, getSelection} from './ProvisionState';
import {checkCalcProgress, fetchPending} from '../ProvisioningSaga';
import {isSuperclusterMember} from 'containers/User/UserState';
import apiSaga, {apiCachedResponses} from 'api/apiSaga';
import {getProvisionSelection} from '../ProvisioningUtils';
import {getPendingProvisioningRows} from 'containers/Provisioning/Pending/List/PendingListState';
import {selectedGridSettings, dependencyGridSettings} from './ProvisionConfig';
import gridSaga from 'components/Grid/GridSaga';
import {fetchPendingObjectList} from 'containers/Provisioning/Pending/List/PendingListSaga';

let pendingOverrideTimer;

export function* fetchDependencies({pversion = 'draft', data, force = false} = {}) {
  yield call(apiSaga, 'sec_policy.dependencies', {
    params: {pversion},
    data,
    cache: !force,
    *onDone({data}) {
      if (data !== (yield select(getDependencies))) {
        yield put({type: 'PROVISION_GET_DEPENDENCIES', data});
      }
    },
  });
}

export function* removeSecPolicies({changeSubset}) {
  yield call(apiSaga, 'sec_policies.delete', {
    data: {
      change_subset: changeSubset,
    },
    *onDone() {
      apiCachedResponses.clearAll();

      yield call(fetchPending);
      yield put({type: 'PROVISION_CHECK_CALC_PROGRESS'});
    },
  });
}

export function* fetchProvisionList(route, refetch = false) {
  // Check if session has selections
  let selection = getProvisionSelection();

  if (_.isEmpty(selection)) {
    selection = yield select(getSelection);
  } else {
    yield put({type: 'PROVISION_SELECTION', data: {selection}});
    refetch = true;
  }

  if (_.isEmpty(selection)) {
    throw new errorUtils.RedirectError({to: 'pending', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(fetchPendingObjectList, {priority: 0});
  yield call(gridSaga, {route, settings: selectedGridSettings});

  yield call(gridSaga, {route, settings: dependencyGridSettings});

  yield all([call(fetchDependencies, {data: selection, force: refetch}), call(fetchAllUsers, {force: refetch})]);
}

export function* removeSelection() {
  while (true) {
    const {href, objType} = yield take('REMOVE_SELECTION');
    const selection = yield select(getSelection);
    const newSelection = _.cloneDeep(selection);
    //If type is outbound then selection is a part of rule_sets change_subset
    const changeSubsetType = objType;

    newSelection.change_subset[changeSubsetType] = newSelection.change_subset[changeSubsetType]?.filter(
      object => object.href !== href,
    );

    if (!newSelection.change_subset[changeSubsetType]?.length) {
      delete newSelection.change_subset[changeSubsetType];
    }

    yield call(fetchProvision, {selection: newSelection});
  }
}

export function* fetchProvision({selection}) {
  yield fork(fetchPending);
  yield fork(fetchDependencies, {data: selection, force: true});
  yield fork(fetchAllUsers);

  yield put({type: 'PROVISION_SELECTION', data: {selection: {...selection}}});
}

export function* createSecPolicies({items, note}) {
  const changeSubset = {};

  items.forEach(row => {
    // If a selected row type is outbound then it may include any or both a draft rule_set or draft enforcement_boundaries
    // row.data of this row includes the rule_sets and enforcement_boundaries keys if they are pending changes
    // Only add rule_set to changeSubset when row.data contains rule_sets key
    const rowType = row.data.type;

    changeSubset[rowType] ??= [];
    changeSubset[rowType].push({href: row.key});
  });

  yield call(apiSaga, 'sec_policies.create', {
    data: {
      update_description: note,
      change_subset: changeSubset,
    },
    *onDone({data}) {
      apiCachedResponses.clearAll();

      const workloadsAffected = _.get(data, 'workloads_affected');

      if (workloadsAffected === 0 || !(yield call(checkCalcProgress, {force: true}))) {
        // Handle cases where workloads affected are non zero and API returns rule calc completed
        yield put({
          type: 'PROVISION_COMPLETED',
          data: {isComplete: true, showProvisionProgressPopup: true, workloadsAffected},
        });
      } else {
        // Set store content to force display closed provision progress popup
        yield put({type: 'PROVISION_COMPLETED', data: {showProvisionProgressPopup: true}});
        yield put({type: 'PROVISION_CHECK_CALC_PROGRESS'});

        const isSuperClusterMember = yield select(isSuperclusterMember);
        // In Supercluster setups, Provision.inProgress (GET rule_calc_progress)
        // sometimes will return empty red_agents because of latency in-between
        // the replication. It can take up to 30 seconds for the correct
        // red_agents array to be returned, in between then, we still want to
        // show the Provisioning Progress Bar. For the count in the Provisioning
        // Progress Bar, we just use the workloadsAffected count from above

        if (isSuperClusterMember) {
          yield put({type: 'PROVISION_CALC_PROGRESS_OVERRIDE', data: true});
          yield put({type: 'PROVISION_CALC_PROGRESS_OVERRIDE_COUNT', data: workloadsAffected});

          // Clear the timer if one already exists
          if (pendingOverrideTimer) {
            pendingOverrideTimer.cancel();
          }

          // Set Pending Override to false after 30 seconds
          // If by this time the red_agents are empty in GET rule_calc_progress,
          // it means everything is up to date
          pendingOverrideTimer = spawn(function* () {
            yield delay(30_000);
            yield put({type: 'PROVISION_CALC_PROGRESS_OVERRIDE', data: false});
          });
        }
      }

      yield call(fetchPending);
    },
  });
}

export function* revertSecPolicies({items}) {
  const changeSubset = {};

  items.forEach(row => {
    const rowType = row.data.type;

    changeSubset[rowType] ??= [];
    changeSubset[rowType].push({href: row.key});
  });

  yield call(removeSecPolicies, {changeSubset});
}

export function* createAllSecPolicies(note) {
  const items = yield select(getPendingProvisioningRows);

  yield call(createSecPolicies, {items, note});
}
