/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';

import {isUserReadOnly, isUserGlobalObjectProvisioner, isUserRulesetProvisioner} from 'containers/User/UserState';
import {getRoute, getRouteParams} from 'containers/App/AppState';

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'SECURE_GATEWAYS_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getSecureGatewayInstance = state => state.securegateways.instance;
export const getSecureGatewayVersions = createSelector(
  [getSecureGatewayInstance, getRouteParams],
  (instance, {pversion}) => getPolicyVersions(instance, pversion),
);

export const getSecureGatewayItem = createSelector(
  [getSecureGatewayVersions, getRoute, isUserReadOnly, isUserGlobalObjectProvisioner, isUserRulesetProvisioner],
  (
    {versions, isOldVersion, pversionObjIsDeleted},
    route,
    userIsReadOnly,
    userIsGlobalObjectProvisioner,
    userIsRulesetProvisioner,
  ) => {
    const {pversion = 'draft'} = route.params;
    const provisional =
      (!userIsReadOnly || userIsGlobalObjectProvisioner || userIsRulesetProvisioner) &&
      Boolean(versions.draft && versions.draft.update_type);

    return {route, provisional, pversion, versions, isOldVersion, pversionObjIsDeleted};
  },
);
