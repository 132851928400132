/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import he from 'he';
import {memoize} from '@formatjs/fast-memoize'; // This is just a fork of fast-memoize with esm support
import {parse as parseICUMessageFormat} from '@formatjs/icu-messageformat-parser';
import {IntlMessageFormat, type PrimitiveType, type FormatXMLElementFn} from 'intl-messageformat';
import {createElement, Fragment, type ReactElement} from 'react';
import {locale, lang} from './locale';
import config from 'config';
import formats from './formats';
import langs, {type LangKeys} from './langs';
import * as utils from './utils';

const messages = langs(config)[lang];

// Memoize native Intl constructors for the same arguments
const getListFormat = memoize((locale, options) => new Intl.ListFormat(locale, options));
const getNumberFormat = memoize((locale, options) => new Intl.NumberFormat(locale, options));
const getDateTimeFormat = memoize((locale, options) => new Intl.DateTimeFormat(locale, options));
const getRelativeFormat = memoize((locale, options) => new Intl.RelativeTimeFormat(locale, options));
const getPluralRules = memoize((locale, options) => new Intl.PluralRules(locale, options));

// Parse a message into AST manually to be able to specify extra options, like requiresOtherClause.
// And memoize it separately to speed up getMessageFormat even more for the same message with different params
// https://formatjs.io/docs/intl-messageformat#passing-in-ast
// Options: https://github.com/formatjs/formatjs/blob/main/packages/icu-messageformat-parser/parser.ts#L36
const parseICU = memoize((message: string, ignoreTag: boolean) =>
  parseICUMessageFormat(message, {
    // Treat HTML/XML tags as string literal instead of parsing them as tag token.
    // We will handle them separately in the formatMessage method below, with 'html' option
    ignoreTag,
    // Should `select`, `selectordinal`, and `plural` arguments always include the `other` case clause.
    requiresOtherClause: false,
    // Whether to parse number/datetime skeleton into Intl.NumberFormatOptions and Intl.DateTimeFormatOptions, respectively.
    // https://formatjs.io/docs/intl-messageformat#datetimenumber-skeleton
    shouldParseSkeletons: true,
    // Capture location info in AST
    captureLocation: false,
  }),
);
// Memoize IntlMessageFormat with the memoized formatters
// Do not use rest and default parameters in memoize https://github.com/caiogondim/fast-memoize.js#gotchas
const getMessageFormat = memoize(
  (message: string, ignoreTag: boolean) =>
    new IntlMessageFormat(parseICU(message, ignoreTag), locale, formats, {
      // Provide memoized instances of the Intl objects inside IntlMessageFormat as well, for speed
      // https://formatjs.io/docs/intl-messageformat#formatters
      formatters: {getNumberFormat, getDateTimeFormat, getPluralRules},
    }),
);

type FormatType = 'date' | 'list' | 'number';

const LIST = 'list';
const DATE = 'date';
const NUMBER = 'number';

function getOptionsFromFormats(type: string, value: number | string, format?: string | unknown) {
  let options = format;

  if (typeof format === 'string') {
    try {
      options = (formats[type] as Record<string, unknown>)[format];
    } catch {
      options = undefined;
    }

    if (options === undefined) {
      throw new ReferenceError(`No '${format}' found in intl/formats.js for the '${type}' type (value ${value})`);
    }
  }

  return options;
}

// Main format function
function format(
  type: 'list',
  value: Parameters<Intl.ListFormat['format']>[0],
  optionsOrFormat?: Intl.ListFormatOptions | string,
): string;
function format(
  type: 'date',
  value: Parameters<Intl.DateTimeFormat['format']>[0],
  optionsOrFormat?: Intl.DateTimeFormatOptions | string,
): string;
function format(
  type: 'number',
  value: Parameters<Intl.NumberFormat['format']>[0],
  optionsOrFormat?: Intl.NumberFormatOptions | string,
): string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function format(type: FormatType, value: any, optionsOrFormat: any): string {
  const options = getOptionsFromFormats(type, value, optionsOrFormat);

  switch (type) {
    case DATE:
      return getDateTimeFormat(locale, options as Intl.DateTimeFormatOptions).format(value);
    case NUMBER:
      return getNumberFormat(locale, options as Intl.NumberFormatOptions).format(value);
    case LIST:
      return getListFormat(locale, options as Intl.ListFormatOptions).format(value);
    default:
      throw new Error(`Unrecognized format type: ${type}. Value: ${value}`);
  }
}

function formatDate(date: utils.LooserDate, options?: Intl.DateTimeFormatOptions | string) {
  const dateInstance = typeof date === 'object' ? date : new Date(date);

  try {
    return format(DATE, dateInstance, options);
  } catch (error) {
    throw new Error(`A valid date/string/timestamp must be provided to formatDate(). Now it's: ${date}`, {
      cause: error,
    });
  }
}

// Returns relative string with specified unit using Intl.RelativeTimeFormat
function formatRelative(
  num: number,
  unit: Intl.RelativeTimeFormatUnitSingular,
  optionsOrFormat?: Intl.RelativeTimeFormatOptions | string,
) {
  if (__DEV__ && typeof num !== NUMBER) {
    throw new TypeError(`A number must be provided to formatRelative(). Now it is: ${num}`);
  }

  const options = getOptionsFromFormats('relative', num, optionsOrFormat) as Intl.RelativeTimeFormatOptions;

  return getRelativeFormat(locale, options).format(num, unit);
}

// Returns relative string with automatically found unit
// For example, '4 days ago', 'tomorrow', 'in one minute'
function formatRelativeBestFit(
  to: utils.LooserDate,
  options?: Intl.RelativeTimeFormatOptions,
  customThresholds?: Parameters<typeof utils.selectRelativeTimeUnit>[2],
) {
  const {unit, value} = utils.selectRelativeTimeUnit(to, Date.now(), customThresholds);

  return formatRelative(value, unit, {numeric: 'auto', ...options});
}

function formatNumber(num: number, options?: Intl.NumberFormatOptions | string) {
  if (typeof num !== NUMBER) {
    throw new TypeError(`A number must be provided to formatNumber(). Now it: ${num}`);
  }

  return format(NUMBER, num, options);
}

function formatList(array: Parameters<Intl.ListFormat['format']>[0], options?: Intl.ListFormatOptions | string) {
  if (!Array.isArray(array)) {
    throw new TypeError(`An array must be provided to formatList(). Now it is: ${array}`);
  }

  return format(LIST, array, options);
}

type KeyMapper = (key: string) => string;
type ValueMapper = (value: string) => string;

// Optional mapper function that takes key as an argument and can return another key. By default, returns the same key
let intlKeyMapper: KeyMapper = key => key;
// Setter to change default intlKeyMapper, for example, can be called in case of Edge in its index.js
export const setIntlKeyMapper = (mapper: KeyMapper): KeyMapper => (intlKeyMapper = mapper);

let intlValueMapper: ValueMapper = value => value;
// Setter to change default intlValueMapper, for example, can be called in case of Edge in its index.js
export const setIntlValueMapper = (mapper: ValueMapper): ValueMapper => (intlValueMapper = mapper);

// Wrap a key into intlKey call to count it by plugin-transform-intl-inline. Key gets inlined (unwrapped from intlKey call).
// Useful in conjunction with intlKeyMapper, to map original key with the desired one without calling real intl().
// For example, {'Common.Workload': intlKey('Edge.Endpoint')} transformed to {'Common.Workload': 'Edge.Endpoint'}
export const intlKey: KeyMapper = key => key;

interface FormatMessageOptions {
  html?: boolean;
  htmlProps?: Record<string, unknown>;
}

// FIXME: instead of inline export default, this is a workaround.
// see https://github.com/import-js/eslint-plugin-import/issues/1590
export default formatMessage;

/**
 * Returns string, format it by ICU with intl-messageformat if params passed
 * @param key - Language key to find message in language bundle
 * @param params - ICU params
 * @param options - Options:
 * @param options.html - Message contains html tags which we want to render, so we must use a wrapper with dangerouslySetInnerHTML.
 *                       It's useful when you have a simple markup in your string,
 *                       and for some reason you don't want to parse tag parameters.
 *                       For example, if a string contains '<br>' and you want to render a line break automatically.
 *                       Confirm.Remove: 'Want to <b>remove</b> this item, {user}?'
 *                       intl('Confirm.Remove', {user: 'John'}, {html: true})
 *                       The result will be:
 *                       <span dangerouslySetInnerHTML: {__html: 'Want to <b>remove</b> this item, John?'}/>
 *
 *                       There are two problems with using html tags in messages:
 *                       1. We need to wrap a message into extra span, which might affect the layout/styling
 *                       2. We use dangerouslySetInnerHTML, that means we need to escape all the parameters
 *                          using a third-party library, like 'he', which creates its own overhead.
 *
 *                       It's better to use parsed tags:
 *                       Common.Yes: 'Want to <b>remove</b> this item, {user}?'
 *                       intl('Confirm.Remove', {user: 'John', b: ([text]) => <strong>{text}</strong>})
 *                       The result will be:
 *                       <>Want to <strong>remove</strong> this item, John?</>
 *                       The benefit is that: we fully control the final presentation of the tag on the component side
 *                       and can apply any dynamic attribute we want, keeping [lang].js simple
 * @param options.htmlProps - Props that will be passed to the span wrapper with dangerouslySetInnerHTML
 */
function formatMessage(key: LangKeys, params?: Record<string, PrimitiveType> | null): string;
function formatMessage(
  key: LangKeys,
  params: Record<string, PrimitiveType> | null | undefined,
  options: FormatMessageOptions & {html: true},
): ReactElement;
function formatMessage(
  key: LangKeys,
  params?: Record<
    string,
    FormatXMLElementFn<string, PrimitiveType | ReactElement> | PrimitiveType | ReactElement
  > | null,
  options?: FormatMessageOptions & {html?: false},
): ReactElement | string;
function formatMessage(
  key: LangKeys,
  params?: Record<
    string,
    FormatXMLElementFn<string, PrimitiveType | ReactElement> | PrimitiveType | ReactElement
  > | null,
  {html = false, htmlProps}: FormatMessageOptions = {},
): ReactElement | string {
  const mappedKey = intlKeyMapper(key) as LangKeys;
  let message = messages[mappedKey];

  if (!message) {
    // Warn about keys that are not found in language bundle
    // Release task fails if it doesn't find some keys in bundle (plugin-transform-intl-inline)
    // This line exists in dev only, and is eliminated in release task
    if (__DEV__) {
      console.error('Intl not found key:', key);
    }

    return key;
  }

  if (params) {
    if (html) {
      params = Object.entries(params).reduce((result: Exclude<typeof params, null | undefined>, [name, value]) => {
        // need to encode(value) for cross-site scripting vulnerability
        // e.g. value = '<img src onerror=alert(1)'> convert to '&#x3C;img src=z onerror=alert(1)&#x3E';
        result[name] = typeof value === 'string' ? he.encode(value) : value;

        return result;
      }, {});
    }

    message = getMessageFormat(message, html).format(params);

    if (Array.isArray(message)) {
      // If the message contains react elements, then IntlMessageFormat will return an array of strings and objects.
      // Create Fragment and spread that array over it to avoid the need to specify a React key for each item
      // Fallback to span while we support react 14 in legacy
      return createElement(Fragment || 'span', {}, ...message);
    }
  }

  // Map message substrings _after_ it was formatted, to make sure we don't replace parameter names and other meta symbols
  message = intlValueMapper(message);

  return html ? createElement('span', {dangerouslySetInnerHTML: {__html: message}, ...htmlProps}) : message;
}

formatMessage.lang = lang;
formatMessage.locale = locale;

formatMessage.utils = utils;
formatMessage.format = format;
formatMessage.formats = formats;
formatMessage.list = formatList;
formatMessage.date = formatDate;
formatMessage.num = formatNumber;
formatMessage.rel = formatRelative;
formatMessage.relBestFit = formatRelativeBestFit;
