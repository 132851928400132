/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';

export function parseVulnerabilities(vulnerabilities) {
  let aggregatedValues;

  const summary =
    vulnerabilities.aggregated_detected_vulnerability_summary ?? vulnerabilities.detected_vulnerability_summary ?? {};
  const instances = (
    vulnerabilities.aggregated_detected_vulnerabilities ??
    vulnerabilities.workload_detected_vulnerabilities ??
    []
  ).reduce((result, vulnerability) => {
    const key = [vulnerability.port, vulnerability.proto].join(',');
    const severity = vulnerability.vulnerability.score / 10;
    const vulnerablePortExposure = vulnerability.hasOwnProperty('vulnerable_port_exposure')
      ? vulnerability.vulnerable_port_exposure
      : vulnerability.port_exposure;
    const wideExposure = vulnerability.vulnerable_port_wide_exposure || vulnerability.port_wide_exposure;
    let vulnerabilityExposureScore = null;

    if (!vulnerability.hasOwnProperty('vulnerability_exposure_score')) {
      vulnerabilityExposureScore =
        vulnerablePortExposure === null
          ? null
          : (Math.floor(Math.sqrt(vulnerablePortExposure) * (Math.pow(severity, 3) / 10)) / 10) *
            (vulnerability.num_workloads || 1);
    } else if (vulnerability.vulnerability_exposure_score !== null) {
      vulnerabilityExposureScore = vulnerability.vulnerability_exposure_score / 10;
    }

    const instance = {
      port: vulnerability.port,
      protocol: vulnerability.proto,
      severity,
      wideExposure,
      numWorkloads: vulnerability.num_workloads,
      vulnerablePortExposure,
      vulnerabilityScore: severity * (vulnerability.num_workloads || 1),
      vulnerabilityExposureScore,
      vulnerabilityComputationState: summary?.vulnerability_computation_state ?? null,
      details: vulnerability.vulnerability,
    };

    aggregatedValues = aggregateVulnerabilityValues(aggregatedValues, instance, summary, key);

    result[key] = (result[key] || []).concat([instance]);

    return result;
  }, {});

  return {aggregatedValues, instances};
}

export function aggregateVulnerabilityValues(values, instance, summary) {
  const aggregatedValues = values || {
    maxSeverity: 0,
    maxExpSeverity: -1,
    wideExposure: {},
    vulnerablePortExposure: null,
    vulnerabilityScore: 0,
    vulnerabilityExposureScore: null,
    maxVulnerabilityExposure: -1,
    portValue: false,
    vulnerabilityComputationState: summary?.vulnerability_computation_state ?? null,
  };

  let {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    maxVulnerabilityExposure,
    portValue,
    vulnerabilityComputationState,
  } = aggregatedValues;

  if (instance.wideExposure && (instance.wideExposure.any || instance.wideExposure.ip_list)) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );

    wideExposure = {
      any: wideExposure.any || instance.wideExposure.any,
      ip_list: wideExposure.ip_list || instance.wideExposure.ip_list,
    };
  }

  maxSeverity = Math.max(maxSeverity, instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity);
  maxVulnerabilityExposure = Math.max(vulnerabilityExposureScore, instance.vulnerabilityExposureScore);

  if (instance.vulnerablePortExposure) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );
  }

  // Aggregate the vulnerability and exposure scores
  if (instance.vulnerablePortExposure !== null && instance.vulnerablePortExposure !== intl('Common.NA')) {
    if (vulnerablePortExposure === null || vulnerablePortExposure === intl('Common.NA')) {
      vulnerablePortExposure = 0;
      vulnerabilityExposureScore = 0;
    }

    vulnerablePortExposure += instance.vulnerablePortExposure;
    vulnerabilityExposureScore += instance.vulnerabilityExposureScore;
  }

  if (instance.vulnerablePortExposure === intl('Common.NA') && vulnerablePortExposure === null) {
    vulnerablePortExposure = intl('Common.NA');
    vulnerabilityExposureScore = intl('Common.NA');
  }

  vulnerabilityScore += instance.vulnerabilityScore;

  portValue |= instance.port !== null;

  return {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    maxVulnerabilityExposure,
    portValue,
    vulnerabilityComputationState,
  };
}

export function calculateComboLinksWithVulnerabilities(comboLinks, detectedVulnerabilities) {
  return Object.entries(comboLinks).reduce((result, [comboLinkKey, comboLink]) => {
    result[comboLinkKey] = {
      ...comboLink,
      vulnerabilities: {instances: []},
    };

    const vulnerabilities = detectedVulnerabilities[comboLink.data.id2];

    if (vulnerabilities) {
      const summary = {
        vulnerability_computation_state: vulnerabilities?.aggregatedValues?.vulnerabilityComputationState ?? null,
      };

      Object.values(result[comboLinkKey].services).forEach(service => {
        const serviceKey = [service.port, service.protocolNum].join(',');
        const instances = vulnerabilities.instances[serviceKey];

        if (instances) {
          instances.forEach(instance => {
            result[comboLinkKey].vulnerabilities.aggregatedValues = aggregateVulnerabilityValues(
              result[comboLinkKey].vulnerabilities.aggregatedValues,
              instance,
              summary,
            );
            result[comboLinkKey].vulnerabilities.instances = (
              result[comboLinkKey].vulnerabilities.instances || []
            ).concat([instance]);
          });
        }
      });
    }

    return result;
  }, {});
}
