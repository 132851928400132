/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import styles from './LabelRulesListViewExpression.css';
import PropTypes from 'prop-types';
import LabelRulesListExpressionPills from '../../Common/LabelRulesListExpressionPills';

LabelRulesListViewExpression.propTypes = {
  expression: PropTypes.object,
};

export default function LabelRulesListViewExpression({expression}) {
  return (
    <div className={styles.container}>
      <div className={styles.expressionWrapper}>
        <LabelRulesListExpressionPills expression={expression} truncateValues />
      </div>
    </div>
  );
}
