/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {PureComponent} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './Popups.css';
import {typesUtils} from '@illumio-shared/utils';

type PopupsProps = ThemeProps & {
  children: typesUtils.ReactStrictNode[];
};

export default class Popups extends PureComponent<PopupsProps> {
  render() {
    const theme = composeThemeFromProps(styles, this.props);

    return (
      <div className={theme.container}>
        {this.props.children.map((popup, idx) => (
          <div key={idx}>{popup}</div>
        ))}
      </div>
    );
  }
}
