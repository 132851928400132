/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import {useEffect, useLayoutEffect, useRef} from 'react';

export const resetFields = (form, names, force = false) => {
  let reset = false;

  if (!Array.isArray(names)) {
    names = [names];
  }

  for (const name of names) {
    if (force || form.values[name] !== form.initialValues[name]) {
      reset = true;
      form.setFieldValue(name, form.initialValues[name]);
    }

    if (force || form.touched[name]) {
      reset = true;
      form.setFieldTouched(name, Array.isArray(form.initialValues[name]) ? [] : false);
    }
  }

  return reset;
};

export const emptyFields = (form, names, force = false) => {
  let emptied = false;

  if (!Array.isArray(names)) {
    names = [names];
  }

  for (const name of names) {
    const initialValue = form.initialValues[name];
    let newValue;

    if (typeof initialValue === 'string') {
      newValue = '';
    } else if (typeof initialValue === 'boolean') {
      newValue = false;
    } else if (Array.isArray(initialValue)) {
      newValue = [];
    } else if (typeof initialValue === 'object') {
      newValue = null;
    }

    if (force || !_.isEqual(newValue, form.values[name])) {
      emptied = true;
      form.setFieldValue(name, newValue);
      form.setFieldTouched(name, Array.isArray(initialValue) ? [] : true);
    }
  }

  return emptied;
};

export const whenPropTypes = {
  // If current form element should be active only when other form field value(s) meet some condition,
  // then those conditions can be specified in `when` (similar to yup's `when`) prop as object, like:
  // when={{checkboxIsBig: true, checkboxColorGroup: ['red', 'green']}}
  // or as function, like:
  // when={(name, value, form) => form.checkboxIsBig && ['red', 'green'].every(val => form.checkboxColorGroup.includes(val))}
  // Both examples here are equivalent, object is a list of other form field names with expected values,
  // whilst function can contain custom logic of any complexity and expected to return boolean.
  // When result of `when` changes from true to false,
  // then the corresponding form value of current form element will be reset to the initial one and touch will be set to false.
  when: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // Specify action that should be done:
  // 1. Result of `when` changes from false to true
  whenBecomesTrue: PropTypes.oneOfType([PropTypes.oneOf(['noop', 'initial', 'empty']), PropTypes.func]),
  // 2. Result of `when` changes from true to false
  whenBecomesFalse: PropTypes.oneOfType([PropTypes.oneOf(['noop', 'initial', 'empty']), PropTypes.func]),
};

export const useWhen = (form, field, props) => {
  const {when, whenBecomesTrue = 'noop', whenBecomesFalse = 'initial', ...restProps} = props;
  const {name, value} = field;
  const whenType = typeof when;
  const whenIsObject = whenType === 'object';
  const whenIsFunction = whenType === 'function';
  const whenIsProvided = whenIsObject || whenIsFunction;
  let newActive;

  if (whenIsFunction) {
    // If `when` is a function, just call it expecting to get activity boolean
    newActive = when(name, value, form);
  } else if (whenIsObject) {
    // If `when` is an object of dependencies, iterate over it and check that every name/value matches current form values
    newActive = Object.entries(when).every(([name, value]) => {
      const currentFormValue = form.values[name];

      if (Array.isArray(currentFormValue)) {
        if (Array.isArray(value)) {
          return value.every(val => currentFormValue.includes(val));
        }

        return currentFormValue.includes(value);
      }

      if (Array.isArray(value)) {
        return value.includes(currentFormValue);
      }

      return value === currentFormValue;
    });
  } else {
    // If `when` is not specified, that consider it as active
    newActive = true;
  }

  const whenIsProvidedRef = useRef(whenIsProvided);
  const activeRef = useRef(newActive);
  const formRef = useRef(form);

  whenIsProvidedRef.current = whenIsProvided;
  formRef.current = form;

  // Right after render (synchronously) check if activity changed, and update activeRef.current
  useLayoutEffect(() => {
    if (newActive !== activeRef.current) {
      // And if it was set to 'false' from 'true' then call resetFields, which will check if value/touched have really changed,
      let action = newActive ? whenBecomesTrue : whenBecomesFalse;

      if (typeof action === 'function') {
        action = action(form, field);
      }

      if (action === 'initial') {
        resetFields(formRef.current, name);
      } else if (action === 'empty') {
        emptyFields(formRef.current, name);
      }

      activeRef.current = newActive;
    }
  });

  // On unmount reset field back to initial and not touched if there is `when` property
  useEffect(
    () => () => {
      if (whenIsProvidedRef.current) {
        // Have to reset without acually comparing dependecy values,
        // because on unmount we don't have the latest form.values - it's stale from the previous render
        resetFields(formRef.current, name);
      }
    },
    [name],
  ); // Empty dependecies to run only on mount(noop) and unmount

  return restProps;
};

export function checkWhenFieldIsActive(currentlyActive, when, form, name) {
  const value = form.values[name];
  let isActive;

  if (typeof when === 'function') {
    isActive = when(name, value, form);
  } else if (typeof when === 'object') {
    isActive = Object.entries(when).every(([name, value]) => {
      const currentFormValue = form.values[name];

      if (Array.isArray(currentFormValue)) {
        if (Array.isArray(value)) {
          return value.every(val => currentFormValue.includes(val));
        }

        return currentFormValue.includes(value);
      }

      if (Array.isArray(value)) {
        return value.includes(currentFormValue);
      }

      return value === currentFormValue;
    });
  } else {
    return null;
  }

  if (currentlyActive && !isActive) {
    resetFields(form, name);
  }

  return isActive;
}

export const getValidationFunctionFromSchema = (objectSchema, fieldName, optionName) => {
  const tests =
    objectSchema && objectSchema.fields && objectSchema.fields[fieldName] && objectSchema.fields[fieldName].tests;

  if (tests && tests.length) {
    return tests.find(item => item.OPTIONS.name === optionName);
  }
};

export const isRequiredField = (objectSchema, fieldName, formValues) => {
  let required = getValidationFunctionFromSchema(objectSchema, fieldName, 'required') !== undefined;

  if (
    !required &&
    _.get(objectSchema, `fields.${fieldName}._conditions`) &&
    // eslint-disable-next-line no-underscore-dangle
    objectSchema.fields[fieldName]._conditions.length
  ) {
    try {
      objectSchema.validateSyncAt(fieldName, {...formValues, [fieldName]: undefined});
    } catch (error) {
      if (error.name === 'ValidationError' && error.type === 'required') {
        required = true;
      }
    }
  }

  return required;
};

/**
 * Get the first found item then return null when object not found to indicate empty object.
 *
 * @param {Array<Objects>} options
 * @param {string} item
 * @returns {any}
 */
export const findSelectedOption = (options = [], item) => options.find(option => option.value === item) || null;

export const getGridRowErrorPath = path => `${path}.rowError`;

export const getSelectionsFromEntries = (valuesEntries = []) => valuesEntries[0]?.[1];
