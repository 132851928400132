/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import type {LangKeys} from 'intl/langs';
import {type ReactNode, type KeyboardEvent, useCallback, useState, useMemo, useEffect} from 'react';
import {Modal} from 'components';
import styles from './ExpandableList.css';
import {mixThemeWithProps} from '@css-modules-theme/react';
import {domUtils, typesUtils} from '@illumio-shared/utils';

export interface ExpandableListProps<Value> {
  /** Number of values visible at first */
  initialCount?: number;

  /** Number of groups visible at first, if 'values' is an array of arrays (groups) */
  initialGroupCount?: number;

  /** If the total number of values is equal or more, they will be expanded in a modal */
  showInModalFrom?: number;

  /** Title of the modal */
  modalTitle?: string;

  /** Intl key to show in collapse link */
  showLessText?: LangKeys;

  /** Intl key or a function to show in expand link */
  showMoreText?: LangKeys | ((options: {valuesLeft: number; groupsLeft: number; groupValuesLeft: number}) => string);

  /** Array of all the values, or array of groups of all values (array of arrays) */
  values: Value[] | Value[][];

  theme?: Record<string, string>;
  children?: (item: Value, index: number, arr?: Value[] | Value[][]) => ReactNode;
  renderGroup?: (items: ReactNode[], index: number, arr?: Value[][]) => ReactNode;
  onToggleMore?: (state: boolean) => void;
  onToggleModal?: (state: boolean) => void;
}

export default function ExpandableList<T>(props: ExpandableListProps<T>): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const {
    theme,
    modalTitle,
    values = [],
    initialCount = 3,
    initialGroupCount = 1,
    showInModalFrom = 25,
    showMoreText = 'Common.CountMore',
    showLessText = 'Common.ShowLess',
    onToggleMore,
    onToggleModal,
    children,
    renderGroup = (items: ReactNode[]) => items,
  } = mixThemeWithProps(styles, props);

  const totalCountOfValues = useMemo(() => {
    // @ts-ignore
    return values.reduce((count: number, item: T | T[]) => count + (Array.isArray(item) ? item.length : 1), 0);
  }, [values]);
  const expandInModal = totalCountOfValues >= showInModalFrom;
  const isGrouped = Array.isArray(values[0]);

  const handleToggle = useCallback(() => {
    setExpanded(!expanded);

    if (onToggleModal && totalCountOfValues >= showInModalFrom) {
      onToggleModal(!expanded);
    }
  }, [onToggleModal, showInModalFrom, totalCountOfValues, expanded]);

  const handleMoreClick = useCallback(
    (event: domUtils.MouseEventLike) => {
      event.stopPropagation();
      handleToggle();
    },
    [handleToggle],
  );

  const handleMoreKeyUp = useCallback(
    (evt: KeyboardEvent) => {
      // Emulate click on Space and Enter
      if (evt.key === ' ' || evt.key === 'Enter') {
        handleToggle();
      }
    },
    [handleToggle],
  );

  const [inlineValues, modalValues, groupValuesLeft] = useMemo(() => {
    let inlineValues: ReactNode[] = [];
    const modalValues: ReactNode[] = [];
    let groupValuesLeft = 0;

    if (totalCountOfValues < initialCount && !children) {
      inlineValues = values as typesUtils.ReactStrictNode[];
    } else {
      let counter = 0;

      for (let v = 0; v < values.length; v++) {
        const value = values[v];
        const isGroup = Array.isArray(value);

        // no need to populate arrays without expanded clicked
        if (
          !expanded &&
          ((isGroup && v >= initialGroupCount) ||
            (v >= initialGroupCount && counter + (isGroup ? value.length : 1) > initialCount))
        ) {
          break;
        }

        if (isGroup) {
          const inlineGroupValues: ReactNode[] = [];
          const modalGroupValues: ReactNode[] = [];

          for (let i = 0; i < value.length; i++) {
            const groupValue = value[i];
            const item = children ? children(groupValue, i, value) : (groupValue as typesUtils.ReactStrictNode[]);

            counter++;

            if (expanded && !expandInModal) {
              inlineGroupValues.push(item);
            } else if (counter <= initialCount && v < initialGroupCount) {
              inlineGroupValues.push(item);

              if (counter === initialCount && value.length > i + 1) {
                groupValuesLeft = value.length - i - 1;
              }
            }

            if (!expanded && counter >= initialCount) {
              break;
            }

            if (expanded && expandInModal) {
              modalGroupValues.push(item);
            }
          }

          if (inlineGroupValues.length) {
            inlineValues.push(renderGroup(inlineGroupValues, v, values as T[][]));
          }

          if (modalGroupValues.length) {
            modalValues.push(renderGroup(modalGroupValues, v, values as T[][]));
          }
        } else {
          const item = children ? children(value, v, values) : (value as typesUtils.ReactStrictNode[]);

          counter++;

          if (counter <= initialCount || (expanded && !expandInModal)) {
            inlineValues.push(item);
          }

          if (!expanded && counter >= initialCount) {
            break;
          }

          if (expanded && expandInModal) {
            modalValues.push(item);
          }
        }
      }
    }

    return [inlineValues, modalValues, groupValuesLeft];
  }, [values, totalCountOfValues, initialCount, initialGroupCount, expanded, expandInModal, children, renderGroup]);

  useEffect(() => {
    if (onToggleMore) {
      onToggleMore(expanded);
    }
  }, [expanded, onToggleMore]);

  return (
    <div
      className={
        cx(isGrouped ? theme.listOfGroups : theme.list, {
          /* Add a class that indicates showing more than one group. Can be replaced with :has() #FirefoxHAS */
          [theme.multipleGroups]: isGrouped && inlineValues.length > 1,
        }) || undefined
      }
    >
      {inlineValues}

      {expanded && expandInModal && (
        <Modal.Alert key="modal" title={modalTitle} onClose={handleToggle}>
          <div
            className={cx(isGrouped ? theme.listOfGroups : theme.list, {
              [theme.multipleGroups]: isGrouped && modalValues.length > 1,
            })}
          >
            {modalValues}
          </div>
        </Modal.Alert>
      )}

      {(totalCountOfValues > initialCount || (isGrouped && values.length > initialGroupCount)) && (
        <div
          key="more"
          onClick={handleMoreClick}
          onKeyUp={handleMoreKeyUp}
          className={theme.more}
          tabIndex={0}
          data-tid={`expandable-list-${expanded ? 'less' : 'more'}`}
        >
          {expanded && !expandInModal
            ? intl(showLessText)
            : typeof showMoreText === 'function'
            ? showMoreText({
                valuesLeft: totalCountOfValues - initialCount,
                groupsLeft: isGrouped ? values.length - inlineValues.length : 0,
                groupValuesLeft,
              })
            : intl(showMoreText, {count: totalCountOfValues - initialCount})}
        </div>
      )}
    </div>
  );
}
