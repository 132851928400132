/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {Field} from 'formik';
import * as PropTypes from 'prop-types';
import {createContext, useContext} from 'react';
import {forwardRefSymbol, forwardRefFactory} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from '../FormUtils';
import Checkbox from './Checkbox';

FormCheckbox.Context = createContext(null);
FormCheckbox.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,
  // By default checkbox state and form value are automatically handled by FormCheckbox itself (uncontrolled).
  // If you want to intercept change event and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,
  // Might depend on other fields
  ...whenPropTypes,
};

export function FormCheckbox(props) {
  const contextProps = useContext(FormCheckbox.Context);

  // Control field reset when dependecies are not true
  const {[forwardRefSymbol]: ref, onChange, form, field, ...checkboxProps} = useWhen(props.form, props.field, props);
  const {name} = field;

  // Pass name from field to Checkbox
  checkboxProps.name = name;
  // Pass original ref from parent to Checkbox
  checkboxProps.ref = ref;

  if (contextProps && contextProps.name === name) {
    const {values, ...propsFromContext} = contextProps;

    if (__DEV__) {
      if (!checkboxProps.value) {
        throw new Error(
          'Grouped FormCheckbox should have `name` and `value` prop and be wrapped into FormCheckboxGroup with the same name',
        );
      }

      if (checkboxProps.when) {
        throw new Error(
          'Grouped FormCheckbox should delegate `when` property to the corresponding FormCheckboxGroup component',
        );
      }
    }

    // Take 'name', 'error', 'onChange', 'onAfterChange', etc. from context provided by FormCheckboxGroup
    Object.assign(checkboxProps, propsFromContext);
    // Mark as checked if value exists in values array from FormCheckboxGroup
    checkboxProps.checked = values.includes(checkboxProps.value);

    // Now render pure Checkbox with merged props from group context to make FormCheckboxGroup controll it
    return <Checkbox {...checkboxProps} />;
  }

  checkboxProps.checked = field.value;
  checkboxProps.error = form.touched[name] === true && form.errors[name] !== undefined;
  checkboxProps.onChange = (evt, checking, pressedKeys, checkboxProps) => {
    if (onChange) {
      return onChange(evt, name, checking, pressedKeys, checkboxProps);
    }

    form.setFieldValue(name, checking);
    form.setFieldTouched(name, true);
  };

  return <Checkbox {...checkboxProps} />;
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(props => <Field {...props} component={FormCheckbox} />, {hoistSource: FormCheckbox});
