/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {forwardRefFactory, type ForwardRefProps, forwardRefSymbol} from 'react-forwardref-utils';
import {Icon, type IconProps, Tooltip} from 'components';
import type {CSSProperties} from 'react';
import type {IterableElement} from 'type-fest';
import {typesUtils} from '@illumio-shared/utils';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {IconName} from 'components/Icon/IconName';
import styles from './StatusIcon.css';

StatusIcon.icons = [
  'inuse',
  'inuse-classic',
  'error',
  'warning',
  'suspended',
  'deny',
  'syncing',
  'staged',
  'info',
  'online',
  'offline',
  'pending',
  'paired',
  'unpaired',
  'enabled',
  'disabled',
  'disabled-status',
  'unmanaged',
  'lock',
  'unlocked',
  'none',
  'help',
  'added',
  'removed',
  'modified',
  'modified-diff',
  'check',
  'stopped',
] as const;

type StatusIconName = IterableElement<typeof StatusIcon.icons>;

export type StatusIconProps = ForwardRefProps<HTMLDivElement> &
  Omit<IconProps, 'name'> &
  ThemeProps & {
    tid?: string;
    status: StatusIconName;
    label?: typesUtils.ReactStrictNode;
    title?: string;

    /** Sets text color to initial instead of applying status icon color */
    noTextColor?: boolean;

    /**
     * Shortcut to show a specific cursor on hover, like 'pointer'.
     * @default 'help' if the status is 'help' or 'info'
     */
    cursor?: CSSProperties['cursor'];

    tooltip?: typesUtils.ReactStrictNode;
    tooltipProps?: TooltipProps;
  };

// StatusIcon is a simple wrapper of Icon component, that takes one property 'status' and pass all extra props down to Icon.
// Property 'status' sets Icon's name property and mix status css class with Icon css class.
function StatusIcon(props: StatusIconProps): JSX.Element {
  const {
    label,
    status,
    theme,
    title,
    tooltip: tooltipContent,
    tooltipProps,
    noTextColor = false,
    cursor,
    [forwardRefSymbol]: ref,
    ...iconProps
  } = mixThemeWithProps(styles, props);
  const style: CSSProperties = {};

  if (cursor) {
    style.cursor = cursor;
  } else if (status === 'help' || status === 'info') {
    style.cursor = 'help';
  }

  let tooltip = tooltipContent;
  let name: IconName;

  switch (status) {
    case 'paired':
      name = 'pair';
      break;
    case 'unpaired':
      name = 'pair';
      break;
    case 'offline':
      name = 'online';
      break;
    case 'added':
      name = 'add';
      tooltip ??= intl('Provision.Added');
      break;
    case 'removed':
      name = 'remove';
      tooltip ??= intl('Common.Removed');
      break;
    case 'modified':
      name = 'progress';
      tooltip ??= intl('Provision.Modified');
      break;
    case 'modified-diff':
      name = 'online';
      tooltip ??= intl('Provision.Modified');
      break;
    case 'none':
      name = 'deny';
      break;
    default:
      name = status;
      break;
  }

  const statusIcon = (
    <span
      ref={ref}
      className={cx(theme[status])}
      data-tid="comp-status-icon"
      aria-label={typeof tooltip === 'string' ? tooltip : title}
      style={style}
    >
      <Icon name={name} theme={theme} position={label ? 'before' : undefined} {...iconProps} />
      {label ? (
        <span className={noTextColor ? theme.noTextColor : undefined} data-tid="elem-text">
          {label}
        </span>
      ) : null}
    </span>
  );

  if (tooltip) {
    const updatedProps: TooltipProps = {...tooltipProps};

    if (status === 'help' || status === 'info') {
      updatedProps.instant ??= true;
    }

    return (
      <Tooltip content={tooltip} {...updatedProps}>
        {() => statusIcon}
      </Tooltip>
    );
  }

  return statusIcon;
}

export default forwardRefFactory(StatusIcon);
