/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {produce} from 'immer';
import {PureComponent} from 'react';
import {MenuDelimiter, MenuInfo, Pill, ExpandableList, type PillProps} from 'components';
import {hrefUtils, ipUtils} from '@illumio-shared/utils';
import {getUpdateTypeProps} from '../PillUtils';
import type {IPList as IPListData} from 'illumio';
import styles from '../Pill.css';

export type IPListProps = PillProps & {
  value: IPListData;

  // policy version
  pversion?: number | string;

  noIcon?: boolean;
  contextualMenuIpAddresses?: ipUtils.IP_IP[];
  contextualMenuShowMaxIpAddresses?: number;
};

export default class IPList extends PureComponent<IPListProps> {
  element: HTMLElement | null | undefined = null;
  constructor(props: IPListProps) {
    super(props);

    this.saveRef = this.saveRef.bind(this);
  }

  private saveRef(element: {element: HTMLElement | null} | null) {
    this.element = element?.element;
  }

  renderContextualMenu: PillProps['contextualMenu'] = (items, {menuStyles, ...options}) => {
    const menu = produce(items, draft => {
      const firstInfo = draft.find(item => item.type === MenuInfo);

      if (firstInfo && this.props.contextualMenuIpAddresses?.length) {
        const {contextualMenuIpAddresses, contextualMenuShowMaxIpAddresses = 5} = this.props;

        draft.splice(
          draft.indexOf(firstInfo) + 1,
          0,
          {type: MenuDelimiter, props: {key: 'delimeter2'}},
          {
            type: MenuInfo,
            props: {
              key: 'ipList',
              children: (
                <div className={styles.scrollable}>
                  <ExpandableList
                    values={contextualMenuIpAddresses.map(ipAddress => (
                      <div className={menuStyles.menuInfoSidebar} key={ipAddress}>
                        {ipAddress}
                      </div>
                    ))}
                    showInModalFrom={1000} // Showing the modal will not work yet because it will disappear with the menu
                    initialCount={contextualMenuShowMaxIpAddresses}
                    modalTitle={intl('IPLists.IPAddresses')}
                  />
                </div>
              ),
            },
          },
        );
      }
    });

    return this.props.contextualMenu ? this.props.contextualMenu(menu, {menuStyles, ...options}) : menu;
  };

  render() {
    const {
      value,
      pversion = 'draft',
      updateTypeTooltip,
      noIcon,
      contextualMenuIpAddresses,
      contextualMenuShowMaxIpAddresses,
      ...pillProps
    } = this.props;

    Object.assign(pillProps, {
      ref: this.saveRef,
      icon: noIcon ? null : value.fqdn ? 'domain' : 'allowlist',
      link:
        pillProps.onClick || (!value.id && !value.href)
          ? null
          : {to: 'iplists.item.view', params: {pversion, id: value.id ?? hrefUtils.getId(value.href)}},
      ...getUpdateTypeProps({
        object: 'iplist',
        updateType: value.update_type,
        updateTypeTooltip,
        deleted: pillProps.deleted,
        pversion,
      }),
    });

    if (!pillProps.noContextualMenu) {
      pillProps.contextualMenu = this.renderContextualMenu;
      pillProps.contextualType ??= intl('Common.IPList');
      pillProps.contextualCopyValue ??= value.name;
    }

    return <Pill {...pillProps}>{value.name}</Pill>;
  }
}
