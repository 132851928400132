/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {domUtils} from '@illumio-shared/utils';
import {motion, AnimatePresence} from 'framer-motion';
import {getErrorStatusCount} from '../../../EditorTypes/IPListEditor/IPListEditorUtils';
import stylesUtils from 'utils.css';
import styles from './ErrorCounter.css';

const variants = {
  show: () => ({height: 'auto', opacity: 1, transition: {duration: domUtils.isMotionReduced() ? 0 : 0.25}}),
  hide: () => ({height: 0, opacity: 0, transition: {duration: domUtils.isMotionReduced() ? 0 : 0.15}}),
};

const ErrorCounter = props => {
  const {
    store: {getEditorState},
  } = props;
  const editorState = getEditorState();
  const currentContent = editorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();
  const errorInfo = getErrorStatusCount(blockMap);
  const count = blockMap.size;
  let showCounter;

  if (count > 0) {
    // Size checked is used for  the first initial block, when there is no text then error counter will not appear
    showCounter = count > 1 || Boolean(currentContent.getFirstBlock().getText().trim());
  } else {
    showCounter = errorInfo.error > 0 || errorInfo.exclusion > 0 || errorInfo.warning > 0;
  }

  return (
    <AnimatePresence initial={false}>
      {showCounter && (
        <motion.div
          key="a"
          initial="hide"
          animate="show"
          exit="hide"
          variants={variants}
          className={styles.main}
          data-tid="comp-counter"
        >
          {count > 0 && (
            <div data-tid="comp-size" className={styles.total}>
              {intl('Common.TotalNumber', {count, className: stylesUtils.monoSpace}, {html: true})}
            </div>
          )}
          {errorInfo.error > 0 && (
            <div data-tid="comp-error" className={styles.error}>
              {intl('IPLists.List.Error', {count: errorInfo.error, className: stylesUtils.monoSpace}, {html: true})}
            </div>
          )}
          {errorInfo.exclusion > 0 && (
            <div data-tid="comp-exclusion" className={styles.exclusion}>
              {intl(
                'IPLists.List.Exclusion',
                {count: errorInfo.exclusion, className: stylesUtils.monoSpace},
                {html: true},
              )}
            </div>
          )}
          {errorInfo.warning > 0 && (
            <div data-tid="comp-warning" className={styles.warning}>
              {intl('IPLists.List.Warning', {count: errorInfo.warning, className: stylesUtils.monoSpace}, {html: true})}
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ErrorCounter;
