/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserScoped} from 'containers/User/UserState';
import listReducers from './List/SwitchListState';
import detailReducers from './Detail/SwitchDetailState';

export default {
  switch: combineReducers({
    ...listReducers,
    ...detailReducers,
  }),
};

export const isSwitchEnabled = createSelector(isUserScoped, userIsScoped => !userIsScoped);
