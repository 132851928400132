/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getApplyLabelsRulesConfig} from './ApplyLabelRulesConfig';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {omit} from 'lodash';
import {createUIStateReducer} from '../../LabelRulesStateUtils';
import {getRemainingTime} from './ApplyLabelRulesUtils';

const getStartedAt = (state, newState) => {
  if (newState.status === 'queued') {
    return newState.createdAt;
  }

  if (newState.status === 'working' && state?.status === 'queued') {
    return new Date().toISOString();
  }

  return state?.startedAt ?? newState.createdAt ?? null;
};

export default {
  /**
   * reducer for the async jobs and their results
   * @param state
   * @param action
   * @returns {Pick<{}, Exclude<never, [undefined][number]>>|{}}
   */
  asyncJobs(state = {}, action) {
    switch (action.type) {
      case 'LABEL_RULES_ASYNC_JOB_CREATE_INIT':
      case 'LABEL_RULES_ASYNC_JOB_GET_INIT':
      case 'LABEL_RULES_ASYNC_JOB_ASSIGN_LABELS_INIT':
        return {
          ...state,
          [action.id]: {...state[action.id]},
        };
      case 'LABEL_RULES_ASYNC_JOB_CREATE_SUCCESS':
      case 'LABEL_RULES_ASYNC_JOB_GET_SUCCESS':
        const startedAt = getStartedAt(state[action.id], action.job);

        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.job,
            remainingTime: getRemainingTime(startedAt, action.job.progress),
            startedAt,
          },
        };
      case 'LABEL_RULES_ASYNC_JOB_ASSIGN_LABELS_SUCCESS':
        return {...state, [action.id]: {...state[action.id], updated: action.updated}};
      case 'LABEL_RULES_ASYNC_JOB_DOWNLOAD_SUCCESS':
        return {...state, [action.id]: {...state[action.id], result: action.result}};
      case 'LABEL_RULES_ASYNC_JOB_COPY':
        return {...state, [action.id]: {...state[action.originalId]}};
      case 'LABEL_RULES_ASYNC_JOB_CLEAR':
        return omit(state, action.id);
      default:
        return state;
    }
  },
  /**
   * reducer for the "apply rules" UI state; handles the progress and error states for
   * the API calls to create an async job, poll for completion, and download the result.
   */
  applyRulesUIState: createUIStateReducer({
    mode: 'multiple',
    initActions: ['LABEL_RULES_ASYNC_JOB_CREATE_INIT'],
    successActions: ['LABEL_RULES_ASYNC_JOB_DOWNLOAD_SUCCESS'],
    progressActions: ['LABEL_RULES_ASYNC_JOB_GET_SUCCESS'],
    failureActions: [
      'LABEL_RULES_ASYNC_JOB_CREATE_FAILED',
      'LABEL_RULES_ASYNC_JOB_GET_FAILED',
      'LABEL_RULES_ASYNC_JOB_DOWNLOAD_FAILED',
      'LABEL_RULES_ASYNC_JOB_POLL_FAILED',
    ],
    clearActions: ['LABEL_RULES_ASYNC_JOB_CLEAR'],
    copyActions: ['LABEL_RULES_ASYNC_JOB_COPY'],
  }),

  /**
   * reducer for the "assign labels" UI state; handles the progress and error states for
   * the API calls to assign labels from an async job.
   */
  assignLabelsUIState: createUIStateReducer({
    mode: 'multiple',
    initActions: ['LABEL_RULES_ASYNC_JOB_ASSIGN_LABELS_INIT'],
    successActions: ['LABEL_RULES_ASYNC_JOB_ASSIGN_LABELS_SUCCESS'],
    failureActions: ['LABEL_RULES_ASYNC_JOB_ASSIGN_LABELS_FAILED'],
    clearActions: ['LABEL_RULES_ASYNC_JOB_CLEAR'],
  }),

  /**
   * reducer for the current async job's ID used by the UI.
   * @param state
   * @param action
   * @returns {string|null}
   */
  currentAsyncJobId(state = null, action) {
    switch (action.type) {
      case 'LABEL_RULES_ASYNC_JOB_SET_CURRENT_ID':
        return action.id;
      case 'LABEL_RULES_ASYNC_JOB_CLEAR_CURRENT_ID':
        return null;
      default:
        return state;
    }
  },

  applyRulesExportUIState: createUIStateReducer({
    mode: 'multiple',
    initActions: ['LABEL_RULES_ASYNC_JOB_EXPORT_CSV_INIT'],
    successActions: ['LABEL_RULES_ASYNC_JOB_EXPORT_CSV_SUCCESS'],
    failureActions: ['LABEL_RULES_ASYNC_JOB_EXPORT_CSV_FAILED'],
    clearActions: ['LABEL_RULES_ASYNC_JOB_CLEAR'],
  }),

  asyncJobPollingUIState: createUIStateReducer({
    mode: 'multiple',
    initActions: ['LABEL_RULES_ASYNC_JOB_CREATE_INIT', 'LABEL_RULES_ASYNC_JOB_POLL_INIT'],
    successActions: ['LABEL_RULES_ASYNC_JOB_DOWNLOAD_SUCCESS'],
    progressActions: ['LABEL_RULES_ASYNC_JOB_GET_SUCCESS'],
    failureActions: [
      'LABEL_RULES_ASYNC_JOB_CREATE_FAILED',
      'LABEL_RULES_ASYNC_JOB_GET_FAILED',
      'LABEL_RULES_ASYNC_JOB_DOWNLOAD_FAILED',
      'LABEL_RULES_ASYNC_JOB_POLL_FAILED',
    ],
    clearActions: ['LABEL_RULES_ASYNC_JOB_CLEAR'],
    copyActions: ['LABEL_RULES_ASYNC_JOB_COPY'],
  }),
};

export const getAsyncJobs = state => state.label.rules.asyncJobs;
export const getApplyRulesUIState = state => state.label.rules.applyRulesUIState;
export const getAssignLabelsUIState = state => state.label.rules.assignLabelsUIState;
export const getApplyRulesExportUIState = state => state.label.rules.applyRulesExportUIState;
export const getCurrentAsyncJobId = state => state.label.rules.currentAsyncJobId;
const getAsyncJobPollingUIState = state => state.label.rules.asyncJobPollingUIState;

export const getGridSettings = createSelector([], () => getApplyLabelsRulesConfig());

export const getCurrentAsyncJob = createSelector([getAsyncJobs, getCurrentAsyncJobId], (jobs, id) => jobs[id] ?? null);
export const getCurrentApplyRulesUIState = createSelector(
  [getCurrentAsyncJobId, getApplyRulesUIState],
  (id, uiState) => uiState[id] ?? {},
);
export const getCurrentApplyRulesExportUIState = createSelector(
  [getCurrentAsyncJobId, getApplyRulesExportUIState],
  (id, uiState) => uiState[id] ?? {},
);
export const getCurrentAssignLabelsUIState = createSelector(
  [getCurrentAsyncJobId, getAssignLabelsUIState],
  (id, uiState) => uiState[id] ?? {},
);

export const getCurrentAsyncJobStatus = createSelector(
  [getCurrentAsyncJobId, getAsyncJobPollingUIState, getCurrentAsyncJob],
  (id, pollingUIState, asyncJob) => {
    const pollingStatus = pollingUIState[id];
    const isDownloaded = Boolean(asyncJob && !pollingStatus?.inProgress && !pollingStatus?.hasError);
    const isPolling = Boolean(pollingStatus?.inProgress);
    const isFailed = Boolean(pollingStatus?.hasError);
    const remainingTime = asyncJob?.remainingTime ?? null;

    return {isDownloaded, isPolling, isFailed, remainingTime};
  },
);

export const getGridRows = createSelector([getCurrentAsyncJob], job => {
  return (job?.result ?? []).map(workload => ({
    key: workload.href,
    data: {
      ...workload,
    },
  }));
});

export const getLabelRulesWorkloadsGrid = createSelector([state => state], state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getGridRows,
  }),
);
