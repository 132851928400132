/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {createSelector} from 'reselect';
import {getRoleFromHref, getLabelObjects} from 'containers/RBAC/RBACUtils';
import {isAllAbility} from 'containers/RBAC/RBACAbilityUtils';
import {gridSettings} from './ScopeDetailConfig';
import {isAPIAvailable} from 'api/apiUtils';

export default {
  permissions(state = [], action) {
    switch (action.type) {
      case 'SCOPE_GET_INSTANCE':
        return action.data.item;
      default:
        return state;
    }
  },
};

export const getScopePermissions = state => state.permissions.scope.permissions;

/*
 * Permissions on the scoped role detail page are grouped by authSecurityPrincipal
 */
export const getScopeDetailRows = createSelector(getScopePermissions, scopePermissions => {
  const permissionsGroupedByPrincipal = scopePermissions.reduce((res, permission) => {
    const role = getRoleFromHref(permission.role.href);

    const authSecPrincipalHref = permission.auth_security_principal.href;

    if (res.hasOwnProperty(authSecPrincipalHref)) {
      res[authSecPrincipalHref].data.roles.add(role);
      res[authSecPrincipalHref].data.permissionHrefs.add(permission.href);
    } else {
      const isAllowed = isAPIAvailable('org_permission.delete');

      res[authSecPrincipalHref] = {
        key: authSecPrincipalHref,
        selectable: isAllowed,
        data: {
          permissionHrefs: new Set([permission.href]),
          authSecPrincipalHref,
          authSecPrincipal: permission.auth_security_principal,
          roles: new Set([role]),
        },
      };
    }

    return res;
  }, {});

  return Object.values(permissionsGroupedByPrincipal);
});

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getScopeDetailRows,
  });

export const getScopedRoleDetailPage = createSelector([getGrid, getScopePermissions], (grid, scopePermissions) => {
  const count = {
    matched: grid.rows.length,
    total: grid.rows.length,
  };

  const editingScopeToAllAllowed = scopePermissions.some(permission =>
    isAllAbility(getRoleFromHref(permission.role.href)),
  );
  // Take scope array from first permission object as all permissions have the same selected scope access
  const scope = scopePermissions[0]?.scope;
  const permissionHrefs = scopePermissions.map(permission => permission.href);
  // Sort by key: 'app', 'env', 'loc'
  const scopeLabels = scope ? _.orderBy(getLabelObjects(scope), 'key') : null;
  const initialScope = scopeLabels ? new Map(scopeLabels.map(label => [label.key, label])) : new Map();

  return {
    grid,
    count,
    scopePermissions,
    editingScopeToAllAllowed,
    permissionHrefs,
    scopeLabels,
    initialScope,
  };
});
