/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createCachedSelector} from 're-reselect';
import {getLabelTypeList} from 'containers/Label/LabelSettings/LabelSettingState';
import {clickableColumn} from 'components/Grid/GridUtils';
import {Modal, Pill} from 'components';
import _ from 'lodash';
import styles from 'components/ExpandableList/ExpandableList.css';

const handleToggle = (_, setParentState, expanded) => {
  setParentState({expanded: !expanded});
};

const columnParent = (props, initialCount, showInModalFrom) => {
  const {content, state, setState, row} = props;
  const {expanded} = state;
  let modal;

  const labelMap = props?.value || row.data.labelsMap;
  let totalLabels = 0;

  labelMap?.forEach(labels => (totalLabels += Array.isArray(labels) ? labels.length : 1));

  if (labelMap && expanded && totalLabels >= showInModalFrom) {
    modal = (
      <Modal.Alert
        key="modal"
        title={intl('Common.Labels')}
        gap="gapXSmall"
        onClose={_.partial(handleToggle, _, setState, expanded)}
      >
        {Array.from(labelMap, ([key, labels]) => {
          return (Array.isArray(labels) ? labels : [labels]).map(({href, value, name}) => (
            <Pill.Label key={href} href={href} group={href.includes('label_groups')} type={key}>
              {value || name}
            </Pill.Label>
          ));
        })}
      </Modal.Alert>
    );
  }

  return (
    <>
      {/*the labels in the column should remain unexpanded*/}
      {content}
      {modal}
    </>
  );
};

const clickableLabelConfig = (key, display_name, labelTypeList, initialCount, showInModalFrom, renderedKeys) =>
  clickableColumn({
    header: display_name,
    noPadding: true,
    value: ({column, columns, row}) => {
      if (column.parentValue) {
        return column.parentValue({column, columns, row});
      }

      return row.data.labelsMap;
    },
    // showLess column should not be in "customizable columns" dropdown
    sortable: key !== 'showLess',
    format: formatProps => {
      const {value: labelsMap, clickableRef, parentState, setParentState, row, column} = formatProps;

      if (!labelsMap?.size) {
        return null;
      }

      const labels = labelsMap.get(key);
      const {expanded} = parentState;

      // don't show anything if:
      // - no label found (no label of current label type) and not mock column 'showLess'
      if (!labels && key !== 'showLess') {
        return null;
      }

      let hiddenCols = 0;

      // visible and existing labels
      let renderableLabels = 0;
      let totalLabels = 0;

      for (const [labelKey] of labelsMap) {
        const labelsOfType = labelsMap.get(labelKey);

        if (row.cells.get(`${column.parentId}-${labelKey}`)?.column.hidden) {
          hiddenCols++;
          totalLabels++;
        } else if (Array.isArray(labelsOfType)) {
          if (renderedKeys.includes(labelKey)) {
            renderableLabels = renderableLabels + labelsOfType.length;
          }

          totalLabels = totalLabels + labelsOfType.length;
        } else if (labelsOfType) {
          if (renderedKeys.includes(labelKey)) {
            renderableLabels++;
          }

          totalLabels++;
        }
      }

      const handleKeyUp = event => {
        if (event.key === ' ' || event.key === 'Enter') {
          // Emulate click on Space and Enter
          setParentState({expanded: true});
        }
      };

      if (
        key !== 'showLess' &&
        (renderableLabels <= initialCount ||
          (renderableLabels > initialCount && expanded && totalLabels < showInModalFrom))
      ) {
        return (Array.isArray(labels) ? labels : [labels]).reduce((result, label, currentIndex) => {
          if ((expanded && totalLabels < showInModalFrom) || renderableLabels + currentIndex < initialCount) {
            result.push(
              <Pill.Label
                href={label.href}
                group={label.href.includes('label_groups')}
                type={key}
                ref={clickableRef}
                key={label.href}
              >
                {label.value || label.name}
              </Pill.Label>,
            );
          }

          return result;
        }, []);
      }

      // show link if at the end of list and there are more labels to see
      if (key === 'showLess' && renderableLabels - initialCount > 0) {
        const numMore = renderableLabels - initialCount;

        return (
          <>
            {expanded && hiddenCols ? <i>{intl('Common.ColumnsHidden', {count: hiddenCols})}</i> : null}{' '}
            <div
              key="more"
              onClick={_.partial(handleToggle, _, setParentState, expanded)}
              onKeyUp={_.partial(handleKeyUp)}
              className={styles.more}
              tabIndex={0}
              ref={clickableRef}
              data-tid={`expandable-list-${expanded ? 'less' : 'more'}`}
            >
              {expanded && initialCount < showInModalFrom
                ? intl('Common.ShowLess')
                : intl('Common.CountMore', {count: numMore})}
            </div>
          </>
        );
      }
    },
    sort: ({value: labelsMap}) => {
      const labelsOfType = labelsMap?.get(key);

      if (Array.isArray(labelsOfType)) {
        return labelsOfType?.[0]?.value || labelsOfType?.[0]?.name;
      }

      return labelsOfType?.value || labelsOfType?.name;
    },
  });

export const allSubColConfig = {
  noPadding: true,
  header: intl('Common.All'),
  value: ({row}) => row.data.labelsMap?.size === 0,
  format: ({value}) => value && <Pill.Label all />,
};

export const getLabelsColumn = createCachedSelector(
  [
    getLabelTypeList,
    (state, props) => Boolean(props?.hasAll),
    (state, props) => props?.customAll,
    (state, props) => props?.initialCount || 8,
    (state, props) => props?.showInModalFrom || 25,
  ],
  (labelTypeList, hasAll, customAll, initialCount, showInModalFrom) => ({
    header: intl('Common.Labels'),
    horizontal: true,
    columns: [...labelTypeList, {key: 'showLess', display_name: 'showLess'}].reduce(
      (result, {key, display_name}) => ({
        ...result,
        [key]: clickableLabelConfig(
          key,
          display_name,
          labelTypeList,
          initialCount,
          showInModalFrom,
          Object.keys(result),
        ),
      }),
      {
        ...(hasAll && {all: customAll || allSubColConfig}),
      },
    ),

    format: props => columnParent(props, initialCount, showInModalFrom),
    templates: [...(hasAll ? ['all'] : []), ...labelTypeList.map(({key}) => key), 'showLess'],
  }),
)((state, props) => (props?.hasAll ? 'hasAll' : 'noAll'));
