/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {AppContext} from 'containers/App/AppUtils';
import {getRouteName} from 'containers/App/AppState';
import {cancelPollingTask, clearAsyncJob, pollAsyncJob} from '../List/Apply/ApplyLabelRulesSaga';
import {useSelector} from '@illumio-shared/utils/redux';
import {getCurrentAsyncJobStatus, getCurrentAsyncJob} from '../List/Apply/ApplyLabelRulesState';
import {getSavedAsyncJob} from '../List/Apply/ApplyLabelRulesUtils';
import intl from '@illumio-shared/utils/intl';
import styles from './LabelRulesApplyRulesCompleted.css';
import Notifications from 'components/Notifications/Notifications';
import LabelReducers from '../../LabelState';

LabelRulesApplyRulesCompleted.reducers = [LabelReducers];

export default function LabelRulesApplyRulesCompleted() {
  const {fetcher, navigate, dispatch} = useContext(AppContext);
  const pollingRef = useRef({start: null, stop: null});
  const asyncJob = useSelector(getCurrentAsyncJob);
  const asyncJobStatus = useSelector(getCurrentAsyncJobStatus);
  const routeName = useSelector(getRouteName);

  const isLabelingRulesPage = routeName === 'app.labels.rules.list';

  const [notificationDismissed, setNotificationDismissed] = useState(false);

  const handleSuccessNotificationClick = useCallback(() => {
    dispatch({type: 'APPLY_RULES_PANEL_OPEN'});
    navigate('labels.rules.list');
    setNotificationDismissed(true);
  }, [dispatch, navigate]);

  const handleErrorNotificationClick = useCallback(() => {
    fetcher.fork(clearAsyncJob, {uuid: asyncJob?.uuid, id: asyncJob?.uuid});
    setNotificationDismissed(true);
    navigate('labels.rules.list');
  }, [fetcher, navigate, asyncJob]);

  const handleCloseErrorNotificationClick = useCallback(() => {
    fetcher.fork(clearAsyncJob, {uuid: asyncJob?.uuid, id: asyncJob?.uuid});
    setNotificationDismissed(true);
  }, [fetcher, asyncJob]);

  const handleDismissNotificationClick = useCallback(() => {
    setNotificationDismissed(true);
  }, []);

  const notifications = useMemo(() => {
    if (notificationDismissed || isLabelingRulesPage) {
      return [];
    }

    if (asyncJobStatus?.isDownloaded) {
      return [
        {
          type: 'success',
          message: (
            <div onClick={handleSuccessNotificationClick}>
              {intl('LabelRules.ApplyRulesSuccessToastMessage', {linkClassName: styles.link}, {html: true})}
            </div>
          ),
          handleClose: handleDismissNotificationClick,
        },
      ];
    }

    if (asyncJobStatus?.isFailed) {
      return [
        {
          type: 'error',
          message: (
            <div onClick={handleErrorNotificationClick}>{intl('LabelRules.ApplyRulesErrorNotificationMessage')}</div>
          ),
          handleClose: handleCloseErrorNotificationClick,
        },
      ];
    }

    return [];
  }, [
    handleSuccessNotificationClick,
    handleErrorNotificationClick,
    handleDismissNotificationClick,
    asyncJobStatus,
    isLabelingRulesPage,
    notificationDismissed,
    handleCloseErrorNotificationClick,
  ]);

  // this effect resets the notificationDismissed state to false if the current asyncJob's uuid matches the
  // savedJob's uuid when the asyncJob's uuid changes;
  useEffect(() => {
    const savedJob = getSavedAsyncJob();

    if (savedJob?.uuid === asyncJob?.uuid) {
      setNotificationDismissed(false);
    }
  }, [asyncJob?.uuid]);

  // this effect sets the current job id in redux, and sets up the start/stop polling functions; it should
  // run every time the asyncJob changes;
  useEffect(() => {
    const {uuid: jobUUID} = asyncJob ?? getSavedAsyncJob() ?? {};

    if (jobUUID && asyncJob?.uuid !== jobUUID) {
      dispatch({type: 'LABEL_RULES_ASYNC_JOB_SET_CURRENT_ID', id: jobUUID});

      pollingRef.current = {
        start: () => {
          fetcher.fork(pollAsyncJob, {id: jobUUID, uuid: jobUUID, instantStart: true, downloadOnCompletion: true});
        },
        stop: () => {
          fetcher.spawn(cancelPollingTask, {uuid: jobUUID});
        },
      };
    }
  }, [fetcher, asyncJob, dispatch]);

  // this effect starts the polling when the asyncJobStatus object changes as long as the job is not
  // downloaded, failed, or polling.
  useEffect(() => {
    if (asyncJob && !asyncJobStatus?.isDownloaded && !asyncJobStatus?.isFailed && !asyncJobStatus?.isPolling) {
      pollingRef.current.start?.();
    }
  }, [asyncJob, asyncJobStatus]);

  // this effect starts the polling when the component mounts, and stops it when it unmounts.
  useEffect(() => {
    pollingRef.current.start?.();

    return () => {
      pollingRef.current.stop?.();
    };
  }, []);

  return notifications.length ? (
    <Notifications
      tid="label-rules-apply-rules-completed-toast"
      themePrefix="notifications-"
      theme={styles}
      shrink
      sidebar
    >
      {notifications}
    </Notifications>
  ) : null;
}
