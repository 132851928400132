/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Icon} from 'components';
import {UserName} from 'containers';
import styles from './Provision.css';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';
import {getProvisionStatusColumn} from 'components/Grid/GridUtils';

/**
[{
  header: string | Function,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function,
  unsortable: boolean,
  isDate: boolean | string,
}];
*/
export const selectedGridSettings = createSelector([], () => ({
  id: 'selected',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  columns: {
    status: getProvisionStatusColumn(),
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    type: {
      header: intl('Provision.Item'),
      value: 'typeLabel',
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => (
        <UserName user={row.data.updated_by} ref={clickableRef} linkProps={{target: '_blank'}} />
      ),
    }),
    remove: GridUtils.clickableColumn({
      header: intl('Common.Remove'),
      value: true,
      onClick: ({row, store}) => store.dispatch({type: 'REMOVE_SELECTION', href: row.key, objType: row.data.type}),
      format: ({clickableRef}) => <Icon name="close" ref={clickableRef} theme={styles} themePrefix="close-" />,
      sortable: false,
    }),
  },
  templates: [
    [
      {columns: ['status'], size: 'minmax(min-content, 130px)'},
      {columns: ['name'], size: 'minmax(320px, auto)'},
      {columns: ['type'], size: 'minmax(150px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(230px, auto)'},
      {columns: ['remove'], size: 'max-content'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['status'], size: 'minmax(min-content, 130px)'},
            {columns: ['name'], size: 'minmax(200px, auto)'},
            {columns: ['type'], size: 'minmax(120px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
            {columns: ['remove'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['status'], size: 'minmax(min-content, 130px)'},
          {columns: ['name'], size: 'minmax(200px, auto)'},
          {columns: ['type'], size: 'minmax(120px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
          {columns: ['remove'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['status'], size: 'minmax(min-content, 120px)'},
            {columns: ['name', 'type'], size: 'minmax(300px, auto)'},
            {columns: ['updatedBy', 'updatedAt'], size: 'minmax(230px, auto)'},
            {columns: ['remove'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['status'], size: 'minmax(min-content, 120px)'},
          {columns: ['name', 'type'], size: 'minmax(300px, auto)'},
          {columns: ['updatedBy', 'updatedAt'], size: 'minmax(230px, auto)'},
          {columns: ['remove'], size: 'max-content'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['name', 'type', 'status'], size: 'minmax(140px, auto)'},
            {columns: ['updatedBy', 'updatedAt'], size: 'minmax(150px, auto)'},
            {columns: ['remove'], size: 'max-content'},
          ];
        }

        return [
          {columns: ['name', 'type', 'status'], size: 'minmax(140px, auto)'},
          {columns: ['updatedBy', 'updatedAt'], size: 'minmax(150px, auto)'},
          {columns: ['remove'], size: 'max-content'},
        ];
      },
    },
  ],
}));

export const dependencyGridSettings = createSelector([], () => ({
  id: 'dependency',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  columns: {
    status: getProvisionStatusColumn(),
    name: {
      linky: true,
      header: intl('Common.Name'),
      value: 'name',
    },
    type: {
      header: intl('Provision.Item'),
      value: 'typeLabel',
    },
    updatedAt: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.LastModifiedOn'),
      value: 'updated_at',
    },
    updatedBy: GridUtils.clickableColumn({
      header: intl('Common.LastModifiedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => (
        <UserName user={row.data.updated_by} ref={clickableRef} linkProps={{target: '_blank'}} />
      ),
    }),
    requiredBy: {
      header: intl('Provision.RequiredBy'),
      value: ({row}) =>
        row.requiredBy && row.requiredBy.length > 1
          ? intl('Provision.EntitiesNumber', {count: row.requiredBy.length})
          : row.requiredBy
          ? row.requiredBy[0].data.name
          : null,
    },
  },
  templates: [
    [
      {columns: ['status'], size: 'minmax(min-content, 130px)'},
      {columns: ['name'], size: 'minmax(210px, auto)'},
      {columns: ['type'], size: 'minmax(150px, auto)'},
      {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
      {columns: ['updatedAt'], size: 'minmax(230px, auto)'},
      {columns: ['requiredBy'], size: 'minmax(210px, auto)'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['status'], size: 'minmax(min-content, 130px)'},
            {columns: ['name'], size: 'minmax(150px, auto)'},
            {columns: ['type'], size: 'minmax(120px, auto)'},
            {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
            {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
            {columns: ['requiredBy'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['status'], size: 'minmax(min-content, 130px)'},
          {columns: ['name'], size: 'minmax(150px, auto)'},
          {columns: ['type'], size: 'minmax(120px, auto)'},
          {columns: ['updatedBy'], size: 'minmax(230px, auto)'},
          {columns: ['updatedAt'], size: 'minmax(170px, auto)'},
          {columns: ['requiredBy'], size: 'minmax(150px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['status'], size: 'minmax(min-content, 120px)'},
            {columns: ['name', 'type'], size: 'minmax(200px, auto)'},
            {columns: ['updatedBy', 'updatedAt'], size: 'minmax(230px, auto)'},
            {columns: ['requiredBy'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['status'], size: 'minmax(min-content, 120px)'},
          {columns: ['name', 'type'], size: 'minmax(200px, auto)'},
          {columns: ['updatedBy', 'updatedAt'], size: 'minmax(230px, auto)'},
          {columns: ['requiredBy'], size: 'minmax(200px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['name', 'type', 'status'], size: 'minmax(130px, auto)'},
            {columns: ['updatedBy', 'updatedAt'], size: 'minmax(130px, auto)'},
            {columns: ['requiredBy'], size: 'minmax(130px, auto)'},
          ];
        }

        return [
          {columns: ['name', 'type', 'status'], size: 'minmax(130px, auto)'},
          {columns: ['updatedBy', 'updatedAt'], size: 'minmax(130px, auto)'},
          {columns: ['requiredBy'], size: 'minmax(130px, auto)'},
        ];
      },
    },
  ],
}));
