/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {StatusIcon} from 'components';
import {getEventType, getEventSeverity} from 'utils/events';
import {createSelector} from 'reselect';

export const getVenName = ven => ven.name || ven.hostname;

export const getActivationType = type => {
  if (type === 'pairing_key') {
    return intl('Common.PairingKey');
  }

  if (type === 'ca_signed_certificate') {
    return intl('VEN.CaCertificate');
  }

  if (type === 'self_signed_certificate') {
    return intl('VEN.SelfCertificate');
  }

  if (type === 'kerberos') {
    return intl('VEN.Kerberos');
  }
};

export const getVenVersion = ven => {
  if (ven.upgrade_target_version) {
    return (
      <div>
        {ven.version}
        <div>
          <StatusIcon
            status="syncing"
            label={intl('VEN.Upgrade.VENUpgradeTo', {version: ven.upgrade_target_version})}
          />
        </div>
      </div>
    );
  }

  return ven.version;
};

export const venTypesList = [
  __ANTMAN__ ? [intl('Common.Server'), 'server'] : [intl('VEN.VEN'), 'server'],
  [intl('VEN.CVEN'), 'containerized'],
  __ANTMAN__ ? [intl('Antman.Endpoints.Endpoint'), 'endpoint'] : [intl('VEN.EVEN'), 'endpoint'],
];

// JS Map with KV pairs for: display value to api values, and api values to display values
export const venTypeMap = new Map(venTypesList.map(type => [...type].reverse()));

export const getVenConditions = (conditions, displayType) => {
  if (conditions.length === 0) {
    return displayType === 'list' ? (
      <StatusIcon status="check" />
    ) : (
      <StatusIcon status="check" label={intl('Common.Healthy')} />
    );
  }

  let highestSeverity = '';

  const notices = {Error: [], Warning: [], Info: []};

  conditions.forEach(o => {
    const event = o?.latest_event;

    if (event?.severity && event?.notification_type) {
      let severity = getEventSeverity(event.severity);

      severity = typeof severity === 'function' ? severity() : severity;

      const title = getEventType(event.notification_type, false);
      const messageLine = `${_.capitalize(severity)}: ${title}`;

      notices[severity].push(messageLine);
    }
  });

  if (notices.Error.length) {
    highestSeverity = 'error';
  } else if (notices.Warning.length) {
    highestSeverity = 'warning';
  } else if (notices.Info.length) {
    highestSeverity = 'info';
  }

  if (!highestSeverity) {
    return null; // conditions were malformed
  }

  const allMessages = [...notices.Error, ...notices.Warning, ...notices.Info].join('\n');

  return displayType === 'list' ? (
    <StatusIcon status={highestSeverity} tooltip={allMessages} key={highestSeverity} />
  ) : (
    <StatusIcon status={highestSeverity} tooltip={allMessages} key={highestSeverity} label={allMessages} />
  );
};

export const getConnectivity = workloads => (workloads?.[0]?.online ? 'online' : 'offline');

export const venStatuses = createSelector([], () => ({
  active: <StatusIcon status="inuse" label={intl('Common.Active')} />,
  suspended: <StatusIcon status="suspended" label={intl('Workloads.Status.Suspended')} />,
  stopped: <StatusIcon status="stopped" label={intl('Common.Stopped')} />,
  uninstalling: <StatusIcon status="disabled" label={intl('Common.Uninstalled')} />,
  deactivated_confirmed: <StatusIcon status="disabled" label={intl('Common.Uninstalled')} />, // rare, not germain to UI
  deactivated_unconfirmed: <StatusIcon status="disabled" label={intl('Common.Uninstalled')} />, // rare, not germain to UI
}));

export const locations = createSelector([], () => ({
  'us-east-1.amazonaws.com': {name: 'Amazon EC2 (US East)', loc: 'N. Virginia, USA'},
  'us-west-1.amazonaws.com': {name: 'Amazon EC2 (US West)', loc: 'N. California, USA'},
  'us-west-2.amazonaws.com': {name: 'Amazon EC2 (US West)', loc: 'Oregon, USA'},
  'sa-east-1.amazonaws.com': {name: 'Amazon EC2 (S. America)', loc: 'Sao Paulo, BRA'},
  'eu-west-1.amazonaws.com': {name: 'Amazon EC2 (EU)', loc: 'Ireland, IRL'},
  'ap-southeast-1.amazonaws.com': {name: 'Amazon EC2 (APAC)', loc: 'Singapore, SGP'},
  'ap-southeast-2.amazonaws.com': {name: 'Amazon EC2 (APAC)', loc: 'Sydney, AUS'},
  'ap-northeast-1.amazonaws.com': {name: 'Amazon EC2 (APAC)', loc: 'Tokyo, JPN'},

  'dfw1.rackspace.com': {name: 'Rackspace (DFW)', loc: 'Grapevine, TX, USA'},
  'ord1.rackspace.com': {name: 'Rackspace (ORD)', loc: 'Chicago, IL, USA'},
  'iad1.rackspace.com': {name: 'Rackspace (IAD)', loc: 'Ashburn, VA, USA'},
  'syd1.rackspace.com': {name: 'Rackspace (SYD)', loc: 'Sydney, AUS'},
  'syd2.rackspace.com': {name: 'Rackspace (SYD)', loc: 'Sydney, AUS'},
  'lon1.rackspace.com': {name: 'Rackspace (LON)', loc: 'Heathrow, London, UK'},
  'lon3.rackspace.com': {name: 'Rackspace (LON)', loc: 'Slough, London, UK'},
  'hkg1.rackspace.com': {name: 'Rackspace (HKG)', loc: 'Hong Kong, CHN'},

  'sfo.gogrid.com': {name: 'GoGrid (US West-1)', loc: 'San Francisco, CA, USA'},
  'iad.gogrid.com': {name: 'GoGrid (US East-1)', loc: 'Auburn, VA, USA'},
  'ams.gogrid.com': {name: 'GoGrid (EU East-1)', loc: 'Amsterdam, NH, NLD'},

  'europe-west1-a.google.com': {name: 'Google (EU West1-a)', loc: 'EU West'},
  'europe-west1-b.google.com': {name: 'Google (EU West1-b)', loc: 'EU West'},
  'us-central1-a.google.com': {name: 'Google (US Central1-a)', loc: 'US Central'},
  'us-central1-b.google.com': {name: 'Google (US Central1-b)', loc: 'US Central'},
  'us-central2-a.google.com': {name: 'Google (US Central2-a)', loc: 'US Central'},

  'europewest.cloudapp.net': {name: 'Windows Azure (EU West)', loc: 'EU West'},
  'europenorth.cloudapp.net': {name: 'Windows Azure (EU North)', loc: 'EU North'},
  'brazilsouth.cloudapp.net': {name: 'Windows Azure (Brazil South)', loc: 'BRA South'},
  'usnorth.cloudapp.net': {name: 'Windows Azure (US North)', loc: 'US North'},
  'useast.cloudapp.net': {name: 'Windows Azure (US East)', loc: 'US East'},
  'useast2.cloudapp.net': {name: 'Windows Azure (US East 2)', loc: 'US East 2'},
  'useast2euap.cloudapp.net': {name: 'Windows Azure (US East 2 EUAP)', loc: 'US East 2 EUAP'},
  'uscentral.cloudapp.net': {name: 'Windows Azure (US Central)', loc: 'US Central'},
  'uscentraleuap.cloudapp.net': {name: 'Windows Azure (US Central EUAP)', loc: 'US Central EUAP'},
  'uswestcentral.cloudapp.net': {name: 'Windows Azure (US West Central)', loc: 'US West Central'},
  'uswest.cloudapp.net': {name: 'Windows Azure (US West)', loc: 'US West'},
  'uswest2.cloudapp.net': {name: 'Windows Azure (US West 2)', loc: 'US West 2'},
  'ussouth.cloudapp.net': {name: 'Windows Azure (US South)', loc: 'US South'},
  'asiasoutheast.cloudapp.net': {name: 'Windows Azure (SE Asia)', loc: 'SE Asia'},
  'asiaeast.cloudapp.net': {name: 'Windows Azure (East Asia)', loc: 'East Asia'},
  'japaneast.cloudapp.net': {name: 'Windows Azure (JPN East)', loc: 'JPN East'},
  'japanwest.cloudapp.net': {name: 'Windows Azure (JPN West)', loc: 'JPN West'},
  'koreaeast.cloudapp.net': {name: 'Windows Azure (KOR East)', loc: 'KOR East'},
  'koreacentral.cloudapp.net': {name: 'Windows Azure (KOR Central)', loc: 'KOR Central'},
  'australiaeast.cloudapp.net': {name: 'Windows Azure (AUS East)', loc: 'AUS East'},
  'australiasoutheast.cloudapp.net': {name: 'Windows Azure (AUS SE)', loc: 'AUS SE'},
  'indiacentral.cloudapp.net': {name: 'Windows Azure (IN Central)', loc: 'IND Central'},
  'indiasouth.cloudapp.net': {name: 'Windows Azure (IN South)', loc: 'IND South'},
  'indiawest.cloudapp.net': {name: 'Windows Azure (IN West)', loc: 'IND West'},
  'canadaeast.cloudapp.net': {name: 'Windows Azure (CAN East)', loc: 'CAN East'},
  'canadacentral.cloudapp.net': {name: 'Windows Azure (CAN Central)', loc: 'CAN Central'},
  'uksouth.cloudapp.net': {name: 'Windows Azure (UK South)', loc: 'UK South'},
  'ukwest.cloudapp.net': {name: 'Windows Azure (UK West)', loc: 'UK West'},
}));

export const locationText = (dataCenter, dataCenterZone, managed) => {
  if (!dataCenter && !dataCenterZone) {
    if (managed) {
      return intl('Policy.UnknownDataCenterAndLocation');
    }

    return '';
  }

  const location = locations()[dataCenter];

  if (location) {
    return `${location.name}, ${location.loc}`;
  }

  //Unmanaged workload
  if (dataCenter && !dataCenterZone && !managed) {
    return dataCenter;
  }

  if (!dataCenter) {
    return `${intl('Policy.UnnamedDataCenter')}, ${dataCenterZone}`;
  }

  if (!dataCenterZone) {
    return `${dataCenter}, ${intl('Policy.UnknownLocation')}`;
  }

  return `${dataCenter}, ${dataCenterZone}`;
};
