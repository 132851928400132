/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import _ from 'lodash';

/**
 * Creates a reducer used to manage loading, progress, and error states for one or more async tasks. It supports two modes -
 * 'single', and 'multiple'. The default mode is 'single'.
 *
 * Single mode is for tracking a single loading/progress/error state. In 'single' mode, the state will be in the structure
 * {inProgress: boolean, progress: integer, hasError: boolean, error: null|any};
 *
 * Multiple mode is for tracking multiple loading/progress/error states indexed by a unique id. In multiple mode, the state
 * for the unique 'id' is updated by passing the unique id in each action's payload. Example: {type: 'INIT_FOO', id: '123'}.
 * The subsequent actions for the 'id' would also need to contain the same id in their action payload. In 'multiple' mode, the
 * state will be in the structure {[id: string]: {inProgress: boolean, progress: integer, hasError: boolean, error: null|any}};
 *
 * The properties stored in the state objects are...
 *   - inProgress: boolean; True if the async task(s) are in progress; When an "initAction" is dispatched, this property is
 *                 set to true; When a successAction, failAction, or resetAction is dispatched, this property is set to false;
 *   - progress:   Integer between 0 and 100; The progress value sent by one of the progressActions; It's initial state is 0;
 *                 and it is set to 100 when one of the successActions is dispatched;
 *   - hasError:   boolean; True if one of the failedActions were dispatched; False otherwise;
 *   - error:      null|any; The value of action.error from one of the failedActions; Usually an instance of Error or null;
 *
 *
 * To maintain correct, predictable behavior of the state machine, it is recommended that the same action type should not
 * appear in multiple actions arrays in the same reducer. Violating this principle may not necessarily cause issues, but it
 * can yield less predictable behavior.
 *
 * @param mode            - String (single|multiple); In single mode, the reducer stores a single loading/progress/error state;
 *                          In multiple mode, it stores an object containing multiple loading/progress/error states that are
 *                          indexed by a unique id.
 * @param initActions     - Array of action types that will initialize a new async action by setting the state
 *                          to the initialState with inProgress=true. (inProgress=true, progress=0, hasError=false, error=null)
 * @param progressActions - Array of action types that update the progress state (progress=action.progress)
 * @param successActions  - Array of action types that set a success state (inProgress=false, progress=100, hasError=false, error=null)
 * @param failureActions  - Array of action types that set the failed state (inProgress=false, hasError=true, error=action.error)
 * @param resetActions    - Array of action types that will reset the state to its initialState
 *                          (inProgress=false, progress=0, hasError=false, error=null)
 * @param clearActions    - Array of action types that will clear out the state; It is really intended for multiple mode,
 *                          but works in single mode as well. In single mode, it returns an empty object as the state;
 *                          In multiple mode, it removes the id from the state;
 * @param copyActions     - Array of action types that will copy an item from the state; it accepts an 'id' and an 'originalId';
 *                          The 'id' is the id to copy to, and the originalId is the state that will be copied to the id; Only
 *                          available in multiple mode;
 *
 * @returns {(function({}=, *): ({}))|*} - reducer that returns the state;
 *                          In 'single' mode, it returns:
 *                          {inProgress: boolean; progress: integer; hasError: boolean; error: Error|null;}
 *                          In 'multiple' mode, it returns:
 *                          {[key: string]: {inProgress: boolean; progress: integer; hasError: boolean; error: Error|null;}}
 *
 */
export const createUIStateReducer = ({
  mode = 'single',
  initActions = [],
  progressActions = [],
  successActions = [],
  failureActions = [],
  resetActions = [],
  clearActions = [],
  copyActions = [],
}) => {
  const actions = {
    init: new Set(initActions),
    progress: new Set(progressActions),
    success: new Set(successActions),
    failure: new Set(failureActions),
    reset: new Set(resetActions),
    clear: new Set(clearActions),
    copy: new Set(copyActions),
  };
  const initialUIState = {
    inProgress: false,
    progress: 0,
    hasError: false,
    error: null,
  };
  const initialState = mode === 'single' ? {...initialUIState} : {};

  if (
    __DEV__ &&
    new Set([
      ...actions.init,
      ...actions.progress,
      ...actions.success,
      ...actions.failure,
      ...actions.reset,
      ...actions.clear,
      ...actions.copy,
    ]).size !==
      actions.init.size +
        actions.progress.size +
        actions.success.size +
        actions.failure.size +
        actions.reset.size +
        actions.clear.size +
        actions.copy.size
  ) {
    console.warn(
      'Duplicate actions passed to createUIStateReducer. A given action type should only appear in one of ' +
        'initActions, progressActions, successActions, failureActions, resetActions, and clearActions. One or more of ' +
        'your actions appear in more than one of those arrays. This may cause unexpected behavior. It should be possible ' +
        'to resolve this by creating additional action types instead of re-using the same one in multiple actions arrays.',
    );
  }

  return function (state = initialState, action) {
    // handle init actions
    if (actions.init.has(action.type)) {
      if (mode === 'single') {
        return {...initialUIState, inProgress: true};
      }

      return {...state, [action.id]: {...initialUIState, inProgress: true}};
    }

    // handle progress actions
    if (actions.progress.has(action.type)) {
      if (mode === 'single') {
        return {
          ...state,
          ...(action.hasOwnProperty('progress') ? {progress: action.progress} : {}),
        };
      }

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...(action.hasOwnProperty('progress') ? {progress: action.progress} : {}),
        },
      };
    }

    // handle success actions
    if (actions.success.has(action.type)) {
      if (mode === 'single') {
        return {...state, inProgress: false, progress: 100};
      }

      return {
        ...state,
        [action.id]: {...state[action.id], inProgress: false, progress: 100},
      };
    }

    // handle failure actions
    if (actions.failure.has(action.type)) {
      if (mode === 'single') {
        return {
          ...state,
          inProgress: false,
          hasError: true,
          ...(action.hasOwnProperty('error') ? {error: action.error} : {}),
        };
      }

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          inProgress: false,
          hasError: true,
          ...(action.hasOwnProperty('error') ? {error: action.error} : {}),
        },
      };
    }

    // handle reset actions
    if (actions.reset.has(action.type)) {
      if (mode === 'single') {
        return {...initialUIState};
      }

      return {
        ...state,
        [action.id]: {...initialUIState},
      };
    }

    // handle clear actions
    if (actions.clear.has(action.type)) {
      if (mode === 'single') {
        return {};
      }

      return _.omit(state, action.id);
    }

    // handle copy actions
    if (actions.copy.has(action.type)) {
      if (mode === 'single') {
        return state;
      }

      return {...state, [action.id]: {...state[action.originalId]}};
    }

    // unhandled actions
    return state;
  };
};
