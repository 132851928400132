/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {
  isManagedEndpoint,
  type EndpointType,
  type LinkData,
  type MapGroupingSettings,
} from 'containers/IlluminationMap/MapTypes';
import {hrefUtils} from '@illumio-shared/utils';

export const defaultGroupingOrder = __ANTMAN__ ? [] : ['role', 'app', 'env', 'loc'];
export const defaultFocusedGrouping = 'role';
export const defaultMaxItems = 500;
export const defaultAutoGrouping = {
  enabled: true,
  maxItems: defaultMaxItems,
  groupingOrder: defaultGroupingOrder,
  nodes: true,
};
export const defaultMapFilters = {
  consumerInclude: new Map(),
  providerInclude: new Map(),
  servicesInclude: new Map(),
  consumerExclude: new Map(),
  providerExclude: new Map(),
  servicesExclude: new Map(),
  consumerOrProviderInclude: new Map(),
  consumerOrProviderExclude: new Map(),
};

export const calculateLabelCounts = (links: LinkData[]): Record<string, Set<string>> =>
  links.reduce(
    (result, link) => {
      const endpoints: EndpointType[] = ['source', 'target'];

      endpoints.forEach(endpointType => {
        const endpoint = link[endpointType];

        if (isManagedEndpoint(endpoint)) {
          endpoint.details.labels.forEach(label => {
            result[label.key] ||= new Set();

            result[label.key].add(label.href);
          });

          result.appGroup.add(endpoint.details.appGroupId);
          result.nodes.add(endpoint.details.labels.map(label => hrefUtils.getId(label.href)).join(','));
        }
      });

      return result;
    },
    {appGroup: new Set(), nodes: new Set()} as Record<string, Set<string>>,
  );

export const calculateAutoGrouping = (
  labelCounts: Record<string, Set<string>>,
  groupingSettings: MapGroupingSettings,
): string[] => {
  const {groupingOrder} = groupingSettings;
  const allGrouping = [...(groupingOrder || [])];

  let nextDefaultIndex = 0;
  const reverseDefaultOrder = [...defaultGroupingOrder].reverse();

  // If the number of nodes at the top level is really large, add the default groupings
  while (
    (labelCounts[allGrouping.at(-1) || 0]?.size || 0) > defaultMaxItems &&
    nextDefaultIndex < defaultGroupingOrder.length
  ) {
    if (!allGrouping.includes(reverseDefaultOrder[nextDefaultIndex])) {
      allGrouping.push(reverseDefaultOrder[nextDefaultIndex]);
    }

    nextDefaultIndex += 1;
  }

  return allGrouping;
};
