/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {isUserReadOnly} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './SwitchListConfig';
import {getInterfaces} from '../InterfaceState';
import {getSupportedDevicesStaticValues} from '../NetworkEnforcementNodeState';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'SWITCH_GET_LIST':
        return action.data.switchList;
      default:
        return state;
    }
  },
  count(state = {}, action) {
    switch (action.type) {
      case 'SWITCH_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
  listGetting(state = false, action) {
    switch (action.type) {
      case 'SWITCH_GETTING_LIST':
        return true;
      case 'SWITCH_GET_LIST':
        return false;
      default:
        return state;
    }
  },
};

export const getSwitches = state => state.switch.list;
export const getSwitchesCount = state => state.switch.count;
export const getSwitchesGetting = state => state.switch.listGetting;

export const getGridSettings = createSelector([gridSettings, isUserReadOnly], (gridSettings, userIsReadOnly) => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = userIsReadOnly;
  columns.download.disabled = userIsReadOnly;

  return {...gridSettings, columns};
});

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

export const getSwitchRows = createSelector(
  [getSwitches, getInterfaces],
  (switches, interfaces) =>
    switches &&
    switches.map(switchInst => {
      const config = switchInst && switchInst.config;
      const interfaceNames = switchInst.network_endpoints
        .map(interfaceHref => {
          const interfaceInst = interfaces[interfaceHref.href];

          return _.get(interfaceInst, 'config.name');
        })
        .join('  ');

      const {
        enforcement_instructions_data_href: enforcementInstructionsDataHref,
        enforcement_instructions_data_timestamp: enforcementInstructionsDataTimestamp,
        enforcement_instructions_generation_in_progress: enforcementInstructionsGenerationInProgress,
        enforcement_instructions_ack_timestamp: enforcementInstructionsAckTimestamp,
      } = switchInst;

      return {
        key: switchInst.href,
        name: config && config.name,
        deviceType: config && config.device_type,
        manufacturer: config && config.manufacturer,
        model: config && config.model,
        ipaddress: config && config.ip_address,
        interfaces: interfaceNames,
        selectable: true,
        removable: true,
        applied: enforcementInstructionsAckTimestamp,
        dataHref: enforcementInstructionsDataHref,
        dataTimeStamp: enforcementInstructionsDataTimestamp,
        inProgress: enforcementInstructionsGenerationInProgress,
      };
    }),
);

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getSwitchRows,
    filterMap: getFilterMap,
  });

export const getSwitchesPage = createSelector(
  [
    getGrid,
    getSwitches,
    getSwitchesCount,
    getSwitchesGetting,
    getSupportedDevicesStaticValues,
    isUserReadOnly,
    categories,
  ],
  (grid, switches, count, isGetting, staticValues, userIsReadOnly, categories) => {
    // Selector parameters based on filter and config

    return {switches, grid, categories, count, isGetting, userIsReadOnly};
  },
);
