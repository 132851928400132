/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {type ComponentPropsWithoutRef, PureComponent} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './Spinner.css';

export type SpinnerProps = ComponentPropsWithoutRef<'div'> &
  ThemeProps & {
    color?: 'dark' | 'light';
    position?: 'after' | 'before' | 'inbetween' | 'normal';
    size?: 'fifty' | 'huge' | 'large' | 'medium' | 'small' | 'twenty' | 'xlarge' | 'xxlarge';
    tid?: string;
  };

export default class Spinner extends PureComponent<SpinnerProps> {
  render() {
    const {
      color,
      size,
      position = 'normal',
      tid = 'comp-spinner',
      theme,
      ...props
    } = mixThemeWithProps(styles, this.props);

    props['data-tid'] = tid;
    props.className = cx(theme.spinner, size && theme[size], theme[position], color && theme[color]);

    return <div {...props} />;
  }
}
