/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import timezones from 'google-timezones-json';

/**
 * Timezones are by default sorted by offset and are presented with labels offsets for better readability.
 *
 * @example
 * e.g. [{value: "America/Anchorage", label: "America/Anchorage: (GMT-09:00) Alaska Time"}, ...]
 *
 * @returns
 */
export const getTimezones = (): {value: string; label: string}[] => [
  ...Object.keys(timezones).map(timezone => ({value: timezone, label: `${timezone}: ${timezones[timezone]}`})),
  {value: 'UTC', label: 'UTC'},
];
