/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import createCachedSelector from 're-reselect';
import {filterReportsByTypeAndSortByRequested} from '../ReportsUtils';
import {getUserReportsComplete, getUserReportsDone, getUserReportsInProgress} from '../List/ReportsListState';

export const getButtonReportsComplete = createCachedSelector(
  getUserReportsComplete,
  (state, props) => props.type,
  (reports, type) => filterReportsByTypeAndSortByRequested(reports, type),
)((state, props) => props.type);

export const getButtonReportsDone = createCachedSelector(
  getUserReportsDone,
  (state, props) => props.type,
  (reports, type) => filterReportsByTypeAndSortByRequested(reports, type).slice(0, 10),
)((state, props) => props.type);

export const getButtonReportsInProgress = createCachedSelector(
  getUserReportsInProgress,
  (state, props) => props.type,
  (reports, type) => filterReportsByTypeAndSortByRequested(reports, type),
)((state, props) => props.type);

export const getReportButtons = createCachedSelector(
  [getButtonReportsComplete, getButtonReportsDone, getButtonReportsInProgress, (state, props) => props],
  (reportsComplete, reportsDone, reportsInProgress, props) => ({
    ...props,
    reportsComplete,
    reportsDone,
    reportsInProgress,
  }),
)((state, props) => props.type);
