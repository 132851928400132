/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {generalUtils} from '@illumio-shared/utils/shared';
import {useLayoutEffect, useMemo, useContext} from 'react';
import {HeaderContext} from './HeaderProvider';

HeaderProps.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Icon before title
  title: PropTypes.string, // Bold text (usually page name)
  subtitle: PropTypes.any, // Secondary text
  label: PropTypes.string, // Additional text, orange
  isXpress: PropTypes.bool, // Flag to show Xpress content
  customSearch: PropTypes.any,

  // Up button parameters
  up: PropTypes.oneOfType([
    PropTypes.bool, // Show up button to the first parent route
    PropTypes.string, // Explicit route name if simply a string, or a relative reference to the parent starting with '<'
    PropTypes.shape({
      // Link parameters if object
      to: PropTypes.string, // Route name, or a relative reference to the parent starting with '<'
      params: PropTypes.object, // Route params
      options: PropTypes.object, // Route options

      // Back button click handler. Can return false to prevent navigation if link parameters specified
      onClick: PropTypes.func,

      icon: PropTypes.string, // Back button Icon name, default is 'navigateup'
    }),
  ]),
  headlineContentArr: PropTypes.array,
};

const generateId = () => generalUtils.randomString(5);

/**
 * @param {{
 *  icon?: string | {name: string}
 * }} props
 * @returns
 */
function HeaderProps(props) {
  const {setContext} = useContext(HeaderContext);

  // Generate key on the first render so header reducer can match correct entry on HEADER_POP/HEADER_REPLACE actions
  const id = useMemo(generateId, []);

  // Remove header entry on unmount
  useLayoutEffect(() => {
    return () => setContext({id, action: 'HEADER_POP'});
  }, [setContext, id]);

  useLayoutEffect(() => {
    setContext({id, ...props});
  }, [id, setContext, props]);

  return null;
}

export default HeaderProps;
