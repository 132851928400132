/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';

export default {
  health: combineReducers({
    clusters(state = [], {type, payload}) {
      switch (type) {
        case 'HEALTH_GET_CLUSTERS':
          return payload.clusters;
        default:
          return state;
      }
    },
    clustersBasic(state = [], {type, payload}) {
      if (type === 'HEALTH_GET_CLUSTERS') {
        const basicInfos = payload.clusters.map(cluster => _.pick(cluster, ['fqdn', 'status', 'type']));

        // Change state only if basics have been changed, not others
        if (!_.isEqual(state, basicInfos)) {
          return basicInfos;
        }
      }

      return state;
    },
    localCluster(state = {}, {type, payload}) {
      if (type === 'HEALTH_GET_CLUSTERS') {
        if (!payload.clusters.length && state) {
          return null;
        }

        // Change state only if local has been changed, not others
        if (!_.isEqual(state, payload.clusters[0])) {
          return payload.clusters[0];
        }
      }

      return state;
    },
    // Object with basic info that changes not often and therefore consumers of this reducer will not be recomputed if other data changes
    localClusterBasic(state = {}, {type, payload}) {
      if (type === 'HEALTH_GET_CLUSTERS') {
        const basicInfo = _.pick(payload.clusters[0], ['fqdn', 'status', 'type']);

        // Change state only if local basic info has been changed, not others
        if (!_.isEqual(state, basicInfo)) {
          return basicInfo;
        }
      }

      return state;
    },
    isActiveDuringUpgradeFinish(state = false, {type, payload}) {
      // Set flag to true (only once) if clusters have been in rolling ugrade status during current user session and now they don't.
      // Trying to mimic behavior that should be handled by backend. Look at EYE-42951
      if (
        type === 'HEALTH_GET_CLUSTERS' &&
        !state &&
        payload.wasInRollingUpgrade &&
        payload.clusters.every(cluster => !cluster.upgrade_pending)
      ) {
        return true;
      }

      return state;
    },
  }),
};

export const getClusters = state => state.health.clusters;
export const getClustersBasic = state => state.health.clustersBasic;
export const getLocalCluster = state => state.health.localCluster;
export const getLocalClusterBasic = state => state.health.localClusterBasic;
export const getActiveWhileUpgradeFinished = state => state.health.isActiveDuringUpgradeFinish;

export const getClustersMap = createSelector(getClusters, (clusters = []) =>
  clusters.reduce((result, cluster) => ({...result, [cluster.fqdn]: cluster}), {}),
);

export const getLocalFQDN = createSelector(getLocalClusterBasic, cluster => cluster.fqdn);

export const getLocalStatus = createSelector(getLocalClusterBasic, cluster => cluster.status);

export const getLocalType = createSelector(getLocalClusterBasic, cluster => cluster.type);

export const getLocalListenOnlyEnableTime = createSelector(getLocalCluster, cluster =>
  cluster ? cluster.listen_only_mode_enabled_at : null,
);

export const getInRollingUpgrade = createSelector(getClusters, (clusters = []) =>
  clusters.some(cluster => cluster.upgrade_pending),
);

export const getRemainingRollingUpgradeCount = createSelector(
  getClusters,
  (clusters = []) => clusters.filter(cluster => cluster.upgrade_pending).length,
);

export const getRollingUpgradeCount = createSelector(getClusters, (clusters = []) => clusters.length);

export const getFQDNList = createSelector(getClustersBasic, (clusters = []) => clusters.map(cluster => cluster.fqdn));

export const hasListenOnlyMember = createSelector(getClusters, (clusters = []) =>
  clusters.some(cluster => cluster.listen_only_mode_enabled_at),
);
