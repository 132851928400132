/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import JSONBig from 'json-bigint-keep-object-prototype-methods';
import {hrefUtils} from '@illumio-shared/utils';
import {Link, Pill} from 'components';
import {getMethodParameterizedPath} from 'api/apiUtils';
import {createSelector} from 'reselect';
import stylesUtils from 'utils.css';
import {generalUtils} from '@illumio-shared/utils/shared';
import * as GridUtils from 'components/Grid/GridUtils';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';
import {call} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {sortOptions} from 'containers/Selector/SelectorUtils';

const LABEL_DELIMINATOR = '~';
const LABEL_GROUP_MARKER = '-';

//  This is a list of all possible roles that a user can have.
export const allRoles = [
  'owner',
  'admin',
  'read_only',
  'ruleset_manager',
  'limited_ruleset_manager',
  'ruleset_provisioner',
  'ruleset_viewer',
  'global_object_provisioner',
  'workload_manager',
];
export const allRolesSet = new Set(allRoles);

//  This is a list of the possible global roles. global roles are roles which must have all all all scope.
export const globalRoles = __ANTMAN__
  ? ['owner', 'admin', 'ruleset_manager', 'ruleset_provisioner', 'global_object_provisioner', 'read_only']
  : ['owner', 'admin', 'read_only', 'global_object_provisioner'];
export const globalRolesSet = new Set(globalRoles);

//  This is a list of all scoped roles which can allow all all all for their scope.
export const scopedAllRoles = ['ruleset_manager', 'ruleset_provisioner', 'ruleset_viewer', 'workload_manager'];
export const scopedAllRolesSet = new Set(scopedAllRoles);

// This is used to handle change for a selector to determine if value is chosen. This is default order
// for scope. Please don't change the order.
export const labelsSelectorValues = ['app', 'env', 'loc'];

export const allScopeIdString = '[]';

// This is a mapping for the labels
export const allLabelDataMap = createSelector([], () => ({
  app: {
    categoryKey: 'all_applications',
    value: intl('Common.AllApplications'),
  },
  env: {
    categoryKey: 'all_environments',
    value: intl('Common.AllEnvironments'),
  },
  loc: {
    categoryKey: 'all_locations',
    value: intl('Common.AllLocations'),
  },
}));

//  This object maps role names to their intl strings
export const roleStrings = createSelector([], () => ({
  read_only: intl('Role.GlobalReadOnly'),
  admin: intl('Role.GlobalAdmin'),
  owner: intl('Role.GlobalOrgOwner'),
  ruleset_manager: intl('Role.RulesetManager'),
  limited_ruleset_manager: intl('Role.LimitedRulesetManager'),
  ruleset_provisioner: intl('Role.RulesetProvisioner'),
  ruleset_viewer: intl('Role.RulesetViewer'),
  global_object_provisioner: intl('Role.GlobalPolicyObjectProvisioner'),
  workload_manager: intl('Role.WorkloadManager'),
}));

export const globalRolesArray = [
  intl('Role.GlobalReadOnly'),
  intl('Role.GlobalAdmin'),
  intl('Role.GlobalOrgOwner'),
  intl('Role.GlobalPolicyObjectProvisioner'),
];

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

//Regex to test if value is number and not string
const regexId = /^\d+$/;

export const getNumber = id => {
  // return number of bigInt. (detect string if it is number of BigInt)
  // if Number return number, if BigInt return bigInt
  if (id && regexId.test(id)) {
    return JSONBigIntNative.parse(id);
  }
};

export const getLabelObject = label => label.label || label.label_group || label;
export const getLabelObjects = scope => (Array.isArray(scope) ? scope.map(label => getLabelObject(label)) : []);
export const getScopeAll = () => [];

export const roleSortValues = allRoles.reduce((roleSortValues, role) => {
  if (!(role in roleSortValues)) {
    roleSortValues[role] = Object.values(roleStrings()).sort().indexOf(roleStrings()[role]);
  }

  return roleSortValues;
}, {});

/*
 *  This is a compare function used to sort lists of roles by the order given in allRoles
 */
export const roleSort = (a, b) => {
  if (allRoles.indexOf(a) > allRoles.indexOf(b)) {
    return 1;
  }

  if (allRoles.indexOf(a) < allRoles.indexOf(b)) {
    return -1;
  }

  if (allRoles.indexOf(a) === allRoles.indexOf(b)) {
    return 0;
  }
};

export const getRolesString = roles =>
  roles
    .sort(roleSort)
    .map(role => roleStrings()[role])
    .join(', ');

export const getScopeHrefsFromId = (orgId, scopeId, labelsMap) =>
  Array.isArray(scopeId)
    ? scopeId.reduce((res, labelObject) => {
        if (res === 'invalid' || !_.isPlainObject(labelObject) || !labelObject.id) {
          return 'invalid';
        }

        const {type: labelType, id: labelId} = labelObject;

        if (labelType && labelId && ['labels', 'label_groups'].includes(labelType)) {
          const isLabel = labelType === 'labels';
          const method = isLabel ? 'labels.get_instance' : 'label_groups.get_instance';
          const params = {
            ...(isLabel && {label_id: labelId}),
            ...(!isLabel && {pversion: 'active', label_group_id: labelId}),
          };

          const labelHref = getMethodParameterizedPath({orgId, params, method});

          if (labelsMap && !labelsMap[labelHref]) {
            return 'invalid';
          }

          res.push(labelHref);
        } else {
          return 'invalid';
        }

        return res;
      }, [])
    : 'invalid';

export const getScopeIdString = scopeId =>
  Array.isArray(scopeId)
    ? scopeId.length
      ? _.orderBy(scopeId, 'id').reduce((result, {type, id}) => `${result}${result ? '!' : ''}${type}.${id}`, '')
      : allScopeIdString
    : '';

/*
 *  This function takes a scope list and converts it to the scopd id format used for RBAC pages
 *  The format is label id # for any specified label, or all for any unspecified labels, joined by '-'
 *  i.e. if scope had app label id = 2, env label id = 3 and no loc label, the returned scopeId would be:
 *  2-3-all. labelsMap is not required when scope has the value and key properties of the labels
 */
export const getScopeId = scope => {
  const labelIds = getLabelObjects(scope).reduce((res, label) => {
    if (label?.key && label?.href) {
      const type = label.href.includes('label_groups') ? 'label_groups' : 'labels';

      // Use label.key for mapping
      return {...res, [label.key]: {id: hrefUtils.getId(label.href), type}};
    }

    return res;
  }, {});

  return Object.entries(labelIds).map(([key, {id, type}]) => ({key, id, type}));
};

export const encodeScopeId = scopeId =>
  scopeId.length
    ? scopeId.reduce((result, item) => {
        const {id, type: labelType} = item;

        if (id && labelType) {
          result = `${result === '' ? '' : `${result}${LABEL_DELIMINATOR}`}${
            labelType === 'label_groups' ? '-' : ''
          }${id}`;
        }

        return result;
      }, '')
    : '[]';

export const decodeScopeId = id => {
  if (typeof id !== 'string') {
    return;
  }

  if (id === '[]') {
    return [];
  }

  const labels = id.split(LABEL_DELIMINATOR);
  const scopeId = labels.map(str => {
    if (str === '') {
      return {};
    }

    const isLabelGroup = str.startsWith(LABEL_GROUP_MARKER);

    return {
      id: isLabelGroup ? str.substring(1) : str,
      type: isLabelGroup ? 'label_groups' : 'labels',
    };
  });

  return scopeId;
};

export const getScopeIdStringFromScope = scope => getScopeIdString(getScopeId(scope));

export const getAuthSecPrincipalCountStrings = (localUserCount, externalUserCount, externalGroupCount) => {
  const finalCountString = [];

  const localUserString = intl('RBAC.PrincipalsTypeCount.LocalUsers', {count: localUserCount});
  const externalUserString = intl('RBAC.PrincipalsTypeCount.ExternalUsers', {count: externalUserCount});
  const externalGroupString = intl('RBAC.PrincipalsTypeCount.ExternalGroups', {count: externalGroupCount});

  if (localUserCount > 0) {
    finalCountString.push(localUserString);
  }

  if (externalUserCount > 0) {
    finalCountString.push(externalUserString);
  }

  if (externalGroupCount > 0) {
    finalCountString.push(externalGroupString);
  }

  return {
    localUserString,
    externalUserString,
    externalGroupString,
    authSecPrincipalString: finalCountString.join(', '),
  };
};

export const getRoleHref = (orgId, roleName) =>
  getMethodParameterizedPath({
    orgId,
    params: {role_name: roleName},
    method: 'roles.get_instance',
  });

export const getRoleHrefs = (roles, orgId) => roles.map(role => getRoleHref(orgId, role));

/*
 *  This function takes a list of authSecPrincipalHrefs and returns different counts / formatted strings based on the
 *  type (user, group) and usertype (local, external) of the input authSecPrincipals
 */
export const getAuthSecPrincipalBreakdown = (authSecPrincipalHrefs, authSecPrincipalsMap) => {
  const breakdown = authSecPrincipalHrefs.reduce(
    (res, authSecPrincipalHref) => {
      const authSecPrincipal = authSecPrincipalsMap[authSecPrincipalHref];

      res.authSecPrincipalNames.add(authSecPrincipal.name);

      if (authSecPrincipal.type === 'user') {
        res.userCount += 1;

        if (authSecPrincipal.usertype === 'local') {
          res.localUserCount += 1;
          res.localUserAuthSecPrincipalHrefs.add(authSecPrincipalHref);
          res.localUserNames.add(authSecPrincipal.name);
        } else if (authSecPrincipal.usertype === 'external') {
          res.externalUserCount += 1;
          res.externalUserAuthSecPrincipalHrefs.add(authSecPrincipalHref);
          res.externalUserNames.add(authSecPrincipal.name);
        }
      } else if (authSecPrincipal.type === 'group') {
        res.externalGroupCount += 1;
        res.externalGroupAuthSecPrincipalHrefs.add(authSecPrincipalHref);
        res.externalGroupNames.add(authSecPrincipal.name);
      }

      return res;
    },
    {
      localUserCount: 0,
      externalUserCount: 0,
      externalGroupCount: 0,
      userCount: 0,
      localUserNames: new Set(),
      externalUserNames: new Set(),
      externalGroupNames: new Set(),
      authSecPrincipalNames: new Set(),
      localUserAuthSecPrincipalHrefs: new Set(),
      externalUserAuthSecPrincipalHrefs: new Set(),
      externalGroupAuthSecPrincipalHrefs: new Set(),
    },
  );

  return {
    ...breakdown,
    totalAuthSecPrincipalCount: authSecPrincipalHrefs.length,
    ...getAuthSecPrincipalCountStrings(
      breakdown.localUserCount,
      breakdown.externalUserCount,
      breakdown.externalGroupCount,
    ),
  };
};

export const getPermissionBreakdown = (permissionHrefs, permissionsMap, authSecPrincipalsMap) => {
  const breakdown = permissionHrefs.reduce(
    (res, permissionHref) => {
      const permission = permissionsMap[permissionHref];

      res.scopeIds.add(permission.scopeId);
      // scopeHrefs are arrays of labelHrefs. We join the scopeHrefs into a string so that duplicates won't be added to the set.
      // in the return for getPermissionBreakdown, we will convert the unique set of scopeHrefs strings back into scopeHrefs arrays.
      res.stringifiedScopeHrefs.add(JSON.stringify(permission.scopeHrefs));
      res.authSecPrincipalHrefs.add(permission.authSecPrincipalHref);
      res.roles.add(permission.role);
      res.roleHrefs.add(permission.roleHref);
      res.roleCounts[permission.role] += 1;
      res.scope = getLabelObjects(permission.scope);

      if (globalRolesSet.has(permission.role)) {
        res.globalRoles.add(permission.role);
        res.globalRoleHrefs.add(permission.roleHref);
      } else {
        res.scopedRoles.add(permission.role);
        res.scopedRoleHrefs.add(permission.roleHref);
      }

      return res;
    },
    {
      scopeIds: new Set(),
      stringifiedScopeHrefs: new Set(),
      authSecPrincipalHrefs: new Set(),
      roles: new Set(),
      roleHrefs: new Set(),
      globalRoles: new Set(),
      globalRoleHrefs: new Set(),
      scopedRoles: new Set(),
      scopedRoleHrefs: new Set(),
      roleCounts: {
        ...allRoles.reduce((res, role) => ({...res, [role]: 0}), {}), // a count for each individual role name
      },
      scope: [],
    },
  );

  return {
    ...breakdown,
    scopeHrefs: [...breakdown.stringifiedScopeHrefs].map(stringifiedScopeHrefs => JSON.parse(stringifiedScopeHrefs)),
    rolesString: getRolesString([...breakdown.roles]),
    ...getAuthSecPrincipalBreakdown([...breakdown.authSecPrincipalHrefs], authSecPrincipalsMap),
  };
};

/*
 * Sort roles in alphabetical order. 'Global Read Only' role will be placed first
 * in the list.
 */
export const sortRoles = roles => {
  if (!Array.isArray(roles)) {
    return roles;
  }

  return [...roles].sort((a, b) =>
    (a > b && a !== intl('Role.GlobalReadOnly')) || (a < b && b === intl('Role.GlobalReadOnly')) ? 1 : -1,
  );
};

export const sortRolesHref = row => {
  if (!Array.isArray(row.roles)) {
    return row.roles;
  }

  const roleWithHref = row.roles.map((role, index) => {
    if (globalRolesArray.includes(role)) {
      return {label: role, href: row.roleHrefs[index].href};
    }

    return {label: role};
  });

  return roleWithHref.sort((a, b) =>
    (a.label > b.label && a.label !== intl('Role.GlobalReadOnly')) ||
    (a.label < b.label && b.label === intl('Role.GlobalReadOnly'))
      ? 1
      : -1,
  );
};

export const getRolesLink = roles => {
  return roles.reduce((result, role, index) => {
    if (role.href) {
      result.push(
        <Link to="rbac.roles.global.detail" params={{id: hrefUtils.getId(role.href)}}>
          {role.label}
        </Link>,
      );
    } else {
      result.push(role.label);
    }

    if (index < roles.length - 1) {
      result.push(<span>, </span>);
    }

    return result;
  }, []);
};

export const formatRoles = roles => {
  if (!Array.isArray(roles)) {
    return roles;
  }

  return roles.map(role => role.label).join(', ');
};

export const getRoleFromHref = href => hrefUtils.getId(href);
export const getFormattedRoleFromHref = href => roleStrings()[getRoleFromHref(href)];
export const isGlobalRoleHref = href => globalRoles.includes(getRoleFromHref(href));
export const isHrefLabelGroups = href => href?.includes('label_groups');
export const areScopesEqual = (src, dest) =>
  generalUtils.sortAndStringifyArray(src, ['key']) === generalUtils.sortAndStringifyArray(dest, ['key']);
export const getFormattedUserActivityRole = (href, isDefaultReadOnly) =>
  isDefaultReadOnly ? intl('RBAC.DefaultReadOnly') : roleStrings()[getRoleFromHref(href)];
export const getFormattedScope = scope =>
  _.orderBy(
    scope.map(({href, key, value, name}) => ({href, key, value: value ?? name})),
    'key',
  );
export const getRolesFromPermissions = permissions =>
  permissions.reduce((result, permission) => {
    const isGlobalRole = isGlobalRoleHref(permission.role.href);
    const scope = getLabelObjects(permission.scope);
    const foundScope = result.find(
      item => areScopesEqual(item.scope, scope) && isGlobalRole === (item.type === 'global'),
    );
    const role = getFormattedUserActivityRole(permission.role.href);

    if (foundScope) {
      // Don't append the same role
      if (!foundScope.roles.includes(role)) {
        foundScope.roles.push(role);
      }
    } else {
      const type = isGlobalRole ? 'global' : 'scoped';

      result.push({
        key: `${type}${getScopeIdStringFromScope(scope)}`,
        type,
        scope,
        data: {
          labelsMap: getMappedLabelsForGrid(scope),
        },
        roles: [role],
      });
    }

    return result;
  }, []);

export const getScopeValue = (scope = []) =>
  scope
    .reduce(
      (res, scopeLabel) => {
        if (scopeLabel.key === 'app') {
          res[0] = scopeLabel.value;
        } else if (scopeLabel.key === 'env') {
          res[1] = scopeLabel.value;
        } else if (scopeLabel.key === 'loc') {
          res[2] = scopeLabel.value;
        }

        return res;
      },
      [intl('Common.All'), intl('Common.All'), intl('Common.All')],
    )
    .join(' | ');

/** Fill user details (username, full_name)
 *  @param {Object} obj :user object with href prop
 *  @param {Map<any, any>} usersMap :user list
 */
export const fillUserInfo = (usersMap = new Map(), userObj, {key = 'href'} = {}) => {
  let result = {id: -1, full_name: 'Unknown', username: 'Unknown'};

  if (userObj) {
    const user = usersMap.get(userObj[key]);

    if (user) {
      result = {id: user.id, href: user.href, full_name: user.full_name, username: user.username, type: user.type};
    } else if (userObj.href === '/users/0') {
      result = {...userObj, id: 0, full_name: 'System', username: 'System'};
    } else if (hrefUtils.isContainerClusterHref(userObj.href)) {
      // Usually, it is a user object with href.
      // With the container work, objects can be provisioned by Kubelink.
      // For this special case, 'Container Cluster' is displayed, instead of Unknown.
      result = {
        id: -1,
        href: userObj.href,
        full_name: intl('Menu.ContainerClusters', {multiple: false}),
        username: intl('Menu.ContainerClusters', {multiple: false}),
      };
    } else if (hrefUtils.isServiceAccountHref(userObj.href)) {
      result = {
        id: hrefUtils.getId(userObj.href),
        href: userObj.href,
        type: 'serviceAccount',
        full_name: userObj.name,
        username: userObj.name,
      };
    } else {
      result = {...userObj, id: -1, full_name: 'Unknown', username: 'Unknown'};
    }
  }

  return result;
};

export const getLabelsQueryParam = scopeHrefs =>
  Array.isArray(scopeHrefs)
    ? scopeHrefs.map(href => ({...(isHrefLabelGroups(href) ? {label_group: {href}} : {label: {href}})}))
    : '';
export const formatErrorData = errorData =>
  (errorData?.token && intl(`ErrorsAPI.err:${errorData.token}`)) || errorData?.message;
export const getLabelsHrefs = labels => labels.map(label => label.href);
export const getScopeLabelPills = (labels = []) =>
  Array.isArray(labels) ? (
    labels.length ? (
      labels.map(label => (
        <Pill.Label key={label.key} type={label.key} group={isHrefLabelGroups(label.href)} href={label.href}>
          {label.value || label.name}
        </Pill.Label>
      ))
    ) : (
      <Pill.Label all />
    )
  ) : (
    ''
  );

export const getStyledScopeLabelPills = value => (
  <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapHorizontalWrap}`}>{getScopeLabelPills(value)}</div>
);
export const scopeColumn = {
  header: intl('Common.Scopes'),
  columns: {
    role: {
      noPadding: true,
      header: intl('Common.Role'),
      ...GridUtils.clickableLabelColumn,
      value: ({row}) => row.labels.role,
    },
    app: {
      noPadding: true,
      header: intl('Common.Application'),
      ...GridUtils.clickableLabelColumn,
      value: ({row}) => row.labels.app,
    },
    env: {
      noPadding: true,
      header: intl('Common.Environment'),
      ...GridUtils.clickableLabelColumn,
      value: ({row}) => row.labels.env,
    },
    loc: {
      noPadding: true,
      header: intl('Common.Location'),
      ...GridUtils.clickableLabelColumn,
      value: ({row}) => row.labels.loc,
    },
    all: {
      noPadding: true,
      header: intl('Common.All'),
      value: ({row}) => !row.labels.loc && !row.labels.role && !row.labels.app && !row.labels.env,
      format: ({value}) => value && <Pill.Label all />,
    },
  },
  horizontal: true,
  templates: ['all', 'role', 'app', 'env', 'loc'],
};

export const populateAuthSecPrincipalCategory = ({id, name, filterOption, query, allowPartial = true}) => ({
  id,
  name,
  resources: {
    [id]: {
      *dataProvider({query}) {
        const {data} = yield call(apiSaga, 'org_auth_security_principals.get_collection', {query});

        return sortOptions(data?.filter(filterOption ?? (() => true)).map(({name}) => name ?? '') ?? [], query.name);
      },
      apiArgs: {
        query: {
          getQuery: query => ({name: query}),
          type: 'user',
          ...query,
        },
      },
      allowPartial,
      selectedProps: {hideResourceName: true},
    },
  },
});
