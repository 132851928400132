/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {eventUtils} from 'utils';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';
import {formatGeneratedBy} from '../EventsUtils';
import {fetchAllUsers} from 'containers/User/UserSagas';
import stylesUtils from 'utils.css';
import {DateTimeCustomPicker} from 'components';
import {populateAutoCompleteCategory} from 'containers/Selector/GridFilter/GridFilterUtils';
import {call} from 'typed-redux-saga';
import {getSearchResult, prepareSearchIndex} from 'containers/Selector/SelectorUtils';
import {getEventTokenMap} from 'containers/Events/List/EventsListUtils';

const maxResults = 25;
const fromPresets = [
  {name: 'anytime', label: 'Anytime', value: null},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const toPresets = [
  {name: 'now', label: 'Now', value: new Date()},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

export const filterPresets = [
  {id: 'failure', value: intl('Common.Failure'), label: intl('Common.Status')},
  {id: 'warning', value: intl('Common.Warning'), label: intl('Common.Severity')},
  {id: 'err', value: intl('Common.Error'), label: intl('Common.Severity')},
  {id: 'timestamp', value: intl('Common.Today'), label: intl('Common.Timestamp')},
];

const populateDateTimePickerResources = id => ({
  [id]: {
    type: 'container',
    container: DateTimeCustomPicker,
    containerProps: {
      noBorder: true,
      fromPresets,
      toPresets,
      getContainerProps: ({onDone, onCancel}) => ({
        onClose: onCancel,
        onSave: onDone,
      }),
    },
    enableFocusLock: true,
  },
});

const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

const events = getEventTokenMap(eventUtils.getSortedEventTypes());

const eventTypes = Object.keys(events).map(key => {
  const event = events[key];

  return {value: event.value, desc: event.desc};
});

let fetchedUsers;
let searchIndex;

export const categories = createSelector([], () => [
  {
    id: 'event_type',
    name: intl('Common.Event'),
    resources: {
      event_type: {
        statics: eventTypes,
        optionProps: {
          format: ({option}) => {
            return (
              <div>
                {option.value}
                <div className={stylesUtils.secondary}>{option.desc}</div>
              </div>
            );
          },
        },
      },
    },
  },
  {
    id: 'severity',
    name: intl('Common.Severity'),
    resources: {
      severity: {
        statics: [
          {id: 'err', value: intl('Common.Error')},
          {id: 'warning', value: intl('Common.Warning')},
          {id: 'info', value: intl('SystemSettings.LogForwarding.Severity.Info')},
        ],
      },
    },
  },
  {
    id: 'status',
    name: intl('Common.Status'),
    resources: {
      status: {
        statics: [
          {id: 'success', value: intl('Common.Success')},
          {id: 'failure', value: intl('Common.Failure')},
          {id: 'nil', value: intl('Common.NA')},
        ],
      },
    },
  },
  {
    id: 'timestamp',
    name: intl('Common.Timestamp'),
    resources: populateDateTimePickerResources('timestamp'),
  },
  {
    id: 'created_by',
    name: intl('Common.GeneratedByUser'),
    resources: {
      created_by: {
        *statics({query}) {
          const data = yield call(fetchAllUsers);

          const userNames = data.map(obj => obj.username);

          if (!userNames.includes('anonymous')) {
            data.push({href: '/users/-1', username: 'anonymous'});
          }

          if (!userNames.includes('System')) {
            data.push({href: 'system', username: 'System'});
          }

          const uniqueUsersSet = new Set(data);
          const uniqueUsersArray = Array.from(uniqueUsersSet);

          if (!_.isEqual(uniqueUsersArray, fetchedUsers)) {
            fetchedUsers = uniqueUsersArray;
            searchIndex = prepareSearchIndex({
              options: uniqueUsersArray,
              textPath: 'username',
              store: true,
            });
          }

          return (
            (query
              ? getSearchResult(searchIndex, query, {enrich: true})
              : fetchedUsers.sort((a, b) => collator.compare(a.value, b.value))
            )?.slice(0, maxResults) ?? []
          );
        },
        optionProps: {
          textPath: 'username',
        },
      },
    },
  },
  populateAutoCompleteCategory({id: 'vens', name: intl('Common.GeneratedByAgent'), resourceType: 'vens'}),
]);

/**
 [{
  header: string | Function,

  cellKey: string | Function,
  cellValue: string | Function,
  cellFormat: Function,
  sortValue: Function,
  unsortable: boolean,
  isDate: boolean | string,
}];
 */

export const gridSettings = createSelector([], () => ({
  id: 'eventlist',
  sort: '-timestamp',
  sortedNaturallyBy: '-timestamp',
  capacities: [25, 50, 100, 250, 500, 1000],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    event: {
      linky: true,
      header: intl('Common.Event'),
      value: ({row}) => row.data.event_type,
    },
    description: {
      header: intl('Common.Description'),
      value: ({row}) => eventUtils.getEventType(row.data.event_type, true),
    },
    severity: {
      header: intl('Common.Severity'),
      value: ({row}) => eventUtils.getEventSeverity(row.data.severity),
    },
    state: {
      header: intl('Common.Status'),
      value: ({row}) => eventUtils.getEventStatusLabel(row.data.status) || intl('Common.NA'),
    },
    timestamp: {
      header: intl('Common.Timestamp'),
      isDate: 'L_HH_mm_ss',
      value: 'timestamp',
    },
    generatedBy: GridUtils.clickableColumn({
      header: intl('Common.GeneratedBy'),
      value: ({row}) => row.data.hostname ?? eventUtils.getEventGeneratedBy(row.data.created_by),
      format: ({row, clickableRef}) => row.data.hostname ?? formatGeneratedBy(row.data.created_by, {ref: clickableRef}),
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to cellFormat function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to cellFormat function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to cellFormat function
    data: ?object,
  }];
   */

  templates: [
    [
      {columns: ['event'], size: 'minmax(120px, auto)'},
      {columns: ['description'], size: 'minmax(120px, auto)'},
      {columns: ['severity'], size: 'minmax(120px, auto)'},
      {columns: ['state'], size: 'minmax(80px, auto)'},
      {columns: ['timestamp'], size: 'minmax(120px, auto)'},
      {columns: ['generatedBy'], size: 'minmax(120px, auto)'},
    ],
    {
      maxWidth: 1366,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event'], size: 'minmax(100px, auto)'},
            {columns: ['description'], size: 'minmax(100px, auto)'},
            {columns: ['severity'], size: 'minmax(100px, auto)'},
            {columns: ['state'], size: 'minmax(70px, auto)'},
            {columns: ['timestamp'], size: 'minmax(100px, auto)'},
            {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event'], size: 'minmax(100px, auto)'},
          {columns: ['description'], size: 'minmax(100px, auto)'},
          {columns: ['severity'], size: 'minmax(100px, auto)'},
          {columns: ['state'], size: 'minmax(70px, auto)'},
          {columns: ['timestamp'], size: 'minmax(100px, auto)'},
          {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event'], size: 'minmax(100px, auto)'},
            {columns: ['description'], size: 'minmax(100px, auto)'},
            {columns: ['severity'], size: 'minmax(100px, auto)'},
            {columns: ['state'], size: 'minmax(70px, auto)'},
            {columns: ['timestamp'], size: 'minmax(100px, auto)'},
            {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event'], size: 'minmax(100px, auto)'},
          {columns: ['description'], size: 'minmax(100px, auto)'},
          {columns: ['severity'], size: 'minmax(100px, auto)'},
          {columns: ['state'], size: 'minmax(70px, auto)'},
          {columns: ['timestamp'], size: 'minmax(100px, auto)'},
          {columns: ['generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event', 'description'], size: 'minmax(120px, auto)'},
            {columns: ['severity', 'state'], size: 'minmax(100px, auto)'},
            {columns: ['timestamp', 'generatedBy'], size: 'minmax(120px, auto)'},
          ];
        }

        return [
          {columns: ['event', 'description'], size: 'minmax(120px, auto)'},
          {columns: ['severity', 'state'], size: 'minmax(100px, auto)'},
          {columns: ['timestamp', 'generatedBy'], size: 'minmax(120px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event', 'description'], size: 'minmax(100px, auto)'},
            {columns: ['severity', 'state'], size: 'minmax(90px, auto)'},
            {columns: ['timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event', 'description'], size: 'minmax(100px, auto)'},
          {columns: ['severity', 'state'], size: 'minmax(90px, auto)'},
          {columns: ['timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['event', 'description', 'severity'], size: 'minmax(100px, auto)'},
            {columns: ['state', 'timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['event', 'description', 'severity'], size: 'minmax(100px, auto)'},
          {columns: ['state', 'timestamp', 'generatedBy'], size: 'minmax(100px, auto)'},
        ];
      },
    },
  ],
}));
