/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';

export const networkTypesList = [
  [intl('Rulesets.Rules.CorporateNetwork'), 'brn'],
  [intl('Rulesets.Rules.NonCorporateNetworks'), 'non_brn'],
  [intl('Rulesets.Rules.AllNetworks'), 'all'],
];

export const networkDataCenterList = createSelector([], () => ({
  link_local: intl('Network.LinkLocal'),
  Corporate: intl('Common.Corporate'),
}));

// array of UI display values for network types
export const networkTypes = networkTypesList.map(([type]) => type);

// JS Map with KV pairs for: display value to api values, and api values to display values
export const networkTypeMap = new Map([...networkTypesList, ...networkTypesList.map(type => [...type].reverse())]);

// used in OptionSelector component
export const networkTypesOptionObject = networkTypesList.map(([label, value]) => ({label, value}));

// object for selectors
export const networkTypeMenuOptions = {
  network_type: networkTypes,
};
