/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {whenPropTypes} from '../FormUtils';
import FormInput from '../Input/FormInput';

FormTextarea.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  ...whenPropTypes,
};

function FormTextarea(props) {
  return <FormInput type="textarea" {...props} />;
}

export default FormTextarea;
