/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import type {ConnectionQuickFilterValue} from './MapQuickFilter';

/**
 * Returns a set containing all items that are different between two sets.
 * @param a {Set}
 * @param b {Set}
 */
export const getDiffBetweenSets = (a: Set<unknown>, b: Set<unknown>): Set<unknown> => {
  const all = new Set([...a, ...b]);
  const diff = new Set<unknown>();

  for (const item of all) {
    if (!(a.has(item) && b.has(item))) {
      diff.add(item);
    }
  }

  return diff;
};

/**
 * Returns true if any of the "global quick filters" changed. Returns false if only "selected group filters" changed.
 * @param oldSelections
 * @param newSelections
 */
export const didAnyGlobalQuickFiltersChange = (
  oldSelections: Set<ConnectionQuickFilterValue>,
  newSelections: Set<ConnectionQuickFilterValue>,
): boolean => {
  const diff = getDiffBetweenSets(oldSelections, newSelections) as Set<ConnectionQuickFilterValue>;

  return [...diff].some(item => !['inbound', 'outbound', 'intrascope'].includes(item));
};
