/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {
  formatCVEIDs,
  formatEWExposure,
  formatVulnerabilityScore,
  formatWideExposure,
  formatVEScore,
  sortVEScore,
} from 'containers/IlluminationMap/Panels/Vulnerabilities/MapPanelVulnerabilitiesUtils';
import {formatProProtocol} from 'containers/Workload/Item/Vulnerabilities/WorkloadVulnerabilitiesUtils';
import intl from '@illumio-shared/utils/intl';

export const gridConfig = {
  id: 'vulnerabilities',
  sort: '-exposurescore',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    exposurescore: {
      header: intl('Vulnerability.VEScore'),
      value: ({row}) => row.vulnerabilityExposureScore,
      format: ({row}) => formatVEScore(row),
      sortFunction: sortVEScore,
    },
    score: {
      header: intl('Vulnerability.Score'),
      value: ({row}) => row.severity,
      format: ({value}) => formatVulnerabilityScore(value),
    },
    portexposure: {
      header: intl('Vulnerability.EWExposure'),
      value: ({row}) => formatEWExposure(row),
    },
    wideexposure: {
      header: intl('Vulnerability.InternetExposure'),
      value: ({row}) => formatWideExposure(row),
    },
    workloads: {
      header: intl('Common.Workloads'),
      value: ({row}) => row.numWorkloads,
    },
    port: {
      header: intl('Port.Protocol'),
      value: ({row}) => formatProProtocol(row),
    },
    cveIds: {
      header: intl('Vulnerability.CVEIds'),
      value: ({row}) => formatCVEIDs(row),
    },
    name: {
      header: intl('Common.Name'),
      value: ({row}) => row.details.name,
    },
  },

  templates: [
    [
      {columns: ['exposurescore'], size: 'minmax(60px, auto)'},
      {columns: ['score'], size: 'minmax(80px, auto)'},
      {columns: ['portexposure'], size: 'minmax(80px, auto)'},
      {columns: ['wideexposure'], size: 'minmax(80px, auto)'},
      {columns: ['workloads'], size: 'minmax(80px, auto)'},
      {columns: ['port'], size: 'minmax(80px, auto)'},
      {columns: ['cveIds'], size: 'minmax(100px, auto)'},
      {columns: ['name'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 640,
      template() {
        return [
          {columns: ['exposurescore', 'score', 'portexposure', 'wideexposure'], size: 'minmax(200px, auto)'},
          {columns: ['workloads', 'port', 'cveIds', 'name'], size: 'minmax(200px, auto)'},
        ];
      },
    },
  ],
};
