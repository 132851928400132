/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {useCallback, useLayoutEffect} from 'react';
import OptionPanel from '../OptionPanel';
import Option from 'containers/Selector/Option';
import {categorySuggestionRegex} from '../SelectorUtils';
import {useFilialPiety, getHighlightedText} from '../SelectorFormatUtils';

export default function Category(props) {
  const {
    query,
    saveRef,
    pathArr,
    category,
    category: {id, name, format, displayResourceAsCategory, hidden, sticky, disabled, tooltip, tooltipProps},
    active,
    onClick,
    theme,
    onKeyDown,
    registerHandlers,
    highlighted,
    onMouseOver,
    ...optionPanelProps
  } = props;

  const {saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild, keyDown} = useFilialPiety();

  const saveRefCallback = useCallback(element => saveRef(id, element), [id, saveRef]);

  useLayoutEffect(() => {
    if (displayResourceAsCategory) {
      registerHandlers(id, {setHighlightedChild, keyDown, resetHighlightedChild});
    }
  }, [displayResourceAsCategory, id, registerHandlers, keyDown, setHighlightedChild, resetHighlightedChild]);

  if (hidden || sticky) {
    return null;
  }

  if (!displayResourceAsCategory) {
    // Category hint text i.e. Substring matching query are highlighted with underline
    // Categories are searched only when "in" keyword is added in the query string and hint text is added after "in" keyword
    // split the query to get hint text
    const categoryHintText = categorySuggestionRegex.test(query)
      ? query.split(categorySuggestionRegex)[2].trimStart()
      : '';

    return (
      <Option
        saveRef={saveRef}
        tid="comp-selector-category"
        theme={theme}
        isCategory
        id={id}
        text={format?.(category) ?? getHighlightedText({query: categoryHintText, text: name})}
        insensitive={props.insensitive}
        disabled={disabled}
        active={active}
        highlighted={highlighted}
        tooltip={tooltip}
        tooltipProps={tooltipProps}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={props.onMouseLeave}
      />
    );
  }

  Object.assign(optionPanelProps, {
    saveRef: saveChildRef,
    pathArr: [...pathArr, category.id],
    theme,
    registerHandlers: registerChildHandlers,
    category,
    query,
  });

  return (
    <div ref={saveRefCallback} data-tid="comp-selector-category">
      <OptionPanel {...optionPanelProps} />
    </div>
  );
}
