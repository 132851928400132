/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {useCallback} from 'react';
import {domUtils} from '@illumio-shared/utils';
import MenuItem, {type MenuItemProps} from '../MenuItem';

export type ContextualMenuCopyProps = domUtils.CopyToClipboardWithCallbackProps & MenuItemProps;

export default function MenuItemCopy(props: Readonly<ContextualMenuCopyProps>): JSX.Element {
  const {content, contentType, onSuccess, onError, children, ...itemProps} = props;

  const handleClick = useCallback(() => {
    (contentType ? domUtils.copyToClipboard(content, contentType) : domUtils.copyToClipboard(content)).then(
      onSuccess,
      error => {
        if (onError) {
          onError(error);
        } else {
          console.error(`Error while copying '${content}' with type '${contentType}' to the Clipboard`, error);
        }
      },
    );
  }, [content, contentType, onSuccess, onError]);

  // Specify defaults for some Menu props, can be overridden by parent
  itemProps.tid ??= 'copy';
  itemProps.icon ??= 'copy';
  itemProps.text ??= intl('Common.Copy');
  itemProps.onSelect ??= handleClick;

  return <MenuItem {...itemProps} />;
}
