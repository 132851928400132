/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

export const bannedParameters = [
  '-t',
  '--table',
  '-A',
  '--append',
  '-C',
  '--check',
  '-D',
  '--delete',
  '-I',
  '--insert',
  '-R',
  '--replace',
  '-L',
  '--list',
  '-F',
  '--flush',
  '-Z',
  '--zero',
  '-N',
  '--new-chain',
  '-X',
  '--delete-chain',
  '-P',
  '--policy',
  '-E',
  '--rename-chain',
  '-S',
  '--list-rules',
  '-h',
  '-g',
  '--goto',
  '-v',
  '--verbose',
  '-n',
  '--numeric',
  '-x',
  '--exact',
  '-c',
  '--set-counters',
  '-w',
  '--wait',
  '--line-numbers',
  '--modprobe',
  '-m set',
  '--match-set',
  '--add-set',
  '--del-set',
];

export const bannedChainNames = [
  'usr_nat_prerouting',
  'usr_nat_output',
  'usr_nat_postrouting',
  'mark_nat_t_in',
  'usr_mangle_input',
  'usr_mangle_output',
  'usr_mangle_forward',
  'usr_mangle_prerouting',
  'usr_mangle_postrouting',
  'pre_out',
  'other_allow_out',
  'tcp_allow_out',
  'test_out',
  'tcp_test_out',
  'other_test_out',
  'udp_allow_out',
  'drop_out',
  'tcp_drop_out',
  'udp_drop_out',
  'icmp_drop_out',
  'other_drop_out',
  'icmp_allow_out',
  'pre_in',
  'pkt_check_in',
  'tcp_check_in',
  'tcp_allow_in',
  'test_in',
  'tcp_test_in',
  'other_test_in',
  'udp_allow_in',
  'icmp_allow_in',
  'other_allow_in',
  'drop_in',
  'tcp_drop_in',
  'udp_drop_in',
  'icmp_drop_in',
  'other_drop_in',
  'ike_in',
  'nat_t_in',
  'ike_out',
  'nat_t_out',
  'usr_filter_input',
  'usr_filter_forward',
  'usr_filter_output',
];

export const bannedTokens = ['#', '|', '"', '`', '\\', ';', '$', '&', '{', '}', '[', ']'];
