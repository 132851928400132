/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Icon, Pill, Tooltip} from 'components';
import styles from './Profile/Detail/ContainerClusterContainerWorkloadProfileDetail.css';

export const managementOptions = [
  {label: intl('Common.Managed'), value: 'true', subLabel: intl('Settings.Containers.ManagedDesc')},
  {label: intl('Common.Unmanaged'), value: 'false', subLabel: intl('Settings.Containers.UnmanagedDesc')},
];

export const managementView = managed => {
  if (managed) {
    // Managed
    return {
      name: intl('Common.Managed'),
      desc: intl('Settings.Containers.ManagedDesc'),
    };
  }

  // Unmanaged
  return {
    name: intl('Common.Unmanaged'),
    desc: intl('Settings.Containers.UnmanagedDesc'),
  };
};

export const inferLabelSource = (type, labels) => {
  const labelsByType = labels?.filter(label => label.key === type);

  if (labelsByType?.length) {
    if (labelsByType[0].hasOwnProperty('assignment')) {
      return 'assign'; // if there is an assigned label, mode is 'assign' from UI
    }

    if (labelsByType[0].hasOwnProperty('restriction')) {
      const restriction = labelsByType[0].restriction;

      if (Array.isArray(restriction) && restriction.length === 0) {
        return 'none'; // API representation of No labels allowed is restriction: empty array
      }
    }
  }

  return 'allow'; // new default is to allow container annotations
};

const getToolTipForAllowLabels = (typeName, allowed) => {
  const list = allowed
    ? `\n\n${allowed
        .slice(0, 5)
        .map(val => val.value)
        .join('\n')} ${allowed.length > 5 ? '\n...' : ''}`
    : '';
  const quantity = allowed === undefined ? intl('Protocol.Any') : allowed.length || intl('Common.No');

  return `${intl('ContainerClusters.UseContainerAnnotations')}\n${intl('ContainerClusters.LabelsAllowedNumberAndType', {
    count: quantity,
    labelTypeName: typeName || '',
  })}${list}`;
};

const allowListIcon = <Icon name="allowlist" position="before" theme={styles} themePrefix="allowlist-" />;

// key needed because labelSet can be undefined, as in the case "Any"
export const formatAllowLabels = (labelSet, typeName, managed) => {
  if (managed === false) {
    return null;
  }

  if (!labelSet) {
    // sparse/excluded
    return (
      <Tooltip content={getToolTipForAllowLabels(typeName)} bottom>
        {allowListIcon} {intl('Protocol.Any')}
      </Tooltip>
    );
  }

  const {restriction} = labelSet;
  const length = restriction?.length;

  if (length === 0) {
    return intl('ContainerClusters.NoLabelAllowed');
  }

  const tag = length === 1 ? restriction[0].value : length > 1 ? `${length} ${intl('ContainerClusters.Allowed')}` : '';

  return (
    <Tooltip content={getToolTipForAllowLabels(typeName, restriction)} bottom>
      {allowListIcon}
      {tag}
    </Tooltip>
  );
};

export const labelOrAllowList = (labelSet, typeName, managed) => {
  if (!labelSet || labelSet?.hasOwnProperty('restriction')) {
    return formatAllowLabels(labelSet, typeName, managed);
  }

  const assigned = labelSet?.assignment;

  return assigned ? (
    <Pill.Label type={labelSet.key} href={assigned.href}>
      {assigned.value}
    </Pill.Label>
  ) : null;
};

export const getContainerClusterContainerWorkloadProfileLabelsPayload = labels => {
  return labels.reduce((payload, item) => {
    const labelPayload = {};

    labelPayload.key = item.data.key;

    if (item.data.optionValue === 'multi') {
      labelPayload.restriction = item.data.multi.map(item => ({href: item.href}));
    } else if (item.data.optionValue === 'single') {
      labelPayload.assignment = {href: item.data.single[0].href};
    } else {
      labelPayload.restriction = [];
    }

    payload.push(labelPayload);

    return payload;
  }, []);
};

export const getContainerClusterContainerWorkloadProfilePayload = values => {
  const {managed} = values;
  const labels = [];

  const payload = {
    managed: managed.value === 'true',
    labels,
  };

  if (payload.managed) {
    const labelPayload = getContainerClusterContainerWorkloadProfileLabelsPayload(values.labels);

    payload.labels = labelPayload;

    payload.enforcement_mode = values.enforcementMode.value;

    // API will default visibility_level as 'flow_summary' when enforcement mode is 'idle'
    if (values.enforcementMode.value !== 'idle') {
      payload.visibility_level = values.visibility_level;
    }
  }

  return payload;
};
