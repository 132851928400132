/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {call, put, spawn} from 'redux-saga/effects';
import {fetchKvpairs, updateKvpairs} from '../User/Settings/SettingsSaga';

export function* fetchInstantSearchHistory() {
  const history = yield call(fetchKvpairs, {key: 'instant_search_history', force: true});

  if (history === undefined) {
    // If there is no history in kvpairs yet, make non-blocking call to create them once
    yield spawn(updateInstantSearchHistory, {history: []});
  } else {
    yield put({type: 'SET_INSTANT_SEARCH_HISTORY', data: history});
  }

  return history;
}

export function* updateInstantSearchHistory({history, dispatch = false}) {
  if (history.length > 25) {
    history = history.slice(0, 25);
  }

  if (dispatch) {
    // Immediately update history in the store
    yield put({type: 'SET_INSTANT_SEARCH_HISTORY', data: history});
  }

  yield call(updateKvpairs, {key: 'instant_search_history', data: history});
}
