/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import type {ComponentPropsWithoutRef} from 'react';
import {motion} from 'framer-motion';
import {domUtils, tidUtils} from '@illumio-shared/utils';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import type {TooltipProps} from 'components/Tooltip/Tooltip';
import type {SingletonProps} from 'components/Tooltip/TooltipSingleton';
import styles from './GraphBar.css';
import {Tooltip} from 'components';

const colorPalettes = {
  lightgreenAndRed: ['#46ab5c', '#e83f33'],
  lightgreenAndOrangeAndRed: ['#46ab5c', '#ed972d', '#e83f33'],
  darkgreenAndLightgreenAndRed: ['#2d8743', '#46ab5c', '#e83f33'],
} as const;

export type GraphBarValue = {
  color?: string;
  percentage?: number;
  tooltip?: string;
  exact?: boolean;
  tooltipProps?: TooltipProps;
};

export type GraphBarProps = ComponentPropsWithoutRef<'div'> &
  ThemeProps & {
    tid?: string;
    // The items will be shown based on the order specified in this array.
    // Takes percentage, color, tooltip, tooltipProps
    // [{color: '#d50000', percentage: 50, tooltip: 'abcd'}, {color: '#de2e02', percentage: 49}, {percentage: 1, exact: true}]
    values: GraphBarValue[];
    // If color in values is not specified pick color from colorPalette
    colorPalette?: keyof typeof colorPalettes;
    disabled?: boolean;
    noAnimation?: boolean;
    noInitialAnimation?: boolean;
    // Tooltip props for all tooltips inside values
    tooltipProps?: SingletonProps;
    size?: 'large' | 'medium' | 'small';
  };

export default function GraphBar(props: GraphBarProps): JSX.Element {
  const {
    size,
    disabled = false,
    noAnimation = false,
    noInitialAnimation = false,
    theme,
    colorPalette = 'lightgreenAndRed',
    values = [],
    tooltipProps = {fast: true},
    ...elementProps
  } = mixThemeWithProps(styles, props);
  const tid = elementProps.tid;
  let totalPercentage = 0;

  const composedGraph = values.reduce((result, {...value}, index) => {
    if (value.percentage) {
      // Take color from pallete if not defined
      value.color ??= colorPalettes[colorPalette][index];
      // Set default tooltip
      value.tooltip ??= `${value.percentage}%`;
      // Align the minimum percentage
      value.percentage = !value.exact && value.percentage > 0 && value.percentage < 10 ? 5 : value.percentage;
      // Add up to the total
      totalPercentage += value.percentage;

      result.push(value);
    }

    return result;
  }, [] as GraphBarValue[]);

  elementProps.tid ??= tidUtils.getTid('graph-bar', tid);
  elementProps.className = cx({
    [theme.disabled]: disabled,
    [theme.small]: size === 'small',
    [theme.medium]: size === 'medium',
    [theme.large]: size === 'large',
  });

  let left = 0;

  return (
    <div {...elementProps}>
      <Tooltip.Singleton {...tooltipProps}>
        {composedGraph.map((item, index) => {
          const width = item.percentage ? (item.percentage / totalPercentage) * 100 : 0;
          const bar = (
            <div key={index} style={{flex: `0 0 ${width}%`}}>
              <motion.div
                data-tid={tidUtils.getTid('graph-bar-item', tid)}
                className={cx(theme.bar, {[theme.first]: !index})}
                initial={
                  noInitialAnimation || noAnimation || domUtils.isMotionReduced()
                    ? false
                    : {x: `-${(100 * left) / width}%`, scaleX: 0}
                }
                animate={{
                  x: '0%',
                  scaleX: 1,
                }}
                transition={{
                  type: 'tween',
                  ease: 'easeInOut',
                  duration: 0.8,
                }}
                style={{
                  width: `${width}%`,
                  background: item.color,
                }}
              />
            </div>
          );

          left += width;

          return item.tooltip ? (
            <Tooltip key={index} content={item.tooltip} {...item.tooltipProps}>
              {() => bar}
            </Tooltip>
          ) : (
            bar
          );
        })}
      </Tooltip.Singleton>
    </div>
  );
}
