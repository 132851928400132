/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createContext} from 'react';
import type GatewayTarget from './GatewayTarget';
import type {AppContextValue} from 'containers/App/AppUtils';
import type {GatewayProps} from './Gateway';
import {typesUtils} from '@illumio-shared/utils';

/**
 * GatewayController is a dispatcher, instance of which is passed down the react tree by the GatewayProvider and
 * gets consumed by the Gateway and GatewayTarget components,
 * and is used by them to pass children from Gateway to GatewayTarget by the name.
 *
 * For example,
 * ####################################################################
 * Gateway into="T1"  ↘                                               #
 * Gateway into="T2" -> GatewayController -> GatewayTarget name="T1"  #
 * Gateway into="T1"  ↗                    ↘ GatewayTarget name="T2"  #
 * ####################################################################
 */

export const GatewayContext = createContext({} as GatewayController);

export type ChildrenMap = Map<
  number,
  {
    appContext: AppContextValue;
    props: {into: string; children?: typesUtils.ReactStrictNode; onUnmountReady?: (childId: number) => void};
  }
>;

export default class GatewayController {
  targets: Map<string, {children: ChildrenMap; instance: GatewayTarget | null}>;
  children: Map<number, string>;
  nextChildId: number;

  constructor() {
    this.targets = new Map();
    this.children = new Map();
    this.nextChildId = 1;
  }

  checkInTarget(name: string, instance: GatewayTarget): void {
    if (this.targets.has(name)) {
      if (__DEV__ && this.targets.get(name)?.instance) {
        throw new Error('There cannot be two GatewayTargets with the same name');
      }

      const target = this.targets.get(name);

      if (target) {
        target.instance = instance;
      }
    } else {
      this.targets.set(name, {instance, children: new Map()});
    }

    this.renderTarget(name);
  }

  renderTarget(name: string): void {
    const target = this.targets.get(name);

    if (target && target.instance) {
      target.instance.setChildren(target.children);
    }
  }

  checkOutTarget(name: string): void {
    const target = this.targets.get(name);

    if (target) {
      target.instance = null;

      if (!target.children.size) {
        this.targets.delete(name);
      }
    }
  }

  checkInChild(props: GatewayProps, appContext: AppContextValue, id?: number): number {
    id ||= this.nextChildId++;

    const {into: targetName} = props;
    const {targets, children} = this;
    let target = targets.get(targetName);

    if (!target) {
      target = {instance: null, children: new Map()};
      targets.set(targetName, target);
    }

    children.set(id, targetName);
    target.children.set(id, {props, appContext});

    return id;
  }

  renderChild(props: GatewayProps, appContext: AppContextValue, id: number | null): number {
    if (id) {
      const newTargetName = props.into;
      const previousTargetName = this.children.get(id);

      if (newTargetName === previousTargetName) {
        this.targets.get(newTargetName)?.children.set(id, {props, appContext});
      } else {
        this.checkOutChild(id, false);
        this.checkInChild(props, appContext, id);
      }
    } else {
      id = this.checkInChild(props, appContext);
    }

    this.renderTarget(props.into);

    return id;
  }

  checkOutChild(id: number, rerender = true): void {
    const targetName = this.children.get(id);

    if (targetName) {
      this.children.delete(id);

      const target = this.targets.get(targetName);

      if (target) {
        target.children.delete(id);

        if (target.instance) {
          if (rerender) {
            this.renderTarget(targetName);
          }
        } else if (!target.children.size) {
          this.targets.delete(targetName);
        }
      }
    }
  }
}
