/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {cloneElement, type ComponentPropsWithoutRef, createElement, type ReactElement} from 'react';
import {reactUtils, typesUtils} from '@illumio-shared/utils';
import Button, {type ButtonProps} from './Button';
import styles from './Button.css';

// List of props specific to ButtonGroup component, all extra props will be passed down to rendered div as is
type ButtonGroupPropsIn = ComponentPropsWithoutRef<'div'> &
  Pick<ButtonProps, 'color' | 'noFill' | 'noStyle' | 'size'> &
  ThemeProps & {
    /** List of group <Button>s */
    children: typesUtils.ReactStrictNode;

    /** Makes whole group not interactable (not clickable, not tabbable) */
    insensitive?: ButtonProps['insensitive'];

    /** Makes whole group not interactable and apply disabled styles */
    disabled?: ButtonProps['disabled'];
  };

export default function ButtonGroup(props: ButtonGroupPropsIn): JSX.Element {
  const {
    size,
    color,
    noStyle = false,
    noFill = false,
    insensitive,
    disabled,
    children,
    theme,
    ...elementProps
  } = mixThemeWithProps(styles, props);

  const buttons = (
    reactUtils
      .unwrapChildren(children)
      .filter(
        child =>
          reactUtils.isReactElementOf(child, Button) ||
          reactUtils.isReactElementOf(child, Button.Menu) ||
          reactUtils.isReactElementOf(child, Button.Link),
      ) as ReactElement<ButtonProps>[]
  ).map((button, index, buttons) =>
    cloneElement(button, {
      size,
      color,
      noStyle,
      noFill,
      disabled: typeof button.props.disabled === 'boolean' ? button.props.disabled : disabled,
      insensitive: typeof button.props.insensitive === 'boolean' ? button.props.insensitive : insensitive,
      isGroupFirst: !index,
      isGroupLast: index === buttons.length - 1,
    }),
  );

  elementProps.className = theme.group;

  return createElement('div', elementProps, ...buttons);
}
