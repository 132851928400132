/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getHelpVersion, getDocsUrl, isSupercluster} from 'containers/App/AppState';
import {isUserReadOnly, isSuperclusterLeader, isUserScoped} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, getSelectorSettings, categories} from './LoadBalancerListConfig';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'SLB_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  count(state = {}, action) {
    switch (action.type) {
      case 'SLB_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
  nfcs(state = [], action) {
    switch (action.type) {
      case 'NFCS_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },
};

export const getLoadBalancers = state => state.loadBalancer.list;
export const getLoadBalancersCount = state => state.loadBalancer.count;
export const getNetworkFunctionControls = state => state.loadBalancer.nfcs || [];
export const getDiscoveredVirtualServers = state => state.virtualservers.dvs;

export const getLoadBalancerDeviceTypes = state => {
  const nfcHref = state.loadBalancer.instance?.nfc?.href;

  if (nfcHref) {
    const selectedNFC = state.loadBalancer.nfcs.find(nfc => nfc.href === nfcHref);

    return selectedNFC.supported_device_types;
  }

  return (state.loadBalancer.nfcs[0] && state.loadBalancer.nfcs[0].supported_device_types) || [];
};

const errorStatuses = new Set([
  'cannot_resolve',
  'cannot_connect',
  'bad_credentials',
  'bad_request',
  'bad_certificate',
]);

const getLoadBalancersRows = createSelector([getLoadBalancers, isUserReadOnly], (loadBalancersList, userIsReadOnly) => {
  return loadBalancersList.map(item => {
    const {devices, has_virtual_server: slbIsInUse} = item;
    let status;

    if (Array.isArray(devices) && devices.length) {
      const [
        {
          status: {connection_state: state},
        },
      ] = devices;

      if (devices.length === 1) {
        if (state === 'successful') {
          status = 'inuse';
        } else if (state === 'pending') {
          status = 'pending';
        } else if (errorStatuses.has(state)) {
          status = 'error';
        }
      } else if (devices.length === 2) {
        const [
          ,
          {
            status: {connection_state: state2},
          },
        ] = devices;

        if (state === 'successful' && state2 === 'successful') {
          status = 'inuse';
        } else if (state === 'pending' || state2 === 'pending') {
          status = 'pending';
        } else if (errorStatuses.has(state) || errorStatuses.has(state2)) {
          status = 'error';
        }
      }
    }

    return {
      status,
      key: item.href,
      id: item.href.split('/').at(-1),
      selectable: !userIsReadOnly,
      removable: !slbIsInUse,
      data: item,
      inUse: slbIsInUse,
    };
  });
});

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

export const getGridSettings = createSelector([isUserReadOnly, gridSettings], (userIsReadOnly, gridSettings) => {
  const columns = {...gridSettings.columns};

  columns.checkboxes.disabled = userIsReadOnly;

  return {...gridSettings, columns};
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getLoadBalancersRows,
    filterMap: getFilterMap,
  });

// whether to allow SLB add, delete, or edit
export const enableLoadBalancerControls = createSelector(
  [isSupercluster, isSuperclusterLeader, isUserScoped, isUserReadOnly, getLoadBalancerDeviceTypes],
  (isSupercluster, isSuperclusterLeader, isUserScoped, isUserReadOnly, deviceTypes) => {
    if (isUserReadOnly || isUserScoped) {
      return false; // denied for permissions reasons
    }

    if (isSupercluster) {
      return isSuperclusterLeader; // in Supercluster context, allowed on Supercluster leader only
    }

    return Boolean(deviceTypes?.length); // allowed if a NEN is present
  },
);

export const getLoadBalancersPage = createSelector(
  [
    getGrid,
    getLoadBalancersCount,
    getLoadBalancerDeviceTypes,
    getHelpVersion,
    getDocsUrl,
    enableLoadBalancerControls,
    getSelectorSettings,
    categories,
  ],
  (grid, count, deviceTypes, helpVersion, docsUrl, enableSLBControls, selectorSettingsObject, categories) => {
    const loadBalancerDocLink = `${docsUrl}load balancers`;

    return {
      grid,
      count,
      deviceTypes,
      loadBalancerDocLink,
      isSuperclusterLeader,
      enableSLBControls,
      categories,
    };
  },
);
