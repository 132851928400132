/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {Component, type PropsWithChildren} from 'react';
import GatewayController, {GatewayContext} from './GatewayController';

export default class GatewayProvider extends Component<PropsWithChildren> {
  controller = new GatewayController();

  render() {
    return <GatewayContext.Provider value={this.controller}>{this.props.children}</GatewayContext.Provider>;
  }
}
