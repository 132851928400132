/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {getOrgSettings} from 'containers/App/AppState';
import {createUIStateReducer} from '../../../LabelRulesStateUtils';
import {createSelector} from 'reselect';
import {unSerializeApplyLabelRulesSettings} from './ApplyLabelRulesSchedulerUtils';

export default {
  confirmScheduleRulesUIState: createUIStateReducer({
    initActions: ['APPLY_RULES_SETTINGS_SAVE_INIT'],
    successActions: ['APPLY_RULES_SETTINGS_SAVE_SUCCESS'],
    failureActions: ['APPLY_RULES_SETTINGS_SAVE_FAILED'],
    resetActions: ['APPLY_RULES_SETTINGS_SAVE_RESET'],
  }),
};

export const getConfirmScheduleRulesUIState = state => state.label.rules.confirmScheduleRulesUIState;

export const getApplyLabelRulesSettings = createSelector([getOrgSettings], settings =>
  unSerializeApplyLabelRulesSettings(settings),
);
