/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './ToggleButton.css';
import {useCallback} from 'react';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {tidUtils, typesUtils} from '@illumio-shared/utils';

export type ToggleButtonProps = ThemeProps & {
  checked?: boolean;
  checkedText?: typesUtils.ReactStrictNode;
  unCheckedText?: typesUtils.ReactStrictNode;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  tid?: string;
};

export default function ToggleButton(props: ToggleButtonProps): typesUtils.ReactStrictNode {
  const {
    checked = false,
    checkedText = intl('Common.On'),
    unCheckedText = intl('Common.Off'),
    onChange = () => {},
    disabled,
    tid,
    theme,
  } = mixThemeWithProps(styles, props);

  const handleClick = useCallback(() => onChange(!checked), [onChange, checked]);

  return (
    <button
      data-tid={tidUtils.getTid('comp-toggle-button', tid)}
      type="button"
      className={theme.toggleButton}
      onClick={handleClick}
      disabled={disabled}
    >
      <div className={cx(theme.handle, {[theme.checked]: !checked})}>{unCheckedText}</div>
      <div className={cx(theme.handle, {[theme.checked]: checked})}>{checkedText}</div>
    </button>
  );
}
