/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

import _ from 'lodash';
import cx from 'classnames';
import {createElement, Component, type ReactElement} from 'react';
import {composeThemeFromProps, type ThemeProps} from '@css-modules-theme/react';
import {reactUtils, tidUtils, typesUtils} from '@illumio-shared/utils';
import TabUnderline from './TabUnderline';
import Tab from './Tab';
import {
  Icon,
  type CounterBadgeColors,
  type IconName,
  type IconProps,
  type LinkClass,
  type LinkLikeProp,
} from 'components';
import styles from './TabPanel.css';
import stylesUtils from 'utils.css';

type TabPanelState = {
  visible: boolean;
  showArrows: boolean;
  leftArrowEnabled: boolean;
  rightArrowEnabled: boolean;
};

export type TabProps = {
  disabled?: boolean;
  link: LinkLikeProp;
  text?: string;
  tid?: string;
  counter?: number;
  counterColor?: CounterBadgeColors;
  onClick?: () => void;
  onActivityChange?: () => void;
  iconStyle?: string;
  icon?: IconName | ReactElement;
  iconProps?: IconProps;
  active?: boolean;
  params?: {[key: string]: unknown};
  mergeParams?: boolean;
};

interface TabPanelProps extends ThemeProps {
  color?: 'pill' | 'primary' | 'secondary' | 'tertiary';
  disableInactiveTabs?: boolean;
  children: typesUtils.Falsable<TabProps>[];
  tid?: string;
  containerWidth?: boolean;
  key?: string;
  mergeParams?: boolean;
}

export default class TabPanel extends Component<TabPanelProps, TabPanelState> {
  outerTabPanel?: HTMLDivElement;
  innerTabPanel?: HTMLDivElement;
  underline?: TabUnderline;
  allTabs: LinkClass[];
  updatingArrowsEnabledState?: Promise<unknown> | null;
  updatingWidth?: Promise<unknown> | null;
  waitingForArrowsEnabledStateUpdate?: boolean;
  waitingWidthUpdate?: boolean;
  visibilityTimeout?: ReturnType<typeof setTimeout>;

  constructor(props: TabPanelProps) {
    super(props);
    this.state = {
      visible: false,
      showArrows: false,
      leftArrowEnabled: false,
      rightArrowEnabled: false,
    };
    this.allTabs = [];
    this.saveTabRefs = this.saveTabRefs.bind(this);
    this.saveUnderlineRef = this.saveUnderlineRef.bind(this);
    this.saveOuterTabPanel = this.saveOuterTabPanel.bind(this);
    this.saveInnerTabPanel = this.saveInnerTabPanel.bind(this);

    this.handleLeftArrowClick = this.handleLeftArrowClick.bind(this);
    this.handleRightArrowClick = this.handleRightArrowClick.bind(this);
    this.handleTabActivityChange = this.handleTabActivityChange.bind(this);

    // Debounce scroll handling to enable/disable arrows,
    // leading is true to switch left/right arrow immediately if scroll is happening from very left/right edge
    this.handleScroll = _.debounce(this.handleScroll.bind(this), 50, {leading: true});
    // Throttle resize, to make sure it updates width every Xms while user is resizing the screen
    this.handleResize = _.throttle(this.handleResize.bind(this), 250, {leading: false, trailing: true});
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    // In the beginning panel is invisible, so we have time to render it, compute scroll and
    // show scrolled panel after to avoid initial jump if tab is need to be scrolled
    this.updateWidth(true).then(() => {
      // Give some time to handleScroll or underline position to be set and skip its processing since visible if still false.
      // If we don't timeout, handleScroll is called after visible set to true,
      // so handling won't be skipped and one more useless setState will be invoked
      this.visibilityTimeout = setTimeout(() => this.setState({visible: true}));
    });
  }

  componentDidUpdate(prevProps: TabPanelProps) {
    if (!_.isEqual(prevProps.children, this.props.children)) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    if (this.visibilityTimeout) {
      clearTimeout(this.visibilityTimeout);
    }

    window.removeEventListener('resize', this.handleResize);
  }

  private saveOuterTabPanel(element: HTMLDivElement) {
    this.outerTabPanel = element;
  }

  private saveInnerTabPanel(element: HTMLDivElement) {
    this.innerTabPanel = element;
  }

  // Saves tabs refs for all tabs so that the left most and right most tab can be calculated
  private saveTabRefs(tab: LinkClass) {
    if (tab) {
      this.allTabs.push(tab);
    } else {
      this.allTabs.pop();
    }
  }

  private saveUnderlineRef(underline: TabUnderline) {
    this.underline = underline;
  }

  private handleTabActivityChange(isActive: boolean) {
    if (isActive) {
      const dimensions = this.calcTabDimensions();

      if (dimensions) {
        this.moveActiveTabToCenter(dimensions);

        if (this.underline) {
          this.underline.setDimensions(dimensions);
        }
      }
    }
  }

  private handleScroll() {
    if (!this.state.visible) {
      // Skip scroll handling on first render
      return;
    }

    // Make sure we calculate arrows state only once at the time
    if (!this.updatingArrowsEnabledState) {
      // If there is no updating running, start it and raise a flag that it is running
      this.updatingArrowsEnabledState = this.calcArrowDisabled()?.then(() => {
        // Unset flag
        this.updatingArrowsEnabledState = null;
      });
    } else if (!this.waitingForArrowsEnabledStateUpdate) {
      // If calculation is already happening, put next calculation in queue
      // and skip all next handleScroll invocations until current calculation and next one is over
      // to make sure if there are multiple handleScroll invocations during render cycly, only first and last will be called
      this.waitingForArrowsEnabledStateUpdate = true;

      this.updatingArrowsEnabledState.then(() => {
        this.waitingForArrowsEnabledStateUpdate = false;
        this.handleScroll();
      });
    }
  }

  private handleResize() {
    // Make sure we calculate width only once at the time (the same logic as in handleScroll)
    if (!this.updatingWidth) {
      this.updatingWidth = this.updateWidth().then(() => {
        this.updatingWidth = null;
      });
    } else if (!this.waitingWidthUpdate) {
      this.waitingWidthUpdate = true;

      this.updatingWidth.then(() => {
        this.waitingWidthUpdate = false;
        this.handleResize();
      });
    }
  }

  private handleLeftArrowClick() {
    if (!this.outerTabPanel) {
      return;
    }

    const panelWidth = this.outerTabPanel.offsetWidth;
    const panelLeft = this.outerTabPanel.scrollLeft;
    const panelRight = panelLeft + panelWidth;
    let offset = 0;

    // Iterating from right to left
    for (let i = this.allTabs.length - 1; i >= 0; i--) {
      const tab = this.allTabs[i];
      const tabLeft = tab.element?.offsetLeft ?? 0;
      const tabWidth = tab.element?.offsetWidth ?? 0;
      const tabRight = tabLeft + tabWidth;

      // First element whose left border is 'lefter' than container's left border
      if (tabLeft < panelLeft) {
        if (tabRight > panelRight || tabLeft <= panelLeft - panelWidth) {
          // If element's right border is 'righter' than container's rigth border
          // (means only body of element is visible, not his border, i.e. element is bigger than container)
          // or element's left border is 'lefter' than even previous panel scroll (element is bigger than container)
          // then just scroll to element's left border
          offset = tabLeft - panelLeft;
        } else {
          // Otherwise, scroll panel's right to the element's right
          offset = tabRight - panelRight;
        }

        break;
      }
    }

    this.outerTabPanel.scrollLeft += offset;
  }

  private handleRightArrowClick() {
    if (!this.outerTabPanel) {
      return;
    }

    const panelWidth = this.outerTabPanel.offsetWidth;
    const panelLeft = this.outerTabPanel.scrollLeft;
    const panelRight = panelLeft + panelWidth;
    let offset = 0;

    // Iterating from left to right
    for (const tab of this.allTabs) {
      const tabLeft = tab.element?.offsetLeft ?? 0;
      const tabRight = tabLeft + (tab.element?.offsetWidth ?? 0);

      // First element whose right border is 'righter' than container's right border
      if (tabRight > panelRight) {
        if (tabLeft <= panelLeft) {
          // If element's left border is equals to or 'lefter' than container's left border
          // (means only body of element is visible, not his right border, i.e. element is bigger than container)
          // then just scroll to elemen's right border (next element's left border)
          offset = tabRight - panelLeft;
        } else {
          // Otherwise, scroll panel's left to the element's left
          offset = tabLeft - panelLeft;
        }

        break;
      }
    }

    this.outerTabPanel.scrollLeft += offset;
  }

  private async updateWidth(moveToCenter?: boolean) {
    const state = await this.calcArrowVisibility();
    const dimensions = this.calcTabDimensions();

    if (dimensions && this.underline) {
      this.underline.setDimensions(dimensions);
    }

    if (state.showArrows) {
      if (moveToCenter && dimensions) {
        this.moveActiveTabToCenter(dimensions);
      }

      await this.calcArrowDisabled();
    }
  }

  private calcTabDimensions() {
    const activeTab = this.allTabs.find(tab => tab.isActive);

    if (activeTab) {
      const left = activeTab.element?.offsetLeft ?? 0;
      const width = activeTab.element?.getBoundingClientRect().width ?? 0;
      const center = left + width / 2;

      return {left, width, center};
    }
  }

  private calcArrowVisibility() {
    return reactUtils.setStateAsync((state: TabPanelState) => {
      const outerOffsetWidth = this.outerTabPanel?.offsetWidth ?? 0;
      const innerOffsetWidth = this.innerTabPanel?.offsetWidth ?? 0;

      if (innerOffsetWidth > outerOffsetWidth) {
        if (!state.showArrows) {
          return {showArrows: true};
        }
      } else if (state.showArrows) {
        return {showArrows: false};
      }

      return null; // To prevent changing state and rendering
    }, this);
  }

  private calcArrowDisabled() {
    return reactUtils.setStateAsync((state: TabPanelState) => {
      const scrollLeft = this.outerTabPanel?.scrollLeft ?? 0;
      const outerOffsetWidth = this.outerTabPanel?.offsetWidth ?? 0;
      const innerOffsetWidth = this.innerTabPanel?.offsetWidth ?? 0;
      const newState = {
        leftArrowEnabled: scrollLeft > 0,
        rightArrowEnabled: scrollLeft + outerOffsetWidth < innerOffsetWidth - 1, // -1 to prevent rounding error with fluid fonts
      };

      if (
        state.leftArrowEnabled !== newState.leftArrowEnabled ||
        state.rightArrowEnabled !== newState.rightArrowEnabled
      ) {
        return newState;
      }

      return null; // To prevent changing state and rendering
    }, this);
  }

  private moveActiveTabToCenter({left, center}: {left: number; center: number}) {
    if (!this.outerTabPanel) {
      return;
    }

    this.outerTabPanel.scrollLeft = Math.min(left, center - (this.outerTabPanel?.offsetWidth ?? 0) / 2);
  }

  render() {
    const {
      children,
      tid,
      color = 'primary',
      containerWidth = false,
      disableInactiveTabs = false,
      mergeParams,
    } = this.props;
    const {visible, showArrows} = this.state;

    const theme = composeThemeFromProps(styles, this.props);
    const containerClassName = cx(theme[color], {
      [stylesUtils.containerWidth]: containerWidth,
    });
    const leftArrowClass = cx(theme.arrowLeft, {[theme.disabled]: !this.state.leftArrowEnabled});
    const rightArrowClass = cx(theme.arrowRight, {[theme.disabled]: !this.state.rightArrowEnabled});

    const activeTab = disableInactiveTabs && this.allTabs.length && this.allTabs.find(tab => tab.isActive);

    const tabs = children.reduce((result: typesUtils.ReactStrictNode[], item) => {
      if (item) {
        const linkTo = typeof item.link === 'string' ? item.link : item.link?.to || '';

        result.push(
          <Tab
            {...item}
            disabled={activeTab ? !activeTab.name?.endsWith(linkTo) : item.disabled}
            onActivityChange={this.handleTabActivityChange}
            ref={this.saveTabRefs}
            mergeParams={mergeParams}
          />,
        );
      }

      return result;
    }, []);

    return (
      <div className={containerClassName} data-tid={tidUtils.getTid('comp-tabs', tid)}>
        {showArrows && (
          <div className={leftArrowClass} onClick={this.handleLeftArrowClick} data-tid="comp-tabs-left">
            <Icon name="back" theme={theme} themePrefix="arrow-" />
          </div>
        )}

        <div
          className={cx(theme.outerTabPanel, {[theme.hidden]: !visible})}
          ref={this.saveOuterTabPanel}
          onScroll={this.handleScroll}
        >
          {/* For secondary tabPanel, add extra 10px border at bottom left to prevent
          overlapping of the first tab left border and page gray border*/}
          {color === 'secondary' && containerWidth ? <div className={theme.extraBottomBorderLeft} /> : null}
          {createElement(
            'div',
            {
              ref: this.saveInnerTabPanel,
              className: color === 'pill' ? theme.pillInnerTabPanel : theme.innerTabPanel,
            },
            ...tabs,
          )}
          <div className={cx({[theme.extraBottomBorder]: !['pill', 'tertiary'].includes(color)})} />
          {color === 'primary' && <TabUnderline theme={theme} ref={this.saveUnderlineRef} />}
        </div>

        {showArrows && (
          <div className={rightArrowClass} onClick={this.handleRightArrowClick} data-tid="comp-tabs-right">
            <Icon name="next" theme={theme} themePrefix="arrow-" />
          </div>
        )}
      </div>
    );
  }
}
