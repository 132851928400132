/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {Component, createRef, type TransitionEvent, type RefObject} from 'react';
import {generalUtils} from '@illumio-shared/utils/shared';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import Modal from './Modal';
import {Button} from 'components';
import stylesUtils from 'utils.css';
import {domUtils, typesUtils} from '@illumio-shared/utils';
import type {Theme} from '@css-modules-theme/react';
import type {BaseButton, ButtonContextType} from '../Button/Button';

interface ModalGatewayProps extends Record<string, unknown> {
  instance?: Modal;
  idleOnEsc?: boolean;
  onClose(evt: domUtils.MouseEventLike): void;
  children?: typesUtils.ReactStrictNode;
  idleOnBackdropClick?: boolean;
  zIndex?: number;
  instant?: boolean;
  onUnmountReady?(): void;
  theme: Theme;
}

type ModalGatewayState = {
  contentInsensitive?: boolean;
  loadingButtons: BaseButton[];
  phase?: 'animatingIn' | 'animatingOut' | 'hidden' | 'mounting' | 'showing';
  waitingForButtonLoading?: boolean;
  childrenToRender?: typesUtils.ReactStrictNode;
  children?: typesUtils.ReactStrictNode;
};

export default class ModalGateway extends Component<ModalGatewayProps, ModalGatewayState> {
  animator: RefObject<HTMLDivElement>;
  buttonContextProps: ButtonContextType;
  buttonContextPropsStopLoading: Record<string, unknown>;
  rAF?: number;

  constructor(props: ModalGatewayProps) {
    super(props);

    this.state = {
      children: null,
      // Possible state of ModalGateway
      // hidden
      // mounting
      // animatingIn
      // showing
      // animatingOut
      phase: 'hidden',
      // Buttons in Modal which have progress state
      loadingButtons: [],
      // Whether the content is insensitive to mouse click actions
      contentInsensitive: false,
      // Whether we need to wait for progress buttons before switching to animatingOut/hidden phase
      waitingForButtonLoading: false,
    };

    this.animator = createRef();

    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
    this.handleAnimatorClick = this.handleAnimatorClick.bind(this);
    this.handleDocumentKeydown = this.handleDocumentKeydown.bind(this);

    // Create two possible context objects statically to prevent button rerender on each modalGateway rerender
    // https://reactjs.org/docs/context.html#caveats
    this.buttonContextProps = {
      onProgressStart: this.handleButtonLoaderStart.bind(this),
      onProgressBeforeFade: this.handleButtonBeforeFade.bind(this),
      onBeforeUnmount: this.handleButtonBeforeUnmount.bind(this),
    };
    this.buttonContextPropsStopLoading = {
      progress: false,
      ...this.buttonContextProps,
    };
  }

  static getDerivedStateFromProps(nextProps: ModalGatewayProps, prevState: ModalGatewayState) {
    if (prevState.waitingForButtonLoading && !prevState.loadingButtons.length) {
      const nextState: Partial<ModalGatewayState> = {waitingForButtonLoading: false};

      if (nextProps.instant || domUtils.isMotionReduced()) {
        // Immediately hide modal
        if (nextProps.onUnmountReady) {
          // If parent GatewayTarget is waiting for disappearence animation,
          // call its handler immediately and it will stop rendering ModalGateway
          nextProps.onUnmountReady();
        } else {
          // Otherwise stop rendering Modal manually
          nextState.phase = 'hidden';
        }

        nextState.childrenToRender = nextProps.children;
      } else {
        nextState.phase = 'animatingOut';
      }

      return nextState;
    }

    if (nextProps.children === prevState.children) {
      return null;
    }

    const nextState: Partial<ModalGatewayState> = {children: nextProps.children};
    const {phase} = prevState;

    if (nextProps.children && prevState.children) {
      nextState.childrenToRender = nextProps.children;
    } else if (nextProps.children && !prevState.children) {
      if (nextState.phase !== 'showing' && nextState.phase !== 'animatingIn') {
        // firstRender is true if it is a first render (cDM on App.js is yet to come) or if first render was < 500ms ago
        const firstRender = !window.renderedAt || Date.now() - window.renderedAt < 500;
        // Modal should be animatable only if it is opened by user interaction after the first render
        // To avoid adding animate class on rerender if modal was opened within first render (by url param, for example)
        const animate = !firstRender && !nextProps.instant && !domUtils.isMotionReduced();

        if (!animate) {
          // Show immediately if animation is not needed
          nextState.phase = 'showing';
        } else if (phase === 'hidden') {
          // Render in init state (with zero opacity and initial transformations)
          nextState.phase = 'mounting';
        } else {
          // Start showing animation by applying final classname
          nextState.phase = 'animatingIn';
        }

        nextState.childrenToRender = nextProps.children;
      }

      if (prevState.waitingForButtonLoading) {
        nextState.waitingForButtonLoading = false;
      }
    } else if (!nextProps.children && prevState.children) {
      if (phase === 'showing' || phase === 'animatingIn') {
        if (prevState.loadingButtons.length) {
          nextState.waitingForButtonLoading = true;
        } else if (nextProps.instant || domUtils.isMotionReduced()) {
          // Immediately hide modal
          if (nextProps.onUnmountReady) {
            // If parent GatewayTarget is waiting for disappearence animation,
            // call its handler immediately and it will stop rendering ModalGateway
            nextProps.onUnmountReady();
          } else {
            // Otherwise stop rendering Modal manually
            nextState.phase = 'hidden';
          }
        } else {
          // Show disappearing animation
          nextState.phase = 'animatingOut';
        }
      } else {
        nextState.childrenToRender = nextProps.children;
      }
    }

    return nextState;
  }

  componentDidMount() {
    // @types body-scroll-lock argument is type 'HTMLElement | Element'
    // '!' at the end for non-null
    disableBodyScroll(this.animator.current!, {reserveScrollBarGap: true}); // Make body unscrollable while modal is opened

    // Change phase in timeout after body scroll was disabled (by timeout set in disableBodyScroll)
    setTimeout(() => {
      this.setState(state => {
        if (state.phase === 'mounting') {
          return {phase: 'animatingIn'};
        }

        return null;
      });
    });

    document.addEventListener('keydown', this.handleDocumentKeydown);
  }

  shouldComponentUpdate(nextProps: ModalGatewayProps, nextState: ModalGatewayState) {
    // Rerender only if props changes or state without loadingButtons
    return (
      !generalUtils.shallowEqual(this.props, nextProps) ||
      !generalUtils.shallowEqualLooseByProps(this.state, nextState, [
        'children',
        'phase',
        'waitingForButtonLoading',
        'contentInsensitive',
      ])
    );
  }

  componentDidUpdate(_prevProps: ModalGatewayProps, prevState: ModalGatewayState) {
    if (prevState.waitingForButtonLoading && !this.state.waitingForButtonLoading) {
      PubSub.publish('MODAL.ACTION_DONE');
    }

    if (this.state.phase === 'showing' && prevState.phase !== 'showing' && this.props.instance) {
      this.props.instance.fixStretchedWidth();
    }
  }

  componentWillUnmount() {
    enableBodyScroll(this.animator.current!); // Make body scrollable again on modal close

    if (this.rAF) {
      cancelAnimationFrame(this.rAF);
    }

    document.removeEventListener('keydown', this.handleDocumentKeydown);

    if (this.state.waitingForButtonLoading) {
      PubSub.publish('MODAL.ACTION_DONE');
    }
  }

  private handleTransitionEnd(evt: TransitionEvent) {
    const {target, propertyName} = evt;

    if (target === this.animator.current) {
      this.setState((state, props) => {
        // Appearance animation ends with transform transition
        if (state.phase === 'animatingIn' && propertyName === 'transform') {
          return {phase: 'showing'};
        }

        // Disappearance animation ends with opacity, doesn't wait for transformation, it should not scale back down to initial value
        if (state.phase === 'animatingOut' && propertyName === 'opacity') {
          if (props.onUnmountReady) {
            // If parent GatewayTarget is waiting for disappearance animation,
            // call its handler immediately and it will stop rendering ModalGateway
            props.onUnmountReady();
          } else {
            // Otherwise stop rendering Modal manually
            return {phase: 'hidden'};
          }
        }

        return null;
      });
    }
  }

  handleBackdropClick(evt: domUtils.MouseEventLike): boolean {
    // Click on backdrop should do nothing and do not propagate
    return domUtils.preventEvent(evt);
  }

  private handleAnimatorClick(evt: domUtils.MouseEventLike) {
    // Click on animator outside of modal should not propagate and close modal in case it is showing
    domUtils.preventEvent(evt);

    if (!this.props.idleOnBackdropClick && (this.state.phase === 'showing' || this.state.phase === 'animatingIn')) {
      this.props.onClose(evt);
    }
  }

  private handleDocumentKeydown(evt: KeyboardEvent) {
    // Escape key should close modal
    if (
      evt.keyCode === 27 &&
      !this.props.idleOnEsc &&
      (this.state.phase === 'showing' || this.state.phase === 'animatingIn')
    ) {
      domUtils.preventEvent(evt);
      this.props.onClose(evt);
    }
  }

  private handleButtonLoaderStart(button: BaseButton) {
    this.startWaitingForLoadingButton(button);
  }

  private handleButtonBeforeFade(button: BaseButton) {
    this.stopWaitingForLoadingButton(button);
  }

  private handleButtonBeforeUnmount(button: BaseButton) {
    this.stopWaitingForLoadingButton(button);
  }

  private startWaitingForLoadingButton(button: BaseButton) {
    this.setState(({loadingButtons}) =>
      loadingButtons.includes(button)
        ? null
        : {
            loadingButtons: [...loadingButtons, button],
            contentInsensitive: true,
          },
    );
  }

  private stopWaitingForLoadingButton(button: BaseButton) {
    this.setState(({loadingButtons}) =>
      loadingButtons.includes(button)
        ? {
            loadingButtons: loadingButtons.filter(loadingButton => loadingButton !== button),
            contentInsensitive: loadingButtons.length > 1,
          }
        : null,
    );
  }

  render() {
    const {
      props: {theme, zIndex},
      state: {childrenToRender, phase, waitingForButtonLoading, contentInsensitive},
    } = this;

    if (phase === 'hidden') {
      return null;
    }

    let backdropClass;
    let animatorClass;
    let buttonContextProps;

    if (phase === 'animatingIn' || phase === 'showing') {
      backdropClass = theme.backdropShow;
      animatorClass = theme.animatorShow;
    } else {
      backdropClass = theme.backdrop;
      animatorClass = theme.animator;
    }

    if (phase !== 'showing' || contentInsensitive) {
      animatorClass += ` ${stylesUtils.insensitive}`;
    }

    if (waitingForButtonLoading || phase === 'animatingOut') {
      buttonContextProps = this.buttonContextPropsStopLoading;
    } else {
      buttonContextProps = this.buttonContextProps;
    }

    return (
      <>
        <div className={backdropClass} style={{zIndex}} onClick={this.handleBackdropClick} />
        <div
          className={animatorClass}
          style={{zIndex}}
          ref={this.animator}
          onTransitionEnd={this.handleTransitionEnd}
          onClick={this.handleAnimatorClick}
        >
          <Button.Context.Provider value={buttonContextProps}>{childrenToRender}</Button.Context.Provider>
        </div>
      </>
    );
  }
}
