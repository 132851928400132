/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Badge} from 'components';
import {UserName} from 'containers';
import {getUpdateTypeByBadgeType, getBadgeLabelByUpdateType} from 'utils/formats';
import styles from './VersionDetail.css';
import * as GridUtils from 'components/Grid/GridUtils';
import {createSelector} from 'reselect';

export const gridSettings = createSelector([], () => ({
  id: 'versiondetail',
  sort: 'name',
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  columns: {
    change: {
      header: intl('Provision.Status'),
      value: ({row}) =>
        getBadgeLabelByUpdateType(
          getUpdateTypeByBadgeType(row.data.update_type === 'update' ? 'updated' : row.data.update_type),
          'version',
        ),
      format: ({row, value}) =>
        value ? (
          <Badge
            type={row.data.update_type === 'update' ? 'updated' : row.data.update_type}
            theme={styles}
            themePrefix="status-"
          >
            {value}
          </Badge>
        ) : null,
    },
    item: {
      header: intl('Common.Type'),
      value: 'typeLabel',
    },
    name: {
      header: intl('Common.Name'),
      value: 'name',
    },
    provisionedOn: {
      isDate: 'L_HH_mm_ss',
      header: intl('Common.UpdatedAt'),
      value: 'updated_at',
    },
    provisionedBy: GridUtils.clickableColumn({
      header: intl('Common.UpdatedBy'),
      value: ({row}) => row.data.updated_by.username,
      format: ({row, clickableRef}) => <UserName user={row.data.updated_by} ref={clickableRef} />,
    }),
  },

  /* Grid's breakpoints configuration */
  /**
   Each breakpoint can have:
   [{
    // Possible dimensions of breakpoint, go to format function
    minWidth: ?number,
    maxWidth: ?number,
    minHeight: ?number,
    maxHeight: ?number,

    // Required columns configuration for breapoint
    template: array | Function,

    // Optional breakpoint id, goes to format function
    id: ?string,
    // Optional props that will be merged to Grid container element
    props: ?object
    // Optional object with any data, goes to format function
    data: ?object,
  }];
   */
  templates: [
    [
      {columns: ['change'], size: 'min-content'},
      {columns: ['item'], size: 'minmax(150px, auto)'},
      {columns: ['name'], size: 'minmax(320px, auto)'},
      {columns: ['provisionedOn'], size: 'minmax(230px, auto)'},
      {columns: ['provisionedBy'], size: 'minmax(230px, auto)'},
    ],
    {
      maxWidth: 1152,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['change'], size: 'min-content'},
            {columns: ['item'], size: 'minmax(120px, auto)'},
            {columns: ['name'], size: 'minmax(280px, auto)'},
            {columns: ['provisionedOn'], size: 'minmax(230px, auto)'},
            {columns: ['provisionedBy'], size: 'minmax(170px, auto)'},
          ];
        }

        return [
          {columns: ['change'], size: 'min-content'},
          {columns: ['item'], size: 'minmax(120px, auto)'},
          {columns: ['name'], size: 'minmax(280px, auto)'},
          {columns: ['provisionedOn'], size: 'minmax(230px, auto)'},
          {columns: ['provisionedBy'], size: 'minmax(170px, auto)'},
        ];
      },
    },
    {
      maxWidth: 960,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['change'], size: 'min-content'},
            {columns: ['item', 'name'], size: 'minmax(300px, auto)'},
            {columns: ['provisionedOn', 'provisionedBy'], size: 'minmax(230px, auto)'},
          ];
        }

        return [
          {columns: ['change'], size: 'min-content'},
          {columns: ['item', 'name'], size: 'minmax(300px, auto)'},
          {columns: ['provisionedOn', 'provisionedBy'], size: 'minmax(230px, auto)'},
        ];
      },
    },
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['item', 'name', 'change'], size: 'minmax(170px, auto)'},
            {columns: ['provisionedOn', 'provisionedBy'], size: 'minmax(150px, auto)'},
          ];
        }

        return [
          {columns: ['item', 'name', 'change'], size: 'minmax(170px, auto)'},
          {columns: ['provisionedOn', 'provisionedBy'], size: 'minmax(150px, auto)'},
        ];
      },
    },
  ],
}));
