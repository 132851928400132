/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect/lib/index';
import {getRouteParams, isIPForwardingEnabled} from 'containers/App/AppState';
import {labelGroupFilterCategories} from './LabelGroupItemUtils';
import {getAllUsersMap, isUserReadOnly, isUserReadOnlyAll} from '../../User/UserState';
import {isAPIAvailable} from 'api/apiUtils';
import {fillPolicyObjUserInfo, getPolicyVersions} from 'containers/Provisioning/ProvisioningUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {memberOfGridSettings, memberOfFilterMap} from './MemberOf/LabelGroupMemberOfConfig';
import {memberGridSettings, memberFilterMap} from './Members/LabelGroupMembersConfig';
import {getDisplayNames} from 'containers/Label/LabelSettings/LabelSettingState';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUPS_GET_DETAIL':
        return action.data;
      default:
        return state;
    }
  },
  membersOfList(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_OF_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  membersOfCount(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_OF_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
  membersOfData(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_OF_LIST':
        return action.data.static;
      default:
        return state;
    }
  },
  membersList(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  membersCount(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
  membersData(state = {}, action) {
    switch (action.type) {
      case 'LABEL_GROUP_MEMBER_LIST':
        return action.data.static;
      default:
        return state;
    }
  },
};

export const getLabelGroupDetail = state => state.labelGroup.detail;

export const getMemberOfData = state => state.labelGroup.membersOfData;
export const getMemberOfList = state => state.labelGroup.membersOfList;
export const getMemberOfCount = state => state.labelGroup.membersOfCount;

export const getMemberData = state => state.labelGroup.membersData;
export const getMemberList = state => state.labelGroup.membersList;
export const getMemberCount = state => state.labelGroup.membersCount;

export const getLabelGroupSummary = createSelector(
  [getRouteParams, getLabelGroupDetail, getAllUsersMap, isUserReadOnly, isIPForwardingEnabled, getDisplayNames],
  (params, labelGroupDetail, usersMap, isUserReadOnly, isIPForwardingEnabled, typeNames) => {
    const labelGroupId = params.id;
    let {versions, isOldVersion, pversionObjIsDeleted} = getPolicyVersions(labelGroupDetail.detail, params.pversion);
    const policyVersion = isOldVersion ? Number(params.pversion) : params.pversion;
    const labelDisplayName = typeNames[versions.pversionObj.key];

    versions = fillPolicyObjUserInfo(versions, usersMap);

    return {
      versions,
      policyVersion,
      isOldVersion,
      labelGroupId,
      labelGroupDetail,
      params,
      isUserReadOnly,
      pversionObjIsDeleted,
      isIPForwardingEnabled,
      labelDisplayName,
    };
  },
);

const getMemberRows = createSelector(
  [getMemberList, getMemberCount, getLabelGroupSummary],
  (memberList, memberCount, {versions: {pversionObj}, policyVersion}) =>
    memberList.map(item => ({
      type: pversionObj.key || item.key,
      key: item.href,
      removable: isAPIAvailable('label_group.update') && (!item.update_type || item.update_type !== 'delete'),
      selectable: isAPIAvailable('label_group.update') && item.update_type !== 'delete',
      isGroup: item.href.indexOf('label_groups') > 0,
      name: item.name || item.value,
      value: item.name || item.value,
      update_type: item.update_type,
      policyVersion,
    })),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, memberGridSettings],
  (userIsReadOnlyAll, memberGridSettings) => {
    const columns = {...memberGridSettings.columns};

    columns.checkboxes.disabled = !isAPIAvailable('label_group.update') && userIsReadOnlyAll;

    return {...memberGridSettings, columns};
  },
);

const getMemberGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getMemberRows,
    filterMap: memberFilterMap(),
  });

const getMembersFilterCategories = createSelector([getMemberData, getLabelGroupSummary], (memberData, {versions}) => {
  const memberDataWithType = memberData.map(item => ({...item, type: versions.pversionObj.key}));

  return labelGroupFilterCategories(memberDataWithType);
});

export const getLabelGroupMember = createSelector(
  [getMemberGrid, getMemberCount, getMemberData, getLabelGroupSummary, getMembersFilterCategories],
  (
    memberGrid,
    memberCount,
    memberData,
    {versions: {pversionObj, draft}, policyVersion, labelGroupId, isOldVersion},
    categories,
  ) => ({
    memberGrid,
    memberCount,
    memberData,
    pversionObj,
    draft,
    policyVersion,
    labelGroupId,
    isOldVersion,
    categories,
  }),
);

const getMemberOfRows = createSelector([getMemberOfList, getLabelGroupSummary], (membersOf, {versions}) =>
  membersOf.map(item => ({
    key: item.href,
    type: versions.pversionObj.key,
    data: item,
  })),
);

const getMemberOfGrid = state =>
  getGridSelector(state, {
    settings: memberOfGridSettings,
    rows: getMemberOfRows,
    filterMap: memberOfFilterMap(),
  });

const getmembersFilterCategories = createSelector(
  [getMemberOfData, getLabelGroupSummary],
  (memberOfData, {versions}) => {
    const memberOfDataWithType = memberOfData.map(item => ({...item, type: versions.pversionObj.key}));

    return labelGroupFilterCategories(memberOfDataWithType);
  },
);

export const getLabelGroupMemberOfPage = createSelector(
  [getMemberOfGrid, getMemberOfData, getMemberOfCount, getLabelGroupSummary, getmembersFilterCategories],
  (grid, memberOfData, memberOfCount, {versions}, categories) => ({
    grid,
    memberOfData,
    memberOfCount,
    versions,
    categories,
  }),
);
