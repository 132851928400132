/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import {
  attributeOptions,
  logicOperatorOptions,
  operatorOptions,
  resourceIds,
  defaultAttributeOption,
} from './LabelRulesListEditExpressionConstants';
import {portUtils} from '@illumio-shared/utils';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {
  validateRegex,
  validateHostname,
  validateOS,
  validateIP,
  validateProcess,
  validatePortAndOrProtocol,
} from './LabelRulesListEditExpressionValidationUtils';
import {attributeIds, operatorIds} from 'containers/Label/Rules/LabelRulesConstants';
import {Button} from 'components';
import styles from './LabelRulesListEditExpression.css';

export const getContainerInputPlaceholderText = ({attribute, operator}) => {
  if (operator === operatorOptions.regex.id) {
    return intl('LabelRules.PlaceholderTextRegex');
  }

  if (attribute === attributeOptions.port.id && operator === operatorOptions.is.id) {
    return intl('LabelRules.PlaceholderTextPortIs');
  }

  if (attribute === attributeOptions.port.id && operator === operatorOptions.isIn.id) {
    return intl('LabelRules.PlaceholderTextPortIsIn');
  }

  if (attribute === attributeOptions.hostname.id) {
    return intl('LabelRules.PlaceholderTextHostname');
  }

  if (attribute === attributeOptions.os.id) {
    return intl('LabelRules.PlaceholderTextOS');
  }

  if (attribute === attributeOptions.ip.id) {
    return intl('LabelRules.PlaceholderTextIP');
  }

  if (attribute === attributeOptions.process.id) {
    return intl('LabelRules.PlaceholderTextProcess');
  }

  return '';
};

/**
 * converts a port object to a string to be displayed in the UI; it is currently
 * used to generate the text shown in the expression pills with port conditions;
 * @param portObject (e.g. {port: 80, toPort: 81, proto: 6})
 * @returns {string}
 */
export const portObjectToString = portObject => {
  // extract only the properties we want, and only if they are not null or undefined
  portObject = _.omitBy(_.pick(portObject, ['port', 'toPort', 'proto']), _.isNil);

  const keys = new Set(_.keys(portObject));
  const protoName = portUtils.ProtocolMap[String(portObject.proto)] ?? '';

  // port only
  if (keys.size === 1 && keys.has('port')) {
    return `${intl('Port.Port')} ${portObject.port}`;
  }

  // protocol only
  if (keys.size === 1 && keys.has('proto')) {
    return protoName || `${intl('Common.Protocol')} ${portObject.proto}`;
  }

  // port & proto
  if (keys.size === 2 && keys.has('port') && keys.has('proto')) {
    return `${portObject.port}${protoName ? ` ${protoName}` : ''}`;
  }

  // port range
  if (keys.size === 2 && keys.has('port') && keys.has('toPort')) {
    return `${portObject.port}-${portObject.toPort}`;
  }

  // port range with protocol
  if (keys.size === 3 && keys.has('port') && keys.has('toPort') && keys.has('proto')) {
    return `${portObject.port}-${portObject.toPort}${protoName ? ` ${protoName}` : ''}`;
  }

  return '';
};

/**
 * converts a port object to a string that can be used in the expression selector's
 * text input; the output should be similar to what the user entered when they were
 * searching for the port/protocol;
 * @param portObject (e.g. {port: 80, toPort: 81, proto: 6})
 * @returns {string|string}
 */
export const portObjectToInputValue = portObject => {
  // extract only the properties we want, and only if they are not null or undefined
  portObject = _.omitBy(_.pick(portObject, ['port', 'toPort', 'proto']), _.isNil);

  const keys = new Set(_.keys(portObject));
  const protoName = portUtils.ProtocolMap[String(portObject.proto)] ?? '';

  // port only
  if (keys.size === 1 && keys.has('port')) {
    return String(portObject.port);
  }

  // protocol only
  if (keys.size === 1 && keys.has('proto')) {
    return protoName || String(portObject.proto);
  }

  // port & proto
  if (keys.size === 2 && keys.has('port') && keys.has('proto')) {
    return `${portObject.port}${protoName ? ` ${protoName}` : ''}`;
  }

  // port range
  if (keys.size === 2 && keys.has('port') && keys.has('toPort')) {
    return `${portObject.port}-${portObject.toPort}`;
  }

  // port range with protocol
  if (keys.size === 3 && keys.has('port') && keys.has('toPort') && keys.has('proto')) {
    return `${portObject.port}-${portObject.toPort}${protoName ? ` ${protoName}` : ''}`;
  }

  return '';
};

/**
 * converts the conditionValue to a string to be used in the UI, such as displayed
 * to the user or populated in a text input;
 * @param attribute
 * @param operator
 * @param value
 * @returns {*|string}
 */
export const conditionValueToString = ({attribute, operator, value}) => {
  if (attribute === attributeIds.port && (operator === operatorIds.is || operator === operatorIds.isIn)) {
    return portObjectToString(value);
  }

  return value;
};

export const conditionValueToInputValue = ({attribute, operator, value}) => {
  if (attribute === attributeIds.port && (operator === operatorIds.is || operator === operatorIds.isIn)) {
    return portObjectToInputValue(value);
  }

  return value;
};

/**
 * converts a condition's value to a prefixed (unique) id to be used in a selector option's value property;
 * when the value is an object, we convert it to a unique string; when the value is a string, we just use the
 * string; in either case, we prefix the value with attribute_operator so the selector thinks that the value is
 * unique; this allows us to have different attribute/operator combos that have the same value; for example:
 * 'hostname_is_foo' and 'hostname_startsWith_foo';
 * @param attribute
 * @param operator
 * @param value
 * @returns {`${string}_${string}_${string}`}
 */
const conditionValueToPrefixedValueId = ({attribute, operator, value}) => {
  let valueString = value;

  if (attribute === attributeIds.port && (operator === operatorIds.is || operator === operatorIds.isIn)) {
    // creates a unique id out of the port object's keys; note that _.pick orders the keys before being filtered and passed to reduce;
    // {port: 80, toPort: 81, proto: 6} -> 'port_isIn_port:80,toPort:81,proto:6'
    valueString = _.reduce(
      _.omitBy(_.pick(value, ['port', 'toPort', 'proto']), _.isNil),
      (valueString, value, key) => `${valueString ? `${valueString},` : valueString}${key}:${value}`,
      '',
    );
  }

  // HACK: prefix selector value with attribute and operator so selector will allow us
  // to select the same value for different attribute/operator values. For example, it
  // allows us to have "hostname is foo" and "os startsWith foo" in the same resource
  // because the value "foo" is prefixed by attribute_operator, so selector thinks that
  // it is two unique values.
  return `${attribute}_${operator}_${valueString}`;
};

/**
 * returns a selector option with the specified values; this is intended to be used
 * to create a selector option with the necessary values needed by the expression
 * selector;
 * @param attribute {string} - the attribute of the condition
 * @param operator {string} - the operator of the condition
 * @param value {string} - the value used by the selector (NOTE: we prefix this
 *        value; see prefixSelectorOptionValue)
 * @param name {string} - the name of the option; this is the string that will be
 *        displayed in the ui;
 * @param inputValue {string} - the value that the user entered to get this option
 *        (a.k.a. "query"); used to populate the expression selector's text input;
 * @param conditionValue {string|object} - the value that will be used in the actual
 *        condition object when this option is converted to an expression; this is the
 *        actual value that will eventually be sent to the API after being serialized.
 * @returns {{inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}}
 */
const getSelectorOption = ({attribute, operator, value, name, inputValue, conditionValue}) => {
  conditionValue ??= value;
  inputValue ??= value;
  name ??= value;

  const valueId = conditionValueToPrefixedValueId({attribute, operator, value: conditionValue});

  return {
    attribute,
    operator,
    value: valueId,
    conditionValue,
    inputValue,
    name,
  };
};

/**
 * returns a selector option for the attribute;
 * @param attributeOption - {id, label} attribute option from constants;
 * @returns {{resourceKey: string, name, value}|null}
 */
export const getAttributeSelectorOption = attributeOption => {
  return attributeOption
    ? {
        value: attributeOption.id,
        name: attributeOption.label,
        resourceKey: resourceIds.attribute,
      }
    : null;
};

/**
 * returns a selector option for the operator;
 * @param operatorOption - {id, label} operator option from constants;
 * @returns {{resourceKey: string, name, value}|null}
 */
export const getOperatorSelectorOption = operatorOption => {
  return operatorOption
    ? {
        value: operatorOption.id,
        name: operatorOption.label,
        resourceKey: resourceIds.operator,
      }
    : null;
};

/**
 * converts a value from one of an expression's conditions to a selector option,
 * which can be passed to the selector; it is used to transform expression values
 * to selector options;
 * @param attribute
 * @param operator
 * @param value
 * @param name
 * @returns {{name: (*|string), conditionValue: (*|number), attribute, value: string, operator}}
 */
export const conditionValueToSelectorOption = ({attribute, operator, value}) => {
  const name = conditionValueToString({attribute, operator, value});
  const valueId = conditionValueToPrefixedValueId({attribute, operator, value});
  const inputValue = conditionValueToInputValue({attribute, operator, value});

  return {
    attribute,
    operator,
    value: valueId,
    name,
    conditionValue: value,
    inputValue,
  };
};

/**
 * Converts selector values to an Expression (either a condition expression or complex expression).
 * This first iteration of compound conditions only supports condition expressions or a single
 * complex expression with an 'and' logicOperator and conditions in the childExpressions.
 * The values Map contains a key called 'expression', which is an array that contains the values
 * needed to build the expression. Values with the same attribute & operator are put into the same
 * condition and joined with an OR. Separate conditions are implicitly joined with an AND.
 * Examples below.
 * -----------------------------
 * Example 1: complex expression
 *   Input 1: values {Map}
 *   new Map([
 *     [
 *       'expression', [
 *         {value: 'os_is_windows', attribute: 'os', operator: 'is', name: 'windows'},
 *         {value: 'os_is_linux', attribute: 'os', operator: 'is', name: 'linux'},
 *         {value: 'ip_isIn_1.1.1.1/24', attribute: 'ip', operator: 'isIn', name: '1.1.1.1/24'},
 *         {value: 'ip_isIn_1.1.1.1-2.2.2.2', attribute: 'ip', operator: 'isIn', name: '1.1.1.1-2.2.2.2'},
 *       ],
 *     ],
 *   ])
 *
 *   Output 1: expression {object}
 *   {logicOperator: 'and', childExpressions: [
 *     {operator: 'os', attribute: 'is', values: ['windows', 'linux']},
 *     {operator: 'ip', attribute: 'isIn', values: ['1.1.1.1/24', '1.1.1.1-2.2.2.2']}
 *     ]}
 * -------------------------------
 * Example 2: condition expression
 *   Input 2: values {Map}
 *   new Map([['expression', [{value: 'hostname_startsWith_foo', attribute: 'hostname', operator: 'startsWith', value: 'foo'}]]])
 *
 *   Output 2: expression {object}
 *   {attribute: 'hostname', operator: 'startsWith', values: ['foo']}
 * -------------------------------
 * @param values {Map}
 * @returns {expression}
 */
export const selectorValuesToExpression = values => {
  const selections = values?.get(resourceIds.expression) ?? [];
  // aggregate the expression resource's values by attribute & operator
  const aggregatedValues = selections.reduce((result, selection) => {
    // HACK: ignore the dummy value that was inserted to ensure that formatResource is always called
    // on the expression resource.
    if (selection?.dummy) {
      return result;
    }

    const {attribute, operator, conditionValue: value} = selection ?? {};

    if (attribute && operator && value) {
      result[`${attribute}_${operator}`] ??= {attribute, operator, values: []};
      result[`${attribute}_${operator}`].values.push(value);
    } else if (__DEV__) {
      console.warn(
        `An attribute, operator, and value are required to to make a valid condition. This condition has attribute="${attribute}"; operator="${operator}"; value="${selection?.value}"`,
      );
    }

    return result;
  }, {});
  const conditions = Object.values(aggregatedValues);

  // if there is only one condition, just return it as a condition expression
  if (conditions.length <= 1) {
    return conditions[0];
  }

  // if multiple conditions, return them in a complex 'and' expression.
  return {logicOperator: logicOperatorOptions.and.id, childExpressions: conditions};
};

/**
 * Converts a value from a condition to a selector value object.
 * @param attribute
 * @param operator
 * @param value
 * @returns {{name: string, attribute: string, value: string, operator: string}}
 */

/**
 * Converts a condition to an array of selector values.
 * @param condition
 * @returns {*|*[]}
 */
const conditionToSelectorValuesArray = condition => {
  const {attribute, operator, values} = condition ?? {};

  if (!attribute || !operator || !values) {
    return [];
  }

  return values
    .filter(value => Boolean(value))
    .map(value => conditionValueToSelectorOption({attribute, operator, value}));
};

/**
 * Converts an expression to an array of selector values.
 * TODO: this function will need to be updated to be able to support more deeply nested expressions
 *
 * @param expression
 * @returns {*|*[]}
 */
const expressionToSelectorValuesArray = expression => {
  if (!expression) {
    return [];
  }

  // expression is a condition
  if (!expression.hasOwnProperty('childExpressions')) {
    return conditionToSelectorValuesArray(expression);
  }

  // expression is complex
  return expression.childExpressions.reduce(
    (result, condition) => [...result, ...conditionToSelectorValuesArray(condition)],
    [],
  );
};

/**
 * Converts an expression to a Map object to be consumed by the Selector component. This function
 * currently either a condition expression or a single complex expression with conditions in the
 * childExpressions array. The function accepts an expression, currentAttribute, and currentOperator.
 * The currentAttribute, currentOperator, and the expression's condition(s) are inserted into the
 * Map as Selector resource values. Examples below.
 * -------------------------------
 * Example 1: complex expression
 *   Input 1: expression {object}, currentAttribute: {string}|null, currentOperator: {string}|null,
 *   expression: {
 *     logicOperator: 'and',
 *     childExpressions: [
 *       {attribute: 'os', operator: 'endsWith', values: ['foo', 'bar']},
 *       {attribute: 'ip', operator: 'isIn', values: ['1.1.1.1/24', '1.1.1.1-2.2.2.2']}
 *     ]
 *   }
 *   currentAttribute: 'hostname'
 *   currentOperator: 'is'
 *
 *   Output 1: values {Map}
 *   new Map([
 *     ['attributeAndOperator', [{value: 'hostname', resourceKey: 'attribute', name: 'hostname'}, {value: 'is', resourceKey: 'operator', name: 'is'}]],
 *     [
 *       'expression', [
 *         {value: 'os_endsWith_foo', attribute: 'os', operator: 'endsWith', name: 'foo'},
 *         {value: 'os_endsWith_bar', attribute: 'os', operator: 'endsWith', name: 'bar'},
 *         {value: 'ip_isIn_1.1.1.1/24', attribute: 'ip', operator: 'isIn', name: '1.1.1.1/24'},
 *         {value: 'ip_isIn_1.1.1.1-2.2.2.2', attribute: 'ip', operator: 'isIn', name: '1.1.1.1-2.2.2.2'},
 *       ],
 *     ],
 *   ])
 * -------------------------------
 * Example 2: condition expression
 *   Input 2: expression {object}, currentAttribute: {string}|null, currentOperator: {string}|null,
 *   expression: {attribute: 'hostname', operator: 'is', values: ['foo', 'bar']}
 *   currentAttribute: 'os'
 *   currentOperator: 'is'
 *
 *   Output 2: values {Map}
 *   new Map([
 *     ['attributeAndOperator', [{value: 'os', resourceKey: 'attribute', name: 'os'}, {value: 'is', resourceKey: 'operator', name: 'is'}]],
 *     [
 *       'expression', [
 *         {value: 'hostname_is_foo', attribute: 'hostname', operator: 'is', value: 'foo'},
 *         {value: 'hostname_is_bar', attribute: 'hostname', operator: 'is', value: 'bar'},
 *       ]
 *     ]
 *   ])
 * -------------------------------
 * @param expression
 * @param currentAttribute
 * @param currentOperator
 * @returns {Map<string|*[], string>}
 */
export const expressionToSelectorValues = ({expression, currentAttribute, currentOperator}) => {
  const attribute = getAttributeSelectorOption(attributeOptions[currentAttribute]);
  const operator = getOperatorSelectorOption(operatorOptions[currentOperator]);
  const entries = [
    // add the attributeAndOperator resource and its values
    [resourceIds.attributeAndOperator, [...(attribute ? [attribute] : []), ...(operator ? [operator] : [])]],
    // add the expression resource and its values
    [
      resourceIds.expression,
      [
        // HACK: insert a dummy value so that selector will always call formatResource on the
        // expression resource
        {value: 'dummy', dummy: true},

        // convert the expression to a 1D array of selector values
        ...expressionToSelectorValuesArray(expression),
      ],
    ],
  ];

  return new Map(entries);
};

/**
 * returns true if the attribute selection is valid; returns false otherwise;
 * @param attribute - id of the attribute
 * @returns {boolean}
 */
export const isAttributeValid = attribute => Boolean(attribute) && Boolean(attributeOptions[attribute]?.id);

/**
 * returns true if the attribute selection is valid, and the operator selection is valid for the attribute;
 * returns false otherwise;
 * @param attribute - id of the attribute
 * @param operator - id of the operator
 * @returns {boolean}
 */
export const isOperatorValid = (attribute, operator) =>
  isAttributeValid(attribute) &&
  Boolean(operator) &&
  attributeOptions[attribute].operators.some(op => op.id === operator);

/**
 * returns a valid attribute selection;
 * if the attribute selection is invalid, returns the first item from attributeOptions;
 * if the attribute selection is valid, returns the attribute selection;
 * @param attributeSelection
 */
export const getSupportedAttributeOption = attributeSelection => {
  if (!isAttributeValid(attributeSelection?.value)) {
    return getAttributeSelectorOption(defaultAttributeOption);
  }

  return attributeSelection;
};

/**
 * returns a valid operator option for the selected attribute option;
 * if the attribute selection is invalid, return null;
 * if the operator selection is invalid, return the first operator for the attribute;
 * if the operator selection is valid, just return the operator selection;
 * @param attributeSelection
 * @param operatorSelection
 * @returns {*|{resourceKey: string, name, value}|null}
 */
export const getSupportedOperatorOption = (attributeSelection, operatorSelection) => {
  if (!isAttributeValid(attributeSelection?.value)) {
    return null;
  }

  if (!isOperatorValid(attributeSelection.value, operatorSelection?.value)) {
    return getOperatorSelectorOption(attributeOptions[attributeSelection.value].operators[0]);
  }

  return operatorSelection;
};

/**
 * checks if a given value exists in the selector values Map; returns true if the
 * value exists in the values Map's expression resource, and false otherwise.
 * @param value - value object
 * @param values - selector values Map
 * @param attribute - selected attribute
 * @param operator - selected operator
 * @returns {boolean}
 */
export const isExpressionValueSelected = ({value, values}) => {
  return (values.get(resourceIds.expression) ?? []).some(item => item.value === value);
};

/**
 * Reads the selected attribute and operator values from the selector's values Map;
 * If the attribute or operator is not selected in the values Map, undefined is returned
 * instead of an attribute or operator.
 * @param values
 * @returns {{attribute: *, operator: *}}
 */
export const getAttributeAndOperatorFromSelectorValues = values => {
  const selections = values.get(resourceIds.attributeAndOperator) ?? [];
  const attribute = selections.find(item => item?.resourceKey === resourceIds.attribute);
  const operator = selections.find(item => item?.resourceKey === resourceIds.operator);

  return {attribute, operator};
};

/**
 * returns true if the expression contains no values in any of the conditions
 * @param expression
 * @returns {boolean}
 */
export const isExpressionEmpty = expression => {
  if (!expression) {
    return true;
  }

  // expression is a condition; check if there are any values;
  if (!expression.hasOwnProperty('childExpressions')) {
    return !expression.values || expression.values.length === 0;
  }

  // expression is complex; recursively check the child expressions;
  return expression.childExpressions.every(childExpr => isExpressionEmpty(childExpr));
};

/**
 * returns true if the expression does not contain any values for the current
 * attribute/operator combination.
 * @param expression
 * @param currentAttribute
 * @param currentOperator
 * @returns {*|boolean}
 */
export const isNewAttributeOperator = ({expression, currentAttribute, currentOperator}) => {
  // return true if the current attribute or operator is falsy;
  if (!currentAttribute || !currentOperator) {
    return true;
  }

  // return true if there is no expression;
  if (!expression) {
    return true;
  }

  // expression is a condition; check if the attribute/operator is different, or values are empty;
  if (!expression.hasOwnProperty('childExpressions')) {
    return (
      expression.attribute !== currentAttribute || expression.operator !== currentOperator || !expression.values?.length
    );
  }

  // expression is complex; check if any of the child expressions are not new and invert the result;
  return !expression.childExpressions.some(
    childExpr => !isNewAttributeOperator({expression: childExpr, currentAttribute, currentOperator}),
  );
};

/**
 * returns the correct resource validator for the attribute/operator;
 * @param attribute
 * @param operator
 * @returns {validateRegex|validateIP|validateHostname|validateProcess|validateOS|(function({query: *, operator: *}=): ([]|{length}|*))|*|((...args: any[]) => void)}
 */
export const getExpressionResourceValidator = ({attribute, operator}) => {
  if (operator === operatorOptions.regex.id) {
    return validateRegex;
  }

  switch (attribute) {
    case attributeOptions.ip.id:
      return validateIP;
    case attributeOptions.hostname.id:
      return validateHostname;
    case attributeOptions.port.id:
      return validatePortAndOrProtocol;
    case attributeOptions.process.id:
      return validateProcess;
    case attributeOptions.os.id:
      return validateOS;
    default:
      return _.noop;
  }
};

/**
 * returns selector options for the regex operator; used for any attribute that supports regex;
 * @param query
 * @param attribute
 * @param operator
 * @returns {{num_matches: number, matches: {inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}[]}|{num_matches: number, error, matches: *[]}|{num_matches: number, matches: *[]}}
 */
export const getRegexOptions = ({query, attribute, operator} = {}) => {
  query ??= '';

  if (!query) {
    return {matches: [], num_matches: 0};
  }

  try {
    validateRegex({query});
  } catch (error) {
    return {matches: [], num_matches: 0, error: error.message};
  }

  return {matches: [getSelectorOption({attribute, operator, value: query})], num_matches: 1};
};

/**
 * returns selector options for the hostname attribute that match the query (all operators except regex);
 * @param query
 * @param attribute
 * @param operator
 * @returns {{num_matches: number, matches: {inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}[]}|{num_matches: number, matches: *[]}}
 */
export const getHostnameOptions = ({query = '', attribute, operator} = {}) => {
  query = query?.trim() ?? '';

  if (!query) {
    return {matches: [], num_matches: 0};
  }

  try {
    validateHostname({query, operator});
  } catch (error) {
    if (!(error instanceof TypeError)) {
      console.error(error);
    }

    return {matches: [], num_matches: 0};
  }

  return {matches: [getSelectorOption({attribute, operator, value: query})], num_matches: 1};
};

/**
 * returns selector options for the ip attribute that match the query;
 * @param query
 * @param attribute
 * @param operator
 * @returns {{num_matches: number, matches: {inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}[]}|{num_matches: number, matches: *[]}}
 */
export const getIPAddressOptions = ({query = '', attribute, operator} = {}) => {
  query ??= '';

  if (!query) {
    return {matches: [], num_matches: 0};
  }

  try {
    validateIP({query, operator});
  } catch {
    return {matches: [], num_matches: 0};
  }

  return {matches: [getSelectorOption({attribute, operator, value: query})], num_matches: 1};
};

/**
 * returns selector options for the process attribute that match the query; (used for all operators except regex)
 * @param query
 * @param attribute
 * @param operator
 * @returns {{num_matches: number, matches: {inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}[]}|{num_matches: number, matches: *[]}}
 */
export const getProcessOptions = ({query = '', attribute, operator} = {}) => {
  query ??= '';

  if (!query) {
    return {matches: [], num_matches: 0};
  }

  return {matches: [getSelectorOption({attribute, operator, value: query})], num_matches: 1};
};

/**
 * returns selector options for the port/protocol attribute that match the query;
 * @param query
 * @param attribute
 * @param operator
 * @returns {{num_matches: number, matches: {inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}[]}|{num_matches: number, matches: *[]}}
 */
export const getPortAndOrProtocolOptions = ({query, attribute, operator} = {}) => {
  query = (query ?? '').trim();

  if (!query) {
    return {matches: [], num_matches: 0};
  }

  let matches = [];

  try {
    matches = validatePortAndOrProtocol({query, operator});
  } catch (error) {
    if (!(error instanceof TypeError)) {
      console.error(error);
    }

    return {matches: [], num_matches: 0};
  }

  return {
    matches: matches.map(match =>
      getSelectorOption({
        attribute,
        operator,
        value: match.value,
        name: match.value,
        inputValue: query,
        conditionValue: match.detail,
      }),
    ),
    num_matches: matches.length,
  };
};

/**
 * returns selector options for the os attribute; (used for all operators except regex)
 * @param query
 * @param attribute
 * @param operator
 * @returns {{num_matches: number, matches: *[]}|{num_matches, matches: *}}
 */
export const getOSOptions = ({query = '', attribute, operator} = {}) => {
  query ??= '';

  if (operator === operatorOptions.is.id) {
    const options = attributeOptions.os.values.map(v =>
      getSelectorOption({attribute, operator, value: v.id, name: v.label}),
    );

    return {
      matches: options,
      num_matches: options.length,
    };
  }

  if (!query) {
    return {matches: [], num_matches: 0};
  }

  return {
    matches: [getSelectorOption({attribute, operator, value: query})],
    num_matches: 1,
  };
};

/**
 * returns the options for the specified attribute and operator that match the query;
 * @param query
 * @param attribute
 * @param operator
 * @returns {[]|[{inputValue: string, name: string, conditionValue: string|object, attribute: string, value: string, operator: string}]|*|{num_matches: number, matches: []}|{num_matches: number, error: *, matches: []}|{num_matches: number, matches: [{inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}]}|*[]|[{inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}]|{inputValue: string, name: string, conditionValue: *, attribute: string, value: string, operator: string}[]|*[]}
 */
export const getResourceStatics = ({query, attribute, operator}) => {
  if (!attribute || !operator) {
    return [];
  }

  if (operator === operatorOptions.regex.id) {
    return getRegexOptions({query, attribute, operator}).matches;
  }

  switch (attribute) {
    case attributeOptions.os.id:
      return getOSOptions({query, attribute, operator}).matches;
    case attributeOptions.ip.id:
      return getIPAddressOptions({query, attribute, operator}).matches;
    case attributeOptions.hostname.id:
      return getHostnameOptions({query, attribute, operator}).matches;
    case attributeOptions.process.id:
      return getProcessOptions({query, attribute, operator}).matches;
    case attributeOptions.port.id:
      return getPortAndOrProtocolOptions({query, attribute, operator}).matches;
    default:
      return [];
  }
};

export const isSelectorValuesExpressionEmpty = selectorValues =>
  !(selectorValues.get(resourceIds.expression) ?? []).some(
    item =>
      isAttributeValid(item?.attribute) && isOperatorValid(item?.attribute, item?.operator) && Boolean(item?.value),
  );

export const ExpressionSelectorFooter = ({close = _.noop}) => (
  <div className={styles.expressionSelectorFooter}>
    <Button size="small" color="standard" text={intl('Common.Close')} onClick={close} />
  </div>
);
