/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import apiSaga from 'api/apiSaga';
import {all, call, select, put} from 'redux-saga/effects';
import {fetchAllUsers} from 'containers/User/UserSagas';
import {getPairingProfiles, getPairingProfilesCount, getGridSettings, getFilterMap} from './PairingProfileListState';
import gridSaga from 'components/Grid/GridSaga';
import {cachedResponses} from 'api/apiCache';
import {isPairingProfilesEnabled} from '../PairingProfileState';
import {errorUtils} from '@illumio-shared/utils';

export function* fetchPairingProfiles({filter, force = false} = {}) {
  const query = {max_results: 500, representation: 'pairing_profile_labels'};

  if (filter) {
    for (const [name, values] of Object.entries(filter)) {
      if (name === 'labelsAndLabelGroups') {
        query.xxxlabels = [values.map(({href}) => href)];
      } else if (name === 'ven_type') {
        query.ven_type = values[0].id;
      } else if (name === 'xxxlabels') {
        query.xxxlabels = values[0];
      } else {
        const value = values[0];

        query[name] = typeof value === 'object' ? value.value : value;
      }
    }
  }

  return yield call(apiSaga, 'pairing_profiles.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (force || list !== (yield select(getPairingProfiles)) || count !== (yield select(getPairingProfilesCount))) {
        yield put({type: 'PAIRING_PROFILE_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removePairingProfiles({hrefs}) {
  yield call(apiSaga, 'pairing_profiles.delete', {
    data: {profiles: hrefs.map(href => ({href}))},
    *onDone() {
      // Invalid labels list cache
      cachedResponses.removeByMethodName('labels.get_collection');
    },
    hrefs,
  });
}

export function* fetchPairingProfileList(route, refetch = false, options = {}) {
  const {customFilter} = options;
  const pairingProfilesIsEnabled = yield select(isPairingProfilesEnabled);

  if (!pairingProfilesIsEnabled) {
    throw new errorUtils.RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    filterMap: getFilterMap,
    *onSaga({filterParams}) {
      const [{list}] = yield all([
        call(fetchPairingProfiles, {
          filter: filterParams.isEmpty
            ? customFilter
            : _.mergeWith({}, filterParams.valid, customFilter, (objValue, srcValue) => {
                if (Array.isArray(objValue)) {
                  return objValue.concat(srcValue);
                }
              }),
          force: refetch,
        }),
        call(fetchAllUsers, {force: refetch}),
      ]);

      return list && list.length;
    },
  });
}
