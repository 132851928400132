/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

import {createSelector} from 'reselect';
import {getRouteParams} from 'containers/App/AppState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridConfig} from 'containers/AppGroups/Vulnerabilities/AppGroupVulnerabilitiesConfig';
import {roundNumber} from 'components/Vulnerability/VulnerabilityUtils';

export default {
  vulnerabilities(state = {}, action) {
    switch (action.type) {
      case 'APP_GROUP_VULNERABILITIES':
        return {...state, [action.key]: action.data};
      default:
        return state;
    }
  },
};

export const getVulnerabilities = state => state.appGroup.vulnerabilities;
const getAppGroups = state => state.appGroup.appGroups;

export const getAppGroupInstance = createSelector([getRouteParams, getAppGroups], (routeParams, appGroups) =>
  appGroups.find(appGroup => appGroup.href === routeParams.id),
);

const getVulnerabilityRows = createSelector([getAppGroupInstance, getVulnerabilities], (appGroup, vulnerabilities) => {
  if (!appGroup?.href || !vulnerabilities[appGroup.href]) {
    return [];
  }

  return Object.values(vulnerabilities[appGroup.href].instances)
    .flat()
    .map((instance, index) => ({...instance, key: index}));
});

export const getTotalVEScore = createSelector(
  [getAppGroupInstance, getVulnerabilities],
  (appGroup, vulnerabilities) => {
    if (!appGroup?.href || !vulnerabilities[appGroup.href]?.aggregatedValues) {
      return null;
    }

    return roundNumber(vulnerabilities[appGroup.href].aggregatedValues.vulnerabilityExposureScore);
  },
);

const getGridSettings = createSelector([], () => gridConfig);

export const getVulnerabilitiesGrid = createSelector([state => state], state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getVulnerabilityRows,
  }),
);
