/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/ServiceListState';
import detailReducers from './Item/ServiceItemState';

export default {
  service: combineReducers({
    ...listReducers,
    ...detailReducers,
  }),
};
