/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import listReducers from './List/ScopeListState';
import instanceReducers from './Detail/ScopeDetailState';

export default {
  scope: combineReducers({
    ...listReducers,
    ...instanceReducers,
  }),
};
