/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {Icon, StatusIcon} from 'components';
import {eventKeyMap, repositoryDescriptions} from '../EventsUtils';
import {createSelector} from 'reselect';

const repositoryDescriptionsObj = repositoryDescriptions();
export const getForwardingEventTypes = data => {
  const events = {...data.audit_event_logger, ...data.traffic_event_logger, ...data.node_status_logger};

  return Object.keys(events)
    .reduce((result, key) => {
      if (events[key] && key !== 'min_severity') {
        result.push(eventKeyMap()[key]);
      }

      return result;
    }, [])
    .join(', ');
};

export const superclusterGridSettings = createSelector([], () => ({
  id: 'supercluster',
  sort: 'repository',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  columns: {
    checkboxes: {},
    pce: {
      header: intl('Common.PCE'),
      value: ({row}) => row.data.pce_scope.join(', '),
    },
    repository: {
      header: intl('Events.Repository'),
      value: ({row}) => row.data.description,
      format: ({row, value}) => {
        let icon;

        if (row.data.remote_syslog) {
          icon = row.data.remote_syslog.tls_enabled ? (
            <Icon name="lock" position="before" title={repositoryDescriptionsObj.encrypted} />
          ) : (
            <StatusIcon title={repositoryDescriptionsObj.notEncrypted} status="warning" position="before" />
          );
        }

        return (
          <span>
            {icon}
            {value}
          </span>
        );
      },
    },
    events: {
      header: intl('Common.Events'),
      value: ({row}) => getForwardingEventTypes(row.data),
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['pce'], size: 'auto'},
      {columns: ['repository'], size: 'auto'},
      {columns: ['events'], size: 'auto'},
    ],
  ],
}));

export const nonSuperclusterGridSettings = createSelector([], () => ({
  id: 'nonsupercluster',
  sort: 'repository',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showManager: false,
  columns: {
    checkboxes: {},
    repository: {
      header: intl('Events.Repository'),
      value: ({row}) => row.data.description,
      format: ({row, value}) => {
        let icon;

        if (row.data.remote_syslog) {
          icon = row.data.remote_syslog.tls_enabled ? (
            <Icon name="lock" position="before" title={repositoryDescriptionsObj.encrypted} />
          ) : (
            <StatusIcon title={repositoryDescriptionsObj.notEncrypted} status="warning" position="before" />
          );
        }

        return (
          <span>
            {icon}
            {value}
          </span>
        );
      },
    },
    events: {
      header: intl('Common.Events'),
      value: ({row}) => getForwardingEventTypes(row.data),
    },
  },
  templates: [
    [
      {columns: ['checkboxes'], size: 'max-content'},
      {columns: ['repository'], size: 'auto'},
      {columns: ['events'], size: 'auto'},
    ],
  ],
}));
