/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {Tooltip, type TooltipProps} from 'components';
import styles from './ModalFooter.css';
import type {ComponentPropsWithoutRef} from 'react';
import {typesUtils} from '@illumio-shared/utils';

/**
 * actions shape = {close: @type [function]}
 */
type Actions = {
  close(): void;
};

export interface ModalFooterProps extends ComponentPropsWithoutRef<'div'>, ThemeProps {
  // whether we should render a singleton or not
  noSingleton?: boolean;
  // we can pass props to the singleton if we want
  singletonProps?: TooltipProps;
  actions?: Actions;
  children: typesUtils.ReactStrictNode;
}

export default function ModalFooter(props: ModalFooterProps): JSX.Element {
  const {
    actions,
    children,
    theme,
    noSingleton = false, // by default we render the ModalFooter inside of a singleton
    singletonProps,
    ...elementProps
  } = mixThemeWithProps(styles, props);

  elementProps.className = theme.footer;
  elementProps['data-tid'] = 'comp-dialog-footer';

  return (
    <div {...elementProps}>
      <div className={theme.actions} data-tid="comp-dialog-actions">
        {noSingleton ? children : <Tooltip.Singleton {...singletonProps}>{children}</Tooltip.Singleton>}
      </div>
    </div>
  );
}
