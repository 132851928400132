/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call} from 'redux-saga/effects';
import {cachedResponses} from 'api/apiCache';
import {isAPIAvailable} from 'api/apiUtils';
import {errorUtils} from '@illumio-shared/utils';

export function* createLabels(payload) {
  return yield call(apiSaga, 'labels.create', {
    data: payload,
    *onDone() {
      // After label is created invalidate list cache
      cachedResponses.removeByMethodName('labels.get_collection');
      cachedResponses.removeByMethodName('label_dimensions.get_collection');
    },
  });
}

export function* verifyUserPermissions({name, params: {id}}) {
  if (name.endsWith('edit') && !isAPIAvailable('label.update')) {
    throw new errorUtils.RedirectError({
      to: 'labels.item.view',
      params: {id},
      proceedFetching: true,
      thisFetchIsDone: true,
    });
  }

  if (name.endsWith('create') && !isAPIAvailable('labels.create')) {
    throw new errorUtils.RedirectError({to: 'labels', proceedFetching: true, thisFetchIsDone: true});
  }
}
