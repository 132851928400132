/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';
import {reverseProviderConsumer, getAppGroupLabelTypes} from 'containers/App/AppState';
import {createAppGroupName, createAppGroupLabelsQuery} from 'containers/Reporting/ReportingUtils';
import {getAppGroups, getAppGroupLabels} from 'containers/AppGroups/AppGroupState';
import {getRecentFilters, getSavedFilters} from 'containers/Reporting/Recurrence/EditModal/RecurrenceEditState';
import {getAllServices} from 'containers/Service/List/ServiceListState';
import {
  convertLegacyQueries,
  filterLegacyQueries,
  transformQueries,
} from 'containers/IlluminationMap/Filter/MapFilterApiUtils';
import {getUserSettings} from 'containers/User/Settings/SettingsState';
import {isUserScoped} from 'containers/User/UserState';

export default {
  resultsDeleteInProgressState(state = {}, action) {
    switch (action.type) {
      case 'EXPLORER_DELETE_RESULT_INIT':
        return {...state, [action.data]: true};
      case 'EXPLORER_DELETE_RESULT_COMPLETE':
        delete state[action.data];

        return state;
      default:
        return state;
    }
  },
};

export const getResultsDeleteInProgressState = state => state.map.resultsDeleteInProgressState;
const getResults = state => state.map.results;

export const getAppGroupsList = createSelector(
  [getAppGroups, getAppGroupLabels, getAppGroupLabelTypes],
  (appGroups, labels, labelTypes) =>
    (appGroups || []).map(appGroup => {
      const name = createAppGroupName(appGroup.label_ids, labels, labelTypes);

      return {
        value: name,
        detail: {
          ...appGroup,
          name,
          labelsQuery: createAppGroupLabelsQuery(appGroup.label_ids, labels),
        },
      };
    }),
);

export const getAllSavedFilterNames = createSelector([getSavedFilters], savedFilters =>
  _.map(savedFilters, filter => (filter?.queryName || filter?.label || '').trim()),
);

export const getFavorites = createSelector(
  [getSavedFilters, getRecentFilters, getAppGroupsList, getAllServices],
  (savedFilters, recentFilters, appGroups, services) => ({
    recentFilters: transformQueries(filterLegacyQueries(recentFilters || [], appGroups)),
    savedFilters: transformQueries(convertLegacyQueries(savedFilters || [], appGroups, services)),
  }),
);

export const getMapFilterState = createSelector(
  [reverseProviderConsumer, getFavorites, getAllSavedFilterNames],
  (isReverseProviderConsumerStatus, {recentFilters, savedFilters}, savedFilterNames) => ({
    isReverseProviderConsumerStatus,
    recentFilters,
    savedFilters,
    savedFilterNames,
  }),
);

export const getResultsDialogData = createSelector(
  [getResults, getUserSettings, isUserScoped, getFavorites],
  (results, userSettings, isScopedUser, favorites) => ({
    results: (results ?? []).map(result => ({...result, key: result.href})),
    userSettings,
    isScopedUser,
    recentFilters: favorites?.recentFilters,
    savedFilters: favorites?.savedFilters,
  }),
);
