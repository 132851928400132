/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import _ from 'lodash';
import {isUserReadOnly} from 'containers/User/UserState';
import {getInterfacesForSwitch} from '../InterfaceState';
import {getWorkloadsHrefMap} from '../../Workload/List/WorkloadListState';
import {getNetworkEnforcementNodes} from '../NetworkEnforcementNodeState';
import {getWorkloadName} from 'containers/Workload/WorkloadUtils';

export default {
  switchInstance(state = {}, action) {
    switch (action.type) {
      case 'SWITCH_GET_INSTANCE':
        return action.data.switchInstance;
      default:
        return state;
    }
  },
};

export const getSwitchInstance = state => state.switch.switchInstance;

export const getSwitchDetail = createSelector(
  [getSwitchInstance, getInterfacesForSwitch, getWorkloadsHrefMap, getNetworkEnforcementNodes, isUserReadOnly],
  (switchInstance, interfaces, workloadsMap, networkEnforcementNodes, userIsReadOnly) => {
    const switchNEN = networkEnforcementNodes.find(
      nen => _.get(switchInstance, 'network_enforcement_node.href') === nen.href,
    );
    const switchInstanceConfig = switchInstance ? switchInstance.config : {};

    const interfaceAttributeListData = interfaces.map(
      interfaceInst =>
        interfaceInst && {
          key: 'ifName',
          value: interfaceInst.config.name,
        },
    );

    const ifIndexAttributeListData = interfaces.map(
      interfaceInst =>
        interfaceInst && {
          key: interfaceInst.config.name,
          value: interfaceInst.config.traffic_flow_id || intl('Common.NA'),
        },
    );

    const workloadAttributeListData = interfaces.map(interfaceInst => {
      if (interfaceInst) {
        const interfaceWorkloads = interfaceInst.workloads.map(({href: workloadHref}) => {
          const workload = workloadsMap[workloadHref];

          return workload ? {name: getWorkloadName(workload), key: workloadHref} : null;
        });

        return {
          key: interfaceInst.config.name,
          value: interfaceWorkloads.length ? interfaceWorkloads : intl('Common.NA'),
        };
      }

      return {key: '', value: ''};
    });

    return {
      interfaces,
      interfaceAttributeListData,
      ifIndexAttributeListData,
      workloadAttributeListData,
      switchNEN,
      switchInstance,
      switchInstanceConfig,
      userIsReadOnly,
    };
  },
);
