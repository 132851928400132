/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {put, call} from 'redux-saga/effects';
import apiSaga from 'api/apiSaga';
import {cachedResponses} from 'api/apiCache';
import {hrefUtils} from '@illumio-shared/utils';
import gridSaga from 'components/Grid/GridSaga';
import {gridSettings} from './LabelSettingsConfig';

export function* fetchLabelSettingsList(route, refetch = false) {
  return yield call(gridSaga, {
    route,
    settings: gridSettings,
    *onSaga() {
      const {list} = yield call(fetchLabelSettingsPage, {force: refetch});

      return list?.length ?? 0;
    },
  });
}

export function* fetchLabelSettings({force = false} = {}) {
  return yield call(apiSaga, 'label_dimensions.get_collection', {
    cache: !force,
    *onDone({data: list, count}) {
      yield put({
        type: 'LABEL_SETTINGS_GET_LIST',
        data: {list, count},
      });

      return {list, count};
    },
  });
}

export function* fetchLabelSettingsPage({force = false} = {}) {
  const limitNumber = yield call(apiSaga, 'label_dimensions.obj_limits');

  return yield call(apiSaga, 'label_dimensions.get_collection', {
    cache: !force,
    *onDone({data: list, count}) {
      yield put({
        type: 'LABEL_SETTINGS_GET_LIST',
        data: {list, count: {...count, limitNumber: limitNumber.data}},
      });

      return {list, count: {...count, limitNumber: limitNumber.data}};
    },
  });
}

export function* createNewLabelType(payload) {
  return yield call(apiSaga, 'label_dimensions.create', {
    data: payload,
    *onDone() {
      cachedResponses.removeByMethodName('label_dimensions.get_collection');
    },
  });
}

export function* updateLabelType(id, payload) {
  return yield call(apiSaga, 'label_dimension.update', {
    params: {label_dimension_id: id},
    data: payload,
    *onDone() {
      cachedResponses.removeByMethodName('label_dimensions.get_instance');
      cachedResponses.removeByMethodName('label_dimensions.get_collection');
    },
  });
}

export function* deleteLabelType({href}) {
  return yield call(apiSaga, 'label_dimension.delete', {
    params: {label_dimension_id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}
