/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';
import {createSelector} from 'reselect';
import {isUserReadOnlyClusterInsensitive, isUserWorkloadManager} from 'containers/User/UserState';
import listReducers from './List/SupportReportsListState';

export default {
  supportReports: combineReducers({
    ...listReducers,
  }),
};

export const isSupportReportsEnabled = createSelector(
  [isUserReadOnlyClusterInsensitive, isUserWorkloadManager],
  (userIsReadOnlyClusterInsensitive, userIsWorkloadManager) =>
    !userIsReadOnlyClusterInsensitive || userIsWorkloadManager,
);
