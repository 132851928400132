/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createContext, type MouseEventHandler} from 'react';

export type ModalContextValue = {
  onClose: MouseEventHandler;
  tid?: string;
  getModalRef(): HTMLElement | undefined;
  zIndex: number;
};

export const ModalContext = createContext({} as ModalContextValue);
