/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select, put, all} from 'redux-saga/effects';
import {getAccessRestrictions, getAccessRestrictionsCount, getGridSettings} from './AccessRestrictionListState';
import gridSaga from 'components/Grid/GridSaga';
import _ from 'lodash';
import {hrefUtils, errorUtils} from '@illumio-shared/utils';

export function* fetchAccessRestrictions({force = false} = {}) {
  const query = {max_results: 500};

  return yield call(apiSaga, 'access_restrictions.get_collection', {
    query,
    cache: !force,
    *onDone({data: list, count}) {
      if (
        force ||
        list !== (yield select(getAccessRestrictions)) ||
        count !== (yield select(getAccessRestrictionsCount))
      ) {
        yield put({type: 'ACCESS_RESTRICTIONS_GET_LIST', data: {list, count}});
      }

      return {list, count};
    },
  });
}

export function* removeAccessRestrictions({hrefs}) {
  const removed = [];
  const errors = new Map();
  let apiMessageExist = false;
  let error;

  yield all(
    hrefs.map(function* (href) {
      try {
        yield call(apiSaga, 'access_restriction.delete', {
          params: {id: hrefUtils.getId(href)},
        });

        removed.push(href);
      } catch (err) {
        const apiMessage = _.get(err, 'data[0].message');
        const message = typeof apiMessage === 'string' && apiMessage.length > 0 ? apiMessage : err.message;
        const hrefs = errors.get(message) || [];

        error = err;
        hrefs.push(href);
        errors.set(message, hrefs);
        apiMessageExist ||= apiMessage === message;
      }
    }),
  );

  if (errors.size) {
    throw errors.size === 1 && !apiMessageExist ? error : new errorUtils.APIError({errors, removed});
  }
}

export function* removeAccessRestriction({href}) {
  yield call(apiSaga, 'access_restriction.delete', {
    params: {id: hrefUtils.getId(href)},
    hrefs: [href],
  });
}

export function* fetchAccessRestrictionsList(route, refetch = false) {
  if (__ANTMAN__ && !__MSP__) {
    throw new errorUtils.RedirectError({to: 'landing', proceedFetching: true, thisFetchIsDone: true});
  }

  yield call(gridSaga, {
    route,
    settings: getGridSettings,
    *onSaga() {
      const {list} = yield call(fetchAccessRestrictions, {
        force: refetch,
      });

      return list.length;
    },
  });
}
