/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {isUserReadOnlyClusterInsensitive} from 'containers/User/UserState';
import {getRouteParams, isClasEnabled} from '../../App/AppState';
import {getClusters, getLocalFQDN, getLocalStatus} from 'containers/Health/HealthState';
import {isLocalPce} from 'containers/App/AppUtils';

export default {
  detail(state = {}, action) {
    switch (action.type) {
      case 'CONTAINER_CLUSTER_GET_DETAIL':
        return action.data.detail;
      default:
        return state;
    }
  },
};

export const getContainerClusterDetail = state => state.containerClusters?.detail;

export const isLocalContainerCluster = createSelector(
  [getClusters, getContainerClusterDetail, getLocalFQDN],
  (clusters, item, localFqdn) => {
    if (clusters.length < 2) {
      return true; // on an SNC, item.fqdn might be null (never recorded).
    }

    return item.pce_fqdn === localFqdn;
  },
);

export const getContainerClusterDetailPage = createSelector(
  [
    getContainerClusterDetail,
    getRouteParams,
    isUserReadOnlyClusterInsensitive,
    getClusters,
    getLocalFQDN,
    getLocalStatus,
    isClasEnabled,
  ],
  (detail, {id, token}, userIsReadOnlyClusterInsensitive, clusters, local, health, isClasEnabled) => ({
    ...detail,
    id,
    token,
    userIsReadOnlyClusterInsensitive,
    isLocalPce: isLocalPce({clusters, local, pceFqdn: detail && detail.pce_fqdn}),
    clusters,
    local,
    health,
    isClasEnabled,
  }),
);
