/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {getAllUsersMap, isUserScoped} from 'containers/User/UserState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './VersionDetailConfig';
import {getTypeAndRoute, formatCountsForTally, getProvisionCounts} from 'containers/Provisioning/ProvisioningUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {getProvisionVersions} from '../List/VersionListState';
import {isAPIAvailable} from 'api/apiUtils';
import {isKubernetesSupported} from 'containers/App/AppState';

export default {
  version(state = {}, action) {
    switch (action.type) {
      case 'PROVISION_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },

  modifiedObjectList(state = [], action) {
    switch (action.type) {
      case 'PROVISION_MODIFIED_OBJECT_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVersion = state => state.provisioning.version;
export const getModifiedObjects = state => state.provisioning.modifiedObjectList;

export const getModifiedObjectsRows = createSelector(
  [getVersion, getModifiedObjects, getAllUsersMap],
  (version, modifiedObjects, usersMap) => {
    let modifiedObjectRows = [];

    modifiedObjectRows = modifiedObjectRows.concat(
      modifiedObjects
        .filter(
          ({object_type: type}) =>
            !(
              (__ANTMAN__ && (type === 'virtual_servers' || type === 'enforcement_boundaries')) ||
              (!isKubernetesSupported && type === 'virtual_services')
            ),
        )
        .map(item => ({
          key: item.href,
          selectable: true,
          // Fill each pending provisioning with user object
          data: {
            ...item,
            ...getTypeAndRoute(item.object_type),
            updated_at: item.updated_at,
            updated_by: fillUserInfo(usersMap, item.updated_by),
          },
        })),
    );

    return modifiedObjectRows;
  },
);

export const getCounts = createSelector([getModifiedObjects], items => getProvisionCounts(items));

const getGrid = state =>
  getGridSelector(state, {
    settings: gridSettings,
    rows: getModifiedObjectsRows,
  });

export const getModifiedObjectsPage = createSelector(
  [getGrid, getCounts, getVersion, getProvisionVersions, isUserScoped],
  (grid, counts, version, versions, userIsScoped) => {
    const hasWritePermission = isAPIAvailable('sec_policy.restore');

    const tallyItems = [
      //conditionally add workloads affected count in tally
      ...(version.workloads_affected > 0
        ? [
            {
              children: intl('Version.Detail.WorkloadsAffected', {count: version.workloads_affected}),
              count: version.workloads_affected,
            },
          ]
        : []),
      ...formatCountsForTally(counts, {versionLabel: true}),
    ];

    return {
      grid,
      tallyItems,
      version,
      hasWritePermission,
      userIsScoped,
      active: versions && versions[0] && version && versions[0].version === version.version,
    };
  },
);
