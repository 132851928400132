/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import styles from './SideNavigationLogo.css';

const filenameExpanded = 'illumio-logo';
const filenameCollapsed = 'illumio-glyph';

interface SideNavigationProps {
  collapsed: boolean;
}

export default function SideNavigationLogo(props: SideNavigationProps): JSX.Element {
  const {collapsed} = props;

  return (
    <div
      className={cx(styles.logosContainer, {[styles.logosContainerCollapsed]: collapsed})}
      {...(__ANTMAN__ && {'data-tid': collapsed ? 'side-navigation-logo-collapsed' : 'side-navigation-logo-expanded'})}
    >
      {!collapsed && !__ANTMAN__ && (
        <div className={styles.expanded}>
          <svg className={styles.svg}>
            <use href={`#${filenameExpanded}`} />
          </svg>
        </div>
      )}
      {collapsed && !__ANTMAN__ && (
        <div className={styles.collapsed}>
          <svg className={styles.svg}>
            <use href={`#${filenameCollapsed}`} />
          </svg>
        </div>
      )}
    </div>
  );
}
