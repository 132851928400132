/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import type {
  GraphSelection,
  GraphSelectionType,
  GraphComboOrEndpoint,
  GraphCombos,
  GraphLink,
  Items,
  ComboId,
} from '../Graph/MapGraphTypes';
import {isManagedEndpoint, type LinkData, type EndpointDataType, type EndpointType} from '../MapTypes';
import {getComboIds} from '../Graph/Utils/MapGraphStyleUtils';
import type {MutableRefObject} from 'react';
import {removeSourceTargetFromString} from '../MapUtils';
import {
  type ConnectionQuickFilterValue,
  getConnectionQuickFilterOptionValues,
  connectionQuickFilterOptions,
} from 'containers/IlluminationMap/ToolBar/QuickFilter/MapQuickFilter';
import type {PolicyVersion} from '../MapPolicyUtils';

export function getSelectionType(selectionObject: GraphSelection = {}): GraphSelectionType {
  const selectedTypes: GraphSelectionType[] = Object.keys(selectionObject).reduce(
    (result: GraphSelectionType[], type: string) => {
      const selection = {...selectionObject};

      delete selection.clickedId;

      if (selection[type as keyof GraphSelection]?.length) {
        result.push(type as GraphSelectionType);
      }

      return result;
    },
    [],
  );

  // selection is empty; return undefined;
  if (selectedTypes.length === 0) {
    return undefined;
  }

  // selection contains one type; return selection type;
  if (selectedTypes.length === 1) {
    return selectedTypes[0];
  }

  // multiple types were selected; return 'multiple';
  return 'multiple';
}

export const getBidirectionalId = (linkId: string): string => (linkId || '').replace(/_bidirectional$/, '');

export const getLinkEndpointDetails = (
  id: ComboId | string,
  items: Items,
  combos: GraphCombos,
): GraphComboOrEndpoint | undefined => {
  const unmanagedDetails = items.unmanagedEndpoints[id];
  const endpointDetails = items.managedEndpoints[id];
  const comboDetails = combos[id as ComboId];

  if (unmanagedDetails) {
    return unmanagedDetails;
  }

  if (endpointDetails) {
    return endpointDetails;
  }

  if (comboDetails) {
    return comboDetails;
  }
};

export const calculatedSelectedLink = (
  selectedLinkId: string,
  selectedComboLinkId: string,
  selectedComboLinkId2: string,
  links: Record<string, GraphLink>,
  comboLinks: Record<string, GraphLink>,
  openComboLinks: Record<string, GraphLink>,
): GraphLink[] => {
  // If it's an individual link
  if (links[selectedLinkId]) {
    return [links[selectedLinkId]];
  }

  // If it's a closed combo link
  if (comboLinks[selectedLinkId]) {
    return [comboLinks[selectedLinkId]];
  }

  if (comboLinks[selectedComboLinkId]) {
    return [comboLinks[selectedComboLinkId]];
  }

  // Open combo links are bi-directional, so show the traffic in both directions
  return [selectedComboLinkId, selectedComboLinkId2].reduce((result: GraphLink[], id): GraphLink[] => {
    if (openComboLinks[id]) {
      result.push(openComboLinks[id]);
    }

    return result;
  }, []);
};

export const calculateSelectedLinkFromSelection = (
  filters: GraphSelection,
  links: Record<string, GraphLink>,
  comboLinks: Record<string, GraphLink>,
  openComboLinks: Record<string, GraphLink>,
): GraphLink[] => {
  const selectedLinkId = (filters.link?.length && filters.link[0]) || '';

  const selectedComboLinkId = getBidirectionalId((filters.comboLink?.length && filters.comboLink[0]) || '');
  const selectedComboLinkId2 = selectedComboLinkId.split(';').reverse().join(';');

  return calculatedSelectedLink(
    selectedLinkId,
    selectedComboLinkId,
    selectedComboLinkId2,
    links,
    comboLinks,
    openComboLinks,
  );
};

export const calculateSelectedLinkFromHover = (
  id: string,
  links: Record<string, GraphLink>,
  comboLinkIds: MutableRefObject<Record<string, string>>,
  comboLinks: Record<string, GraphLink>,
  openComboLinks: Record<string, GraphLink>,
): GraphLink[] => {
  const selectedLinkId = id;
  const selectedComboLinkId = (comboLinkIds && comboLinkIds.current[id]) || '';
  const selectedComboLinkId2 = (selectedComboLinkId && selectedComboLinkId.split(';').reverse().join(';')) || '';

  return calculatedSelectedLink(
    selectedLinkId,
    selectedComboLinkId,
    selectedComboLinkId2,
    links,
    comboLinks,
    openComboLinks,
  );
};

export const comboMatching = (
  link: LinkData,
  endpoint: EndpointType,
  ids: string[],
  openAppGroups: string[] | null,
): boolean => {
  const linkEndpoint = link[endpoint];

  if (isManagedEndpoint(linkEndpoint)) {
    const details = linkEndpoint.details;
    const endpointIds = (details?.labels || []).map(label => label.id);
    const endpointKeys = details && Object.keys(details.labelObject);

    if (openAppGroups) {
      return !openAppGroups.includes(`_appGroup_${details.appGroupId}`);
    }

    if (ids.includes('deleted')) {
      return details.subType === 'deleted';
    }

    if (details.subType === 'deleted') {
      return ids.includes('deleted');
    }

    // If id is not a number, it represents a missing label key.
    // If the link endpoint is also missing that key match the link.
    return ids.every(id => endpointIds.includes(id) || (details && isNaN(Number(id)) && !endpointKeys.includes(id)));
  }

  return false;
};

export const managedMatching = (link: LinkData, endpoint: EndpointType, node: EndpointDataType): boolean => {
  const linkEndpoint = link[endpoint];

  if (node.includes('deleted')) {
    return isManagedEndpoint(linkEndpoint) && linkEndpoint.details.subType === 'deleted';
  }

  return isManagedEndpoint(linkEndpoint) && linkEndpoint.details.href === node?.replace(`_${endpoint}`, '');
};

export const unmanagedMatching = (link: LinkData, endpoint: EndpointType, node: EndpointDataType): boolean =>
  link[endpoint].type === node;

export const calculateQuickFilteredLinks = (
  links: LinkData[],
  filter: GraphSelection,
  quickFilters: Set<ConnectionQuickFilterValue> = new Set(),
  policyVersion: PolicyVersion,
): LinkData[] => {
  const allPolicySelected =
    connectionQuickFilterOptions.every(
      option =>
        option.apiKey !== 'policy' ||
        quickFilters.has(option.value) ||
        (option.value === 'unknown' && policyVersion === 'draft'),
    ) || quickFilters.size === 0;

  const allIpPropertiesSelected =
    connectionQuickFilterOptions.every(option => option.apiKey !== 'ip_property' || quickFilters.has(option.value)) ||
    quickFilters.size === 0;

  const allTransmissionsSelected =
    connectionQuickFilterOptions.every(option => option.apiKey !== 'transmission' || quickFilters.has(option.value)) ||
    quickFilters.size === 0;

  const allDirectionsSelected =
    connectionQuickFilterOptions.every(option => option.apiKey !== 'none' || quickFilters.has(option.value)) ||
    quickFilters.size === 0;

  return links.filter(link => {
    const ipListMatch = ['source', 'target'].some(endpoint =>
      unmanagedMatching(link, endpoint as EndpointType, 'ipList' as EndpointDataType),
    );
    const privateMatch = ['source', 'target'].some(endpoint =>
      unmanagedMatching(link, endpoint as EndpointType, 'privateAddress' as EndpointDataType),
    );
    const publicMatch = ['source', 'target'].some(endpoint =>
      unmanagedMatching(link, endpoint as EndpointType, 'publicAddress' as EndpointDataType),
    );

    const unmanagedMatch = [...quickFilters].some(direction => {
      return (
        allIpPropertiesSelected ||
        (!ipListMatch && !privateMatch && !publicMatch) ||
        (direction === 'iplist' && ipListMatch) ||
        (direction === 'private' && privateMatch) ||
        (direction === 'public' && publicMatch)
      );
    });

    if (!unmanagedMatch) {
      return false;
    }

    const broadcastMatch = link.target.transmission === 'broadcast';
    const multicastMatch = link.target.transmission === 'multicast';

    const transmissionMatch = [...quickFilters].some(direction => {
      return (
        allTransmissionsSelected ||
        (!broadcastMatch && !multicastMatch) ||
        (direction === 'broadcast' && broadcastMatch) ||
        (direction === 'multicast' && multicastMatch)
      );
    });

    if (!transmissionMatch) {
      return false;
    }

    const allowedMatch = (link.policy[policyVersion].decision || ([] as string[])).includes('allowed');
    const blockedMatch = (link.policy[policyVersion].decision || ([] as string[])).includes('blocked');
    const potentiallyBlockedMatch = (link.policy[policyVersion].decision || ([] as string[])).includes(
      'potentiallyBlocked',
    );
    const unknownMatch = (link.policy[policyVersion].decision || ([] as string[])).includes('unknown');

    const policyMatch = [...quickFilters].some(direction => {
      return (
        allPolicySelected ||
        (direction === 'allowed' && allowedMatch) ||
        (direction === 'blocked' && blockedMatch) ||
        (direction === 'potentiallyblocked' && potentiallyBlockedMatch) ||
        (direction === 'unknown' && unknownMatch)
      );
    });

    if (!policyMatch) {
      return false;
    }

    if (filter.combo && filter.combo.length) {
      return filter.combo.some(node => {
        let ids = getComboIds(
          node.includes('superAppGroups') ? node : (node || '').replace('_source', '').replace('_target', ''),
        );

        if (Array.isArray(ids)) {
          ids = ids.map(id => id || 'deleted');
        }

        const comboInboundMatch = comboMatching(link, 'target', ids as string[], null);
        const comboOutboundMatch = comboMatching(link, 'source', ids as string[], null);
        const comboIntrascopeMatch = comboInboundMatch && comboOutboundMatch;

        if (!comboInboundMatch && !comboOutboundMatch) {
          return true;
        }

        if (allDirectionsSelected) {
          return comboInboundMatch || comboOutboundMatch;
        }

        return [...quickFilters].some(direction => {
          if (direction === 'inbound') {
            return comboInboundMatch && !comboIntrascopeMatch;
          }

          if (direction === 'outbound') {
            return comboOutboundMatch && !comboIntrascopeMatch;
          }

          if (direction === 'intrascope') {
            return comboIntrascopeMatch;
          }

          return false;
        });
      });
    }

    return true;
  });
};

export const calculateFilteredLinks = (
  links: LinkData[],
  filter: GraphSelection,
  focusedData: {focusedComboId: string; openComboId: string},
  connectionDirections: Set<ConnectionQuickFilterValue>,
): LinkData[] => {
  const endpointTypes: EndpointType[] = ['source', 'target'];
  const openAppGroups = Object.values(focusedData).map(data => data && removeSourceTargetFromString(data));
  const allDirectionsSelected =
    getConnectionQuickFilterOptionValues().every(value => connectionDirections.has(value)) ||
    connectionDirections.size === 0;

  return links.filter(link => {
    if (filter.combo && filter.combo.length) {
      return filter.combo.some(node => {
        let ids = getComboIds(
          node.includes('superAppGroups') ? node : (node || '').replace('_source', '').replace('_target', ''),
        );

        if (Array.isArray(ids)) {
          ids = ids.map(id => id || 'deleted');
        }

        const comboInboundMatch = comboMatching(link, 'target', ids as string[], null);
        const comboOutboundMatch = comboMatching(link, 'source', ids as string[], null);
        const comboIntrascopeMatch = comboInboundMatch && comboOutboundMatch;

        if (allDirectionsSelected) {
          return comboInboundMatch || comboOutboundMatch;
        }

        return [...connectionDirections].some(direction => {
          if (direction === 'inbound') {
            return comboInboundMatch && !comboIntrascopeMatch;
          }

          if (direction === 'outbound') {
            return comboOutboundMatch && !comboIntrascopeMatch;
          }

          if (direction === 'intrascope') {
            return comboIntrascopeMatch;
          }

          return false;
        });
      });
    }

    if (filter.managed && filter.managed.length) {
      return filter.managed.some(node =>
        ['source', 'target'].some(endpoint =>
          managedMatching(link, endpoint as EndpointType, node as EndpointDataType),
        ),
      );
    }

    if (filter.unmanaged && filter.unmanaged.length) {
      return filter.unmanaged.some(node =>
        ['source', 'target'].some(endpoint =>
          unmanagedMatching(link, endpoint as EndpointType, node as EndpointDataType),
        ),
      );
    }

    if (filter.link && filter.link.length) {
      return filter.link.some(selectedLink => {
        const [source, target] = selectedLink.split(';');
        const linkEndpoint = {source, target};

        return endpointTypes.every(
          endpoint =>
            unmanagedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType) ||
            managedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType),
        );
      });
    }

    if (filter.comboLink && filter.comboLink.length) {
      return filter.comboLink.some(linkId => {
        if (!linkId) {
          return false;
        }

        const selectedLink = getBidirectionalId(linkId);
        const bidirectional = selectedLink !== linkId;

        const [source, target] = selectedLink.split(';');
        const linkEndpoint = {source, target};
        // Do not reverse the non-bidirectional links
        const reverseLinkEndpoint = bidirectional ? {source: target, target: source} : linkEndpoint;

        let unidirectionalAppGroupLink;

        if (focusedData.openComboId && linkId.includes('_appGroup_')) {
          unidirectionalAppGroupLink =
            (focusedData.openComboId.includes('_source') && focusedData.openComboId === linkEndpoint.source) ||
            (focusedData.openComboId.includes('_target') && focusedData.openComboId === linkEndpoint.target)
              ? 'forward'
              : 'reverse';
        }

        let sourceComboIds = getComboIds((source || '').replace('_source', ''));

        if (Array.isArray(sourceComboIds)) {
          sourceComboIds = sourceComboIds.map(id => id || 'deleted');
        }

        let targetComboIds = getComboIds((target || '').replace('_target', ''));

        if (Array.isArray(targetComboIds)) {
          targetComboIds = targetComboIds.map(id => id || 'deleted');
        }

        const linkEndpointCombos = {source: sourceComboIds, target: targetComboIds};
        let directionalOpenAppGroups = [...openAppGroups];

        // If the opposite app group is open, don't look at the connected group when finding the super app group traffic
        if (focusedData.openComboId) {
          endpointTypes.forEach(endpoint => {
            if (linkEndpointCombos[endpoint] === '_superAppGroups' && !focusedData.openComboId.includes(endpoint)) {
              directionalOpenAppGroups = [focusedData.focusedComboId];
            }
          });
        }

        const linkReverseEndpointCombos = bidirectional
          ? {source: targetComboIds, target: sourceComboIds}
          : linkEndpointCombos;

        const linkMatches =
          (!unidirectionalAppGroupLink || unidirectionalAppGroupLink === 'forward') &&
          endpointTypes.every(endpoint =>
            Array.isArray(linkEndpointCombos[endpoint] as string[]) ||
            linkEndpointCombos[endpoint] === '_superAppGroups'
              ? comboMatching(
                  link,
                  endpoint,
                  (linkEndpointCombos[endpoint] || ['deleted']) as string[],
                  linkEndpoint[endpoint].includes('_superAppGroups') ? directionalOpenAppGroups : null,
                )
              : unmanagedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType) ||
                managedMatching(link, endpoint, linkEndpoint[endpoint] as EndpointDataType),
          );

        let reverseLinkMatches = false;

        if (!unidirectionalAppGroupLink || unidirectionalAppGroupLink === 'reverse') {
          reverseLinkMatches = endpointTypes.every(endpoint =>
            Array.isArray(linkReverseEndpointCombos[endpoint] as string[]) ||
            linkEndpointCombos[endpoint] === '_superAppGroups'
              ? comboMatching(
                  link,
                  endpoint,
                  linkReverseEndpointCombos[endpoint] as string[],
                  linkEndpoint[endpoint].includes('_superAppGroups') ? directionalOpenAppGroups : null,
                )
              : unmanagedMatching(link, endpoint, reverseLinkEndpoint[endpoint] as EndpointDataType) ||
                managedMatching(link, endpoint, reverseLinkEndpoint[endpoint] as EndpointDataType),
          );
        }

        return linkMatches || reverseLinkMatches;
      });
    }

    return true;
  });
};

export const calculateFilteredTableLinks = (links: LinkData[], filteredIndex: number[]): LinkData[] => {
  return links.filter(link => filteredIndex.includes(link.index));
};
