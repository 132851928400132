/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Field} from 'formik';
import * as PropTypes from 'prop-types';
import {forwardRefSymbol, forwardRefFactory} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from '../../../FormUtils';
import * as IPListEditorUtils from './IPListEditorUtils';
import IPListEditor from './IPListEditor';
import {useRef} from 'react';

FormIPListEditor.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,
  // By default form selector state and form value are automatically handled by FormOptionSelector itself (uncontrolled).
  // If you want to intercept change event and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,
  // onBlur
  onBlur: PropTypes.func,
  // onFocus
  onFocus: PropTypes.func,
  plugins: PropTypes.array,
  ipv4: PropTypes.bool,
  // Might depend on other fields
  ...whenPropTypes,
};

FormIPListEditor.defaultProps = {
  plugins: [],
  onChange: _.noop,
  onBlur: _.noop,
  onFocus: _.noop,
  ipv4: false,
};

// Assign utils as static prop to be able to easily use it, like `Form.IPListEditor.Utils.getIpMap`
FormIPListEditor.Utils = IPListEditorUtils;

export function FormIPListEditor(props) {
  const {
    [forwardRefSymbol]: ref,
    onChange,
    form,
    field,
    ...richTextareaProps
  } = useWhen(props.form, props.field, props);

  const {name} = field;
  // Use firstTouched as mutable property to indicate first time focus
  const firstTouched = useRef(false);

  richTextareaProps.ref = ref;

  richTextareaProps.errorMessage = _.get(form.touched, name) === true && _.get(form.errors, name, false);

  /* onChange is a callback that is called to the parent with the updated editorState data */
  richTextareaProps.onChange = editorState => {
    const currentContent = editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();

    // Get ip error
    const ipErrors = {
      [name]: IPListEditorUtils.getError(blockMap),
    };

    if (!_.get(form.touched, name) && ipErrors[name].error) {
      form.setFieldTouched(name, true);
    }

    // When all current data is deleted and already focused but not yet touched then set to touched by
    // making sure the initial value had previous value
    if (
      !_.get(form.touched, name) &&
      firstTouched.current === true &&
      currentContent.hasText() === false &&
      props.initialValue?.length > 0
    ) {
      form.setFieldTouched(name, true);
    }

    props.onChange(editorState, name, {ipErrors});
  };

  /* onBlur is an optional callback that can call the parent onBlur */
  richTextareaProps.onBlur = () => {
    // Check to make sure touched isn't already set and firstTouched exist
    if (!_.get(form.touched, name) && firstTouched.current === true) {
      form.setFieldTouched(name, true);
    }

    props.onBlur();
  };

  /* onFocus is an optional callback that can call the parent onFocus */
  richTextareaProps.onFocus = () => {
    if (firstTouched.current === false) {
      // Set firstTouched to indicate Editor was first focused
      firstTouched.current = true;
    }

    props.onFocus();
  };

  return <IPListEditor {...richTextareaProps} />;
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(props => <Field {...props} component={FormIPListEditor} />, {
  hoistSource: FormIPListEditor,
});
