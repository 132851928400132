/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import type {KeyboardEvent} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {Link} from 'components';
import {composeThemeFromProps, Compose, type ThemeProps} from '@css-modules-theme/react';
import {domUtils, typesUtils} from '@illumio-shared/utils';
import styles from './Tally.css';

type Tid = {tidComp?: string; tidLabel?: string; tidCount?: string};
type TallyProps = ThemeProps & {
  status?: 'error' | 'info' | 'warning';
  tallyItems: {
    children: typesUtils.ReactStrictNode;
    count: number;
    link?: string;
    onClick?: domUtils.MouseEventLikeHandler;
  }[];
  justify?: 'center' | 'left' | 'right';
  tid?: Tid;
};

/* Emulate Tally with onClick (but no 'link') to support Enter/Space press like a link */
function handleKeyUp(onClick: domUtils.MouseEventLikeHandler | undefined, evt: KeyboardEvent) {
  if (onClick && (evt.key === 'Enter' || evt.key === ' ')) {
    domUtils.preventEvent(evt);
    onClick(evt);
  }
}

export default function Tally(props: TallyProps): JSX.Element | null {
  const {
    tallyItems,
    justify = 'right',
    tid = {tidComp: 'comp-tally', tidLabel: 'elem-tally-label', tidCount: 'elem-tally-count'},
    status = 'info',
  } = props;

  const theme = composeThemeFromProps(styles, props);

  if (tallyItems?.length <= 0) {
    return null;
  }

  return (
    <div className={theme[justify]}>
      <div className={theme[status]} data-tid={tid.tidComp}>
        {tallyItems.map(({onClick, link, ...tallyItem}, index) => {
          const content = (
            <div className={theme.underline}>
              <span className={theme.count} data-tid={tid.tidCount}>
                {tallyItem.count}
              </span>
              <span className={theme.label} data-tid={tid.tidLabel}>
                {tallyItem.children}
              </span>
            </div>
          );

          return link ? (
            <Link
              key={index}
              theme={styles}
              themeCompose={'merge' as Compose}
              themePrefix="tallyLink-"
              {...(typeof link === 'string' ? {to: link} : link)}
            >
              {content}
            </Link>
          ) : (
            <div
              key={index}
              onClick={onClick}
              onKeyUp={_.partial(handleKeyUp, onClick)}
              tabIndex={onClick ? 0 : -1}
              className={cx(theme.tally, {[theme.tallyClickable]: onClick})}
            >
              {content}
            </div>
          );
        })}
      </div>
    </div>
  );
}
