/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl, {setIntlValueMapper} from '@illumio-shared/utils/intl';
import {createContext} from 'react';
import type {ReactReduxContextValue} from '@illumio-shared/utils/redux';
import type {Router} from '../../router/configureRouter';
import {domUtils} from '@illumio-shared/utils';
import type Fetcher from '../../router/Fetcher';
import Prefetcher from '../../router/Prefetcher';
import {getIntlValueMapper} from '@illumio-shared/utils/intl/utils';

export interface AppPropsFromChildren {
  noContentFrame?: boolean;
  insensitive?: boolean;
}

export interface AppContextValue {
  /**
   * FIXME: use the proper types once they are ready.
   * `add`, `update`, `upsert`, `remove`, `navigate` and `navigateBackOrTo` can be derived from `typeof App`
   *
   * And we probably should move the whole interface to App.js
   */
  add(props: AppPropsFromChildren): string;

  update(props: AppPropsFromChildren, id: string): string;

  upsert(props: AppPropsFromChildren, id?: string): string;

  remove(id: string): void;

  // TODO use configureStore type once it's converted to TS
  store: ReactReduxContextValue['store'] & {prefetcher: Prefetcher};
  // FIXME: once ReactReduxContextValue['store'] is augmented, we can use the correct type here
  router: Router;
  dispatch: ReactReduxContextValue['store']['dispatch'];
  navigate: (
    to:
      | string
      | {
          to?: string;
          params?: Record<string, unknown>;
          reload?: boolean;
          replace?: boolean;
          evt?: domUtils.MouseEventLike;
          pageName?: string;
          scrollTop?: boolean | 'smooth';
          noUnsavedPendingWarning?: boolean;
          mergeParams?: boolean;
          target?: string;
        },
  ) => void;
  navigateBackOrTo: unknown;
  fetcher: Fetcher;

  sendAnalyticsEvent(name: string, data: unknown): void;

  isCSFrame: boolean;
  // we do null assertion to prevent null-check on the use site. However, we need to make sure the context is consumed within a Provider with correct values
  // see: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context
}

// Provides global context for router, store, navigate, navigateBackOrTo
export const AppContext = createContext<AppContextValue>(null!);

/**
 * Call fetchHealth will get information for clusters and local fqdn.
 * These information can help to decide whether the current PCE is local PCE for the super cluster setting.
 * If it is not a super cluster, it is always a local PCE.
 * @param clusters A list of clusters. If the number is greater than 1, it is a super cluster with multiple PCEs
 * @param local fqdn
 * @param pceFqdn The current PCE's fqdn
 * @returns {boolean}
 */
export const isLocalPce = ({
  clusters,
  local,
  pceFqdn,
}: {
  clusters: unknown[];
  local: boolean;
  pceFqdn: boolean;
}): boolean => (clusters.length > 1 ? local === pceFqdn : true);

const directions = {
  consumerFirst: {
    horizontal: 'right',
    vertical: 'down',
  },
  providerFirst: {
    horizontal: 'left',
    vertical: 'up',
  },
} as const;

/**
 * Helper that provides arrow direction based on consumer provider order.
 * @param {String} preference
 * @returns {{leftOrRight: string, upOrDown: string}}
 */
export const getConsumerProviderArrowDirection = (
  isReverseProviderConsumer: boolean,
  bearing: 'horizontal' | 'vertical',
): 'down' | 'left' | 'right' | 'up' => {
  return directions[isReverseProviderConsumer ? 'consumerFirst' : 'providerFirst'][bearing];
};

/* eslint-disable rulesdir/intl-call-verify */
export const toggleXpressIntlMapping = (): void => {
  const intlValuesMappingForAntman = {
    [intl('ErrorsAPI.err:rbac_cannot_operate_on_rule_with_broader_consumer_scope')]: intl(
      'Antman.ErrorsAPI.err:rbac_cannot_operate_on_rule_with_broader_consumer_scope',
    ),
    [intl('ErrorsAPI.err:rbac_rule_ub_scope_should_be_same_as_scope')]: intl(
      'Antman.ErrorsAPI.err:rbac_rule_ub_scope_should_be_same_as_scope',
    ),
    [intl('ErrorsAPI.err:service_provider_too_long')]: intl('Antman.ErrorsAPI.err:service_provider_too_long'),
    [intl('ErrorsAPI.err:rs_provider_required')]: intl('Antman.ErrorsAPI.err:rs_provider_required'),
    [intl('ErrorsAPI.err:rs_duplicate_provider')]: intl('Antman.ErrorsAPI.err:rs_duplicate_provider'),
    [intl('ErrorsAPI.err:rs_duplicate_consumer')]: intl('Antman.ErrorsAPI.err:rs_duplicate_consumer'),
    [intl('ErrorsAPI.err:cant_specify_ip_value_actor')]: intl('Antman.ErrorsAPI.err:cant_specify_ip_value_actor'),
    [intl('ErrorsAPI.err:ip_list_referenced_by_enforcement_boundary')]: intl(
      'Antman.ErrorsAPI.err:ip_list_referenced_by_enforcement_boundary',
    ),
    [intl('ErrorsAPI.err:resolve_actors_requires_rule_actors')]: intl(
      'Antman.ErrorsAPI.err:resolve_actors_requires_rule_actors',
    ),
    [intl('ErrorsAPI.err:rule_ub_must_be_managed')]: intl('Antman.ErrorsAPI.err:rule_ub_must_be_managed'),
    [intl('ErrorsAPI.err:rule_pb_only_ip_list_or_all')]: intl('Antman.ErrorsAPI.err:rule_pb_only_ip_list_or_all'),
    [intl('ErrorsAPI.err:provider_not_found')]: intl('Antman.ErrorsAPI.err:provider_not_found'),
    [intl('ErrorsAPI.err:consumer_not_found')]: intl('Antman.ErrorsAPI.err:consumer_not_found'),
    [intl('ErrorsAPI.err:label_still_has_associated_cwp')]: intl('Antman.ErrorsAPI.err:label_still_has_associated_cwp'),
    [intl('ErrorsAPI.err:label_still_has_associated_enforcement_boundary')]: intl(
      'Antman.ErrorsAPI.err:label_still_has_associated_enforcement_boundary',
    ),
    [intl('ErrorsAPI.err:ams_with_label_not_allowed')]: intl('Antman.ErrorsAPI.err:ams_with_label_not_allowed'),
    [intl('ErrorsAPI.err:label_group_referenced_by_enforcement_boundaries')]: intl(
      'Antman.ErrorsAPI.err:label_group_referenced_by_enforcement_boundaries',
    ),
    [intl('ErrorsAPI.err:service_referenced_by_enforcement_boundary')]: intl(
      'Antman.ErrorsAPI.err:service_referenced_by_enforcement_boundary',
    ),
    [intl('ErrorsAPI.err:stateless_rule_consumer_count_exceeded')]: intl(
      'Antman.ErrorsAPI.err:stateless_rule_consumer_count_exceeded',
    ),
    [intl('ErrorsAPI.err:invalid_windows_service_provider')]: intl(
      'Antman.ErrorsAPI.err:invalid_windows_service_provider',
    ),
    [intl('ErrorsAPI.err:non_brn_must_use_ip_list')]: intl('Antman.ErrorsAPI.err:non_brn_must_use_ip_list'),
    [intl('ErrorsAPI.err:virtual_server_no_service_with_workload')]: intl(
      'Antman.ErrorsAPI.err:virtual_server_no_service_with_workload',
    ),
    [intl('ErrorsAPI.err:virtual_server_non_workload_label_provider')]: intl(
      'Antman.ErrorsAPI.err:virtual_server_non_workload_label_provider',
    ),
    [intl('ErrorsAPI.err:service_binding_invalid_operation')]: intl(
      'Antman.ErrorsAPI.err:service_binding_invalid_operation',
    ),
    [intl('ErrorsAPI.err:container_cluster_virtual_service_edit')]: intl(
      'Antman.ErrorsAPI.err:container_cluster_virtual_service_edit',
    ),
    [intl('ErrorsAPI.err:container_cluster_virtual_service_delete')]: intl(
      'Antman.ErrorsAPI.err:container_cluster_virtual_service_delete',
    ),
    [intl('ErrorsAPI.err:ingress_service_must_be_empty')]: intl('Antman.ErrorsAPI.err:ingress_service_must_be_empty'),
    [intl('ErrorsAPI.err:ingress_service_required')]: intl('Antman.ErrorsAPI.err:ingress_service_required'),
    [intl('ErrorsAPI.err:resolve_labels_required')]: intl('Antman.ErrorsAPI.err:resolve_labels_required'),
    [intl('ErrorsAPI.err:stateless_rule_cannot_have_ip_list_provider')]: intl(
      'Antman.ErrorsAPI.err:stateless_rule_cannot_have_ip_list_provider',
    ),
    [intl('ErrorsAPI.err:invalid_egress_windows_service_consumer')]: intl(
      'Antman.ErrorsAPI.err:invalid_egress_windows_service_consumer',
    ),
    [intl('ErrorsAPI.err:container_host_rule_invalid_provider')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_invalid_provider',
    ),
    [intl('ErrorsAPI.err:container_host_rule_single_consumer')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_single_consumer',
    ),
    [intl('ErrorsAPI.err:container_host_rule_invalid_resolve_labels')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_invalid_resolve_labels',
    ),
    [intl('ErrorsAPI.err:container_host_rule_sec_connect_machine_auth')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_sec_connect_machine_auth',
    ),
    [intl('ErrorsAPI.err:container_host_rule_stateless')]: intl('Antman.ErrorsAPI.err:container_host_rule_stateless'),
    [intl('ErrorsAPI.err:container_host_rule_no_windows_service')]: intl(
      'Antman.ErrorsAPI.err:container_host_rule_no_windows_service',
    ),
    [intl('ErrorsAPI.err:container_host_rule_tcp_only')]: intl('Antman.ErrorsAPI.err:container_host_rule_tcp_only'),
    [intl('ErrorsAPI.err:namespace_key_mismatch')]: intl('Antman.ErrorsAPI.err:namespace_key_mismatch'),
    [intl('ErrorsAPI.err:container_workload_profile_invalid_pairing_key')]: intl(
      'Antman.ErrorsAPI.err:container_workload_profile_invalid_pairing_key',
    ),
    [intl('ErrorsAPI.err:unmanaged_container_workload_profile_labels')]: intl(
      'Antman.ErrorsAPI.err:unmanaged_container_workload_profile_labels',
    ),
    [intl('ErrorsAPI.err:ves_invalid_projected_actor')]: intl('Antman.ErrorsAPI.err:ves_invalid_projected_actor'),
    [intl('ErrorsAPI.err:duplicate_label_type_assignments')]: intl(
      'Antman.ErrorsAPI.err:duplicate_label_type_assignments',
    ),
    [intl('ErrorsAPI.err:invalid_expression_count_logical_operator')]: intl(
      'Antman.ErrorsAPI.err:invalid_expression_count_logical_operator',
    ),
    [intl('ErrorsAPI.err:invalid_label_mapping_rule_attribute')]: intl(
      'Antman.ErrorsAPI.err:invalid_label_mapping_rule_attribute',
    ),
    [intl('ErrorsAPI.err:invalid_label_mapping_rule_condition_value')]: intl(
      'Antman.ErrorsAPI.err:invalid_label_mapping_rule_condition_value',
    ),
    [intl('ErrorsAPI.err:invalid_label_mapping_rule_operator')]: intl(
      'Antman.ErrorsAPI.err:invalid_label_mapping_rule_operator',
    ),
    // ========== ErrorsAPI remapping ends ===========

    [intl('Explorer.ConsumerProcessService')]: intl('Explorer.SourceProcessService'),
    [intl('Explorer.ConsumerIPList')]: intl('Explorer.SourceIPList'),
    [intl('Rulesets.Rules.ProvidingService')]: intl('Rulesets.Rules.DestinationService'),
    [intl('Rulesets.Rules.ProvidingProcess')]: intl('Common.DestinationProcess'),
    [intl('Rulesets.Rules.ProvidingUsername')]: intl('Rulesets.Rules.DestinationUsername'),
    [intl('Rulesets.Rules.ConsumingUsername')]: intl('Rulesets.Rules.SourceUsername'),
    [intl('Rulesets.Rules.ConsumingService')]: intl('Rulesets.Rules.SourceService'),
    [intl('Rulesets.Rules.ExtraScope.Title')]: intl('Rulesets.DenyRule'),
    [intl('Rulesets.Rules.ExtraScope.NoData')]: intl('EnforcementBoundaries.DenyRulesNoData'),
    [intl('Policy.ProviderConsumerOrder')]: intl('Policy.SourceDestinationOrder'),
    [intl('RBAC.ServiceAccounts.RolesAndScopes')]: intl('Common.Roles'),
    [intl('RBAC.GlobalRoles')]: intl('Common.Roles'),
    [intl('Rulesets.Rules.DestinationServices')]: intl('Common.Services'),
    [intl('Common.ViewScope')]: intl('Common.View'),
    [intl('Users.AccessScopesRoles')]: intl('Users.AccessRoles'),
    [intl('Users.AddGlobalRole')]: intl('Users.AddRole'),
    [intl('Users.ConvertLocalUserWarning')]: intl('Users.ConvertLocalUserWarningWithoutScope'),
    // map the core name to antman name
    [intl('Common.ContainerWorkloads')]: intl('Antman.Common.ContainerWorkloads'),
    [intl('Common.ContainerWorkload')]: intl('Antman.Common.ContainerWorkload'),
    [intl('Common.InUseByContainerWorkloads')]: intl('Antman.Common.InUseByContainerWorkloads'),
    [intl('Common.InUseByContainerWorkloadsProfiles')]: intl('Antman.Common.InUseByContainerWorkloadsProfiles'),
    [intl('Common.NoDataContainerWorkloads')]: intl('Antman.Common.NoDataContainerWorkloads'),
    [intl('Common.NoDataContainerCluster')]: intl('Antman.Common.NoDataContainerCluster'),
    [intl('Common.NoDataContainerWorkloadProfiles')]: intl('Antman.Common.NoDataContainerWorkloadProfiles'),
    [intl('Common.NoMatchDataContainerClusters')]: intl('Antman.Common.NoMatchDataContainerClusters'),
    [intl('Common.NoMatchDataContainerWorkloads')]: intl('Antman.Common.NoMatchDataContainerWorkloads'),
    [intl('Common.PotentiallyBlocked')]: intl('Antman.Common.PotentiallyBlocked'),
    [intl('Common.PotentiallyBlockByDenyRules')]: intl('Antman.Common.PotentiallyBlockedByBoundary'),
    [intl('AppGroups.ContainerWorkloads')]: intl('Antman.AppGroups.ContainerWorkloads'),
    [intl('BlockedTraffic.PotentiallyBlockedOnConsumer')]: intl('Antman.BlockedTraffic.PotentiallyBlockedOnConsumer'),
    [intl('BlockedTraffic.PotentiallyBlockedOnProvider')]: intl('Antman.BlockedTraffic.PotentiallyBlockedOnProvider'),
    [intl('Dashboard.BlockedOrPotentiallyBlockedFlow')]: intl('Antman.Dashboard.BlockedOrPotentiallyBlockedFlow'),
    [intl('ContainerClusters.AddNew')]: intl('Antman.ContainerClusters.AddNew'),
    [intl('ContainerClusters.UseContainerAnnotations')]: intl('Antman.ContainerClusters.UseContainerAnnotations'),
    [intl('ContainerClusters.Delete')]: intl('Antman.ContainerClusters.Delete'),
    [intl('ContainerClusters.DeleteConfirm')]: intl('Antman.ContainerClusters.DeleteConfirm'),
    [intl('ContainerClusters.DeletedSuccessfully')]: intl('Antman.ContainerClusters.DeletedSuccessfully'),
    [intl('ContainerClusters.CannotDelete')]: intl('Antman.ContainerClusters.CannotDelete'),
    [intl('ContainerClusters.DuplicateMachineIDsError')]: intl('Antman.ContainerClusters.DuplicateMachineIDsError'),
    [intl('ContainerClusters.ContainerWorkloadProfiles')]: intl('Antman.ContainerClusters.ContainerWorkloadProfiles'),
    [intl('ContainerClusters.ContainerWorkloadProfilesMessage')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfilesMessage',
    ),
    [intl('ContainerClusters.ContainerWorkloadProfilesAdd')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfilesAdd',
    ),
    [intl('ContainerClusters.ContainerWorkloadProfilesUnmanaged')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfilesUnmanaged',
    ),
    [intl('ContainerClusters.ContainerWorkloadProfileTemplate')]: intl(
      'Antman.ContainerClusters.ContainerWorkloadProfileTemplate',
    ),
    [intl('ContainerClusters.DeleteContainerWorkloadProfile')]: intl(
      'Antman.ContainerClusters.DeleteContainerWorkloadProfile',
    ),
    [intl('ContainerClusters.DeleteContainerWorkloadProfileConfirm')]: intl(
      'Antman.ContainerClusters.DeleteContainerWorkloadProfileConfirm',
    ),
    [intl('ContainerClusters.DeletedContainerWorkloadProfileSuccessfully')]: intl(
      'Antman.ContainerClusters.DeletedContainerWorkloadProfileSuccessfully',
    ),
    [intl('ContainerClusters.CannotDeleteContainerWorkloadProfile')]: intl(
      'Antman.ContainerClusters.CannotDeleteContainerWorkloadProfile',
    ),
    [intl('ContainerClusters.AddNewContainerWorkloadProfile')]: intl(
      'Antman.ContainerClusters.AddNewContainerWorkloadProfile',
    ),
    [intl('ContainerClusters.CreatedByContainerCluster')]: intl('Antman.ContainerClusters.CreatedByContainerCluster'),
    [intl('ContainerClusters.UndeletedVirtualServices')]: intl('Antman.ContainerClusters.UndeletedVirtualServices'),
    [intl('VirtualServices.BoundContainerWorkloads')]: intl('Antman.VirtualServices.BoundContainerWorkloads'),
    [intl('VirtualServices.ManagedBy.ContainerCluster')]: intl('Antman.VirtualServices.ManagedBy.ContainerCluster'),
    [intl('VirtualServices.ManagedBy.ContainerClusterDeletedAndOnLeader')]: intl(
      'Antman.VirtualServices.ManagedBy.ContainerClusterDeletedAndOnLeader',
    ),
    [intl('VirtualServices.ManagedBy.ContainerClusterDeletedAndOnMember')]: intl(
      'Antman.VirtualServices.ManagedBy.ContainerClusterDeletedAndOnMember',
    ),
    [intl('Connectivity.SelectWorkloadContainerWorkloadOrIP')]: intl(
      'Antman.Connectivity.SelectWorkloadContainerWorkloadOrIP',
    ),
    [intl('Connectivity.VerifyRulesExist')]: intl('Antman.Connectivity.VerifyRulesExist'),
    [intl('ContainerWorkloads.All')]: intl('Antman.ContainerWorkloads.All'),
    [intl('ContainerWorkloads.View')]: intl('Antman.ContainerWorkloads.View'),
    [intl('ContainerWorkloads.ByCount')]: intl('Antman.ContainerWorkloads.ByCount'),
    [intl('ContainerWorkloads.WithCount')]: intl('Antman.ContainerWorkloads.WithCount'),
    [intl('ContainerWorkloads.Total')]: intl('Antman.ContainerWorkloads.Total'),
    [intl('CoreServices.AcceptedCoreService')]: intl('Antman.CoreServices.AcceptedCoreService'),
    [intl('CoreServices.RejectedCoreService')]: intl('Antman.CoreServices.RejectedCoreService'),
    [intl('CoreServices.AcceptedNotification')]: intl('Antman.CoreServices.AcceptedNotification'),
    [intl('CoreServices.AcceptedError')]: intl('Antman.CoreServices.AcceptedError'),
    [intl('CoreServices.RejectAcceptedError')]: intl('Antman.CoreServices.RejectAcceptedError'),
    [intl('Events.PotentiallyBlockedTraffic')]: intl('Antman.Events.PotentiallyBlockedTraffic'),
    [intl('EventUtils.ContainerWorkloadInvalidPairingConfig')]: intl(
      'Antman.EventUtils.ContainerWorkloadInvalidPairingConfig',
    ),
    [intl('EventUtils.ContainerWorkloadUpdateServices')]: intl('Antman.EventUtils.ContainerWorkloadUpdateServices'),
    [intl('EventUtils.ContainerWorkloadNotCreated')]: intl('Antman.EventUtils.ContainerWorkloadNotCreated'),
    [intl('EventUtils.ContainerWorkloadProfileCreated')]: intl('Antman.EventUtils.ContainerWorkloadProfileCreated'),
    [intl('EventUtils.ContainerWorkloadProfileDeleted')]: intl('Antman.EventUtils.ContainerWorkloadProfileDeleted'),
    [intl('EventUtils.ContainerWorkloadProfileUpdated')]: intl('Antman.EventUtils.ContainerWorkloadProfileUpdated'),
    [intl('EventUtils.ContainerClusterCreated')]: intl('Antman.EventUtils.ContainerClusterCreated'),
    [intl('EventUtils.ContainerClusterDeleted')]: intl('Antman.EventUtils.ContainerClusterDeleted'),
    [intl('EventUtils.ContainerClusterUpdated')]: intl('Antman.EventUtils.ContainerClusterUpdated'),
    [intl('EventUtils.ContainerClusterDuplicateMachineID')]: intl(
      'Antman.EventUtils.ContainerClusterDuplicateMachineID',
    ),
    [intl('EventUtils.ContainerClusterRegionMismatch')]: intl('Antman.EventUtils.ContainerClusterRegionMismatch'),
    [intl('EventUtils.ContainerClusterChangeRate')]: intl('Antman.EventUtils.ContainerClusterChangeRate'),
    [intl('Help.Desc.ProvidingService')]: intl('Antman.Help.Desc.ProvidingService'),
    [intl('Help.Desc.ProvidedTraffic')]: intl('Antman.Help.Desc.ProvidedTraffic'),
    [intl('Help.Desc.Explorer')]: intl('Antman.Help.Desc.Explorer'),
    [intl('Help.Desc.PolicySettings')]: intl('Antman.Help.Desc.PolicySettings'),
    [intl('Help.Desc.CCServices')]: intl('Antman.Help.Desc.CCServices'),
    [intl('Help.Desc.ContainerClusters')]: intl('Antman.Help.Desc.ContainerClusters'),
    [intl('Help.Desc.ContainerWorkloadInIllumination')]: intl('Antman.Help.Desc.ContainerWorkloadInIllumination'),
    [intl('Help.Desc.ContainerInheritHostPolicy')]: intl('Antman.Help.Desc.ContainerInheritHostPolicy'),
    [intl('Help.Desc.RejectedCoreService')]: intl('Antman.Help.Desc.RejectedCoreService'),
    [intl('Help.Desc.SelectiveEnforcementState')]: intl('Antman.Help.Desc.SelectiveEnforcementState'),
    [intl('Map.CantChangeLabelsOnContainers')]: intl('Antman.Map.CantChangeLabelsOnContainers'),
    [intl('Map.ConsumingAppGroups')]: intl('Antman.Map.ConsumingAppGroups'),
    [intl('Map.ProvidingAppGroups')]: intl('Antman.Map.ProvidingAppGroups'),
    [intl('Map.SearchConsumingAppGroups')]: intl('Antman.Map.SearchConsumingAppGroups'),
    [intl('Map.SearchProvidingAppGroups')]: intl('Antman.Map.SearchProvidingAppGroups'),
    [intl('Map.ViewBoundContainerWorkloads')]: intl('Antman.Map.ViewBoundContainerWorkloads'),
    [intl('Map.Search.ContainerWorkload')]: intl('Antman.Map.Search.ContainerWorkload'),
    [intl('Map.FlowVisibility.BlockAllowed')]: intl('Antman.Map.FlowVisibility.BlockAllowed'),
    [intl('Map.FlowVisibility.BlockAllowedEdge')]: intl('Antman.Map.FlowVisibility.BlockAllowedEdge'),
    [intl('Map.FlowVisibility.BlockTraffic')]: intl('Antman.Map.FlowVisibility.BlockTraffic'),
    [intl('Map.FlowVisibility.EnhancedDataCollectionDesc')]: intl(
      'Antman.Map.FlowVisibility.EnhancedDataCollectionDesc',
    ),
    [intl('Map.LegendPanel.PotentiallyBlockedVulnerableTraffic')]: intl(
      'Antman.Map.LegendPanel.PotentiallyBlockedVulnerableTraffic',
    ),
    [intl('Map.Workloads.RoleContainerWorkloads')]: intl('Antman.Map.Workloads.RoleContainerWorkloads'),
    [intl('Menu.ContainerClusters')]: intl('Antman.Menu.ContainerClusters'),
    [intl('Port.ProtocolProcess')]: intl('Antman.Port.ProtocolProcess'),
    [intl('Role.GlobalOrgOwner')]: intl('Antman.Role.GlobalOrgOwner'),
    [intl('Role.GlobalAdmin')]: intl('Antman.Role.GlobalAdmin'),
    [intl('Role.GlobalPolicyObjectProvisioner')]: intl('Antman.Role.GlobalPolicyObjectProvisioner'),
    [intl('Role.GlobalReadOnly')]: intl('Antman.Role.GlobalReadOnly'),
    [intl('Rule.Validation.ProcessServicesCantWithIPListProvider')]: intl(
      'Antman.Rule.Validation.ProcessServicesCantWithIPListProvider',
    ),
    [intl('Rule.Validation.ProviderIpListWindowsService')]: intl('Antman.Rule.Validation.ProviderIpListWindowsService'),
    [intl('Rule.Validation.ConsumerWithVirtualService')]: intl('Antman.Rule.Validation.ConsumerWithVirtualService'),
    [intl('Rule.Validation.ProvidingServicesVirtualServices')]: intl(
      'Antman.Rule.Validation.ProvidingServicesVirtualServices',
    ),
    [intl('Rule.Validation.ProviderWithVirtualService')]: intl('Antman.Rule.Validation.ProviderWithVirtualService'),
    [intl('Rule.Validation.ContainerHostItself')]: intl('Antman.Rule.Validation.ContainerHostItself'),
    [intl('Rule.Validation.ContainerHostNonTcpService')]: intl('Antman.Rule.Validation.ContainerHostNonTcpService'),
    [intl('PolicyGenerator.ExtraScope.NumConsumingAppGroup')]: intl(
      'Antman.PolicyGenerator.ExtraScope.NumConsumingAppGroup',
    ),
    [intl('Rulesets.NewProposedRuleset')]: intl('Antman.Rulesets.NewProposedRuleset'),
    [intl('Rulesets.EnforcementBoundaryRuleset')]: intl('Antman.Rulesets.EnforcementBoundaryRuleset'),
    [intl('Rulesets.Rules.IntraToExtra')]: intl('Antman.Rulesets.Rules.IntraToExtra'),
    [intl('Rulesets.Rules.ExtraToIntra')]: intl('Antman.Rulesets.Rules.ExtraToIntra'),
    [intl('Rulesets.Rules.ExtraScope.Add')]: intl('Antman.Rulesets.Rules.ExtraScope.Add'),
    [intl('Rulesets.Rules.ExtraScope.NoMatch')]: intl('Antman.Rulesets.Rules.ExtraScope.NoMatch'),
    [intl('Rulesets.Rules.ExtraScope.Rules')]: intl('Antman.Rulesets.Rules.ExtraScope.Rules'),
    [intl('Rulesets.Rules.ExtraScope.IPListWarning')]: intl('Antman.Rulesets.Rules.ExtraScope.IPListWarning'),
    [intl('Rulesets.Rules.IntraScope.Title')]: intl('Antman.Rulesets.Rules.IntraScope.Title'),
    [intl('Rulesets.Rules.IntraScope.Add')]: intl('Antman.Rulesets.Rules.IntraScope.Add'),
    [intl('Rulesets.Rules.IntraScope.NoData')]: intl('Antman.Rulesets.Rules.IntraScope.NoData'),
    [intl('Rulesets.Rules.IntraScope.NoMatch')]: intl('Antman.Rulesets.Rules.IntraScope.NoMatch'),
    [intl('Rulesets.Rules.IntraScope.Rules')]: intl('Antman.Rulesets.Rules.IntraScope.Rules'),
    [intl('Settings.Containers.ContainerInheritHostPolicyDesc')]: intl(
      'Antman.Settings.Containers.ContainerInheritHostPolicyDesc',
    ),
    [intl('Settings.Containers.AddScopeForContainerInheritHostPolicy')]: intl(
      'Antman.Settings.Containers.AddScopeForContainerInheritHostPolicy',
    ),
    [intl('Settings.Containers.EditScopeForContainerInheritHostPolicy')]: intl(
      'Antman.Settings.Containers.EditScopeForContainerInheritHostPolicy',
    ),
    [intl('Settings.Containers.ConfigInstruction')]: intl('Antman.Settings.Containers.ConfigInstruction'),
    [intl('Settings.Containers.DuplicatedScopesAndLabels')]: intl(
      'Antman.Settings.Containers.DuplicatedScopesAndLabels',
    ),
    [intl('Settings.Containers.UnmanagedDesc')]: intl('Antman.Settings.Containers.UnmanagedDesc'),
    [intl('Settings.Containers.ManagedDesc')]: intl('Antman.Settings.Containers.ManagedDesc'),
    [intl('Settings.Containers.AnnotationLabelHint')]: intl('Antman.Settings.Containers.AnnotationLabelHint'),
    [intl('Settings.Containers.AnnotationLabelTooltip')]: intl('Antman.Settings.Containers.AnnotationLabelTooltip'),
    [intl('Settings.IPForwarding.Description')]: intl('Antman.Settings.IPForwarding.Description'),
    [intl('TrafficEvents.PotentiallyBlockedEvents')]: intl('Antman.TrafficEvents.PotentiallyBlockedEvents'),
    [intl('VEN.ContainerCluster')]: intl('Antman.VEN.ContainerCluster'),
    [intl('Workloads.IncompatibilityTypes.UnwantedWFProviders')]: intl(
      'Antman.Workloads.IncompatibilityTypes.UnwantedWFProviders',
    ),
    [intl('EnforcementBoundaries.BlockedConnections')]: intl('Antman.EnforcementBoundaries.BlockedConnections'),
    [intl('EnforcementBoundaries.RulesProvision')]: intl('Antman.EnforcementBoundaries.RulesProvision'),
    [intl('EnforcementBoundaries.Remove')]: intl('Antman.EnforcementBoundaries.Remove'),
    [intl('EnforcementBoundaries.Description')]: intl('Antman.EnforcementBoundaries.Description'),
    [intl('Tooltip.ContainerWorkload')]: intl('Antman.Tooltip.ContainerWorkload'),
    [intl('Tooltip.ContainerWorkloadCount')]: intl('Antman.Tooltip.ContainerWorkloadCount'),
  };

  setIntlValueMapper(getIntlValueMapper(intlValuesMappingForAntman));
};

/**
 * Send page dirty status to parent window.
 * @param {boolean} dirty Page's dirty status
 */
export const sendIFramePageDirtyStatus = (dirty: boolean): void => {
  window.parent.postMessage(
    {
      id: 'pageDirtyStatus',
      dirty,
    },
    document.referrer,
  );
};
