/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';
import {Icon, Vulnerability} from 'components';
import {roundNumber} from 'components/Vulnerability/VulnerabilityUtils';

export const formatWideExposure = row => {
  if (row.vulnerabilityComputationState === 'syncing') {
    return '';
  }

  if (row.vulnerabilityComputationState === 'not_applicable') {
    return intl('Common.NA');
  }

  return row.wideExposure?.any || row.wideExposure?.ip_list ? (
    <Icon name="internet" size="medium" />
  ) : (
    intl('Common.None')
  );
};

export const formatCVEIDs = row => {
  return (row.details.cve_ids ?? []).map((id, index) => <div key={index}>{id}</div>);
};

export const formatVEScore = row => {
  return (
    <Vulnerability
      internetExposure={row.wideExposure?.any || row.wideExposure?.ip_list}
      severityScore={row.severity}
      vulnerabilityExposureScore={row.vulnerabilityExposureScore}
      vulnerabilityComputationState={row.vulnerabilityComputationState}
    />
  );
};

export const formatEWExposure = row => {
  if (row.vulnerabilityComputationState === 'syncing') {
    return '';
  }

  if (row.vulnerabilityComputationState === 'not_applicable') {
    return intl('Common.NA');
  }

  return roundNumber(row.vulnerablePortExposure);
};

export const formatVulnerabilityScore = value => {
  return roundNumber(value);
};

export const transformVulnerabilityInstances = vulnerabilities => {
  if (vulnerabilities?.instances) {
    return Object.values(vulnerabilities.instances).flat();
  }

  return [];
};

export const sortVEScore = ({rowA, rowB, sortFactor}) => {
  // Handle empty vulnerabilities
  if (!rowA && !rowB) {
    return 0;
  }

  if (!rowA) {
    return -1 * sortFactor;
  }

  if (!rowB) {
    return sortFactor;
  }

  // If the VES scores are the same, use the vulnerabilityScore
  if (rowA.vulnerabilityExposureScore === rowB.vulnerabilityExposureScore) {
    return rowA.severity > rowB.severity ? sortFactor : -1 * sortFactor;
  }

  if (rowA.vulnerabilityExposureScore === null) {
    return -1 * sortFactor;
  }

  if (rowB.vulnerabilityExposureScore === null) {
    return sortFactor;
  }

  return rowA.vulnerabilityExposureScore > rowB.vulnerabilityExposureScore ? sortFactor : -1 * sortFactor;
};
