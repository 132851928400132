/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName} from 'containers/App/AppState';
import {isSuperclusterUser, isSuperclusterMember} from 'containers/User/UserState';

export default {
  config(state = {}, action) {
    switch (action.type) {
      case 'LDAP_CONFIGS_GET_CONFIG':
        return action.data;
      default:
        return state;
    }
  },
};

export const getLDAPConfig = state => state.authenticationSettings.ldap.config;

export const getLDAPConfigViewPage = createSelector(
  isSuperclusterUser,
  isSuperclusterMember,
  getLDAPConfig,
  getRouteName,
  (userIsSuperclusterUser, userIsSuperclusterMember, ldapConfig, routeName) => ({
    userIsSuperclusterUser,
    userIsSuperclusterMember,
    ldapConfig: routeName === 'app.authenticationSettings.ldap.list.create' ? {} : ldapConfig,
  }),
);
