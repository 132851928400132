/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {useContext, type ComponentPropsWithoutRef} from 'react';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import {ModalContext} from './ModalUtils';
import {Icon} from 'components';
import styles from './ModalHeader.css';
import {typesUtils} from '@illumio-shared/utils';

export interface ModalHeaderProps extends Omit<ComponentPropsWithoutRef<'div'>, 'title'>, ThemeProps {
  // Any valid react element can be a title
  title?: typesUtils.ReactStrictNode;
  subtitle?: string;
  // Whether hide (true) or show close icon on header (true - default)
  noCloseIcon?: boolean;
}

export default function ModalHeader(props: ModalHeaderProps): JSX.Element {
  const {title, subtitle, theme, noCloseIcon, ...elementProps} = mixThemeWithProps(styles, props);
  const {onClose} = useContext(ModalContext);

  elementProps.className = theme.header;
  elementProps['data-tid'] = 'comp-dialog-header';

  return (
    <div {...elementProps}>
      {title ? (
        <div className={theme.headerContainer}>
          <div className={theme.title} data-tid="comp-dialog-title">
            <span className={theme.titleText} title={typeof title === 'string' ? title : undefined}>
              {title}
            </span>
          </div>
          {subtitle ? (
            <div className={theme.subtitle} data-tid="comp-dialog-subtitle">
              <span className={theme.subtitleText}>{subtitle}</span>
            </div>
          ) : null}
        </div>
      ) : null}
      {!noCloseIcon && typeof onClose === 'function' && (
        <div className={theme.close} onClick={onClose} data-tid="comp-dialog-close" title={intl('Common.Close')}>
          <Icon name="close" theme={styles} />
        </div>
      )}
    </div>
  );
}
