/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import {PureComponent} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {composeThemeFromProps} from '@css-modules-theme/react';
import {domUtils} from '@illumio-shared/utils';
import {Icon} from 'components';
import * as PropTypes from 'prop-types';
import styles from './TextLabel.css';

const defaultTextTid = 'elem-text';
const defaultAsteriskTid = 'elem-asterisk';
const statusVariants = {
  hide: () => ({opacity: 0, transition: {duration: domUtils.isMotionReduced() ? 0 : 0.1}}),
  show: () => ({opacity: 1, transition: {duration: domUtils.isMotionReduced() ? 0 : 0.2}}),
};

export default class TextLabel extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string, // unique name to handle errors key mapping
    children: PropTypes.arrayOf(PropTypes.element),
    touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    // toggle to disable CSS, Icon, Asterisk
    disabled: PropTypes.bool,
    // line height of Text Label
    paddingTop: PropTypes.string,
    lineHeight: PropTypes.string,
    // true: show asterisk, false: don't show asterisk
    showAsterisk: PropTypes.bool,
    // true: show error, false: don't show error icon
    showError: PropTypes.bool,
    // show field validated checkmark
    showValidatedCheckMark: PropTypes.bool,
    // themr object
    theme: PropTypes.object,
  };

  static defaultProps = {
    disabled: false,
    showError: false,
    showValidatedCheckMark: false,
  };

  render() {
    const {
      title,
      disabled,
      showValidatedCheckMark,
      showAsterisk,
      showError,
      lineHeight,
      paddingTop,
      theme,
      ...elementProps
    } = this.props;
    const themeStyle = composeThemeFromProps(styles, this.props);

    elementProps.className = themeStyle.labelContainer;

    if (lineHeight || paddingTop) {
      elementProps.style = {...(lineHeight && {lineHeight}), ...(paddingTop && {paddingTop})};
    }

    const hasAsterisk = !disabled && showAsterisk;

    const asteriskProps = {
      className: cx(themeStyle.mainAsterisk, {
        [themeStyle.asterisk]: hasAsterisk,
        [themeStyle.asteriskHidden]: !hasAsterisk,
      }),
    };

    // show asterisk with data-tid value only when field is required
    if (hasAsterisk) {
      asteriskProps['data-tid'] = defaultAsteriskTid;
    }

    const asterisk = <span {...asteriskProps}>*</span>;
    const labelCSS = cx(themeStyle.label, {[themeStyle.disabled]: disabled, [themeStyle.labelError]: showError});
    const label = (
      <span className={labelCSS} data-tid={defaultTextTid}>
        {title}
      </span>
    );

    const statusIcon = (
      <AnimatePresence initial={false}>
        {showValidatedCheckMark && (
          <motion.span
            key="valid"
            className={themeStyle.fieldValidated}
            variants={statusVariants}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <Icon name="inuse" position="before" />
          </motion.span>
        )}
      </AnimatePresence>
    );

    return (
      <div {...elementProps}>
        {asterisk}
        {label}
        {statusIcon}
      </div>
    );
  }
}
