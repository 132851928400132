/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './ContextualMenu.css';
import Menu, {type MenuProps} from 'components/Menu/Menu';

export type ContextualMenuProps = MenuProps & ThemeProps;

export default function ContextualMenu(props: ContextualMenuProps): JSX.Element {
  const {children, theme, ...menuProps} = mixThemeWithProps(styles, props);

  // Specify defaults for some Menu props, can be overridden by parent
  menuProps.triggerOnHover ??= true;
  menuProps.triggerOnHoverOpenDebounce ??= 200;
  menuProps.triggerOnHoverCloseTimeout ??= 150;
  menuProps.triggerOnFocusOpenDebounce ??= 300;
  menuProps.triggerOnFocusCloseTimeout ??= 0;
  // Since we open the menu on hover, we should allow scrolling
  menuProps.scrollParentBehavior ??= 'none';

  return (
    <Menu theme={theme} {...menuProps}>
      {children}
    </Menu>
  );
}
