/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import styles from './SideNavigation.css';
import {
  Checkbox,
  Icon,
  Input,
  Menu,
  MenuDelimiter,
  type MenuDelimiterProps,
  MenuItem,
  type MenuItemProps,
} from 'components';
import {ASPVersionModal} from 'containers';
import {default as Item, type SideNavigationItemProps} from './SideNavigationItem';
import {default as Logo} from './Logo/SideNavigationLogo';
import {type MutableRefObject, useCallback, useEffect, useRef, useState} from 'react';
import {getRouteCurrent, getRouteParams, isCoreServicesEnabled} from 'containers/App/AppState';
import {getCoreServiceSettings} from 'containers/CoreServices/Settings/CoreServicesSettingsState';
import {useSelector} from '@illumio-shared/utils/redux';
import {VALUE_ESCAPE} from 'keycode-js';
import {routesMap} from '../../router/routes';
import {domUtils, tidUtils} from '@illumio-shared/utils';
import {getContainersPropertiesMap} from 'containers/ContainersProperties';
import {isAPIAvailable} from 'api/apiUtils';
import type {IconName} from 'components/Icon/IconName';
import {getXpressFeaturesMode} from 'containers/User/Settings/SettingsState';

const MIN_WIDTH = 1152;

interface SideNavigationProps {
  searchInputRef: MutableRefObject<HTMLLIElement>;
}

export default function SideNavigation(props: SideNavigationProps): JSX.Element {
  const {searchInputRef} = props;
  const sidenavRef = useRef<HTMLDivElement>(null);
  const itemProps = useSelector(getContainersPropertiesMap);
  const xpressFeatures = useSelector(getXpressFeaturesMode);

  const routeParams = useSelector(getRouteParams);
  const currentRoute = useSelector(getRouteCurrent);
  const {group} = routesMap.get(currentRoute.name) || {};

  const viewPortWidth = domUtils.getViewportWidth();
  const BIG_SCREEN = viewPortWidth >= MIN_WIDTH;
  const SMALL_SCREEN = viewPortWidth < MIN_WIDTH;
  const DELIMITER = 'Delimiter';
  const lsCollapsedItem = JSON.parse(localStorage.getItem('collapsed') ?? 'false');
  let collapsedInitialValue = false;
  const coreServicesIsEnabled = useSelector(isCoreServicesEnabled);
  const coreServicesSettingsIsEnabled = useSelector(getCoreServiceSettings);

  if (routeParams.display === 'map') {
    collapsedInitialValue = true;
  } else {
    collapsedInitialValue = BIG_SCREEN ? lsCollapsedItem : true;
  }

  const [collapsed, setCollapsed] = useState<boolean>(collapsedInitialValue);
  const sidenavProps: Record<string, unknown> = {};

  sidenavProps['data-tid'] = tidUtils.getTid('comp-sidenav', String(collapsed ? 'collapsed' : 'expanded'));
  sidenavProps.ref = sidenavRef;

  const handleCollapse = useCallback(() => {
    setCollapsed(!collapsed);

    if (BIG_SCREEN) {
      localStorage.setItem('collapsed', String(!collapsed));
    }
  }, [BIG_SCREEN, collapsed]);

  useEffect(() => {
    function browserResizeHandler() {
      if (window.innerWidth < MIN_WIDTH) {
        setCollapsed(true);
      }
    }

    function handleDocumentKeydown(evt: KeyboardEvent) {
      if (evt.key === VALUE_ESCAPE) {
        setCollapsed(true);
      }
    }

    window.addEventListener('resize', browserResizeHandler);
    window.addEventListener('keydown', handleDocumentKeydown);

    // remove event listeners on unmount
    return () => {
      window.removeEventListener('resize', browserResizeHandler);
      window.removeEventListener('keydown', handleDocumentKeydown);
    };
  }, []);

  const instantSearch = (
    <li className={cx(styles.search)} ref={searchInputRef}>
      <Icon
        name="search"
        themePrefix="search-"
        theme={styles}
        tooltip={collapsed && intl('Common.Search')}
        tooltipProps={{right: true, theme: styles, themePrefix: 'icon-'}}
      />
      <Input
        tid="instant-search-input"
        name="instantSearchInput"
        themePrefix="search-"
        theme={styles}
        placeholder="Search"
        icon="search"
      />
    </li>
  );

  interface NestedNavItemProps extends SideNavigationItemProps {
    items?: SideNavigationItemProps[];
  }
  type NavigationItem =
    | NestedNavItemProps
    | (MenuDelimiterProps & {
        container: typeof DELIMITER;
      });

  const antmanMenuContent: NavigationItem[] = [
    {container: 'XpressDashboard'},
    {
      container: 'Explore',
      defaultOpen: true,
      collapsed,
      groupIsActive: group === 'Explore',
      items: [{container: 'IlluminationMap'}, {container: 'IlluminationTraffic'}, {container: 'IlluminationMesh'}],
    },
    {
      container: 'Workloads',
      defaultOpen: true,
      collapsed,
      groupIsActive: group === 'Workloads',
      items: [
        {container: 'WorkloadList'},
        {container: 'ServerList'},
        {container: 'EndpointList'},
        {container: 'VenList'},
      ],
    },
    {
      container: 'Policy',
      defaultOpen: false,
      collapsed,
      groupIsActive: group === 'Policy',
      items: [{container: 'RulesetList'}, {container: 'PendingList'}],
    },
    ...(xpressFeatures === 'advanced'
      ? [
          {
            container: 'AdvancedPolicy',
            defaultOpen: false,
            collapsed,
            groupIsActive: group === 'AdvancedPolicy',
            items: [
              {container: 'ServiceList'},
              {container: 'IPListList'},
              {container: 'LabelList'},
              {container: 'LabelGroupList'},
              {container: 'NetworkList'},
              {container: 'PairingProfileList'},
            ],
          },
        ]
      : []),
    {container: DELIMITER, themePrefix: 'sidenav-', theme: styles, className: styles.collapsed},
    {
      container: 'Access',
      collapsed,
      groupIsActive: group === 'Access',
      items: [
        {container: 'GlobalRoleList'},
        {container: 'ScopeList'},
        {container: 'ExternalGroupList'},
        {container: 'ExternalUserList'},
        {container: 'LocalUserList'},
        {container: 'ServiceAccountList'},
        {container: 'UserActivityList'},
        {container: 'AuthenticationSettings'},
        {container: 'AccessRestrictionList'},
      ],
    },
    {
      container: 'Infrastructure',
      collapsed,
      groupIsActive: group === 'Infrastructure',
      items: [
        {container: 'CoreServicesList'},
        {container: 'LoadBalancerList'},
        {container: 'ContainerClusterList'},
        {container: 'SecureGatewayList'},
        {container: 'SwitchList'},
      ],
    },
    {
      container: 'Settings',
      collapsed,
      groupIsActive: group === 'Settings',
      items: [
        {container: 'CorporatePublicIPsView'},
        {container: 'EventsConfig'},
        {container: 'FlowCollectionFilters'},
        {container: 'LabelSettings'},
        {container: 'SecuritySettings'},
        {container: 'CoreServicesSettings'},
        {container: 'EssentialServiceRules'},
        {container: 'VenOperations'},
        {container: 'TrustedProxyList'},
        {container: 'PolicySettings'},
        {container: 'APIKeySettingsView'},
        {container: 'OfflineTimers'},
      ],
    },
    {
      container: 'Troubleshoot',
      collapsed,
      groupIsActive: group === 'Troubleshoot',
      items: [
        {container: 'RuleSearch'},
        {container: 'EventsList'},
        {container: 'SupportReportsList'},
        {container: 'PCESupportBundlesList'},
        {container: 'Reporting'},
        {container: 'UserGroupList'},
        {container: 'AppGroupList'},
        {container: 'VenLibrary'},
        {container: 'SupportPortal'},
        {container: 'ProductVersion'},
      ],
    },
  ];

  const coreMenuContent: NavigationItem[] = [
    ...(isAPIAvailable('vens.statistics') || isAPIAvailable('reports.time_series_statistics')
      ? [
          {
            container: 'Dashboard',
            defaultOpen: true,
            collapsed,
            groupIsActive: group === 'Dashboard',
            items: [{container: 'VenStatistics'}, {container: 'RansomwareDashboard'}],
          },
        ]
      : []),
    {container: 'MyManagedTenantsList', collapsed},
    {
      container: 'Explore',
      defaultOpen: true,
      collapsed,
      groupIsActive: group === 'Explore',
      items: [
        {container: 'IlluminationMap'},
        {container: 'IlluminationTraffic'},
        {container: 'IlluminationMesh'},
        {container: 'Reporting'},
        {container: 'AppGroupList'},
        {container: 'Explorer'},
        {container: 'ClassicIllumination'},
      ],
    },
    {
      container: 'Policy',
      defaultOpen: true,
      collapsed,
      groupIsActive: group === 'Policy',
      items: [{container: 'RulesetList'}, {container: 'EnforcementBoundariesMain'}, {container: 'PendingList'}],
    },
    {
      container: 'ServersAndEndpoints',
      defaultOpen: true,
      collapsed,
      groupIsActive: group === 'ServersAndEndpoints',
      items: [{container: 'WorkloadList'}, {container: 'PairingProfileList'}],
    },
    {
      container: 'PolicyObjects',
      collapsed,
      groupIsActive: group === 'PolicyObjects',
      items: [
        {container: 'ServiceList'},
        {container: 'IPListList'},
        {container: 'LabelList'},
        {container: 'UserGroupList'},
        {container: 'LabelGroupList'},
        {container: 'VirtualServiceList'},
        {container: 'VirtualServerList'},
      ],
    },
    {container: DELIMITER, themePrefix: 'sidenav-', theme: styles, className: styles.collapsed},
    {
      container: 'Access',
      collapsed,
      groupIsActive: group === 'Access',
      items: [
        {container: 'GlobalRoleList'},
        {container: 'ScopeList'},
        {container: 'ExternalGroupList'},
        {container: 'ExternalUserList'},
        {container: 'LocalUserList'},
        {container: 'ServiceAccountList'},
        {container: 'UserActivityList'},
        {container: 'AuthenticationSettings'},
        {container: 'AccessRestrictionList'},
      ],
    },
    {
      container: 'Infrastructure',
      collapsed,
      groupIsActive: group === 'Infrastructure',
      items: [
        ...(isAPIAvailable('settings_core_services.update') &&
        coreServicesIsEnabled &&
        coreServicesSettingsIsEnabled === 'enabled'
          ? [{container: 'CoreServicesList'}]
          : []),
        {container: 'LoadBalancerList'},
        {container: 'ContainerClusterList'},
        {container: 'SecureGatewayList'},
        {container: 'NetworkList'},
        {container: 'SwitchList'},
        {container: 'CloudSecure'},
      ],
    },
    {
      container: 'Settings',
      collapsed,
      groupIsActive: group === 'Settings',
      items: [
        {container: 'CorporateIPList'},
        {container: 'EventsConfig'},
        {container: 'FlowCollectionFilters'},
        {container: 'LabelSettings'},
        {container: 'SecuritySettings'},
        {container: 'CoreServicesSettings'},
        {container: 'EssentialServiceRules'},
        {container: 'VenOperations'},
        {container: 'TrustedProxyList'},
        {container: 'PolicySettings'},
        {container: 'APIKeySettingsView'},
        {container: 'OfflineTimers'},
      ],
    },
    {
      container: 'Troubleshoot',
      collapsed,
      groupIsActive: group === 'Troubleshoot',
      items: [
        {container: 'EventsList'},
        {container: 'ReportsList'},
        {container: 'SupportReportsList'},
        {container: 'PCESupportBundlesList'},
        {container: 'PolicyCheck'},
        {container: 'ProductVersion'},
        {container: 'SegmentationTemplates'},
      ],
    },
    {
      container: 'Support',
      collapsed,
      groupIsActive: group === 'Support',
      items: [{container: 'VenLibrary'}, {container: 'SupportPortal'}],
    },
  ];
  const menuContent = __ANTMAN__ ? antmanMenuContent : coreMenuContent;

  const isActiveRouteMapping: Record<string, MenuItemProps['isActive']> = {
    EventsList: ['events.list', 'events.detail'],
    ExternalUserList: ['rbac.users.external.list', 'rbac.users.external.detail'],
  };

  const expandedMenu = menuContent.map((item, index) => {
    const key = `${item.container}-${index}-expanded`;

    // for now we will couple the delimiter with PolicyObjects. Future designs have delimiter always after PolicyObjects
    if (item.container === DELIMITER && itemProps.get('PolicyObjects')?.isAvailable) {
      const {container, ...delimiterProps} = item;

      return <MenuDelimiter {...delimiterProps} key={key} />;
    }

    const {items, ...props} = item as NestedNavItemProps;

    return (
      <Item {...props} key={key}>
        {items?.map(({container}: {container: SideNavigationItemProps['container']}, i) => (
          <Item key={`${container}-${i}-expanded-child`} container={container} />
        ))}
      </Item>
    );
  });

  const availableMenuItems = (itemChildren: SideNavigationItemProps[]) =>
    itemChildren.filter(item => {
      const {container} = item;
      const {isAvailable} = itemProps.get(container) || {};

      return isAvailable;
    });
  const defaultTid = 'comp-sidenav-item';

  return (
    <div className={cx({[styles.collapsed]: collapsed, [styles.expanded]: !collapsed})} {...sidenavProps}>
      {SMALL_SCREEN && <div className={styles.backdrop} onClick={_.partial(handleCollapse)} />}
      <aside className={cx(styles.container, {[styles.screenMobile]: SMALL_SCREEN})}>
        <Logo collapsed={collapsed} />
        <ul className={styles.content}>
          {instantSearch}
          {collapsed ? (
            <Menu
              keepParentOpen
              preventParentAnimate
              openOnLoad
              triggerOnHoverCloseTimeout={150}
              themePrefix="flyoutMenu-"
              tid="side-nav"
              theme={styles}
            >
              {menuContent.map((item, index) => {
                if (item.container === DELIMITER) {
                  const {container, ...delimiterProps} = item;

                  return <MenuDelimiter {...delimiterProps} key={`${container}-${index}-collapsed`} />;
                }

                const {viewName, linkProps, icon, isAvailable} = itemProps.get(item.container) || {};
                const availItems = availableMenuItems((item as NestedNavItemProps).items || []);
                const subDrop = availItems?.map((item, index) => {
                  const {container} = item;
                  const {viewName, linkProps} = itemProps.get(container) || {};

                  const tids = [viewName as string];
                  let display = viewName;

                  // illumination param is table, but viewName for it is Traffic
                  if (viewName === 'Traffic') {
                    display = 'table';
                  }

                  const active = routeParams.display
                    ? routeParams.display === (display as string)?.toLowerCase()
                    : isActiveRouteMapping[item.container];

                  return (
                    <MenuItem
                      themePrefix="menuItem-"
                      theme={styles}
                      key={`${container}-${index}-subDropdown`}
                      isActive={active}
                      link={linkProps}
                      text={viewName}
                      tid={tidUtils.getTid(defaultTid, tids)}
                    />
                  );
                });

                const subDropKey = `${item.container}-${index}-collapsed`;

                return (
                  isAvailable &&
                  icon &&
                  (subDrop?.length ? (
                    <MenuItem
                      themePrefix="menuItem-"
                      tid={viewName}
                      theme={styles}
                      key={subDropKey}
                      link={linkProps}
                      icon={<Icon name={icon as IconName} themePrefix="menu-" theme={styles} />}
                    >
                      {subDrop}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      themePrefix="menuItem-"
                      key={subDropKey}
                      tid={viewName}
                      theme={styles}
                      link={linkProps}
                      icon={
                        <Icon
                          name={icon as IconName}
                          themePrefix="menu-"
                          theme={styles}
                          tooltip={viewName}
                          tooltipProps={{right: true, theme: styles, themePrefix: 'icon-'}}
                        />
                      }
                    />
                  ))
                );
              })}
            </Menu>
          ) : (
            expandedMenu
          )}
        </ul>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            id="navToggle"
            themePrefix="toggle-"
            theme={styles}
            tid="nav-toggle"
            iconOn="chevron-double-left"
            iconOff="chevron-double-right"
            onChange={_.partial(handleCollapse)}
            checked={Boolean(collapsed)}
          />
        </div>
      </aside>
      {routeParams.showVersion && (
        <ASPVersionModal
          link={{params: {showVersion: null}, replace: true, scrollTop: false, noUnsavedPendingWarning: true}}
        />
      )}
    </div>
  );
}
