/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

export default {
  instance(state = {}, action) {
    switch (action.type) {
      case 'MSPTENANTS_GET_INSTANCE':
        return action.data;
      default:
        return state;
    }
  },
};

export const getMSPTenant = state => state.mspTenants.instance;
