/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {useCallback, useRef, useEffect, useMemo} from 'react';
import {Pill, Tooltip} from 'components';
import styleUtils from 'utils.css';
import {generalUtils} from '@illumio-shared/utils/shared';
import {domUtils} from '@illumio-shared/utils';

export default function SelectedValue(props) {
  const {
    resource,
    theme,
    saveRef,
    disabled,
    insensitive,
    id,
    text,
    value,
    formatValue,
    formatValueText,
    resourceName,
    hideResourceName,
    error,
    highlighted,
    isPill,
    pillProps,
    shouldScrollToElement,
    onClick,
    onRemove,
    onMouseLeave,
  } = props;

  const elementRef = useRef();
  const saveRefCallback = useCallback(
    element => {
      elementRef.current = element;
      saveRef(id, element);
    },
    [id, saveRef],
  );
  const handleRemove = useMemo(() => (onRemove ? evt => onRemove(evt, id) : undefined), [id, onRemove]);

  useEffect(() => {
    if (!shouldScrollToElement) {
      return;
    }

    domUtils.scrollToElement({element: elementRef.current});
  }, [shouldScrollToElement]);

  const isInsensitive = insensitive || disabled;

  let val =
    formatValueText?.(value) ??
    (hideResourceName || !resourceName ? (
      text
    ) : (
      <>
        {resourceName}
        <span className={styleUtils.bold}>{text}</span>
      </>
    ));

  const hasErrorContent = typeof error === 'string' ? Boolean(error.trim()) : error;

  if (hasErrorContent) {
    val = (
      <Tooltip content={error} visible>
        {val}
      </Tooltip>
    );
  }

  return (
    <div
      ref={saveRefCallback}
      className={theme.selectedValue}
      onMouseLeave={onMouseLeave}
      data-tid="comp-selected-value"
    >
      {formatValue?.({value, resource, insensitive, disabled, highlighted, theme, onRemove: handleRemove, onClick}) ?? (
        <Pill
          onClose={isInsensitive ? undefined : handleRemove}
          theme={theme}
          error={typeof error === 'string'}
          highlighted={highlighted}
          insensitive={insensitive}
          disabled={disabled}
          themePrefix={isPill ? undefined : 'joinerValuePill-'}
          {...generalUtils.callableValue(pillProps, {value})}
          noContextualMenu
          onClick={isInsensitive ? undefined : onClick}
        >
          {val}
        </Pill>
      )}
    </div>
  );
}
