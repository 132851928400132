/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  ReactNode,
  ElementType,
  ComponentClass,
  FunctionComponent,
  DetailedHTMLProps,
  ComponentType,
  PropsWithoutRef,
  ComponentProps,
} from 'react';
import type {SagaIterator} from 'redux-saga';
import type {FieldProps} from 'formik';
import type {ForwardRefProps} from 'react-forwardref-utils';
import _ from 'lodash';
import * as PropTypes from 'prop-types';

//TODO convert to type
const whenPropTypes = {
  // If current form element should be active only when other form field value(s) meet some condition,
  // then those conditions can be specified in `when` (similar to yup's `when`) prop as object, like:
  // when={{checkboxIsBig: true, checkboxColorGroup: ['red', 'green']}}
  // or as function, like:
  // when={(name, value, form) => form.checkboxIsBig && ['red', 'green'].every(val => form.checkboxColorGroup.includes(val))}
  // Both examples here are equivalent, object is a list of other form field names with expected values,
  // whilst function can contain custom logic of any complexity and expected to return boolean.
  // When result of `when` changes from true to false,
  // then the corresponding form value of current form element will be reset to the initial one and touch will be set to false.
  when: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // Specify action that should be done:
  // 1. Result of `when` changes from false to true
  whenBecomesTrue: PropTypes.oneOfType([PropTypes.oneOf(['noop', 'initial', 'empty']), PropTypes.func]),
  // 2. Result of `when` changes from true to false
  whenBecomesFalse: PropTypes.oneOfType([PropTypes.oneOf(['noop', 'initial', 'empty']), PropTypes.func]),
};

/**
 * Single param without a return value
 *
 * @param param Generic Type
 */
export type ReturnVoid<T> = (param: T) => void;

/**
 * Single generic input with same generic output
 *
 * @param param Generic Type
 * @returns Generic Type
 */
export type ExactInputOutput<T> = (param: T) => T;

/**
 * Single Optional Param return the same generic input type or undefined
 *
 * @param param An optional Generic Type
 * @returns Generic Type or undefined
 */
export type SingleOptionalParam<T> = (param?: T) => T | undefined;

/**
 * Single required parameter return same param type or null
 *
 * @param param Generic Type
 * @returns Generic Type or null
 */
export type SingleParamReturnTypeNull<T> = (param: T) => T | null;

/**
 * Single required parameter return same boolean
 *
 * @param param Generic Type
 * @returns boolean
 */
export type SingleParamReturnTypeBooleanOnly<T> = (param: T) => boolean;

/**
 * Difference parameter comparison then return an intersection with U
 *
 * @template T
 * @template U type will be intersected with type T initialized in the method
 * @param newData Generic Type
 * @param oldData An optional Generic Type
 * @returns An intersection between newData and oldData
 */
export type DifferenceComparisonWithIntersection<T, U> = (newData: T & U, oldData?: T & U) => T & U;

/**
 * Accept two exact types
 *
 * @template T
 * @param param Generic Type
 * @param paramB Generic Type
 * @returns
 */
export type DoubleParamsSameType<T> = (param: T, paramB: T) => T;

/**
 * Key to type index signature
 *
 * @param Generic Type
 * @returns
 */
export type KeyToType<T> = {
  [P in keyof T]: T[P];
};

/**
 *  Using saga: () => SagaIterator; to pass to call() will work however, we might have cases that 'T' value doesn't
 *  have the proper : context, fn thus TypeScript complains. By declaring this syntax making T and subtype of
 *  {context: any; fn: (this: unknown, ...args: any[]) => any;}
 *
 *  When they are a subtype of each other we can use 'double asssertion' on the callback passed to call()
 *  Note: {context: any, fn: (this: unknown, ...args: any[]) => any} is the type declaration in effects.d.ts (e.g. CallEffectDescriptor<RT>)
 *
 *  Issue: // https://github.com/redux-saga/redux-saga/issues/1177
 */
export type SagaReduxCall<T> = T & {context: unknown; fn: (this: unknown, ...args: unknown[]) => any};

/**
 *  An a default iterator return type for generator method since we force user to have a return type
 *  e.g. @typescript-eslint/explicit-function-return-type (type script rule)
 *  e.g. *() => ReturnIteratorGenerator
 */
export type ReturnIterableIterator = IterableIterator<unknown>;

/**
 * An Redux Saga Iterator type for calling 'yield call(method: ReduxSagaIterator)'
 */
export type ReduxSagaIteratorCallback = () => SagaIterator;

/**
 * A type to extract the array types
 *
 * https://stackoverflow.com/questions/41253310/typescript-retrieve-element-type-information-from-array-type
 *
 * export const eventStatus = createSelector([], () => [
 * {value: 'success', label: intlOriginal('Common.Success')},
 * {value: 'failure', label: intlOriginal('Common.Failure')},
 * {value: 'nil', label: intlOriginal('Common.NA')},
 * ]);
 * const k = ArrayElement<ReturnType<typeof eventStatus>>
 * e.g. k: {value: string, label: any}
 *
 * Note: ArrayType[number] uses number because TypeScript uses numbers for indexing Arrays while JavaScript
 *       uses string and numbers
 */
export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

/**
 * Set a specific type to all keyof T
 *
 * e.g. Initializing PropTypes.bool type
 *
 * type ParseAddressChecks = typeof parseAddressChecks;
 * type S = typesUtils.SetSpecificTypeToKeyof<ParseAddressChecks, keyof ParseAddressChecks, PropTypes.Requireable<boolean>>;
 */
export type SetSpecificType<T, K extends keyof T, TYPE> = {[p in K]: TYPE};

/**
 * A more specific `ReactNode` which disallows any undefined objects
 * This type removes `undefined` from `ReactNode` so it better conforms to the renderable
 * React children
 */
export type ReactStrictNode = Exclude<ReactNode, undefined>;

/**
 * `Pick` that distribute on union types.
 * The `Pick` generic does not distribute on union type
 *
 * See: https://github.com/microsoft/TypeScript/issues/28339
 */
export type UnionPick<T, K extends keyof T> = T extends unknown ? Pick<T, K> : never;

/**
 * `Omit` that distribute on union types.
 * The `Omit` generic does not distribute on union type
 *
 * See: https://github.com/microsoft/TypeScript/issues/28339
 */
export type UnionOmit<T, K extends string> = T extends unknown ? Omit<T, K> : never;

export type DistributiveSetRequired<BaseType, Keys extends keyof BaseType> = BaseType extends unknown
  ? // Pick just the keys that are optional from the base type.
    Pick<BaseType, Exclude<keyof BaseType, Keys>> &
      // Pick the keys that should be required from the base type and make them required.
      Required<Pick<BaseType, Keys>>
  : never;

/**
 * Get the version of a type without falsy types like `undefined`, `null` or `false`
 */
export type TruthFull<T> = Exclude<T, 0 | false | null | undefined>;

export type Falsable<T> = T | false | null;

/**
 * Get the instance type of the ElementType
 *
 * #### Example
 *
 * - `ElementInstance<'div'>` gives type `HTMLDivElement`
 * - `ElementInstance<typeof MyClassComponent>` gives type `MyClassComponent`
 * - `ElementInstance<typeof ForwardRefFunctionComponent>` gives `typeof ForwardRefFunctionComponent.props.ref`
 */
export type ElementInstance<T extends ElementType> = T extends ComponentClass<any>
  ? InstanceType<T>
  : T extends FunctionComponent<infer P>
  ? 'ref' extends keyof P
    ? P['ref']
    : never
  : T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T] extends DetailedHTMLProps<any, infer F>
    ? F
    : never
  : never;

/**
 * Get the external props from a component. External props correctly make defaultProps optional
 * See: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/default_props#solution
 */
export type ComponentExternalProps<C extends ElementType> = C extends ComponentType<infer P>
  ? JSX.LibraryManagedAttributes<C, P>
  : ComponentProps<C>;

export type ComponentExternalPropsWithoutRef<T extends ElementType> = PropsWithoutRef<ComponentExternalProps<T>>;

/**
 * Get the forwardRef, Formik FieldProps Types, whenPropTypes
 *
 * @typeParam T props needed to intersect
 * @tppeParam E the type of the instance provided by the `ref`
 */
export type ForwardRefFormikWhenTypes<T, E = HTMLDivElement> = FieldProps &
  ForwardRefProps<E> &
  T &
  typeof whenPropTypes;

/**
 * Turns a union of array types into an array of unions.
 *
 *
 */
export type ZipArrayUnion<T extends any[]> = (T extends (infer U)[] ? U : never)[];

/**
 * @param ObjectType  The object containing the mutually exclusive properties
 * @param KeysType    The keys inside the object that are mutually exclusive
 *
 * Similar to `RequireExactlyOne` from `type-fest` but does not enforce that at least one property exists
 *
 * Note: It does not change whether the properties are optional or required and it returns a **union type**,
 * so be careful when you try to `Omit` or `Pick` on the returned union type. In some situation you may want
 * distributed omit/pick with `UnionOmit` and `UnionPick`
 */
export type MutuallyExclusive<ObjectType, KeysType extends keyof ObjectType = keyof ObjectType> = {
  [Key in KeysType]: Partial<Record<Exclude<KeysType, Key>, never>> & Pick<ObjectType, Key>;
}[KeysType] &
  Omit<ObjectType, KeysType>;
