/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {mixThemeWithProps, type ThemeProps, type Theme} from '@css-modules-theme/react';
import {Icon} from 'components';
import styles from './PillSymbol.css';
import type {IconName} from 'components/Icon/IconName';

type TypeItem = {
  type: string;
  symbolTheme: Theme;
};

const linkSymbolMap = new WeakMap<Theme, TypeItem[]>();

// Cache theme object for Icon by own PillSymbol theme, so Icon will not need to recompose theme on each rerender,
// if PillSymbol theme and icon are the same across rerenders
function getSymbolTheme(pillTheme: Theme, type: string) {
  let linkThemesByType = linkSymbolMap.get(pillTheme);

  if (linkThemesByType === undefined) {
    linkThemesByType = [];
    linkSymbolMap.set(pillTheme, linkThemesByType);
  } else {
    const typeItem = linkThemesByType.find(item => item.type === type);

    if (typeItem !== undefined) {
      return typeItem.symbolTheme;
    }
  }

  const typeItem = {
    type,
    symbolTheme: {
      ...pillTheme,
      icon: cx(pillTheme.symbol, pillTheme[type]),
    },
  };

  linkThemesByType.push(typeItem);

  return typeItem.symbolTheme;
}

export type PillSymbolProps = ThemeProps & {
  name: IconName | undefined;
  initial?: string;
  pillStyle?: string;

  // If icon is for a group (icon will have 'cloned' background)
  group?: boolean;
};

declare module 'react' {
  interface CSSProperties {
    '--pill-symbol-color'?: string;
    '--pill-symbol-background-color'?: string;
  }
}

export default function PillSymbol(props: PillSymbolProps): JSX.Element {
  const {theme, group = false, name, initial, pillStyle = 'icon', ...iconProps} = mixThemeWithProps(styles, props);
  const type = _.camelCase(props.name);

  const displayIcon = name && (pillStyle.includes('icon') || !initial);
  const displayInitial = initial && (pillStyle.includes('initial') || !name);

  return (
    <div className={cx(theme.pillSymbol, {[theme.isGroup]: group})}>
      {displayIcon && <Icon name={name} {...iconProps} labelGroup={group} theme={getSymbolTheme(theme, type)} />}
      {displayInitial && <span className={cx(theme.symbol, {[theme.small]: initial.length > 1})}>{initial}</span>}
    </div>
  );
}
